import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { saveUserInfoToLocale } from '../../../../../redux/app-v2/user/thunks';
import { saveMerchantInfoToLocale } from '../../../../../redux/app-v2/merchant/thunks';
import { formatAuth } from '../../../../../common/utils';
import { postUserWecomLogin } from './api-request';

/**
 * User Wecom login
 */
export const userWecomLogin = createAsyncThunk(
  'login/userLogin',
  async (
    {
      authCode,
      authType,
      navigateToHomePage,
    }: {
      authCode: string | null;
      authType: string | null;
      navigateToHomePage: Function;
    },
    { dispatch }
  ) => {
    try {
      if (!authCode) {
        throw Error('Wecom auth code is empty');
      }

      if (!authType) {
        throw Error('Wecom auth type is empty');
      }

      const result: any = await postUserWecomLogin({ authCode, authType });
      const { data } = result;

      await dispatch(
        saveMerchantInfoToLocale({ merchantName: data.merchantName })
      );
      await dispatch(
        saveUserInfoToLocale({
          id: data.characterId,
          authorization: formatAuth(data.bearerToken),
          isLogin: true,
        })
      );

      navigateToHomePage();
    } catch (e) {
      notification.error({
        message: '登录失败',
        description: '企业微信验证失败',
      });

      throw e;
    }
  }
);
