import React from 'react';
import { Tag } from 'antd';
import { useMount } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../../common/utils/types';
import ContentDetail from '../../../../../../common/components/ContentDetail';
import TableResponsiveFrame from '../../../../../../common/components/tables/TableResponsiveFrame';
import {
  getAssociationPlayersImportTaskList,
  getAssociationPlayersImportTasksPageNumber,
  getAssociationPlayersImportTasksPageSize,
  getAssociationPlayersImportTasksTotal,
} from './redux/selectors';
import {
  loadAssociationPlayersImportTasks,
  updateAssociationPlayersImportTasksPageNumber,
  updateAssociationPlayersImportTasksPageSize,
} from './redux/thunks';
import ImportDescription from './components/ImportDescription';
import BreadcrumbRouter from '../../../../../../common/components/BreadcrumbRouter';
import './AssociationPlayersImport.less';
import { getIsBaseball } from '../../../../../../redux/app-v2/merchant/selectors';

const createContentMarkup = (content: any) => {
  return { __html: content };
};
const AssociationPlayersImport = () => {
  const dispatch = useDispatch<AppDispatch>();
  const AssociationPlayersImportTasks = useSelector(
    getAssociationPlayersImportTaskList
  );
  const pageNumber = useSelector(getAssociationPlayersImportTasksPageNumber);
  const pageSize = useSelector(getAssociationPlayersImportTasksPageSize);
  const total = useSelector(getAssociationPlayersImportTasksTotal);
  const columns: any = [
    {
      title: '时间',
      width: 140,
      dataIndex: 'createdTime',
      key: 'time',
      align: 'center',
    },
    {
      title: '操作人',
      width: 120,
      dataIndex: 'operatorName',
      key: 'name',
      align: 'center',
      render: (name: any) => (
        <div dangerouslySetInnerHTML={createContentMarkup(name)} />
      ),
    },
    {
      title: '状态',
      width: 120,
      dataIndex: 'status',
      key: 'archived',
      align: 'center',
      render: (status: any) => (
        <Tag
          color={
            status === 'imported'
              ? 'success'
              : status === 'failed'
              ? 'error'
              : 'processing'
          }
        >
          {status === 'imported'
            ? '已导入'
            : status === 'failed'
            ? '导入失败'
            : '导入中'}
        </Tag>
      ),
    },
    {
      title: '文件(含错误提示)',
      width: 120,
      dataIndex: 'fileUrl',
      key: 'remarkName',
      align: 'center',
      render: (_: any, record: any) =>
        record.status !== 'pending' ? (
          <a href={record.fileUrl}>点击下载</a>
        ) : null,
    },
  ];

  useMount(() => {
    dispatch(loadAssociationPlayersImportTasks({}));
  });

  const isBaseball = useSelector(getIsBaseball);
  const playerText = isBaseball ? '球员' : '运动员';

  return (
    <ContentDetail
      title={`注册${playerText} / 表格导入`}
      headerLeft={
        <BreadcrumbRouter
          routes={[
            {
              path: 'association/players',
              breadcrumbName: `注册${playerText}`,
            },
            {
              path: 'import',
              breadcrumbName: '表格导入',
            },
          ]}
        />
      }
    >
      <div className="AssociationPlayersImport content__filter">
        <ImportDescription />
        <TableResponsiveFrame
          columns={columns}
          pageNumber={pageNumber}
          pageSize={pageSize}
          data={AssociationPlayersImportTasks}
          dataItemsTotal={total}
          onChangePageSize={async (_: number, pageSize: number) => {
            await dispatch(
              updateAssociationPlayersImportTasksPageSize(pageSize)
            );
          }}
          onChangePageNumber={async (pageNumber: number) => {
            await dispatch(
              updateAssociationPlayersImportTasksPageNumber(pageNumber)
            );
          }}
          onRowClick={(record: any) => {
            return record.id ? record.id + Date.now() : 1;
          }}
        />
      </div>
    </ContentDetail>
  );
};

export default AssociationPlayersImport;
