import _get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../../service/constants';
import { getEmployeeById as getEmployeeEntityById } from '../../../../../redux/entities/employees/selectors';

export const getEmployeeDetailId = (state: any) =>
  _get(state.employee, 'details.data.id', null);

export const getEmployeeChangedDetail = (state: any) =>
  _get(state.employee, 'details.data', {});

export const getLoadEmployeeDetailRequestStatus = (state: any) =>
  _get(state.employee, 'details.loadRequestStatus', '');

export const getEmployeeEntityDetail = (state: any) => {
  const id = _get(state.employee, 'details.data.id', {});

  return getEmployeeEntityById(state, id);
};

export const getEmployeeDetail = createSelector(
  getEmployeeChangedDetail,
  getEmployeeEntityDetail,
  (changedDetail, originalDetail) => {
    return { ...originalDetail, ...changedDetail };
  }
);

export const getEmployeeDetailName = createSelector(
  getEmployeeChangedDetail,
  getEmployeeEntityDetail,
  (changedDetail, originalDetail) => {
    const { name } = changedDetail || {};
    const { name: originalDetailName } = originalDetail || {};

    return name || originalDetailName;
  }
);

export const getEmployeeDetailPhone = createSelector(
  getEmployeeChangedDetail,
  getEmployeeEntityDetail,
  (changedDetail, originalDetail) => {
    const { phone } = changedDetail || {};
    const { phone: originalDetailPhone } = originalDetail || {};

    return phone || originalDetailPhone;
  }
);

export const getEmployeeDetailStoreDisplayName = createSelector(
  getEmployeeChangedDetail,
  getEmployeeEntityDetail,
  (changedDetail, originalDetail) => {
    const { storeDisplayName } = changedDetail || {};
    const { storeDisplayName: originalDetailStoreDisplayName } =
      originalDetail || {};

    return storeDisplayName || originalDetailStoreDisplayName;
  }
);

export const getIsEmployeeDetailRequestLoaded = createSelector(
  getLoadEmployeeDetailRequestStatus,
  (loadRequestStatus) => loadRequestStatus === API_REQUEST_STATUS.FULFILLED
);

export const getEmployeeDetailCanOperateSensitiveAssets = createSelector(
  getEmployeeChangedDetail,
  getEmployeeEntityDetail,
  (changedDetail, originalDetail) => {
    const { canOperateSensitiveAssets } = changedDetail || {};
    const {
      canOperateSensitiveAssets: originalDetailCanOperateSensitiveAssets,
    } = originalDetail || {};

    return canOperateSensitiveAssets || originalDetailCanOperateSensitiveAssets;
  }
);
