import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { RootState } from '../../../common/utils/types';
import { actions as auditsActions } from './index';
import { loadStoresV2 as loadEntitiesStores } from '../../../redux/entities/stores/thunks';
import { loadEmployeesV2 as loadEntitiesEmployees } from '../../../redux/entities/employees/thunks';
import { getTicketList } from '../../../service/http';
import {
  getMerchantAssetsOperationsFilterOptions,
  getAssetsSetting,
  getPlayersList,
} from './api-request';
import {
  getAssetsDictionary,
  getPageSize,
  getSelectedStoreKeys,
} from './selectors';
import { getMerchantName } from '../../userSettings/Settings/redux/selectors';

const transferFromPaperTicketMap: { [key: string]: string } = {
  buy: '购买',
  use: '消费',
  fromPhysical: '从实体转移',
  toPhysical: '转移到实体箭票',
  add: '添加',
  cancel: '取消',
  reward: '奖励',
  remedy: '补救措施',
  import: '导入',
};

const backupTicketMap: { [key: string]: string } = {
  '62e6ac143cc0071b7893a26b': '手动添加会员',
  '62db7dd1c35cd32546b85e03': '手动添加会员',
  '62e6ac143cc0071b7893a26a': '会员卡',
  '62db7dd1c35cd32546b85e02': '会员卡',
  '630780df69b136131da3511d': '券码',
  '630cbe490b4012e2c71aacda': '券码',
};

export const getTheAssets = createAsyncThunk(
  'audits/getAssets',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState();
      const merchantName = getMerchantName(state);
      const res: any = await getAssetsSetting({ merchantName });
      if (res.message === 'OK') {
        // 将资产类型数组中的id作为key，资产类型名称作为value，存入redux
        const assetsDictionary = res.data.types.reduce(
          (acc: { [key: string]: string }, cur: any) => {
            acc[cur.id] = cur.type;
            return acc;
          },
          {}
        );
        if (process.env.REACT_APP_ENV === 'test') {
          assetsDictionary['62db7dd1c35cd32546b85e03'] = '手动添加会员';
          assetsDictionary['62db7dd1c35cd32546b85e02'] = '会员卡';
          assetsDictionary['630780df69b136131da3511d'] = '券码';
        } else {
          assetsDictionary['62e6ac143cc0071b7893a26b'] = '手动添加会员';
          assetsDictionary['62e6ac143cc0071b7893a26a'] = '会员卡';
          assetsDictionary['630cbe490b4012e2c71aacda'] = '券码';
        }
        dispatch(auditsActions.setAssetsDictionary(assetsDictionary));
      }
    } catch (e) {
      console.error('get stores error', e);
      throw e;
    }
  }
);

export const requestTableData = createAsyncThunk(
  'audits/requestTableData',
  async (
    param: {
      from: number;
      to: number;
      offset: number;
      limit: number;
      assetsTypeId: string;
      event: string;
      operatorId: string;
      storeId: string;
      playerId: string;
    },
    { dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);
      var assetsDictionary: { [key: string]: string } =
        getAssetsDictionary(state);
      if (Object.keys(assetsDictionary).length === 0) {
        const res: any = await getAssetsSetting({ merchantName });
        if (res.message === 'OK') {
          // 将资产类型数组中的id作为key，资产类型名称作为value，存入redux
          assetsDictionary = res.data.types.reduce(
            (acc: { [key: string]: string }, cur: any) => {
              acc[cur.id] = cur.type;
              return acc;
            },
            {}
          );
        }
      }
      const list: any = await getTicketList(param);
      dispatch(
        auditsActions.setTableData(
          list.data.map((data: any) => {
            let newData = data;
            newData.operateTime = moment(data.operateTime).format(
              'YYYY/MM/DD HH:mm:ss'
            );
            newData.key = data.id;
            newData.event = transferFromPaperTicketMap[data.event];
            newData.assetsType =
              assetsDictionary[data.assetsTypeId] === undefined
                ? backupTicketMap[data.assetsTypeId]
                : assetsDictionary[data.assetsTypeId];
            newData.isConfirmed = data.isConfirmed ? data.isConfirmed : false;
            newData.confirmatorName = data.confirmatorName
              ? data.confirmatorName
              : '';
            newData.operatorName = data.operatorName
              ? data.operatorName
              : '系统';
            return newData;
          })
        )
      );
      dispatch(auditsActions.setTotal(list.pagination.total));
      if (list.pagination.end) {
        const state = getState() as RootState;
        const pageSize = getPageSize(state);
        const newPage = Math.ceil(list.pagination.total / pageSize);
        newPage === 0
          ? dispatch(auditsActions.setPaginateNum(1))
          : dispatch(auditsActions.setPaginateNum(newPage));
      }
    } catch (e) {
      console.error('request table data error', e);
      throw e;
    }
  }
);

export const updateSelectedStoreKeys = createAsyncThunk(
  'audits/updateSelectedStoreKeys',
  async (keys: any, { getState }) => {
    const state = getState() as RootState;
    const selectedStoreKeys: string[] = getSelectedStoreKeys(state);

    return keys.filter((item: string) => {
      if (!selectedStoreKeys.includes(item)) {
        return item;
      }
    });
  }
);

export const loadSearchingPlayers = createAsyncThunk(
  'audits/loadSearchingPlayers',
  async (keyword: string, { getState }) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);
      const result = await getPlayersList(merchantName, {
        search: keyword,
        offset: 0,
        limit: 10,
      });

      return result;
    } catch (error) {
      throw error;
    }
  }
);

export const mounted = createAsyncThunk(
  'audits/mounted',
  async (
    payload: {
      isManager: boolean;
      userBelongToStoreId: string;
    },
    { dispatch, getState }
  ) => {
    try {
      const state = getState();
      const merchantName = getMerchantName(state);

      const { data }: any = await getMerchantAssetsOperationsFilterOptions({
        merchantName,
      });
      dispatch(loadSearchingPlayers(''));

      await dispatch(
        loadEntitiesEmployees({
          storeId: payload.userBelongToStoreId,
          offset: 0,
          limit: 500,
        })
      );

      if (payload.isManager) {
        const storesInfo: any = await dispatch(
          loadEntitiesStores({
            offset: 0,
            limit: 500,
          })
        );

        return {
          auditOptions: data,
          storesInfo,
        };
      }

      await dispatch(updateSelectedStoreKeys([payload.userBelongToStoreId]));

      return {
        auditOptions: data,
      };
    } catch (e) {
      console.error('audit error', e);

      throw e;
    }
  }
);
