import { get } from '../../../service/api';
import { saveLocaleData } from '../../../service/local-api-request';

export const postUserLogout = async (
  payload: { merchantName: string },
  options?: { isShow: false }
) => {
  try {
    const { merchantName } = payload;
    const result = await get(
      `/merchants/${merchantName}/employees.logout`,
      {},
      {},
      options
    );

    return result;
  } catch (e) {
    throw new Error('user login failed');
  }
};

export const getUserById = async (
  payload: { merchantName: string; id: string },
  options?: { isShow: false }
) => {
  try {
    const { merchantName, id } = payload;
    const result = await get(
      `/merchants/${merchantName}/employees/${id}`,
      {},
      {},
      options
    );

    return result;
  } catch (e) {
    throw new Error('get user info failed');
  }
};

export const getUserRoleDetailById = async (
  payload: {
    merchantName: string;
    id: string;
  },
  options?: { isShow: false }
) => {
  try {
    const { merchantName, id } = payload;
    const result = await get(
      `/merchants/${merchantName}/roles/${id}`,
      {},
      {},
      options
    );

    return result;
  } catch (e) {
    throw new Error('get user info failed');
  }
};

export const saveLocaleAuth = (authorization: string) =>
  saveLocaleData('long-term', 'Auth', authorization);

export const saveLocaleUser = (user: object) =>
  saveLocaleData('long-term', 'user', user);
