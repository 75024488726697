// ！！！deprecation file in future
// ！！！please don't add anything here
export const GET_WX_QR = (merchantName: string) =>
  '/merchants/' + merchantName + '/wxacode';
export const GET_TiCKT_LIST = (merchantName: string) =>
  '/merchants/' + merchantName + '/assets/operations';
export const CHECK_TiCKT = (merchantName: string) =>
  '/merchants/' + merchantName + '/assets/operations/';

export const GET_POSTER = (merchantName: string, id: string) =>
  '/merchants/' + merchantName + '/posters/' + id;
export const DELETE_POSTER = (merchantName: string, id: string) =>
  '/merchants/' + merchantName + '/posters/' + id;

export const POST_CARDS_IMAGE = (merchantName: string, type: string) =>
  '/merchants/' +
  merchantName +
  '/assets/member-cards/setting/types/' +
  type +
  '/pic';

export const GET_ASSETS = (merchantName: string) =>
  '/merchants/' + merchantName + '/assets/setting';

export const GET_VOUCHER_SEARCH_LIST = (merchantName: string) =>
  '/merchants/' + merchantName + '/market/vouchers';

export const IMPORT_PLAYERS = (merchantName: string) =>
  '/merchants/' + merchantName + '/players/import/tasks';
