import { createSlice } from '@reduxjs/toolkit';
import { loadEnrollmentList, duplicateEnrollmentDetail } from './thunks';
import { API_REQUEST_STATUS } from '../../../../../../../service/constants';

const initialState = {
  tableDataSource: [],
  searchInputText: '',
  pageSize: 10,
  pageNumber: 0,
  total: 0,
  end: true,
  duplicateEnrollmentStatus: API_REQUEST_STATUS.IDLE,
};

export const { reducer, actions } = createSlice({
  name: 'enrollmentList',
  initialState,
  reducers: {
    updatePageNumber: (state: any, action: any) => {
      state.pageNumber = action.payload;
    },
    updatePageSize: (state: any, action: any) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: {
    [loadEnrollmentList.fulfilled.type]: (state, { payload }) => {
      const { data, pagination } = payload || {};

      state.tableDataSource = data;
      state.total = pagination?.total || 0;
    },
    [duplicateEnrollmentDetail.pending.type]: (state: any) => {
      state.duplicateEnrollmentStatus = API_REQUEST_STATUS.PENDING;
    },
    [duplicateEnrollmentDetail.fulfilled.type]: (state: any) => {
      state.duplicateEnrollmentStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [duplicateEnrollmentDetail.rejected.type]: (state: any) => {
      state.duplicateEnrollmentStatus = API_REQUEST_STATUS.REJECTED;
    },
  },
});

export default reducer;
