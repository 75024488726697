import _isEmpty from 'lodash/isEmpty';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import {
  loadUserAndRoleById,
  saveUserInfoToLocale,
} from '../../../redux/app-v2/user/thunks';
import { actions as userActions } from '../../../redux/app-v2/user';
import {
  getUserIsLoginStatus,
  getIsManager,
  getIsOwner,
  getIsOperationsManager,
} from '../../../redux/app-v2/user/selectors';
import {
  loadMerchantInfo,
  loadMerchantSubscription,
} from '../../../redux/app-v2/merchant/thunks';
import { actions as merchantActions } from '../../../redux/app-v2/merchant';
import {
  getMerchantName,
  getIsAssociationMerchant,
} from '../../../redux/app-v2/merchant/selectors';
import { getCurrentHash } from './selectors';
import { actions as featuresSettingActions } from '../../../redux/app-v2/featuresSetting';
import { loadTheFeaturesSetting } from '../../../redux/app-v2/featuresSetting/thunks';
import { postLogout } from './api-request';
import { RootState } from '../../../common/utils/types';

/**
 * app level mounted
 */
export const appMounted = createAsyncThunk(
  'app/mounted',
  async (
    {
      navigateToLoginPage,
      navigateToDashboardPage,
      navigateToFeaturesPage,
      navigateToStoresPage,
    }: {
      navigateToLoginPage: Function;
      navigateToDashboardPage: Function;
      navigateToFeaturesPage: Function;
      navigateToStoresPage: Function;
    },
    { dispatch, getState }
  ) => {
    // initial user info
    // initial merchant info
    // initial merchant subscription info
    // check user login status
    // load user info
    // load merchant info
    await dispatch(userActions.userSet());
    await dispatch(merchantActions.merchantSet());

    const state: any = getState();
    const isLogin = getUserIsLoginStatus(state);
    const currentHash = getCurrentHash(state);

    if (isLogin) {
      await dispatch(loadUserAndRoleById());
      await dispatch(loadMerchantInfo());
      dispatch(loadMerchantSubscription());
      await dispatch(loadTheFeaturesSetting());

      const isManager = getIsManager(getState() as RootState);
      const isOwner = getIsOwner(getState() as RootState);
      const isOperation = getIsOperationsManager(getState() as RootState);
      const isAssociationMerchant = getIsAssociationMerchant(
        getState() as RootState
      );

      if (_isEmpty(currentHash)) {
        isManager || isOwner || isOperation
          ? isAssociationMerchant || isOperation
            ? navigateToFeaturesPage()
            : navigateToDashboardPage()
          : navigateToStoresPage();

        return;
      }
    } else {
      navigateToLoginPage();
    }
  }
);

/**
 * Logout user
 */
export const userLogout = createAsyncThunk(
  'index/userLogout',
  async (navigateToLoginPage: Function, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);

      await postLogout({ merchantName });
      dispatch(featuresSettingActions.featureSettingReset());
      dispatch(userActions.userReset());
      dispatch(merchantActions.merchantRest());
      dispatch(
        saveUserInfoToLocale({
          id: '',
          authorization: '',
          isLogin: false,
        })
      );

      navigateToLoginPage();
    } catch (e) {
      notification.error({ message: '登出失败', description: '服务器开小差' });
    }
  }
);
