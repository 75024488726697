/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Progress, Select, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Line } from '@ant-design/plots';
import { AppDispatch } from '../../common/utils/types';
import ContentDetail from '../../common/components/ContentDetail';
import StatisticCard from './components/StatisticCard';
import {
  getDashboardCardPaidPlayers,
  getDashboardCardRegisteredPlayers,
  getDashboardTodayPurchasedCardPlayerCount,
  getDashboardNewTodayAndCardRegisteredPlayers,
  getDashboardTodayActivePlayerCount,
  getDashboardDailyNewPlayerReports,
  getDashboardStoreList,
  getSelectedStore,
  getIsSelectedAllStoresDashboard,
} from './redux/selectors';
import { getMerchantName } from '../../redux/app-v2/merchant/selectors';
import { actions as dashboardActions } from './redux';
import { loadReport, loadDashboardStores } from './redux/thunks';
import './index.less';

const Dashboard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const merchantName = useSelector(getMerchantName);
  const cardPaidPlayers = useSelector(getDashboardCardPaidPlayers);
  const cardRegisteredPlayer = useSelector(getDashboardCardRegisteredPlayers);
  const todayPurchasedCardPlayerCount = useSelector(
    getDashboardTodayPurchasedCardPlayerCount
  );
  const newTodayAndCardRegisteredPlayers = useSelector(
    getDashboardNewTodayAndCardRegisteredPlayers
  );
  const todayActivePlayerCount = useSelector(
    getDashboardTodayActivePlayerCount
  );
  const dailyNewPlayerReports = useSelector(getDashboardDailyNewPlayerReports);
  const storeList = useSelector(getDashboardStoreList);
  const selectedStore = useSelector(getSelectedStore);
  const isSelectedAllStoresDashboard = useSelector(
    getIsSelectedAllStoresDashboard
  );

  const labelForSelectedStore = isSelectedAllStoresDashboard ? '' : '（本店）';
  // window height - 64px header - 100px content header - 231px player info - 44px padding - 56px section height
  const oneMonthCardPaidChartHeight = window.innerHeight - 164 - 231 - 44 - 56;

  const minCardPaidPlayerCount =
    dailyNewPlayerReports?.reduce(
      (lastDailyNewPlayerReport, nextDailyNewPlayerReport) =>
        lastDailyNewPlayerReport?.cardPaidPlayerCount <
        nextDailyNewPlayerReport?.cardPaidPlayerCount
          ? lastDailyNewPlayerReport
          : nextDailyNewPlayerReport,
      dailyNewPlayerReports[0]
    )?.cardPaidPlayerCount || 0;

  const originalMaxCardPaidPlayerCount =
    dailyNewPlayerReports?.reduce(
      (lastDailyNewPlayerReport, nextDailyNewPlayerReport) =>
        lastDailyNewPlayerReport?.cardPaidPlayerCount >
        nextDailyNewPlayerReport?.cardPaidPlayerCount
          ? lastDailyNewPlayerReport
          : nextDailyNewPlayerReport,
      dailyNewPlayerReports[0]
    )?.cardPaidPlayerCount || 100;
  const maxCardPaidPlayerCount =
    originalMaxCardPaidPlayerCount === minCardPaidPlayerCount
      ? originalMaxCardPaidPlayerCount + 5
      : originalMaxCardPaidPlayerCount;
  // TODO: optimization merchant & user 加载顺序问题
  useEffect(() => {
    if (merchantName) {
      dispatch(loadDashboardStores());
    }
  }, [dispatch, merchantName]);

  useEffect(() => {
    if (selectedStore.id) {
      dispatch(loadReport());
    }
  }, [dispatch, selectedStore.id]);

  return (
    <ContentDetail
      className="dashboard"
      title="状态概况"
      headerRightClassName="dashboardHeaderRight"
      headerRight={
        <>
          <Button className="insertButton">
            <DownOutlined />
            <span>{selectedStore.displayName}</span>
          </Button>
          <Select
            className="dashboardStoreListButton"
            placeholder="选择一个门店"
            value={selectedStore.id}
            onChange={(id) => {
              dispatch(dashboardActions.storeSelected(id));
            }}
            onPopupScroll={(event) => {
              //TODO: when store is so more should load more stores
            }}
          >
            {storeList
              .filter((id: string) => !!id)
              .map((store: any) => (
                <Select.Option
                  className="dashboardStoreItem"
                  key={store.id}
                  value={store.id}
                >
                  {store.displayName}
                </Select.Option>
              ))}
          </Select>
        </>
      }
    >
      <div className="dashboardSubContainer dashboardReportListContainer">
        <h3 className="dashboardSubtitle">重要指标</h3>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <StatisticCard
              title={`已购卡会员总数${labelForSelectedStore}`}
              value={cardPaidPlayers}
              color="#4FC7E4"
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <StatisticCard
              title="注册会员总数"
              value={cardRegisteredPlayer}
              color="#F97D96"
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <StatisticCard
              title={`今日购卡会员${labelForSelectedStore}`}
              value={todayPurchasedCardPlayerCount}
              color="#3BB888"
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <StatisticCard
              title="今日注册会员"
              value={newTodayAndCardRegisteredPlayers}
              color="#F3A811"
            />
          </Col>
        </Row>
      </div>
      <div className="dashboardMembersInfoContainer flex">
        <div className="dashboardCardPaidTrendContainer dashboardSubContainer flex flex-column flex__fluid-content">
          <div className="flex flex-middle flex-space-between">
            <h3 className="dashboardSubtitle">
              已购卡会员增长{labelForSelectedStore}
            </h3>
            <Select
              className="dashboardCardPaidChartContainer"
              defaultValue="1"
            >
              <Select.Option value="1">1个月</Select.Option>
            </Select>
          </div>
          <div className="flex__fluid-content">
            <Line
              height={oneMonthCardPaidChartHeight}
              data={dailyNewPlayerReports}
              padding="auto"
              color="#4fc7e4"
              xField="date"
              yField="cardPaidPlayerCount"
              yAxis={{
                min: minCardPaidPlayerCount,
                max: maxCardPaidPlayerCount,
                tickCount: 5,
              }}
              meta={{
                date: {
                  alias: '日期',
                },
                cardPaidPlayerCount: {
                  alias: '已购会员卡人数',
                },
              }}
              smooth
            />
          </div>
        </div>
        <div className="dashboardTodayVisitorContainer dashboardSubContainer flex__shrink-fixed flex flex-column">
          <h3 className="dashboardSubtitle">
            今日到访会员数{labelForSelectedStore}
          </h3>
          <div className="dashboardTodayVisitorContent flex flex-column">
            <Progress
              type="dashboard"
              strokeColor={{
                '0%': '#BCECBC',
                '100%': '#4FC7E4',
              }}
              strokeWidth={10}
              width={194}
              percent={todayActivePlayerCount}
              format={() => {
                return (
                  <span className="dashboardTodayVisitorCount">
                    {todayActivePlayerCount}
                  </span>
                );
              }}
            />
            <span className="dashboardTodayVisitorPrompt">
              到访会员数：今日有权益操作的会员
            </span>
          </div>
        </div>
      </div>
    </ContentDetail>
  );
};

export default Dashboard;
