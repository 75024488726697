import { createSlice } from '@reduxjs/toolkit';

type Player = {
  id: string;
  memberCardPaid: boolean;
  name: string;
  phone: string;
  remarkName: string;
  birthday?: string;
  remarks?: string;
};

type PlayerMappingType = {
  [key: string]: Player;
};

const initialState: PlayerMappingType = {};

export const { reducer, actions } = createSlice({
  name: 'entities/players',
  initialState,
  reducers: {
    updatePlayerMapping: (
      state: { [x: string]: any },
      action: { payload: any }
    ) => {
      const list = action.payload;
      const players: any = state;

      list.forEach((player: { id: string }) => {
        const { id } = player || {};

        if (state[id]) {
          players[id] = { ...state[id], ...player };
        } else if (id) {
          players[id] = player;
        }
      });

      return players;
    },
    updatePlayerById: (
      state: { [x: string]: any },
      action: { payload: { id: string } }
    ) => {
      const player = action.payload;
      const { id } = action.payload || {};

      if (state[id]) {
        state[id] = { ...state[id], ...player };
      } else if (id) {
        state[id] = player;
      }

      return state;
    },
  },
});

export default reducer;
