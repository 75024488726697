import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Collapse, InputNumber, List, Radio, Skeleton } from 'antd';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  HomeOutlined,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { AppDispatch } from '../../../../../common/utils/types';
import { CURRENCY_SYMBOLS } from '../../../../../common/constants';
import {
  PRICE_PLAN_TYPES_MAPPING,
  PRICE_PLAN_LEVEL,
} from '../../utils/constants';
import {
  getMerchantSubscriptionPricePlanId,
  getMerchantSubscriptionAddonStoreCount,
  getIsMerchantSubscriptionInitialized,
  getMerchantSubscriptionPricePlanType,
} from '../../../../../redux/app-v2/merchant/selectors';
import {
  createPreviewOrder,
  loadPricePlanList,
  updateActivePricePlanId,
  updateAddonStoreCount,
} from '../../redux/thunks';
import {
  getSubscriptionPricePlanList,
  getSubscriptionActivePricePlanId,
  getSubscriptionLoadPricePlanCompleted,
} from '../../redux/selectors';
import './SubscriptionPlan.less';

const PlanFunctions = ({
  unAvailableIndexList = [],
}: {
  unAvailableIndexList?: number[];
}) => {
  const functions = [
    '双端小程序，管理后台',
    '会员，员工，权益管理',
    '对战，排名，活动报名',
    '兑换商城',
    '独家小程序',
  ];

  return (
    <List
      itemLayout="horizontal"
      dataSource={functions}
      renderItem={(functionName) => {
        const index = functions.indexOf(functionName);
        const isUnavailable = unAvailableIndexList.includes(index);

        return (
          <List.Item className="SubscriptionPlanFunction">
            <List.Item.Meta
              className="SubscriptionPlanFunctionMeta"
              avatar={
                isUnavailable ? (
                  <CloseCircleFilled style={{ color: '#BAE7FF' }} />
                ) : (
                  <CheckCircleFilled style={{ color: '#4FC7E4' }} />
                )
              }
              description={functionName}
            />
          </List.Item>
        );
      }}
    />
  );
};

const PlanStores = ({
  id,
  addonStoreCount,
  limitedStoreCount,
}: {
  id: string;
  addonStoreCount: number;
  limitedStoreCount: number;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  // Must compare with current price plan id, because the price plan id will be updated after paid
  const merchantSubscriptionPricePlanId = useSelector(
    getMerchantSubscriptionPricePlanId
  );
  const merchantSubscriptionAddonStoreCount = useSelector(
    getMerchantSubscriptionAddonStoreCount
  );
  const addonStoreCountDisabled = merchantSubscriptionPricePlanId === id;
  const minusAddonStoreCountDisabled =
    merchantSubscriptionPricePlanId === id ||
    addonStoreCount <= merchantSubscriptionAddonStoreCount;
  const increaseAddonStoreCountDisabled =
    merchantSubscriptionPricePlanId === id ||
    (addonStoreCount >= limitedStoreCount && limitedStoreCount !== 0);

  return (
    <div className="SubscriptionPlanStore flex flex-top flex-space-between">
      <div className="flex flex-top flex__fluid-container">
        <HomeOutlined className="SubscriptionPlanStoreIcon" />
        <div className="SubscriptionPlanStoreLabelContainer">
          <h3 className="SubscriptionPlanStoreLabel flex flex-middle">
            附加门店
          </h3>
          <dd className="SubscriptionPlanStoreHint">计划已包含1家门店</dd>
        </div>
      </div>
      {limitedStoreCount !== 1 ? (
        <InputNumber
          className="SubscriptionPlanStoreInput"
          size="large"
          controls={false}
          disabled={addonStoreCountDisabled}
          min={0}
          max={limitedStoreCount === 0 ? undefined : limitedStoreCount}
          step={1}
          value={addonStoreCount}
          onChange={async (value) => {
            const currentAddonStoreCount = parseInt(
              value.toString() as string,
              10
            );

            if (!isNaN(currentAddonStoreCount) && currentAddonStoreCount >= 0) {
              await dispatch(updateAddonStoreCount(currentAddonStoreCount));
            }
          }}
          addonBefore={
            <Button
              className="SubscriptionPlanStoreAddonButton"
              type="text"
              disabled={minusAddonStoreCountDisabled}
              onClick={async () => {
                await dispatch(updateAddonStoreCount(addonStoreCount - 1));
              }}
            >
              <MinusOutlined />
            </Button>
          }
          addonAfter={
            <Button
              className="SubscriptionPlanStoreAddonButton"
              type="text"
              disabled={increaseAddonStoreCountDisabled}
              onClick={async () => {
                await dispatch(updateAddonStoreCount(addonStoreCount + 1));
              }}
            >
              <PlusOutlined />
            </Button>
          }
          defaultValue={1}
        />
      ) : null}
    </div>
  );
};

const SubscriptionPlan = () => {
  const dispatch = useDispatch<AppDispatch>();
  const pricePlanList = useSelector(getSubscriptionPricePlanList);
  const activePricePlanId = useSelector(getSubscriptionActivePricePlanId);
  // Init plan to use current price plan id and addon store count, because the price plan id will be updated after paid
  const merchantSubscriptionPricePlanId = useSelector(
    getMerchantSubscriptionPricePlanId
  );
  const merchantSubscriptionAddonStoreCount = useSelector(
    getMerchantSubscriptionAddonStoreCount
  );
  const merchantSubscriptionPricePlanType = useSelector(
    getMerchantSubscriptionPricePlanType
  );
  const isMerchantSubscriptionInitialized = useSelector(
    getIsMerchantSubscriptionInitialized
  );
  const isLoadPricePlanCompleted = useSelector(
    getSubscriptionLoadPricePlanCompleted
  );
  const subscriptionPlanPanelKeyPrefix = 'subscription-plan-';

  useEffect(() => {
    if (isMerchantSubscriptionInitialized && merchantSubscriptionPricePlanId) {
      dispatch(loadPricePlanList());
      dispatch(
        createPreviewOrder({
          planId: merchantSubscriptionPricePlanId,
          addonStoreCount: merchantSubscriptionAddonStoreCount,
        })
      );
    }
  }, [
    dispatch,
    isMerchantSubscriptionInitialized,
    merchantSubscriptionPricePlanId,
    merchantSubscriptionAddonStoreCount,
  ]);

  if (!isLoadPricePlanCompleted) {
    return (
      <>
        <Skeleton active avatar />
        <Skeleton active avatar />
        <Skeleton active avatar />
      </>
    );
  }

  return (
    <Collapse
      ghost
      accordion
      className="SubscriptionPlan"
      activeKey={[`${subscriptionPlanPanelKeyPrefix}${activePricePlanId}`]}
      expandIcon={({ isActive, collapsible }) => (
        <Radio
          checked={isActive}
          disabled={collapsible === 'disabled'}
          className="SubscriptionPlanRadio"
        />
      )}
      onChange={async (key: string | string[]) => {
        if (typeof key === 'string') {
          const selectedId = key.replace(subscriptionPlanPanelKeyPrefix, '');

          await dispatch(updateActivePricePlanId(selectedId));
        }
      }}
    >
      {pricePlanList.map((subscriptionPricePlan: any) => {
        const {
          id,
          type,
          prices,
          addonStoreCount,
          addonStorePrices,
          limitedStoreCount,
        } = subscriptionPricePlan || {};
        const { value: basePrice, currency: basePriceCurrency } =
          prices?.[0] || {};
        const { value: addonOneStorePriceValue, currency: addonStoreCurrency } =
          addonStorePrices?.[0] || {};
        const typeMapping =
          PRICE_PLAN_TYPES_MAPPING[type] &&
          PRICE_PLAN_TYPES_MAPPING[type](
            addonOneStorePriceValue,
            addonStoreCurrency
          );
        const collapsible =
          PRICE_PLAN_LEVEL[type] <
          PRICE_PLAN_LEVEL[merchantSubscriptionPricePlanType]
            ? 'disabled'
            : undefined;

        if (!PRICE_PLAN_TYPES_MAPPING[type]) {
          return null;
        }

        return (
          <Collapse.Panel
            key={`${subscriptionPlanPanelKeyPrefix}${id}`}
            className="SubscriptionPlanPanel"
            collapsible={collapsible}
            header={
              <div className="flex flex-top flex-space-between">
                <dl className="SubscriptionPlanHeaderDescriptionList">
                  <dt className="SubscriptionPlanTitle">{typeMapping.name}</dt>
                  <dd className="SubscriptionPlanDescription">
                    {typeMapping.description}
                  </dd>
                </dl>
                <dl className="SubscriptionPlanHeaderDescriptionList flex flex-column flex-bottom">
                  <dt className="SubscriptionPlanPrice">
                    {CURRENCY_SYMBOLS[basePriceCurrency]}
                    {basePrice}
                  </dt>
                  <dd className="SubscriptionPlanUnit">{typeMapping.period}</dd>
                </dl>
              </div>
            }
          >
            <div className="SubscriptionPlanFunctionContainer">
              <PlanFunctions
                unAvailableIndexList={typeMapping.unAvailableIndexList}
              />
            </div>
            {limitedStoreCount >= 0 ? (
              <PlanStores
                id={id}
                addonStoreCount={addonStoreCount}
                limitedStoreCount={limitedStoreCount}
              />
            ) : null}
          </Collapse.Panel>
        );
      })}
    </Collapse>
  );
};

export default SubscriptionPlan;
