import { createSlice } from '@reduxjs/toolkit';
import { PricePlanType, PricePlanMappingType } from './types';

const initialState: PricePlanMappingType = {};

export const { reducer, actions } = createSlice({
  name: 'entities/pricePlans',
  initialState,
  reducers: {
    updatePricePlanMapping: (
      state: PricePlanMappingType,
      action: { payload: PricePlanType[] }
    ) => {
      const list = action.payload;
      const pricePlans: PricePlanMappingType = state;

      list.forEach((pricePlan: PricePlanType) => {
        const { id } = pricePlan || {};

        if (state[id]) {
          pricePlans[id] = { ...state[id], ...pricePlan };
        } else if (id) {
          pricePlans[id] = pricePlan;
        }
      });

      return pricePlans;
    },
    updatePricePlanById: (
      state: PricePlanMappingType,
      action: { payload: PricePlanType }
    ) => {
      const pricePlan = action.payload;
      const { id } = action.payload || {};

      if (state[id]) {
        state[id] = { ...state[id], ...pricePlan };
      } else if (id) {
        state[id] = pricePlan;
      }

      return state;
    },
  },
  extraReducers: {},
});

export default reducer;
