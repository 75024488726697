import { createSlice } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../../service/constants';
import { ForgetPasswordStateTypes } from './types';
import { passwordReset } from './thunks';

const initialState: ForgetPasswordStateTypes = {
  merchantName: '',
  accountName: '',
  password: '',
  otp: '',
  updatedPasswordStatus: API_REQUEST_STATUS.IDLE,
  createOtpStatus: API_REQUEST_STATUS.IDLE,
};

export const { reducer, actions } = createSlice({
  name: 'forgetPassword',
  initialState,
  reducers: {
    dataRest: (state) => {
      state.merchantName = '';
      state.accountName = '';
      state.password = '';
      state.otp = '';
      state.updatedPasswordStatus = API_REQUEST_STATUS.IDLE;
      state.createOtpStatus = API_REQUEST_STATUS.IDLE;
    },
    merchantNameUpdated: (state, { payload }) => {
      state.merchantName = payload;
    },
    accountNameUpdated: (state, { payload }) => {
      state.accountName = payload;
    },
    passwordUpdated: (state, { payload }) => {
      state.password = payload;
    },
    otpUpdated: (state, { payload }) => {
      state.otp = payload;
    },
  },
  extraReducers: {
    [passwordReset.pending.type]: (state) => {
      state.updatedPasswordStatus = API_REQUEST_STATUS.PENDING;
    },
    [passwordReset.fulfilled.type]: (state) => {
      state.updatedPasswordStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [passwordReset.rejected.type]: (state) => {
      state.updatedPasswordStatus = API_REQUEST_STATUS.REJECTED;
    },
  },
});

export default reducer;
