import { get } from '../../../../../../../service/api';

export const getEnrollmentClubs = async (
  enrollmentId: string,
  params: {
    offset: number;
    limit: number;
    text?: string;
    isSubmitted?: boolean;
  }
) => {
  try {
    const result: any = await get(
      `/association-enrolls/${enrollmentId}/forms`,
      params,
      {},
      {}
    );

    return result;
  } catch (error) {
    throw error;
  }
};

export const getEnrollmentClubsExport = async (
  enrollmentId: string,
  params: {
    isSubmitted?: boolean;
  }
) => {
  try {
    const result: any = await get(
      `/association-enrolls/${enrollmentId}/forms/export`,
      params,
      {},
      {}
    );

    return result;
  } catch (error) {
    throw error;
  }
};
