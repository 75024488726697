import { get } from '../../../../../../../../service/api';

export const getAssociationClubs = async (
  merchantName: string,
  payload: {
    offset: number;
    limit: number;
    text?: string;
    isMember?: boolean;
    isRegisteredMerchant?: boolean;
  }
) => {
  try {
    const { text, isRegisteredMerchant, isMember, offset, limit } = payload;
    const params = { isRegisteredMerchant, isMember, text, offset, limit };
    const result = await get(
      `/merchants/${merchantName}/association-clubs`,
      params,
      {},
      {}
    );

    return result;
  } catch (error) {
    throw error;
  }
};

export const getAssociationClubById = async (
  merchantName: string,
  id: string
) => {
  try {
    const result = await get(
      `/merchants/${merchantName}/association-clubs/${id}`,
      {}
    );

    return result;
  } catch (error) {
    throw error;
  }
};

export const getAssociationPlayers = async (
  merchantName: string,
  payload: {
    offset: number;
    limit: number;
    associationClubId?: string;
    text?: string;
    gender?: string;
  }
) => {
  try {
    const { text, gender, associationClubId, offset, limit } = payload;
    const params = { gender, text, associationClubId, offset, limit };
    const result = await get(
      `/merchants/${merchantName}/association-players`,
      params,
      {},
      {}
    );

    return result;
  } catch (error) {
    throw error;
  }
};
