import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Tag } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { AppDispatch } from '../../../../../../../../common/utils/types';
import { priceConvertToIntegerCents } from '../../../../../../../../common/utils/currency';
import {
  getSelectedGameOptionName,
  getSelectedGameOptionTeamPlayerCount,
  getSelectedGameOptionEnableChargePrice,
  getSelectedGameOptionPrice,
  getSelectedGameOptionAssociationPlayerPrice,
} from '../../redux/selectors';
import { actions as enrollmentDetailActions } from '../../redux';
import NormalOptionSettingModal from './components/NormalOptionSettingModal';

const GameOptionSettingDetailModal = (props: {
  id: string;
  disabled: boolean;
  name: string;
  saved: boolean;
  teamPlayerCount: number;
  enableChargePrice: boolean;
  price: number;
  associationPlayerPrice: number;
  isArchery: boolean;
  categoryOptions: object[];
  onDelete: () => void;
  onUpdate: (option: any) => void;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const selectedGameOptionName = useSelector(getSelectedGameOptionName);
  const selectedGameOptionTeamPlayerCount = useSelector(
    getSelectedGameOptionTeamPlayerCount
  );
  const selectedGameOptionEnableChargePrice = useSelector(
    getSelectedGameOptionEnableChargePrice
  );
  const selectedGameOptionPrice = useSelector(getSelectedGameOptionPrice);
  const selectedGameOptionAssociationPlayerPrice = useSelector(
    getSelectedGameOptionAssociationPlayerPrice
  );
  const {
    id,
    disabled,
    name,
    saved,
    teamPlayerCount,
    enableChargePrice,
    price,
    associationPlayerPrice,
    isArchery,
    categoryOptions,
    onDelete,
    onUpdate,
  } = props;
  const [
    gameOptionSettingDetailModalVisible,
    setGameOptionSettingDetailModalVisible,
  ] = useState(false);
  const handleUpdateGameOption = async () => {
    try {
      await form.validateFields();

      onUpdate({
        id,
        name: selectedGameOptionName,
        teamPlayerCount: selectedGameOptionTeamPlayerCount,
        enableChargePrice: selectedGameOptionEnableChargePrice,
        pricingInfo: {
          price: priceConvertToIntegerCents(selectedGameOptionPrice),
          associationPlayerPrice: priceConvertToIntegerCents(
            selectedGameOptionAssociationPlayerPrice
          ),
        },
      });
      setGameOptionSettingDetailModalVisible(false);
    } catch (error) {
      throw error;
    }
  };
  const resetData = async () => {
    await form.resetFields();
    dispatch(enrollmentDetailActions.resetSelectedGameOption());
  };
  const contentEl = (
    <Tag>
      <span className="EnrollmentDetailsOptionItemText">{name}</span>
      <span className="EnrollmentDetailsOptionItemText">{` (${teamPlayerCount}人)`}</span>
      {saved || isArchery ? null : <EditOutlined />}
    </Tag>
  );

  return (
    <>
      {saved ? (
        contentEl
      ) : (
        <>
          <Button
            type="text"
            size="small"
            disabled={disabled || isArchery}
            className="EnrollmentDetailsOptionItemButton"
            onClick={async () => {
              await dispatch(
                enrollmentDetailActions.updateSelectedGameOptionId(id)
              );
              await dispatch(
                enrollmentDetailActions.updateGameInfoCategoryOptionName(name)
              );
              await dispatch(
                enrollmentDetailActions.updateGameInfoCategoryOptionTeamPlayerCount(
                  teamPlayerCount
                )
              );
              await dispatch(
                enrollmentDetailActions.updateGameInfoCategoryOptionEnableChargePrice(
                  enableChargePrice
                )
              );
              await dispatch(
                enrollmentDetailActions.updateGameInfoCategoryOptionPrice(price)
              );
              await dispatch(
                enrollmentDetailActions.updateGameInfoCategoryOptionAssociationPlayerPrice(
                  associationPlayerPrice
                )
              );
              setGameOptionSettingDetailModalVisible(true);
            }}
          >
            {contentEl}
          </Button>
          {gameOptionSettingDetailModalVisible && (
            <NormalOptionSettingModal
              id={id}
              deletable={false}
              preserve={false}
              show={gameOptionSettingDetailModalVisible}
              data={{
                gameOptionName: selectedGameOptionName,
                gameOptionTeamPlayerCount: selectedGameOptionTeamPlayerCount,
                gameOptionEnableChargePrice:
                  selectedGameOptionEnableChargePrice,
                gameOptionPrice: selectedGameOptionPrice,
                gameOptionAssociationPlayerPrice:
                  selectedGameOptionAssociationPlayerPrice,
              }}
              categoryOptions={categoryOptions}
              onDelete={onDelete}
              onSave={handleUpdateGameOption}
              onReset={resetData}
              onClose={() => {
                setGameOptionSettingDetailModalVisible(false);
              }}
              onChangeGameInfoCategoryOptionName={(value) => {
                dispatch(
                  enrollmentDetailActions.updateGameInfoCategoryOptionName(
                    value
                  )
                );
              }}
              onChangeGameInfoCategoryOptionTeamPlayerCount={(value) => {
                dispatch(
                  enrollmentDetailActions.updateGameInfoCategoryOptionTeamPlayerCount(
                    value
                  )
                );
              }}
              onChangeGameInfoCategoryOptionEnableChargePrice={(value) => {
                dispatch(
                  enrollmentDetailActions.updateGameInfoCategoryOptionEnableChargePrice(
                    value
                  )
                );
              }}
              onChangeGameInfoCategoryOptionPrice={(value) => {
                dispatch(
                  enrollmentDetailActions.updateGameInfoCategoryOptionPrice(
                    value
                  )
                );
              }}
              onChangeGameInfoCategoryOptionAssociationPlayerPrice={(value) => {
                dispatch(
                  enrollmentDetailActions.updateGameInfoCategoryOptionAssociationPlayerPrice(
                    value
                  )
                );
              }}
            />
          )}
        </>
      )}
    </>
  );
};

GameOptionSettingDetailModal.displayName = 'GameOptionSettingDetailModal';

export default GameOptionSettingDetailModal;
