import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  ProfileOutlined,
  QuestionCircleFilled,
  SafetyCertificateOutlined,
} from '@ant-design/icons';
import {
  Button,
  Space,
  Input,
  Form,
  Select,
  Modal,
  Switch,
  DatePicker,
  DatePickerProps,
  Upload,
  message,
  Spin,
  FormInstance,
  notification,
  Tooltip,
  InputNumber,
  Radio,
} from 'antd';
import { AppDispatch } from '../../../../../../redux/store';
import { useMount, useUnmount } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { publicIp } from '../../../../../../service/apiIP';
import ContentDetail from '../../../../../../common/components/ContentDetail';
import BreadcrumbRouter from '../../../../../../common/components/BreadcrumbRouter';
import { getUserAuthorization } from '../../../../../../redux/app-v2/user/selectors';
import { getMerchantName } from '../../../../../../redux/app-v2/merchant/selectors';
import {
  ENROLLMENT_LIMITATION_TYPES,
  ENROLLMENT_LIMITATION_TYPES_MAPPING,
} from '../../utils/constants';
import {
  getEnrollmentDetailsData,
  getGameInfoData,
  getIsPosterListReload,
  getPosterList,
  getIsEnrollmentDetailsLoaded,
  getIsDisabledContent,
} from './redux/selectors';
import { actions as enrollmentDetailActions } from './redux';
import {
  addNewEnrollmentDetails,
  loadEnrollmentDetailsById,
  loadPosterList,
  updateEnrollmentDetailsById,
  loadArcheryCompetitionOptions,
} from './redux/thunks';
import EnrollmentClubFormSetting from './components/EnrollmentClubFormSetting';
import EnrollmentPlayerInfoSetting from './components/EnrollmentPlayerInfoSetting';
import GameOptionSetting from './components/GameOptionSetting';
import './EnrollmentDetail.less';
import './EnrollmentDetailsCategory.less';
import ImgCrop from 'antd-img-crop';

const EnrollmentDetails = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const enrollmentDetailsData = useSelector(getEnrollmentDetailsData);
  const gameInfoData = useSelector(getGameInfoData);
  const posterList = useSelector(getPosterList);
  const isPosterListReload = useSelector(getIsPosterListReload);
  const isEnrollmentDetailsLoaded = useSelector(getIsEnrollmentDetailsLoaded);
  const [isNew, setIsNew] = useState(false);
  const userAuthorization = useSelector(getUserAuthorization);
  const merchantName = useSelector(getMerchantName);
  const isDisabledContent = useSelector(getIsDisabledContent);
  const [isUploadingSkinImagePending, setIsUploadingSkinImagePending] =
    useState(false);
  const deadlineValue = enrollmentDetailsData.deadline
    ? moment(enrollmentDetailsData.deadline)
    : undefined;
  const imageContent = (
    <div className="memberCardModalImageContent">
      <img
        className="memberCardModalImage"
        src={enrollmentDetailsData.coverImageUrl}
        alt=""
      />
      <div
        className={`memberCardModalImageCover flex flex-column flex-middle flex-center${
          isUploadingSkinImagePending ? ' active' : ''
        }`}
      >
        {isUploadingSkinImagePending ? <Spin tip="正在上传图片..." /> : null}
      </div>
    </div>
  );
  const [images, setImages] = useState<any[]>(
    enrollmentDetailsData.coverImageUrl
      ? [
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: enrollmentDetailsData.coverImageUrl,
          },
        ]
      : []
  );

  useEffect(() => {
    enrollmentDetailsData.coverImageUrl &&
      form.setFieldsValue({
        coverImageUrl: enrollmentDetailsData.coverImageUrl,
      });
  }, [enrollmentDetailsData, enrollmentDetailsData.coverImageUrl, form]);

  useEffect(() => {
    const image = images[0];
    if (!image) {
      return;
    }
    const { status, uid, name, url } = image;
    switch (status) {
      case 'done':
        setIsUploadingSkinImagePending(false);
        message.success(`${name} 活动封面上传成功.`);
        dispatch(
          enrollmentDetailActions.updateEnrollmentDetailsData({
            coverImageUrl: url,
          })
        );
        break;
      case 'error':
        setIsUploadingSkinImagePending(false);
        message.error(`${name} 活动封面上传失败.`);
        break;
      case 'removed':
        setIsUploadingSkinImagePending(false);
        message.error(`${name} 活动封面已删除.`);
        dispatch(
          enrollmentDetailActions.updateEnrollmentDetailsData({
            coverImageUrl: null,
          })
        );
        break;
      case 'uploading':
        setIsUploadingSkinImagePending(true);
        break;
      case 'success':
        setIsUploadingSkinImagePending(false);
        break;
    }
  }, [dispatch, images]);

  const uploadImageAction = `${publicIp}/merchants/${merchantName}/association-enrolls/pic`;
  const uploadImageHeaders = {
    Authorization: userAuthorization,
  };
  const onChangeStartDate: DatePickerProps['onChange'] = (date) => {
    dispatch(
      enrollmentDetailActions.updateEnrollmentDetailsData({
        deadline: date?.toISOString(),
      })
    );
  };

  useMount(async () => {
    await dispatch(enrollmentDetailActions.resetAll());
    if (id) {
      await dispatch(loadEnrollmentDetailsById(id));
    } else {
      setIsNew(true);
    }

    dispatch(enrollmentDetailActions.setPlayInfoSaved());
    dispatch(enrollmentDetailActions.setGameInfoSaved());
    dispatch(loadArcheryCompetitionOptions());
  });

  useEffect(() => {
    if (isPosterListReload) {
      dispatch(loadPosterList());
    }

    if (isEnrollmentDetailsLoaded) {
      dispatch(enrollmentDetailActions.setPlayInfoSaved());
      dispatch(enrollmentDetailActions.setGameInfoSaved());
    }
  }, [dispatch, isEnrollmentDetailsLoaded, isPosterListReload]);

  useUnmount(async () => {
    await dispatch(enrollmentDetailActions.resetAll());
  });

  if (!isEnrollmentDetailsLoaded && !isNew) {
    return null;
  }

  return (
    <ContentDetail
      title="赛事活动报名 / 报名详情"
      headerLeft={
        <BreadcrumbRouter
          routes={[
            {
              path: '/association-activities/enrollments',
              breadcrumbName: '赛事活动报名',
            },
            {
              path: `${id}`,
              breadcrumbName: '报名详情',
            },
          ]}
        />
      }
      headerRight={
        <div>
          {!isNew ? (
            <Space className="EnrollmentDetailCheckListButtonContainer">
              <Button
                className="EnrollmentDetailCheckListButton"
                onClick={() => {
                  navigate(`/association-activities/enrollments/${id}/clubs`);
                }}
              >
                <SafetyCertificateOutlined className="EnrollmentDetailCheckListButtonProfileIcon" />
                <span className="EnrollmentDetailCheckListButtonText">
                  管理俱乐部
                </span>
              </Button>
              <Button
                className="EnrollmentDetailCheckListButton"
                onClick={() => {
                  navigate(`/association-activities/enrollments/${id}/players`);
                }}
              >
                <ProfileOutlined className="EnrollmentDetailCheckListButtonProfileIcon" />
                <span className="EnrollmentDetailCheckListButtonText">
                  管理报名数据
                </span>
              </Button>
            </Space>
          ) : undefined}
        </div>
      }
    >
      <section className="EnrollmentDetailFormContainer">
        <Form
          scrollToFirstError
          layout="vertical"
          form={form}
          ref={React.createRef<FormInstance>()}
          initialValues={{
            coverImageUrl: enrollmentDetailsData.coverImageUrl,
            title: enrollmentDetailsData.title,
            deadline: deadlineValue,
            gameInfoData,
          }}
        >
          <Form.Item
            name="coverImageUrl"
            className="EnrollmentDetailFormItem formItem"
            label="活动封面"
            rules={[{ required: true, message: '活动封面不能为空' }]}
          >
            <ImgCrop aspect={5 / 3}>
              <Upload.Dragger
                className="EnrollmentDetailFormItemUploader"
                disabled={isDisabledContent && !isNew}
                showUploadList={false}
                maxCount={1}
                name="file"
                accept=".png, .jpg, .jpeg"
                action={uploadImageAction}
                headers={uploadImageHeaders}
                fileList={images}
                beforeUpload={(file) => {
                  const validImageTypes = [
                    'image/png',
                    'image/jpeg',
                    'image/jpg',
                  ];

                  if (file.size > 2 * 1024 * 1024) {
                    message.error('图片大小不能超过2M');

                    return false;
                  }

                  if (!validImageTypes.includes(file.type)) {
                    message.error('文件必须为png, jpeg或者jpg的图片');

                    return false;
                  }

                  return true;
                }}
                onChange={(info) => {
                  const { fileList: newFileList } = info;
                  setImages(
                    newFileList.map((file) => {
                      return {
                        ...file,
                        url: file.response?.data?.imageUrl,
                      };
                    })
                  );
                }}
              >
                {enrollmentDetailsData.coverImageUrl ? (
                  imageContent
                ) : (
                  <p>上传活动封面</p>
                )}
              </Upload.Dragger>
            </ImgCrop>
          </Form.Item>
          <Form.Item
            className="EnrollmentDetailFormItem formItem"
            label="活动标题"
            name="title"
            rules={[{ required: true, message: '标题不能为空' }]}
          >
            <Input
              disabled={isDisabledContent && !isNew}
              value={enrollmentDetailsData.title}
              type="text"
              onChange={(e) => {
                dispatch(
                  enrollmentDetailActions.updateEnrollmentDetailsData({
                    title: e.target.value,
                  })
                );
              }}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="deadline"
            className="formItem"
            label="报名截止日期"
            rules={[{ required: true, message: '日期不能为空' }]}
          >
            <DatePicker
              disabled={isDisabledContent && !isNew}
              format="YYYY-MM-DD HH:mm:ss"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
              onChange={onChangeStartDate}
              value={
                enrollmentDetailsData.deadline
                  ? moment(enrollmentDetailsData.deadline)
                  : undefined
              }
            />
          </Form.Item>
          <div>
            <span className="EnrollmentDetailLimitationTile">报名限制</span>
            <Form.Item
              className="EnrollmentDetailLimitationType EnrollmentDetailFormItem formItem"
              label="类型"
              name={[enrollmentDetailsData.id, 'enrollmentLimitType']}
              initialValue={enrollmentDetailsData.enrollmentLimitType}
            >
              <Radio.Group
                disabled={isDisabledContent && !isNew}
                value={enrollmentDetailsData.enrollmentLimitType}
                onChange={async (event: any) => {
                  await dispatch(
                    enrollmentDetailActions.updateEnrollmentDetailsData({
                      enrollmentLimitType: event.target.value,
                      maxIndividualEnrolledCount: null,
                      maxClubEnrolledCount: null,
                      maxPlayerPerClubEnrolledCount: null,
                    })
                  );

                  form.setFieldsValue({
                    [enrollmentDetailsData.id]: {
                      maxIndividualEnrolledCount: null,
                      maxClubEnrolledCount: null,
                      maxPlayerPerClubEnrolledCount: null,
                    },
                  });
                }}
              >
                {ENROLLMENT_LIMITATION_TYPES.map((type) => (
                  <Radio value={type.value}>{type.label}</Radio>
                ))}
              </Radio.Group>
            </Form.Item>

            {enrollmentDetailsData.enrollmentLimitType ===
            ENROLLMENT_LIMITATION_TYPES_MAPPING.INDIVIDUAL ? (
              <Form.Item
                className="EnrollmentDetailFormItem formItem"
                label="人次"
                name={[enrollmentDetailsData.id, 'maxIndividualEnrolledCount']}
                initialValue={enrollmentDetailsData.maxIndividualEnrolledCount}
                rules={[
                  {
                    type: 'integer',
                    min: 1,
                    message: '报名人不可以少于1人，并且只能是整数',
                  },
                ]}
              >
                <InputNumber
                  controls={false}
                  disabled={isDisabledContent && !isNew}
                  placeholder="总人次"
                  addonAfter="人"
                  value={enrollmentDetailsData.maxIndividualEnrolledCount}
                  onChange={(value) => {
                    dispatch(
                      enrollmentDetailActions.updateEnrollmentDetailsData({
                        maxIndividualEnrolledCount: value,
                      })
                    );
                  }}
                />
              </Form.Item>
            ) : (
              <>
                <Form.Item
                  className="EnrollmentDetailFormItem formItem"
                  label="俱乐部数量上限"
                  name={[enrollmentDetailsData.id, 'maxClubEnrolledCount']}
                  initialValue={enrollmentDetailsData.maxClubEnrolledCount}
                  rules={[
                    {
                      type: 'integer',
                      min: 1,
                      message: '俱乐部数不可以少于1，并且只能是整数',
                    },
                  ]}
                >
                  <InputNumber
                    controls={false}
                    className="EnrollmentDetailFormItemInputNumber"
                    placeholder="请输入俱乐部数"
                    disabled={
                      enrollmentDetailsData.enrollmentLimitType !== 'club' ||
                      (isDisabledContent && !isNew)
                    }
                    value={enrollmentDetailsData.maxClubEnrolledCount}
                    onChange={(value) => {
                      dispatch(
                        enrollmentDetailActions.updateEnrollmentDetailsData({
                          maxClubEnrolledCount: value,
                        })
                      );
                    }}
                  />
                </Form.Item>
                <Form.Item
                  className="EnrollmentDetailFormItem formItem"
                  label="每个俱乐部人数"
                  name={[
                    enrollmentDetailsData.id,
                    'maxPlayerPerClubEnrolledCount',
                  ]}
                  initialValue={
                    enrollmentDetailsData.maxPlayerPerClubEnrolledCount
                  }
                  rules={[
                    {
                      type: 'integer',
                      min: 1,
                      message: '报名人不可以少于1人，并且只能是整数',
                    },
                  ]}
                >
                  <InputNumber
                    controls={false}
                    disabled={isDisabledContent && !isNew}
                    placeholder="每个俱乐部人数"
                    addonAfter="人 / 俱乐部"
                    value={enrollmentDetailsData.maxPlayerPerClubEnrolledCount}
                    onChange={(value) => {
                      dispatch(
                        enrollmentDetailActions.updateEnrollmentDetailsData({
                          maxPlayerPerClubEnrolledCount: value,
                        })
                      );
                    }}
                  />
                </Form.Item>
              </>
            )}
          </div>
          <Form.Item
            className="EnrollmentDetailFormItem formItem"
            label="链接宣传海报"
          >
            <Select
              placeholder="选择一个活动海报"
              disabled={isDisabledContent && !isNew}
              value={enrollmentDetailsData.posterId}
              onChange={(value) => {
                dispatch(
                  enrollmentDetailActions.updateEnrollmentDetailsData({
                    posterId: value,
                  })
                );
              }}
            >
              {posterList.map((poster: any) => (
                <Select.Option key={poster.id} value={poster.id}>
                  {poster.posterName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <EnrollmentClubFormSetting isNew={isNew} />
          <EnrollmentPlayerInfoSetting isNew={isNew} />
          <GameOptionSetting isNew={isNew} form={form} />

          <Form.Item
            className="EnrollmentDetailsTurnedOn formItem"
            label={
              <div className="flex flex-middle flex__shrink-fixed">
                <span className="EnrollmentDetailsTurnedOnSwitchText">
                  开启报名
                </span>
                <Tooltip
                  placement="topLeft"
                  title="开启之后，选手和俱乐部管理员可以在小程序（联赛报名列表）中查看"
                >
                  <QuestionCircleFilled style={{ fontSize: '16px' }} />
                </Tooltip>
              </div>
            }
          >
            <Switch
              disabled={isDisabledContent && !isNew}
              checked={enrollmentDetailsData.turnedOn}
              onChange={(checked: boolean) => {
                if (!enrollmentDetailsData.turnedOn) {
                  Modal.confirm({
                    centered: true,
                    title: `确定开启报名吗？`,
                    content: '开启报名之后无法修改和删除自定义选项，确定开启？',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: () => {
                      dispatch(enrollmentDetailActions.updateTurnOn(checked));
                    },
                  });
                } else {
                  dispatch(enrollmentDetailActions.updateTurnOn(checked));
                }
              }}
            />
          </Form.Item>

          <Form.Item style={{ marginBottom: '20px' }}>
            <div>
              <Space size="middle">
                {isDisabledContent && !isNew ? null : (
                  <Button
                    type="primary"
                    className="operateButton"
                    htmlType="submit"
                    onClick={async () => {
                      try {
                        await form.validateFields();

                        if (!isNew) {
                          await dispatch(
                            updateEnrollmentDetailsById({
                              enrollmentId: id,
                              backToListPage: () => {
                                navigate('/association-activities/enrollments');
                              },
                            })
                          );
                        } else {
                          if (gameInfoData.categories.length === 0) {
                            notification.error({
                              message: '添加报名详情失败',
                              description: '请至少添加一个组别',
                            });
                            throw Error;
                          }

                          const result = await dispatch(
                            addNewEnrollmentDetails({
                              backToListPage: () => {
                                navigate('/association-activities/enrollments');
                              },
                            })
                          );

                          if (result.error) {
                            throw Error;
                          }
                        }
                      } catch (error) {
                        throw error;
                      }
                    }}
                  >
                    保存
                  </Button>
                )}
              </Space>
              <Space size={10} style={{ marginLeft: '10px' }}>
                <Button
                  type="default"
                  className="cancelButton"
                  style={{ marginLeft: '0px' }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  返回
                </Button>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </section>
    </ContentDetail>
  );
};

export default EnrollmentDetails;
