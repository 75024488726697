import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { RootState } from '../../../../../redux/store';
import { postOtp } from '../../../api-request';
import { postResetPassword } from './api-request';
import {
  getMerchantName,
  getAccountName,
  getResetPasswordRequestInfo,
} from './selectors';

/**
 * Otp created
 */
export const createOtp = createAsyncThunk(
  'register/createOtp',
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);
      const accountName = getAccountName(state);

      await postOtp({
        merchantName,
        phoneNumber: accountName,
      });
    } catch (e) {
      notification.error({
        message: '获取验证码失败',
        description: '服务器开小差',
      });
    }
  }
);

export const passwordReset = createAsyncThunk(
  'forgetPassword/passwordReset',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const registerInfo = getResetPasswordRequestInfo(state);

    try {
      const result: any = await postResetPassword(registerInfo);
      const { data } = result;

      return data;
    } catch (e: any) {
      if (e.message === '服务器拒绝本次访问') {
        notification.error({
          message: '重置密码失败',
          description: '验证码出错',
        });
      } else {
        notification.error({
          message: '重置密码失败',
          description: '服务器开小差',
        });
      }
    }
  }
);
