import { createSelector } from '@reduxjs/toolkit';
import {
  getStores as getAllStoreList,
  getStores,
  getStoresMapping,
} from '../../../../../redux/entities/stores/selectors';
import _get from 'lodash/get';
import { getFormatLocalTime } from '../../../../../common/utils/time-lib';
import { RootState } from '../../../../../common/utils/types';
import {
  getIsStoreManager,
  getUserBelongToStoreId,
} from '../../../../../redux/app-v2/user/selectors';

export { getAllStoreList };

export const getRankingListPageNumber = (state: RootState) =>
  state.ranking.list.pageNumber;

export const getRankingListPageSize = (state: RootState) =>
  state.ranking.list.pageSize;

export const getRankingListTotalItems = (state: RootState) =>
  state.ranking.list.total;

export const getTimeStamp = (state: RootState) => state.ranking.list.timeStamp;

export const getRankingListResDataSource = (state: RootState) =>
  state.ranking.list.dataSource;

export const getRankingListData = (state: RootState) =>
  state.ranking.list.rankListData;

export const getRankingSettings = (state: RootState) =>
  state.ranking.list.rankingSettings;

export const getRankingSettingsCategories = (state: RootState) =>
  _get(state.ranking, 'list.categories', []);

export const getSelectedCategoryTypeName = (state: RootState) =>
  state.ranking.list.selectedCategoryTypeName;

export const getRankingSettingTimeInterval = (state: RootState) =>
  _get(state.ranking, 'list.rankingSettings.timeInterval', []);

export const getSelectedTimeInterval = (state: RootState) =>
  state.ranking.list.selectedTimeInterval;

export const getSelectedRankingSettingId = (state: RootState) =>
  state.ranking.list.selectedRankingSettingId;

export const getRankingSettingsList = (state: RootState) =>
  _get(state.ranking, 'list.rankingSettingsList', []);

export const getSelectedStoreId = (state: RootState) =>
  state.ranking.list.selectedStoreId;

export const getStoreIdForRankingSetting = createSelector(
  getSelectedStoreId,
  getStores,
  getIsStoreManager,
  (selectedStoreId, assignedStore, isStoreManager) => {
    if (isStoreManager) {
      return assignedStore[0]?.id;
    } else {
      return selectedStoreId;
    }
  }
);

export const getCategoriesOptionData = createSelector(
  getRankingSettingsCategories,
  (categories) => {
    if (categories.length <= 0) {
      return [
        {
          id: `ranking-detail-category-0`,
          name: '',
          categoryTypes: [
            {
              id: `ranking-detail-category-type-0`,
              name: '',
            },
          ],
        },
      ];
    }

    return categories.map((category: any, index: number) => {
      let displayCategoryTypes = [];

      if (category.categoryTypes) {
        displayCategoryTypes = category.categoryTypes.filter(
          (categoryType: any) => !categoryType.isOutdated
        );
      }

      return {
        id: `ranking-detail-category-${index}`,
        displayCategoryTypes: displayCategoryTypes.map(
          (type: any, typeIndex: number) => ({
            id: `ranking-detail-category-type-${typeIndex}`,
            ...type,
          })
        ),
        ...category,
      };
    });
  }
);

export const getStoreList = createSelector(
  getStoresMapping,
  getRankingSettings,
  getUserBelongToStoreId,
  (allStoresMapping, rankingSettings, userStoreId) => {
    const storeList = rankingSettings.storeIds.map((id: string) => {
      const { displayName }: any = allStoresMapping[id] || {};

      return {
        id,
        displayName,
      };
    });
    const userStore = {
      id: userStoreId,
      displayName: allStoresMapping[userStoreId]?.displayName,
    };
    if (storeList.includes(userStore)) {
      return storeList;
    } else {
      storeList.push(userStore);
      return storeList;
    }
  }
);

export const getTimeIntervalArray = createSelector(
  getRankingSettingTimeInterval,
  (rankingSettingTimeIntervals) => {
    return rankingSettingTimeIntervals?.map(
      (rankingSettingTimeInterval: string) => {
        if (rankingSettingTimeInterval === 'daily') {
          return {
            label: '日榜',
            key: rankingSettingTimeInterval,
          };
        }
        if (rankingSettingTimeInterval === 'weekly') {
          return {
            label: '周榜',
            key: rankingSettingTimeInterval,
          };
        }
        if (rankingSettingTimeInterval === 'monthly') {
          return {
            label: '月榜',
            key: rankingSettingTimeInterval,
          };
        }
        if (rankingSettingTimeInterval === 'allTime') {
          return {
            label: '永久榜',
            key: rankingSettingTimeInterval,
          };
        }
      }
    );
  }
);

export const getRankingListTableData = createSelector(
  getRankingSettings,
  getSelectedCategoryTypeName,
  getAllStoreList,
  getStoreIdForRankingSetting,
  getRankingListData,
  (rankingSettings, categoryTypeName, storeList, storeId, listData) => {
    return listData.map(
      (
        rowData: {
          earlistBestScoreTime: string;
          name: string | undefined;
          bestScore: string | undefined;
          playerId: string | undefined;
        },
        index: number
      ) => {
        const { name, bestScore, earlistBestScoreTime, playerId } = rowData;
        const targetStore = storeList.find(
          (store: any) => store.id === storeId
        );

        return {
          rank: index + 1,
          categoryValue: categoryTypeName,
          player: name,
          playerId,
          stores: targetStore?.displayName || '全部门店',
          bestScore,
          recordedTime: getFormatLocalTime(
            earlistBestScoreTime,
            'YYYY/MM/DD HH:mm:ss'
          ),
        };
      }
    );
  }
);
