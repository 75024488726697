import React, { useEffect } from 'react';
import QRCode from 'react-qr-code';
import { Button, Modal, Radio, Skeleton, Spin, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { CURRENCY_SYMBOLS } from '../../../../../common/constants';
import { AppDispatch } from '../../../../../common/utils/types';
import { loadMerchantSubscription } from '../../../../../redux/app-v2/merchant/thunks';
import { UNPAID_STATUS } from '../../utils/constants';
import { getFormatLocalTime } from '../../../../../common/utils/time-lib';
import {
  getLoadSubscriptionPreviewPlanOrderStatusCompleted,
  getSubscriptionOrderCurrency,
  getSubscriptionOrderDisplayTotal,
  getSubscriptionOrderItems,
  getSubscriptionShowRenewalAgreementModalVisibility,
  getSubscriptionOrderStatus,
  getSubscriptionAgreementInfo,
  getSubscriptionBusinessLicenseModalVisibility,
  getSubscriptionOrderPaymentQRCodeUrl,
} from '../../redux/selectors';
import { actions as subscriptionActions } from '../../redux';
import {
  createSubscriptionOrder,
  pollingSubscriptionOrderStatus,
  stopPollingSubscriptionOrderStatus,
  updateSubscriptionOrderListPageNumber,
} from '../../redux/thunks';
import BusinessLicense from '../../../../app/components/BusinessLicense';
import RenewalAgreement from '../../../../app/components/RenewalAgreement';
import WeixinPaymentImage from '../../../../../images/payment-weixin.svg';
import './SubscriptionBill.less';

const SubscriptionBill = () => {
  const dispatch = useDispatch<AppDispatch>();
  const loadSubscriptionPreviewPlanOrderStatusCompleted = useSelector(
    getLoadSubscriptionPreviewPlanOrderStatusCompleted
  );
  const subscriptionOrderCurrency = useSelector(getSubscriptionOrderCurrency);
  const subscriptionOrderDisplayTotal = useSelector(
    getSubscriptionOrderDisplayTotal
  );
  const subscriptionOrderItems: any = useSelector(getSubscriptionOrderItems);
  const subscriptionShowRenewalAgreementModalVisibility = useSelector(
    getSubscriptionShowRenewalAgreementModalVisibility
  );
  const subscriptionOrderStatus = useSelector(getSubscriptionOrderStatus);
  const subscriptionOrderPaymentQRCodeUrl = useSelector(
    getSubscriptionOrderPaymentQRCodeUrl
  );
  const subscriptionAgreementInfo = useSelector(getSubscriptionAgreementInfo);
  const subscriptionBusinessLicenseModalVisibility = useSelector(
    getSubscriptionBusinessLicenseModalVisibility
  );
  const BILLING_LIST_LABEL: any = {
    plan: (item: {
      quantity: number;
      currencySymbol: string;
      displayUnitPrice: number;
    }) => ({
      label: `基础费用（${item.quantity}月 x ${item.currencySymbol}${item.displayUnitPrice}）`,
    }),
    addonStore: (item: {
      addonStoreCount: number;
      quantity: number;
      currencySymbol: string;
      displayUnitPrice: number;
      displayAddonUnitPrice: number;
    }) => ({
      label: `附加门店（+${item.addonStoreCount} x ${item.quantity}月 x ${item.currencySymbol}${item.displayAddonUnitPrice}/月/店）`,
    }),
    existingSubscriptionOffset: () => ({
      label: '当前计划剩余抵扣',
      needHelp: '当前计划的剩余时长按比例换算成余额进行抵扣',
      isMinus: true,
    }),
  };

  useEffect(() => {
    if (
      subscriptionOrderStatus &&
      !UNPAID_STATUS.includes(subscriptionOrderStatus)
    ) {
      dispatch(subscriptionActions.orderReset());
      dispatch(updateSubscriptionOrderListPageNumber(0));
      dispatch(loadMerchantSubscription()).then((result) => {
        const { payload }: any = result || {};
        const { endTime } = payload || {};

        Modal.info({
          centered: true,
          title: '支付成功',
          content: `您的订单已支付成功, 您的当前计划截止时间为: ${getFormatLocalTime(
            endTime,
            'YYYY-MM-DD HH:mm'
          )}`,
        });
      });
      Modal.destroyAll();
    }
  }, [dispatch, subscriptionOrderStatus]);

  useEffect(() => {
    if (subscriptionOrderPaymentQRCodeUrl) {
      Modal.info({
        icon: <></>,
        centered: true,
        width: '80%',
        className: 'SubscriptionBillPaymentConfirmModal',
        title: '支付确认',
        okText: '关闭',
        okButtonProps: {
          ghost: true,
        },
        onOk: () => {
          stopPollingSubscriptionOrderStatus();
          dispatch(subscriptionActions.orderReset());
          dispatch(updateSubscriptionOrderListPageNumber(0));
          dispatch(loadMerchantSubscription());
        },
        content: (
          <div className="SubscriptionBillPaymentConfirmModalQrCodePlaceholder flex flex-column flex-middle flex-space-between">
            <h4>扫描二维码支付</h4>
            {subscriptionOrderPaymentQRCodeUrl ? (
              <QRCode
                className="SubscriptionBillPaymentConfirmModalQrCode"
                value={subscriptionOrderPaymentQRCodeUrl}
              />
            ) : (
              <Spin indicator={<LoadingOutlined spin />} />
            )}
            <p>支付完毕后会自动跳转关闭</p>
          </div>
        ),
      });
    }
  }, [dispatch, subscriptionOrderPaymentQRCodeUrl]);

  return (
    <section className="SubscriptionBill">
      <div>
        <h3 className="SubscriptionBillPaymentOptionsTitle">支付方式</h3>
        <ul className="SubscriptionBillPaymentOptionList">
          <li className="SubscriptionBillPaymentOption flex flex-middle flex-pace-between">
            <Radio checked={true} className="flex__shrink-fixed" />
            <div className="flex__fluid-content text-right">
              <img src={WeixinPaymentImage} alt="" />
            </div>
          </li>
        </ul>
      </div>

      <ul className="SubscriptionBillList">
        {loadSubscriptionPreviewPlanOrderStatusCompleted ? (
          subscriptionOrderItems.map((item: any, index: number) => {
            const labelClassName = ['flex__shrink-fixed'];
            const { type, displayTotal } = item || {};

            if (!type) {
              return null;
            }

            const { label, needHelp, isMinus } = BILLING_LIST_LABEL[type]({
              ...item,
              currencySymbol: CURRENCY_SYMBOLS[subscriptionOrderCurrency],
            });

            if (isMinus) {
              labelClassName.push('SubscriptionBillLabelMinus');
            }

            return (
              <li
                key={`subscription-bill-${index}`}
                className="SubscriptionBillItem flex flex-middle flex-pace-between"
              >
                <label className={labelClassName.join(' ')}>
                  {label}
                  {needHelp && (
                    <Tooltip
                      className="SubscriptionBillTooltip"
                      placement="right"
                      title={needHelp}
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  )}
                </label>

                <span className="SubscriptionBillPrice flex__fluid-content text-right">
                  {isMinus ? ' -' : null}
                  {CURRENCY_SYMBOLS[subscriptionOrderCurrency]}
                  {displayTotal}
                </span>
              </li>
            );
          })
        ) : (
          <Skeleton
            className="SubscriptionBillListSkeleton"
            active
            paragraph={{
              width: '100%',
            }}
          />
        )}
      </ul>
      <div className="SubscriptionBillTotalContainer flex flex-top flex-space-between">
        <label className="SubscriptionBillTotalLabel flex__shrink-fixed">
          总计
        </label>
        <Skeleton
          active
          title={false}
          paragraph={{ rows: 1 }}
          loading={!loadSubscriptionPreviewPlanOrderStatusCompleted}
        >
          <span className="SubscriptionBillTotal">
            {CURRENCY_SYMBOLS[subscriptionOrderCurrency]}
            {subscriptionOrderDisplayTotal}
          </span>
        </Skeleton>
      </div>
      <Button
        block
        type="primary"
        size="large"
        onClick={() => {
          dispatch(subscriptionActions.showBusinessLicenseModal());
        }}
      >
        完成支付
      </Button>
      <BusinessLicense
        show={subscriptionBusinessLicenseModalVisibility}
        data={subscriptionAgreementInfo}
        onUpdate={(agreementInfo: {
          companyName?: string;
          companyAddress?: string;
          principalName?: string;
        }) => {
          dispatch(subscriptionActions.businessLicenseUpdated(agreementInfo));
        }}
        onReset={async () => {
          await dispatch(subscriptionActions.businessLicenseReset());
        }}
        onConfirm={async () => {
          await dispatch(subscriptionActions.showRenewalAgreementModal());
        }}
        onClose={() => {
          dispatch(subscriptionActions.hideBusinessLicenseModal());
        }}
      />
      <RenewalAgreement
        show={subscriptionShowRenewalAgreementModalVisibility}
        data={subscriptionAgreementInfo}
        onConfirm={async () => {
          dispatch(subscriptionActions.hideRenewalAgreementModal());
          await dispatch(createSubscriptionOrder());
          await dispatch(pollingSubscriptionOrderStatus({}));
        }}
        onClose={async () => {
          await dispatch(subscriptionActions.hideRenewalAgreementModal());
        }}
      />
    </section>
  );
};

export default SubscriptionBill;
