import { createSelector } from '@reduxjs/toolkit';
import {
  getIsManager,
  getIsOwner,
  getUserAuthorization,
} from '../../../../../redux/app-v2/user/selectors';
import { getMerchantName } from '../../../../../redux/app-v2/merchant/selectors';
import { EXCHANGE_CATEGORIES } from '../constants';

export { getUserAuthorization, getMerchantName };

export const getMerchandiseListPageNumber = (state: any) =>
  state.merchandise.list.pageNumber;

export const getMerchandiseListPageSize = (state: any) =>
  state.merchandise.list.pageSize;

export const getDataCountTotalMerchandises = (state: any) =>
  state.merchandise.list.totalMerchandises;

export const getIsMerchandiseListLastPage = (state: any) =>
  state.merchandise.list.end;

export const getIsEmployeeAdminModalVisible = createSelector(
  getIsManager,
  getIsOwner,
  (isManager, isOwner) => isManager || isOwner
);

export const getOriginalMerchandiseDetail = (state: any) =>
  state.merchandise.details.data || {};

export const getAssetsSettingTypes = (state: any) =>
  state.merchandise.assetsSetting.types || [];

export const getMerchandiseDetail = createSelector(
  getOriginalMerchandiseDetail,
  getAssetsSettingTypes,
  (detail, assetsSettingTypes) => {
    const { imageUrl, exchangeBy, exchanged } = detail;
    const currentExchangeBy =
      assetsSettingTypes.find(
        (type: any) => exchangeBy?.assetsTypeId === type.id
      ) || {};
    const currentExchanged =
      exchanged?.type !== 'voucher'
        ? assetsSettingTypes.find(
            (type: any) => exchanged?.assetsTypeId === type.id
          ) || {}
        : { type: exchanged?.type };
    const {
      unit: exchangeByUnit,
      description: exchangeByDescription,
      type: exchangeByType,
    } = currentExchangeBy;
    const {
      type: exchangedType,
      description: exchangedDescription,
      unit: exchangedUnit,
    } = currentExchanged;
    const displayDetail = {
      ...detail,
      assetsSettingTypes,
      exchangeByUnit,
      exchangeByDescription,
      exchangeByType,
      exchangedType,
      exchangedUnit,
      exchangedDescription,
      uploadList: [],
    };

    if (imageUrl) {
      const name = imageUrl?.split('/').pop() || '';

      displayDetail.uploadList = [
        {
          uid: imageUrl,
          name,
          status: 'done',
          url: imageUrl,
        },
      ];
    }

    return displayDetail;
  }
);

export const getOriginalMerchandiseList = (state: any) =>
  state.merchandise.list.data;

export const getMerchandiseList = createSelector(
  getOriginalMerchandiseList,
  getAssetsSettingTypes,
  (list, assetsSettingTypes) => {
    return list.map((item: any) => {
      const { exchangeByAssetsTypeId, exchangedType, exchangedAssetsTypeId } =
        item;
      const { type: exchangeByTypeLabel } =
        assetsSettingTypes?.find(
          (type: any) => exchangeByAssetsTypeId === type.id
        ) || {};
      const exchangedTypeLabel =
        exchangedType === 'voucher'
          ? EXCHANGE_CATEGORIES[1].label
          : `${EXCHANGE_CATEGORIES[0].label} : ${
              assetsSettingTypes.find(
                (item: any) => item.id === exchangedAssetsTypeId
              )?.type || ''
            }`;

      return {
        ...item,
        exchangeByTypeLabel,
        exchangedTypeLabel,
      };
    });
  }
);
