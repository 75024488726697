export const getPlayersMapping = (state: { entities: { players: any } }) =>
  state.entities.players;

export const getPlayers = (state: { entities: { players: any } }) => {
  const playersMapping = state.entities.players;
  const keys = Object.keys(playersMapping);

  return keys.map((key) => playersMapping[key]);
};

export const getPlayerById = (
  state: { entities: { players: any } },
  id: string
) => state.entities.players[id];
