/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { DatePicker, Button, Menu, Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import { AppDispatch } from '../../../../redux/store';
import { useSelector, useDispatch } from 'react-redux';
import {
  getPageSize,
  getPaginateNum,
  getSelectStatus,
  getTableData,
  getTotal,
} from './redux/selectors';
import { setPageSize, setPaginateNum } from './redux';
import { requestTableData, getTheAssets } from './redux/thunks';
import {
  getIsManager,
  getIsOwner,
  getIsUserInitialized,
} from './redux/selectors';
import TableResponsiveFrame from '../../../../common/components/tables/TableResponsiveFrame';
import ContentDetail from '../../../../common/components/ContentDetail';
import BreadcrumbRouter from '../../../../common/components/BreadcrumbRouter';
import './Vouchers.less';
import { getIsOperationsManager } from '../../../../redux/app-v2/user/selectors';

const RangePicker: any = DatePicker.RangePicker;

export default function Vouchers() {
  const dispatch = useDispatch<AppDispatch>();
  const isManager = useSelector(getIsManager);
  const isOwner = useSelector(getIsOwner);
  const isOperation = useSelector(getIsOperationsManager);
  const isUserInitialized = useSelector(getIsUserInitialized);
  const dataSource = useSelector(getTableData);
  const paginateSize = useSelector(getPageSize);
  const paginateNum = useSelector(getPaginateNum);
  const total = useSelector(getTotal);
  const columns: any = [
    {
      title: '时间',
      width: 180,
      dataIndex: 'createdTime',
      key: 'createdTime',
      align: 'center',
    },
    {
      title: '订单号',
      width: 250,
      dataIndex: 'purchaseOrderId',
      key: 'purchaseOrderId',
      align: 'center',
      mobileAlign: 'headerLeft',
    },
    {
      title: '状态',
      width: 120,
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      mobileAlign: 'contentHeaderRight',
    },
    {
      title: '会员',
      width: 90,
      dataIndex: 'playerDisplayName',
      key: 'playerName',
      align: 'center',
    },
    {
      title: '兑换礼品',
      dataIndex: 'merchandiseDisplayName',
      width: 160,
      key: 'merchandiseName',
      align: 'center',
      mobileAlign: 'contentHeaderLeft',
    },
    {
      title: '核销人',
      width: 160,
      dataIndex: 'redeemOperatorDisplayName',
      key: 'operatorName',
      align: 'center',
    },
  ];

  const today = moment().startOf('day').subtract(6, 'days').unix();
  const tomorrow = moment().startOf('day').add(1, 'days').unix();
  const [searchTextByTimeFrom, setSearchTextByTimeFrom] = useState(today);
  const [searchTextByTimeTo, setSearchTextByTimeTo] = useState(tomorrow);

  const selectArray = useSelector(getSelectStatus);
  const [selectedStatus, setSelectedStatus] = useState<Array<string>>([]);

  const filterClick = (prop: string, data: string) => {
    if (prop === 'Status') {
      selectedStatus?.includes(data)
        ? setSelectedStatus([...selectedStatus.filter((item) => item !== data)])
        : setSelectedStatus([...(selectedStatus as string[]), data]);
    }
  };

  const selectStatusList = (
    <Menu
      onClick={(e) => filterClick('Status', e.key)}
      items={selectArray}
      selectedKeys={selectedStatus}
    />
  );

  const onRangePickerChange = (dates: any, dateStrings: [string, string]) => {
    setSearchTextByTimeFrom(moment(dateStrings[0]).unix());
    setSearchTextByTimeTo(moment(dateStrings[1]).add(1, 'days').unix());

    dispatch(setPaginateNum(1));
  };

  const onShowSizeChange = (current: number, pageSize: number) => {
    dispatch(setPageSize(pageSize));
  };

  const paginateChange = (page: number) => {
    dispatch(setPaginateNum(page));
  };

  useEffect(() => {
    dispatch(getTheAssets());
  }, []);

  useEffect(() => {
    if (!isUserInitialized) {
      return;
    }

    let param_status: string = '';
    for (let item of selectedStatus) {
      param_status += item + ',';
    }
    const param = {
      from: searchTextByTimeFrom,
      to: searchTextByTimeTo,
      offset: (paginateNum - 1) * paginateSize,
      limit: paginateSize,
      status: param_status.slice(0, -1),
    };

    if (!isManager && !isOwner && !isOperation) {
      return;
    }

    //https request for search API;
    dispatch(requestTableData(param) as any);
  }, [
    searchTextByTimeFrom,
    searchTextByTimeTo,
    paginateNum,
    paginateSize,
    selectedStatus,
    isManager,
    isOwner,
    isUserInitialized,
    isOperation,
    dispatch,
  ]);

  return (
    <ContentDetail
      title="券码管理"
      headerLeft={
        <BreadcrumbRouter
          routes={[
            {
              path: '/redemption-market',
              breadcrumbName: '兑换商城',
            },
            {
              path: '/voucher-management',
              breadcrumbName: '券码管理',
            },
          ]}
        />
      }
    >
      <div className="content__filter flex flex-middle">
        <div className="form__group">
          <label className="form__label">日期:</label>
          <RangePicker
            className="form__item"
            onChange={onRangePickerChange}
            placeholder={['开始日期', '结束日期']}
            defaultValue={
              searchTextByTimeFrom === 0
                ? [moment.unix(today), moment.unix(tomorrow - 1)]
                : [
                    moment.unix(searchTextByTimeFrom),
                    moment.unix(searchTextByTimeTo - 1),
                  ]
            }
          />
        </div>

        <div className="form__group">
          <label className="form__label">筛选:</label>
          <Dropdown overlay={selectStatusList} arrow className="form__item">
            <Button shape="round">
              <Space>
                状态
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </div>
      </div>
      <TableResponsiveFrame
        columns={columns}
        pageNumber={paginateNum}
        pageSize={paginateSize}
        data={dataSource}
        dataItemsTotal={total}
        onChangePageSize={onShowSizeChange}
        onChangePageNumber={paginateChange}
      />
    </ContentDetail>
  );
}
