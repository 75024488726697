import { createSlice } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../../service/constants';
import {
  loadMerchandiseList,
  loadAssetsSetting,
  changeMerchandiseListPageNumber,
  changeMerchandiseListPageSize,
  loadMerchandiseById,
  saveMerchandiseById,
} from './thunks';

const initialState: {
  list: {
    data: [];
    pageSize: number;
    pageNumber: number;
    totalMerchandises: number;
    end: boolean;
    loadMerchandisesStatus: string;
  };
  details: {
    data: any;
    loadRequestStatus: string;
    saveRequestStatus: string;
  };
  assetsSetting: {
    id: string;
    types: [];
    loadAssetsSettingStatus: string;
  };
} = {
  list: {
    data: [],
    pageSize: 10,
    pageNumber: 0,
    totalMerchandises: 0,
    end: false,
    loadMerchandisesStatus: API_REQUEST_STATUS.IDLE,
  },
  details: {
    data: {},
    loadRequestStatus: API_REQUEST_STATUS.IDLE,
    saveRequestStatus: API_REQUEST_STATUS.IDLE,
  },
  assetsSetting: {
    id: '',
    types: [],
    loadAssetsSettingStatus: API_REQUEST_STATUS.IDLE,
  },
};

export const { reducer, actions } = createSlice({
  name: 'merchandise',
  initialState,
  reducers: {
    backMerchandiseListPage: (state: any) => {
      state.details.data = {};
      state.loadRequestStatus = API_REQUEST_STATUS.IDLE;
      state.saveRequestStatus = API_REQUEST_STATUS.IDLE;
    },
    updatePageNumber: (state: any, action: any) => {
      state.list.pageNumber = action.payload;
    },
    updatePageSize: (state: any, action: any) => {
      state.list.pageSize = action.payload;
    },
    updateMerchandiseDetail: (state: any, action: any) => {
      state.details.data = {
        ...state.details.data,
        ...action.payload,
      };
    },
  },
  extraReducers: {
    [loadMerchandiseList.fulfilled.type]: (state: any, action) => {
      const { pagination, data } = action.payload;

      state.list.data = data;
      state.list.end = pagination.end;
      state.list.totalMerchandises = pagination.total;
    },
    [loadMerchandiseList.rejected.type]: (state: {
      list: { loadMerchandisesStatus: string };
    }) => {
      state.list.loadMerchandisesStatus = API_REQUEST_STATUS.REJECTED;
    },
    [changeMerchandiseListPageNumber.fulfilled.type]: (
      state: any,
      action: any
    ) => {
      state.list.pageNumber = action.payload;
    },
    [changeMerchandiseListPageSize.fulfilled.type]: (
      state: any,
      action: any
    ) => {
      state.list.pageSize = action.payload;
    },
    [loadMerchandiseById.fulfilled.type]: (
      state: typeof initialState,
      action: any
    ) => {
      state.details.data = action.payload;
      state.details.loadRequestStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [loadMerchandiseById.rejected.type]: (state: typeof initialState) => {
      state.details.data = {};
      state.details.loadRequestStatus = API_REQUEST_STATUS.REJECTED;
    },
    [saveMerchandiseById.fulfilled.type]: (state: typeof initialState) => {
      state.details.saveRequestStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [saveMerchandiseById.rejected.type]: (state: typeof initialState) => {
      state.details.saveRequestStatus = API_REQUEST_STATUS.REJECTED;
    },
    [loadAssetsSetting.fulfilled.type]: (
      state: typeof initialState,
      action: any
    ) => {
      state.assetsSetting.id = action.payload.data.id;
      state.assetsSetting.types = action.payload.data.types;
      state.assetsSetting.loadAssetsSettingStatus =
        API_REQUEST_STATUS.FULFILLED;
    },
    [loadAssetsSetting.rejected.type]: (state: typeof initialState) => {
      state.assetsSetting.loadAssetsSettingStatus = API_REQUEST_STATUS.REJECTED;
    },
  },
});

export default reducer;
