import { createSlice } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../../../../service/constants';
import { HistoryPlayersAchievementImportState } from './types';
import {
  loadHistoryPlayerAchievementImportTempTournament,
  loadHistoryPlayersAchievementImportTasks,
} from './thunks';

const initialState: HistoryPlayersAchievementImportState = {
  data: {
    tasks: [],
    headers: [],
    pageNumber: 0,
    pageSize: 10,
    totalHistoryPlayersAchievementImportTasks: 0,
    associationHistoryTournamentTemplateId: null,
    associationHistoryTournamentTemplateName: null,
  },
  loadHistoryPlayersAchievementImportTasksStatus: API_REQUEST_STATUS.IDLE,
  loadHistoryPlayersAchievementImportTempTournamentStatus:
    API_REQUEST_STATUS.IDLE,
};

export const { reducer, actions } = createSlice({
  name: 'association/histories/playersAchievementImport',
  initialState,
  reducers: {
    associationHistoryTournamentTemplateIdUpdated: (state, { payload }) => {
      state.data.associationHistoryTournamentTemplateId = payload;
    },
  },
  extraReducers: {
    [loadHistoryPlayersAchievementImportTasks.rejected.type]: (state) => {
      state.loadHistoryPlayersAchievementImportTasksStatus =
        API_REQUEST_STATUS.REJECTED;
    },
    [loadHistoryPlayersAchievementImportTasks.pending.type]: (state) => {
      state.loadHistoryPlayersAchievementImportTasksStatus =
        API_REQUEST_STATUS.PENDING;
    },
    [loadHistoryPlayersAchievementImportTasks.fulfilled.type]: (
      state,
      { payload }
    ) => {
      state.loadHistoryPlayersAchievementImportTasksStatus =
        API_REQUEST_STATUS.FULFILLED;
      state.data.tasks = payload;
    },
    [loadHistoryPlayersAchievementImportTasks.rejected.type]: (state) => {
      state.loadHistoryPlayersAchievementImportTasksStatus =
        API_REQUEST_STATUS.REJECTED;
    },
    [loadHistoryPlayerAchievementImportTempTournament.rejected.type]: (
      state
    ) => {
      state.loadHistoryPlayersAchievementImportTempTournamentStatus =
        API_REQUEST_STATUS.REJECTED;
    },
    [loadHistoryPlayerAchievementImportTempTournament.pending.type]: (
      state
    ) => {
      state.loadHistoryPlayersAchievementImportTempTournamentStatus =
        API_REQUEST_STATUS.PENDING;
    },
    [loadHistoryPlayerAchievementImportTempTournament.fulfilled.type]: (
      state,
      { payload }
    ) => {
      const { title } = payload || {};

      state.loadHistoryPlayersAchievementImportTempTournamentStatus =
        API_REQUEST_STATUS.FULFILLED;
      state.data.associationHistoryTournamentTemplateName = title;
    },
  },
});

export default reducer;
