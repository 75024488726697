import { post } from '../../../../../../../service/api';
import { AddClubState } from './types';

export const postClub = async (merchantName: string, payload: AddClubState) => {
  try {
    const result = await post(
      `/merchants/${merchantName}/association-clubs`,
      payload,
      {}
    );

    return result;
  } catch (error) {
    throw error;
  }
};
