import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input, InputNumber, Modal, Select, Switch } from 'antd';
import {
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { AppDispatch } from '../../../../../../../../common/utils/types';
import { priceConvertToIntegerCents } from '../../../../../../../../common/utils/currency';
import {
  getSelectedClubNestItemSettingShowModal,
  getSelectedClubNestItemSettingDescription,
  getSelectedClubNestItemSettingId,
  getSelectedClubNestItemSettingMaxValue,
  getSelectedClubNestItemSettingEnableChargePrice,
  getSelectedClubNestItemSettingPricingInfoPrice,
  getSelectedClubNestItemSettingPricingInfoAssociationPlayerPrice,
  getSelectedClubNestItemSettingPropertyName,
  getSelectedClubNestItemSettingPropertyType,
  getSelectedClubNestItemsSetting,
} from '../../redux/selectors';
import { actions as enrollmentDetailActions } from '../../redux';

const NestItemOptionDetailModal = (props: {
  onDelete: (id: string) => void;
  onUpdate: (item: any) => void;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const { onDelete, onUpdate } = props;
  const showModal = useSelector(getSelectedClubNestItemSettingShowModal);
  const id = useSelector(getSelectedClubNestItemSettingId);
  const propertyName = useSelector(getSelectedClubNestItemSettingPropertyName);
  const description = useSelector(getSelectedClubNestItemSettingDescription);
  const propertyType = useSelector(getSelectedClubNestItemSettingPropertyType);
  const maxValue = useSelector(getSelectedClubNestItemSettingMaxValue);
  const enableChargePrice = useSelector(
    getSelectedClubNestItemSettingEnableChargePrice
  );
  const price = useSelector(getSelectedClubNestItemSettingPricingInfoPrice);
  const associationPlayerPrice = useSelector(
    getSelectedClubNestItemSettingPricingInfoAssociationPlayerPrice
  );
  const selectedClubNestItemsSetting = useSelector(
    getSelectedClubNestItemsSetting
  );
  const resetData = async () => {
    // close will not reset history the form data, so we need to use form.resetFields()
    await form.resetFields();
    await dispatch(enrollmentDetailActions.resetSelectedClubNestItemSetting());
  };
  const handleCloseModal = async () => {
    resetData();
    await dispatch(
      enrollmentDetailActions.hideSelectedClubInfoNestItemSettingModal()
    );
  };
  const handleDeleteNestItemSetting = async () => {
    await resetData();
    await onDelete(id);
    handleCloseModal();
  };
  const handleUpdateNestItemSetting = async () => {
    try {
      await form.validateFields();
      await onUpdate({
        id,
        propertyName,
        description,
        propertyType,
        maxValue,
        enableChargePrice,
        pricingInfo: {
          price: priceConvertToIntegerCents(price),
          associationPlayerPrice: priceConvertToIntegerCents(
            associationPlayerPrice
          ),
        },
      });
      handleCloseModal();
    } catch (error) {
      throw error;
    }
  };

  return (
    <Modal
      centered
      // for reset form data, if not destroyOnClose, the form data will be kept history data at same [id]
      destroyOnClose
      className="NestItemOptionDetailModal"
      maskClosable={false}
      visible={showModal}
      footer={[
        <Button
          danger
          key="EnrollmentDetailsOptionModalDeleteButton"
          type="text"
          icon={<DeleteOutlined />}
          onClick={handleDeleteNestItemSetting}
        >
          删除
        </Button>,
        <Button
          key="EnrollmentDetailsOptionModalDeleteSubmit"
          type="primary"
          loading={false}
          onClick={handleUpdateNestItemSetting}
        >
          保存
        </Button>,
        <Button
          ghost
          key="EnrollmentDetailsOptionModalDeleteCancel"
          type="primary"
          onClick={handleCloseModal}
        >
          取消
        </Button>,
      ]}
      onOk={() => {}}
      onCancel={handleCloseModal}
      // afterClose should be clear the form data, if not, the form data will be kept history data
      afterClose={resetData}
    >
      <Form form={form} preserve={false}>
        <Form.Item
          label="项目名称"
          name={[id, 'propertyName']}
          initialValue={propertyName}
          rules={[
            {
              required: true,
              message: '项目名称不能为空',
            },
            {
              validator(_, itemPropertyName) {
                const duplicatedNameItems = selectedClubNestItemsSetting.filter(
                  (item: any) =>
                    item.name === itemPropertyName && item.id !== id
                );

                if (duplicatedNameItems?.length > 0) {
                  return Promise.reject(new Error('项目名称不能重复'));
                }

                if (itemPropertyName && itemPropertyName.includes('.')) {
                  return Promise.reject(new Error('项目名称不能包含点'));
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            value={propertyName}
            placeholder="请输入项目名称（例如: 团体赛）"
            onChange={(e) => {
              dispatch(
                enrollmentDetailActions.updateClubInfoNestItemPropertyName(
                  e.target.value
                )
              );
            }}
          />
        </Form.Item>
        <Form.Item
          label="项目类型"
          name={[id, 'propertyType']}
          initialValue={propertyType}
          rules={[{ required: true, message: '选择项目类别' }]}
        >
          <Select
            className="NestItemOptionDetailModalPropertyType"
            dropdownMatchSelectWidth={100}
            placeholder="选择项目类别"
            value={propertyType || 'number'}
            options={[{ value: 'number', label: '数字' }]}
            onChange={(value) => {
              dispatch(
                enrollmentDetailActions.updateClubInfoNestItemPropertyType(
                  value
                )
              );
            }}
          />
        </Form.Item>
        <Form.Item
          label="数量上限"
          name={[id, 'maxValue']}
          initialValue={maxValue}
          rules={[
            {
              required: true,
              message: '数量上限不能为空',
            },
            { type: 'integer', min: 1, message: '数量上限为大于0的整数' },
          ]}
        >
          <InputNumber
            placeholder="（例: 10）"
            controls={false}
            value={maxValue}
            onChange={(value) => {
              dispatch(
                enrollmentDetailActions.updateClubInfoNestItemMaxValue(value)
              );
            }}
          />
        </Form.Item>
        {propertyType === 'number' && (
          <Form.Item
            className="NestItemOptionNewModalFormItem"
            label="设置价格"
            name={[id, 'enableChargePrice']}
            initialValue={enableChargePrice}
          >
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={enableChargePrice}
              onChange={(checked) => {
                dispatch(
                  enrollmentDetailActions.updateClubInfoNestItemEnableChargePrice(
                    checked
                  )
                );
              }}
            />
          </Form.Item>
        )}

        {enableChargePrice ? (
          <Form.Item
            className="NestItemOptionNewModalFormPrice NestItemOptionNewModalFormItem"
            label="价格（元）"
            initialValue={price}
            name={[id, 'price']}
            rules={[
              {
                required: true,
                message: '价格不能为空',
              },
              () => ({
                validator(_, value) {
                  if (typeof value === 'number' && value <= 0) {
                    return Promise.reject(new Error('价格不能为0.00'));
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <InputNumber
              addonBefore="￥"
              placeholder="356.50"
              controls={false}
              min={0}
              precision={2}
              value={price}
              onChange={(value: any) => {
                dispatch(
                  enrollmentDetailActions.updateClubInfoNestItemPrice(value)
                );
              }}
            />
          </Form.Item>
        ) : null}

        <Form.Item
          className="NestItemOptionDetailModalFormItem"
          label="项目描述"
          name={[id, 'description']}
          initialValue={description}
        >
          <Input
            value={description}
            placeholder="项目描述（例: 300元/晚 含早）"
            onChange={(e) => {
              dispatch(
                enrollmentDetailActions.updateClubInfoNestItemDescription(
                  e.target.value
                )
              );
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NestItemOptionDetailModal;
