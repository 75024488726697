import { get } from '../../../../../service/api';

export const getRankSettingsList = async (payload: {
  merchantName: string;
  offset: number;
  limit: number;
}) => {
  try {
    const { merchantName, offset, limit } = payload;
    const params = { offset, limit };
    const res = await get(
      `/merchants/${merchantName}/ranking-settings`,
      params,
      {},
      {}
    );

    return res;
  } catch (e) {
    throw new Error('get ranking setting failed');
  }
};
