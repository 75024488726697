import { get, post, put, deleteRequest } from '../../../../../service/api';

export const getMerchandises = async (payload: {
  merchantName: string;
  offset: number;
  limit: number;
  configOptions?: any;
}) => {
  try {
    const { merchantName, offset, limit, configOptions = {} } = payload;
    const params = { offset, limit };

    const result = await get(
      `/merchants/${merchantName}/market/merchandises`,
      params,
      {},
      configOptions
    );

    return result;
  } catch (e) {
    throw new Error('get merchandise failed');
  }
};

export const postMerchandise = async (payload: {
  merchantName: string;
  merchandise: any;
}) => {
  try {
    const { merchantName, merchandise } = payload;

    const result = await post(
      `/merchants/${merchantName}/market/merchandises`,
      merchandise,
      { isShow: true }
    );

    return result;
  } catch (e) {
    throw new Error('post merchandise failed');
  }
};

export const deleteMerchandise = async (payload: {
  merchantName: string;
  id: string;
}) => {
  try {
    const { merchantName, id } = payload;

    const result = await deleteRequest(
      `/merchants/${merchantName}/market/merchandises/${id}`,
      {
        isShow: false,
      }
    );

    return result;
  } catch (e) {
    throw new Error('delete merchandise failed');
  }
};

export const putMerchandise = async (payload: {
  merchantName: string;
  id: string;
  merchandise: any;
}) => {
  try {
    const { merchantName, id, merchandise } = payload;

    const result = await put(
      `/merchants/${merchantName}/market/merchandises/${id}`,
      merchandise,
      {
        isShow: false,
      }
    );

    return result;
  } catch (e) {
    throw new Error('put merchandise failed');
  }
};

export const getMerchandiseById = async (payload: {
  merchantName: string;
  id: string;
}) => {
  try {
    const { merchantName, id } = payload;

    const result = await get(
      `/merchants/${merchantName}/market/merchandises/${id}`,
      {},
      {},
      { isShow: false }
    );

    return result;
  } catch (e) {
    throw new Error('get merchandise by id failed');
  }
};

export const getAssetsSetting = async (payload: { merchantName: string }) => {
  try {
    const { merchantName } = payload;

    const result = await get(
      `/merchants/${merchantName}/assets/setting`,
      {},
      {},
      { isShow: false }
    );

    return result;
  } catch (e) {
    throw new Error('get assets setting failed');
  }
};
