/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback } from 'react';
import { PlusOutlined, DeleteFilled } from '@ant-design/icons';
import { Button, Space, List, Modal, Checkbox } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useMount } from 'react-use';
import { useNavigate } from 'react-router';
import { AppDispatch } from '../../common/utils/types';
import {
  getMemberCards,
  getIsAutoGenerateCardNumber,
} from './redux/common/selectors';
import {
  loadMemberCards,
  deleteTheMemberCard,
  revertTheMemberCard,
  memberCardItemClicked,
  updateMemberCardsSetting,
} from './redux/common/thunks';
import ContentDetail from '../../common/components/ContentDetail';
import MemberCardDetails from './components/MemberCardDetails';
import NewMemberCard from './components/NewMemberCard';
import './MemberCards.less';

const MemberCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const isAutoGenerateCardNumber = useSelector(getIsAutoGenerateCardNumber);
  const memberCards = useSelector(getMemberCards);
  const handleIsAutoGenerateCardNumberChange = useCallback(
    (e: any) => {
      dispatch(updateMemberCardsSetting(e.target.checked));
    },
    [dispatch]
  );
  const handleMemberCardItemClicked = (id: string) => {
    dispatch(memberCardItemClicked(id));
  };
  const handleMemberCardItemDeleted = (id: string, type: string) => {
    dispatch(
      deleteTheMemberCard({
        id,
        type,
      })
    );
  };
  const handleMemberCardItemReverted = (id: string, type: string) => {
    dispatch(
      revertTheMemberCard({
        id,
        type,
      })
    );
  };

  useMount(() => {
    dispatch(loadMemberCards() as any);
  });

  return (
    <ContentDetail title="个性化会员卡">
      <div className="memberCardContainer">
        <div className="memberCardAutoGenerateNumber">
          <Checkbox
            checked={isAutoGenerateCardNumber}
            onChange={(e) => {
              handleIsAutoGenerateCardNumberChange(e);
            }}
          >
            自动生成卡号
          </Checkbox>
        </div>
        <List
          grid={{
            gutter: 24,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 4,
            xl: 5,
            xxl: 6,
          }}
          dataSource={[
            {
              id: 'addCard',
              imageUrl: <PlusOutlined className="memberCardAddIcon" />,
            },
            ...memberCards,
          ]}
          renderItem={({
            id,
            imageUrl,
            deleted,
            type,
          }: {
            id: string;
            imageUrl: string;
            deleted: boolean;
            type: string;
          }) => {
            if (id === 'addCard') {
              return (
                <List.Item key={id} className="memberCardAddItem">
                  <div className="memberCardContent">
                    <NewMemberCard imageUrl={imageUrl} />
                  </div>
                </List.Item>
              );
            }

            return (
              <List.Item key={id} className="memberCardItem">
                <div
                  className="memberCardContent"
                  onClick={() => {
                    handleMemberCardItemClicked(id);
                  }}
                >
                  {imageUrl ? (
                    <figure className="memberCardContentImage">
                      <img
                        className={
                          deleted ? 'memberCardContentDeletedImage' : ''
                        }
                        src={imageUrl}
                        alt=""
                      />
                    </figure>
                  ) : (
                    <div className="memberCardContentImage flex flex-column flex-middle flex-center">
                      <h3>{type}</h3>
                      <sub>没有找到卡面照片，请点击修改上传</sub>
                    </div>
                  )}

                  {deleted ? (
                    <div className="memberCardContentDeletedDetails flex flex-column flex-middle flex-center">
                      <Button
                        className="memberCardContentRevertButton"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          Modal.confirm({
                            centered: true,
                            title: '确认恢复此卡种吗？',
                            okText: '确认',
                            cancelText: '取消',
                            onOk: () => {
                              handleMemberCardItemReverted(id, type);
                            },
                          });
                        }}
                      >
                        <span>恢复</span>
                      </Button>
                    </div>
                  ) : (
                    <div className="memberCardContentDetails flex flex-column flex-middle flex-center">
                      {imageUrl ? (
                        <h3 className="memberCardContentTitle">{type}</h3>
                      ) : null}
                      <p className="memberCardContentDescription">
                        点击查看详情或修改
                      </p>
                      <Button
                        className="memberCardDeleteButton"
                        type="text"
                        onClick={(e) => {
                          e.stopPropagation();
                          Modal.confirm({
                            centered: true,
                            title: '删除之后无法在店员端为会员添加此卡种',
                            okText: '确认',
                            cancelText: '取消',
                            onOk: () => {
                              handleMemberCardItemDeleted(id, type);
                            },
                          });
                        }}
                      >
                        <DeleteFilled className="memberCardDeleteButtonIcon" />
                      </Button>
                    </div>
                  )}
                </div>
              </List.Item>
            );
          }}
        />

        <Space size="middle">
          <Button
            className="outlineButton"
            onClick={() => {
              navigate(-1);
            }}
          >
            返回
          </Button>
        </Space>
      </div>
      <MemberCardDetails />
    </ContentDetail>
  );
};

export default MemberCard;
