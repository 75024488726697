export const ExchangeItemType = {
  ASSETS: 'assets',
  VOUCHER: 'voucher',
};

export const STATUS_OPTIONS = [
  { label: '展示', value: 'exhibit' },
  { label: '下架', value: 'takeDown' },
];

export const EXCHANGE_BY_ASSETS_CATEGORIES = [
  {
    label: '权益',
    value: 'assets',
  },
];

export const EXCHANGE_CATEGORIES = [
  {
    label: '权益',
    value: 'assets',
  },
  {
    label: '券码',
    value: 'voucher',
  },
];
