import React from 'react';
import { Tag } from 'antd';
import { useMount } from 'react-use';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../../common/utils/types';
import ContentDetail from '../../../../../../common/components/ContentDetail';
import TableResponsiveFrame from '../../../../../../common/components/tables/TableResponsiveFrame';
import {
  getHistoryClubsRankingImportTaskList,
  getHistoryClubsRankingImportTasksPageNumber,
  getHistoryClubsRankingImportTasksPageSize,
  getHistoryClubsRankingImportTasksTotal,
} from './redux/selectors';
import { actions as clubsRankingImportActions } from './redux';
import {
  loadHistoryClubsRankingImportTasks,
  loadHistoryClubsRankingImportTempTournament,
  updateHistoryClubsRankingImportTasksPageNumber,
  updateHistoryClubsRankingImportTasksPageSize,
} from './redux/thunks';
import ImportDescription from './components/ImportDescription';
import BreadcrumbRouter from '../../../../../../common/components/BreadcrumbRouter';
import './ClubsRankingImport.less';

const createContentMarkup = (content: any) => {
  return { __html: content };
};
const ClubsRankingImport = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { associationHistoryTournamentTemplateId }: any = useParams();
  const historyClubsRankingImportTaskList = useSelector(
    getHistoryClubsRankingImportTaskList
  );
  const pageNumber = useSelector(getHistoryClubsRankingImportTasksPageNumber);
  const pageSize = useSelector(getHistoryClubsRankingImportTasksPageSize);
  const total = useSelector(getHistoryClubsRankingImportTasksTotal);
  const columns: any = [
    {
      title: '开始时间',
      width: 140,
      dataIndex: 'createdTime',
      key: 'time',
      align: 'center',
    },
    {
      title: '操作人',
      width: 120,
      dataIndex: 'operatorName',
      key: 'name',
      align: 'center',
      render: (name: any) => (
        <div dangerouslySetInnerHTML={createContentMarkup(name)} />
      ),
    },
    {
      title: '状态',
      width: 120,
      dataIndex: 'status',
      key: 'archived',
      align: 'center',
      render: (status: any) => (
        <Tag
          color={
            status === 'imported'
              ? 'success'
              : status === 'failed'
              ? 'error'
              : 'processing'
          }
        >
          {status === 'imported'
            ? '已导入'
            : status === 'failed'
            ? '导入失败'
            : '导入中'}
        </Tag>
      ),
    },
    {
      title: '文件(含错误提示)',
      width: 120,
      dataIndex: 'fileUrl',
      key: 'remarkName',
      align: 'center',
      render: (_: any, record: any) =>
        record.status !== 'pending' ? (
          <a
            href={
              record.fileUrl.startsWith('http://')
                ? record.fileUrl.replace('http', 'https')
                : record.fileUrl
            }
          >
            点击下载
          </a>
        ) : null,
    },
  ];

  useMount(async () => {
    await dispatch(
      clubsRankingImportActions.associationHistoryTournamentTemplateIdUpdated(
        associationHistoryTournamentTemplateId
      )
    );
    dispatch(loadHistoryClubsRankingImportTasks({}));
    dispatch(loadHistoryClubsRankingImportTempTournament());
  });

  return (
    <ContentDetail
      title="导入俱乐部名次表"
      headerLeft={
        <BreadcrumbRouter
          routes={[
            {
              path: 'functions',
              breadcrumbName: '功能',
            },
            {
              path: `history/clubs-ranking-import/${associationHistoryTournamentTemplateId}`,
              breadcrumbName: '导入俱乐部名次表',
            },
          ]}
        />
      }
    >
      <div className="ClubsRankingImport">
        <ImportDescription
          tempTournamentId={associationHistoryTournamentTemplateId}
        />
        <TableResponsiveFrame
          columns={columns}
          pageNumber={pageNumber}
          pageSize={pageSize}
          data={historyClubsRankingImportTaskList}
          dataItemsTotal={total}
          onChangePageSize={async (_: number, pageSize: number) => {
            await dispatch(
              updateHistoryClubsRankingImportTasksPageSize(pageSize)
            );
          }}
          onChangePageNumber={async (pageNumber: number) => {
            await dispatch(
              updateHistoryClubsRankingImportTasksPageNumber(pageNumber)
            );
          }}
          onRowClick={(record: any) => {
            return record.id ? record.id + Date.now() : 1;
          }}
        />
      </div>
    </ContentDetail>
  );
};

export default ClubsRankingImport;
