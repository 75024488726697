import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../common/utils/types';
import { getUserAuthorization } from '../../../../redux/app-v2/user/selectors';
import { getMerchantName } from '../../../../redux/app-v2/merchant/selectors';
import { getMemberImageUrl } from '../../utils';

export { getUserAuthorization, getMerchantName };

export const getIsAutoGenerateCardNumber = (state: RootState) =>
  state.memberCards.common.autoGenCardNum;

export const getOriginalMemberCards = (state: any) =>
  state.memberCards.common.types;

export const getMemberCards = createSelector(
  getOriginalMemberCards,
  (memberCards) =>
    memberCards.map((card: any) => ({
      imageUrl: getMemberImageUrl(card.skinImageUrl),
      id: card.id,
      description: card.description,
      deleted: typeof card.deleted === 'boolean' ? card.deleted : false,
      type: card.type,
    }))
);
