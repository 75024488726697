/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useUnmount } from 'react-use';
import {
  PlusCircleOutlined,
  ImportOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Space,
  Tag,
  Input,
  Select,
  Modal,
  Form,
  FormInstance,
} from 'antd';
import { AppDispatch } from '../../../../../../common/utils/types';
import TableResponsiveFrame from '../../../../../../common/components/tables/TableResponsiveFrame';
import { ContentTableColumnPropsType } from '../../../../../../common/components/tables/ContentTable/types';
import ContentDetail from '../../../../../../common/components/ContentDetail';

import { actions as clubAuthenticationActions } from './redux';
import './ClubAuthentication.less';
import {
  loadClubAuthentication,
  transferToClubAuthentication,
} from './redux/thunks';
import {
  getClubAuthenticationShowTransferModal,
  getClubAuthenticationTransferMemberData,
  getClubAuthenticationTransferMemberId,
  getClubAuthenticationFiltersIsMember,
  getClubAuthenticationPageNumber,
  getClubAuthenticationPageSize,
  getClubAuthenticationTableData,
  getClubAuthenticationTotal,
} from './redux/selector';
import { getIsBaseball } from '../../../../../../redux/app-v2/merchant/selectors';

const MEMBER_ID_REGEX = /^[0-9]*$/;
const ClubAuthentication = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const clubAuthenticationTableData = useSelector(
    getClubAuthenticationTableData
  );
  const pageNumber = useSelector(getClubAuthenticationPageNumber);
  const pageSize = useSelector(getClubAuthenticationPageSize);
  const dataTotal = useSelector(getClubAuthenticationTotal);
  const filtersIsMember = useSelector(getClubAuthenticationFiltersIsMember);
  const showTransferModal = useSelector(getClubAuthenticationShowTransferModal);
  const memberId = useSelector(getClubAuthenticationTransferMemberId);
  const onSearch = async (value: string) => {
    await dispatch(clubAuthenticationActions.updateSearchInputText(value));
    dispatch(clubAuthenticationActions.updatePageNumber(0));
    dispatch(loadClubAuthentication() as any);
  };
  const isBaseball = useSelector(getIsBaseball);
  const clubText = isBaseball ? '联盟球队' : '成员单位';

  const columns: ContentTableColumnPropsType[] = [
    {
      title: '成员单位全称（行业名称）',
      width: 400,
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      mobileAlign: 'headerLeft',
    },
    {
      title: `${clubText}号`,
      dataIndex: 'memberId',
      key: 'memberId',
      align: 'center',
      render: (memberId: string) => <span>{memberId || '-'}</span>,
    },
    {
      title: '联系人电话',
      dataIndex: 'contactPhoneNumber',
      key: 'contactPhoneNumber',
      align: 'center',
    },
    {
      title: '申请日期',
      dataIndex: 'applyingDate',
      key: 'applyingDate',
      align: 'center',
    },
    {
      title: '类型',
      dataIndex: 'memberId',
      key: 'memberId',
      align: 'center',
      render: (memberId: string) =>
        memberId ? (
          <Tag color="processing">{clubText}</Tag>
        ) : (
          <Tag color="error">非{clubText}</Tag>
        ),
    },
    {
      title: '小程序',
      dataIndex: 'clubMerchantName',
      key: 'clubMerchantName',
      align: 'center',
      render: (clubMerchantName: string) =>
        clubMerchantName ? (
          <Tag color="success">已注册</Tag>
        ) : (
          <Tag color="error">未注册</Tag>
        ),
    },
    {
      title: '操作',
      dataIndex: 'memberId',
      key: 'memberId',
      align: 'center',
      render: (memberId: string, record) => {
        if (memberId) {
          return '-';
        } else {
          return (
            <Button
              ghost
              type="primary"
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                dispatch(
                  clubAuthenticationActions.transferMemberDataUpdated(record)
                );
                dispatch(
                  clubAuthenticationActions.showTransferModalUpdated(true)
                );
              }}
            >
              升级为{clubText}
            </Button>
          );
        }
      },
    },
  ];

  useEffect(() => {
    dispatch(loadClubAuthentication() as any);
  }, [pageNumber, pageSize]);

  useUnmount(async () => {
    dispatch(clubAuthenticationActions.filtersDataReset());
  });

  return (
    <ContentDetail
      title={`${clubText}认证`}
      headerRight={
        <div>
          <Button
            className="ClubAuthenticationAddButton"
            onClick={() => {
              navigate('/association-activities/clubs/import');
            }}
          >
            <div className="ClubAuthenticationAddButtonText flex">
              <ImportOutlined style={{ fontSize: '19px' }} />
              表格导入
            </div>
          </Button>
          <Button
            className="ClubAuthenticationAddButton"
            onClick={() => {
              navigate('/association-activities/clubs/new');
            }}
          >
            <div className="ClubAuthenticationAddButtonText flex">
              <PlusCircleOutlined style={{ fontSize: '19px' }} />
              添加认证单位
            </div>
          </Button>
        </div>
      }
    >
      <div className="content__filter flex flex-middle">
        <div className="form__group">
          <Space size="middle">
            <Input.Search
              placeholder="搜索成员单位"
              onSearch={onSearch}
              style={{ width: 200 }}
            />
            <Select
              dropdownMatchSelectWidth={120}
              defaultValue={null}
              value={filtersIsMember}
              onChange={async (value: string) => {
                await dispatch(
                  clubAuthenticationActions.filtersIsMemberUpdated(value)
                );
                dispatch(loadClubAuthentication());
              }}
            >
              <Select.Option value={null}>全部单位</Select.Option>
              <Select.Option value={true}>{clubText}</Select.Option>
              <Select.Option value={false}>非{clubText}</Select.Option>
            </Select>
          </Space>
        </div>
      </div>
      <TableResponsiveFrame
        columns={columns}
        pageNumber={pageNumber + 1}
        pageSize={pageSize}
        data={clubAuthenticationTableData}
        dataItemsTotal={dataTotal}
        onRowClick={(record: any) => {
          navigate(`/association-activities/clubs/${record.id}`);
          return true;
        }}
        onChangePageSize={async (_: number, pageSize: number) => {
          await dispatch(clubAuthenticationActions.updatePageSize(pageSize));
        }}
        onChangePageNumber={async (pageNumber: number) => {
          await dispatch(
            clubAuthenticationActions.updatePageNumber(pageNumber - 1)
          );
        }}
      />
      <Modal
        centered
        title={null}
        closable={false}
        visible={showTransferModal}
        width={400}
        onCancel={() => {
          dispatch(clubAuthenticationActions.showTransferModalUpdated(false));
        }}
        onOk={async () => {
          try {
            await form.validateFields();

            await dispatch(transferToClubAuthentication() as any);
            dispatch(loadClubAuthentication() as any);
            dispatch(clubAuthenticationActions.transferMemberDataUpdated(null));
            dispatch(clubAuthenticationActions.showTransferModalUpdated(false));
          } catch (error) {
            throw error;
          }
        }}
      >
        <div className="flex flex-top ClubAuthenticationTransferContainer">
          <ExclamationCircleOutlined className="ClubAuthenticationTransferIcon" />

          <div className="ClubAuthenticationTransferContent">
            <h3>确认升级为{clubText}？</h3>
            <span className="ClubAuthenticationTransferDescription">
              升级后，该单位将成为{clubText}
            </span>
            <Form form={form} ref={React.createRef<FormInstance>()}>
              <Form.Item
                className="ClubAuthenticationTransferFormItem"
                name="memberId"
                label={isBaseball ? '联盟球队号' : '成员单位号'}
                rules={[
                  {
                    required: true,
                    message: `${
                      isBaseball ? '联盟球队号' : '成员单位号'
                    }不能为空`,
                  },
                  {
                    pattern: MEMBER_ID_REGEX,
                    message: `请填写正确的${
                      isBaseball ? '联盟球队号' : '成员单位号'
                    }，${isBaseball ? '联盟球队号' : '成员单位号'}为数字组成`,
                  },
                ]}
              >
                <Input
                  value={memberId}
                  type="text"
                  placeholder="例子：12345678"
                  onChange={(e) => {
                    dispatch(
                      clubAuthenticationActions.transferMemberIdUpdated(
                        e.target.value
                      )
                    );
                  }}
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </ContentDetail>
  );
};

export default ClubAuthentication;
