import { createSlice } from '@reduxjs/toolkit';
import { RolesMappingTypes } from './types';
import { loadRoles } from './thunks';

const initialState: RolesMappingTypes = {};

export const { reducer, actions } = createSlice({
  name: 'app/roles',
  initialState,
  reducers: {},
  extraReducers: {
    [loadRoles.pending.toString()]: (state, action) => {
      return state;
    },
    [loadRoles.fulfilled.toString()]: (state, action) => {
      const list = action.payload;
      const roles: any = state;

      list.forEach((role: { id: string }) => {
        const { id } = role || {};

        if (state[id]) {
          roles[id] = { ...state[id], ...role };
        } else if (id) {
          roles[id] = role;
        }
      });

      return roles;
    },
    [loadRoles.rejected.toString()]: (state, action) => {
      return state;
    },
  },
});

export default reducer;
