import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { RootState } from '../../../../../redux/store';
import { saveUserInfoToLocale } from '../../../../../redux/app-v2/user/thunks';
import { saveMerchantInfoToLocale } from '../../../../../redux/app-v2/merchant/thunks';
import { formatAuth } from '../../../../../common/utils';
import { getMerchantName, getAccountName, getRegisterInfo } from './selectors';
import { postUserRegister, getBusinessType } from './api-request';
import { postOtp } from '../../../api-request';

/**
 * Business type get
 */
export const loadBusinessType = createAsyncThunk(
  'register/loadBusinessType',
  async () => {
    try {
      const result: any = await getBusinessType();

      return result.data;
    } catch (e) {
      throw e;
    }
  }
);

/**
 * Otp created
 */
export const createOtp = createAsyncThunk(
  'register/createOtp',
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);
      const accountName = getAccountName(state);

      await postOtp({
        merchantName,
        phoneNumber: accountName,
      });
    } catch (e) {
      notification.error({
        message: '获取验证码失败',
        description: '服务器开小差',
      });
    }
  }
);

/**
 * User register
 */
export const userRegister = createAsyncThunk(
  'register/userRegister',
  async (navigateToHomePage: Function, { getState, dispatch }) => {
    const state = getState() as RootState;
    const registerInfo = getRegisterInfo(state);

    try {
      const result: any = await postUserRegister(registerInfo);
      const { data } = result;

      await dispatch(
        saveMerchantInfoToLocale({ merchantName: registerInfo.merchantName })
      );
      await dispatch(
        saveUserInfoToLocale({
          id: data.characterId,
          authorization: formatAuth(data.bearerToken),
          isLogin: true,
        })
      );

      navigateToHomePage();
    } catch (e: any) {
      if (e.message === '服务器拒绝本次访问') {
        notification.error({
          message: '注册失败',
          description: '验证码出错',
        });
      } else if (e.message === 'already exist') {
        notification.error({
          message: '注册失败',
          description: '此场馆账户已被注册,请更换场馆名字',
        });
      } else {
        notification.error({
          message: '注册失败',
          description: '服务器开小差',
        });
      }
    }
  }
);
