import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMount, useUnmount } from 'react-use';
import { Button, Input, Select, Space, Tag } from 'antd';
import { ImportOutlined, InboxOutlined } from '@ant-design/icons';
import { AppDispatch } from '../../../../../../redux/store';
import { ContentTableColumnPropsType } from '../../../../../../common/components/tables/ContentTable/types';
import ContentDetail from '../../../../../../common/components/ContentDetail';
import TableResponsiveFrame from '../../../../../../common/components/tables/TableResponsiveFrame';
import {
  getAssociationDisplayPlayers,
  getAssociationPlayerListPageNumber,
  getAssociationPlayerListPageSize,
  getAssociationPlayerListTotal,
  getIsSearchClubsFilterLoading,
  getSearchClubsFilterOptions,
  getSelectedAssociationPlayersAssociationClubId,
} from './redux/list/selectors';
import {
  loadAssociationPlayerList,
  loadSearchClubsFilter,
  selectAssociationPlayerListSearchAssociationClubId,
  updateAssociationPlayerListPageNumber,
  updateAssociationPlayerListPageSize,
  updateAssociationPlayerListSearchKeyword,
} from './redux/list/thunks';
import { actions as playerListActions } from './redux/list';
import { actions as playerDetailActions } from './redux/detail';
import AddPlayer from './components/AddPlayer';
import './AssociationPlayerList.less';
import PlayerDetail from './components/PlayerDetail';
import { getIsBaseball } from '../../../../../../redux/app-v2/merchant/selectors';

const GENDER_MAPPING: any = {
  female: '女',
  male: '男',
};

let AssociationPlayerListSearchClubsTimer: any = null;
const AssociationPlayers = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isSearchClubsFilterLoading = useSelector(getIsSearchClubsFilterLoading);
  const selectedAssociationPlayersAssociationClubId = useSelector(
    getSelectedAssociationPlayersAssociationClubId
  );
  const searchClubsFilterOptions = useSelector(getSearchClubsFilterOptions);
  const associationDisplayPlayers = useSelector(getAssociationDisplayPlayers);
  const pageNumber = useSelector(getAssociationPlayerListPageNumber);
  const pageSize = useSelector(getAssociationPlayerListPageSize);
  const total = useSelector(getAssociationPlayerListTotal);
  const isBaseball = useSelector(getIsBaseball);
  const clubText = isBaseball ? '联盟球队' : '成员单位';
  const playerText = isBaseball ? '球员' : '运动员';

  const columns: ContentTableColumnPropsType[] = [
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      mobileAlign: 'headerLeft',
    },
    {
      title: '性别',
      dataIndex: 'gender',
      key: 'gender',
      align: 'center',
      render: (gender: string) => {
        return GENDER_MAPPING[gender];
      },
    },
    {
      title: `所属${clubText}`,
      dataIndex: 'associationClubName',
      key: 'associationClubName',
      align: 'center',
      render: (associationClubName: any) => {
        return <Tag color="success">{associationClubName}</Tag>;
      },
    },
    {
      title: `${clubText}编号`,
      dataIndex: 'associationClubMemberId',
      key: 'associationClubMemberId',
      align: 'center',
    },
    {
      title: `${playerText}编号`,
      dataIndex: 'registerNo',
      key: 'registerNo',
      align: 'center',
      mobileAlign: 'contentHeaderLeft',
    },
    {
      title: '证件号',
      dataIndex: 'idCardNum',
      key: 'idCardNum',
      align: 'center',
    },
    {
      title: '联系电话',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
    },
    {
      title: '注册时间',
      dataIndex: 'registerDate',
      key: 'registerDate',
      align: 'center',
    },
    {
      title: '有效期',
      dataIndex: 'expiredDate',
      key: 'expiredDate',
      align: 'center',
    },
  ];

  useMount(() => {
    dispatch(loadSearchClubsFilter());
    dispatch(loadAssociationPlayerList({}));
  });

  useUnmount(() => {
    dispatch(playerListActions.filtersDataReset());
  });

  return (
    <ContentDetail
      title={`注册${playerText}`}
      headerRight={
        <div>
          <AddPlayer />
          <Button
            className="AssociationPlayerListAddButton"
            onClick={() => {
              navigate('/association/players/import');
            }}
          >
            <div className="AssociationPlayerListAddButtonText flex">
              <ImportOutlined />
              表格导入
            </div>
          </Button>
        </div>
      }
    >
      <div className="content__filter flex flex-middle">
        <div className="form__group">
          <Space size="middle">
            <Input.Search
              allowClear
              placeholder={`请输入关键字查找${playerText}`}
              onSearch={(value: string) => {
                dispatch(updateAssociationPlayerListSearchKeyword(value));
              }}
            />
            <Select
              showSearch
              allowClear
              className="AssociationPlayerListSearchClubsFilter"
              dropdownMatchSelectWidth={270}
              loading={isSearchClubsFilterLoading}
              placeholder={`搜索${clubText}名称`}
              value={selectedAssociationPlayersAssociationClubId}
              defaultActiveFirstOption={false}
              filterOption={false}
              onSearch={(value) => {
                clearTimeout(AssociationPlayerListSearchClubsTimer);

                AssociationPlayerListSearchClubsTimer = setTimeout(() => {
                  dispatch(loadSearchClubsFilter(value));
                }, 400);
              }}
              onChange={(value) => {
                dispatch(
                  selectAssociationPlayerListSearchAssociationClubId(value)
                );
              }}
              notFoundContent={
                <div className="flex flex-column flex-middle flex-center">
                  <InboxOutlined />
                  <span>未搜到此{clubText}</span>
                </div>
              }
              options={searchClubsFilterOptions}
            />
          </Space>
        </div>
      </div>
      <TableResponsiveFrame
        columns={columns}
        pageNumber={pageNumber + 1}
        pageSize={pageSize}
        data={associationDisplayPlayers}
        dataItemsTotal={total}
        onRowClick={(record: any) => {
          dispatch(
            playerDetailActions.associationPlayerDetailVisibilityShown()
          );
          dispatch(
            playerDetailActions.associationPlayerDetailIdUpdated(record.id)
          );

          return true;
        }}
        onChangePageSize={async (_: number, pageSize: number) => {
          await dispatch(updateAssociationPlayerListPageSize(pageSize));
        }}
        onChangePageNumber={async (pageNumber: number) => {
          await dispatch(updateAssociationPlayerListPageNumber(pageNumber - 1));
        }}
      />
      <PlayerDetail />
    </ContentDetail>
  );
};

export default AssociationPlayers;
