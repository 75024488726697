import { createSlice } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../../../../service/constants';
import { AssociationPlayersImportState } from './types';
import {
  loadAssociationPlayersImportTasks,
  loadAssociationPlayersImportTemplate,
} from './thunks';

const initialState: AssociationPlayersImportState = {
  data: {
    tasks: [],
    headers: [],
    pageNumber: 0,
    pageSize: 10,
    totalAssociationPlayersImportTasks: 0,
  },
  loadAssociationPlayersImportTasksStatus: API_REQUEST_STATUS.IDLE,
  loadAssociationPlayersImportTemplateStatus: API_REQUEST_STATUS.IDLE,
};

export const { reducer, actions } = createSlice({
  name: 'association/players/import',
  initialState,
  reducers: {},
  extraReducers: {
    [loadAssociationPlayersImportTasks.pending.type]: (state) => {
      state.loadAssociationPlayersImportTasksStatus =
        API_REQUEST_STATUS.PENDING;
    },
    [loadAssociationPlayersImportTasks.fulfilled.type]: (
      state,
      { payload }
    ) => {
      state.loadAssociationPlayersImportTasksStatus =
        API_REQUEST_STATUS.FULFILLED;
      state.data.tasks = payload;
    },
    [loadAssociationPlayersImportTasks.rejected.type]: (state) => {
      state.loadAssociationPlayersImportTasksStatus =
        API_REQUEST_STATUS.REJECTED;
    },
    [loadAssociationPlayersImportTemplate.pending.type]: (state) => {
      state.loadAssociationPlayersImportTemplateStatus =
        API_REQUEST_STATUS.PENDING;
    },
    [loadAssociationPlayersImportTemplate.fulfilled.type]: (
      state,
      { payload }
    ) => {
      const title = payload.firstLine.split(',');

      state.loadAssociationPlayersImportTemplateStatus =
        API_REQUEST_STATUS.FULFILLED;
      state.data.headers = title.map((item: string) => ({
        label: item,
        key: item,
      }));
    },
  },
});

export default reducer;
