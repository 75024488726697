import { createSlice } from '@reduxjs/toolkit';
import { loadClubAuthentication } from './thunks';

const initialState = {
  tableDataSource: [],
  pageSize: 10,
  pageNumber: 0,
  total: 0,
  end: true,
  showTransferModal: false,
  transferMemberId: null,
  transferMemberData: null,
  filters: {
    searchInputText: '',
    isMember: null,
  },
};

export const { reducer, actions } = createSlice({
  name: 'clubAuthentication',
  initialState,
  reducers: {
    updatePageNumber: (state: any, action: any) => {
      state.pageNumber = action.payload;
    },
    updatePageSize: (state: any, action: any) => {
      state.pageSize = action.payload;
    },
    updateSearchInputText: (state: any, action: any) => {
      state.filters.searchInputText = action.payload;
    },
    filtersIsMemberUpdated: (state: any, { payload }: any) => {
      state.filters.isMember = payload;
      state.pageNumber = 0;
    },
    filtersDataReset: (state) => {
      state.filters.searchInputText = '';
      state.filters.isMember = null;
    },
    transferMemberIdUpdated: (state, { payload }) => {
      state.transferMemberId = payload;
    },
    showTransferModalUpdated: (state, { payload }) => {
      state.showTransferModal = payload;
    },
    transferMemberDataUpdated: (state, { payload }) => {
      state.transferMemberData = payload;
    },
  },
  extraReducers: {
    [loadClubAuthentication.fulfilled.type]: (state, { payload }) => {
      const { data, pagination } = payload || {};

      state.tableDataSource = data;
      state.total = pagination?.total || 0;
    },
  },
});

export default reducer;
