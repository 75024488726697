import { createSlice } from '@reduxjs/toolkit';
import { loadStoreList } from './thunks';

const initialState = {
  ids: [],
  pageSize: 10,
  pageNumber: 0,
  total: 0,
  end: true,
};

export const { reducer, actions } = createSlice({
  name: 'stores',
  initialState,
  reducers: {
    updatePageNumber: (state: any, action: any) => {
      state.pageNumber = action.payload;
    },
    updatePageSize: (state: any, action: any) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: {
    [loadStoreList.fulfilled.type]: (state, { payload }) => {
      const { pagination, ids } = payload || {};
      const { end, total } = pagination || {};

      state.end = end || true;
      state.total = total || 0;
      state.ids = ids || [];
    },
  },
});

export default reducer;
