import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import { getMerchantName } from '../../../../../redux/app-v2/merchant/selectors';
import {
  getIsManager,
  getIsOwner,
} from '../../../../../redux/app-v2/user/selectors';
import { loadStoresV2 as loadEntitiesStores } from '../../../../../redux/entities/stores/thunks';
import {
  getRankSettingsById,
  postNewRankSettings,
  patchRankSettingsById,
  deleteRankSettingsById,
} from './api-request';
import {
  getCategoriesData,
  getRankingSettingData,
  getScoreLimitData,
} from './selectors';
import { ALL_STORES_ENTITIES } from '../../../../../redux/entities/stores/constant';
import { RootState } from '../../../../../common/utils/types';

export const loadRankingSettingsById: any = createAsyncThunk(
  'rankingDetails/loadRankSettingsById',
  async (id: string, { getState }) => {
    try {
      const state = getState();
      const merchantName = getMerchantName(state) as string;
      const res: any = await getRankSettingsById({ merchantName, id });

      return res.data;
    } catch (e) {
      message.error('加载排行榜管理详情失败');

      throw e;
    }
  }
);

export const addNewRankingSettings: any = createAsyncThunk(
  'rankingDetails/addRankSettingsById',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state) as string;
      const payload = getRankingSettingData(state);
      const categoriesPayload = getCategoriesData(state);
      const scoreLimitPayload = getScoreLimitData(state);
      const res = await postNewRankSettings({
        merchantName,
        ranksettings: payload,
        categories: categoriesPayload,
        scoreLimit: scoreLimitPayload,
      });

      message.success('更新排行榜管理详情成功');

      dispatch(loadRankingSettingsById(res.id));
    } catch (e) {
      message.error('添加排行榜管理详情失败');

      throw e;
    }
  }
);

export const updateRankingSettingsById: any = createAsyncThunk(
  'rankingDetails/updateRankingSettingsById',
  async (rankingsettingid: string, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);
      const payload = getRankingSettingData(state);
      const categoriesPayload = getCategoriesData(state);
      const scoreLimitPayload = getScoreLimitData(state);

      await patchRankSettingsById({
        merchantName,
        id: rankingsettingid,
        ranksettings: payload,
        categories: categoriesPayload,
        scoreLimit: scoreLimitPayload,
      });

      message.success('更新排行榜管理详情成功');
    } catch (e) {
      message.error('更新排行榜管理详情失败');
    }

    dispatch(loadRankingSettingsById(rankingsettingid));
  }
);

export const removeRankingSettingsById: any = createAsyncThunk(
  'rankingDetails/removeRankingSettingsById',
  async (rankingsettingid: string, { getState }) => {
    const state = getState() as RootState;
    const merchantName = getMerchantName(state);

    try {
      await deleteRankSettingsById({
        merchantName,
        id: rankingsettingid,
      });

      message.success('删除排行榜管理详情成功');
    } catch (e) {
      message.error('删除排行榜管理详情失败');
    }
  }
);

export const loadStoresFilter = createAsyncThunk(
  'rankingDetails/stores',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const isManager = getIsManager(state);
      const isOwner = getIsOwner(state);
      const offset: number = 0;
      const limit: number = 1000;

      const { payload }: any = await dispatch(
        loadEntitiesStores({ offset, limit })
      );

      if (isManager || isOwner) {
        payload.ids = [ALL_STORES_ENTITIES.id, ...payload.ids];
      }

      return payload;
    } catch (e) {
      message.error('加载门店列表失败');
    }
  }
);
