import React from 'react';
import { AppDispatch } from '../../../../../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Form, Checkbox, Tooltip, Switch, Popover } from 'antd';
import {
  MinusCircleOutlined,
  PlusOutlined,
  PlusCircleOutlined,
  QuestionCircleFilled,
} from '@ant-design/icons';
import { priceRoundFractionalToDollar } from '../../../../../../../../common/utils/currency';
import { actions as enrollmentDetailActions } from '../../redux';
import {
  getGameInfoData,
  getIsDisabledContent,
  getIsArcheryGameCategory,
} from '../../redux/selectors';
import {
  getIsArchery,
  getIsBaseball,
} from '../../../../../../../../redux/app-v2/merchant/selectors';
import GameOptionSettingNewModal from './GameOptionSettingNewModal';
import GameOptionSettingDetailModal from './GameOptionSettingDetailModal';
import GameArcheryOptionSettingDetailModal from './GameArcheryOptionSettingDetailModal';
import './GameOptionSetting.less';

const GameOptionSetting = ({ isNew, form }: { isNew: boolean; form: any }) => {
  const dispatch = useDispatch<AppDispatch>();
  const gameInfoData = useSelector(getGameInfoData);
  const isBaseball = useSelector(getIsBaseball);
  const isArchery = useSelector(getIsArchery);
  const isArcheryGameCategory = useSelector(getIsArcheryGameCategory);
  const isDisabledContent: any = useSelector(getIsDisabledContent);
  const clubText = isBaseball ? '联盟球队' : '成员单位';
  const playerText = isBaseball ? '球员' : '运动员';

  return (
    <section>
      <h5 className="EnrollmentDetailsAttributeTitle">参赛信息</h5>
      <Form.Item
        className="EnrollmentDetailsAttributeAllowCross formItem"
        label={
          <div className="flex flex-middle flex__shrink-fixed">
            <span className="EnrollmentDetailsCategorySwitchText">
              可同时报名多个项目
            </span>
            <Tooltip
              placement="topLeft"
              title="例如：选手可同时报名反曲弓50米和反曲弓30米"
            >
              <QuestionCircleFilled style={{ fontSize: '16px' }} />
            </Tooltip>
          </div>
        }
      >
        <Switch
          checked={gameInfoData.allowCrossCategories}
          disabled={isDisabledContent && !isNew}
          onChange={(checked: boolean) => {
            dispatch(
              enrollmentDetailActions.updateGameInfoAllowCrossCategories(
                checked
              )
            );
          }}
        />
      </Form.Item>

      <ul className="EnrollmentDetailsCategoryList">
        {(gameInfoData.categories || []).map((category: any) => {
          const {
            id: categoryId,
            isOpenOrYouthGroup,
            saved: categorySaved,
            name: categoryName,
            options: categoryOptions,
            isArchery: categoryIsArchery,
          } = category;
          const isArcheryCategory =
            categoryIsArchery ||
            categoryOptions.some((option: any) => option.isArchery);

          return (
            <li className="EnrollmentDetailsCategoryItem" key={categoryId}>
              <div className="flex flex-middle">
                <div className="flex flex-middle flex__shrink-fixed">
                  <Form.Item
                    name={[categoryId, 'isOpenOrYouthGroup']}
                    initialValue={isOpenOrYouthGroup}
                  >
                    <Checkbox
                      checked={isOpenOrYouthGroup}
                      disabled={categorySaved || (isDisabledContent && !isNew)}
                      onChange={(e) => {
                        dispatch(
                          enrollmentDetailActions.updateGameInfoCategoryIsOpenOrYouthGroup(
                            {
                              currentGameInfoCategoryId: category.id,
                              isOpenOrYouthGroup: e.target.checked,
                            }
                          )
                        );
                      }}
                    >
                      公开组
                    </Checkbox>
                  </Form.Item>
                  <Tooltip
                    className="EnrollmentDetailsCategoryIsOpenOrYouthGroupTooltipContainer"
                    placement="topLeft"
                    title={
                      <ul className="EnrollmentDetailsCategoryIsOpenOrYouthGroupTooltip">
                        <li>
                          设置成公开赛/青少年组将开放此项目报名，即：任意
                          {playerText}(注册{playerText}
                          /非注册{playerText})可代表任意俱乐部(
                          {clubText}/非{clubText})参赛。
                        </li>
                        <li>
                          取消勾选将限制为只能{clubText}报名，即：
                          <br />
                          1. 注册{playerText}只能代表其{clubText}参赛
                          <br />
                          2. 非注册{playerText}可代表任意{clubText}报名参赛
                        </li>
                      </ul>
                    }
                  >
                    <QuestionCircleFilled style={{ fontSize: '16px' }} />
                  </Tooltip>
                </div>
                <Form.Item
                  name={
                    isArcheryCategory ? undefined : [categoryId, 'categoryName']
                  }
                  initialValue={categoryName}
                  className="EnrollmentDetailsCategoryNameFormItem flex__shrink-fixed"
                  rules={
                    categorySaved
                      ? []
                      : [
                          { required: true, message: '项目名不能为空' },
                          () => ({
                            validator(_, categoryName) {
                              const fieldsValues = Object.values(
                                form.getFieldsValue()
                              );
                              const sameFields = fieldsValues.filter(
                                (fieldValue: any) =>
                                  fieldValue &&
                                  fieldValue?.categoryName === categoryName
                              );

                              if (sameFields && sameFields.length > 1) {
                                return Promise.reject(
                                  new Error('项目名不能重复')
                                );
                              }

                              return Promise.resolve();
                            },
                          }),
                        ]
                  }
                >
                  <Input
                    className="EnrollmentDetailsCategoryNameInput"
                    placeholder="项目名（例如：光弓50米），最多10个字"
                    maxLength={10}
                    disabled={
                      categorySaved ||
                      (isDisabledContent && !isNew) ||
                      isArcheryCategory
                    }
                    value={categoryName}
                    onChange={(e) => {
                      dispatch(
                        enrollmentDetailActions.updateGameInfoCategoryName({
                          currentGameInfoCategoryId: category.id,
                          GameInfoCategoryName: e.target.value,
                        })
                      );
                    }}
                  />
                </Form.Item>
                <Form.Item
                  className="EnrollmentDetailsOptionListFromItem"
                  name={[categoryId, 'categoryOptions']}
                  initialValue={categoryOptions}
                  rules={
                    categorySaved
                      ? []
                      : [
                          {
                            validator() {
                              if (categoryOptions.length <= 0) {
                                return Promise.reject(
                                  new Error('至少添加一个选项')
                                );
                              }

                              return Promise.resolve();
                            },
                          },
                        ]
                  }
                >
                  <ol
                    className={`EnrollmentDetailsOptionList flex flex-middle ${
                      categorySaved ? 'disabled' : ''
                    }`}
                  >
                    {(categoryOptions || []).map((option: any) => {
                      const {
                        id: optionId,
                        name: optionName,
                        saved: optionSaved,
                        teamPlayerCount,
                        enableChargePrice,
                        pricingInfo,
                        isArchery,
                      } = option;
                      const { price, associationPlayerPrice } =
                        pricingInfo || {};

                      return (
                        <li
                          key={optionId}
                          className="EnrollmentDetailsOptionItem"
                        >
                          <GameOptionSettingDetailModal
                            id={optionId}
                            disabled={isDisabledContent && !isNew}
                            name={optionName}
                            saved={optionSaved}
                            teamPlayerCount={teamPlayerCount}
                            enableChargePrice={enableChargePrice}
                            price={priceRoundFractionalToDollar(price)}
                            associationPlayerPrice={priceRoundFractionalToDollar(
                              associationPlayerPrice
                            )}
                            isArchery={isArchery}
                            categoryOptions={categoryOptions}
                            onDelete={() => {
                              dispatch(
                                enrollmentDetailActions.removeGameInfoCategoryOption(
                                  {
                                    currentGameInfoCategoryId: category.id,
                                    currentGameInfoCategoryOptionId: optionId,
                                  }
                                )
                              );
                            }}
                            onUpdate={(option) => {
                              dispatch(
                                enrollmentDetailActions.updateGameInfoCategoryOption(
                                  {
                                    currentGameInfoCategoryId: category.id,
                                    currentGameInfoCategoryOptionId: option.id,
                                    option,
                                  }
                                )
                              );
                            }}
                          />
                        </li>
                      );
                    })}

                    {categorySaved ? null : isArcheryCategory &&
                      categoryOptions.length > 0 ? (
                      <GameArcheryOptionSettingDetailModal
                        id={categoryId}
                        name={categoryName}
                        saved={categorySaved}
                        categoryOptions={categoryOptions}
                        onDelete={() => {
                          const optionIds = categoryOptions.map(
                            (option: any) => option.id
                          );

                          dispatch(
                            enrollmentDetailActions.updateGameInfoCategoryName({
                              currentGameInfoCategoryId: category.id,
                              GameInfoCategoryName: '',
                            })
                          );

                          optionIds.forEach((optionId: any) => {
                            dispatch(
                              enrollmentDetailActions.removeGameInfoCategoryOption(
                                {
                                  currentGameInfoCategoryId: category.id,
                                  currentGameInfoCategoryOptionId: optionId,
                                }
                              )
                            );
                          });

                          dispatch(
                            enrollmentDetailActions.removeGameInfoCategory({
                              currentGameInfoCategoryId: category.id,
                            })
                          );
                        }}
                        onDeleteOption={(optionId: any) => {
                          dispatch(
                            enrollmentDetailActions.removeGameInfoCategoryOption(
                              {
                                currentGameInfoCategoryId: category.id,
                                currentGameInfoCategoryOptionId: optionId,
                              }
                            )
                          );
                        }}
                        onAddOption={(option: any) => {
                          dispatch(
                            enrollmentDetailActions.addNewGameInfoCategoryOption(
                              {
                                currentGameInfoCategoryId: category.id,
                                option,
                              }
                            )
                          );
                        }}
                        onUpdateOption={(option: any) => {
                          dispatch(
                            enrollmentDetailActions.updateGameInfoCategoryOption(
                              {
                                currentGameInfoCategoryId: category.id,
                                currentGameInfoCategoryOptionId: option.id,
                                option,
                              }
                            )
                          );
                        }}
                      />
                    ) : (
                      <li className="EnrollmentDetailsOptionAddFieldItem flex flex-middle">
                        <Button
                          className="EnrollmentDetailsOptionAddFieldItemButton flex__fluid-content"
                          shape="circle"
                          size="small"
                          icon={<PlusOutlined />}
                          onClick={() => {
                            if (isArcheryCategory) {
                              dispatch(
                                enrollmentDetailActions.showArcheryGameOptionSettingNewModal()
                              );
                            } else {
                              dispatch(
                                enrollmentDetailActions.showGameOptionSettingNewModal()
                              );
                            }

                            dispatch(
                              enrollmentDetailActions.updateNewGameOptionSettingCategoryId(
                                category.id
                              )
                            );
                          }}
                        />
                      </li>
                    )}
                  </ol>
                </Form.Item>
                {categorySaved ? null : (
                  <Button
                    type="link"
                    size="small"
                    className="EnrollmentDetailsCategoryRemoveButton"
                    onClick={() => {
                      dispatch(
                        enrollmentDetailActions.removeGameInfoCategory({
                          currentGameInfoCategoryId: categoryId,
                        })
                      );
                    }}
                  >
                    <MinusCircleOutlined className="EnrollmentDetailsRemoveIcon" />
                  </Button>
                )}
              </div>
            </li>
          );
        })}
      </ul>

      {isDisabledContent && !isNew ? null : (
        <div className="EnrollmentDetailsCategoryAddFieldItem flex flex-middle">
          {isArchery && gameInfoData.categories.length === 0 ? (
            <Popover
              title={null}
              content={
                <div className="flex flex-column">
                  <Button
                    className="EnrollmentDetailsPopoverButton flex__fluid-content"
                    type="text"
                    onClick={() => {
                      dispatch(
                        enrollmentDetailActions.addNewGameInfoCategory()
                      );
                    }}
                  >
                    普通项目
                  </Button>
                  <Button
                    className="flex__fluid-content"
                    type="text"
                    onClick={() => {
                      dispatch(
                        enrollmentDetailActions.addNewGameInfoCategory({
                          isArchery: true,
                        })
                      );
                    }}
                  >
                    比赛项目
                  </Button>
                </div>
              }
            >
              <Button
                className="flex__fluid-content EnrollmentDetailsAttributeItemAddButtonContent"
                type="dashed"
              >
                添加参赛信息
                <PlusCircleOutlined />
              </Button>
            </Popover>
          ) : (
            <Button
              className="flex__fluid-content EnrollmentDetailsAttributeItemAddButtonContent"
              type="dashed"
              onClick={() => {
                dispatch(
                  enrollmentDetailActions.addNewGameInfoCategory({
                    isArchery: isArcheryGameCategory,
                  })
                );
              }}
            >
              添加参赛信息
              <PlusCircleOutlined />
            </Button>
          )}
        </div>
      )}

      <GameOptionSettingNewModal
        onAdd={(option) => {
          dispatch(
            enrollmentDetailActions.addNewGameInfoCategoryOption({
              currentGameInfoCategoryId: option.categoryId,
              option,
            })
          );
        }}
      />
    </section>
  );
};

export default GameOptionSetting;
