import { post, patch } from '../../../../../service/api';

export const postLogout = async (
  payload: { merchantName: string },
  options = { isShow: true }
) => {
  try {
    const { merchantName } = payload;

    await post(`/merchants/${merchantName}/employees.logout`, {}, options);
  } catch (e) {
    throw new Error('employee error');
  }
};

export const postOtp = async (
  payload: {
    merchantName: string;
    phoneNumber: string;
  },
  options?: { isShow: false }
) => {
  try {
    const { merchantName, ...restPayload } = payload;
    const result = await post(
      `/merchants/${merchantName}/accounts/otp`,
      restPayload,
      options
    );

    return result;
  } catch (e) {
    throw new Error('register failed');
  }
};

export const patchTransferOwner = async (
  merchantName: string,
  payload: {
    toEmployeeId: string;
    otp: string;
  },
  options?: { isShow: false }
) => {
  try {
    const result = await patch(
      `/merchants/${merchantName}/employees/owner-transfer`,
      payload,
      options
    );

    return result;
  } catch (error) {
    throw error;
  }
};
