import { get, post, put } from '../../../../../../../service/api';

export const getEnrollmentDetailsById = async (payload: { id: string }) => {
  try {
    const { id } = payload;
    const result: any = await get(`/association-enrolls/${id}`, {}, {});

    return result;
  } catch (e) {
    throw new Error('get enrollment details by id failed');
  }
};

export const postNewEnrollmentDetails = async (payload: {
  enrollmentDetails: any;
  clubFormInfo: any;
  playerInfo: any;
  gameInfo: any;
}) => {
  try {
    const { enrollmentDetails, clubFormInfo, playerInfo, gameInfo } = payload;
    const finalPayload = {
      ...enrollmentDetails,
      clubFormInfo,
      playerInfo,
      gameInfo,
    };
    const result: any = await post(`/association-enrolls`, finalPayload, {});

    return result;
  } catch (e) {
    throw new Error('post new enrollment details by failed');
  }
};

export const putEnrollmentDetailsById = async (payload: {
  id: string;
  enrollmentDetails: any;
  clubFormInfo: any;
  playerInfo: any;
  gameInfo: any;
}) => {
  const { id, enrollmentDetails, clubFormInfo, playerInfo, gameInfo } = payload;
  const finalPayload = {
    ...enrollmentDetails,
    clubFormInfo,
    playerInfo,
    gameInfo,
  };
  try {
    const result: any = await put(
      `/association-enrolls/${id}`,
      finalPayload,
      {}
    );

    return result;
  } catch (e) {
    throw new Error('patch new enrollment details by failed');
  }
};

export const getPosters = async (payload: {
  merchantName: string;
  offset: number;
  limit: number;
}) => {
  try {
    const { merchantName, offset, limit } = payload;
    const params = { offset, limit };

    const result = await get(
      `/merchants/${merchantName}/posters`,
      params,
      {},
      { isShow: false }
    );

    return result;
  } catch (e) {
    throw e;
  }
};

export const getArcheryCompetitionOptions = () =>
  get('/activities/archery/competition-setting', {}, {}, {});
