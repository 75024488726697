import _isNil from 'lodash/isNil';
import parsePhoneNumber from 'libphonenumber-js/mobile';

export const getInfoByPhoneNumber = (phoneNumber: string) => {
  const parsePhoneNumberObject = parsePhoneNumber(phoneNumber);

  if (_isNil(phoneNumber) || phoneNumber === '') {
    return '';
  }

  if (!parsePhoneNumberObject && typeof phoneNumber === 'string') {
    return phoneNumber;
  }

  return parsePhoneNumberObject;
};

export const getInternationalPhoneNumberObject = (
  code: string | number | undefined,
  phone: string | undefined
) => {
  if (_isNil(code) || code === '') {
    return;
  }

  if (typeof code === 'string' && code.startsWith('+')) {
    return parsePhoneNumber(`${code}${phone}`);
  }

  return parsePhoneNumber(`+${code}${phone}`);
};
