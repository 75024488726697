import { combineReducers } from '@reduxjs/toolkit';
import { reducer as common } from './common';
import { reducer as details } from './memberCardDetails';

const reducer = combineReducers({
  common,
  details,
});

export default reducer;
