import { createSlice } from '@reduxjs/toolkit';
import { loadMemberCards, updateMemberCardsSetting } from './thunks';

const initialState: {
  id: string;
  autoGenCardNum: boolean;
  types: {
    id: string;
    type: string;
    skinImageUrl: string;
    description: string;
    deleted: boolean;
  }[];
} = {
  id: '',
  autoGenCardNum: false,
  types: [],
};

export const { reducer, actions } = createSlice({
  name: 'member-cards',
  initialState,
  reducers: {},
  extraReducers: {
    [loadMemberCards.fulfilled.type]: (state, { payload }) => {
      state.id = payload.id;
      state.autoGenCardNum = payload.autoGenCardNum;
      state.types = payload.types;
    },
    [updateMemberCardsSetting.fulfilled.type]: (state, { payload }) => {
      state.autoGenCardNum = payload;
    },
  },
});

export default reducer;
