import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getMerchantBusiness,
  getMerchantName,
} from '../../../../../../../../redux/app-v2/merchant/selectors';
import {
  getAssociationPlayerById,
  putAssociationPlayer,
  putAssociationPlayerClub,
  delAssociationPlayerById,
} from './api-request';
import {
  errorMessage,
  successMessage,
} from '../../../../../../../../common/utils/feedback';
import { getFormatRequestDataTime } from '../../../../../../../../common/utils/time-lib';
import { actions as associationPlayersCommonActions } from '../common';
import {
  loadAssociationClubById,
  loadAssociationClubs,
} from '../common/thunks';
import { loadAssociationPlayerList } from '../list/thunks';
import {
  getSearchClubsParameters,
  getAssociationPlayerDetailData,
  getAssociationPlayerTransferClubId,
  getAssociationPlayerDetailId,
} from './selectors';
import { AssociationPlayerDetailData } from './types';
import { BUSINESS_TYPES } from '../../../../../../../../common/constants';

export const loadSearchClubsFilter = createAsyncThunk(
  'association/players/detail/loadSearchClubsFilter',
  async (keyword: string | undefined | null, { dispatch, getState }) => {
    try {
      const state: any = getState();
      const searchClubsParameters = getSearchClubsParameters(state);
      const payload = {
        ...searchClubsParameters,
        text: keyword || '',
        isMember: true,
      };
      const data: any = await dispatch(loadAssociationClubs(payload)).unwrap();

      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const loadAssociationPlayerById = createAsyncThunk(
  'association/players/detail/loadAssociationPlayerById',
  async (id: string, { dispatch, getState }) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);
      const result: any = await getAssociationPlayerById(merchantName, id);
      const { data } = result;
      const { associationClubId } = data || {};

      dispatch(loadAssociationClubById(associationClubId));
      dispatch(
        associationPlayersCommonActions.associationPlayerUpdatedById(data)
      );

      return data;
    } catch (error) {
      const state: any = getState();
      const business = getMerchantBusiness(state);
      errorMessage(
        `加载注册${
          business === BUSINESS_TYPES.Baseball ? '球员' : '运动员'
        }失败`
      );

      throw error;
    }
  }
);

export const updateAssociationPlayerDetail = createAsyncThunk(
  'association/players/detail/updateAssociationPlayerDetail',
  async (closeDetailModal: Function, { dispatch, getState }) => {
    const state: any = getState();
    const business = getMerchantBusiness(state);
    const playerText = business === BUSINESS_TYPES.Baseball ? '球员' : '运动员';
    try {
      const merchantName = getMerchantName(state);
      const payload: AssociationPlayerDetailData =
        getAssociationPlayerDetailData(state);
      const { id, registerDate, expiredDate } = payload || {};
      const result: any = await putAssociationPlayer(merchantName, id, {
        ...payload,
        registerDate: getFormatRequestDataTime(registerDate, 'YYYY-MM-DD'),
        expiredDate: getFormatRequestDataTime(expiredDate, 'YYYY-MM-DD'),
      });
      const { data } = result;

      dispatch(
        associationPlayersCommonActions.associationPlayerUpdatedById(data)
      );

      successMessage(`更新注册${playerText}成功`, {
        handleConfirm: () => {
          closeDetailModal();
        },
      });

      return data;
    } catch (error) {
      errorMessage(`更新注册${playerText}失败`);

      throw error;
    }
  }
);

export const transferClubForAssociationPlayer = createAsyncThunk(
  'association/players/detail/transferClubForAssociationPlayer',
  async (_, { dispatch, getState }) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);
      const id = getAssociationPlayerDetailId(state);
      const toClubId = getAssociationPlayerTransferClubId(state);
      const result: any = await putAssociationPlayerClub(merchantName, id, {
        toClubId,
      });
      const { data } = result;

      dispatch(
        associationPlayersCommonActions.associationPlayerUpdatedById({
          id,
          associationClubId: toClubId,
        })
      );

      return data;
    } catch (error) {
      const state: any = getState();
      const business = getMerchantBusiness(state);
      errorMessage(
        `注册${
          business === BUSINESS_TYPES.Baseball ? '球员' : '运动员'
        }转会失败`
      );

      throw error;
    }
  }
);

export const deleteAssociationPlayerById = createAsyncThunk(
  'association/players/detail/loadAssociationPlayerById',
  async (
    { id, closeDetailModal }: { id: string; closeDetailModal: Function },
    { dispatch, getState }
  ) => {
    const state: any = getState();
    const business = getMerchantBusiness(state);
    const playerText = business === BUSINESS_TYPES.Baseball ? '球员' : '运动员';
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);
      const result: any = await delAssociationPlayerById(merchantName, id);
      const { data } = result;

      await dispatch(
        associationPlayersCommonActions.associationPlayerUpdatedById({
          id,
          deleted: true,
        })
      );
      dispatch(loadAssociationPlayerList({}));

      successMessage(`删除注册${playerText}成功`, {
        handleConfirm: () => {
          closeDetailModal();
        },
      });

      return data;
    } catch (error) {
      errorMessage(`删除注册${playerText}失败`);

      throw error;
    }
  }
);

export const mounted = createAsyncThunk(
  'association/players/detail/mounted',
  async (_, { dispatch }) => {
    try {
      await dispatch(loadSearchClubsFilter(''));
    } catch (error) {
      throw error;
    }
  }
);
