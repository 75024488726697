import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import { RootState } from '../../../../../common/utils/types';
import { getMerchantName } from '../../../../../redux/app-v2/merchant/selectors';
import { getRankSettingsList } from './api-request';
import {
  getRankingManagementPageNumber,
  getRankingManagementPageSize,
} from './selectors';

export const loadRankSettingsList = createAsyncThunk(
  'rankingManagement/loadRankSettingsList',
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state) as string;
      const offset: number =
        getRankingManagementPageNumber(state) *
        getRankingManagementPageSize(state);
      const limit: number = getRankingManagementPageSize(state);
      const res: any = await getRankSettingsList({
        merchantName,
        limit,
        offset,
      });

      return res.data;
    } catch (e) {
      message.error('加载排行榜管理失败');

      throw e;
    }
  }
);
