import { get } from '../../../../../service/api';

export const getPlayerMemberCards = async (
  merchantName: string,
  id: string | undefined
) => {
  try {
    const result = await get(
      `/merchants/${merchantName}/players/${id}/assets/member-cards`,
      ''
    );

    return result;
  } catch (error) {
    throw error;
  }
};
