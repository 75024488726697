import { createSlice } from '@reduxjs/toolkit';
import { showMemberCardDetails, hideMemberCardDetails } from './thunks';
import { API_REQUEST_STATUS } from '../../../../service/constants';

const initialState: {
  selectedMemberCardDetailsId: string;
  skinImageUrl: string;
  uploadSkinImageStatus: string;
  type: string;
  description: string;
  memberCardDetailsVisibility: boolean;
} = {
  selectedMemberCardDetailsId: '',
  skinImageUrl: '',
  uploadSkinImageStatus: API_REQUEST_STATUS.IDLE,
  type: '',
  description: '',
  memberCardDetailsVisibility: false,
};

export const { reducer, actions } = createSlice({
  name: 'member-cards/details',
  initialState,
  reducers: {
    cardSkinImageUrlSet: (state, { payload }) => {
      state.skinImageUrl = payload.skinImageUrl;
    },
    cardSkinImageUploadStatusSet: (state, { payload }) => {
      if (payload === 'uploading') {
        state.uploadSkinImageStatus = API_REQUEST_STATUS.PENDING;
      } else if (payload === 'done') {
        state.uploadSkinImageStatus = API_REQUEST_STATUS.FULFILLED;
      } else if (payload === 'error') {
        state.uploadSkinImageStatus = API_REQUEST_STATUS.REJECTED;
      } else {
        state.uploadSkinImageStatus = API_REQUEST_STATUS.IDLE;
      }
    },
    cardTypeSet: (state, { payload }) => {
      state.type = payload;
    },
    cardDescriptionSet: (state, { payload }) => {
      state.description = payload;
    },
  },
  extraReducers: {
    [showMemberCardDetails.fulfilled.type]: (state, { payload }) => {
      state.memberCardDetailsVisibility = true;
      state.selectedMemberCardDetailsId = payload.id;
      state.skinImageUrl = payload.skinImageUrl;
      state.type = payload.type;
      state.description = payload.description;
    },
    [hideMemberCardDetails.fulfilled.type]: (state) => {
      state.memberCardDetailsVisibility = false;
      state.selectedMemberCardDetailsId = '';
      state.skinImageUrl = '';
      state.type = '';
      state.description = '';
    },
  },
});

export default reducer;
