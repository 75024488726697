import { createSlice } from '@reduxjs/toolkit';

interface MarketOrderDetailState {
  merchantName: string;
  playerId: string;
  orderNumber: string;
  status: string;
  createdTime: string;
  total: number;
  items: [
    {
      id: string;
      amount: number;
      merchandiseId: string;
      displayName: string;
      exchangeByType: string;
      exchangeByAssetsTypeId: string;
      exchangeByValue: number;
      exchangedType: string;
      exchangedAssetsTypeId: string;
    }
  ];
  assets: {};
}

const initialState: MarketOrderDetailState = {
  merchantName: 'zy',
  playerId: '620901570ef42e930ea988e3',
  orderNumber: '',
  createdTime: '',
  status: 'finished',
  total: 0,
  items: [
    {
      id: '62fbabfc8dfd3b7c1d8163b0',
      amount: 1,
      merchandiseId: '47823094978210',
      displayName: 'item 2',
      exchangeByType: 'assets',
      exchangeByAssetsTypeId: '784201834124124',
      exchangeByValue: 10,
      exchangedType: 'assets',
      exchangedAssetsTypeId: '74820740287402',
    },
  ],
  assets: {},
};

export const { reducer, actions } = createSlice({
  name: 'market/order/details',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.merchantName = action.payload.merchantName;
      state.playerId = action.payload.playerId;
      state.orderNumber = action.payload.orderNumber;
      state.status = action.payload.status;
      state.createdTime = action.payload.createdTime;
      state.items = action.payload.items;
      state.total = action.payload.total ? action.payload.total : 0;
    },
    setAssets: (state, action) => {
      state.assets = action.payload;
    },
  },
  extraReducers: {},
});

export const initType = initialState;

export const { setData, setAssets } = actions;
export default reducer;
