import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMount } from 'react-use';
import { useNavigate, Link } from 'react-router-dom';
import {
  Button,
  Form,
  FormInstance,
  Input,
  Divider,
  Space,
  message,
  Result,
} from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { AppDispatch } from '../../../../common/utils/types';
import {
  PHONE_NUMBER_REGEX,
  MERCHANT_ID_REGEX,
} from '../../../../common/constants';
import {
  getAccountName,
  getMerchantName,
  getPassword,
  getOtp,
} from './redux/selectors';
import { actions as forgetPasswordActions } from './redux';
import {
  getIsForgetPasswordStatusPending,
  getIsForgetPasswordStatusSuccess,
  getIsCreatedOtpStatusPending,
} from './redux/selectors';
import { passwordReset, createOtp } from './redux/thunks';
import RegisterImage from '../../../../images/entrance-image.png';
import LogoImage from '../../../../images/logo-2x.png';
import PhoneNumberInput from '../../../../common/components/PhoneNumberInput';
import { actions as userActions } from '../../../../redux/app-v2/user';
import './index.less';

const OTP_COUNT_DOWN_DURATION = 60;

// form verification rules
const FORM_RULES = {
  merchantName: [
    { required: true, message: '请输入商户账号' },
    {
      pattern: MERCHANT_ID_REGEX,
      message: '请填写正确商户账号格式',
    },
  ],
  accountName: [
    { required: true, message: '请输入手机号码' },
    {
      pattern: PHONE_NUMBER_REGEX,
      message: '请填写正确手机号码',
    },
  ],
  otp: [{ required: true, message: '请输入手机验证码' }],
  password: [{ required: true, message: '请输入密码' }],
  confirmPassword: [
    { required: true, message: '请输入确认密码' },
    ({ getFieldValue }: { getFieldValue: Function }) => ({
      validator(_: any, value: string) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }

        return Promise.reject(new Error('两个密码不一致！'));
      },
    }),
  ],
};

export default function ForgetPassword() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [confirmPassword, setConfirmPassword] = useState();
  const [countingDown, setCountingDown] = useState(false);
  const [countingDownTime, setCountingDownTime] = useState(
    OTP_COUNT_DOWN_DURATION
  );
  // get reset password status pending
  const isForgetPasswordStatusPending = useSelector(
    getIsForgetPasswordStatusPending
  );
  // get reset password status success
  const isForgetPasswordStatusSuccess = useSelector(
    getIsForgetPasswordStatusSuccess
  );
  // get otp status pending
  const isCreatedOtpStatusPending = useSelector(getIsCreatedOtpStatusPending);
  // get merchant name
  const merchantName = useSelector(getMerchantName);
  // get account name
  const accountName = useSelector(getAccountName);
  // get password
  const password = useSelector(getPassword);
  // get otp
  const otp = useSelector(getOtp);
  // update merchant name
  const handleMerchantNameChange = (e: { target: { value: any } }) => {
    dispatch(forgetPasswordActions.merchantNameUpdated(e.target.value));
  };
  // update account name
  const handleAccountNameChange = (phoneNumber: string) => {
    dispatch(forgetPasswordActions.accountNameUpdated(phoneNumber));
  };
  // update password
  const handlePasswordChange = (e: { target: { value: any } }) => {
    dispatch(forgetPasswordActions.passwordUpdated(e.target.value));
  };
  const handleOtpChange = (e: { target: { value: any } }) => {
    dispatch(forgetPasswordActions.otpUpdated(e.target.value));
  };
  // navigate to login page
  const navigateToLoginPage = async () => {
    await dispatch(userActions.userReset());
    await dispatch(forgetPasswordActions.dataRest());

    navigate('/login');
  };
  // otp resend count down
  let timeoutTimer: any = null;
  const otpResendCountDown = (time: number) => {
    if (time === 0) {
      setCountingDown(false);
      clearTimeout(timeoutTimer);
      return;
    }

    timeoutTimer = setTimeout(() => {
      time -= 1;
      setCountingDownTime(time);
      otpResendCountDown(time);
    }, 1000);
  };
  // create a new otp
  const handleOtpCreate = async () => {
    try {
      await form.validateFields(['merchantId', 'accountName']);
      setCountingDown(true);
      otpResendCountDown(OTP_COUNT_DOWN_DURATION);
      dispatch(createOtp());
    } catch (error: any) {
      message.error('获取验证码失败');
    }
  };
  // submit reset password form
  const handleForgetPasswordSubmit = () => {
    dispatch(passwordReset());
  };

  useMount(() => {
    document.title = '在也｜忘记密码';
  });

  if (isForgetPasswordStatusSuccess) {
    return (
      <div className="forgetPasswordSuccessResult flex flex-center flex-middle">
        <Result
          status="success"
          title="重置密码成功"
          subTitle="您的密码已经重置成功，可以回到登录页面重新登录"
          extra={[
            <Button type="primary" key="login" onClick={navigateToLoginPage}>
              回到登录
            </Button>,
          ]}
        />
      </div>
    );
  }

  return (
    <div className="forgetPassword">
      <div className="logoImageContainer">
        <img className="logoImage" src={LogoImage} alt="Zaiye logo" />
      </div>
      <div className="forgetPasswordContentContainer flex flex-middle">
        <div className="forgetPasswordImageContainer">
          <img
            className="forgetPasswordImage"
            src={RegisterImage}
            alt="Zaiye forgetPassword"
          />
        </div>
        <div className="flex__fluid-content" style={{ marginTop: '60px' }}>
          <div className="forgetPasswordContent">
            <div>
              <h2 className="forgetPasswordTitle">重置密码</h2>
              <p className="forgetPasswordDescription">
                请填写您订阅时申请的商家账户与员工注册电话用以重置密码
              </p>
              <Divider />
            </div>
            <Form
              className="registerForm"
              layout="vertical"
              form={form}
              ref={React.createRef<FormInstance>()}
              onFinish={handleForgetPasswordSubmit}
            >
              <Form.Item
                label="场馆账户"
                name="merchantId"
                rules={FORM_RULES.merchantName}
              >
                <Input
                  size="large"
                  placeholder="小写英文字符 不超过10位"
                  value={merchantName}
                  onChange={handleMerchantNameChange}
                />
              </Form.Item>
              <PhoneNumberInput
                label="账号"
                placeholder="手机号码"
                size="large"
                phoneNumber={accountName}
                name="accountName"
                onChange={(phoneNumber: string) => {
                  handleAccountNameChange(phoneNumber);
                }}
              />
              <Form.Item
                label="新密码"
                name="password"
                rules={FORM_RULES.password}
              >
                <Input.Password
                  size="large"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </Form.Item>
              <Form.Item
                label="确认密码"
                name="confirmPassword"
                dependencies={['password']}
                rules={FORM_RULES.confirmPassword}
              >
                <Input.Password
                  size="large"
                  value={confirmPassword}
                  onChange={(e: { target: { value: any } }) => {
                    setConfirmPassword(e.target.value);
                  }}
                />
              </Form.Item>

              <Form.Item name="otp" rules={FORM_RULES.otp}>
                <Space size={40} className="registerOtp flex flex-middle">
                  <Input
                    type="tel"
                    size="large"
                    allowClear
                    placeholder="短信验证码"
                    value={otp}
                    onChange={handleOtpChange}
                  />
                  <Button
                    block
                    loading={isCreatedOtpStatusPending}
                    type="primary"
                    size="large"
                    htmlType="button"
                    disabled={countingDown}
                    onClick={handleOtpCreate}
                  >
                    获取验证码{countingDown ? `(${countingDownTime}s)` : ''}
                  </Button>
                </Space>
              </Form.Item>

              <Form.Item className="registerButton">
                <Button
                  block
                  loading={isForgetPasswordStatusPending}
                  type="primary"
                  size="large"
                  htmlType="submit"
                >
                  提交
                </Button>
              </Form.Item>
            </Form>

            <div className="forgetPasswordBackLogin flex flex-center">
              <Link to="/login">回到登录页</Link>
            </div>

            <div className="flex flex-center flex-middle">
              <LockOutlined />
              <span className="forgetPasswordSecurityText">
                您的信息正在被保护中
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
