import { createSlice } from '@reduxjs/toolkit';
import { Store, StoreMappingType } from './types';

const initialState: StoreMappingType = {};

export const { reducer, actions } = createSlice({
  name: 'entities/stores',
  initialState,
  reducers: {
    updateStoreMapping: (
      state: StoreMappingType,
      action: { payload: Array<{ id: string }> }
    ) => {
      const list = action.payload;
      const stores = state as StoreMappingType;

      list.forEach((store: { id: string }) => {
        const { id }: { id: string } = store || {};

        if (state[id]) {
          stores[id] = { ...state[id], ...store };
        } else if (id) {
          stores[id] = store as Store;
        }
      });

      return stores;
    },
    updateStoreById: (
      state: { [x: string]: any },
      action: { payload: { id: string } }
    ) => {
      const { id } = action.payload || {};

      if (state[id]) {
        state[id] = { ...state[id], ...action.payload };
      } else if (id) {
        state[id] = action.payload;
      }

      return state;
    },
  },
});

export default reducer;
