import { createSlice } from '@reduxjs/toolkit';
import { AddClubState } from './types';
import { createClub } from './thunks';
import { API_REQUEST_STATUS } from '../../../../../../../service/constants';

const initialState: { data: AddClubState; createClubStatus: string } = {
  data: {
    logo: null,
    name: '',
    memberId: '',
    companyName: null,
    companyPhoneNumber: '',
    companyEmail: '',
    contactPhoneNumber: '',
    contactName: '',
    contactEmail: '',
    address: '',
    remark: '',
  },
  createClubStatus: API_REQUEST_STATUS.IDLE,
};

export const { reducer, actions } = createSlice({
  name: 'association/clubs/add',
  initialState,
  reducers: {
    clubLogoUpdated: (state, { payload }) => {
      state.data.logo = payload;
    },
    clubNameUpdated: (state, { payload }) => {
      state.data.name = payload;
    },
    clubAddressUpdated: (state, { payload }) => {
      state.data.address = payload;
    },
    clubMemberIdUpdated: (state, { payload }) => {
      state.data.memberId = payload;
    },
    clubCompanyPhoneNumberUpdated: (state, { payload }) => {
      state.data.companyPhoneNumber = payload;
    },
    clubCompanyEmailUpdated: (state, { payload }) => {
      state.data.companyEmail = payload;
    },
    clubContactNameUpdated: (state, { payload }) => {
      state.data.contactName = payload;
    },
    clubContactPhoneNumberUpdated: (state, { payload }) => {
      state.data.contactPhoneNumber = payload;
    },
    clubContactEmailUpdated: (state, { payload }) => {
      state.data.contactEmail = payload;
    },
    clubCompanyNameUpdated: (state, { payload }) => {
      state.data.companyName = payload;
    },
    clubRemarkUpdated: (state, { payload }) => {
      state.data.remark = payload;
    },
    newClubReset: (state) => {
      state.data = {
        logo: null,
        name: '',
        memberId: '',
        contactPhoneNumber: '',
        companyName: null,
        companyPhoneNumber: '',
        companyEmail: '',
        contactName: '',
        contactEmail: '',
        address: '',
        remark: '',
      };
    },
  },
  extraReducers: {
    [createClub.pending.type]: (state) => {
      state.createClubStatus = API_REQUEST_STATUS.PENDING;
    },
    [createClub.fulfilled.type]: (state) => {
      state.createClubStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [createClub.rejected.type]: (state) => {
      state.createClubStatus = API_REQUEST_STATUS.REJECTED;
    },
  },
});

export default reducer;
