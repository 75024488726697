import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { RootState } from '../../../../../redux/store';
import { saveUserInfoToLocale } from '../../../../../redux/app-v2/user/thunks';
import { actions as UserActions } from '../../../../../redux/app-v2/user';
import { getUserIsLoginStatus } from '../../../../../redux/app-v2/user/selectors';
import { saveMerchantInfoToLocale } from '../../../../../redux/app-v2/merchant/thunks';
import { formatAuth } from '../../../../../common/utils';
import { getLoginInfo } from './selectors';
import { postUserLogin } from '../../../api-request';

/**
 * User checked login
 */
export const userLoginStatusChecked = createAsyncThunk(
  'login/userLoginStatusChecked',
  async (navigateToHomePage: Function, { dispatch, getState }) => {
    // initial user info
    // check user login status
    await dispatch(UserActions.userSet());

    const state: any = getState();
    const isLogin = getUserIsLoginStatus(state);

    if (isLogin) {
      navigateToHomePage();
    }
  }
);

/**
 * User login
 */
export const userLogin = createAsyncThunk(
  'login/userLogin',
  async (navigateToHomePage: Function, { getState, dispatch }) => {
    const state = getState() as RootState;
    const loginInfo = getLoginInfo(state);

    try {
      const result: any = await postUserLogin(loginInfo);
      const { data } = result;

      await dispatch(
        saveMerchantInfoToLocale({ merchantName: loginInfo.merchantName })
      );
      await dispatch(
        saveUserInfoToLocale({
          id: data.characterId,
          authorization: formatAuth(data.bearerToken),
          isLogin: true,
        })
      );

      navigateToHomePage();
    } catch (e) {
      notification.error({
        message: '登录失败',
        description: '账户或者密码错误',
      });
    }
  }
);
