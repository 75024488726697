/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useMount } from 'react-use';
import { Button, Input, Space, Switch } from 'antd';
import { actions as storeDetailActions } from './redux';
import { getStoreInfo } from './redux/selectors';
import { loadStoreById, updateStoreById } from './redux/thunks';
import ContentDetail from '../../../../common/components/ContentDetail';
import BreadcrumbRouter from '../../../../common/components/BreadcrumbRouter';
import './index.less';

const storeDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { displayName, isDefault, province, city, district, addressDetail } =
    useSelector(getStoreInfo);
  useMount(() => {
    dispatch(loadStoreById(id));
  });

  return (
    <ContentDetail
      title="门店/门店详情"
      headerLeft={
        <BreadcrumbRouter
          routes={[
            {
              path: '/stores',
              breadcrumbName: '门店',
            },
            {
              path: `${id}`,
              breadcrumbName: '门店详情',
            },
          ]}
        />
      }
    >
      <div className="settingsInputsContainer">
        <div className="inputDiv" style={{ fontSize: '12px', padding: '0px' }}>
          <div>
            <span>门店名称</span>
          </div>
          <div>
            <Input
              value={displayName}
              onChange={(e) => {
                dispatch(storeDetailActions.displayNameSet(e.target.value));
              }}
              id="merchantName"
              className="searchInput"
              allowClear
              style={{ marginTop: '8px' }}
            />
          </div>
        </div>
        <div className="inputDiv" style={{ fontSize: '12px', padding: '0px' }}>
          <div>
            <span>省份</span>
          </div>
          <div>
            <Input
              value={province}
              onChange={(e) => {
                dispatch(storeDetailActions.provinceSet(e.target.value));
              }}
              id="merchantName"
              className="searchInput"
              allowClear
              style={{ marginTop: '8px' }}
            />
          </div>
        </div>
        <div className="inputDiv" style={{ fontSize: '12px', padding: '0px' }}>
          <div>
            <span>城市</span>
          </div>
          <div>
            <Input
              value={city}
              onChange={(e) => {
                dispatch(storeDetailActions.citySet(e.target.value));
              }}
              id="merchantName"
              className="searchInput"
              allowClear
              style={{ marginTop: '8px' }}
            />
          </div>
        </div>
        <div className="inputDiv" style={{ fontSize: '12px', padding: '0px' }}>
          <div>
            <span>区</span>
          </div>
          <div>
            <Input
              value={district}
              onChange={(e) => {
                dispatch(storeDetailActions.districtSet(e.target.value));
              }}
              id="merchantName"
              className="searchInput"
              allowClear
              style={{ marginTop: '8px' }}
            />
          </div>
        </div>
        <div className="inputDiv" style={{ fontSize: '12px', padding: '0px' }}>
          <div>
            <span>详细地址</span>
          </div>
          <div>
            <Input
              value={addressDetail}
              onChange={(e) => {
                dispatch(storeDetailActions.addressDetailSet(e.target.value));
              }}
              id="merchantName"
              className="searchInput"
              allowClear
              style={{ marginTop: '8px' }}
            />
          </div>
        </div>
        <div className="flex flex-middle flex-space-between">
          <span className="text-bold">是否为默认门店 (目前不可编辑)</span>
          <Switch
            disabled={true}
            checked={isDefault}
            onChange={(checked: boolean) => {
              dispatch(storeDetailActions.isDefaultSet(checked));
            }}
          />
        </div>
        <div className="StoreDetailButtons">
          <Space size={10}>
            <Button
              type="primary"
              className="operateButton"
              onClick={() => {
                dispatch(updateStoreById() as any);
              }}
            >
              保存
            </Button>
          </Space>
          <Space size={10}>
            <Button
              type="default"
              className="operateButton cancelButton"
              onClick={() => {
                navigate(-1);
              }}
            >
              返回
            </Button>
          </Space>
        </div>
      </div>
    </ContentDetail>
  );
};

export default storeDetail;
