import _get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../common/utils/types';
import { getEmployeesMapping } from '../../../redux/entities/employees/selectors';
import { API_REQUEST_STATUS } from '../../../service/constants';
import { getIsStoreManager } from '../../../redux/app-v2/user/selectors';

export const getAllowedCreators = (state: RootState) =>
  _get(state.tournament, 'allowedCreators', []);

export const getIsEnabled = (state: RootState) => state.tournament.isEnabled;

export const getEmployeeIds = (state: RootState) =>
  _get(state.tournament, 'employeeIds', []);

export const getDisplayAllowedCreators = createSelector(
  getAllowedCreators,
  getEmployeesMapping,
  getIsStoreManager,
  (allowedCreators, employeesMapping, isStoreManager) => {
    if (isStoreManager) {
      const storeAllowedCreators: string[] = [];

      allowedCreators.forEach((allowedCreator: string) => {
        if (employeesMapping[allowedCreator]) {
          storeAllowedCreators.push(allowedCreator);
        }
      });

      return storeAllowedCreators;
    }

    return allowedCreators;
  }
);

export const getEmployeeList = createSelector(
  getEmployeesMapping,
  getEmployeeIds,
  getIsStoreManager,
  (employeesMapping, employeeIds, isStoreManager) => {
    if (isStoreManager) {
      const storeManagerEmployees: { id: string; name: string }[] = [];

      employeeIds.forEach((id: string) => {
        if (employeesMapping[id]) {
          storeManagerEmployees.push({
            id,
            name: employeesMapping[id].name,
          });
        }
      });

      return storeManagerEmployees;
    }

    return employeeIds.map((id: string) => ({
      id,
      name: employeesMapping[id].name,
    }));
  }
);

export const getUpdateDetailStatus = (state: RootState) =>
  state.tournament.updateDetailStatus;

export const getIsUpdatingDetails = createSelector(
  getUpdateDetailStatus,
  (updateDetailStatus) => updateDetailStatus === API_REQUEST_STATUS.PENDING
);
