import { createSlice } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../../service/constants';
import { loadActivityById, saveActivityById } from './thunks';

/**
 * data: {
 *  name,
 *  description,
 *  maxPlayers,
 *  storeId,
 *  startTime,
 *  endDeadline,
 *  posterId,
 * }
 */
const initialState: {
  data: any;
  loadRequestStatus: string;
  saveRequestStatus: string;
} = {
  data: {},
  loadRequestStatus: API_REQUEST_STATUS.IDLE,
  saveRequestStatus: API_REQUEST_STATUS.IDLE,
};

export const { reducer, actions } = createSlice({
  name: 'activity/details',
  initialState,
  reducers: {
    backActivityListPage: (state: any) => {
      state.data = {};
      state.loadRequestStatus = API_REQUEST_STATUS.IDLE;
    },
    updateActivityDetail: (state: any, action: any) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
  },
  extraReducers: {
    [loadActivityById.fulfilled.type]: (
      state: typeof initialState,
      action: any
    ) => {
      state.data = action.payload;
      state.loadRequestStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [loadActivityById.rejected.type]: (state: typeof initialState) => {
      state.data = {};
      state.loadRequestStatus = API_REQUEST_STATUS.REJECTED;
    },
    [saveActivityById.fulfilled.type]: (state: typeof initialState) => {
      state.saveRequestStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [saveActivityById.rejected.type]: (state: typeof initialState) => {
      state.saveRequestStatus = API_REQUEST_STATUS.REJECTED;
    },
  },
});

export default reducer;
