import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMerchantName } from '../../../../../redux/app-v2/merchant/selectors';
import { getActivityById, putActivity } from './api-request';
import { getOriginalActivityDetail } from './selectors';
import {
  errorMessage,
  successMessage,
} from '../../../../../common/utils/feedback';

export const loadActivityById = createAsyncThunk(
  'activities/loadActivityById',
  async (id: string | undefined, { getState }) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);

      if (id) {
        const result: any = await getActivityById({ merchantName, id });

        return result.data;
      }
    } catch (e) {
      errorMessage('加载活动信息失败');
    }
  }
);

export const saveActivityById = createAsyncThunk(
  'activities/saveActivityById',
  async (_, { getState }) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);
      const { id, ...otherData } = getOriginalActivityDetail(state);
      await putActivity({
        merchantName,
        id,
        activity: otherData,
      });

      successMessage('保存活动信息成功');
    } catch (e) {
      errorMessage('保存活动信息失败');
    }
  }
);
