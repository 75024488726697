import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../common/utils/types';
import {
  getMerchantInfo,
  saveLocaleMerchantInfo,
  getMerchantSubscription,
  patchBasicToTrail,
} from './api-request';
import { loadPricePlan } from '../../entities/pricePlans/thunks';
import {
  getMerchantName,
  getMerchantSubscriptionPricePlanType,
} from './selectors';

export const loadMerchantInfo = createAsyncThunk(
  'merchant/getMerchantInfo',
  async (_, { getState }) => {
    try {
      const state = getState();
      const merchantName = getMerchantName(state);
      const result: any = await getMerchantInfo({ merchantName });
      const { data } = result;

      return data;
    } catch (e) {
      throw e;
    }
  }
);

export const loadMerchantSubscription = createAsyncThunk(
  'merchant/loadMerchantSubscription',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState();
      const merchantName = getMerchantName(state);
      const subscriptionResult: any = await getMerchantSubscription({
        merchantName,
      });
      const { data: subscriptions } = subscriptionResult;
      const { pricePlanType } = subscriptions || {};

      const { payload: pricePlanId }: any = await dispatch(
        loadPricePlan(pricePlanType)
      );

      return {
        ...subscriptions,
        pricePlanId,
      };
    } catch (e) {
      throw e;
    }
  }
);

export const saveMerchantInfoToLocale = createAsyncThunk(
  'merchant/saveMerchantInfoToLocale',
  async (merchant: { merchantName: string }) => saveLocaleMerchantInfo(merchant)
);

export const upgradeMerchantBasicToEliteTrail = createAsyncThunk(
  'app/function/upgradeBasicToTrail',
  async (_, { getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);
      const pricePlanType = getMerchantSubscriptionPricePlanType(state);

      await patchBasicToTrail(merchantName, { pricePlanType });

      dispatch(loadMerchantSubscription() as any);
    } catch (error) {
      throw error;
    }
  }
);
