import _isEmpty from 'lodash/isEmpty';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../../common/utils/types';
import {
  getEnrollmentList,
  getEnrollmentDetailsById,
  getTournamentLegends,
  getTournamentList,
  postCreateGame,
  getGameDetail,
  putGameDetail,
  deleteRequestGameDetail,
  getTargetsSettingExcelExport,
  getCurrentStageScoreSheetExport,
  getEnrollmentPlayersUnclassified,
  patchGameStatus,
  patchGameNextSegment,
  patchSegmentStatus,
  getEnrollmentResultPlayersSummaryById,
} from './api-request';
import { loadEmployeesV2 as loadEntitiesEmployees } from '../../../../../../../redux/entities/employees/thunks';
import { getMerchantName } from '../../../../../../../redux/app-v2/merchant/selectors';
import {
  getEnrollmentListPageNumber,
  getEnrollmentListPageSize,
  getEnrollmentListSearchInputText,
  getTournamentListPageNumber,
  getTournamentListPageSize,
  getTournamentListSearchInputText,
  getGameDetailData,
  getGameDetailSelectedGameId,
  getGameDetailEnrollmentId,
  getCreateGameRequestSelectedEnrollmentId,
  getIsDetailTargetsSettingModal,
  getEmployeeListPageNumber,
  getEmployeeListPageSize,
} from './selectors';
import {
  errorMessage,
  successMessage,
} from '../../../../../../../common/utils/feedback';

export const loadTournamentList = createAsyncThunk(
  'association/tournaments/loadTournamentList',
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);
      const offset: number =
        getTournamentListPageNumber(state) * getTournamentListPageSize(state);
      const text: string = getTournamentListSearchInputText(state);
      const limit: number = getTournamentListPageSize(state);
      const result: any = await getTournamentList({
        text,
        limit,
        offset,
        merchantName,
      });

      return result;
    } catch (error) {
      errorMessage('加载赛事活动联赛列表失败, 请刷新重试');

      throw error;
    }
  }
);

export const loadEnrollmentDetailsById: any = createAsyncThunk(
  'enrollmentDetails/loadEnrollmentDetailsById',
  async (id: string) => {
    try {
      const result: any = await getEnrollmentDetailsById({ id });
      const { data }: any = result || {};

      return data;
    } catch (e) {
      throw e;
    }
  }
);

export const loadEnrollmentList = createAsyncThunk(
  'association/tournaments/loadEnrollmentList',
  async (searchInputText: string | undefined, { getState }) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);
      const offset: number =
        getEnrollmentListPageNumber(state) * getEnrollmentListPageSize(state);
      const text: string =
        searchInputText || getEnrollmentListSearchInputText(state);
      const limit: number = getEnrollmentListPageSize(state);
      const result: any = await getEnrollmentList({
        text,
        limit,
        offset,
        merchantName,
      });
      const { data }: any = result || {};

      return data;
    } catch (error) {
      errorMessage('加载报名列表失败, 请刷新重试');

      throw error;
    }
  }
);

export const loadEnrollmentPlayersSummaryById = createAsyncThunk(
  'association/tournaments/loadEnrollmentPlayersSummaryById',
  async (enrollmentId: string) => {
    try {
      const result = await getEnrollmentResultPlayersSummaryById({
        enrollmentId,
      });
      const { data }: any = result || {};

      return { ...data, enrollmentId };
    } catch (e) {
      throw e;
    }
  }
);

export const loadEnrollmentPlayersUnclassified = createAsyncThunk(
  'association/tournaments/loadEnrollmentPlayersUnclassified',
  async (enrollmentId: string) => {
    try {
      const result = await getEnrollmentPlayersUnclassified({ enrollmentId });
      const { data }: any = result || {};

      return data;
    } catch (e) {
      throw e;
    }
  }
);

export const loadEmployeeList = createAsyncThunk(
  'employee/loadEmployeeList',
  async (_, { dispatch, getState }) => {
    try {
      const state: any = getState();
      const offset: number =
        getEmployeeListPageNumber(state) * getEmployeeListPageSize(state);
      const limit: number = getEmployeeListPageSize(state);
      const { payload } = await dispatch(
        loadEntitiesEmployees({ offset, limit })
      );

      return payload;
    } catch (e) {
      throw e;
    }
  }
);

export const createGameRequestCreateModalHidden = createAsyncThunk(
  'association/tournaments/createGameRequestCreateModalHidden',
  async () => { }
);

export const createGame = createAsyncThunk(
  'association/tournaments/createGame',
  async (
    payload: {
      title: string;
      address: string;
      enrollmentId: string;
      eventSchedule: 'qualifyingAndKnockout' | 'onlyQualifying';
      scoreRecordingType: string;
      scoreVerificationMethod: string;
      demandIds: string[];
      targetNums: string[];
      segments: object[];
    },
    { getState, dispatch }
  ) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);
      const result: any = await postCreateGame(merchantName, payload);
      const { data, code, description }: any = result || {};

      if (code !== 0) {
        if (description) {
          errorMessage(description);

          return;
        }

        throw new Error(result);
      }

      dispatch(createGameRequestCreateModalHidden());
      await dispatch(loadTournamentList());

      return data;
    } catch (error: any) {
      errorMessage('创建比赛失败, 请刷新重试');

      throw error;
    }
  }
);

export const loadGameById = createAsyncThunk(
  'association/tournaments/loadGameById',
  async (gameId: string, { getState }) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);
      const result = await getGameDetail(merchantName, gameId);
      const { data }: any = result || {};

      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const loadGameLegend = createAsyncThunk(
  'association/tournaments/loadGameLegend',
  async (enrollmentId: string | null) => {
    try {
      const result = await getTournamentLegends(enrollmentId);
      const { data }: any = result || {};

      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const loadGameDetailRequestTargetsSettingModalHidden = createAsyncThunk(
  'association/tournaments/loadGameDetailRequestTargetsSettingModalHidden',
  async () => { }
);

export const loadGameDetailRequestDetailModalHidden = createAsyncThunk(
  'association/tournaments/loadGameDetailRequestDetailModalHidden',
  async () => { }
);

export const updateGameDetail = createAsyncThunk(
  'association/tournaments/updateGameDetail',
  async (changeData: object | undefined, { getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);
      const gameId: string | null = getGameDetailSelectedGameId(state);
      const payload: object = getGameDetailData(state);

      const result: any = await putGameDetail(merchantName, {
        gameId,
        ...payload,
        ...changeData,
      });
      const { code, description }: any = result || {};

      if (code !== 0) {
        if (description) {
          errorMessage(description);

          return;
        }

        throw new Error(result);
      }

      successMessage('更新比赛成功');

      dispatch(loadGameDetailRequestTargetsSettingModalHidden());
      dispatch(loadGameDetailRequestDetailModalHidden());
      dispatch(loadTournamentList());

      return { payload, data: result?.data };
    } catch (error: any) {
      errorMessage('更新比赛失败, 请刷新重试');

      throw error;
    }
  }
);

export const updateGameDetailSummary = createAsyncThunk(
  'association/tournaments/updateGameDetailSummary',
  async (params: any, { getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);
      const gameId: string | null = getGameDetailSelectedGameId(state);
      const enrollmentId: string | null = getGameDetailEnrollmentId(state);
      const payload: any = getGameDetailData(state);
      const enrollmentSummary = payload.enrollmentSummary.map((item: any) => {
        if (item.categoryName === params.categoryName && item.optionName === params.optionName) {
          return params;
        }
        return item;
      });

      if (!gameId || !enrollmentId) {
        throw new Error('Invalid gameId or enrollmentId');
      }
      const result: any = await putGameDetail(merchantName, {
        gameId,
        modifiedTime: payload.modifiedTime,
        enrollmentSummary,
      });
      const { code, description }: any = result || {};

      if (code !== 0) {
        if (description) {
          errorMessage(description);

          return;
        }

        throw new Error(result);
      }

      await dispatch(loadGameById(gameId));
      await dispatch(loadGameLegend(enrollmentId));

      return { payload, data: result?.data };
    } catch (error: any) {
      errorMessage('更新比赛失败, 请刷新重试');
      throw error;
    }
  }
);
export const resetQueryUpdateGameDetailError = createAsyncThunk(
  'association/tournaments/resetQueryUpdateGameDetailError',
  async () => { }
);

export const queryUpdateGameDetail = createAsyncThunk(
  'association/tournaments/queryUpdateGameDetail',
  async (changeData: object | undefined, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);
      const gameId: string | null = getGameDetailSelectedGameId(state);
      const payload: object = getGameDetailData(state);

      if (_isEmpty(payload) || !gameId) {
        return payload;
      }

      const result: any = await putGameDetail(merchantName, {
        gameId,
        ...payload,
        ...changeData,
      });
      const { code, description }: any = result || {};

      if (code !== 0) {
        if (description) {
          errorMessage(description, {
            handleContinue: async () => {
              if (gameId) {
                await dispatch(loadGameById(gameId));
                dispatch(resetQueryUpdateGameDetailError());
              }
            },
          });
          throw new Error('errorAlerted');
        }

        throw new Error('update game detail failed');
      }

      return { ...payload, data: result?.data };
    } catch (error: any) {
      const { message } = error || {};

      if (message !== 'errorAlerted') {
        errorMessage('更新比赛失败, 请检查设置靶位是否正确');
      }

      throw error;
    }
  }
);

export const updateGameStatus = createAsyncThunk(
  'association/tournaments/updateGameStatus',
  async (data: { gameId: string; status: string }, { getState, dispatch }) => {
    try {
      const { gameId, status } = data;
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);

      const result: any = await patchGameStatus(merchantName, gameId, status);
      const { code, description }: any = result || {};

      if (code !== 0) {
        if (description) {
          errorMessage(description);

          return;
        }

        throw new Error(result);
      }

      dispatch(loadTournamentList());

      return result;
    } catch (error: any) {
      if (error === '内部服务器错误') {
        errorMessage('修改比赛下状态失败, 请刷新重试');
      } else {
        errorMessage('修改比赛下状态失败, 请刷新重试');
      }

      throw error;
    }
  }
);

export const updateSegmentStatus = createAsyncThunk(
  'association/tournaments/updateSegmentStatus',
  async (
    data: { gameId: string; segmentId: string; status: string },
    { getState, dispatch }
  ) => {
    try {
      const { gameId, segmentId, status } = data;
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);

      const result = await patchSegmentStatus(
        merchantName,
        gameId,
        segmentId,
        status
      );
      const { code, description }: any = result || {};

      if (code !== 0) {
        if (description) {
          errorMessage(description);

          return;
        }

        throw new Error('update segment status failed');
      }

      dispatch(loadTournamentList());

      return result;
    } catch (error) {
      if (error === '内部服务器错误') {
        errorMessage('修改当前轮状态失败, 请刷新重试');
      } else {
        errorMessage('修改当前轮状态失败, 请刷新重试');
      }

      throw error;
    }
  }
);

export const goToNextSegment = createAsyncThunk(
  'association/tournaments/goToNextSegment',
  async (gameId: string, { getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);

      const result = await patchGameNextSegment(merchantName, gameId);
      const { code, description }: any = result || {};

      if (code !== 0) {
        if (description) {
          errorMessage(description);

          return;
        }

        throw new Error('patch game next segment failed');
      }

      dispatch(loadTournamentList());

      return result;
    } catch (error) {
      errorMessage('进入比赛下一阶段失败, 请刷新重试');

      throw error;
    }
  }
);

export const deleteGame = createAsyncThunk(
  'association/tournaments/deleteGame',
  async (_, { getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);
      const gameId: string | null = getGameDetailSelectedGameId(state);

      const result = await deleteRequestGameDetail(merchantName, gameId);
      const { code }: any = result || {};

      if (code !== 0) {
        throw new Error('create game detail failed');
      }

      dispatch(loadTournamentList());
    } catch (error) {
      errorMessage('删除比赛失败, 请刷新重试');

      throw error;
    }
  }
);

export const exportTargetsSettingExcel = createAsyncThunk(
  'association/tournaments/exportTargetsSettingExcel',
  async (gameId: string, { getState }) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);
      const result = await getTargetsSettingExcelExport(merchantName, gameId);

      return result;
    } catch (error: any) {
      if (error?.response?.status === 400) {
        errorMessage('导出靶位图失败， 请刷新重试');
      } else {
        errorMessage('导出靶位图失败， 请刷新重试');
      }

      throw error;
    }
  }
);

export const exportCurrentStageScoreSheet = createAsyncThunk(
  'association/tournaments/exportCurrentStageScoreSheet',
  async (data: { gameId: string; segmentId: string }, { getState }) => {
    try {
      const { gameId, segmentId } = data;
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);
      const result = await getCurrentStageScoreSheetExport(
        merchantName,
        gameId,
        segmentId
      );

      return result;
    } catch (error: any) {
      if (error?.response?.status === 400) {
        errorMessage('导出当前阶段记分纸失败， 请刷新重试');
      } else {
        errorMessage('导出当前阶段记分纸失败， 请刷新重试');
      }

      throw error;
    }
  }
);

export const targetsSettingMounted = createAsyncThunk(
  'association/tournaments/TargetsSettingMounted',
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    const isDetailTargetsSettingModal: boolean =
      getIsDetailTargetsSettingModal(state);
    let enrollmentId: string | null = null;

    try {
      if (isDetailTargetsSettingModal) {
        const gameId: string | null = getGameDetailSelectedGameId(state);
        await dispatch(loadGameById(gameId as string) as any);

        enrollmentId = getGameDetailEnrollmentId(getState() as RootState);
      } else {
        enrollmentId = getCreateGameRequestSelectedEnrollmentId(
          getState() as RootState
        );
      }

      dispatch(loadGameLegend(enrollmentId) as any);
    } catch (error) {
      throw error;
    }
  }
);
