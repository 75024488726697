import { createAsyncThunk } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { BUSINESS_TYPES } from '../../../../../../../common/constants';
import {
  errorMessage,
  successMessage,
} from '../../../../../../../common/utils/feedback';
import {
  getMerchantBusiness,
  getMerchantName,
  getIsBaseball,
} from '../../../../../../../redux/app-v2/merchant/selectors';
import { getClubDetailById, putClubDetail } from './api-request';
import { getClubDetailData } from './selectors';

export const clubDetailByIdLoaded = createAsyncThunk(
  'association/clubs/add/clubDetailByIdLoaded',
  async (
    {
      id,
      backToListPage,
    }: {
      id: string;
      backToListPage: Function;
    },
    { getState }
  ) => {
    const state: any = getState();
    const business = getMerchantBusiness(state);
    const isBaseball = business === BUSINESS_TYPES.Baseball;
    const clubText = isBaseball ? '联盟球队' : '成员单位';

    try {
      const merchantName = getMerchantName(state);
      const result: any = await getClubDetailById(merchantName, id);
      const { data } = result;

      return data;
    } catch (error) {
      errorMessage(
        `获取${clubText}认证失败，回到上个页面重新选择${clubText}认证详情`,
        {
          handleContinue: () => {
            backToListPage();
          },
        }
      );

      throw error;
    }
  }
);

export const updateClubDetail = createAsyncThunk(
  'association/clubs/add/updateClubDetail',
  async (backToListPage: Function, { getState }) => {
    const state: any = getState();
    const business = getMerchantBusiness(state);
    const isBaseball = business === BUSINESS_TYPES.Baseball;
    const clubText = isBaseball ? '联盟球队' : '成员单位';
    try {
      const merchantName = getMerchantName(state);
      const payload = getClubDetailData(state);
      const { id } = payload;
      const result: any = await putClubDetail(merchantName, id, payload);
      const { data } = result;

      if (
        result.description === '成员单位号已存在' ||
        result.description === '联盟球队号已存在'
      ) {
        throw new Error(`${clubText}号已存在`);
      } else {
        successMessage(`更新${clubText}认证成功`, {
          handleConfirm: () => {
            backToListPage();
          },
        });

        return data;
      }
    } catch (error: any) {
      const { message } = error || {};

      if (message === '成员单位号已存在' || message === '联盟球队号已存在') {
        errorMessage(`${clubText}号已存在，请重新输入`);
      } else {
        errorMessage(`更新${clubText}认证失败，请重试`);
      }

      throw error;
    }
  }
);
