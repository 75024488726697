import _isNil from 'lodash/isNil';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Form,
  Modal,
  Input,
  Radio,
  Select,
  DatePicker,
  Button,
  Row,
  Col,
} from 'antd';
import {
  DeleteOutlined,
  InboxOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { AppDispatch } from '../../../../../../../../common/utils/types';
import {
  getIsIDNumberValidated,
  getIsPassportNumberValidated,
} from '../../../../../../../../common/utils';
import {
  getIsAssociationPlayerDetailClubTransferring,
  getIsAssociationPlayerDetailAssociationClubDisabled,
  getAssociationPlayerDetailAssociationClubId,
  getAssociationPlayerDetailAssociationClubMemberName,
  getAssociationPlayerDetailRegisterDate,
  getAssociationPlayerDetailExpiredDate,
  getAssociationPlayerDetailGender,
  getAssociationPlayerDetailIdCardNum,
  getAssociationPlayerDetailIdCardType,
  getAssociationPlayerDetailName,
  getAssociationPlayerDetailPhone,
  getSearchClubsFilterOptions,
  getIsSearchClubsFilterLoading,
  getIsAssociationPlayerDetailUpdating,
  getIsAssociationPlayerDetailVisibility,
  getIsAssociationPlayerDetailLoaded,
  getIsAssociationPlayerDetailDeleting,
  getAssociationPlayerDetailId,
  getAssociationPlayerDetailRegisterNo,
  getIsAssociationPlayerDetailRegisterNoNotEditable,
  getAssociationPlayerTransferClubId,
  getAssociationPlayerTransferClubName,
  getIsAssociationPlayerDetailTransferring,
} from '../../redux/detail/selectors';
import { actions as playerDetailActions } from '../../redux/detail';
import { useMount } from 'react-use';
import {
  loadAssociationPlayerById,
  loadSearchClubsFilter,
  updateAssociationPlayerDetail,
  deleteAssociationPlayerById,
  transferClubForAssociationPlayer,
} from '../../redux/detail/thunks';
import PhoneNumberInput from '../../../../../../../../common/components/PhoneNumberInput';
import './PlayerDetail.less';
import { getIsBaseball } from '../../../../../../../../redux/app-v2/merchant/selectors';

const getDisabledDate = (current: any, registerDate: any) => {
  return current && current <= registerDate;
};
let timer: any = null;

const PlayerDetail = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const associationPlayerDetailVisibility = useSelector(
    getIsAssociationPlayerDetailVisibility
  );
  const id = useSelector(getAssociationPlayerDetailId);
  const name = useSelector(getAssociationPlayerDetailName);
  const gender = useSelector(getAssociationPlayerDetailGender);
  const idCardType = useSelector(getAssociationPlayerDetailIdCardType);
  const idCardNum = useSelector(getAssociationPlayerDetailIdCardNum);
  const phone = useSelector(getAssociationPlayerDetailPhone);
  const registerDate: any = useSelector(getAssociationPlayerDetailRegisterDate);
  const expiredDate: any = useSelector(getAssociationPlayerDetailExpiredDate);
  const isRegisterNoNotEditable = useSelector(
    getIsAssociationPlayerDetailRegisterNoNotEditable
  );
  const registerNo = useSelector(getAssociationPlayerDetailRegisterNo);
  const isAssociationClubDisabled = useSelector(
    getIsAssociationPlayerDetailAssociationClubDisabled
  );
  const associationClubId = useSelector(
    getAssociationPlayerDetailAssociationClubId
  );
  const isAssociationPlayerDetailClubTransferring = useSelector(
    getIsAssociationPlayerDetailClubTransferring
  );
  const associationPlayerTransferClubId = useSelector(
    getAssociationPlayerTransferClubId
  );
  const associationPlayerTransferClubName = useSelector(
    getAssociationPlayerTransferClubName
  );
  const associationClubMemberName = useSelector(
    getAssociationPlayerDetailAssociationClubMemberName
  );
  const searchClubsFilterOptions = useSelector(getSearchClubsFilterOptions);
  const isSearchClubsFilterLoading = useSelector(getIsSearchClubsFilterLoading);
  const isAssociationPlayerDetailDeleting = useSelector(
    getIsAssociationPlayerDetailDeleting
  );
  const isAssociationPlayerDetailLoaded = useSelector(
    getIsAssociationPlayerDetailLoaded
  );
  const isAssociationPlayerDetailTransferring = useSelector(
    getIsAssociationPlayerDetailTransferring
  );
  const isAssociationPlayerDetailUpdating = useSelector(
    getIsAssociationPlayerDetailUpdating
  );
  const associationPlayerDetailUpdateRequest = async () => {
    await dispatch(
      updateAssociationPlayerDetail(() => {
        dispatch(playerDetailActions.associationPlayerDetailVisibilityHidden());
      })
    );
  };
  const isBaseball = useSelector(getIsBaseball);
  const clubText = isBaseball ? '联盟球队' : '成员单位';
  const playerText = isBaseball ? '球员' : '运动员';
  const handleUpdateAssociationPlayer = async () => {
    try {
      await form.validateFields();

      if (associationPlayerTransferClubId) {
        Modal.confirm({
          centered: true,
          title: `确定转会该${playerText}？`,
          content: `转会后，该${playerText}将不再属于当前${clubText}`,
          okText: '确认',
          cancelText: '取消',
          onOk: async () => {
            await dispatch(transferClubForAssociationPlayer());
            associationPlayerDetailUpdateRequest();
          },
        });
      } else {
        await associationPlayerDetailUpdateRequest();
      }
    } catch (error) {
      throw error;
    }
  };
  const handleDeleteAssociationPlayer = () => {
    Modal.confirm({
      centered: true,
      title: `确定删除该注册${playerText}？`,
      content: `若只是更换${clubText}，可以尝试详情中的转会功能`,
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        try {
          await dispatch(
            deleteAssociationPlayerById({
              id,
              closeDetailModal: () => {
                dispatch(
                  playerDetailActions.associationPlayerDetailVisibilityHidden()
                );
              },
            })
          );
        } catch (error) {
          throw error;
        }
      },
    });
  };
  const resetData = async () => {
    await form.resetFields();
    dispatch(playerDetailActions.associationPlayerDetailReset());
  };

  useMount(async () => {
    dispatch(loadSearchClubsFilter());
  });

  useEffect(() => {
    if (id) {
      dispatch(loadAssociationPlayerById(id));
    }
  }, [dispatch, id]);

  if (!isAssociationPlayerDetailLoaded) {
    return null;
  }

  return (
    <Modal
      centered
      visible={associationPlayerDetailVisibility}
      width={630}
      title={`编辑注册${playerText}`}
      onCancel={() => {
        dispatch(playerDetailActions.associationPlayerDetailVisibilityHidden());
      }}
      onOk={handleUpdateAssociationPlayer}
      afterClose={resetData}
      footer={
        <div className="flex flex-middle flex-space-between">
          <span className="PlayerDetailFooterPromptText">
            {associationPlayerTransferClubId ? (
              <>
                <sub className="PlayerDetailFooterPromptTextRequired">*</sub>{' '}
                更新之后，{playerText}编号和日期由新的俱乐部重新生成
              </>
            ) : null}
          </span>
          <div className="flex flex-middle">
            <Button
              danger
              className="PlayerDetailDeleteButton flex flex-middle"
              type="text"
              loading={isAssociationPlayerDetailDeleting}
              onClick={handleDeleteAssociationPlayer}
            >
              <span>删除{playerText}</span>
              <DeleteOutlined />
            </Button>
            <Button
              key="submit"
              type="primary"
              loading={
                isAssociationPlayerDetailTransferring ||
                isAssociationPlayerDetailUpdating
              }
              onClick={handleUpdateAssociationPlayer}
            >
              更新
            </Button>
            <Button
              type="primary"
              ghost
              onClick={() => {
                dispatch(
                  playerDetailActions.associationPlayerDetailVisibilityHidden()
                );
              }}
            >
              返回
            </Button>
          </div>
        </div>
      }
    >
      <Form scrollToFirstError layout="vertical" form={form} preserve={false}>
        <Row gutter={40}>
          <Col xs={24} sm={12}>
            <Form.Item
              className="formItem"
              label="姓名"
              name={[id, 'name']}
              initialValue={name}
              rules={[{ required: true, message: '姓名不能为空' }]}
            >
              <Input
                value={name}
                type="text"
                onChange={(e) => {
                  dispatch(
                    playerDetailActions.associationPlayerDetailNameUpdated(
                      e.target.value
                    )
                  );
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={40}>
          <Col xs={24} sm={12}>
            <Form.Item
              className="formItem"
              label="性别"
              name={[id, 'gender']}
              initialValue={gender}
              rules={[{ required: true, message: '性别不能为空' }]}
            >
              <Radio.Group
                name="gender"
                value={gender}
                onChange={(e) => {
                  dispatch(
                    playerDetailActions.associationPlayerDetailGenderUpdated(
                      e.target.value
                    )
                  );
                }}
              >
                <Radio value="male">男</Radio>
                <Radio value="female">女</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={40}>
          <Col xs={24} sm={12}>
            <Form.Item
              className="PlayerDetail formItem"
              label="证件号"
              required
            >
              <Input.Group compact>
                <Select
                  dropdownMatchSelectWidth={120}
                  value={idCardType}
                  onChange={(value) => {
                    dispatch(
                      playerDetailActions.associationPlayerDetailIdCardTypeUpdated(
                        value
                      )
                    );
                  }}
                >
                  <Select.Option value="IDCard">身份证</Select.Option>
                  <Select.Option value="passport">护照</Select.Option>
                  <Select.Option value="HongKong/Macau/Taiwan">
                    港澳台人士证件
                  </Select.Option>
                </Select>
                <Form.Item
                  noStyle
                  name={[id, 'idCardNum']}
                  initialValue={idCardNum}
                  rules={[
                    { required: true, message: '证件号不能为空' },
                    () => ({
                      validator(_, value) {
                        const isValidIDCardNumber =
                          idCardType === 'IDCard' &&
                          getIsIDNumberValidated(value);
                        const isValidPassportNumber =
                          idCardType === 'passport' &&
                          getIsPassportNumberValidated(value);
                        const isValidExitEntryPermitNumber =
                          idCardType === 'HongKong/Macau/Taiwan';

                        if (
                          isValidIDCardNumber ||
                          isValidPassportNumber ||
                          isValidExitEntryPermitNumber ||
                          value === '' ||
                          _isNil(value)
                        ) {
                          return Promise.resolve();
                        }

                        return Promise.reject(new Error('请输入正确的证件号'));
                      },
                    }),
                  ]}
                >
                  <Input
                    value={idCardNum}
                    onChange={(e) => {
                      dispatch(
                        playerDetailActions.associationPlayerDetailIdCardNumUpdated(
                          e.target.value
                        )
                      );
                    }}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <PhoneNumberInput
              name={[id, 'phone']}
              label="联系电话"
              placeholder={`请输入注册${playerText}联系电话`}
              phoneNumber={phone}
              onChange={(phoneNumber: string) => {
                dispatch(
                  playerDetailActions.associationPlayerDetailPhoneUpdated(
                    phoneNumber
                  )
                );
              }}
            />
          </Col>
        </Row>
        <Row gutter={40}>
          <Col xs={24} sm={12}>
            <Form.Item className="formItem" label="注册日期">
              <DatePicker
                allowClear={false}
                style={{ width: '100%' }}
                value={registerDate}
                format="YYYY/MM/DD"
                onChange={(_, dateString) => {
                  dispatch(
                    playerDetailActions.associationPlayerDetailRegisterDateUpdated(
                      dateString
                    )
                  );
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item className="formItem" label="有效期">
              <DatePicker
                allowClear={false}
                style={{ width: '100%' }}
                value={expiredDate}
                format="YYYY/MM/DD"
                disabledDate={(current) => {
                  return getDisabledDate(current, registerDate);
                }}
                onChange={(_, dateString) => {
                  dispatch(
                    playerDetailActions.associationPlayerDetailExpiredDateUpdated(
                      dateString
                    )
                  );
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={40}>
          <Col xs={24} sm={12}>
            <Form.Item className="formItem" label={`${playerText}编号`}>
              <Input
                disabled={isRegisterNoNotEditable}
                defaultValue={registerNo}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={40}>
          <Col xs={24} sm={12}>
            <Form.Item
              className="formItem"
              label={
                <div>
                  <span>所属{clubText}</span>
                  {isAssociationPlayerDetailClubTransferring ? (
                    <button
                      className="PlayerDetailCancelTransferClubButton"
                      onClick={() => {
                        dispatch(
                          playerDetailActions.associationPlayerDetailTransferClubIdUpdated(
                            null
                          )
                        );
                        dispatch(
                          playerDetailActions.associationPlayerDetailClubTransferCanceled()
                        );
                      }}
                    >
                      <span>取消转会</span>
                    </button>
                  ) : (
                    <button
                      className="PlayerDetailTransferClubButton"
                      onClick={() => {
                        dispatch(
                          playerDetailActions.associationPlayerDetailClubTransferTurnOn()
                        );
                      }}
                    >
                      <span>转会</span>
                      <UserSwitchOutlined />
                    </button>
                  )}
                </div>
              }
            >
              {isAssociationPlayerDetailClubTransferring ? (
                <Select
                  showSearch
                  loading={isSearchClubsFilterLoading}
                  placeholder={`请输入${clubText}名称`}
                  value={associationPlayerTransferClubId || associationClubId}
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  onSearch={(value) => {
                    clearTimeout(timer);

                    timer = setTimeout(() => {
                      dispatch(loadSearchClubsFilter(value));
                    }, 400);
                  }}
                  onChange={(value) => {
                    dispatch(
                      playerDetailActions.associationPlayerDetailTransferClubIdUpdated(
                        value
                      )
                    );
                  }}
                  notFoundContent={
                    <div className="flex flex-column flex-middle flex-center">
                      <InboxOutlined />
                      <span>未搜到此{clubText}</span>
                    </div>
                  }
                  options={searchClubsFilterOptions}
                />
              ) : (
                <Input
                  disabled={isAssociationClubDisabled}
                  defaultValue={
                    associationPlayerTransferClubName ||
                    associationClubMemberName
                  }
                  addonAfter={
                    <Button
                      className="PlayerDetailClubLinkButton"
                      type="link"
                      onClick={() => {
                        const currentClubId =
                          associationPlayerTransferClubId || associationClubId;

                        navigate(
                          `/association-activities/clubs/${currentClubId}`
                        );
                      }}
                    >
                      详情
                    </Button>
                  }
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default PlayerDetail;
