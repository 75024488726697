import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Input, Modal, Checkbox, Form, Tag, notification } from 'antd';
import { AppDispatch } from '../../../../common/utils/types';
import { actions as assetsActions } from '../../redux';
import {
  getIsPlayerAssesDetailsDetailsVisible,
  getSelectedAssets,
  getIsAssetsDetailsUpdating,
} from '../../redux/selectors';
import { updateAsset } from '../../redux/thunks';
import './PlayerAssetsDetails.less';

const PlayerAssetsDetails = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const isPlayerAssesDetailsDetailsVisible = useSelector(
    getIsPlayerAssesDetailsDetailsVisible
  );
  const selectedAssets: any = useSelector(getSelectedAssets);
  const { id, type, unit, description, isSensitive, archived }: any =
    selectedAssets;
  const isAssetsDetailsUpdating = useSelector(getIsAssetsDetailsUpdating);
  const title = `${type}权益详情`;
  const titleContent = archived ? (
    <h4 className="flex flex-middle">
      {title}
      <Tag className="PlayerAssetsDetailsTitleTag" color="error">
        已停用
      </Tag>
    </h4>
  ) : (
    title
  );
  const handleCancelPlayerAssetsDetails = async () => {
    await form.resetFields();
    dispatch(assetsActions.hidePlayerAssesDetailsDetailsVisibility());
    dispatch(assetsActions.resetDetails());
  };
  const handleUpdatePlayerAssetsDetails = async () => {
    try {
      await form.validateFields();

      if (selectedAssets.archived) {
        throw new Error('该资产已封存，不可修改');
      }

      await dispatch(updateAsset() as any);
    } catch (error: any) {
      if (error.message) {
        notification.error({ message: error.message });
      }
      throw error;
    }
  };

  if (!selectedAssets) {
    return null;
  }

  return (
    <Modal
      key={id}
      title={titleContent}
      visible={isPlayerAssesDetailsDetailsVisible}
      closable
      onOk={handleUpdatePlayerAssetsDetails}
      onCancel={handleCancelPlayerAssetsDetails}
      footer={
        <div className="flex flex-end">
          <Button
            type="primary"
            loading={isAssetsDetailsUpdating}
            onClick={handleUpdatePlayerAssetsDetails}
            {...(selectedAssets.archived ? { disabled: true } : {})}
          >
            修改
          </Button>
          <Button type="default" onClick={handleCancelPlayerAssetsDetails}>
            取消
          </Button>
        </div>
      }
    >
      <Form form={form} layout="vertical" preserve={false}>
        <Form.Item
          label="权益类型"
          name={[id, 'type']}
          initialValue={type}
          rules={[
            { required: true, message: '请输入权益类型' },
            {
              validator(_, value) {
                if (!value) {
                  return Promise.resolve();
                }

                const maxCharacterLength = 10;
                const cnCharacters = value.match(/[^\x00-\xff]/g);
                let totalLength = value.length;

                if (cnCharacters) {
                  const cnCharactersLength = cnCharacters.length;
                  const calculateCnLength = cnCharactersLength * 2;
                  const calculateEnLength = value.length - cnCharactersLength;
                  totalLength = calculateCnLength + calculateEnLength;
                }

                if (totalLength > maxCharacterLength) {
                  return Promise.reject(
                    new Error('权益类型最多为5个汉字或者10个英文字母')
                  );
                }

                const reg = /[`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/im;
                if (reg.test(value)) {
                  return Promise.reject(new Error('类型名称不能包含特殊字符'));
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            showCount
            allowClear
            value={type}
            onChange={(e) => {
              dispatch(assetsActions.detailsTypeUpdated(e.target.value));
            }}
          />
        </Form.Item>

        <Form.Item
          label="权益单位"
          name={[id, 'unit']}
          initialValue={unit}
          rules={[
            {
              validator(_, value) {
                const maxCharacterLength = 6;
                const cnCharacters = value.match(/[^\x00-\xff]/g);
                let totalLength = value.length;

                if (cnCharacters) {
                  const cnCharactersLength = cnCharacters.length;
                  const calculateCnLength = cnCharactersLength * 2;
                  const calculateEnLength = value.length - cnCharactersLength;
                  totalLength = calculateCnLength + calculateEnLength;
                }

                if (totalLength > maxCharacterLength) {
                  return Promise.reject(
                    new Error('权益类型最多为3个汉字或者6个英文字母')
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            showCount
            allowClear
            value={unit}
            onChange={(e) => {
              dispatch(assetsActions.detailsUnitUpdated(e.target.value));
            }}
          />
        </Form.Item>

        <Form.Item
          label="描述"
          name={[id, 'description']}
          initialValue={description}
        >
          <Input.TextArea
            rows={4}
            value={description}
            onChange={(e) => {
              dispatch(assetsActions.detailsDescriptionUpdated(e.target.value));
            }}
          />
        </Form.Item>

        <Checkbox
          checked={isSensitive}
          onChange={(e) => {
            dispatch(assetsActions.detailsIsSensitiveUpdated(e.target.checked));
          }}
        >
          敏感权益
        </Checkbox>
      </Form>
    </Modal>
  );
};

PlayerAssetsDetails.displayName = 'PlayerAssetsDetails';

export default PlayerAssetsDetails;
