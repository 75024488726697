import { combineReducers } from '@reduxjs/toolkit';
import { reducer as add } from '../containers/AddClub/redux';
import { reducer as detail } from '../containers/ClubDetail/redux';
import { reducer as list } from '../containers/ClubAuthentication/redux';
import { reducer as clubsImport } from '../containers/ClubAuthenticationImport/redux';

const clubs = combineReducers({
  add,
  detail,
  list,
  clubsImport,
});

export default clubs;
