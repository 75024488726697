import { createSelector } from '@reduxjs/toolkit';
import _get from 'lodash/get';

export const getRoleMapping = (state: any) => _get(state.appV2, 'roles', '');

export const getRoles = createSelector(getRoleMapping, (roleMapping) => {
  const keys = Object.keys(roleMapping);

  return keys.map((key) => roleMapping[key]);
});

export const getOwnerRoleId = createSelector(getRoles, (roles) => {
  const roleOfOwnerInfo = roles.find((role) => role.roleName === 'Owner');

  return roleOfOwnerInfo?.id;
});
