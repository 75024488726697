import _get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { getPlayersMapping } from '../../../../../redux/entities/players/selectors';
import {
  getIsStoreManager,
  getUserBelongToStoreId,
  getUserId,
} from '../../../../../redux/app-v2/user/selectors';

export const getPlayerIds = (state: { player: { list: { ids: string[] } } }) =>
  _get(state.player, 'list.ids', []);

export const getPlayerList = createSelector(
  getPlayersMapping,
  getPlayerIds,
  getUserId,
  (playersMapping, ids) => ids.map((id: string) => playersMapping[id])
);

export const getPlayerListPageNumber = (state: any) =>
  state.player.list.pageNumber;

export const getPlayerListPageSize = (state: any) => state.player.list.pageSize;

export const getPlayerListTotalPlayers = (state: any) =>
  state.player.list.totalPlayers;

export const getPlayerListFilters = (state: any) => state.player.list.filters;

export const getPlayerListFiltersMemberCardPaid = createSelector(
  getPlayerListFilters,
  (filters) => filters.memberCardPaid
);

export const getPlayerListParameters = createSelector(
  getPlayerListPageNumber,
  getPlayerListPageSize,
  getPlayerListFiltersMemberCardPaid,
  getIsStoreManager,
  getUserBelongToStoreId,
  (
    pageNumber,
    pageSize,
    memberCardPaid,
    isStoreManager,
    userBelongToStoreId
  ) => {
    const offset: number = pageNumber * pageSize;
    const limit: number = pageSize;
    const parameters = {
      offset,
      limit,
      memberCardPaid,
      storeId: '',
    };

    if (userBelongToStoreId && isStoreManager) {
      parameters.storeId = userBelongToStoreId;
    }

    return parameters;
  }
);

export const getVerifyEmployeeModalVisibility = (state: any) =>
  state.player.list.verifyEmployeeModalVisible;
