import { createSelector } from '@reduxjs/toolkit';
import { getTimeMomentWithSecond } from '../../../../../common/utils/time-lib';

export const getOriginalActivityDetail = (state: any) =>
  state.activities.details.data;

export const getActivityDetail = createSelector(
  getOriginalActivityDetail,
  (detail) => {
    return {
      ...detail,
      startTimeMoment: getTimeMomentWithSecond(detail.startTime),
      enrollDeadlineMoment: getTimeMomentWithSecond(detail.enrollDeadline),
    };
  }
);
