import { deleteRequest, get, post, put } from '../../../../service/api';

export const getMemberCardsSetting = async (
  payload: {
    merchantName: string;
  },
  config = { isShow: false }
) => {
  try {
    const { merchantName } = payload;
    const res = await get(
      `/merchants/${merchantName}/assets/member-cards/setting`,
      {},
      {},
      config
    );

    return res;
  } catch (e) {
    throw new Error('get member card setting failed');
  }
};

export const deleteRequestMemberCard = async (payload: {
  merchantName: string;
  id: string;
}) => {
  try {
    const { merchantName, id } = payload;
    const res = await deleteRequest(
      `/merchants/${merchantName}/assets/member-cards/setting/types/${id}`,
      {}
    );

    return res;
  } catch (e) {
    throw new Error('delete member card setting failed');
  }
};

export const postMemberCard = async (payload: { merchantName: string }) => {
  try {
    const { merchantName, ...data } = payload;
    const res = await post(
      `/merchants/${merchantName}/assets/member-cards/setting/types`,
      data
    );
    return res;
  } catch (e) {
    throw new Error('post member card setting failed');
  }
};

export const putMemberCard = async (payload: {
  merchantName: string;
  id: string;
  deleted?: boolean;
  type?: string;
  skinImageUrl?: string;
  description?: string;
}) => {
  try {
    const { merchantName, id, ...data } = payload;
    const res = await put(
      `/merchants/${merchantName}/assets/member-cards/setting/types/${id}`,
      data
    );
    return res;
  } catch (e) {
    throw new Error('put member card setting failed');
  }
};

export const putMemberCardsSetting = async (payload: {
  merchantName: string;
  data: object;
}) => {
  try {
    const { merchantName, data } = payload;
    const res = await put(
      `/merchants/${merchantName}/assets/member-cards/setting`,
      data
    );
    return res;
  } catch (e) {
    throw new Error('put member card setting failed');
  }
};
