import { IFMenuBase } from './types';

export const ROUTE_LIST: IFMenuBase[] = [
  // 菜单相关路由
  {
    key: '/subscription',
    title: '订阅',
    component: 'Subscription',
  },
  {
    key: '/settings',
    title: '设置',
    component: 'Settings',
  },
  {
    key: '/dashboard',
    title: '状态概况',
    component: 'Dashboard',
  },
  {
    key: '/audits',
    title: '审计跟踪',
    component: 'Audit',
  },
  {
    key: '/employees',
    title: '员工管理',
    component: 'EmployeeList',
  },
  {
    key: '/stores',
    title: '门店',
    component: 'StoreList',
  },
  {
    key: '/functions',
    title: '功能',
    component: 'Functions',
  },
  {
    key: '/players',
    title: '会员管理',
    component: 'PlayerList',
  },
  {
    key: '/redemption-market',
    title: '兑换商城',
    component: 'RedemptionMarket',
  },
  {
    key: '/association-activities',
    title: '赛事活动',
    component: 'AssociationFunctions',
  },
  {
    key: '/activities',
    title: '活动管理',
    component: 'ActivityList',
  },
  {
    key: '/ranking-management',
    title: '排行榜管理',
    component: 'RankingManagement',
  },
  {
    key: '/posters',
    title: '海报编辑',
    component: 'PosterList',
  },
  {
    key: '/member-cards',
    title: '个性化会员卡',
    component: 'MemberCards',
  },
  {
    key: '/association/players',
    title: (isBaseball: boolean) => (isBaseball ? '注册球员' : '注册运动员'),
    component: 'AssociationPlayerList',
  },
  {
    key: '/association-activities/enrollments',
    title: '赛事活动报名',
    component: 'EnrollmentList',
  },
  {
    key: '/association-activities/tournaments',
    title: '赛事活动联赛',
    component: 'TournamentList',
  },
  // 非菜单相关路由
  {
    key: '/stores/:id',
    title: '门店详情',
    component: 'StoreDetail',
  },
  {
    key: '/posters/:id',
    title: '海报 | 海报详情',
    component: 'Poster',
  },
  {
    key: '/activities/:id',
    title: '活动 | 活动详情',
    component: 'ActivityDetail',
  },
  {
    key: '/ranking-management/new',
    title: '排行榜管理 | 新增排行榜详情',
    component: 'RankingDetails',
  },
  {
    key: '/ranking-management/:id',
    title: '排行榜管理 | 排行榜详情',
    component: 'RankingDetails',
  },
  {
    key: '/ranking-management/:id/ranking',
    title: '排行榜管理 | 排行榜详情 | 排名',
    component: 'Ranking',
  },
  {
    key: '/association-activities/clubs',
    title: (isBaseball: boolean) =>
      `赛事活动 | ${isBaseball ? '联盟球队' : '成员单位'}认证`,
    component: 'ClubAuthentication',
  },
  {
    key: '/association-activities/clubs/:id',
    title: (isBaseball: boolean) =>
      `赛事活动 | ${isBaseball ? '联盟球队' : '成员单位'}认证 | 认证详情`,
    component: 'ClubDetail',
  },
  {
    key: '/association-activities/clubs/new',
    title: (isBaseball: boolean) =>
      `赛事活动 | 新增${isBaseball ? '联盟球队' : '成员单位'}认证`,
    component: 'AddClub',
  },
  {
    key: '/association-activities/clubs/import',
    title: (isBaseball: boolean) =>
      `赛事活动 | ${isBaseball ? '联盟球队' : '成员单位'}认证 | 表格导入`,
    component: 'ClubAuthenticationImport',
  },
  {
    key: '/association-activities/enrollments',
    title: '赛事活动 | 报名',
    component: 'EnrollmentList',
  },
  {
    key: '/association-activities/enrollments/new',
    title: '赛事活动 | 报名 | 新增报名详情',
    component: 'EnrollmentDetails',
  },
  {
    key: '/association-activities/enrollments/:id',
    title: '赛事活动 | 报名 | 报名详情',
    component: 'EnrollmentDetails',
  },
  {
    key: '/association-activities/enrollments/:id/clubs',
    title: '赛事活动 | 报名 | 报名详情 | 俱乐部管理',
    component: 'EnrollmentClubs',
  },
  {
    key: '/association-activities/enrollments/:id/players',
    title: '赛事活动 | 报名 | 报名详情 | 数据管理',
    component: 'EnrollmentResult',
  },
  {
    key: '/association/players/import',
    title: '注册运动员 | 表格导入',
    component: 'AssociationPlayersImport',
  },
  {
    key: '/competition',
    title: '功能 | 射箭比赛',
    component: 'TournamentDetails',
  },
  {
    key: '/players/:id',
    title: '会员 | 会员详情',
    component: 'PlayerDetails',
  },
  {
    key: '/players/new-player',
    title: '会员 | 新增会员',
    component: 'NewPlayer',
  },
  {
    key: '/players/import',
    title: '会员 | 表格导入',
    component: 'PlayerImport',
  },
  {
    key: '/employees/:id',
    title: '员工 | 员工详情',
    component: 'EmployeeDetail',
  },
  {
    key: '/assets',
    title: '会员权益管理',
    component: 'AssetList',
  },
  {
    key: '/redemption-market/order-management',
    title: '兑换商城 | 订单管理',
    component: 'RedemptionMarketOrders',
  },
  {
    key: '/redemption-market/order-management/:id',
    title: '订单管理 | 订单详情',
    component: 'RedemptionMarketOrderDetails',
  },
  {
    key: '/redemption-market/voucher-management',
    title: '兑换商城 | 券码管理',
    component: 'RedemptionMarketVouchers',
  },

  {
    key: '/redemption-market/merchandise-management',
    title: '兑换商城 | 产品管理',
    component: 'RedemptionMarketMerchandises',
  },
  {
    key: '/redemption-market/merchandise-management/:id',
    title: '兑换商城 | 产品详情',
    component: 'RedemptionMarketMerchandiseDetails',
  },
  {
    key: '/functions/history/players-achievement-import/:associationHistoryTournamentTemplateId',
    title: '导入运动员成绩表',
    component: 'PlayersAchievementImport',
  },
  {
    key: '/functions/history/clubs-ranking-import/:associationHistoryTournamentTemplateId',
    title: '导入俱乐部名次表',
    component: 'ClubsRankingImport',
  },
];
