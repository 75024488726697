import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { RootState } from '../../../../../common/utils/types';
import {
  setAssetsDictionary,
  setPaginateNum,
  setTableData,
  setTotal,
} from './index';
import { getAssets, searchVoucherList } from '../../../../../service/http';
import { getAssetsDictionary, getPageSize } from './selectors';

const voucherNameMap: { [key: string]: string } = {
  sentOut: '已发放',
  redeemed: '已核销',
  cancelled: '已取消',
};

export const getTheAssets = createAsyncThunk(
  'miniMarket/vouchers/getAssets',
  async (_, { dispatch }) => {
    try {
      const res: any = await getAssets();
      if (res.message === 'OK') {
        // 将资产类型数组中的id作为key，资产类型名称作为value，存入redux
        const assetsDictionary = res.data.types.reduce(
          (acc: { [key: string]: string }, cur: any) => {
            acc[cur.id] = cur.type;
            return acc;
          },
          {}
        );
        dispatch(setAssetsDictionary(assetsDictionary));
      }
    } catch (e) {
      console.error('get stores error', e);
      throw e;
    }
  }
);

export const requestTableData = createAsyncThunk(
  'miniMarket/vouchers/requestTableData',
  async (param: any, { dispatch, getState }) => {
    try {
      const store: any = getState();
      var assetsDictionary: { [key: string]: string } =
        getAssetsDictionary(store);
      if (Object.keys(assetsDictionary).length === 0) {
        const res: any = await getAssets();
        if (res.message === 'OK') {
          // 将资产类型数组中的id作为key，资产类型名称作为value，存入redux
          assetsDictionary = res.data.types.reduce(
            (acc: { [key: string]: string }, cur: any) => {
              acc[cur.id] = cur.type;
              return acc;
            },
            {}
          );
        }
      }
      if (!param.from || !param.to) {
        dispatch(setTableData([]));
        dispatch(setTotal(0));
        return;
      }
      const list: any = await searchVoucherList(param);
      dispatch(
        setTableData(
          list.data.map((data: any) => {
            let newData = data;
            newData.createdTime = moment(data.createdTime).format(
              'YYYY/MM/DD HH:mm:ss'
            );
            newData.status = voucherNameMap[data.status];
            newData.key = data.id;

            return newData;
          })
        )
      );
      dispatch(setTotal(list.pagination.total));
      if (list.pagination.end) {
        const state = getState() as RootState;
        const pageSize = getPageSize(state);
        const newPage = Math.ceil(list.pagination.total / pageSize);
        newPage === 0
          ? dispatch(setPaginateNum(1))
          : dispatch(setPaginateNum(newPage));
      }
    } catch (e) {
      console.error('request table data error', e);
      throw e;
    }
  }
);
