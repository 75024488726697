import { get, post } from '../../../service/api';

export const getPlayersList = async (merchantName: string, param: any) => {
  try {
    const res = await get(`/merchants/${merchantName}/players`, param);
    return res;
  } catch (e) {
    throw new Error('get players list failed');
  }
};

export const getAllExportedPlayers = async (merchantName: string) => {
  try {
    const res = await get(`/merchants/${merchantName}/players/export/all`, '');
    return res;
  } catch (e) {
    throw new Error('get all exported players failed');
  }
};

export const postVerifyUserPassword = async (
  merchantName: string,
  payload: {
    accountName: string;
    password: string;
  }
) => {
  try {
    const res = await post(
      `/merchants/${merchantName}/employees.verify`,
      payload
    );
    return res;
  } catch (e) {
    throw new Error('post verify user password failed');
  }
};

export const getMemberCardSetting = async (merchantName: string) => {
  try {
    const res = await get(
      `/merchants/${merchantName}/assets/member-cards/setting`,
      {},
      {},
      { isShow: false }
    );
    return res;
  } catch (e) {
    throw new Error('get member card setting failed');
  }
};

export const getPlayerById = async (merchantName: string, id: string) => {
  try {
    const res = await get(`/merchants/${merchantName}/players/${id}`, '');
    return res;
  } catch (e) {
    throw new Error('get player by id failed');
  }
};

export const getPlayerMemberCards = async (
  merchantName: string,
  id: string
) => {
  try {
    const res = await get(
      `/merchants/${merchantName}/players/${id}/assets/member-cards`,
      ''
    );
    return res;
  } catch (e) {
    console.error('getPlayerMemberCards error', e);
    throw e;
  }
};

export const addNewPlayer = async (merchantName: string, param: any) => {
  try {
    const res = await post(`/merchants/${merchantName}/players`, param);
    return res;
  } catch (e) {
    console.error('addNewPlayer error', e);
    throw e;
  }
};

export const getEmployeeList = async (merchantName: string, param: any) => {
  try {
    const res = await get(
      `/merchants/${merchantName}/employees`,
      param,
      {},
      { isShow: false }
    );
    return res;
  } catch (e) {
    throw e;
  }
};

export const getImportLogs = async (merchantName: string, param: any) => {
  try {
    const res = await get(
      `/merchants/${merchantName}/players/import/tasks`,
      param
    );
    return res;
  } catch (e) {
    throw e;
  }
};

export const getImportTemplate = async (merchantName: string) => {
  try {
    const res = await get(
      `/merchants/${merchantName}/players/import/template`,
      {}
    );
    return res;
  } catch (e) {
    throw e;
  }
};
