import { createSelector } from '@reduxjs/toolkit';
import { getEmployees } from '../../../../redux/entities/employees/selectors';

export const getTheDataSource = (state: {
  player: { imports: { dataSource: [any] } };
}) => state.player.imports.dataSource;

export const getTheTotal = (state: {
  player: { imports: { total: number } };
}) => state.player.imports.total;

export const getThePaginateNum = (state: {
  player: { imports: { paginateNum: number } };
}) => state.player.imports.paginateNum;

export const getThePageSize = (state: {
  player: { imports: { pageSize: number } };
}) => state.player.imports.pageSize;
export const getHeader = (state: { player: { imports: { header: [any] } } }) =>
  state.player.imports.header;

export const getTheEmployeeList = createSelector(
  getEmployees,
  (employees) => employees
);
