import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import moment from 'moment';
import { setDataSource, setHeader, setTotal } from '.';
import { getMerchantName } from '../../../../../../../redux/app-v2/merchant/selectors';
import {
  getEmployeeList,
  getImportLogs,
  getImportTemplate,
} from './api-request';
import {
  getTheEmployeeList,
  getThePageSize,
  getThePaginateNum,
} from './selectors';

export const getTheImportLogs = createAsyncThunk(
  'clubAuthentication/import/getImportLogs',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as any;
      const merchantName = getMerchantName(state);
      let employeeList: { [key: string]: string } = getTheEmployeeList(
        state
      ).reduce((acc: any, cur: any) => {
        acc[cur.id] = cur.name;
        return acc;
      }, {});
      if (Object.keys(employeeList).length === 0) {
        const originEmployeeList: any = await getEmployeeList(merchantName, {
          offset: 0,
          limit: 1000,
        });
        employeeList = originEmployeeList.data.reduce((acc: any, cur: any) => {
          acc[cur.id] = cur.name;
          return acc;
        }, {});
      }
      const paginateNum = getThePaginateNum(state);
      const pageSize = getThePageSize(state);
      const params = {
        offset: (paginateNum - 1) * pageSize,
        limit: pageSize,
      };
      const res: any = await getImportLogs(merchantName, params);
      if (res.message === 'OK') {
        dispatch(
          setDataSource(
            res.data.map((log: any) => {
              const operatorName = employeeList[log['operatorId']];
              log['createdTime'] = moment(log['createdTime']).format(
                'YYYY/MM/DD HH:mm:ss'
              );
              log['operatorName'] = operatorName;
              return log;
            })
          )
        );
        dispatch(setTotal(res.pagination.total));
      }
    } catch (e) {
      throw e;
    }
  }
);

export const getImportTheTemplate = createAsyncThunk(
  'clubAuthentication/import/getImportTemplate',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as any;
      const merchantName = getMerchantName(state);
      const res: any = await getImportTemplate(merchantName);
      if (res.message === 'OK') {
        const title = res.data.firstLine.split(',');
        const headers = title.map((item: string) => {
          return {
            label: item,
            key: item,
          };
        });

        dispatch(setHeader(headers));
      }
    } catch (e) {
      notification.error({
        message: '获取模板失败',
        description: '服务器开小差',
      });
    }
  }
);
