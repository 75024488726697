import { createSelector } from '@reduxjs/toolkit';

export const getClubAuthenticationTableDataSource = (state: any) =>
  state.clubs.list.tableDataSource;

export const getClubAuthenticationSearchInit = (state: any) =>
  state.clubs.list.searchInit;

export const getClubAuthenticationTableData = createSelector(
  getClubAuthenticationTableDataSource,
  (clubAuthenticationTableDataSource) => {
    return clubAuthenticationTableDataSource?.map(
      (rowDataSource: {
        id: string;
        name: string;
        clubMerchantName: string | undefined;
        memberId: string | undefined;
        contactPhoneNumber: string | undefined;
        applyingDate: string | undefined;
        confirmed: boolean | undefined;
      }) => {
        const {
          name,
          clubMerchantName,
          memberId,
          contactPhoneNumber,
          applyingDate,
          confirmed,
          id,
        } = rowDataSource;
        return {
          id,
          name,
          clubMerchantName,
          memberId,
          contactPhoneNumber,
          applyingDate: applyingDate?.substring(0, 10),
          confirmed,
        };
      }
    );
  }
);

export const getClubAuthenticationPageNumber = (state: any) =>
  state.clubs.list.pageNumber;

export const getClubAuthenticationPageSize = (state: any) =>
  state.clubs.list.pageSize;

export const getClubAuthenticationTotal = (state: any) =>
  state.clubs.list.total;

export const getClubAuthenticationSearchInputText = (state: any) =>
  state.clubs.list.filters.searchInputText;

export const getClubAuthenticationFiltersIsMember = (state: any) =>
  state.clubs.list.filters.isMember;

export const getClubAuthenticationShowTransferModal = (state: any) =>
  state.clubs.list.showTransferModal;

export const getClubAuthenticationTransferMemberId = (state: any) =>
  state.clubs.list.transferMemberId;

export const getClubAuthenticationTransferMemberData = (state: any) =>
  state.clubs.list.transferMemberData;
