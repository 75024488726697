import {
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Radio,
  Space,
  Result,
  Select,
  Upload,
  message,
} from 'antd';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import {
  PlusCircleOutlined,
  InfoCircleOutlined,
  InboxOutlined,
  EditOutlined,
} from '@ant-design/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { publicIp } from '../../../../../../service/apiIP';
import {
  STATUS_OPTIONS,
  EXCHANGE_BY_ASSETS_CATEGORIES,
  EXCHANGE_CATEGORIES,
} from '../../constants';
import { AppDispatch } from '../../../../../../redux/store';
import {
  getUserAuthorization,
  getMerchantName,
  getAssetsSettingTypes,
} from '../../redux/selectors';
import { createMerchandise } from '../../redux/thunks';
import RichEditor from '../../../../../../common/components/RichEditor';
import './NewMerchandiseModal.less';

const { Option } = Select;
const NewMerchandiseModal: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const userAuthorization = useSelector(getUserAuthorization);
  const merchantName = useSelector(getMerchantName);
  const assetsSettingTypes = useSelector(getAssetsSettingTypes);
  const [form] = Form.useForm();
  const [uploadList, setUploadList] = useState<any>([]);
  const [displayName, setDisplayName] = useState('');
  const [enabled, setEnabled] = useState(true);
  const [exchangeBy, setExchangeBy] = useState({
    id: null,
    type: '',
    value: 1,
  });
  const [exchangeByUnit, setExchangeByUnit] = useState('');
  const [exchangeByDescription, setExchangeByDescription] = useState('');
  const [exchanged, setExchanged] = useState({
    id: null,
    type: '',
    value: 1,
  });
  const [exchangedUnit, setExchangedUnit] = useState('');
  const [exchangedDescription, setExchangedDescription] = useState('');
  const [description, setDescription] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createdNewMerchandise, setCreatedNewMerchandise] = useState(false);
  const onCloseModal = useCallback(() => {
    setUploadList([]);
    setDisplayName('');
    setEnabled(true);
    setExchangeBy({
      id: assetsSettingTypes[0]?.id,
      type: EXCHANGE_BY_ASSETS_CATEGORIES[0]?.value,
      value: assetsSettingTypes[0]?.value || 1,
    });
    setExchangeByUnit(assetsSettingTypes[0]?.unit);
    setExchangeByDescription(assetsSettingTypes[0]?.description);
    setExchanged({
      id: assetsSettingTypes[0]?.id,
      type: EXCHANGE_CATEGORIES[0]?.value,
      value: 1,
    });
    setDescription('');
    setIsModalVisible(false);
    setCreatedNewMerchandise(false);
    setUploadList([]);

    form.setFieldsValue({
      uploadList: [],
      displayName: '',
      enabled: true,
      exchangeBy: null,
      exchangeByUnit: '',
      exchangeByDescription: '',
      exchanged: null,
      description: '',
    });
  }, [assetsSettingTypes, form]);
  useEffect(() => {
    if (assetsSettingTypes[0]) {
      setExchangeBy({
        ...exchangeBy,
        id: assetsSettingTypes[0].id,
        type: EXCHANGE_BY_ASSETS_CATEGORIES[0].value,
      });
      setExchangeByUnit(assetsSettingTypes[0].unit);
      setExchangeByDescription(assetsSettingTypes[0].description);
      setExchanged({
        ...exchanged,
        id: assetsSettingTypes[0].id,
        type: EXCHANGE_CATEGORIES[0].value,
      });
    }
  }, [assetsSettingTypes]);
  const currentAsset = (assetsSettingTypes: any, id: string | null) =>
    assetsSettingTypes.find((type: any) => type.id === id) || {};

  return (
    <>
      <Button
        className="insertButton"
        onClick={() => {
          setIsModalVisible(true);
        }}
      >
        <PlusCircleOutlined />
        <span>添加礼品</span>
      </Button>
      {isModalVisible ? (
        <Modal
          title="添加礼品"
          visible={true}
          onOk={async () => {
            try {
              await form.validateFields();

              if (!uploadList[0].url) {
                message.error('必须上传礼品图片');

                return;
              }

              const merchandise = {
                imageUrl: uploadList[0].url,
                displayName,
                enabled,
                exchangeBy,
                exchanged,
                description,
              };

              dispatch(createMerchandise(merchandise));

              onCloseModal();
            } catch (e) {
              message.error('添加礼品失败');
            }
          }}
          onCancel={onCloseModal}
          // If footer is undefined, the DOM will use ant.design default modal footer
          footer={createdNewMerchandise ? null : undefined}
          destroyOnClose
        >
          {createdNewMerchandise ? (
            <>
              <Result
                className="newMerchandiseModalResult"
                status="success"
                title="成功创建活动"
              />
            </>
          ) : (
            <Form
              layout="vertical"
              form={form}
              name="newMerchandiseForm"
              initialValues={{
                uploadList,
                displayName,
                enabled,
                exchangeBy,
                exchangeByUnit,
                exchangeByDescription,
                exchanged,
                description,
              }}
            >
              <Form.Item
                className="formItem"
                label="礼品图片"
                name="uploadList"
                rules={[{ required: true, message: '请上传礼品图片' }]}
              >
                <Upload
                  className="merchandise-drag"
                  listType="picture"
                  accept=".png, .jpg, .jpeg"
                  defaultFileList={uploadList}
                  headers={{
                    Authorization: userAuthorization,
                  }}
                  beforeUpload={(file) => {
                    const validImageTypes = [
                      'image/png',
                      'image/jpeg',
                      'image/jpg',
                    ];

                    if (file.size > 2 * 1024 * 1024) {
                      message.error('图片大小不能超过2M');

                      return false;
                    }

                    if (!validImageTypes.includes(file.type)) {
                      message.error('图片必须为png, jpeg或者jpg的格式');

                      return false;
                    }

                    return true;
                  }}
                  action={`${publicIp}/merchants/${merchantName}/market/merchandises/pic`}
                  onChange={({ file, fileList }) => {
                    if (file.status === 'uploading') {
                      const uploadList = document.querySelectorAll(
                        '.ant-upload-list-picture-container'
                      );

                      uploadList.forEach((currentUpload: any, index) => {
                        if (index !== fileList.length - 1) {
                          currentUpload.style.display = 'none';
                        }
                      });
                    }

                    if (file.status === 'done') {
                      message.success(`${file.name} 礼品图片上传成功.`);
                      const { imageUrl } = file.response.data;
                      const name = imageUrl?.split('/').pop() || '';

                      setUploadList([
                        {
                          uid: imageUrl,
                          name,
                          status: 'done',
                          url: imageUrl,
                        },
                      ]);
                    } else if (file.status === 'error') {
                      message.error(`${file.name} 礼品图片上传失败.`);
                    }
                  }}
                  onRemove={() => {
                    const uploader = document.querySelector(
                      '.merchandise-drag input[type="file"]'
                    ) as HTMLInputElement;

                    uploader.click();

                    return false;
                  }}
                  showUploadList={{
                    removeIcon: <EditOutlined />,
                  }}
                  onPreview={async (file: UploadFile) => {
                    let src = file.url as string;
                    if (!src) {
                      src = await new Promise((resolve) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file.originFileObj as RcFile);
                        reader.onload = () => resolve(reader.result as string);
                      });
                    }
                    const image = new Image();
                    image.src = src;
                    const imgWindow = window.open(src);
                    imgWindow?.document.write(image.outerHTML);
                  }}
                >
                  <div
                    id="newMerchandiseUploader"
                    style={{
                      display: uploadList.length > 0 ? 'none' : 'block',
                    }}
                    className="ant-upload ant-upload-select ant-upload-select-picture-card"
                  >
                    <span className="ant-upload" role="button">
                      <InboxOutlined
                        style={{ fontSize: '42px', color: '#4fc7e4' }}
                      />
                    </span>
                  </div>
                </Upload>
              </Form.Item>
              <Form.Item
                className="formItem"
                label="礼品名称"
                name="displayName"
                rules={[{ required: true, message: '请填写礼品名称' }]}
              >
                <Input
                  value={displayName}
                  onChange={(e) => {
                    setDisplayName(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item className="formItem" label="状态">
                <Radio.Group
                  options={STATUS_OPTIONS}
                  onChange={(e) => {
                    setEnabled(e.target.value === STATUS_OPTIONS[0].value);
                  }}
                  value={
                    enabled ? STATUS_OPTIONS[0].value : STATUS_OPTIONS[1].value
                  }
                />
              </Form.Item>
              <Form.Item
                className="formItem"
                label="兑换方式"
                extra={exchangeByDescription}
              >
                <Space size="middle">
                  <Select
                    defaultValue={EXCHANGE_BY_ASSETS_CATEGORIES[0].value}
                    style={{ width: 90 }}
                  >
                    {EXCHANGE_BY_ASSETS_CATEGORIES.map((category) => {
                      return (
                        <Option key={category.value}>{category.label}</Option>
                      );
                    })}
                  </Select>
                  <Select
                    labelInValue
                    defaultValue={{
                      value: exchangeBy.id,
                      label:
                        assetsSettingTypes.find(
                          (item: { id: string }) => item.id === exchangeBy.id
                        )?.type || '',
                    }}
                    style={{ width: 90 }}
                    onChange={({ value, label }) => {
                      const { description, unit } = currentAsset(
                        assetsSettingTypes,
                        value
                      );

                      setExchangeBy({
                        ...exchangeBy,
                        type: EXCHANGE_BY_ASSETS_CATEGORIES[0].value,
                        id: value,
                        value: exchangeBy.value || 1,
                      });
                      setExchangeByUnit(unit);
                      setExchangeByDescription(description);
                    }}
                  >
                    {assetsSettingTypes.map((type: any) => (
                      <Option key={type.id}>{type.type}</Option>
                    ))}
                  </Select>
                  <InputNumber
                    min={1}
                    defaultValue={exchangeBy?.value}
                    value={exchangeBy?.value}
                    onChange={(value) => {
                      setExchangeBy({
                        ...exchangeBy,
                        value,
                      });
                    }}
                  />
                  <span>{exchangeByUnit}</span>
                </Space>
              </Form.Item>
              <Form.Item
                className="formItem"
                label="兑换物品"
                extra={exchangedDescription}
              >
                <Space size="middle">
                  <Select
                    defaultValue={EXCHANGE_CATEGORIES[0].value}
                    style={{ width: 90 }}
                    onChange={(value) => {
                      if (value === 'voucher') {
                        setExchanged({
                          id: null,
                          type: EXCHANGE_CATEGORIES[1].value,
                          value: 1,
                        });
                      } else {
                        setExchanged({
                          id: assetsSettingTypes[0].id,
                          type: EXCHANGE_CATEGORIES[0].value,
                          value: 1,
                        });
                      }
                    }}
                  >
                    {EXCHANGE_CATEGORIES.map((category) => {
                      return (
                        <Option key={category.value}>{category.label}</Option>
                      );
                    })}
                  </Select>
                  {exchanged.type === 'voucher' ? null : (
                    <Select
                      labelInValue
                      defaultValue={{
                        value: exchanged?.id,
                        label:
                          assetsSettingTypes.find(
                            (item: { id: string }) => item.id === exchanged.id
                          )?.type || '',
                      }}
                      style={{ width: 90 }}
                      onChange={({ value }) => {
                        const { description, unit } = currentAsset(
                          assetsSettingTypes,
                          value
                        );

                        setExchanged({
                          ...exchanged,
                          id: value,
                          type: EXCHANGE_CATEGORIES[0].value,
                        });

                        setExchangedUnit(unit);
                        setExchangedDescription(description);
                      }}
                    >
                      {assetsSettingTypes.map((type: any) => (
                        <Option key={type.id}>{type.type}</Option>
                      ))}
                    </Select>
                  )}
                  <InputNumber
                    disabled={exchanged.type === 'voucher'}
                    min={1}
                    defaultValue={exchanged?.value}
                    value={exchanged?.value}
                    onChange={(value) => {
                      setExchanged({
                        ...exchanged,
                        value,
                      });
                    }}
                  />
                  {exchanged.type === 'voucher' ? null : (
                    <span>{exchangedUnit}</span>
                  )}
                </Space>
              </Form.Item>
              <Form.Item
                className="formItem"
                label="礼品详情"
                tooltip={{
                  title:
                    '礼品详情主要在手机端显示使用，为了加载快速，请使用尽量小的图片，为了整洁美观，建议图片宽度不超过350px，文档总大小请不要超过2M！',
                  icon: <InfoCircleOutlined />,
                }}
              >
                <RichEditor
                  uploadHeader={{
                    Authorization: userAuthorization,
                  }}
                  uploadUrl={`${publicIp}/merchants/${merchantName}/market/merchandises/pic`}
                  content={description}
                  setContent={(content) => {
                    setDescription(content);
                  }}
                  savePoster={() => {}}
                />
              </Form.Item>
            </Form>
          )}
        </Modal>
      ) : null}
    </>
  );
};

export default NewMerchandiseModal;
