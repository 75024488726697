/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { useMount } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Input,
  Space,
  Typography,
  Upload,
  message,
  Modal,
  Switch,
} from 'antd';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import ImgCrop from 'antd-img-crop';
import {
  QrcodeOutlined,
  CloudUploadOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { errorMessage } from '../../../common/utils/feedback';
import { AppDispatch } from '../../../redux/store';
import { publicIp } from '../../../service/apiIP';
import PopWechatQR from './PopWechatQR';
import {
  getMerchantAgreement,
  getMerchantName,
  getMerchantDisplayName,
  getUserAuthorization,
  getMerchantLogo,
  getMerchantBanners,
  getMerchantEnableLiveStream,
  getMerchantLiveStreamId,
  getMerchantVoteLink,
} from './redux/selectors';
import { updateMerchant, loadMerchantInfo } from './redux/thunks';
import { actions as merchantActions } from '../../../redux/app-v2/merchant';
import { getIsAssociationMerchant } from '../../../redux/app-v2/merchant/selectors';
import ContentDetail from '../../../common/components/ContentDetail';
import './Settings.less';

const { Text } = Typography;
const { TextArea } = Input;
const { Dragger } = Upload;

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const Settings = () => {
  const [logoPreviewVisible, setLogoPreviewVisible] = useState(false);
  const [logoPreviewImage, setLogoPreviewImage] = useState('');
  const [logoPreviewTitle, setLogoPreviewTitle] = useState('');
  const [bannerPreviewVisible, setBannerPreviewVisible] = useState(false);
  const [bannerPreviewImage, setBannerPreviewImage] = useState('');
  const [bannerPreviewTitle, setBannerPreviewTitle] = useState('');

  const displayName = useSelector(getMerchantDisplayName);
  const voteLink = useSelector(getMerchantVoteLink);
  const IsEnabledLiveStream = useSelector(getMerchantEnableLiveStream);
  const liveStreamId = useSelector(getMerchantLiveStreamId);
  const userAgreement = useSelector(getMerchantAgreement);
  const merchantName = useSelector(getMerchantName);
  const isAssociationMerchant = useSelector(getIsAssociationMerchant);
  const userAuthorization = useSelector(getUserAuthorization);
  const merchantLogo = useSelector(getMerchantLogo);
  const banners = useSelector(getMerchantBanners);
  const dispatch = useDispatch<AppDispatch>();

  const originalLogoFileList: UploadFile<any>[] = merchantLogo
    ? [
        {
          uid: merchantLogo,
          status: 'done',
          name: '',
          url: merchantLogo,
          thumbUrl: merchantLogo,
        },
      ]
    : [];
  const originalBannerFileList: UploadFile<any>[] = banners.map(
    (banner: { id: string; merchantName: string; imageUrl: string }) => ({
      uid: banner.id,
      status: 'done',
      name: '',
      url: banner.imageUrl,
      thumbUrl: banner.imageUrl,
    })
  );
  const [logoFileList, setLogoFileList] = useState<UploadFile[]>([]);
  const [bannerFileList, setBannerFileList] = useState<UploadFile[]>([]);

  const constructTheQRCode = (classify: string) => {
    PopWechatQR.show({
      title:
        classify === 'merchant' ? '商家端小程序二维码' : '客户端小程序二维码',
      classify: classify,
    });
  };

  useMount(async () => {
    await dispatch(loadMerchantInfo());
  });
  useEffect(() => {
    if (originalLogoFileList.length > 0 && originalLogoFileList[0].url) {
      setLogoFileList(originalLogoFileList);
    }

    if (originalBannerFileList.length > 0 && originalBannerFileList[0].url) {
      setBannerFileList(originalBannerFileList);
    }
  }, [originalLogoFileList.length, originalBannerFileList.length]);

  return (
    <ContentDetail
      title="设置"
      headerRight={
        !isAssociationMerchant ? (
          <div className="text-right">
            <Button
              className="settingsQRCodeButton"
              onClick={() => constructTheQRCode('client')}
            >
              <div className="settingsQRCodeButtonText">
                <QrcodeOutlined style={{ fontSize: '19px' }} />
                在也小程序码
              </div>
            </Button>
            <Button
              className="settingsQRCodeButton"
              onClick={() => constructTheQRCode('merchant')}
            >
              <div className="settingsQRCodeButtonText">
                <QrcodeOutlined style={{ fontSize: '19px' }} />
                小助手小程序码
              </div>
            </Button>
          </div>
        ) : null
      }
    >
      <div className="settingsInputsContainer">
        <div className="inputDiv" style={{ fontSize: '12px', padding: '0px' }}>
          <div>
            <span>标识（LOGO）- 最大能上传2M的png, jpg 或者 jpeg</span>
          </div>
          <ImgCrop grid aspect={1 / 1}>
            <Dragger
              id="logoUploader"
              multiple={true}
              className={`settingsLogoUploader ${
                logoFileList.length > 0 ? 'hidden' : ''
              }`}
              name="file"
              listType="picture"
              accept=".png, .jpg, .jpeg"
              fileList={logoFileList}
              action={`${publicIp}/merchants/${merchantName}/logo`}
              headers={{
                Authorization: userAuthorization,
              }}
              beforeUpload={(file) => {
                const validImageTypes = [
                  'image/png',
                  'image/jpeg',
                  'image/jpg',
                ];

                if (file.size > 2 * 1024 * 1024) {
                  message.error('图片大小不能超过2M');

                  return false;
                }

                if (!validImageTypes.includes(file.type)) {
                  message.error('文件必须为png, jpeg或者jpg的图片');

                  return false;
                }

                return true;
              }}
              onChange={(info) => {
                const { status } = info.file;

                if (status === 'uploading') {
                  setLogoFileList(
                    info.fileList.filter((file) => file.status === 'uploading')
                  );
                }

                if (status === 'done') {
                  message.success(`${info.file.name} 商标上传成功.`);

                  setLogoFileList(
                    info.fileList.filter((file) => file.status === 'done')
                  );

                  dispatch(loadMerchantInfo());
                } else if (status === 'error') {
                  message.error(`${info.file.name} 商标上传失败.`);
                }
              }}
              onPreview={async (file: UploadFile) => {
                if (!file.url && !file.preview) {
                  file.preview = await getBase64(file.originFileObj as RcFile);
                }

                setLogoPreviewImage(file.url || (file.preview as string));
                setLogoPreviewVisible(true);
                setLogoPreviewTitle(
                  file.name ||
                    file.url!.substring(file.url!.lastIndexOf('/') + 1)
                );
              }}
              onRemove={() => {
                const uploader = document.querySelector(
                  '#logoUploader'
                ) as HTMLInputElement;

                uploader.click();
              }}
              showUploadList={{
                removeIcon: <EditOutlined />,
              }}
            >
              <CloudUploadOutlined className="settingsUploadIcon" />
              <p className="settingsUploadText">点击上传商标图片</p>
            </Dragger>
          </ImgCrop>
          <Modal
            visible={logoPreviewVisible}
            title={logoPreviewTitle}
            footer={null}
            onCancel={() => setLogoPreviewVisible(false)}
          >
            <img
              alt="example"
              style={{ width: '100%' }}
              src={logoPreviewImage}
            />
          </Modal>
        </div>

        <div className="inputDiv" style={{ fontSize: '12px', padding: '0px' }}>
          <div>
            <span>
              小程序首页顶部背景图片(最大能上传2M的png, jpg 或者 jpeg)
            </span>
          </div>
          <Dragger
            id="bannerUploader"
            className={`settingsBannerUploader ${
              bannerFileList.length > 0 ? 'hidden' : ''
            }`}
            name="file"
            listType="picture"
            accept=".png, .jpg, .jpeg"
            action={`${publicIp}/merchants/${merchantName}/banner-pic`}
            fileList={bannerFileList}
            headers={{
              Authorization: userAuthorization,
            }}
            beforeUpload={(file) => {
              const validImageTypes = ['image/png', 'image/jpeg', 'image/jpg'];

              if (file.size > 2 * 1024 * 1024) {
                message.error('图片大小不能超过2M');

                return false;
              }

              if (!validImageTypes.includes(file.type)) {
                message.error('文件必须为png, jpeg或者jpg的图片');

                return false;
              }

              return true;
            }}
            onChange={(info) => {
              const { status } = info.file;

              if (status === 'uploading') {
                setBannerFileList(
                  info.fileList.filter((file) => file.status === 'uploading')
                );
              }

              if (status === 'done') {
                message.success(`${info.file.name} 背景图片上传成功.`);

                setBannerFileList(
                  info.fileList.filter((file) => file.status === 'done')
                );

                dispatch(loadMerchantInfo());
              } else if (status === 'error') {
                message.error(`${info.file.name} 背景图片上传失败.`);
              }
            }}
            onPreview={async (file: UploadFile) => {
              if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj as RcFile);
              }

              setBannerPreviewImage(file.url || (file.preview as string));
              setBannerPreviewVisible(true);
              setBannerPreviewTitle(
                file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
              );
            }}
            onRemove={() => {
              const uploader = document.querySelector(
                '#bannerUploader'
              ) as HTMLInputElement;

              uploader.click();
            }}
            showUploadList={{
              removeIcon: <EditOutlined />,
            }}
          >
            <CloudUploadOutlined className="settingsUploadIcon" />
            <p className="settingsUploadText">点击上传首页背景图片</p>
          </Dragger>
          <Modal
            visible={bannerPreviewVisible}
            title={bannerPreviewTitle}
            footer={null}
            onCancel={() => setBannerPreviewVisible(false)}
            width={600}
          >
            <img
              alt="example"
              style={{ width: '100%' }}
              src={bannerPreviewImage}
            />
          </Modal>
        </div>
        <div className="inputDiv" style={{ fontSize: '12px', padding: '0px' }}>
          <div>
            <span style={{ color: 'red' }}>*</span>
            <span>组织显示名称：</span>
          </div>
          <div>
            <Input
              value={displayName}
              onChange={(e) =>
                dispatch(merchantActions.displayNameSet(e.target.value))
              }
              id="displayName"
              className="searchInput"
              allowClear
              style={{ marginTop: '8px' }}
            />
          </div>

          <Space>
            <Text type="secondary" style={{ fontSize: '12px' }}>
              名称将显示在店员与在也小程序中
            </Text>
          </Space>
        </div>

        <div className="inputDiv" style={{ fontSize: '12px' }}>
          <div>
            <span style={{ color: 'red' }}>*</span>
            <span>组织会员条款：</span>
          </div>
          <TextArea
            rows={5}
            value={userAgreement}
            onChange={(e) =>
              dispatch(merchantActions.userAgreementSet(e.target.value))
            }
            id="userAgreement"
            className="searchInput"
            style={{ marginTop: '8px' }}
          />
        </div>

        {isAssociationMerchant ? (
          <div
            className="inputDiv"
            style={{ fontSize: '12px', padding: '0px' }}
          >
            <div>
              <span>投票地址</span>
            </div>
            <div>
              <Input
                value={voteLink}
                onChange={(e) =>
                  dispatch(merchantActions.voteLinkSet(e.target.value))
                }
                id="voteLink"
                className="searchInput"
                allowClear
                style={{ marginTop: '8px' }}
              />
            </div>
          </div>
        ) : null}

        {isAssociationMerchant ? (
          <section className="settingMiniProgramLiveStream">
            <div>
              <span className="settingMiniProgramLiveStreamTitle">
                小程序直播：
              </span>
              <Switch
                checked={IsEnabledLiveStream}
                onChange={(checked: boolean) => {
                  dispatch(merchantActions.enableLiveStreamSet(checked));
                  if (!checked) {
                    dispatch(merchantActions.liveStreamIdSet(null));
                  }
                }}
              />
            </div>

            {IsEnabledLiveStream ? (
              <div className="settingMiniProgramLiveStreamId">
                <span style={{ color: 'red' }}>*</span>
                <span className="settingMiniProgramLiveStreamIdTitle">
                  小程序视频号ID
                </span>
                <Input
                  type="text"
                  value={liveStreamId}
                  onChange={(e) =>
                    dispatch(merchantActions.liveStreamIdSet(e.target.value))
                  }
                />
              </div>
            ) : null}
          </section>
        ) : null}

        <Space className="settingFooter" size={10}>
          <Button
            type="primary"
            className="operateButton"
            onClick={() => {
              if (
                (IsEnabledLiveStream && !liveStreamId) ||
                liveStreamId === ''
              ) {
                errorMessage('请填写小程序视频号ID');
              } else {
                dispatch(updateMerchant());
              }
            }}
          >
            提交修改
          </Button>
        </Space>
      </div>
    </ContentDetail>
  );
};

export default Settings;
