import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../common/utils/types';
import {
  getMerchantName,
  getMerchantSubscriptionAddonStoreCount,
} from '../../../../redux/app-v2/merchant/selectors';
import { getPricePlansMapping } from '../../../../redux/entities/pricePlans/selectors';
import { loadPricePlanList as loadEntitiesPricePlans } from '../../../../redux/entities/pricePlans/thunks';
import {
  getHistoryAgreement,
  getSubscriptionOrderList,
  getSubscriptionOrderStatus,
  postPreviewOrder,
  postSubscriptionOrders,
} from './api-request';
import {
  getSubscriptionOrderListPagination,
  getSubscriptionPreviewOrder,
  getSubscriptionOrderId,
  getSubscriptionOrderListPageSize,
  getSubscriptionOrderAgreementInfo,
  getSubscriptionActivePricePlanType,
  getSubscriptionChangedDifferentAddonStoreCount,
} from './selectors';
import { errorMessage } from '../../../../common/utils/feedback';
import { UNPAID_STATUS } from '../utils/constants';
import { notification } from 'antd';

export const loadPricePlanList = createAsyncThunk(
  'subscription/loadPricePlanList',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const subscriptionAddonStoreCount =
        getMerchantSubscriptionAddonStoreCount(state);
      const { payload }: { payload: any } = await dispatch(
        loadEntitiesPricePlans()
      );

      return {
        pricePlanTypeIds: payload,
        subscriptionAddonStoreCount,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const createPreviewOrder = createAsyncThunk(
  'subscription/createPreviewOrder',
  async (
    changedPayload: {
      planId?: string;
      addonStoreCount?: number;
    },
    { getState }
  ) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);
      const subscriptionPreviewOrder = getSubscriptionPreviewOrder(state);

      const result: any = await postPreviewOrder({
        merchantName,
        ...subscriptionPreviewOrder,
        ...changedPayload,
      });
      const { data, description, code }: any = result || {};

      if (code !== 0 && description) {
        throw Error(description);
      }

      return data;
    } catch (error: any) {
      errorMessage(error?.message || '预览订单失败', {
        handleContinue: () => {
          if (error?.message === '协会商户不支持购买计划') {
            window.location.href = '/';
          }
        },
      });

      throw error;
    }
  }
);

export const updateActivePricePlanId = createAsyncThunk(
  'subscription/updateActivePricePlanId',
  async (planId: string, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const subscriptionChangedDifferentAddonStoreCount =
        getSubscriptionChangedDifferentAddonStoreCount(state);
      const pricePlansMapping = getPricePlansMapping(state);
      const { type } = pricePlansMapping[planId] || {};

      dispatch(
        createPreviewOrder({
          planId,
          addonStoreCount: subscriptionChangedDifferentAddonStoreCount[type],
        })
      );

      return planId;
    } catch (error) {
      throw error;
    }
  }
);

export const updateAddonStoreCount = createAsyncThunk(
  'subscription/updateAddonStoreCount',
  async (addonStoreCount: number, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const subscriptionActivePricePlanType: string =
        getSubscriptionActivePricePlanType(state);
      dispatch(createPreviewOrder({ addonStoreCount }));

      return {
        activeType: subscriptionActivePricePlanType,
        addonStoreCount,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const createSubscriptionOrder = createAsyncThunk(
  'subscription/createSubscriptionOrder',
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);
      const subscriptionPreviewOrder = getSubscriptionPreviewOrder(state);
      const subscriptionAgreementInfo =
        getSubscriptionOrderAgreementInfo(state);

      const result = await postSubscriptionOrders({
        merchantName,
        ...subscriptionPreviewOrder,
        agreementInfo: subscriptionAgreementInfo,
      });
      const { data }: any = result || {};

      return data;
    } catch (error) {
      throw error;
    }
  }
);

let pollingSubscriptionOrderStatusTimer: any = null;

export const pollingSubscriptionOrderStatus = createAsyncThunk(
  'subscription/pollingSubscriptionOrderStatus',
  async ({ id }: { id?: string | undefined }, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const orderId = getSubscriptionOrderId(state);
      const requestOrderId = id || orderId;
      const result = await getSubscriptionOrderStatus(requestOrderId);
      const { data }: any = result || {};

      if (UNPAID_STATUS.includes(data.status)) {
        pollingSubscriptionOrderStatusTimer = setTimeout(async () => {
          await dispatch(
            pollingSubscriptionOrderStatus({ id: requestOrderId })
          );
        }, 3000);
      }

      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const stopPollingSubscriptionOrderStatus = () => {
  clearTimeout(pollingSubscriptionOrderStatusTimer);
};

export const loadSubscriptionOrderList = createAsyncThunk(
  'subscription/loadSubscriptionOrderList',
  async (
    changedPayload: {
      limit?: number;
      offset?: number;
    },
    { getState }
  ) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);
      const subscriptionOrderListPagination =
        getSubscriptionOrderListPagination(state);
      const result = await getSubscriptionOrderList({
        merchantName,
        ...subscriptionOrderListPagination,
        ...changedPayload,
      });
      const { data, pagination }: any = result || {};

      return { data, pagination };
    } catch (error) {
      throw error;
    }
  }
);

export const updateSubscriptionOrderListPageSize = createAsyncThunk(
  'subscription/updateSubscriptionOrderListPageSize',
  async (pageSize: number, { dispatch }) => {
    try {
      dispatch(loadSubscriptionOrderList({ offset: 0, limit: pageSize }));

      return {
        pageNumber: 0,
        pageSize,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const updateSubscriptionOrderListPageNumber = createAsyncThunk(
  'subscription/updateSubscriptionOrderListPageNumber',
  async (pageNumber: number, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const pageSize = getSubscriptionOrderListPageSize(state);
      dispatch(
        loadSubscriptionOrderList({
          offset: pageNumber * pageSize,
        })
      );

      return pageNumber;
    } catch (error) {
      throw error;
    }
  }
);

export const loadHistoryAgreementById: any = createAsyncThunk(
  'components/subscription/loadHistoryAgreementById',
  async (id: string, { getState }) => {
    try {
      const result: any = await getHistoryAgreement(id);

      if (result.data.content === null) {
        errorMessage('当前请求的历史协议不存在');
      }

      return result.data;
    } catch (error) {
      errorMessage('加载指定历史协议详情失败');
      throw error;
    }
  }
);
