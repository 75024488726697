import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRoles } from './api-request';
import { getMerchantName } from '../merchant/selectors';

export const loadRoles = createAsyncThunk(
  'app/loadRoles',
  async (_, { getState }) => {
    try {
      const state = getState();
      const merchantName = getMerchantName(state);
      const result = await getRoles(merchantName);
      const { data } = result;

      return data;
    } catch (error) {
      throw error;
    }
  }
);
