import React, { useEffect, useState, useCallback } from 'react';
import { useWindowSize } from 'react-use';
import { Table, Pagination } from 'antd';
import { ContentTablePropsType } from './types';
import './ContentTable.less';

const ContentTable = ({
  className,
  bordered = false,
  paginationSize = 'default',
  contentTablePaginationConfig = false,
  useDefaultPagination = false,
  columns,
  pageNumber,
  pageSize,
  data,
  dataItemsTotal,
  onRowClick,
  onChangePageSize,
  onChangePageNumber,
}: ContentTablePropsType) => {
  const { height: windowHeight } = useWindowSize();
  const [maxTableHeight, setMaxTableHeight] = useState(0);
  const setMaxAndMinTableHeight = useCallback(
    (windowHeight: number) => {
      const headerHeight =
        document.querySelector('.ant-layout-header')?.clientHeight || 0;
      const pagination = document.querySelector(
        '.ant-pagination'
      ) as HTMLElement | null;
      const paginationHeight = pagination ? pagination.clientHeight + 32 : 0;
      const titleHeight =
        document.querySelector('.content__title')?.clientHeight || 0;
      const tableHeadHeight =
        document.querySelector('.ant-table-thead')?.clientHeight;

      if (tableHeadHeight) {
        setMaxTableHeight(
          windowHeight -
            headerHeight -
            paginationHeight -
            titleHeight -
            tableHeadHeight
        );
      }
    },
    [windowHeight]
  );

  useEffect(() => {
    setMaxAndMinTableHeight(windowHeight);
  }, [windowHeight]);

  return (
    <>
      <Table
        bordered={bordered}
        className={className}
        rowKey={(record: any) => {
          return record.id ? record.id + Date.now() : 1;
        }}
        columns={columns}
        dataSource={data}
        pagination={contentTablePaginationConfig}
        scroll={{ y: maxTableHeight }}
        rowClassName={onRowClick ? 'ContentTableRowClickable' : ''}
        onRow={(record) => ({
          onClick: () => {
            if (onRowClick && typeof onRowClick === 'function') {
              onRowClick(record);
            }
          },
        })}
      />
      {useDefaultPagination ? null : (
        <Pagination
          className="ContentTablePagination"
          size={paginationSize}
          current={pageNumber}
          pageSize={pageSize}
          total={dataItemsTotal}
          showSizeChanger
          showQuickJumper
          onChange={(currentPageNumber, currentPageSize) => {
            if (currentPageSize !== pageSize) {
              onChangePageSize(currentPageNumber, currentPageSize);
            } else if (
              currentPageSize === pageSize &&
              currentPageNumber !== pageNumber
            ) {
              onChangePageNumber(currentPageNumber);
            }
          }}
          showTotal={(total) => `共计 ${total}条 数据`}
        />
      )}
    </>
  );
};

ContentTable.displayName = 'ContentTable';

ContentTable.defaultProps = {
  contentTablePaginationConfig: false,
  useDefaultPagination: false,
};

export default ContentTable;
