import _isNill from 'lodash/isNil';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount, useUnmount } from 'react-use';
import {
  Button,
  Divider,
  Input,
  Modal,
  Select,
  Space,
  Upload,
  message,
} from 'antd';
import ImgCrop from 'antd-img-crop';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import { InboxOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { publicIp } from '../../../../../../service/apiIP';
import { AppDispatch } from '../../../../../../common/utils/types';
import { getUserAuthorization } from '../../../../../../redux/app-v2/user/selectors';
import { getMerchantName } from '../../../../../../redux/app-v2/merchant/selectors';
import {
  getFeatureSettingShownComponent,
  getAssociationHistoryTempTournamentId,
  getDisplayAssociationHistoryTournamentList,
  getAssociationHistoryImage,
  getAssociationHistoryImageUploaderHidden,
} from '../../redux/selectors';
import { actions as functionsActions } from '../../redux';
import {
  createTempTournament,
  loadHistoryClubsRankingImportTemplate,
  loadHistoryPlayersAchievementImportTemplate,
  loadTempTournamentList,
  updateTournamentImage,
} from '../../redux/thunks';
import { errorMessage } from '../../../../../../common/utils/feedback';
import './AssociationHistoriesModal.less';

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

let timer: any = null;
const AssociationHistoriesModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const userAuthorization = useSelector(getUserAuthorization);
  const merchantName = useSelector(getMerchantName);
  const featureSettingShownComponent = useSelector(
    getFeatureSettingShownComponent
  );
  const associationHistoryTournamentTemplateId = useSelector(
    getAssociationHistoryTempTournamentId
  );
  const associationHistoryTournamentList = useSelector(
    getDisplayAssociationHistoryTournamentList
  );
  const associationHistoryImage = useSelector(getAssociationHistoryImage);
  const associationHistoryImageUploaderHidden = useSelector(
    getAssociationHistoryImageUploaderHidden
  );
  const [title, setTitle] = useState('');
  const [historyImage, setHistoryImage] = useState({
    visible: false,
    url: '',
    title: '',
  });

  useMount(() => {
    dispatch(loadTempTournamentList());
  });

  useUnmount(() => {
    dispatch(functionsActions.tempTournamentIdUpdated(null));
  });

  return (
    <>
      <Modal
        centered
        visible={featureSettingShownComponent === 'AssociationHistoriesModal'}
        title="历史成绩"
        onCancel={() => {
          dispatch(functionsActions.componentShown(''));
        }}
        footer={null}
      >
        <div className="flex flex-column flex-middle">
          <div className="AssociationHistoryModalSelectContainer flex flex-start">
            <div className="text-center flex__fluid-content">
              <ImgCrop grid aspect={5 / 4}>
                <Upload
                  id="associationHistoryUploader"
                  name="file"
                  listType="picture-card"
                  accept=".png, .jpg, .jpeg"
                  className="AssociationHistoryModalUpload"
                  headers={{
                    authorization: userAuthorization,
                  }}
                  action={`${publicIp}/merchants/${merchantName}/baseball/tournaments/pic`}
                  beforeUpload={(file) => {
                    const validImageTypes = [
                      'image/png',
                      'image/jpeg',
                      'image/jpg',
                    ];

                    if (file.size > 2 * 1024 * 1024) {
                      message.error('图片大小不能超过2M');

                      return false;
                    }

                    if (!validImageTypes.includes(file.type)) {
                      message.error('文件必须为png, jpeg或者jpg的图片');

                      return false;
                    }

                    return true;
                  }}
                  onChange={(info) => {
                    const { status } = info.file;

                    if (status === 'done') {
                      message.success(
                        `${info.file.name} 比赛宣传头图上传成功.`
                      );
                    } else if (status === 'error') {
                      message.error(`${info.file.name} 比赛宣传头图上传失败.`);
                    }

                    const currentInfo = info.fileList.filter(
                      (file) => file.status === status
                    );

                    dispatch(
                      functionsActions.imageUploadStatusSet(
                        currentInfo[0].status
                      )
                    );

                    if (currentInfo[0].status === 'done') {
                      const { imageUrl } = currentInfo[0].response.data;

                      dispatch(
                        updateTournamentImage({
                          id: associationHistoryTournamentTemplateId,
                          imageUrl,
                        })
                      );
                      dispatch(functionsActions.imageUrlSet(imageUrl));
                    }
                  }}
                  onRemove={() => {
                    const uploader = document.querySelector(
                      '#associationHistoryUploader'
                    ) as HTMLInputElement;

                    uploader.click();
                  }}
                  showUploadList={{
                    removeIcon: (
                      <EditOutlined
                        style={{
                          color: '#FFFFFF',
                        }}
                      />
                    ),
                  }}
                  onPreview={async (file: UploadFile) => {
                    if (!file.url && !file.preview) {
                      file.preview = await getBase64(
                        file.originFileObj as RcFile
                      );
                    }

                    setHistoryImage({
                      visible: true,
                      url: file.url || (file.preview as string),
                      title:
                        file.name ||
                        file.url!.substring(file.url!.lastIndexOf('/') + 1),
                    });
                  }}
                >
                  {associationHistoryImage.url ? (
                    <img
                      src={associationHistoryImage.url}
                      alt="比赛宣传头图"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  ) : (
                    <Button
                      type="text"
                      disabled={!associationHistoryTournamentTemplateId}
                      className={`AssociationHistoryModalUploadImageButton flex flex-column flex-middle flex-center ${
                        associationHistoryImageUploaderHidden ? 'hidden' : ''
                      }`}
                    >
                      <InboxOutlined
                        style={{ fontSize: '42px', color: '#4fc7e4' }}
                      />
                      <span>比赛宣传头图</span>
                    </Button>
                  )}
                </Upload>
              </ImgCrop>
            </div>

            <div className="flex flex-column flex__fluid-content">
              <label className="AssociationHistoryModalSelectLabel">
                锦标赛场次
              </label>
              <Select
                showSearch
                loading={false}
                className="AssociationHistoryModalSelect"
                dropdownClassName="AssociationHistoryModalSelectDropdown"
                placeholder="请输入锦标赛名称"
                value={associationHistoryTournamentTemplateId}
                defaultActiveFirstOption={false}
                filterOption={false}
                onSearch={(value) => {
                  clearTimeout(timer);

                  timer = setTimeout(() => {
                    dispatch(loadTempTournamentList(value));
                  }, 400);
                }}
                onChange={(value) => {
                  dispatch(functionsActions.tempTournamentIdUpdated(value));
                }}
                notFoundContent={
                  <div className="flex flex-column flex-middle flex-center">
                    <InboxOutlined />
                    <span>未搜到任何锦标赛</span>
                  </div>
                }
                options={associationHistoryTournamentList}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <Space style={{ padding: '0 8px 4px' }}>
                      <Input
                        placeholder="输入新锦标赛名称"
                        value={title}
                        onChange={(event) => {
                          setTitle(event.target.value);
                        }}
                      />
                      <Button
                        type="link"
                        onClick={() => {
                          if (!title || title.trim() === '') {
                            return;
                          }

                          dispatch(createTempTournament(title));
                          dispatch(functionsActions.imageUploadStatusSet(null));
                          dispatch(functionsActions.imageUrlSet(null));
                        }}
                      >
                        创建
                      </Button>
                    </Space>
                  </>
                )}
              />
            </div>
          </div>

          <div className="flex flex-middle">
            <div className="text-center">
              <Button
                ghost
                type="primary"
                className="AssociationHistoryModalGhostButton"
                onClick={() => {
                  dispatch(loadHistoryPlayersAchievementImportTemplate())
                    .then(async (response: any) => {
                      if (!_isNill(response.payload)) {
                        const blob = new Blob([response.payload]);
                        const reader = new FileReader();

                        reader.readAsText(blob);
                        reader.onload = (event: any) => {
                          if (
                            event.target?.result.indexOf('"statusCode"') >= 0
                          ) {
                            errorMessage('下载运动员成绩模板失败');
                          } else {
                            if ('download' in document.createElement('a')) {
                              const link = document.createElement('a');

                              link.download = '运动员成绩模板.xlsx';
                              link.style.display = 'none';
                              link.href = URL.createObjectURL(blob);
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                            } else if ((navigator as any).msSaveBlob) {
                              (navigator as any).msSaveBlob(
                                blob,
                                '运动员成绩模板.xlsx'
                              );
                            }
                          }
                        };
                      }
                    })
                    .catch(() => {
                      errorMessage('下载运动员成绩模板失败');
                    });
                }}
              >
                导出运动员成绩模板
              </Button>
              <Button
                ghost
                type="primary"
                className="AssociationHistoryModalGhostButton"
                disabled={!associationHistoryTournamentTemplateId}
                onClick={() => {
                  navigate(
                    `/functions/history/players-achievement-import/${associationHistoryTournamentTemplateId}`
                  );
                  dispatch(functionsActions.componentShown(''));
                }}
              >
                导入运动员成绩表
              </Button>
            </div>
            <div className="text-center">
              <Button
                ghost
                type="primary"
                className="AssociationHistoryModalGhostButton"
                onClick={() => {
                  dispatch(loadHistoryClubsRankingImportTemplate() as any).then(
                    async (response: any) => {
                      if (!_isNill(response.payload)) {
                        const blob = new Blob([response.payload]);
                        const reader = new FileReader();

                        reader.readAsText(blob);
                        reader.onload = (event: any) => {
                          if (
                            event.target?.result.indexOf('"statusCode"') >= 0
                          ) {
                            errorMessage('下载俱乐部名次模板失败');
                          } else {
                            if ('download' in document.createElement('a')) {
                              const link = document.createElement('a');

                              link.download = '俱乐部名次模板.xlsx';
                              link.style.display = 'none';
                              link.href = URL.createObjectURL(blob);
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                            } else if ((navigator as any).msSaveBlob) {
                              (navigator as any).msSaveBlob(
                                blob,
                                '俱乐部名次模板.xlsx'
                              );
                            }
                          }
                        };
                      }
                    }
                  );
                }}
              >
                导出俱乐部名次模板
              </Button>
              <Button
                ghost
                type="primary"
                className="AssociationHistoryModalGhostButton"
                disabled={!associationHistoryTournamentTemplateId}
                onClick={() => {
                  navigate(
                    `/functions/history/clubs-ranking-import/${associationHistoryTournamentTemplateId}`
                  );
                  dispatch(functionsActions.componentShown(''));
                }}
              >
                导入俱乐部名次表
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        visible={historyImage.visible}
        title={historyImage.title}
        footer={null}
        onCancel={() =>
          setHistoryImage({
            visible: false,
            url: '',
            title: '',
          })
        }
        width={600}
      >
        <img
          alt="Association Histories Modal"
          style={{ width: '100%' }}
          src={historyImage.url}
        />
      </Modal>
    </>
  );
};

export default AssociationHistoriesModal;
