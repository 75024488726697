import React, { useState } from 'react';
import { useMount } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Form,
  Input,
  Radio,
  Select,
  InputNumber,
  Button,
  Space,
  Upload,
  message,
  Modal,
} from 'antd';
import {
  InfoCircleOutlined,
  InboxOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { publicIp } from '../../../../../../service/apiIP';
import {
  STATUS_OPTIONS,
  EXCHANGE_BY_ASSETS_CATEGORIES,
  EXCHANGE_CATEGORIES,
} from '../../constants';
import { AppDispatch } from '../../../../../../redux/store';
import { actions as merchandiseActions } from '../../redux';
import {
  loadAssetsSetting,
  loadMerchandiseById,
  saveMerchandiseById,
} from '../../redux/thunks';
import {
  getMerchandiseDetail,
  getUserAuthorization,
  getMerchantName,
} from '../../redux/selectors';
import ContentDetail from '../../../../../../common/components/ContentDetail';
import BreadcrumbRouter from '../../../../../../common/components/BreadcrumbRouter';
import RichEditor from '../../../../../../common/components/RichEditor';
import './MerchandiseDetail.less';

const { Option } = Select;
const MerchandiseDetail: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [imagePreviewVisible, setImagePreviewVisible] = useState(false);
  const [imagePreviewImage, setImagePreviewImage] = useState();
  const merchandise = useSelector(getMerchandiseDetail);
  const userAuthorization = useSelector(getUserAuthorization);
  const merchantName = useSelector(getMerchantName);

  useMount(async () => {
    await dispatch(loadAssetsSetting());
    await dispatch(loadMerchandiseById(id));
  });

  return (
    <ContentDetail
      title="礼品管理 / 礼品详情"
      headerLeft={
        <BreadcrumbRouter
          routes={[
            {
              path: '/redemption-market',
              breadcrumbName: '兑换商城',
            },
            {
              path: '/merchandise-management',
              breadcrumbName: '礼品管理',
            },
            {
              path: `${id}`,
              breadcrumbName: '礼品详情',
            },
          ]}
        />
      }
    >
      <section className="merchandiseDetailFormContainer">
        <Form
          layout="vertical"
          form={form}
          key={merchandise.id}
          initialValues={{ ...merchandise }}
        >
          <Form.Item className="formItem">
            <Upload
              className="merchandise-drag"
              listType="picture"
              accept=".png, .jpg, .jpeg"
              defaultFileList={merchandise.uploadList}
              headers={{
                Authorization: userAuthorization,
              }}
              action={`${publicIp}/merchants/${merchantName}/market/merchandises/pic`}
              beforeUpload={(file) => {
                const validImageTypes = [
                  'image/png',
                  'image/jpeg',
                  'image/jpg',
                ];

                if (file.size > 2 * 1024 * 1024) {
                  message.error('图片大小不能超过2M');

                  return false;
                }

                if (!validImageTypes.includes(file.type)) {
                  message.error('图片必须为png, jpeg或者jpg的格式');

                  return false;
                }

                return true;
              }}
              onChange={({ file, fileList }) => {
                if (file.status === 'uploading') {
                  const uploadList = document.querySelectorAll(
                    '.ant-upload-list-picture-container'
                  );

                  uploadList.forEach((currentUpload: any, index) => {
                    if (index !== fileList.length - 1) {
                      currentUpload.style.display = 'none';
                    }
                  });
                }

                if (file.status === 'done') {
                  message.success(`${file.name} 礼品图片更换成功.`);
                  const { imageUrl } = file.response.data;

                  dispatch(
                    merchandiseActions.updateMerchandiseDetail({ imageUrl })
                  );
                } else if (file.status === 'error') {
                  message.error(`${file.name} 礼品图片更换失败.`);
                }
              }}
              onRemove={() => {
                const uploader = document.querySelector(
                  '.merchandise-drag input[type="file"]'
                ) as HTMLInputElement;

                uploader.click();

                return false;
              }}
              showUploadList={{
                removeIcon: <EditOutlined />,
              }}
              onPreview={() => {
                setImagePreviewVisible(true);
                setImagePreviewImage(merchandise.uploadList[0].url);
              }}
            >
              <div
                id="detailMerchandiseUploader"
                style={{
                  display: merchandise.uploadList.length > 0 ? 'none' : 'block',
                }}
                className="ant-upload ant-upload-select ant-upload-select-picture-card"
              >
                <span className="ant-upload" role="button">
                  <InboxOutlined
                    style={{ fontSize: '42px', color: '#4fc7e4' }}
                  />
                </span>
              </div>
            </Upload>
          </Form.Item>
          <Form.Item className="formItem" label="礼品名称" required>
            <Input
              value={merchandise.displayName}
              onChange={(e) => {
                dispatch(
                  merchandiseActions.updateMerchandiseDetail({
                    displayName: e.target.value,
                  })
                );
              }}
            />
          </Form.Item>
          <Form.Item className="formItem" label="状态">
            <Radio.Group
              options={STATUS_OPTIONS}
              onChange={(e) => {
                dispatch(
                  merchandiseActions.updateMerchandiseDetail({
                    enabled: STATUS_OPTIONS[0].value === e.target.value,
                  })
                );
              }}
              value={
                merchandise.enabled
                  ? STATUS_OPTIONS[0].value
                  : STATUS_OPTIONS[1].value
              }
            />
          </Form.Item>
          <Form.Item
            className="formItem"
            label="兑换方式"
            extra={merchandise.exchangeByDescription}
          >
            <Space size="middle">
              <Select
                defaultValue={EXCHANGE_BY_ASSETS_CATEGORIES[0].value}
                style={{ width: 90 }}
              >
                {EXCHANGE_BY_ASSETS_CATEGORIES.map((category) => {
                  return <Option key={category.value}>{category.label}</Option>;
                })}
              </Select>
              <Select
                labelInValue
                defaultValue={{
                  value: merchandise.exchangeBy?.assetsTypeId,
                  label: merchandise.exchangeByType,
                }}
                style={{ width: 90 }}
                onChange={({ value }) => {
                  dispatch(
                    merchandiseActions.updateMerchandiseDetail({
                      exchangeBy: {
                        ...merchandise.exchangeBy,
                        assetsTypeId: value,
                        type: EXCHANGE_CATEGORIES[0].value,
                      },
                    })
                  );
                }}
              >
                {merchandise.assetsSettingTypes.map((type: any) => (
                  <Option key={type.id}>{type.type}</Option>
                ))}
              </Select>
              <InputNumber
                min={1}
                defaultValue={merchandise.exchangeBy?.value}
                onChange={(value) => {
                  dispatch(
                    merchandiseActions.updateMerchandiseDetail({
                      exchangeBy: { ...merchandise.exchangeBy, value },
                    })
                  );
                }}
              />
              <span>{merchandise.exchangeByUnit}</span>
            </Space>
          </Form.Item>
          <Form.Item
            className="formItem"
            label="兑换物品"
            extra={merchandise.exchangedDescription}
          >
            <Space size="middle">
              <Select
                defaultValue={
                  merchandise.exchangedType === 'voucher'
                    ? EXCHANGE_CATEGORIES[1].value
                    : EXCHANGE_CATEGORIES[0].value
                }
                style={{ width: 90 }}
                onChange={(value) => {
                  if (value === 'voucher') {
                    dispatch(
                      merchandiseActions.updateMerchandiseDetail({
                        exchanged: {
                          ...merchandise.exchanged,
                          assetsTypeId: null,
                          type: EXCHANGE_CATEGORIES[1].value,
                          value: 1,
                        },
                      })
                    );
                  } else {
                    dispatch(
                      merchandiseActions.updateMerchandiseDetail({
                        exchanged: {
                          ...merchandise.exchanged,
                          assetsTypeId: merchandise.assetsSettingTypes[0].id,
                          type: EXCHANGE_CATEGORIES[0].value,
                        },
                      })
                    );
                  }
                }}
              >
                {EXCHANGE_CATEGORIES.map((category) => {
                  return <Option key={category.value}>{category.label}</Option>;
                })}
              </Select>
              {merchandise.exchangedType === 'voucher' ? null : (
                <Select
                  labelInValue
                  defaultValue={{
                    value: merchandise.exchanged?.assetsTypeId,
                    label: merchandise.exchangedType,
                  }}
                  style={{ width: 90 }}
                  onChange={({ value }) => {
                    dispatch(
                      merchandiseActions.updateMerchandiseDetail({
                        exchanged: {
                          ...merchandise.exchanged,
                          assetsTypeId: value,
                          type: EXCHANGE_CATEGORIES[0].value,
                        },
                      })
                    );
                  }}
                >
                  {merchandise.assetsSettingTypes.map((type: any) => (
                    <Option key={type.id}>{type.type}</Option>
                  ))}
                </Select>
              )}
              <InputNumber
                disabled={merchandise.exchangedType === 'voucher'}
                min={1}
                defaultValue={merchandise.exchanged?.value}
                value={merchandise.exchanged?.value}
                onChange={(value) => {
                  dispatch(
                    merchandiseActions.updateMerchandiseDetail({
                      exchanged: { ...merchandise.exchanged, value },
                    })
                  );
                }}
              />
              {merchandise.exchangedType === 'voucher' ? null : (
                <span>{merchandise.exchangedUnit}</span>
              )}
            </Space>
          </Form.Item>
          <Form.Item
            className="formItem"
            label="礼品详情"
            tooltip={{
              title:
                '礼品详情主要在手机端显示使用，为了加载快速，请使用尽量小的图片，为了整洁美观，建议图片宽度不超过350px，文档总大小请不要超过2M！',
              icon: <InfoCircleOutlined />,
            }}
          >
            <RichEditor
              uploadHeader={{
                Authorization: userAuthorization,
              }}
              uploadUrl={`${publicIp}/merchants/${merchantName}/market/merchandises/pic`}
              content={merchandise.description}
              setContent={(content) => {
                dispatch(
                  merchandiseActions.updateMerchandiseDetail({
                    description: content,
                  })
                );
              }}
              savePoster={() => {}}
            />
          </Form.Item>
          <Form.Item>
            <Space size="middle">
              <Button
                type="primary"
                onClick={() => {
                  dispatch(saveMerchandiseById());
                }}
              >
                保存
              </Button>
              <Button
                className="outlineButton"
                onClick={async () => {
                  await dispatch(merchandiseActions.backMerchandiseListPage());

                  navigate(-1);
                }}
              >
                返回
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </section>
      <Modal
        centered
        visible={imagePreviewVisible}
        title=""
        footer={null}
        onCancel={() => setImagePreviewVisible(false)}
        width={600}
      >
        <img alt="example" style={{ width: '100%' }} src={imagePreviewImage} />
      </Modal>
    </ContentDetail>
  );
};

MerchandiseDetail.displayName = 'MerchandiseDetail';

export default MerchandiseDetail;
