import { Modal } from 'antd';
import { useMount } from 'react-use';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import ArcheryCompetitionImage from '../../../../images/archery-competition.png';
import AssociationFunctionsImage from '../../../../images/association-activities.png';
import AssetImage from '../../../../images/asset.png';
import ActivitiesImage from '../../../../images/functions-activities.png';
import AssociationHistoryResult from '../../../../images/association-history-result.png';
import MarketImage from '../../../../images/market.png';
import MemberCardImage from '../../../../images/member-card.png';
import PosterEditingImage from '../../../../images/poster-editing.png';
import RankingImage from '../../../../images/ranking.png';
import FunctionCard from '../../components/FunctionCard';
import ContentDetail from '../../../../common/components/ContentDetail';
import { getIsOperationsManager } from '../../../../redux/app-v2/user/selectors';
import {
  loadTheFeaturesSetting,
  updateTheFeatureSetting,
} from '../../../../redux/app-v2/featuresSetting/thunks';
import {
  getDisplayFeatureSetting,
  getFeatureSettingVisible,
  getIsPosterAvailable,
  getIsAllFunctionsDataPending,
} from './redux/selectors';
import { actions as functionsActions } from './redux';
import { updateBasicToEliteTrail } from './redux/thunks';
import { FUNCTION_URLS_MAP } from './utils/constants';
import AssociationHistoriesModal from './components/AssociationHistoriesModal';
import './Functions.less';

export const FUNCTION_ACTION_TEXT_MAP: { [key: string]: string } = {
  available: '已开通',
  provisioning: '开通',
  trial: '试用',
};

const FEATURE_NAMES: { [key: string]: string } = {
  posters: '海报编辑',
  memberCards: '个性化会员卡',
  rankings: '排行榜管理',
  activities: '活动管理',
  archeryCompetitions: '射箭比赛',
  market: '兑换商城',
  assets: '会员权益管理',
  associationActivities: '赛事活动',
  scoreHistoryStats: '历史成绩',
};

const FEATURE_IMAGES: { [key: string]: string } = {
  posters: PosterEditingImage,
  memberCards: MemberCardImage,
  rankings: RankingImage,
  activities: ActivitiesImage,
  archeryCompetitions: ArcheryCompetitionImage,
  market: MarketImage,
  assets: AssetImage,
  associationActivities: AssociationFunctionsImage,
  scoreHistoryStats: AssociationHistoryResult,
};

const FEATURE_DESCRIPTIONS: { [key: string]: string } = {
  posters: '编辑各类图文综合海报，包括组织介绍，教练介绍等等',
  memberCards: '可以添加各种自定义类型的会员卡(点击进入自定义配置)',
  rankings: '会员可登记成绩，比较成绩形成排名，促进会员竞争学习',
  activities: '发布活动，设置活动人数和时间，让会员自己报名活动',
  archeryCompetitions: '让付费会员可以创建射箭比赛房间与其他会员进行比赛',
  market: '添加礼品，可设置任意权益兑换礼品，实现会员激励闭环',
  assets: '增加和管理会员权益类型，如积分，代金券，课程等',
  associationActivities: '发布赛事活动，组织各成员俱乐部参与活动',
  scoreHistoryStats: '沉淀往届赛事数据，为运动员，俱乐部提供数据支持',
};

const TrialSubscriptionPromptModal = ({
  closable = false,
  showModal,
  content = null,
  footer = null,
  onOk,
  onCancel,
}: {
  closable: boolean;
  showModal: boolean;
  content: any;
  footer: any;
  onOk: (e: React.MouseEvent<HTMLElement>) => void;
  onCancel: (e: React.MouseEvent<HTMLElement>) => void;
}) => {
  return (
    <Modal
      key="FunctionsTrialSubscriptionPromptModal"
      className="FunctionsTrialSubscriptionPromptModal"
      width={638}
      closable={closable}
      visible={showModal}
      onOk={onOk}
      onCancel={onCancel}
      footer={footer}
    >
      {content}
    </Modal>
  );
};

TrialSubscriptionPromptModal.displayName = 'TrialSubscriptionPromptModal';

const ProvisioningPromptModal = ({
  statusText,
  feature,
  showModal,
  isTrail,
  onOk = () => {},
  onCancel = () => {},
}: {
  statusText: string;
  feature: string;
  showModal: boolean;
  isTrail: boolean;
  onOk: (e: React.MouseEvent<HTMLElement>, feature: string) => void;
  onCancel: (e: React.MouseEvent<HTMLElement>) => void;
}) => {
  const FEATURE_PROMPTS: { [key: string]: string } = {
    activities: `${statusText}活动报名之后会在小程序首页显示活动模块，场馆可设置各类可报名的活动信息，玩家可选择场馆设置的各类活动进行报名，活动可以选择链接到一个海报作为活动信息的宣传和描述。`,
    archeryCompetitions: `射箭比赛乐趣多多，仅开放给订购了“精英场”以上的场馆开通。 ${statusText}射箭比赛之后，可以允许本馆已经购买会员卡的会员创建各种弓种距离赛制的射箭比赛，其他会员和游客可搜索房间号加入比赛或者观战。此功能可以增加会员粘性，同时方便于场馆举办各种比赛活动，增加会员活跃度，促进会员消费。`,
    default:
      '请勿发布任何违反中华人民共和国法律法规或加入国际条约的内容，如有违反或被举报，‘在也’平台可在未作通知的情况下先行删除内容或者冻结账号，由此造成的一切法律后果由发布者承担。请参考服务协议第六章，第七章内容。',
  };

  return (
    <Modal
      title={`功能${statusText}`}
      visible={showModal}
      onOk={(e) => {
        onOk(e, feature);
      }}
      onCancel={onCancel}
    >
      <p className="FunctionsPromptText">
        {FEATURE_PROMPTS[feature] || FEATURE_PROMPTS.default}
      </p>
      <p className="FunctionsPromptText">
        {isTrail ? (
          '确定升级为精英俱乐部试用计划吗？'
        ) : (
          <>
            确定{statusText}
            <strong>{FEATURE_NAMES[feature]}</strong> 吗?
          </>
        )}
      </p>
    </Modal>
  );
};

ProvisioningPromptModal.displayName = 'ProvisioningPromptModal';

const Functions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isOperationsManager = useSelector(getIsOperationsManager);
  const displayFeatureSetting = useSelector(getDisplayFeatureSetting);
  const featureSettingVisible = useSelector(getFeatureSettingVisible);
  const isPosterAvailable = useSelector(getIsPosterAvailable);
  const isAllFunctionsDataPending = useSelector(getIsAllFunctionsDataPending);
  const [showPromptModal, setShowPromptModal] = useState(false);
  const [currentFeature, setCurrentFeature] = useState('');
  const [currentFeatureStatusText, setCurrentFeatureStatusText] = useState('');
  const [currentActiveKey, setCurrentActiveKey] = useState('');
  const handlePromptModalConfirm = (
    e: React.MouseEvent<HTMLElement>,
    featureKey: string
  ) => {
    e.preventDefault();

    const data = {
      [featureKey]: true,
    };

    if (currentActiveKey === 'trial') {
      dispatch(updateBasicToEliteTrail() as any);
    } else {
      dispatch(updateTheFeatureSetting(data) as any);
    }
    setShowPromptModal(false);
  };
  const handlePromptModalCancel = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setShowPromptModal(false);
  };

  useMount(() => {
    dispatch(loadTheFeaturesSetting() as any);
  });

  if (!featureSettingVisible) {
    return null;
  }

  return (
    <ContentDetail title="探索所有功能">
      <div className="FunctionsContainer flex flex__wrap">
        {displayFeatureSetting.map((featureSettingItem: any) => {
          const { key, available, trial } = featureSettingItem;
          const activeKey: any = Object.keys(featureSettingItem).find(
            (itemKey: string) => {
              return itemKey !== 'key' && !!featureSettingItem[itemKey];
            }
          );

          if (key === 'scoreHistoryStats' && isOperationsManager) {
            return null;
          }

          return (
            <div
              className="FunctionsCardContainer"
              key={key}
              onClick={() => {
                if (!available) {
                  return;
                }

                if (FUNCTION_URLS_MAP[key]?.pathname) {
                  navigate(FUNCTION_URLS_MAP[key]);
                } else if (FUNCTION_URLS_MAP[key]?.component) {
                  dispatch(
                    functionsActions.componentShown(
                      FUNCTION_URLS_MAP[key].component
                    )
                  );
                }
              }}
            >
              <FunctionCard
                img={FEATURE_IMAGES[key]}
                title={FEATURE_NAMES[key]}
                isAvailable={available}
                isTrial={trial}
                statusText={
                  isAllFunctionsDataPending
                    ? '加载中...'
                    : FUNCTION_ACTION_TEXT_MAP[activeKey] || ''
                }
                description={FEATURE_DESCRIPTIONS[key]}
                onButtonClick={() => {
                  if (key === 'activities' && !isPosterAvailable) {
                    Modal.error({
                      title: `您还不能${FUNCTION_ACTION_TEXT_MAP[activeKey]}活动功能！`,
                      content: `${FUNCTION_ACTION_TEXT_MAP[activeKey]}活动功能前需要先开通海报编辑功能。`,
                    });
                    return;
                  }

                  setCurrentFeature(key);
                  setCurrentFeatureStatusText(
                    FUNCTION_ACTION_TEXT_MAP[activeKey]
                  );
                  setCurrentActiveKey(activeKey);
                  setShowPromptModal(true);
                }}
              />
            </div>
          );
        })}
      </div>
      <ProvisioningPromptModal
        statusText={currentFeatureStatusText}
        feature={currentFeature}
        showModal={showPromptModal}
        isTrail={currentActiveKey === 'trial'}
        onOk={handlePromptModalConfirm}
        onCancel={handlePromptModalCancel}
      />
      <AssociationHistoriesModal />
    </ContentDetail>
  );
};

export default Functions;
