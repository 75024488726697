import { createAsyncThunk } from '@reduxjs/toolkit';
import { loadStoresV2 as loadEntitiesStores } from '../../../../redux/entities/stores/thunks';
import { getMerchantName } from '../../../../redux/app-v2/merchant/selectors';
import { getPosters } from './api-request';
import { errorMessage } from '../../../../common/utils/feedback';

export const loadStoreList = createAsyncThunk(
  'activities/loadStoreList',
  async (_, { dispatch }) => {
    try {
      const { payload } = await dispatch(
        loadEntitiesStores({ offset: 0, limit: 500 })
      );

      return payload;
    } catch (e) {
      errorMessage('加载门店列表失败');
    }
  }
);

export const loadPosterList = createAsyncThunk(
  'activities/loadPosterList',
  async (_, { getState }) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);
      const result: any = await getPosters({
        merchantName,
        offset: 0,
        limit: 500,
      });

      return result.data;
    } catch (e) {
      errorMessage('加载海报列表失败');
    }
  }
);
