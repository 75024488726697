import { combineReducers } from '@reduxjs/toolkit';
import { reducer as list } from '../containers/StoreList/redux';
import { reducer as details } from '../containers/StoreDetails/redux';

const reducer = combineReducers({
  list,
  details,
});

export default reducer;
