import { get, post, deleteRequest } from '../../../../../service/api';

export const getPosterList = async (payload: {
  merchantName: string;
  offset: number;
  limit: number;
}) => {
  try {
    const { merchantName, offset, limit } = payload;
    const params = { offset, limit };

    const { data, pagination }: any = await get(
      `/merchants/${merchantName}/posters`,
      params,
      {},
      { isShow: false }
    );

    return {
      pagination,
      data,
    };
  } catch (error: any) {
    throw error;
  }
};

export const postPoster = async (payload: {
  merchantName: string;
  poster: any;
}) => {
  try {
    const { merchantName, poster } = payload;

    const result = await post(`/merchants/${merchantName}/posters`, poster, {
      isShow: false,
    });

    return result;
  } catch (e) {
    throw e;
  }
};

export const deletePoster = async (payload: {
  merchantName: string;
  id: string;
}) => {
  try {
    const { merchantName, id } = payload;

    const result = await deleteRequest(
      `/merchants/${merchantName}/posters/${id}`,
      {
        isShow: false,
      }
    );

    return result;
  } catch (e) {
    throw e;
  }
};

export const postDuplicatePoster = async (merchantName: string, id: string) => {
  try {
    const result = await post(
      `/merchants/${merchantName}/posters/${id}/duplicate`,
      {},
      {}
    );

    return result;
  } catch (error) {
    throw error;
  }
};
