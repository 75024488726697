import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Input,
  Form,
  Select,
  Checkbox,
  InputNumber,
  Upload,
} from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { actions as enrollmentDetailActions } from '../../redux';
import { priceConvertToIntegerCents } from '../../../../../../../../common/utils/currency';
import { AppDispatch } from '../../../../../../../../redux/store';
import {
  getIsDisabledContent,
  getPlayerInfoMappedData,
} from '../../redux/selectors';
import './EnrollmentPlayerInfoSetting.less';
import { getIsBaseball } from '../../../../../../../../redux/app-v2/merchant/selectors';

const EnrollmentPlayerInfoSetting = ({ isNew }: { isNew: boolean }) => {
  const dispatch = useDispatch<AppDispatch>();
  const playerInfoData = useSelector(getPlayerInfoMappedData);
  const isBaseball = useSelector(getIsBaseball);
  const isDisabledContent = useSelector(getIsDisabledContent);
  const playerText = isBaseball ? '球员' : '运动员';

  return (
    <section className="EnrollmentDetailsAttribute">
      <h5 className="EnrollmentDetailsAttributeTitle">
        选手信息（姓名，电话，证件类型，证件号，性别为默认属性之外可自定义单选属性）
      </h5>
      <ul>
        {(playerInfoData || []).map((playerInfoItem: any) => {
          const {
            key: playerKey,
            default: playerDefault,
            saved: playerSaved,
            propertyName,
            propertyType,
            required,
            optionTypes,
            enableChargePrice,
            pricingInfo,
          } = playerInfoItem;
          const { price, associationPlayerPrice } = pricingInfo || {};
          const notEditablePlayer =
            playerDefault === false && playerSaved !== true;
          const isHiddenAttributeItem = playerDefault || playerSaved;

          if (playerDefault) {
            return null;
          }

          return (
            <li key={playerKey} className="EnrollmentDetailsAttributeItem">
              <div className="flex flex-top">
                <div>
                  <div className="EnrollmentDetailsAttributeBasic flex flex-middle flex__shrink-fixed">
                    <Form.Item initialValue={required}>
                      <Checkbox
                        checked={required}
                        disabled={playerSaved || (isDisabledContent && !isNew)}
                        onChange={(e) => {
                          dispatch(
                            enrollmentDetailActions.updatePlayInfoProperty({
                              currentPlayInfoId: playerKey,
                              playInfoItemProperty: {
                                required: e.target.checked,
                              },
                            })
                          );
                        }}
                      >
                        必填
                      </Checkbox>
                    </Form.Item>
                    <Form.Item
                      className="EnrollmentDetailsAttributeNameItem"
                      name={[playerKey, 'propertyName']}
                      initialValue={propertyName}
                      rules={
                        isHiddenAttributeItem
                          ? []
                          : [
                              {
                                required: true,
                                message: '填写属性名称',
                              },
                            ]
                      }
                    >
                      <Input
                        placeholder="属性名称(最多10个字)"
                        maxLength={10}
                        disabled={
                          isHiddenAttributeItem || (isDisabledContent && !isNew)
                        }
                        value={propertyName}
                        onChange={(e) => {
                          dispatch(
                            enrollmentDetailActions.updatePlayInfoProperty({
                              currentPlayInfoId: playerKey,
                              playInfoItemProperty: {
                                propertyName: e.target.value,
                              },
                            })
                          );
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="EnrollmentDetailsAttributeTypeItem"
                      rules={[{ required: true, message: '填写属性类别' }]}
                    >
                      <Select
                        dropdownMatchSelectWidth={100}
                        disabled={
                          isHiddenAttributeItem || (isDisabledContent && !isNew)
                        }
                        placeholder="选择属性类别"
                        value={propertyType}
                        options={[
                          { value: 'charactor', label: '字符' },
                          { value: 'phoneNumber', label: '电话号码' },
                          { value: 'number', label: '数字' },
                          { value: 'option', label: '单选' },
                          { value: 'multipleOption', label: '多选' },
                          { value: 'imageUrl', label: '图片' },
                        ]}
                        onChange={(value) => {
                          dispatch(
                            enrollmentDetailActions.updatePlayInfoProperty({
                              currentPlayInfoId: playerKey,
                              playInfoItemProperty: {
                                propertyType: value,
                              },
                            })
                          );
                        }}
                      />
                    </Form.Item>

                    {propertyType === 'option' ||
                    propertyType === 'multipleOption' ? (
                      <Form.Item
                        name={[playerKey, 'optionTypes']}
                        initialValue={optionTypes}
                        rules={
                          isHiddenAttributeItem
                            ? []
                            : [
                                {
                                  required: true,
                                  message: '填写属性选项',
                                },
                                {
                                  validator(_, optionValues) {
                                    if (!optionValues) {
                                      return Promise.resolve();
                                    }

                                    const inValidValues = optionValues.filter(
                                      (value: string) => value.length > 8
                                    );

                                    if (
                                      inValidValues &&
                                      inValidValues.length > 0
                                    ) {
                                      return Promise.reject(
                                        new Error('属性选项单个最多8个字')
                                      );
                                    }

                                    return Promise.resolve();
                                  },
                                },
                              ]
                        }
                      >
                        <Select
                          mode="tags"
                          className="EnrollmentDetailsAttributeTypeOptionList"
                          disabled={
                            isHiddenAttributeItem ||
                            (isDisabledContent && !isNew)
                          }
                          placeholder="回车添加新标签，选项最多8个字"
                          options={optionTypes}
                          onChange={(value) => {
                            dispatch(
                              enrollmentDetailActions.updatePlayInfoOptionType({
                                currentPlayInfoId: playerKey,
                                optionTypes: value,
                              })
                            );
                          }}
                        />
                      </Form.Item>
                    ) : null}

                    {propertyType === 'number' ? (
                      <Form.Item initialValue={enableChargePrice}>
                        <Checkbox
                          disabled={
                            isHiddenAttributeItem ||
                            (isDisabledContent && !isNew)
                          }
                          checked={enableChargePrice}
                          onChange={(e) => {
                            dispatch(
                              enrollmentDetailActions.updatePlayInfoProperty({
                                currentPlayInfoId: playerKey,
                                playInfoItemProperty: {
                                  enableChargePrice: e.target.checked,
                                },
                              })
                            );
                          }}
                        >
                          设置价格
                        </Checkbox>
                      </Form.Item>
                    ) : null}
                  </div>
                  {enableChargePrice ? (
                    <>
                      <Form.Item
                        className="EnrollmentDetailsAttributePriceItem"
                        label={`非注册${playerText}价格（元）`}
                        initialValue={price}
                        name={[playerKey, 'price']}
                        rules={[
                          {
                            required: true,
                            message: `非注册${playerText}价格不能为空`,
                          },
                          () => ({
                            validator(_, value) {
                              if (typeof value === 'number' && value <= 0) {
                                return Promise.reject(
                                  new Error(`非注册${playerText}价格不能为0.00`)
                                );
                              }

                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          addonBefore="￥"
                          placeholder="356.50"
                          controls={false}
                          min={0}
                          precision={2}
                          disabled={
                            isHiddenAttributeItem ||
                            (isDisabledContent && !isNew)
                          }
                          value={price}
                          onChange={(value) => {
                            dispatch(
                              enrollmentDetailActions.updatePlayInfoProperty({
                                currentPlayInfoId: playerKey,
                                playInfoItemProperty: {
                                  pricingInfo: {
                                    price: priceConvertToIntegerCents(value),
                                    associationPlayerPrice:
                                      priceConvertToIntegerCents(
                                        associationPlayerPrice
                                      ),
                                  },
                                },
                              })
                            );
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        className="EnrollmentDetailsAttributePriceItem"
                        label={`注册${playerText}价格（元）`}
                        initialValue={associationPlayerPrice}
                        name={[playerKey, 'associationPlayerPrice']}
                        rules={[
                          {
                            required: true,
                            message: `注册${playerText}价格不能为空`,
                          },
                          () => ({
                            validator(_, value) {
                              if (typeof value === 'number' && value <= 0) {
                                return Promise.reject(
                                  new Error(`注册${playerText}价格不能为0.00`)
                                );
                              }

                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          addonBefore="￥"
                          placeholder="356.50"
                          controls={false}
                          min={0}
                          precision={2}
                          disabled={
                            isHiddenAttributeItem ||
                            (isDisabledContent && !isNew)
                          }
                          value={associationPlayerPrice}
                          onChange={(value) => {
                            dispatch(
                              enrollmentDetailActions.updatePlayInfoProperty({
                                currentPlayInfoId: playerKey,
                                playInfoItemProperty: {
                                  pricingInfo: {
                                    price: priceConvertToIntegerCents(price),
                                    associationPlayerPrice:
                                      priceConvertToIntegerCents(value),
                                  },
                                },
                              })
                            );
                          }}
                        />
                      </Form.Item>
                    </>
                  ) : null}
                </div>

                {notEditablePlayer ? (
                  <Button
                    type="link"
                    onClick={() => {
                      dispatch(
                        enrollmentDetailActions.removePlayInfoItem(playerKey)
                      );
                    }}
                  >
                    <MinusCircleOutlined className="EnrollmentDetailsRemoveIcon" />
                  </Button>
                ) : null}
              </div>
            </li>
          );
        })}
      </ul>
      {isDisabledContent && !isNew ? null : (
        <div className="EnrollmentDetailsAttributeItemAddButton">
          <Button
            type="dashed"
            block
            className="EnrollmentDetailsAttributeItemAddButtonContent"
            onClick={() => {
              dispatch(enrollmentDetailActions.addNewPlayInfoItem());
            }}
          >
            添加选手信息
            <PlusCircleOutlined />
          </Button>
        </div>
      )}
    </section>
  );
};

export default EnrollmentPlayerInfoSetting;
