import _isNill from 'lodash/isNil';
import _isBoolean from 'lodash/isBoolean';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { Link, useParams } from 'react-router-dom';
import { Button, Input, Select, Space, Tag } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { AppDispatch } from '../../../../../../common/utils/types';
import { downloadCSV } from '../../../../../../common/utils/csv';
import {
  getEnrollmentClubsFiltersIsSubmitted,
  getEnrollmentClubsPageNumber,
  getEnrollmentClubsPageSize,
  getEnrollmentClubsTableData,
  getEnrollmentClubsTotal,
  getIsExportEnrollmentClubsLoading,
} from './redux/selectors';
import { actions as enrollmentClubsActions } from './redux';
import {
  exportEnrollmentClubs,
  filterEnrollmentClubs,
  loadEnrollmentClubs,
  updateEnrollmentClubsPageNumber,
  updateEnrollmentClubsPageSize,
} from './redux/thunks';
import TableResponsiveFrame from '../../../../../../common/components/tables/TableResponsiveFrame';
import { ContentTableColumnPropsType } from '../../../../../../common/components/tables/ContentTable/types';
import ContentDetail from '../../../../../../common/components/ContentDetail';
import BreadcrumbRouter from '../../../../../../common/components/BreadcrumbRouter';
import './EnrollmentClubs.less';
import { getIsBaseball } from '../../../../../../redux/app-v2/merchant/selectors';

const createContentMarkup = (content: any) => {
  return { __html: content };
};
const EnrollmentClubs = () => {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const enrollmentClubs = useSelector(getEnrollmentClubsTableData);
  const pageNumber = useSelector(getEnrollmentClubsPageNumber);
  const pageSize = useSelector(getEnrollmentClubsPageSize);
  const dataTotal = useSelector(getEnrollmentClubsTotal);
  const isSubmitted = useSelector(getEnrollmentClubsFiltersIsSubmitted);
  const isExportEnrollmentClubsLoading = useSelector(
    getIsExportEnrollmentClubsLoading
  );
  const isBaseball = useSelector(getIsBaseball);
  const clubText = isBaseball ? '联盟球队' : '成员单位';

  const columns: ContentTableColumnPropsType[] = [
    {
      title: `${clubText}名称`,
      width: 400,
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      mobileAlign: 'headerLeft',
      render: (
        name: string,
        { associationClubId }: { associationClubId: string }
      ) => (
        <Link to={`/association-activities/clubs/${associationClubId}`}>
          <div dangerouslySetInnerHTML={createContentMarkup(name)} />
        </Link>
      ),
    },
    {
      title: `${clubText}号`,
      dataIndex: 'associationMemberId',
      key: 'associationMemberId',
      align: 'center',
    },
    {
      title: '领队名称',
      dataIndex: 'leaderName',
      key: 'leaderName',
      align: 'center',
      render: (leaderName: string) => leaderName || '-',
    },
    {
      title: '领队电话',
      dataIndex: 'leaderPhone',
      key: 'leaderPhone',
      align: 'center',
      render: (leaderPhone: string) => leaderPhone || '-',
    },
    {
      title: '状态',
      dataIndex: 'paidByOrderId',
      key: 'paidByOrderId',
      align: 'center',
      render: (paidByOrderId: string, club: any) => {
        const { price } = club || {};
        const { total } = price || {};

        return total === 0 ? (
          <Tag color="default">无需支付</Tag>
        ) : paidByOrderId ? (
          <Tag color="success">已支付</Tag>
        ) : (
          <Tag color="error">未支付</Tag>
        );
      },
    },
  ];

  useMount(async () => {
    await dispatch(enrollmentClubsActions.enrollmentIdUpdated(id));
    dispatch(loadEnrollmentClubs({}) as any);
  });

  return (
    <ContentDetail
      title="报名俱乐部管理"
      headerLeft={
        <BreadcrumbRouter
          routes={[
            {
              path: '/association-activities/enrollments',
              breadcrumbName: '赛事活动报名',
            },
            {
              path: `${id}`,
              breadcrumbName: '报名详情',
            },
            {
              path: 'clubs',
              breadcrumbName: '报名俱乐部管理',
            },
          ]}
        />
      }
      headerRight={
        <div className="EnrollmentClubsButtonGroup">
          <Button
            ghost
            type="primary"
            className="EnrollmentClubsExportButton"
            loading={isExportEnrollmentClubsLoading}
            onClick={async () => {
              if (id) {
                await dispatch(exportEnrollmentClubs() as any).then(
                  async (response: any) => {
                    const status = _isBoolean(isSubmitted)
                      ? isSubmitted
                        ? '- 已提交'
                        : ' - 未提交'
                      : '';

                    if (!_isNill(response.payload)) {
                      await downloadCSV(response.payload, {
                        filename: `俱乐部报名信息表${status}.csv`,
                      });
                    }
                  }
                );
              }
            }}
          >
            <ExportOutlined className="NewEnrollmentButtonOutlinedIcon" />
            <span className="NewEnrollmentButtonText">导出俱乐部报名信息</span>
          </Button>
        </div>
      }
    >
      <div className="content__filter flex flex-middle">
        <Space size="middle">
          <Input.Search
            placeholder={`搜索${clubText}名称`}
            onSearch={async (value: string) => {
              await dispatch(filterEnrollmentClubs({ text: value }));
            }}
            style={{ width: 200 }}
          />
          <Select
            dropdownMatchSelectWidth={120}
            defaultValue={null}
            value={isSubmitted}
            onChange={async (value: boolean | null) => {
              await dispatch(filterEnrollmentClubs({ isSubmitted: value }));
            }}
          >
            <Select.Option value={null}>全部</Select.Option>
            <Select.Option value={true}>已提交</Select.Option>
            <Select.Option value={false}>未提交</Select.Option>
          </Select>
        </Space>
      </div>
      <TableResponsiveFrame
        columns={columns}
        pageNumber={pageNumber + 1}
        pageSize={pageSize}
        data={enrollmentClubs}
        dataItemsTotal={dataTotal}
        onChangePageSize={async (_: number, pageSize: number) => {
          await dispatch(updateEnrollmentClubsPageSize(pageSize));
        }}
        onChangePageNumber={async (pageNumber: number) => {
          await dispatch(updateEnrollmentClubsPageNumber(pageNumber - 1));
        }}
      />
    </ContentDetail>
  );
};

export default EnrollmentClubs;
