import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAssociationPlayerListPageSize,
  getAssociationPlayerListParameters,
  getSearchClubsParameters,
} from './selectors';
import { errorMessage } from '../../../../../../../../common/utils/feedback';
import { loadAssociationClubs, loadAssociationPlayers } from '../common/thunks';
import { BUSINESS_TYPES } from '../../../../../../../../common/constants';
import { getMerchantBusiness } from '../../../../../../../../redux/app-v2/merchant/selectors';

export const loadSearchClubsFilter = createAsyncThunk(
  'association/players/add/loadSearchClubsFilter',
  async (keyword: string | undefined | null, { dispatch, getState }) => {
    try {
      const state: any = getState();
      const searchClubsParameters = getSearchClubsParameters(state);
      const payload = {
        ...searchClubsParameters,
        text: keyword || '',
        isMember: true,
      };
      const data: any = await dispatch(loadAssociationClubs(payload)).unwrap();

      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const loadAssociationPlayerList = createAsyncThunk(
  'clubAuthentication/loadClubAuthentication',
  async (
    changedParameters: {
      limit?: number;
      offset?: number;
      text?: string;
      associationClubId?: string;
      gender?: string;
    },
    { dispatch, getState }
  ) => {
    try {
      const state: any = getState();
      const parameters = getAssociationPlayerListParameters(state);

      const data = await dispatch(
        loadAssociationPlayers({
          ...parameters,
          ...changedParameters,
        })
      ).unwrap();

      return data;
    } catch (error) {
      const state: any = getState();
      const business = getMerchantBusiness(state);
      const isBaseball = business === BUSINESS_TYPES.Baseball;
      const clubText = isBaseball ? '联盟球队' : '成员单位';
      errorMessage(`加载${clubText}认证列表失败`);

      throw error;
    }
  }
);

export const updateAssociationPlayerListPageNumber = createAsyncThunk(
  'clubAuthentication/updateAssociationPlayerListPageNumber',
  async (pageNumber: number, { dispatch, getState }) => {
    try {
      const state: any = getState();
      const pageSize = getAssociationPlayerListPageSize(state);

      await dispatch(
        loadAssociationPlayerList({
          offset: pageNumber * pageSize,
        })
      );

      return pageNumber;
    } catch (error) {
      throw error;
    }
  }
);

export const updateAssociationPlayerListPageSize = createAsyncThunk(
  'clubAuthentication/updateAssociationPlayerListPageSize',
  async (pageSize: number, { dispatch }) => {
    try {
      await dispatch(
        loadAssociationPlayerList({
          offset: 0,
          limit: pageSize,
        })
      );

      return {
        pageNumber: 0,
        pageSize,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const updateAssociationPlayerListSearchKeyword = createAsyncThunk(
  'clubAuthentication/updateAssociationPlayerListSearchKeyword',
  async (keyword: string, { dispatch }) => {
    try {
      await dispatch(loadAssociationPlayerList({ text: keyword, offset: 0 }));

      return {
        pageNumber: 0,
        keyword,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const selectAssociationPlayerListSearchAssociationClubId =
  createAsyncThunk(
    'clubAuthentication/selectAssociationPlayerListSearchAssociationClubId',
    async (associationClubId: string, { dispatch }) => {
      try {
        await dispatch(
          loadAssociationPlayerList({ associationClubId, offset: 0 })
        );

        return { pageNumber: 0, associationClubId };
      } catch (error) {
        throw error;
      }
    }
  );

export const selectAssociationPlayerListSearchGender = createAsyncThunk(
  'clubAuthentication/selectAssociationPlayerListSearchGender',
  async (gender: string, { dispatch }) => {
    try {
      await dispatch(loadAssociationPlayerList({ gender }));

      return {
        pageNumber: 0,
        gender,
      };
    } catch (error) {
      throw error;
    }
  }
);
