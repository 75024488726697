import { post } from '../../../../../service/api';

export const postResetPassword = async (
  payload: {
    merchantName: string;
    accountName: string;
    newPassword: string;
    otp: string;
  },
  options?: { isShow: false }
) => {
  try {
    const { merchantName, accountName, ...restPayload } = payload;
    const result = await post(
      `/merchants/${merchantName}/accounts/${accountName}/password`,
      restPayload,
      options
    );

    return result;
  } catch (e) {
    throw new Error('reset password failed');
  }
};
