import { createSelector } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../../service/constants';
import { ForgetPasswordStateTypes } from './types';

/**
 * get is menu items
 * @return {Array}
 */
export const getModifyStatus = (state: {
  forgetPassword: { modifyStatus: boolean };
}) => state.forgetPassword.modifyStatus;

/**
 * get merchant name
 * @return {string}
 */
export const getMerchantName = (state: {
  forgetPassword: ForgetPasswordStateTypes;
}) => state.forgetPassword.merchantName;

/**
 * get account name
 * @return {string}
 */
export const getAccountName = (state: {
  forgetPassword: ForgetPasswordStateTypes;
}) => state.forgetPassword.accountName;

/**
 * get password
 * @return {string}
 */
export const getPassword = (state: {
  forgetPassword: ForgetPasswordStateTypes;
}) => state.forgetPassword.password;

/**
 * get otp
 * @return {string}
 */
export const getOtp = (state: { forgetPassword: ForgetPasswordStateTypes }) =>
  state.forgetPassword.otp;

/**
 * get reset password info
 * @return {merchantName: string, accountName: string, password: string}
 */
export const getResetPasswordRequestInfo = createSelector(
  getMerchantName,
  getAccountName,
  getPassword,
  getOtp,
  (merchantName, accountName, password, otp) => ({
    merchantName,
    accountName,
    newPassword: password,
    otp,
  })
);

export const getIsForgetPasswordStatusPending = (state: {
  forgetPassword: ForgetPasswordStateTypes;
}) => state.forgetPassword.updatedPasswordStatus === API_REQUEST_STATUS.PENDING;

export const getIsForgetPasswordStatusSuccess = (state: {
  forgetPassword: ForgetPasswordStateTypes;
}) =>
  state.forgetPassword.updatedPasswordStatus === API_REQUEST_STATUS.FULFILLED;

export const getIsCreatedOtpStatusPending = (state: {
  forgetPassword: ForgetPasswordStateTypes;
}) => state.forgetPassword.createOtpStatus === API_REQUEST_STATUS.PENDING;
