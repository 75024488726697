import _isNil from 'lodash/isNil';
import { createSlice } from '@reduxjs/toolkit';
import { ClubDetailState } from './types';
import { API_REQUEST_STATUS } from '../../../../../../../service/constants';
import { clubDetailByIdLoaded, updateClubDetail } from './thunks';

const initialState: {
  data: ClubDetailState;
  memberIdDisabled: boolean;
  isMemberIdRequired: boolean;
  loadClubDetailStatus: string;
  updateClubDetailStatus: string;
} = {
  data: {
    id: '',
    name: '',
    memberId: '',
    contactPhoneNumber: '',
    applyingDate: '',
    confirmed: false,
    companyName: null,
    companyPhoneNumber: '',
    companyEmail: '',
    contactName: '',
    contactEmail: '',
    address: '',
    merchantDisplayName: '',
    clubMerchantName: '',
    remark: '',
    logo: '',
  },
  memberIdDisabled: false,
  isMemberIdRequired: true,
  loadClubDetailStatus: API_REQUEST_STATUS.IDLE,
  updateClubDetailStatus: API_REQUEST_STATUS.IDLE,
};

export const { reducer, actions } = createSlice({
  name: 'association/clubs/detail',
  initialState,
  reducers: {
    clubLogoUpdated: (state, { payload }) => {
      state.data.logo = payload;
    },
    clubNameUpdated: (state, { payload }) => {
      state.data.name = payload;
    },
    clubAddressUpdated: (state, { payload }) => {
      state.data.address = payload;
    },
    clubMemberIdUpdated: (state, { payload }) => {
      state.data.memberId = payload;
    },
    clubCompanyNameUpdated: (state, { payload }) => {
      state.data.companyName = payload;
    },
    clubCompanyPhoneNumberUpdated: (state, { payload }) => {
      state.data.companyPhoneNumber = payload;
    },
    clubCompanyEmailUpdated: (state, { payload }) => {
      state.data.companyEmail = payload;
    },
    clubContactNameUpdated: (state, { payload }) => {
      state.data.contactName = payload;
    },
    clubContactPhoneNumberUpdated: (state, { payload }) => {
      state.data.contactPhoneNumber = payload;
    },
    clubContactEmailUpdated: (state, { payload }) => {
      state.data.contactEmail = payload;
    },
    clubMerchantDisplayNameUpdated: (state, { payload }) => {
      state.data.merchantDisplayName = payload;
    },
    clubMerchantNameUpdated: (state, { payload }) => {
      state.data.clubMerchantName = payload;
    },
    clubRemarkUpdated: (state, { payload }) => {
      state.data.remark = payload;
    },
    clubDetailReset: (state) => {
      state.data = {
        id: '',
        name: '',
        memberId: '',
        contactPhoneNumber: '',
        applyingDate: '',
        confirmed: false,
        companyName: null,
        companyPhoneNumber: '',
        companyEmail: '',
        contactName: '',
        contactEmail: '',
        address: '',
        merchantDisplayName: '',
        clubMerchantName: '',
        remark: '',
        logo: '',
      };
      state.memberIdDisabled = false;
      state.loadClubDetailStatus = API_REQUEST_STATUS.IDLE;
      state.updateClubDetailStatus = API_REQUEST_STATUS.IDLE;
    },
  },
  extraReducers: {
    [clubDetailByIdLoaded.pending.type]: (state) => {
      state.loadClubDetailStatus = API_REQUEST_STATUS.PENDING;
    },
    [clubDetailByIdLoaded.fulfilled.type]: (state, action) => {
      const { memberId } = action.payload || {};

      state.loadClubDetailStatus = API_REQUEST_STATUS.FULFILLED;
      state.data = action.payload;
      state.memberIdDisabled = memberId !== '' && !_isNil(memberId);
      state.isMemberIdRequired = memberId !== '' && !_isNil(memberId);
    },
    [clubDetailByIdLoaded.rejected.type]: (state) => {
      state.loadClubDetailStatus = API_REQUEST_STATUS.REJECTED;
    },
    [updateClubDetail.pending.type]: (state) => {
      state.updateClubDetailStatus = API_REQUEST_STATUS.PENDING;
    },
    [updateClubDetail.fulfilled.type]: (state) => {
      state.updateClubDetailStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [updateClubDetail.rejected.type]: (state) => {
      state.updateClubDetailStatus = API_REQUEST_STATUS.REJECTED;
    },
  },
});
