import { createSlice } from '@reduxjs/toolkit';
import { loadRankSettingsList } from './thunks';

const initialState = {
  tableDataSource: [],
  pageSize: 10,
  pageNumber: 0,
  total: 0,
  end: true,
};

export const { reducer, actions } = createSlice({
  name: 'rankingManagement',
  initialState,
  reducers: {
    updatePageNumber: (state: any, action: any) => {
      state.pageNumber = action.payload;
    },
    updatePageSize: (state: any, action: any) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: {
    [loadRankSettingsList.fulfilled.type]: (state, { payload }) => {
      const { rankingSettings, total } = payload || {};
      state.tableDataSource = rankingSettings;
      state.total = total || 0;
    },
  },
});

export default reducer;
