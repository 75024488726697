import React from 'react';
import { useMount } from 'react-use';
import { Button, Modal, Space, Tag } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  getPosterListPageSize,
  getPosterListPaginateNumber,
  getPosterList,
  getPosterItemsTotal,
  getIsPosterDetailDuplicating,
} from './redux/selectors';
import {
  loadPosterList,
  updatePageSize,
  updatePageNumber,
  removePosterById,
  duplicatePosterDetail,
} from './redux/thunks';
import { SYSTEM_CREATED_POSTER_TYPE } from '../../utils/constants';
import { AppDispatch } from '../../../../redux/store';
import NewPoster from './components/NewPoster';
import ContentDetail from '../../../../common/components/ContentDetail';
import TableResponsiveFrame from '../../../../common/components/tables/TableResponsiveFrame';
import { getIsDisabledContent } from '../Poster/redux/selectors';

const PosterList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const posterList = useSelector(getPosterList);
  const pageNumber = useSelector(getPosterListPaginateNumber);
  const pageSize = useSelector(getPosterListPageSize);
  const itemsTotal = useSelector(getPosterItemsTotal);
  const isPosterDetailDuplicating = useSelector(getIsPosterDetailDuplicating);
  const isDisabledContent = useSelector(getIsDisabledContent);
  const columns: any = [
    {
      title: '修改时间',
      dataIndex: 'displayModifiedTime',
      key: 'displayModifiedTime',
      align: 'center',
      mobileAlign: 'headerLeft',
    },
    {
      title: '标题',
      dataIndex: 'posterName',
      key: 'posterName',
      align: 'center',
      mobileAlign: 'contentHeaderLeft',
    },
    {
      title: '类型',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
    },
    {
      title: '状态',
      dataIndex: 'isEnabled',
      key: 'isEnabled',
      align: 'center',
      mobileAlign: 'contentHeaderRight',
      render: (isEnabled: any) => (
        <Tag color="success">{isEnabled ? '开启' : '关闭'}</Tag>
      ),
    },
    {
      title: '操作',
      dataIndex: 'id',
      key: 'operator',
      align: 'center',
      mobileAlign: 'headerRight',
      render: (_: any, { id, type }: any) => {
        if (type === SYSTEM_CREATED_POSTER_TYPE) {
          return null;
        }

        return (
          <Space>
            {isDisabledContent ? null : (
              <Button
                danger
                size="small"
                onClick={(e) => {
                  e.stopPropagation();

                  Modal.confirm({
                    centered: true,
                    title: '确定删除该海报？',
                    icon: <ExclamationCircleOutlined />,
                    okText: '确认',
                    cancelText: '取消',
                    onOk: () => {
                      dispatch(removePosterById(id));
                    },
                  });
                }}
              >
                删除
              </Button>
            )}
            <Button
              ghost
              type="primary"
              size="small"
              loading={isPosterDetailDuplicating}
              onClick={async (event) => {
                event.stopPropagation();
                Modal.confirm({
                  centered: true,
                  title: '确认创建海报副本？',
                  content: '创建副本后，将会跳转到副本详情页',
                  okText: '确认',
                  cancelText: '取消',
                  onOk: async () => {
                    await dispatch(
                      duplicatePosterDetail({
                        id,
                        redirectToPosterDetailPage: (redirectId: string) => {
                          navigate(`/posters/${redirectId}`);
                        },
                      })
                    );
                  },
                });
              }}
            >
              创建副本
            </Button>
          </Space>
        );
      },
    },
  ];

  useMount(async () => {
    await dispatch(loadPosterList());
  });

  return (
    <ContentDetail title="海报编辑" headerRight={<NewPoster />}>
      <TableResponsiveFrame
        columns={columns}
        pageNumber={pageNumber + 1}
        pageSize={pageSize}
        data={posterList}
        dataItemsTotal={itemsTotal}
        onRowClick={(record: any) => {
          navigate(`/posters/${record.id}`);

          return true;
        }}
        onChangePageNumber={async (pageNumber: number) => {
          await dispatch(updatePageNumber(pageNumber - 1));
        }}
        onChangePageSize={async (_: number, pageSize: number) => {
          await dispatch(updatePageSize(pageSize));
        }}
      />
    </ContentDetail>
  );
};

export default PosterList;
