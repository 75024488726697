import { createSlice } from '@reduxjs/toolkit';
import { LoginStateTypes } from './types';
import { userLogin } from './thunks';
import { API_REQUEST_STATUS } from '../../../../../service/constants';

const initialState: LoginStateTypes = {
  merchantName: '',
  accountName: '',
  password: '',
  loginStatus: API_REQUEST_STATUS.IDLE,
};

export const { reducer, actions } = createSlice({
  name: 'login',
  initialState,
  reducers: {
    merchantNameUpdated: (state, { payload }) => {
      state.merchantName = payload;
    },
    accountNameUpdated: (state, { payload }) => {
      state.accountName = payload;
    },
    passwordUpdated: (state, { payload }) => {
      state.password = payload;
    },
  },
  extraReducers: {
    [userLogin.pending.type]: (state) => {
      state.loginStatus = API_REQUEST_STATUS.PENDING;
    },
    [userLogin.fulfilled.type]: (state) => {
      state.loginStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [userLogin.rejected.type]: (state) => {
      state.loginStatus = API_REQUEST_STATUS.REJECTED;
    },
  },
});

export default reducer;
