import { combineReducers } from '@reduxjs/toolkit';
import { reducer as common } from '../containers/AssociationPlayerList/redux/common';
import { reducer as add } from '../containers/AssociationPlayerList/redux/add';
import { reducer as detail } from '../containers/AssociationPlayerList/redux/detail';
import { reducer as list } from '../containers/AssociationPlayerList/redux/list';
import { reducer as playersImport } from '../containers/AssociationPlayersImport/redux';

const players = combineReducers({
  common,
  add,
  detail,
  list,
  playersImport,
});

export default players;
