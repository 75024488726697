import { createSelector } from '@reduxjs/toolkit';
import _get from 'lodash/get';
import { TRANSLATION_NAME_MAPPING } from '../../../utils/constants';
import { ENROLLMENT_RESULT_CATEGORIES_MAP } from '../utils/constants';
import { API_REQUEST_STATUS } from '../../../../../../../service/constants';
import { getURLSearchParam } from '../../../../../../../common/utils';

export const getEnrollmentResultSelectedCategory = (state: any) =>
  getURLSearchParam('category') || state.enrollment.result.selectedCategory;

export const getEnrollmentResultIsPaid = (state: any) =>
  state.enrollment.result.isPaid;

export const getEnrollmentPlayersPageNumber = (state: any) =>
  _get(state.enrollment.result, 'playersData.pageNumber', 0);

export const getEnrollmentPlayersPageSize = (state: any) =>
  _get(state.enrollment.result, 'playersData.pageSize', 0);

export const getEnrollmentPlayersResultTableTotal = (state: any) =>
  _get(state.enrollment.result, 'playersData.total', 0);

export const getEnrollmentPlayersResultTableItems = (state: any) =>
  _get(state.enrollment.result, 'playersData.items', []);

export const getEnrollmentPlayersResultTableData = createSelector(
  getEnrollmentPlayersResultTableItems,
  (enrollmentPlayersResultTableItems) => {
    const newEnrollmentPlayersResultTableItems: Array<any> = [];

    enrollmentPlayersResultTableItems?.map(
      (rowDataSource: {
        clubName: string | undefined;
        formPlayers: Array<any>;
        category: {
          name: string;
          optionName: string;
        };
        enrollmentId: string;
        id: string;
        formId: string;
        logoImageUrl: string;
        leaderName: string;
        leaderPhone: string;
      }) => {
        const {
          clubName,
          formPlayers,
          category,
          enrollmentId,
          id,
          formId,
          logoImageUrl,
          leaderName,
          leaderPhone,
        } = rowDataSource;
        newEnrollmentPlayersResultTableItems.push({
          clubName,
          logoImageUrl,
          leaderName,
          leaderPhone,
          associationPlayerId: formPlayers[0].associationPlayerId,
          name: formPlayers[0].name,
          idCardNum: formPlayers[0].idCardNum,
          idCardType: TRANSLATION_NAME_MAPPING[formPlayers[0].idCardType],
          gender: TRANSLATION_NAME_MAPPING[formPlayers[0].gender],
          phoneNumber: formPlayers[0].phoneNumber,
          paidByOrderId: formPlayers[0].paidByOrderId,
          price: formPlayers[0].price,
          categoryName: category.name,
          enrollmentId,
          formId,
          id,
        });
      }
    );

    return newEnrollmentPlayersResultTableItems;
  }
);

export const getEnrollmentTeamsPageNumber = (state: any) =>
  _get(state.enrollment.result, 'teamsData.pageNumber', 0);

export const getEnrollmentTeamsPageSize = (state: any) =>
  _get(state.enrollment.result, 'teamsData.pageSize', 0);

export const getEnrollmentTeamsResultTableTotal = (state: any) =>
  _get(state.enrollment.result, 'teamsData.total', 0);

export const getEnrollmentTeamsResultTableItems = (state: any) =>
  _get(state.enrollment.result, 'teamsData.items', []);

export const getEnrollmentTeamsResultTableData = createSelector(
  getEnrollmentTeamsResultTableItems,
  (enrollmentTeamsResultTableItems) => {
    const newEnrollmentTeamsResultTableItems: Array<any> = [];

    enrollmentTeamsResultTableItems?.map(
      (rowDataSource: {
        clubName: string | undefined;
        formPlayers: Array<any>;
        category: {
          name: string;
          optionName: string;
        };
        enrollmentId: string;
        formId: string;
        id: string;
        logoImageUrl: string;
        leaderName: string;
        leaderPhone: string;
      }) => {
        const {
          clubName,
          formPlayers,
          category,
          enrollmentId,
          formId,
          id,
          logoImageUrl,
          leaderName,
          leaderPhone,
        } = rowDataSource;

        newEnrollmentTeamsResultTableItems.push({
          clubName,
          logoImageUrl,
          leaderName,
          leaderPhone,
          formPlayers: formPlayers,
          categoryName: category.name,
          optionName: category.optionName,
          enrollmentId,
          formId,
          id,
        });
      }
    );

    return newEnrollmentTeamsResultTableItems;
  }
);

export const getDisplayEnrollmentResultPageNumber = createSelector(
  getEnrollmentResultSelectedCategory,
  getEnrollmentPlayersPageNumber,
  getEnrollmentTeamsPageNumber,
  (selectedCategory, playersPageNumber, teamsPageNumber) => {
    if (selectedCategory === ENROLLMENT_RESULT_CATEGORIES_MAP.PLAYER_VALUE) {
      return playersPageNumber;
    } else if (
      selectedCategory === ENROLLMENT_RESULT_CATEGORIES_MAP.TEAM_VALUE
    ) {
      return teamsPageNumber;
    }
    return 0;
  }
);

export const getDisplayEnrollmentResultPageSize = createSelector(
  getEnrollmentResultSelectedCategory,
  getEnrollmentPlayersPageSize,
  getEnrollmentTeamsPageSize,
  (selectedCategory, playersPageSize, teamsPageSize) => {
    if (selectedCategory === ENROLLMENT_RESULT_CATEGORIES_MAP.PLAYER_VALUE) {
      return playersPageSize;
    } else if (
      selectedCategory === ENROLLMENT_RESULT_CATEGORIES_MAP.TEAM_VALUE
    ) {
      return teamsPageSize;
    }
    return 10;
  }
);

export const getDisplayEnrollmentResultTableTotal = createSelector(
  getEnrollmentResultSelectedCategory,
  getEnrollmentPlayersResultTableTotal,
  getEnrollmentTeamsResultTableTotal,
  (selectedCategory, playersTableTotal, teamsTableTotal) => {
    if (selectedCategory === ENROLLMENT_RESULT_CATEGORIES_MAP.PLAYER_VALUE) {
      return playersTableTotal;
    } else if (
      selectedCategory === ENROLLMENT_RESULT_CATEGORIES_MAP.TEAM_VALUE
    ) {
      return teamsTableTotal;
    }
    return 0;
  }
);

export const getDisplayEnrollmentResultTableData = createSelector(
  getEnrollmentResultSelectedCategory,
  getEnrollmentPlayersResultTableData,
  getEnrollmentTeamsResultTableData,
  (selectedCategory, playersTableData, teamsTableData) => {
    if (selectedCategory === ENROLLMENT_RESULT_CATEGORIES_MAP.PLAYER_VALUE) {
      return playersTableData;
    } else if (
      selectedCategory === ENROLLMENT_RESULT_CATEGORIES_MAP.TEAM_VALUE
    ) {
      return teamsTableData;
    }
    return [];
  }
);

export const getEnrollmentResultSummaryCategories = (state: any) =>
  _get(state.enrollment, 'result.categories', []);

export const getEnrollmentResultSearchInputText = (state: any) =>
  state.enrollment.result.searchInputText;

export const getEnrollmentResultSummaryTotal = (state: any) =>
  state.enrollment.result.summaryTotal;

export const getVerifyAccount = (state: any) =>
  state.enrollment.result.verifyAccount;

export const getVerifyPassword = (state: any) =>
  state.enrollment.result.verifyPassword;

export const getVerifyStatus = (state: any) =>
  state.enrollment.result.verifyStatus;

export const getVerificationModalData = (state: any) =>
  state.enrollment.result.verificationModalData;

export const getSelectedPaidByOrderId = (state: any) =>
  state.enrollment.result.selectedPaidByOrderId;

export const getIsMultiplePlayer = createSelector(
  getEnrollmentResultSelectedCategory,
  (selectedCategory) =>
    selectedCategory === ENROLLMENT_RESULT_CATEGORIES_MAP.TEAM_VALUE
);

export const getEnrollmentTitle = (state: any) =>
  state.enrollment.result.enrollmentTitle || '';

export const getEnrollmentNotTeamCSVStatus = (state: any) =>
  state.enrollment.result.notTeamCSV.status;

export const getEnrollmentCSVStatus = (state: any) =>
  state.enrollment.result.CSV.status;

export const getEnrollmentCSVData = (state: any) =>
  state.enrollment.result.CSV.data;

export const getIsEnrollmentNotTeamCSVLoading = createSelector(
  getEnrollmentNotTeamCSVStatus,
  (enrollmentNotTeamCSVStatus) =>
    enrollmentNotTeamCSVStatus === API_REQUEST_STATUS.PENDING
);

export const getIsEnrollmentCSVLoading = createSelector(
  getEnrollmentCSVStatus,
  (enrollmentCSVStatus) => enrollmentCSVStatus === API_REQUEST_STATUS.PENDING
);

export const getEnrollmentCSVLoadingTips = (state: any) =>
  state.enrollment.result.CSV.tips;

export const getEnrollmentCSVLoadingProgress = (state: any) =>
  state.enrollment.result.CSV.progress;
