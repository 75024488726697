import { RootState } from '../../../../../../common/utils/types';

export const getOrderState = (state: RootState) => {
  return {
    merchantName: state.marketOrderDetails.merchantName,
    playerId: state.marketOrderDetails.playerId,
    orderNumber: state.marketOrderDetails.orderNumber,
    status: state.marketOrderDetails.status,
    createTime: state.marketOrderDetails.createdTime,
    items: state.marketOrderDetails.items,
    total: state.marketOrderDetails.total,
  };
};
