import _get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { getFormatLocalTime } from '../../../../../../../../common/utils/time-lib';
import { API_REQUEST_STATUS } from '../../../../../../../../service/constants';
import {
  getAssociationClubsMapping,
  getAssociationPlayersMapping,
} from '../common/selectors';

export const getAssociationPlayerListStateData = (state: {
  association: { players: { list: any } };
}) => state.association.players.list;

/* Star Filters Club */
export const getSearchClubsPageNumber = createSelector(
  getAssociationPlayerListStateData,
  (associationPlayerListStateData) =>
    _get(associationPlayerListStateData, 'clubs.pageNumber', [])
);

export const getSearchClubsPageSize = createSelector(
  getAssociationPlayerListStateData,
  (associationPlayerListStateData) =>
    _get(associationPlayerListStateData, 'clubs.pageSize', [])
);

export const getSearchClubsPagination = createSelector(
  getSearchClubsPageNumber,
  getSearchClubsPageSize,
  (pageNumber, pageSize) => {
    return { limit: pageSize, offset: pageNumber * pageSize };
  }
);

export const getSearchClubsIsRegisteredMerchant = createSelector(
  getAssociationPlayerListStateData,
  (associationPlayerListStateData) =>
    _get(
      associationPlayerListStateData,
      'clubs.isRegisteredMerchant',
      undefined
    )
);

export const getSearchClubsParameters = createSelector(
  getSearchClubsPagination,
  getSearchClubsIsRegisteredMerchant,
  (pagination, isRegisteredMerchant) => {
    return { ...pagination, isRegisteredMerchant };
  }
);

export const getSearchClubIds = createSelector(
  getAssociationPlayerListStateData,
  (associationPlayerListStateData) =>
    _get(associationPlayerListStateData, 'clubs.ids', [])
);

export const getSearchClubsFilterOptions = createSelector(
  getAssociationClubsMapping,
  getSearchClubIds,
  (associationClubsMapping, searchClubIds) =>
    searchClubIds.map((id: string) => {
      const { name, memberId } = associationClubsMapping[id] || {};

      return {
        value: id,
        label: `${name} ${memberId ? `（${memberId}）` : ''}`,
      };
    })
);

export const getLoadClubsStatus = createSelector(
  getAssociationPlayerListStateData,
  (associationPlayerListStateData) =>
    _get(associationPlayerListStateData, 'clubs.loadClubsStatus', [])
);

export const getIsSearchClubsFilterLoading = createSelector(
  getLoadClubsStatus,
  (loadClubsStatus) => loadClubsStatus === API_REQUEST_STATUS.PENDING
);
/* End Filters Club */

export const getAssociationPlayerListPageNumber = createSelector(
  getAssociationPlayerListStateData,
  (associationPlayerListStateData) =>
    _get(associationPlayerListStateData, 'data.pageNumber', [])
);

export const getAssociationPlayerListPageSize = createSelector(
  getAssociationPlayerListStateData,
  (associationPlayerListStateData) =>
    _get(associationPlayerListStateData, 'data.pageSize', [])
);

export const getAssociationPlayerListPagination = createSelector(
  getAssociationPlayerListPageNumber,
  getAssociationPlayerListPageSize,
  (pageNumber, pageSize) => {
    return { limit: pageSize, offset: pageNumber * pageSize };
  }
);

export const getAssociationPlayerListTotal = createSelector(
  getAssociationPlayerListStateData,
  (associationPlayerListStateData) =>
    _get(associationPlayerListStateData, 'data.totalAssociationPlayers', [])
);

export const getAssociationPlayerIds = createSelector(
  getAssociationPlayerListStateData,
  (associationPlayerListStateData) =>
    _get(associationPlayerListStateData, 'data.ids', [])
);

export const getAssociationDisplayPlayers = createSelector(
  getAssociationPlayersMapping,
  getAssociationPlayerIds,
  (associationPlayersMapping, associationPlayerIds) =>
    associationPlayerIds.map((id: string) => ({
      key: id,
      id,
      name: _get(associationPlayersMapping, `${id}.name`, ''),
      gender: _get(associationPlayersMapping, `${id}.gender`, ''),
      phone: _get(associationPlayersMapping, `${id}.phone`, ''),
      idCardNum: _get(associationPlayersMapping, `${id}.idCardNum`, ''),
      registerDate: getFormatLocalTime(
        _get(associationPlayersMapping, `${id}.registerDate`, ''),
        'YYYY/MM/DD'
      ),
      expiredDate: getFormatLocalTime(
        _get(associationPlayersMapping, `${id}.expiredDate`, ''),
        'YYYY/MM/DD'
      ),
      associationClubName: _get(
        associationPlayersMapping,
        `${id}.associationClubName`,
        ''
      ),
      associationClubMemberId: _get(
        associationPlayersMapping,
        `${id}.associationClubMemberId`,
        ''
      ),
      registerNo: _get(associationPlayersMapping, `${id}.registerNo`, ''),
    }))
);

export const getSearchAssociationPlayersKeyword = createSelector(
  getAssociationPlayerListStateData,
  (associationPlayerListStateData) =>
    _get(associationPlayerListStateData, 'searchKeyword', '')
);

export const getSelectedAssociationPlayersAssociationClubId = createSelector(
  getAssociationPlayerListStateData,
  (associationPlayerListStateData) =>
    _get(associationPlayerListStateData, 'selectedAssociationClubId', '')
);

export const getSelectedAssociationPlayersGender = createSelector(
  getAssociationPlayerListStateData,
  (associationPlayerListStateData) =>
    _get(associationPlayerListStateData, 'selectedGender', '')
);

export const getAssociationPlayerListParameters = createSelector(
  getAssociationPlayerListPagination,
  getSearchAssociationPlayersKeyword,
  getSelectedAssociationPlayersAssociationClubId,
  getSelectedAssociationPlayersGender,
  (
    associationPlayerListPagination,
    searchAssociationPlayersKeyword,
    selectedAssociationPlayersAssociationClubId,
    selectedAssociationPlayersGender
  ) => ({
    ...associationPlayerListPagination,
    text: searchAssociationPlayersKeyword,
    associationClubId: selectedAssociationPlayersAssociationClubId,
    gender: selectedAssociationPlayersGender,
  })
);
