import { get } from '../../../../../../../service/api';

export const getEmployVerification = async (merchantName: string) => {
  try {
    const result = await get(
      `/merchants/${merchantName}/employ-verification`,
      {},
      {},
      { isShow: false }
    );

    return result;
  } catch (error) {
    throw error;
  }
};

export const getNewCrewWechatQRCode = async (
  merchantName: string,
  parameters: {
    employVerification: string;
    appType: string;
    storeSeqNum: number | '';
  }
) => {
  try {
    const result = await get(`/merchants/${merchantName}/wxacode`, parameters, {
      responseType: 'blob',
    });

    return result;
  } catch (error) {
    throw error;
  }
};
