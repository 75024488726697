import { getEmployees } from '../../../../../../../redux/entities/employees/selectors';

export { getEmployees as getTheEmployeeList };

export const getTheDataSource = (state: {
  clubs: { clubsImport: { dataSource: [any] } };
}) => state.clubs.clubsImport.dataSource;

export const getTheTotal = (state: {
  clubs: { clubsImport: { total: number } };
}) => state.clubs.clubsImport.total;

export const getThePaginateNum = (state: {
  clubs: { clubsImport: { paginateNum: number } };
}) => state.clubs.clubsImport.paginateNum;

export const getThePageSize = (state: {
  clubs: { clubsImport: { pageSize: number } };
}) => state.clubs.clubsImport.pageSize;
export const getHeader = (state: {
  clubs: { clubsImport: { header: [any] } };
}) => state.clubs.clubsImport.header;
