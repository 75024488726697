import { createAsyncThunk } from '@reduxjs/toolkit';
import { errorMessage } from '../../../../../common/utils/feedback';
import { RootState } from '../../../../../common/utils/types';
import { loadPlayersV2 as loadEntitiesPlayers } from '../../../../../redux/entities/players/thunks';
import { getPlayerListPageSize, getPlayerListParameters } from './selectors';
import { getMerchantName } from '../../../../../redux/app-v2/merchant/selectors';
import { getAllPlayersExport } from './api-request';

export const loadPlayerList = createAsyncThunk(
  'players/loadPlayerList',
  async (
    changedParameters: {
      limit?: number;
      offset?: number;
      search?: string;
      storeId?: string;
      memberCardPaid?: boolean;
    },
    { dispatch, getState }
  ) => {
    try {
      const state: any = getState();
      const playerListParameters = getPlayerListParameters(state);

      const { payload } = await dispatch(
        loadEntitiesPlayers({
          ...playerListParameters,
          ...changedParameters,
        })
      );

      return payload;
    } catch (error) {
      throw error;
    }
  }
);

export const updatePlayerListPageNumber = createAsyncThunk(
  'players/updatePlayerListPageNumber',
  async (pageNumber: number, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const pageSize = getPlayerListPageSize(state);

      await dispatch(
        loadPlayerList({
          offset: pageNumber * pageSize,
        })
      );

      return pageNumber;
    } catch (error) {
      throw error;
    }
  }
);

export const updatePlayerListPageSize = createAsyncThunk(
  'players/updatePlayerListPageSize',
  async (pageSize: number, { dispatch }) => {
    try {
      await dispatch(
        loadPlayerList({
          offset: 0,
          limit: pageSize,
        })
      );

      return {
        pageNumber: 0,
        pageSize,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const updatePlayerListFilters = createAsyncThunk(
  'players/updatePlayerListFilters',
  async (
    filters: {
      search?: string;
      memberCardPaid?: boolean;
    },
    { dispatch }
  ) => {
    try {
      await dispatch(
        loadPlayerList({
          offset: 0,
          ...filters,
        })
      );

      return {
        pageNumber: 0,
        filters,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const exportAllPlayers = createAsyncThunk(
  'players/export',
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);
      const result = await getAllPlayersExport(merchantName);

      return result;
    } catch (error) {
      errorMessage('导出所有会员列表失败');

      throw error;
    }
  }
);
