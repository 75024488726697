import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../common/utils/types';
import { MERCHANT_TYPE } from '../../../../../common/constants';
import { SYSTEM_CREATED_POSTER_TYPE } from '../../../utils/constants';
import {
  getIsOperationsManager,
  getUserAuthorization,
  getUserId,
} from '../../../../../redux/app-v2/user/selectors';
import {
  getMerchantName,
  getMerchantType,
} from '../../../../../redux/app-v2/merchant/selectors';

export { getUserAuthorization, getMerchantName };

export const getContent = (state: RootState) => state.poster.details.content;

export const getImageUrl = (state: RootState) => state.poster.details.imageUrl;

export const getIsEnabled = (state: RootState) =>
  state.poster.details.isEnabled;

export const getPosterName = (state: RootState) =>
  state.poster.details.posterName;

export const getStoreId = (state: RootState) => state.poster.details.storeId;

export const getType = (state: RootState) => state.poster.details.type;

export const getCreatorId = (state: RootState) =>
  state.poster.details.creatorId;

export const getPosterRequestData = (state: RootState) => state.poster.details;

export const getIsDisabledContent = createSelector(
  getMerchantType,
  getIsOperationsManager,
  getCreatorId,
  getUserId,
  (merchantType, isOperationsManager, creatorId, userId) => {
    if (
      (!creatorId || creatorId !== userId) &&
      merchantType === MERCHANT_TYPE.association &&
      isOperationsManager
    ) {
      return true;
    }

    return false;
  }
);
