import React from 'react';
import { Typography } from 'antd';
import { ContentPropsType } from './types';
import './ContentDetail.less';

const ContentDetail = ({
  className,
  title,
  headerLeft,
  headerRight,
  headerRightClassName,
  children,
}: ContentPropsType) => (
  <div className={`flex__fluid-content${className ? ` ${className}` : ''}`}>
    <section className="flex flex-middle flex-space-between">
      {headerLeft || (
        <Typography.Title level={2} className="ContentDetailTitle">
          {title}
        </Typography.Title>
      )}

      {headerRight ? (
        <div className={headerRightClassName}>{headerRight}</div>
      ) : null}
    </section>
    {children}
  </div>
);

ContentDetail.displayName = 'ContentDetail';

export default ContentDetail;
