import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input, InputNumber, Modal, Select, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { AppDispatch } from '../../../../../../../../common/utils/types';
import { priceConvertToIntegerCents } from '../../../../../../../../common/utils/currency';
import { actions as enrollmentDetailActions } from '../../redux';
import {
  getNestItemSettingOptions,
  getNewClubNestItemSettingClubId,
  getShowNestItemSettingNewModal,
} from '../../redux/selectors';

const NestItemOptionNewModal = (props: { onAdd: (option: any) => void }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const { onAdd } = props;
  const [propertyName, setPropertyName] = useState('');
  const [description, setDescription] = useState('');
  const [propertyType, setPropertyType] = useState('number');
  const [maxValue, setMaxValue] = useState(1);
  const [enableChargePrice, setEnableChargePrice] = useState(false);
  const [price, setPrice] = useState();
  const nestItemSettingOptions = useSelector(getNestItemSettingOptions);
  const newGClubNestItemSettingClubId = useSelector(
    getNewClubNestItemSettingClubId
  );
  const showClubNestItemSettingNewModal = useSelector(
    getShowNestItemSettingNewModal
  );
  const handleCloseModal = async () => {
    await resetData();
    dispatch(enrollmentDetailActions.hideClubNestItemSettingNewModal());
  };
  const handleAddNestItemOption = async () => {
    try {
      await form.validateFields();

      onAdd({
        currentClubInfoId: newGClubNestItemSettingClubId,
        option: {
          propertyName,
          description,
          propertyType,
          maxValue,
          enableChargePrice,
          pricingInfo: {
            price: priceConvertToIntegerCents(price || 0),
          },
        },
      });
      await resetData();
      dispatch(enrollmentDetailActions.hideClubNestItemSettingNewModal());
    } catch (error) {
      throw error;
    }
  };
  const resetData = async () => {
    await form.resetFields();
    setPropertyName('');
    setDescription('');
    setPropertyType('number');
    setMaxValue(1);
    setEnableChargePrice(false);
    setPrice(undefined);
  };

  return (
    <Modal
      centered
      className="NestItemOptionNewModal"
      maskClosable={false}
      visible={showClubNestItemSettingNewModal}
      footer={[
        <Button
          key="EnrollmentNewOptionModalDeleteSubmit"
          type="primary"
          loading={false}
          onClick={handleAddNestItemOption}
        >
          保存
        </Button>,
        <Button
          ghost
          key="EnrollmentNewOptionModalDeleteCancel"
          type="primary"
          onClick={handleCloseModal}
        >
          取消
        </Button>,
      ]}
      onOk={handleAddNestItemOption}
      onCancel={handleCloseModal}
      afterClose={resetData}
    >
      <Form form={form}>
        <Form.Item
          label="项目名称"
          name="propertyName"
          initialValue={propertyName}
          rules={[
            {
              required: true,
              message: '项目名称不能为空',
            },
            {
              validator(_, propertyNameValue) {
                const duplicatedNameOptions = nestItemSettingOptions.filter(
                  (item: any) => item.propertyName === propertyNameValue
                );

                if (duplicatedNameOptions?.length > 0) {
                  return Promise.reject(new Error('项目名称不能重复'));
                }

                if (propertyNameValue && propertyNameValue.includes('.')) {
                  return Promise.reject(new Error('项目名称不能包含点'));
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            value={propertyName}
            placeholder="项目名称（例: 大床房5月12日）"
            onChange={(e) => {
              setPropertyName(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item
          label="项目类型"
          name="propertyType"
          initialValue={propertyType}
          rules={[{ required: true, message: '选择项目类别' }]}
        >
          <Select
            className="NestItemOptionNewModalPropertyType"
            dropdownMatchSelectWidth={100}
            placeholder="选择项目类别"
            value={propertyType || 'number'}
            options={[{ value: 'number', label: '数字' }]}
            onChange={(value) => {
              setPropertyType(value);
            }}
          />
        </Form.Item>
        <Form.Item
          label="数量上限"
          name="maxValue"
          initialValue={maxValue}
          rules={[
            {
              required: true,
              message: '数量上限不能为空',
            },
            { type: 'integer', min: 1, message: '数量上限为大于0的整数' },
          ]}
        >
          <InputNumber
            placeholder="（例: 10）"
            controls={false}
            value={maxValue}
            onChange={(value) => {
              setMaxValue(value);
            }}
          />
        </Form.Item>
        {propertyType === 'number' && (
          <Form.Item
            className="NestItemOptionNewModalFormItem"
            label="设置价格"
            initialValue={enableChargePrice}
          >
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={enableChargePrice}
              onChange={(checked) => {
                setEnableChargePrice(checked);
              }}
            />
          </Form.Item>
        )}

        {enableChargePrice ? (
          <Form.Item
            className="NestItemOptionNewModalFormPrice NestItemOptionNewModalFormItem"
            label="价格（元）"
            initialValue={price}
            name="price"
            rules={[
              {
                required: true,
                message: '价格不能为空',
              },
              () => ({
                validator(_, value) {
                  if (typeof value === 'number' && value <= 0) {
                    return Promise.reject(new Error('价格不能为0.00'));
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <InputNumber
              addonBefore="￥"
              placeholder="356.50"
              controls={false}
              min={0}
              precision={2}
              value={price}
              onChange={(value: any) => {
                setPrice(value);
              }}
            />
          </Form.Item>
        ) : null}

        <Form.Item
          className="NestItemOptionNewModalFormItem"
          label="项目描述"
          name="description"
          initialValue={description}
        >
          <Input
            value={description}
            placeholder="项目描述（例: 300元/晚 含早）"
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NestItemOptionNewModal;
