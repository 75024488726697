import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { Button, Checkbox, Form, Input, Modal, Radio, Select, Tag } from 'antd';
import { InboxOutlined, RightOutlined, PlusOutlined } from '@ant-design/icons';
import { actions as tournamentListActions } from '../../redux';
import {
  getEnrollmentDataList,
  getEnrollmentSelectList,
  getEmployeeCheckBoxList,
  getIsLoadEnrollmentListPending,
  getCreateGameRequestTargetNums,
  getCreateGameRequestSegments,
  getCreateGameRequestShowCreateModal,
  getEnrollmentPlayersNumberUnclassified,
} from '../../redux/selectors';
import {
  createGame,
  loadEmployeeList,
  loadEnrollmentList,
  loadEnrollmentPlayersUnclassified,
  createGameRequestCreateModalHidden,
  loadEnrollmentPlayersSummaryById,
} from '../../redux/thunks';
import './CreateTournamentModal.less';
import JudgesSelectionModal from '../JudgesSelectionModal';
import { updatePageSize } from '../../../../../../../posters/containers/PosterList/redux/thunks';

let timer: any = null;
const CreateTournamentModal = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [title, setTitle] = useState('');
  const [address, setAddress] = useState('');
  const [enrollmentId, setEnrollmentId] = useState(null);
  const [enrolledCount, setEnrolledCount] = useState(0);
  const [multiPlayerTeamCount, setMultiPlayerTeamCount] = useState(0);
  const enrollmentPlayersNumberUnclassified = useSelector(
    getEnrollmentPlayersNumberUnclassified
  );
  const [scoreRecordingType, setScoreRecordingType] = useState('crossEntry');
  const [eventSchedule, setEventSchedule] = useState<
    'qualifyingAndKnockout' | 'onlyQualifying'
  >('qualifyingAndKnockout');

  const [scoreVerificationMethod, setScoreVerificationMethod] =
    useState('noVerification');
  const [demandIds, setDemandIds] = useState<any[]>([]);
  const enrollmentDataList = useSelector(getEnrollmentDataList);
  const enrollmentSelectList = useSelector(getEnrollmentSelectList);
  const employeeCheckBoxList = useSelector(getEmployeeCheckBoxList);
  const isLoadEnrollmentListPending = useSelector(
    getIsLoadEnrollmentListPending
  );
  const createGameRequestTargetNums = useSelector(
    getCreateGameRequestTargetNums
  );
  const createGameRequestSegments = useSelector(getCreateGameRequestSegments);
  const showCreateModal = useSelector(getCreateGameRequestShowCreateModal);

  const [showJudgeSelectionModal, setShowJudgeSelectionModal] =
    useState<boolean>(false);
  const handleCreateGameButtonClick = async () => {
    try {
      await form.validateFields();

      if (enrollmentId) {
        dispatch(
          createGame({
            title,
            address,
            enrollmentId,
            eventSchedule,
            scoreRecordingType,
            scoreVerificationMethod,
            demandIds,
            targetNums: createGameRequestTargetNums,
            segments: createGameRequestSegments,
          }) as any
        );

        form.resetFields();
        dispatch(tournamentListActions.resetData());
      }
    } catch (error) {
      throw error;
    }
  };
  const handleCancelButtonClick = () => {
    form.resetFields();
    dispatch(tournamentListActions.resetData());
    dispatch(createGameRequestCreateModalHidden() as any);
  };

  useMount(() => {
    dispatch(updatePageSize(10000) as any);
    dispatch(loadEnrollmentList() as any);
  });

  useEffect(() => {
    const enrollmentDataItem = enrollmentDataList.find(
      (item: any) => item.id === enrollmentId
    );

    if (enrollmentId && enrollmentDataItem) {
      const { paidFormPlayerCount, multiPlayerTeamCount } = enrollmentDataItem;
      setEnrolledCount(paidFormPlayerCount);
      setMultiPlayerTeamCount(multiPlayerTeamCount);
    } else if (!enrollmentId && enrollmentDataItem) {
      const { paidFormPlayerCount, multiPlayerTeamCount } = enrollmentDataItem;
      setEnrolledCount(paidFormPlayerCount);
      setMultiPlayerTeamCount(multiPlayerTeamCount);
    }
  }, [enrollmentId, enrollmentDataList]);

  useEffect(() => {
    if (employeeCheckBoxList.length > 0) {
      setDemandIds([employeeCheckBoxList[0].value]);
    }
  }, [employeeCheckBoxList]);

  useEffect(() => {
    if (enrollmentId) {
      dispatch(loadEnrollmentPlayersUnclassified(enrollmentId) as any);
      dispatch(loadEnrollmentPlayersSummaryById(enrollmentId) as any);
    }
  }, [dispatch, enrollmentId]);

  // useEffect(() => {
  //   const enrollmentDataItem = enrollmentDataList.find(
  //     (item: any) => item.id === enrollmentId
  //   );

  //   if (enrollmentId && enrollmentDataItem && !title) {
  //     const { title } = enrollmentDataItem;

  //     setTitle(title);
  //   }
  // }, [enrollmentId, enrollmentDataList, title]);

  useEffect(() => {
    if (!showCreateModal) {
      form.resetFields();
      setTitle('');
      setAddress('');
      setEnrollmentId(null);
      setEnrolledCount(0);
      setMultiPlayerTeamCount(0);
      setScoreRecordingType('crossEntry');
      setScoreVerificationMethod('noVerification');
    }
  }, [form, showCreateModal]);

  return (
    <Modal
      centered
      title={null}
      maskClosable={false}
      visible={showCreateModal}
      onOk={handleCreateGameButtonClick}
      onCancel={handleCancelButtonClick}
      footer={[
        <Button
          type="primary"
          loading={false}
          onClick={handleCreateGameButtonClick}
        >
          保存草稿
        </Button>,
        <Button ghost type="primary" onClick={handleCancelButtonClick}>
          取消
        </Button>,
      ]}
    >
      <Form form={form}>
        <JudgesSelectionModal
          visible={showJudgeSelectionModal}
          demandIds={demandIds}
          onCancel={() => setShowJudgeSelectionModal(false)}
          onFinish={(demandIds) => {
            setDemandIds(demandIds);
            setShowJudgeSelectionModal(false);
            form.setFieldsValue({ demandIds });
          }}
        />
        <Form.Item
          className="CreateTournamentModalEnrollmentItem"
          label="选择报名"
          name="enrollmentId"
          rules={[{ required: true, message: `报名不能为空` }]}
        >
          <Select
            showSearch
            loading={isLoadEnrollmentListPending}
            placeholder="请输入报名名称"
            value={enrollmentId}
            defaultActiveFirstOption={false}
            filterOption={false}
            onSearch={(value) => {
              clearTimeout(timer);

              timer = setTimeout(() => {
                dispatch(loadEnrollmentList(value) as any);
              }, 400);
            }}
            onSelect={(value: any) => {
              setEnrollmentId(value);

              const enrollmentDataItem = enrollmentDataList.find(
                (item: any) => item.id === value
              );

              setTitle(enrollmentDataItem?.title);
            }}
            notFoundContent={
              <div className="flex flex-column flex-middle flex-center">
                <InboxOutlined />
                <span>未搜到此报名</span>
              </div>
            }
            options={enrollmentSelectList}
          />
        </Form.Item>
        {title && enrollmentId && (
          <Form.Item
            className="CreateTournamentModalTitleItem"
            label="赛事名称"
            name={[enrollmentId, 'title']}
            rules={[{ required: true, message: `赛事名称不能为空` }]}
            initialValue={title}
          >
            <Input
              type="text"
              placeholder="请输入赛事名称"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </Form.Item>
        )}
        <Form.Item
          className="CreateTournamentModalAddressItem"
          label="地址"
          name="address"
          rules={[{ required: true, message: `地址不能为空` }]}
          initialValue={address}
        >
          <Input
            type="text"
            placeholder="请输入地址"
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
        </Form.Item>

        {title && enrollmentId ? (
          <>
            <section>
              <h4>基础信息</h4>
              <ul className="CreateTournamentModalBasicInfo">
                <li className="flex flex-middle">
                  <span>参赛人数： </span>
                  <Button
                    className="CreateTournamentModalBasicInfoLink"
                    type="link"
                    onClick={() => {
                      window.open(
                        `${window.location.origin}${
                          window.location.pathname
                        }#${`/association-activities/enrollments/${enrollmentId}/players?category=players`}`,
                        '_blank'
                      );
                    }}
                  >
                    {enrolledCount}
                  </Button>
                </li>
                <li className="flex flex-middle">
                  <span>参赛团队： </span>
                  <Button
                    className="CreateTournamentModalBasicInfoLink"
                    type="link"
                    onClick={() => {
                      window.open(
                        `${window.location.origin}${
                          window.location.pathname
                        }#${`/association-activities/enrollments/${enrollmentId}/players?category=team`}`,
                        '_blank'
                      );
                    }}
                  >
                    {multiPlayerTeamCount}
                  </Button>
                  {enrollmentPlayersNumberUnclassified &&
                  enrollmentPlayersNumberUnclassified > 0 ? (
                    <>
                      <span className="CreateTournamentModalBasicInfoLinkText">
                        ，还有{enrollmentPlayersNumberUnclassified}人未分组！
                      </span>
                      <Button
                        type="primary"
                        onClick={() => {
                          window.open(
                            `${window.location.origin}${
                              window.location.pathname
                            }#${`/association-activities/enrollments/${enrollmentId}/players?category=team`}`,
                            '_blank'
                          );
                        }}
                      >
                        <span>前往分组</span>
                        <RightOutlined />
                      </Button>
                    </>
                  ) : null}
                </li>
              </ul>
              <Form.Item
                label="赛程设置"
                name={'eventSchedule'}
                rules={[{ required: true, message: `赛程设置不能为空` }]}
                initialValue={eventSchedule}
              >
                <Radio.Group
                  value={eventSchedule}
                  onChange={(e) => {
                    setEventSchedule(e.target.value);
                  }}
                >
                  <Radio
                    style={{ userSelect: 'none' }}
                    value="qualifyingAndKnockout"
                  >
                    传统联赛
                  </Radio>
                </Radio.Group>
                <Radio.Group
                  value={eventSchedule}
                  onChange={(e) => {
                    setEventSchedule(e.target.value);
                  }}
                >
                  <Radio style={{ userSelect: 'none' }} value="onlyQualifying">
                    达级赛
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="分数录入方式"
                name="scoreRecordingType"
                rules={[{ required: true, message: `分数录入方式不能为空` }]}
                initialValue={scoreRecordingType}
              >
                <Radio.Group
                  value={scoreRecordingType}
                  onChange={(e) => {
                    setScoreRecordingType(e.target.value);
                  }}
                >
                  <Radio style={{ userSelect: 'none' }} value="crossEntry">
                    交叉录入
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="分数核验方式"
                name="scoreVerificationMethod"
                rules={[{ required: true, message: `分数核验方式不能为空` }]}
                initialValue={scoreVerificationMethod}
              >
                <Radio.Group
                  onChange={(e) => {
                    setScoreVerificationMethod(e.target.value);
                  }}
                  value={scoreVerificationMethod}
                >
                  <Radio style={{ userSelect: 'none' }} value="noVerification">
                    无需验证
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </section>

            <Form.Item
              className="CreateTournamentModalDemandedItem"
              label={`设置裁判员${
                demandIds.length > 0 ? `（${demandIds.length}人）` : ''
              }`}
              name="demandIds"
              rules={[
                () => ({
                  validator(_, value) {
                    if (value && value.length > 0) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error('裁判员不能为空'));
                  },
                }),
              ]}
              initialValue={demandIds}
            >
              <div className="CreateTournamentModalJudges">
                {demandIds.map((d) => (
                  <Tag
                    key={d}
                    closable
                    onClose={() => {
                      setDemandIds(demandIds.filter((item) => item !== d));
                    }}
                  >
                    {employeeCheckBoxList.find((e) => e.value === d)?.label}
                  </Tag>
                ))}
                <Button
                  type="link"
                  size="small"
                  style={{ padding: 0 }}
                  onClick={() => setShowJudgeSelectionModal(true)}
                >
                  点击编辑
                </Button>
              </div>
            </Form.Item>
          </>
        ) : null}
      </Form>
    </Modal>
  );
};

export default CreateTournamentModal;
