import { createSelector } from '@reduxjs/toolkit';
import { getStoresMapping } from '../../../../../redux/entities/stores/selectors';

export const getStoreIds = (state: any) => state.stores.list.ids;

export const getTotal = (state: any) => state.stores.list.total;

export const getStoreListPageNumber = (state: any) =>
  state.stores.list.pageNumber;

export const getStoreListPageSize = (state: any) => state.stores.list.pageSize;

export const getStoreListTotalStores = (state: any) => state.stores.list.total;

export const getStoreList = createSelector(
  getStoresMapping,
  getStoreIds,
  (allStoresMapping, storeIds) =>
    storeIds
      .filter((id: string) => !!id)
      .map((id: string) => {
        const { province, city, district, addressDetail, ...restOptions }: any =
          allStoresMapping[id] || {};

        return {
          ...restOptions,
          city,
          displayAddress: [province, city, district, addressDetail]
            .filter((addressItem) => !!addressItem)
            .join(', '),
        };
      })
);
