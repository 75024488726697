import { createSlice } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../../service/constants';
import {
  loadPlayerList,
  updatePlayerListFilters,
  updatePlayerListPageNumber,
  updatePlayerListPageSize,
} from './thunks';

const initialState: {
  pageSize: number;
  pageNumber: number;
  totalPlayers: number;
  end: boolean;
  ids: string[];
  filters: {
    search: string;
    memberCardPaid: null | boolean;
  };
  verifyEmployeeModalVisible: boolean;
  actionName: string;
  loadPlayerStatus: string;
} = {
  pageSize: 10,
  pageNumber: 0,
  totalPlayers: 0,
  end: false,
  ids: [],
  filters: {
    search: '',
    memberCardPaid: null,
  },
  verifyEmployeeModalVisible: false,
  actionName: '',
  loadPlayerStatus: API_REQUEST_STATUS.IDLE,
};

export const { reducer, actions } = createSlice({
  name: 'player/list',
  initialState,
  reducers: {
    showVerifyEmployeeModal: (state) => {
      state.verifyEmployeeModalVisible = true;
    },
    hideVerifyEmployeeModal: (state) => {
      state.verifyEmployeeModalVisible = false;
    },
    setActionName: (state, { payload }) => {
      state.actionName = payload;
    },
  },
  extraReducers: {
    [loadPlayerList.fulfilled.type]: (state, action) => {
      const { pagination, ids } = action.payload || {};
      const { end, total } = pagination || {};

      state.end = end;
      state.totalPlayers = total;
      state.ids = ids;
    },
    [loadPlayerList.rejected.type]: (state: { loadPlayerStatus: string }) => {
      state.loadPlayerStatus = API_REQUEST_STATUS.REJECTED;
    },
    [updatePlayerListPageNumber.fulfilled.type]: (
      state: { pageNumber: number },
      action: { payload: number }
    ) => {
      state.pageNumber = action.payload;
    },
    [updatePlayerListPageSize.fulfilled.type]: (
      state: { pageSize: number; pageNumber: number },
      action: { payload: { pageSize: number; pageNumber: number } }
    ) => {
      state.pageSize = action.payload.pageSize;
      state.pageNumber = action.payload.pageNumber;
    },
    [updatePlayerListFilters.fulfilled.type]: (
      state: {
        pageNumber: number;
        filters: {
          search?: string;
          memberCardPaid: null | boolean;
        };
      },
      action: {
        payload: {
          pageNumber: number;
          filters: {
            search?: string;
            memberCardPaid: null | boolean;
          };
        };
      }
    ) => {
      const { pageNumber, filters } = action.payload || {};

      state.filters = {
        ...state.filters,
        ...filters,
      };
      state.pageNumber = pageNumber;
    },
  },
});

export default reducer;
