import { combineReducers } from '@reduxjs/toolkit';
import { reducer as details } from '../containers/Poster/redux';
import { reducer as list } from '../containers/PosterList/redux';

const reducer = combineReducers({
  details,
  list,
});

export default reducer;
