import { get, patch, post } from '../../../../../service/api';

export const getHistoryClubsRankingImportTemplate = async (
  merchantName: string
) => {
  try {
    const result = await get(
      `/merchants/${merchantName}/baseball/stats/baseballStatClub/import/template`,
      {},
      { responseType: 'blob' }
    );
    return result;
  } catch (error) {
    throw error;
  }
};

export const getHistoryPlayersAchievementImportTemplate = async (
  merchantName: string
) => {
  try {
    const result = await get(
      `/merchants/${merchantName}/baseball/stats/baseballStatPlayer/import/template`,
      {},
      {
        responseType: 'blob',
      }
    );
    return result;
  } catch (error) {
    throw error;
  }
};

export const getTempTournamentList = async (
  merchantName: string,
  parameters: {
    limit: number;
    offset: number;
    title: string | undefined;
  }
) => {
  try {
    const result = await get(
      `/merchants/${merchantName}/baseball/tournaments`,
      parameters,
      {}
    );
    return result;
  } catch (error) {
    throw error;
  }
};

export const postTempTournament = async (
  merchantName: string,
  data: {
    title: string;
  }
) => {
  try {
    const { title } = data;
    const result = await post(
      `/merchants/${merchantName}/baseball/tournaments`,
      {
        merchantName,
        title,
      },
      {}
    );
    return result;
  } catch (error) {
    throw error;
  }
};

export const patchTournamentImage = async (
  merchantName: string,
  id: string,
  data: {
    imageUrl: string;
  }
) => {
  try {
    const result = await patch(
      `/merchants/${merchantName}/baseball/tournaments/${id}`,
      data,
      {}
    );
    return result;
  } catch (error) {
    throw error;
  }
};
