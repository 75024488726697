import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../common/utils/types';
import { getEmployees } from '../../../redux/entities/employees/selectors';
import { getIsOwner, getUserName } from '../../../redux/app-v2/user/selectors';
import { getStores } from '../../../redux/entities/stores/selectors';
import {
  getIsUserInitialized,
  getIsManager,
  getUserBelongToStoreId,
} from '../../../redux/app-v2/user/selectors';

export {
  getIsUserInitialized,
  getIsManager,
  getUserBelongToStoreId,
  getUserName,
};

export const getFrom = (state: RootState) => state.audits.memberSearchFrom;
export const getTo = (state: RootState) => state.audits.memberSearchTo;
export const getEmployeeList = createSelector(getEmployees, (employeeList) => {
  return employeeList.map((item: any) => {
    return { label: item.name, key: item.id };
  });
});
export const getOptions = (state: {
  audits: { auditOptions: { event: [] } };
}) => state.audits.auditOptions;

export const getPaginateNum = (state: { audits: { paginateNum: number } }) =>
  state.audits.paginateNum;

export const getTableData = (state: { audits: { tableData: [] } }) =>
  state.audits.tableData;

export const getTotal = (state: { audits: { total: number } }) =>
  state.audits.total;

export const getPageSize = (state: { audits: { pageSize: number } }) =>
  state.audits.pageSize;

export const getAssetsDictionary = (state: {
  audits: { assetsDictionary: {} };
}) => state.audits.assetsDictionary;

export const getSelectedStoreKeys = (state: {
  audits: { selectedStoreKeys: string[] };
}) => state.audits.selectedStoreKeys;

export const getIsStoreListFilterVisible = createSelector(
  getIsManager,
  getIsOwner,
  (isManager, isOwner) => isManager || isOwner
);

export const getStoreList = createSelector(getStores, (stores) => {
  return stores.map((store) => ({
    value: store.id,
    label: store.displayName,
  }));
});

export const getIsPlayersFilterVisible = createSelector(
  getIsManager,
  getIsOwner,
  (isManager, isOwner) => isManager || isOwner
);

export const getPlayerList = (state: { audits: { players: [] } }) =>
  state.audits.players;

export const getDisplayPlayerList = createSelector(
  getPlayerList,
  (playerList) =>
    playerList.map((player: any) => ({
      value: player.id,
      label: `${player.remarkName}(${player.phone})`,
    }))
);

export const getSelectedPlayerId = (state: {
  audits: { selectedPlayerId: string | undefined };
}) => state.audits.selectedPlayerId;
