import _get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { AddClubState } from './types';
import { API_REQUEST_STATUS } from '../../../../../../../service/constants';

// get creating club data
export const getAddClubData = (state: {
  clubs: { add: { data: AddClubState } };
}) => state.clubs.add.data;

export const getClubLogo = createSelector(getAddClubData, (data) =>
  _get(data, 'logo', null)
);

export const getClubName = createSelector(getAddClubData, (data) =>
  _get(data, 'name', '')
);

export const getClubAddress = createSelector(getAddClubData, (data) =>
  _get(data, 'address', '')
);

// club merchant info
export const getClubMemberId = createSelector(getAddClubData, (data) =>
  _get(data, 'memberId', '')
);

export const getClubCompanyPhoneNumber = createSelector(
  getAddClubData,
  (data) => _get(data, 'companyPhoneNumber', '')
);

export const getClubCompanyEmail = createSelector(getAddClubData, (data) =>
  _get(data, 'companyEmail', '')
);

// club other info
export const getClubContactName = createSelector(getAddClubData, (data) =>
  _get(data, 'contactName', '')
);

export const getClubContactPhoneNumber = createSelector(
  getAddClubData,
  (data) => _get(data, 'contactPhoneNumber', '')
);

export const getClubContactEmail = createSelector(getAddClubData, (data) =>
  _get(data, 'contactEmail', '')
);

export const getClubCompanyName = createSelector(getAddClubData, (data) =>
  _get(data, 'companyName', null)
);

export const getClubRemark = createSelector(getAddClubData, (data) =>
  _get(data, 'remark', '')
);

export const getCreateClubStatus = (state: {
  clubs: { add: { createClubStatus: string } };
}) => state.clubs.add.createClubStatus;

export const getIsClubCreating = createSelector(
  getCreateClubStatus,
  (status) => status === API_REQUEST_STATUS.PENDING
);
