import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../service/constants';
import { RootState } from '../../../common/utils/types';
import {
  getIsCrew,
  getIsStoreManager,
  getUserBelongToStoreId,
} from '../../app-v2/user/selectors';
import { StoreUpdate, StoresApiResult } from './types';
import { getMerchantName } from '../../app-v2/merchant/selectors';
import { getStores, getStoreById, putStoreById } from './api-request';
import { actions as storesEntitiesActions } from './index';

export const loadStoresV2 = createAsyncThunk(
  'stores/loadStores',
  async (
    { offset, limit }: { offset: number; limit: number },
    { dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);
      const isStoreManager = getIsStoreManager(state);
      const isCrew = getIsCrew(state);
      const storeId = getUserBelongToStoreId(state);

      if (isStoreManager || isCrew) {
        const data = (await getStoreById({
          merchantName,
          id: storeId,
        })) as { id: string };

        dispatch(storesEntitiesActions.updateStoreById(data));

        return {
          pagination: { end: true, total: 1 },
          ids: [data.id],
        };
      }

      const { data, pagination } = (await getStores({
        merchantName,
        limit,
        offset,
      })) as StoresApiResult;

      dispatch(storesEntitiesActions.updateStoreMapping(data));

      return {
        pagination,
        ids: data.map((item: { id: string }) => item.id),
      };
    } catch (error) {
      throw error;
    }
  }
);

export const loadStoreByIdV2 = createAsyncThunk(
  'stores/loadStoreById',
  async (id: string, { dispatch, getState }) => {
    try {
      const state = getState();
      const merchantName = getMerchantName(state);
      const data = (await getStoreById({ merchantName, id })) as { id: string };

      await dispatch(storesEntitiesActions.updateStoreById(data));

      return id;
    } catch (error) {
      throw error;
    }
  }
);

export const updateStoreByIdV2 = createAsyncThunk(
  'stores/updateStoreById',
  async (
    { id, payload }: { id: string; payload: StoreUpdate },
    { dispatch, getState }
  ) => {
    try {
      const state = getState();
      const merchantName = getMerchantName(state);
      await putStoreById({
        merchantName,
        id,
        data: payload,
      });

      await dispatch(storesEntitiesActions.updateStoreById({ id, ...payload }));

      return {
        status: API_REQUEST_STATUS.FULFILLED,
      };
    } catch (error) {
      throw error;
    }
  }
);
