import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../common/utils/types';
import { errorMessage, successMessage } from '../../../common/utils/feedback';
import { getMerchantName } from '../../../redux/app-v2/merchant/selectors';
import {
  getAssetsList,
  postAssets,
  deleteAsset,
  patchAsset,
} from './api-request';
import { getSelectedAssets, getSelectedAssetsId } from './selectors';

export const loadAssetList = createAsyncThunk(
  'assets/loadAssetList',
  async (_, { getState }) => {
    try {
      const state = getState();
      const merchantName = getMerchantName(state);
      const result: any = await getAssetsList({ merchantName });

      return result.data;
    } catch (e) {
      throw e;
    }
  }
);

export const createAssets = createAsyncThunk(
  'assets/createAssets',
  async (payload: any, { dispatch, getState }) => {
    try {
      const state = getState();
      const merchantName = getMerchantName(state);
      const param = {
        type: payload.type,
        unit: payload.unit,
        description: payload.description,
        isSensitive: payload.isSensitive,
      };

      const result: any = await postAssets({ merchantName, asset: param });

      successMessage(
        '新的会员权益已经成功添加入列表，请在会员权益列表中进行编辑'
      );

      dispatch(loadAssetList());

      return result;
    } catch (e) {
      errorMessage('添加会员权益失败, 此种资产已存在');

      throw e;
    }
  }
);

export const removeAsset = createAsyncThunk(
  'assets/removeAsset',
  async (id: string, { getState, dispatch }) => {
    try {
      const state = getState();
      const merchantName = getMerchantName(state);
      const result: any = await deleteAsset({ merchantName, id });

      if (result.code === 4) {
        throw Error;
      }

      successMessage('此权益删除成功');
      dispatch(loadAssetList());

      return result;
    } catch (e) {
      errorMessage('删除权益失败, 权益已经进行过操作');

      throw e;
    }
  }
);

export const archiveAsset = createAsyncThunk(
  'assets/archiveAsset',
  async (data: any, { dispatch, getState }) => {
    const { id, type } = data || {};
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);
      const result: any = await patchAsset({ merchantName, id, data });

      successMessage(`${type}权益已${data.archived ? '停用' : '启用'}`);
      dispatch(loadAssetList());

      return result;
    } catch (e) {
      throw e;
    }
  }
);

export const updateAsset = createAsyncThunk(
  'assets/updateAsset',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);
      const id: any = getSelectedAssetsId(state);
      const data: any = getSelectedAssets(state);
      const { type } = data || {};
      const result: any = await patchAsset({ merchantName, id, data });

      successMessage(`${type}详情修改成功`);
      dispatch(loadAssetList());

      return result;
    } catch (e) {
      throw e;
    }
  }
);
