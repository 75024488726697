/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/entrances/containers/WebLogin';
import WecomLogin from './pages/entrances/containers/WecomLogin';
import ForgetPassword from './pages/entrances/containers/ForgetPassword';
import Registered from './pages/entrances/containers/Registered';
import App from './App';

export default () => (
  <Router>
    <Routes>
      <Route path="/*" element={<App />} />
      <Route path="/login" element={<Login />} />
      <Route path="/wecom-login" element={<WecomLogin />} />
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route path="/register" element={<Registered />} />
    </Routes>
  </Router>
);
