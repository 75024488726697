import { combineReducers } from '@reduxjs/toolkit';
import { reducer as user } from './user';
import { reducer as merchant } from './merchant';
import { reducer as featuresSetting } from './featuresSetting';
import { reducer as roles } from './roles';
import common from './common';

const appV2Reducer = combineReducers({
  common,
  user,
  merchant,
  roles,
  featuresSetting,
});

export default appV2Reducer;
