import { createSlice } from '@reduxjs/toolkit';
import { EnrollmentClubsTypes } from './types';
import { API_REQUEST_STATUS } from '../../../../../../../service/constants';
import {
  exportEnrollmentClubs,
  filterEnrollmentClubs,
  loadEnrollmentClubs,
  updateEnrollmentClubsPageNumber,
  updateEnrollmentClubsPageSize,
} from './thunks';

const initialState: EnrollmentClubsTypes = {
  data: [],
  pageNumber: 0,
  pageSize: 10,
  total: 0,
  enrollmentId: '',
  filters: {
    text: '',
    isSubmitted: undefined,
  },
  loadEnrollmentClubsStatus: API_REQUEST_STATUS.IDLE,
  exportEnrollmentClubsStatus: API_REQUEST_STATUS.IDLE,
};

export const { reducer, actions } = createSlice({
  name: 'association/enrollmentClubs',
  initialState,
  reducers: {
    enrollmentIdUpdated: (state, { payload }) => {
      state.enrollmentId = payload;
    },
  },
  extraReducers: {
    [loadEnrollmentClubs.pending.type]: (state) => {
      state.loadEnrollmentClubsStatus = API_REQUEST_STATUS.PENDING;
    },
    [loadEnrollmentClubs.fulfilled.type]: (state, action) => {
      const { data, pagination } = action.payload;
      const { total } = pagination || {};

      state.data = data;
      state.total = total;
      state.loadEnrollmentClubsStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [loadEnrollmentClubs.rejected.type]: (state) => {
      state.loadEnrollmentClubsStatus = API_REQUEST_STATUS.REJECTED;
    },
    [updateEnrollmentClubsPageNumber.fulfilled.type]: (
      state,
      { payload }: { payload: number }
    ) => {
      state.pageNumber = payload;
    },
    [updateEnrollmentClubsPageSize.fulfilled.type]: (
      state,
      {
        payload,
      }: {
        payload: {
          pageNumber: number;
          pageSize: number;
        };
      }
    ) => {
      const { pageNumber, pageSize } = payload || {};

      state.pageNumber = pageNumber;
      state.pageSize = pageSize;
    },
    [filterEnrollmentClubs.fulfilled.type]: (state, { payload }) => {
      const { text, isSubmitted } = payload || {};

      state.filters = {
        text,
        isSubmitted,
      };
    },
    [exportEnrollmentClubs.pending.type]: (state) => {
      state.exportEnrollmentClubsStatus = API_REQUEST_STATUS.PENDING;
    },
    [exportEnrollmentClubs.fulfilled.type]: (state) => {
      state.exportEnrollmentClubsStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [exportEnrollmentClubs.rejected.type]: (state) => {
      state.exportEnrollmentClubsStatus = API_REQUEST_STATUS.REJECTED;
    },
  },
});
