import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Button, Modal, Skeleton } from 'antd';
import './../RenewalAgreement/RenewalAgreementModal.less';

const HistoryAgreement = ({
  show,
  content,
  onClose,
}: {
  show: boolean;
  content: string;
  onClose: Function;
}) => {
  const [isCustomerAgreementLoaded, setIsCustomerAgreementLoaded] =
    useState<any>(false);
  const [customerAgreement, setCustomerAgreement] = useState<any>(null);

  useEffect(() => {
    if (show && content) {
      setCustomerAgreement(content);
      setIsCustomerAgreementLoaded(true);
    }
  }, [content, show]);

  return (
    <Modal
      centered
      width="100%"
      visible={show}
      className="RenewalAgreementModal"
      title="管理后台历史协议"
      footer={ null }
      onCancel={() => {
        onClose();
      }}
    >
      <h2 className="text-center text-bold">SaaS 系统续订协议</h2>
      <Skeleton loading={!isCustomerAgreementLoaded} active>
        <ReactMarkdown
          className="RenewalAgreementModalCustomerAgreement"
          children={customerAgreement}
          remarkPlugins={[remarkGfm]}
        />
      </Skeleton>
    </Modal>
  );
};

HistoryAgreement.displayName = 'HistoryAgreement';

export default HistoryAgreement;
