import { createSelector } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../service/constants';
import { getFormatLocalTime } from '../../../../common/utils/time-lib';
import {
  getMerchantSubscriptionPricePlanId,
  getMerchantSubscriptionCompanyName,
  getMerchantSubscriptionCompanyAddress,
  getMerchantSubscriptionPrincipalName,
} from '../../../../redux/app-v2/merchant/selectors';
import { getPricePlansMapping } from '../../../../redux/entities/pricePlans/selectors';

export const getSubscriptionPricePlans = (state: any) =>
  state.subscription.pricePlans;

export const getSubscriptionPricePlanTypeIdsMapping = createSelector(
  getSubscriptionPricePlans,
  (pricePlans: any) => pricePlans.pricePlanTypeIds
);

export const getSubscriptionLoadPricePlanStatus = createSelector(
  getSubscriptionPricePlans,
  (pricePlans: any) => pricePlans.loadPricePlanStatus
);

export const getSubscriptionChangedDifferentAddonStoreCount = createSelector(
  getSubscriptionPricePlans,
  (pricePlans: any) => pricePlans.differentAddonStoreCount || {}
);

export const getSubscriptionLoadPricePlanCompleted = createSelector(
  getSubscriptionLoadPricePlanStatus,
  (loadPricePlanStatus: string) =>
    loadPricePlanStatus === API_REQUEST_STATUS.FULFILLED ||
    loadPricePlanStatus === API_REQUEST_STATUS.REJECTED
);

export const getSubscriptionPricePlanList = createSelector(
  getPricePlansMapping,
  getSubscriptionPricePlanTypeIdsMapping,
  getSubscriptionChangedDifferentAddonStoreCount,
  (
    pricePlansMapping,
    pricePlanTypeIdsMapping,
    changedDifferentAddonStoreCount
  ) => {
    const pricePlanIds: string[] = Object.values(pricePlanTypeIdsMapping);
    const pricePlanTypes: string[] = Object.keys(pricePlanTypeIdsMapping);
    const pricePlanList: any = [];

    pricePlanIds.forEach((id: string) => {
      const pricePlan = pricePlansMapping[id];
      const { type } = pricePlan || {};
      const planIndex = pricePlanTypes.findIndex(
        (pricePlanType: string) => pricePlanType === type
      );

      if (planIndex >= 0) {
        pricePlanList[planIndex] = {
          ...pricePlansMapping[id],
          addonStoreCount: changedDifferentAddonStoreCount[type] || 0,
        };
      }
    });

    return pricePlanList.filter((pricePlan: any) => !!pricePlan);
  }
);

export const getSubscriptionChangedActivePricePlanId = createSelector(
  getSubscriptionPricePlans,
  (pricePlans: any) => pricePlans.activeId
);

export const getSubscriptionActivePricePlanType = createSelector(
  getSubscriptionPricePlanList,
  getSubscriptionChangedActivePricePlanId,
  (pricePlanList, subscriptionNewActivePricePlanId) => {
    const activePricePlan = pricePlanList.find(
      (pricePlan: any) => pricePlan.id === subscriptionNewActivePricePlanId
    );

    return activePricePlan?.type || '';
  }
);

export const getSubscriptionActivePricePlanId = createSelector(
  getSubscriptionPricePlanList,
  getMerchantSubscriptionPricePlanId,
  getSubscriptionChangedActivePricePlanId,
  (
    pricePlanList,
    merchantSubscriptionPricePlanId,
    subscriptionNewActivePricePlanId
  ) => {
    const activePricePlan = pricePlanList.find(
      (pricePlan: any) => pricePlan.id === merchantSubscriptionPricePlanId
    );

    return subscriptionNewActivePricePlanId || activePricePlan?.id || '';
  }
);

export const getSubscriptionPreviewPlanOrder = (state: any) =>
  state.subscription.previewPlanOrder;

export const getLoadSubscriptionPreviewPlanOrderStatus = createSelector(
  getSubscriptionPreviewPlanOrder,
  (previewPlanOrder: any) => previewPlanOrder.loadPreviewPlanOrderStatus
);

export const getLoadSubscriptionPreviewPlanOrderStatusCompleted =
  createSelector(
    getLoadSubscriptionPreviewPlanOrderStatus,
    (loadPreviewPlanOrderStatus: string) =>
      loadPreviewPlanOrderStatus === API_REQUEST_STATUS.FULFILLED ||
      loadPreviewPlanOrderStatus === API_REQUEST_STATUS.REJECTED
  );

export const getSubscriptionPreviewOrder = createSelector(
  getSubscriptionPreviewPlanOrder,
  getSubscriptionActivePricePlanId,
  getSubscriptionActivePricePlanType,
  getSubscriptionChangedDifferentAddonStoreCount,
  (
    previewPlanOrder,
    activePricePlanId,
    activePricePlanType,
    differentAddonStoreCount
  ) => ({
    purchaseType: previewPlanOrder.purchaseType,
    paymentMethod: previewPlanOrder.paymentMethod,
    paymentProvider: previewPlanOrder.paymentProvider,
    client: previewPlanOrder.client,
    planId: activePricePlanId,
    addonStoreCount: differentAddonStoreCount[activePricePlanType] || 0,
  })
);

export const getSubscriptionOrder = (state: any) => state.subscription.order;

export const getSubscriptionOrderId = createSelector(
  getSubscriptionOrder,
  (order: any) => order.id
);

export const getSubscriptionCreateOrderStatus = createSelector(
  getSubscriptionOrder,
  (order: any) => order.createOrderStatus
);

export const getSubscriptionOrderDisplayTotal = createSelector(
  getSubscriptionOrder,
  (order: any) => order.total / 100
);

export const getSubscriptionOrderCurrency = createSelector(
  getSubscriptionOrder,
  (order: any) => order.currency
);

export const getSubscriptionOrderExistingSubscriptionOffsetAmount =
  createSelector(
    getSubscriptionOrder,
    (order: any) => order.existingSubscriptionOffsetAmount
  );

export const getSubscriptionOrderPaymentQRCodeUrl = createSelector(
  getSubscriptionOrder,
  (order: any) => order.paymentQRCodeUrl
);

export const getSubscriptionAddonStoreUnitPrice = (state: any) =>
  state.subscription.addonStoreUnitPrice;

export const getSubscriptionOrderItems = createSelector(
  getSubscriptionOrder,
  getSubscriptionActivePricePlanType,
  getSubscriptionChangedDifferentAddonStoreCount,
  getSubscriptionOrderExistingSubscriptionOffsetAmount,
  getSubscriptionAddonStoreUnitPrice,
  (
    order: any,
    activePricePlanType,
    differentAddonStoreCount,
    subscriptionOrderExistingSubscriptionOffsetAmount,
    addonStoreUnitPrice
  ) => {
    const { items: orderItems } = order || {};
    const items = (orderItems || []).map((item: any) => {
      const displayItem = {
        type: item.type,
        quantity: item.quantity,
        period: item.period,
        addonStoreCount: 0,
        displaySubtotal: item.subtotal / 100,
        displayTotal: item.total / 100,
        displayUnitPrice: item.unitPrice / 100,
        displayDiscount: item.discount / 100,
        displayAddonUnitPrice: addonStoreUnitPrice,
      };

      if (item.type === 'addonStore') {
        displayItem.addonStoreCount =
          differentAddonStoreCount[activePricePlanType] || 0;
      }

      return displayItem;
    });

    if (subscriptionOrderExistingSubscriptionOffsetAmount) {
      items.push({
        type: 'existingSubscriptionOffset',
        displayTotal: subscriptionOrderExistingSubscriptionOffsetAmount / 100,
      });
    }

    return items;
  }
);

export const getSubscriptionOrderStatus = createSelector(
  getSubscriptionOrder,
  (order: any) => order.status
);

export const getSubscriptionShowRenewalAgreementModalVisibility = (
  state: any
) => state.subscription.renewalAgreementModalVisible;

export const getSubscriptionShowHistoryAgreementModalVisibility = (
  state: any
) => state.subscription.historyAgreementModelVisible;

export const getHistoryAgreementContent = (state: any) =>
  state.subscription.historyAgreement.content;

export const getSubscriptionOrderListPageNumber = (state: any) =>
  state.subscription.orderList.pageNumber;

export const getSubscriptionOrderListPageSize = (state: any) =>
  state.subscription.orderList.pageSize;

export const getSubscriptionOrderListTotalOrders = (state: any) =>
  state.subscription.orderList.totalOrders;

export const getOriginalSubscriptionOrderList = (state: any) =>
  state.subscription.orderList.data || [];

export const getSubscriptionOrderList = createSelector(
  getOriginalSubscriptionOrderList,
  (originalSubscriptionOrderList) =>
    originalSubscriptionOrderList.map((order: any) => {
      const { id, orderId, createdTime, total, items, status } = order || {};
      const itemsLabels = (items || []).map((item: any) => item.title);
      const planAndStores = itemsLabels
        .filter((label: string | undefined | null) => !!label)
        .join(' / ');

      return {
        id: id,
        orderId: orderId,
        displayTotal: total / 100,
        planAndStores,
        displayCreateTime: getFormatLocalTime(createdTime),
        status,
      };
    })
);

export const getSubscriptionOrderListPagination = createSelector(
  getSubscriptionOrderListPageNumber,
  getSubscriptionOrderListPageSize,
  (pageNumber, pageSize) => {
    return { limit: pageSize, offset: pageNumber * pageSize };
  }
);

export const getSubscriptionChangedAgreementInfo = (state: any) =>
  state.subscription.agreementInfo;

export const getSubscriptionOrderUnitPlanPrice = createSelector(
  getSubscriptionOrder,
  (subscriptionOrder) => {
    const { items } = subscriptionOrder || {};
    const unitPlanPrice =
      items?.find((item: any) => item.type === 'plan')?.unitPrice || 0;

    return unitPlanPrice;
  }
);

export const getSubscriptionOrderAgreementInfo = createSelector(
  getSubscriptionChangedAgreementInfo,
  getMerchantSubscriptionCompanyName,
  getMerchantSubscriptionCompanyAddress,
  getMerchantSubscriptionPrincipalName,
  (agreementInfo, companyName, companyAddress, principalName) => {
    return {
      companyName,
      companyAddress,
      principalName,
      ...agreementInfo,
    };
  }
);

export const getSubscriptionAgreementInfo = createSelector(
  getSubscriptionOrderAgreementInfo,
  getSubscriptionOrderUnitPlanPrice,
  getSubscriptionAddonStoreUnitPrice,
  (subscriptionAgreementInfo, planPrice, storePrice) => {
    return {
      ...subscriptionAgreementInfo,
      planPrice: planPrice / 100,
      storePrice: storePrice,
    };
  }
);

export const getSubscriptionBusinessLicenseModalVisibility = (state: any) =>
  state.subscription.businessLicenseModalVisible;
