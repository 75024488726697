import { get, post, put } from '../../../service/api';

export const getEmployees = async (payload: {
  merchantName: string;
  search: string;
  storeId: string;
  offset: number;
  limit: number;
}) => {
  try {
    const { merchantName, search, storeId, offset, limit } = payload;
    const params = { offset, limit, search, storeId };

    const { data, pagination }: any = await get(
      `/merchants/${merchantName}/employees`,
      params,
      {},
      { isShow: false }
    );

    return { data, pagination };
  } catch (error: any) {
    throw error;
  }
};

export const postEmployee = async (payload: {
  merchantName: string;
  accountName: string;
  name: string;
  roleName: string;
  storeId: string;
}) => {
  try {
    const { merchantName, ...othersData } = payload;
    const result: any = await post(
      `/merchants/${merchantName}/employees`,
      othersData,
      {}
    );

    return result;
  } catch (error: any) {
    throw error;
  }
};

export const getEmployeeById = async (payload: {
  merchantName: string;
  id: string;
}) => {
  try {
    const { merchantName, id } = payload;
    const { data }: any = await get(
      `/merchants/${merchantName}/employees/${id}`,
      {},
      {},
      { isShow: false }
    );

    return data;
  } catch (error: any) {
    throw error;
  }
};

export const putEmployeeById = async (payload: {
  merchantName: string;
  id: string;
  data: any;
}) => {
  try {
    const { merchantName, id, data } = payload;
    const result: any = await put(
      `/merchants/${merchantName}/employees/${id}`,
      data,
      { isShow: false }
    );

    return result?.data || {};
  } catch (error: any) {
    throw error;
  }
};
