import { combineReducers } from '@reduxjs/toolkit';
import { reducer as common } from './common';
import { reducer as details } from '../containers/PlayerDetails/redux';
import { reducer as list } from '../containers/PlayerList/redux';
import { reducer as newPlayer } from './newPlayer';
import { reducer as imports } from './playerImport';

const reducer = combineReducers({
  common,
  imports,
  details,
  list,
  newPlayer,
});

export default reducer;
