import { deleteRequest, get, post, patch } from '../../../../../service/api';

export const getRankSettingsById = async (payload: {
  merchantName: string;
  id: string;
}) => {
  try {
    const { merchantName, id } = payload;
    const result: any = await get(
      `/merchants/${merchantName}/ranking-settings/${id}`,
      {},
      {}
    );

    return result;
  } catch (e) {
    throw new Error('get ranking setting by id failed');
  }
};

export const postNewRankSettings = async (payload: {
  merchantName: string;
  ranksettings: any;
  categories: any;
  scoreLimit: any;
}) => {
  try {
    const { merchantName, ranksettings, ...resPayload } = payload;
    const finalPayload = { ...resPayload, ...ranksettings };
    const result: any = await post(
      `/merchants/${merchantName}/ranking-settings`,
      finalPayload,
      {}
    );

    return result.data;
  } catch (e) {
    throw new Error('post new ranking setting by failed');
  }
};

export const patchRankSettingsById = async (payload: {
  merchantName: string;
  id: string;
  ranksettings: any;
  categories: any;
  scoreLimit: any;
}) => {
  try {
    const { merchantName, id, ranksettings, ...resPayload } = payload;
    const finalPayload = { ...resPayload, ...ranksettings };
    const result: any = await patch(
      `/merchants/${merchantName}/ranking-settings/${id}`,
      finalPayload,
      {}
    );

    return result.message;
  } catch (e) {
    throw new Error('patch new ranking setting by failed');
  }
};

export const deleteRankSettingsById = async (payload: {
  merchantName: string;
  id: string;
}) => {
  try {
    const { merchantName, id } = payload;
    const result: any = await deleteRequest(
      `/merchants/${merchantName}/ranking-settings/${id}`,
      {},
      {}
    );

    return result.message;
  } catch (e) {
    throw new Error('delete new ranking setting by failed');
  }
};
