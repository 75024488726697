/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect } from 'react';
import { useMount } from 'react-use';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { CSVLink } from 'react-csv';
import { Button, Space, Upload, UploadProps } from 'antd';
import { publicIp } from '../../../../../../service/apiIP';
import {
  getHeader,
  getTheDataSource,
  getThePageSize,
  getThePaginateNum,
  getTheTotal,
} from './redux/selectors';
import {
  getIsBaseball,
  getMerchantName,
} from '../../../../../../redux/app-v2/merchant/selectors';
import { setPageSize, setPaginateNum } from './redux';
import { getImportTheTemplate, getTheImportLogs } from './redux/thunks';
import ContentDetail from '../../../../../../common/components/ContentDetail';
import TableResponsiveFrame from '../../../../../../common/components/tables/TableResponsiveFrame';
import BreadcrumbRouter from '../../../../../../common/components/BreadcrumbRouter';
import './ClubAuthenticationImport.less';

const ClubAuthenticationImport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dataSource = useSelector(getTheDataSource);
  const paginateNum = useSelector(getThePaginateNum);
  const pageSize = useSelector(getThePageSize);
  const total = useSelector(getTheTotal);
  const header = useSelector(getHeader);
  const merchantName = useSelector(getMerchantName);

  const userAuthorization = useSelector(
    (state: any) => state.appV2.user.authorization
  );

  const columns: any = [
    {
      title: '时间',
      width: 140,
      dataIndex: 'createdTime',
      key: 'time',
      align: 'center',
    },
    {
      title: '操作人',
      width: 120,
      dataIndex: 'operatorName',
      key: 'name',
      align: 'center',
      render: (name: any) => {
        return <div dangerouslySetInnerHTML={{ __html: name }}></div>;
      },
    },
    {
      title: '状态',
      width: 120,
      dataIndex: 'status',
      key: 'archived',
      align: 'center',
      render: (status: any, record: any) => (
        <Button
          size="small"
          className={
            status === 'failed'
              ? 'row-button-disable'
              : status === 'imported'
              ? 'row-button-enable'
              : 'row-button-pendding'
          }
        >
          {status === 'failed'
            ? '导入失败'
            : status === 'imported'
            ? '已导入'
            : '正在导入'}
        </Button>
      ),
    },
    {
      title: '文件(含错误提示)',
      width: 120,
      dataIndex: 'fileUrl',
      key: 'remarkName',
      align: 'center',
      render: (_: any, record: any) =>
        record.status !== 'pending' ? (
          <a href={record.fileUrl}>点击下载</a>
        ) : (
          <></>
        ),
    },
  ];

  const props: UploadProps = {
    name: 'file',
    accept: '.csv',
    headers: {
      Authorization: userAuthorization,
    },
    action: `${publicIp}/merchants/${merchantName}/association-clubs/import/tasks`,
    onChange({ file }) {
      if (file.status === 'done') {
        dispatch(getTheImportLogs() as any);
        setTimeout(() => {
          dispatch(getTheImportLogs() as any);
        }, 5000);
      }
    },
  };

  useMount(() => {
    dispatch(getTheImportLogs() as any);
    dispatch(getImportTheTemplate() as any);
  });

  useEffect(() => {
    dispatch(getTheImportLogs() as any);
  }, [paginateNum, pageSize, dispatch]);

  const onShowSizeChange = (current: number, pageSize: number) => {
    dispatch(setPageSize(pageSize));
  };

  const paginateChange = (page: number) => {
    dispatch(setPaginateNum(page));
  };

  const isBaseball = useSelector(getIsBaseball);
  const clubText = isBaseball ? '联盟球队' : '成员单位';

  return (
    <ContentDetail
      title={`${clubText}认证 / 表格导入`}
      headerLeft={
        <BreadcrumbRouter
          routes={[
            {
              path: '/association-activities/clubs',
              breadcrumbName: `${clubText}认证`,
            },
            {
              path: 'import',
              breadcrumbName: '表格导入',
            },
          ]}
        />
      }
    >
      <div className="ClubAuthenticationImport content__filter">
        <div className="content__body__item">
          <span>提供{clubText}认证的表格以便于快速导入。</span>
          <br />
          <span>
            可以
            <CSVLink
              data={[]}
              headers={header}
              filename={`导入${clubText}认证模板`}
            >
              点击此处
            </CSVLink>
            下载模板文件作为编辑参考。
          </span>
          <br />
          <span>
            阅读
            <a
              target="_blank"
              href={
                process.env.REACT_APP_ENV === 'test'
                  ? process.env.REACT_APP_HELP_CENTER_TEST_URL
                  : process.env.REACT_APP_HELP_CENTER_PROD_URL
              }
              rel="noreferrer"
            >
              帮助文档
            </a>
            可获取更多信息以便能成功导入所需信息。
          </span>
          <br />
          <br />
          <div className="ClubAuthenticationImportNotice">
            <div className="notice_title">重要提示：</div>
            <div className="notice_content">
              请不要更改任何默认表头，否则导入会发生错误
              <br />
              当文件被验证没有任何错误的时候才会开始导入，不会出现导入一半的情况
              <br />
              如果导入成功请勿重复导入，否则会员权益会重复导入
              <br />
              如果导入失败，请下载文件检查错误提示
            </div>
          </div>

          <br />
        </div>

        <div style={{ marginTop: '36px', marginBottom: '16px' }}>
          <Space size={10}>
            <Upload {...props}>
              <Button type="primary" className="operateButton">
                导入
              </Button>
            </Upload>
          </Space>
          <Space size={10} style={{ marginLeft: '10px' }}>
            <Button
              type="default"
              className="operateButton cancelButton"
              onClick={() => {
                navigate(-1);
              }}
            >
              返回
            </Button>
          </Space>
        </div>

        <TableResponsiveFrame
          columns={columns}
          pageNumber={paginateNum}
          pageSize={pageSize}
          data={dataSource}
          dataItemsTotal={total}
          onChangePageSize={onShowSizeChange}
          onChangePageNumber={paginateChange}
          onRowClick={(record: any) => {
            return record.id ? record.id + Date.now() : 1;
          }}
        />
      </div>
    </ContentDetail>
  );
};

export default ClubAuthenticationImport;
