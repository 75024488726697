import { ROLES } from '../../common/constants';

export const ROLE_MAPPING = {
  [ROLES.Owner]: '顶级管理员',
  [ROLES.Manager]: '全局管理员',
  [ROLES.StoreManager]: '门店管理员',
  [ROLES.Crew]: '店员',
  [ROLES.Operation]: '运营人员',
  [ROLES.Judge]: '裁判',
};
