import { RootState } from '../../../../../common/utils/types';
import {
  getIsUserInitialized,
  getIsManager,
  getIsOwner,
  getUserBelongToStoreId,
} from '../../../../../redux/app-v2/user/selectors';

const voucherNameMap: { [key: string]: string } = {
  sentOut: '已发放',
  redeemed: '已核销',
  cancelled: '已取消',
};

export {
  getIsUserInitialized,
  getIsManager,
  getIsOwner,
  getUserBelongToStoreId,
};

export const getPaginateNum = (state: RootState) =>
  state.marketVoucher.paginateNum;

export const getTableData = (state: RootState) => state.marketVoucher.tableData;

export const getTotal = (state: RootState) => state.marketVoucher.total;

export const getPageSize = (state: RootState) => state.marketVoucher.pageSize;

export const getAssetsDictionary = (state: RootState) =>
  state.marketVoucher.assetsDictionary;

export const getSelectStatus = (state: RootState) => {
  return state.marketVoucher.selectStatus.map((status) => ({
    key: status,
    label: voucherNameMap[status],
  }));
};
