import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Switch,
} from 'antd';
import {
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { getIsBaseball } from '../../../../../../../../../redux/app-v2/merchant/selectors';
import { TEAM_PLAYER_COUNTS } from '../utils/constants';
import ArcheryOptions from './ArcheryOptions';

const CompetitionOptionSettingModal = (props: {
  id?: string;
  show?: boolean;
  preserve: boolean;
  deletable: boolean;
  data: any;
  onDelete?: () => void;
  onSave: (form?: any) => void;
  onReset: (form?: any) => void;
  onClose?: (form?: any) => void;
  onSelectBow: (bow: string) => void;
  onSelectArrow: (arrow: string) => void;
  onSelectEnvironment: (environment: string) => void;
  onSelectDistance: (distance: string) => void;
  onSelectTarget: (target: string) => void;
  onChangeCategoryName?: (categoryName: string) => void;
  onChangeGameInfoCategoryOptionTeamPlayerCountList: (
    teamPlayerCountList: { name: string; teamPlayerCount: number }[]
  ) => void;
  onChangeArcheryPriceSettings: (archeryPriceSettings: any) => void;
}) => {
  const [form] = Form.useForm();
  const {
    id,
    show,
    deletable,
    preserve = true,
    data,
    onDelete,
    onSave,
    onReset,
    onClose,
    onSelectBow,
    onSelectArrow,
    onSelectEnvironment,
    onSelectDistance,
    onSelectTarget,
    onChangeCategoryName,
    onChangeGameInfoCategoryOptionTeamPlayerCountList,
    onChangeArcheryPriceSettings,
  } = props;
  const {
    gameOptionName,
    gameOptionTeamPlayerCountList,
    gameOptionArcheryPriceSettings,
    gameArcherySettings,
  } = data || {};
  const [gameOptionSettingModalVisible, setGameOptionSettingModalVisible] =
    useState(false);
  const resetData = async () => {
    await form.resetFields();
    onReset(form);
  };

  const isBaseball = useSelector(getIsBaseball);
  const playerText = isBaseball ? '球员' : '运动员';

  useEffect(() => {
    if (show) {
      setGameOptionSettingModalVisible(true);
    }
  }, [show]);

  return (
    <Modal
      centered
      className="GameOptionSettingDetailModal"
      maskClosable={false}
      visible={gameOptionSettingModalVisible}
      footer={[
        deletable && (
          <Button
            danger
            key="EnrollmentDetailsOptionModalDeleteButton"
            type="text"
            icon={<DeleteOutlined />}
            onClick={onDelete}
          >
            删除
          </Button>
        ),
        <Button
          key="EnrollmentDetailsOptionModalDeleteSubmit"
          type="primary"
          loading={false}
          onClick={() => {
            onSave(form);
          }}
        >
          保存
        </Button>,
        <Button
          ghost
          key="EnrollmentDetailsOptionModalDeleteCancel"
          type="primary"
          onClick={() => {
            onClose && onClose(form);
            setGameOptionSettingModalVisible(false);
          }}
        >
          取消
        </Button>,
      ]}
      onOk={() => {
        onSave(form);
      }}
      onCancel={() => {
        onClose && onClose(form);
        setGameOptionSettingModalVisible(false);
      }}
      afterClose={resetData}
    >
      <Form form={form} preserve={preserve}>
        <ArcheryOptions
          data={gameArcherySettings}
          onSelectBow={onSelectBow}
          onSelectArrow={onSelectArrow}
          onSelectEnvironment={onSelectEnvironment}
          onSelectDistance={onSelectDistance}
          onSelectTarget={onSelectTarget}
        />
        <Form.Item
          label="人数"
          name={
            id
              ? [id, 'selectedGameOptionTeamPlayerCountList']
              : 'selectedGameOptionTeamPlayerCountList'
          }
          rules={[
            {
              required: true,
              message: '人数不能为空',
            },
          ]}
          initialValue={gameOptionTeamPlayerCountList.map(
            (p: any) => `${p.name}-${p.teamPlayerCount}`
          )}
        >
          <Checkbox.Group
            onChange={(checkedValues: any) => {
              const newTeamPlayerCountList: {
                name: string;
                teamPlayerCount: number;
              }[] = checkedValues.map((v: string) => {
                const ps = v.split('-');
                return {
                  name: ps[0],
                  teamPlayerCount: parseInt(ps[1]),
                };
              });
              onChangeGameInfoCategoryOptionTeamPlayerCountList(
                newTeamPlayerCountList.sort(
                  (a, b) =>
                    TEAM_PLAYER_COUNTS.findIndex(
                      (t: any) =>
                        t.name === a.name &&
                        t.teamPlayerCount === a.teamPlayerCount
                    ) -
                    TEAM_PLAYER_COUNTS.findIndex(
                      (t: any) =>
                        t.name === b.name &&
                        t.teamPlayerCount === b.teamPlayerCount
                    )
                )
              );
            }}
            value={gameOptionTeamPlayerCountList.map(
              (p: any) => `${p.name}-${p.teamPlayerCount}`
            )}
          >
            {TEAM_PLAYER_COUNTS.map((value: any) => (
              <Checkbox
                key={`${value.name}-${value.teamPlayerCount}`}
                value={`${value.name}-${value.teamPlayerCount}`}
              >
                {value.name}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Form.Item>

        <div
          className="flex flex-middle"
          style={{
            margin: '12px 0',
          }}
        >
          <label className="flex__shrink-fixed" htmlFor="name">
            项目名称
          </label>
          <Input
            style={{ margin: '0 12px' }}
            onChange={(e) => {
              onChangeCategoryName && onChangeCategoryName(e.target.value);
            }}
            value={gameOptionName}
            placeholder="请输入项目名称（例如: 团体赛）"
          />
        </div>

        {gameOptionTeamPlayerCountList.map((optionTeamPlayer: any) => {
          const settings = gameOptionArcheryPriceSettings.find(
            (s: any) =>
              s.name === optionTeamPlayer.name &&
              s.teamPlayerCount === optionTeamPlayer.teamPlayerCount
          );
          const {
            gameOptionEnableChargePrice,
            gameOptionPrice,
            gameOptionAssociationPlayerPrice,
          } = settings || {};

          return (
            <>
              <Form.Item
                key={`${optionTeamPlayer.name}-${optionTeamPlayer.teamPlayerCount}-gameOptionEnableChargePrice`}
                // @ts-ignore
                label={`设置${optionTeamPlayer.name}价格`}
                name={
                  id
                    ? [
                        id,
                        optionTeamPlayer.name,
                        optionTeamPlayer.teamPlayerCount,
                        'selectedGameOptionEnableChargePrice',
                      ]
                    : [
                        optionTeamPlayer.name,
                        optionTeamPlayer.teamPlayerCount,
                        'selectedGameOptionEnableChargePrice',
                      ]
                }
                initialValue={gameOptionEnableChargePrice}
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={gameOptionEnableChargePrice}
                  onChange={(checked) => {
                    if (settings) {
                      const newGameOptionArcheryPriceSettings =
                        gameOptionArcheryPriceSettings.map((s: any) => {
                          if (
                            s.name === optionTeamPlayer.name &&
                            s.teamPlayerCount ===
                              optionTeamPlayer.teamPlayerCount
                          ) {
                            return {
                              ...s,
                              gameOptionEnableChargePrice: checked,
                            };
                          } else {
                            return s;
                          }
                        });

                      onChangeArcheryPriceSettings(
                        newGameOptionArcheryPriceSettings
                      );
                    } else {
                      const newGameOptionArcheryPriceSettings = [
                        ...gameOptionArcheryPriceSettings,
                        {
                          name: optionTeamPlayer.name,
                          teamPlayerCount: optionTeamPlayer.teamPlayerCount,
                          gameOptionEnableChargePrice: checked,
                        },
                      ];

                      onChangeArcheryPriceSettings(
                        newGameOptionArcheryPriceSettings
                      );
                    }
                  }}
                />
              </Form.Item>
              {gameOptionEnableChargePrice ? (
                <>
                  <Form.Item
                    key={`${optionTeamPlayer.name}-${optionTeamPlayer.teamPlayerCount}-gameOptionPrice`}
                    className="NestItemOptionNewModalFormPrice NestItemOptionNewModalFormItem"
                    label={`非注册${playerText}价格（元）`}
                    name={
                      id
                        ? [
                            id,
                            optionTeamPlayer.name,
                            optionTeamPlayer.teamPlayerCount,
                            'selectedGameOptionPrice',
                          ]
                        : [
                            optionTeamPlayer.name,
                            optionTeamPlayer.teamPlayerCount,
                            'selectedGameOptionPrice',
                          ]
                    }
                    initialValue={gameOptionPrice}
                    rules={[
                      {
                        required: true,
                        message: `非注册${playerText}价格不能为空`,
                      },
                      () => ({
                        validator(_, value) {
                          if (typeof value === 'number' && value <= 0) {
                            return Promise.reject(
                              new Error(`非注册${playerText}价格不能为0.00`)
                            );
                          }

                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      addonBefore="￥"
                      placeholder="356.50"
                      controls={false}
                      min={0}
                      precision={2}
                      value={gameOptionPrice}
                      onChange={(value: any) => {
                        const newGameOptionArcheryPriceSettings =
                          gameOptionArcheryPriceSettings.map((s: any) => {
                            if (
                              s.name === optionTeamPlayer.name &&
                              s.teamPlayerCount ===
                                optionTeamPlayer.teamPlayerCount
                            ) {
                              return {
                                ...s,
                                gameOptionPrice: value,
                              };
                            } else {
                              return s;
                            }
                          });

                        onChangeArcheryPriceSettings(
                          newGameOptionArcheryPriceSettings
                        );
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    key={`${optionTeamPlayer.name}-${optionTeamPlayer.teamPlayerCount}-gameOptionAssociationPlayerPrice`}
                    className="NestItemOptionNewModalFormPrice NestItemOptionNewModalFormItem"
                    label={`注册${playerText}价格（元）`}
                    initialValue={gameOptionAssociationPlayerPrice}
                    name={
                      id
                        ? [
                            id,
                            optionTeamPlayer.name,
                            optionTeamPlayer.teamPlayerCount,
                            'selectedGameOptionAssociationPlayerPrice',
                          ]
                        : [
                            optionTeamPlayer.name,
                            optionTeamPlayer.teamPlayerCount,
                            'selectedGameOptionAssociationPlayerPrice',
                          ]
                    }
                    rules={[
                      {
                        required: true,
                        message: `注册${playerText}价格不能为空`,
                      },
                      () => ({
                        validator(_, value) {
                          if (typeof value === 'number' && value <= 0) {
                            return Promise.reject(
                              new Error(`注册${playerText}价格不能为0.00`)
                            );
                          }

                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      addonBefore="￥"
                      placeholder="356.50"
                      controls={false}
                      min={0}
                      precision={2}
                      value={gameOptionAssociationPlayerPrice}
                      onChange={(value: any) => {
                        const newGameOptionArcheryPriceSettings =
                          gameOptionArcheryPriceSettings.map((s: any) => {
                            if (
                              s.name === optionTeamPlayer.name &&
                              s.teamPlayerCount ===
                                optionTeamPlayer.teamPlayerCount
                            ) {
                              return {
                                ...s,
                                gameOptionAssociationPlayerPrice: value,
                              };
                            } else {
                              return s;
                            }
                          });

                        onChangeArcheryPriceSettings(
                          newGameOptionArcheryPriceSettings
                        );
                      }}
                    />
                  </Form.Item>
                </>
              ) : null}
            </>
          );
        })}
      </Form>
    </Modal>
  );
};

CompetitionOptionSettingModal.displayName = 'CompetitionOptionSettingModal';

export default CompetitionOptionSettingModal;
