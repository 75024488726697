import React from 'react';
import { useSelector } from 'react-redux';
import { Skeleton } from 'antd';
import {
  getIsMerchantSubscriptionInitialized,
  getMerchantSubscriptionPricePlanType,
  getMerchantDisplayEndTime,
} from '../../../../../redux/app-v2/merchant/selectors';
import { PRICE_PLAN_TYPE_NAMES } from '../../utils/constants';
import './SubscriptionInfo.less';

const SubscriptionInfo = () => {
  const isMerchantSubscriptionInitialized = useSelector(
    getIsMerchantSubscriptionInitialized
  );
  const pricePlanType = useSelector(getMerchantSubscriptionPricePlanType);
  const displayEndTime = useSelector(getMerchantDisplayEndTime);

  return (
    <section className="flex flex-middle">
      {isMerchantSubscriptionInitialized ? (
        <>
          <h2 className="SubscriptionInfoCurrentPlan SubscriptionInfoText">
            {PRICE_PLAN_TYPE_NAMES[pricePlanType]}
          </h2>
          {displayEndTime && (
            <time className="SubscriptionInfoExpiredDate SubscriptionInfoText flex flex-middle">
              {displayEndTime} 到期
            </time>
          )}
        </>
      ) : (
        <Skeleton active paragraph={false} />
      )}
    </section>
  );
};

export default SubscriptionInfo;
