import React, { useEffect, useState } from 'react';
import { FileImageOutlined } from '@ant-design/icons';
import { message, notification, Upload } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import BraftEditor from 'braft-editor';
import { ContentUtils } from 'braft-utils';
import { RcFile, UploadChangeParam, UploadProps } from 'antd/es/upload';
import { RichEditorPropsType } from './types';
import 'braft-editor/dist/index.css';
import './RichEditor.less';

const RichEditor = ({
  uploadHeader,
  uploadUrl,
  content,
  setContent,
  savePoster = () => {},
  maxFileSize = 2,
  availableImageExtension = ['image/jpeg', 'image/png', 'image/jpg'],
}: RichEditorPropsType) => {
  const [editorState, setEditorState] = useState(
    BraftEditor.createEditorState(content)
  );

  useEffect(() => {
    if (content != editorState.toHTML()) {
      setEditorState(BraftEditor.createEditorState(content));
    }
  }, [content]);

  const beforeUpload = (file: RcFile) => {
    const isAvailableType = availableImageExtension.includes(file.type);
    const isLessThanMaxSize = file.size / 1024 / 1024 < maxFileSize;
    const errorMessage = [];

    if (!isAvailableType) {
      errorMessage.push('只可以上传 JPEG/JPG/PNG 三种格式的文件!');
    }

    if (!isLessThanMaxSize) {
      errorMessage.push(`图片必须小于${maxFileSize}MB!`);
    }

    if (errorMessage.length > 0) {
      notification.error({
        message: '上传图片失败',
        description: errorMessage.join(''),
      });

      return false;
    }

    return true;
  };

  const handleChange: UploadProps['onChange'] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    const { status, response } = info.file;
    const { error, data } = response || {};

    if (
      response?.hasOwnProperty('error') &&
      'request entity too large' in error.message
    ) {
      notification.error({
        message: '上传图片失败',
        description: `上传的图片不能超过${maxFileSize}MB!`,
      });

      return;
    }

    if (status === 'done') {
      message.success('上传成功');
      // Get this url from response in real world.
      setContent(
        ContentUtils.insertMedias(editorState, [
          {
            type: 'IMAGE',
            url: data.imageUrl,
          },
        ])
      );
    } else if (status === 'error') {
      notification.error({
        message: '上传图片失败',
        description: `服务器开小差了，请刷新重试`,
      });
    }
  };

  const extendControls: any = [
    {
      key: 'antd-uploader',
      type: 'component',
      component: (
        <Upload
          name="file"
          showUploadList={false}
          action={uploadUrl}
          headers={uploadHeader}
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          <button
            type="button"
            className="control-item button upload-button"
            data-title="插入图片"
          >
            <FileImageOutlined />
          </button>
        </Upload>
      ),
    },
  ];

  const handleEditorChange = (editorState: any) => {
    setEditorState(editorState);
    setContent(editorState.toHTML());
  };

  const controls: any = ['media', 'link', 'hr'];

  return (
    <BraftEditor
      className="richEditor"
      excludeControls={controls}
      extendControls={extendControls}
      value={editorState || '<p>请输入内容</p>'}
      onChange={handleEditorChange}
      onSave={savePoster}
    />
  );
};

RichEditor.displayName = 'RichEditor';

export default RichEditor;
