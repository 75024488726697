import { createSlice } from '@reduxjs/toolkit';

const initialState: {
  verifyEmployeeModalVisible: boolean;
  actionName: string;
} = {
  verifyEmployeeModalVisible: false,
  actionName: '',
};

export const { reducer, actions } = createSlice({
  name: 'player/list',
  initialState,
  reducers: {
    showVerifyEmployeeModal: (state) => {
      state.verifyEmployeeModalVisible = true;
    },
    hideVerifyEmployeeModal: (state) => {
      state.verifyEmployeeModalVisible = false;
    },
    setActionName: (state, { payload }) => {
      state.actionName = payload;
    },
  },
  extraReducers: {},
});

export default reducer;
