export const STATUS_OPTIONS = [
  { label: '显示', value: 'show' },
  { label: '隐藏', value: 'hidden' },
];

export const RANKING_OPTIONS = [
  { label: '分值高排名高', value: true },
  { label: '分值低排名高', value: false },
];

export const AVAILABLE_STORES = [
  {
    label: '静安寺店，松江店',
    value: 'stores',
  },
];

export const INTERVAL_OPTIONS = [
  { label: '日榜', value: 'daily' },
  { label: '周榜', value: 'weekly' },
  { label: '月榜', value: 'monthly' },
  { label: '永久榜', value: 'allTime' },
];
