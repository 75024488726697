export const ROLES = {
  Owner: 'Owner',
  Manager: 'Manager',
  StoreManager: 'StoreManager',
  Crew: 'Crew',
  Operation: 'Operation',
  Judge: 'Judge',
};

export const MERCHANT_ID_REGEX = /^[a-z]{1,10}$/g;

export const PHONE_NUMBER_REGEX =
  /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;

export const MERCHANT_SUBSCRIPTION_PRICE_PLANS = {
  basic: 'basic',
  elite: 'elite',
  mega: 'mega',
};

export const MERCHANT_SUBSCRIPTION_STATUS = {
  trial: 'trial',
  active: 'active',
  expired: 'expired',
};

export const MERCHANT_TYPE = {
  association: 'association',
  club: 'club',
};

export const CURRENCY_SYMBOLS: { [currency: string]: string } = {
  CNY: '¥',
};

export const BUSINESS_TYPES = {
  Complex: 'complex',
  Archery: 'archery',
  RockClimbing: 'rockClimbing',
  Gym: 'gym',
  Basketball: 'basketball',
  Badmiton: 'badmiton',
  Football: 'football',
  Swimming: 'swimming',
  Pingpong: 'pingpong',
  Tennis: 'tennis',
  Baseball: 'baseball',
  Golf: 'golf',
  Pool: 'pool',
  Squash: 'squash',
  Fenching: 'fenching',
  Taekwondo: 'taekwondo',
  Boxing: 'boxing',
  Bowling: 'bowling',
};

export const BUSINESS = [
  { value: BUSINESS_TYPES.Complex, label: '综合体育' },
  { value: BUSINESS_TYPES.Archery, label: '射箭' },
  { value: BUSINESS_TYPES.RockClimbing, label: '攀岩' },
  { value: BUSINESS_TYPES.Gym, label: '健身' },
  { value: BUSINESS_TYPES.Basketball, label: '篮球' },
  { value: BUSINESS_TYPES.Badmiton, label: '羽毛球' },
  { value: BUSINESS_TYPES.Football, label: '足球' },
  { value: BUSINESS_TYPES.Swimming, label: '游泳' },
  { value: BUSINESS_TYPES.Pingpong, label: '乒乓球' },
  { value: BUSINESS_TYPES.Tennis, label: '网球' },
  { value: BUSINESS_TYPES.Baseball, label: '棒垒球' },
  { value: BUSINESS_TYPES.Golf, label: '高尔夫' },
  { value: BUSINESS_TYPES.Pool, label: '台球' },
  { value: BUSINESS_TYPES.Squash, label: '壁球' },
  { value: BUSINESS_TYPES.Fenching, label: '击剑' },
  { value: BUSINESS_TYPES.Taekwondo, label: '跆拳道' },
  { value: BUSINESS_TYPES.Boxing, label: '拳击' },
  { value: BUSINESS_TYPES.Bowling, label: '保龄球' },
];
