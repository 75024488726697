import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import moment from 'moment';
import { setData } from '.';
import { RootState } from '../../../../../../redux/store';
import { getMerchantName } from '../../../../../../redux/app-v2/merchant/selectors';
import { getOrder, putOrder } from './api-request';
import { getAssetsDictionary, getMerchandiseDic } from '../../redux/selectors';

const statusNameMap: { [key: string]: string } = {
  created: '创建',
  paid: '已付款',
  finished: '已完成',
  cancelled: '已取消',
  failed: '失败',
};

const exchangeByTypeMap: { [key: string]: string } = {
  assets: '权益',
  voucher: '券码',
};

export const requestData = createAsyncThunk(
  'market/order/details/requestData',
  async (
    param: {
      id: string;
    },
    { dispatch, getState }
  ) => {
    try {
      const state = getState();
      const merchantName = getMerchantName(state);
      const { id } = param;
      const data: any = await getOrder({ merchantName, id });
      const assetsDictionary: { [key: string]: string } = getAssetsDictionary(
        getState() as RootState
      );
      const merchandiseDic: { [key: string]: string } = getMerchandiseDic(
        getState() as RootState
      );
      if (data.message === 'OK') {
        let item = data.data.items;
        const processedItems = item.map((data: any) => {
          let newData = data;
          newData.exchangeByType =
            exchangeByTypeMap[data.exchangeByType] +
            '/' +
            assetsDictionary[data.exchangeByAssetsTypeId];
          newData.exchangedType = merchandiseDic[data.merchandiseId];
          return newData;
        });
        data.data['items'] = processedItems;
        data.data['createdTime'] = moment(data.createdTime).format(
          'YYYY/MM/DD HH:mm:ss'
        );
        data.data['status'] = statusNameMap[data.data['status']];
        dispatch(setData(data.data));
      } else {
        message.error('获取订单详情失败');
      }
    } catch (e) {
      console.error('request table data error', e);
      throw e;
    }
  }
);

export const cancelTheOrder = createAsyncThunk(
  'market/order/details/cancelOrder',
  async (
    param: {
      id: string;
    },
    { dispatch, getState }
  ) => {
    try {
      const state = getState();
      const merchantName = getMerchantName(state);
      const { id } = param;
      const res: any = await putOrder({
        merchantName,
        id,
        status: 'cancelled',
      });
      if (res.message === 'OK') {
        message.success('删除订单成功');
        dispatch(requestData({ id }));
      } else {
        message.error('删除订单失败');
      }
    } catch (e) {
      console.error('request table data error', e);
      throw e;
    }
  }
);
