import { get } from '../../../../service/api';

export const getBusinessType = async (options?: object) => {
  try {
    const result = await get(`/business-types`, {}, {}, options);

    return result;
  } catch (e) {
    throw new Error(typeof e === 'string' ? e : JSON.stringify(e));
  }
};
