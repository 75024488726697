// ！！！deprecation file in future
// ！！！please don't add anything here
import umbrella from 'umbrella-storage';
import { deleteRequest, get, patch } from './api';
import {
  GET_TiCKT_LIST,
  CHECK_TiCKT,
  GET_WX_QR,
  DELETE_POSTER,
  GET_POSTER,
  GET_ASSETS,
  GET_VOUCHER_SEARCH_LIST,
} from './apiURL';

export const getWxQRCode = async (param: any) => {
  const res = await get(
    GET_WX_QR(umbrella.getLocalStorage('merchant')?.merchantName || ''),
    param,
    { responseType: 'blob' }
  );
  return res;
};

export const getTicketList = async (param: any) => {
  const res = await get(
    GET_TiCKT_LIST(umbrella.getLocalStorage('merchant')?.merchantName || ''),
    param
  );
  return res;
};

export const checkTicket = async (param: any) => {
  const res = await patch(
    CHECK_TiCKT(umbrella.getLocalStorage('merchant')?.merchantName || '') +
      param.operationId,
    param.data
  );
  return res;
};

export const getPoster = async (id: string) => {
  const res = await get(
    GET_POSTER(umbrella.getLocalStorage('merchant')?.merchantName || '', id),
    ''
  );
  return res;
};

export const deletePoster = async (id: string) => {
  const res = await deleteRequest(
    DELETE_POSTER(umbrella.getLocalStorage('merchant')?.merchantName || '', id),
    {},
    { isShow: false }
  );
  return res;
};

export const getAssets = async () => {
  const res = await get(
    GET_ASSETS(umbrella.getLocalStorage('merchant')?.merchantName || ''),
    {},
    {},
    { isShow: false }
  );
  return res;
};

export const searchVoucherList = async (param: any) => {
  const res = await get(
    GET_VOUCHER_SEARCH_LIST(
      umbrella.getLocalStorage('merchant')?.merchantName || ''
    ),
    param
  );
  return res;
};
