import { createAsyncThunk } from '@reduxjs/toolkit';
import { getBusinessType } from './api-request';

/**
 * Business type get
 */
export const loadBusinessType = createAsyncThunk(
  'register/loadBusinessType',
  async () => {
    try {
      const result: any = await getBusinessType();

      return result.data;
    } catch (e) {
      throw e;
    }
  }
);
