import { createAsyncThunk } from '@reduxjs/toolkit';
import { loadStoresV2 as loadEntitiesStores } from '../../../redux/entities/stores/thunks';
import { ALL_STORES_ENTITIES } from '../../../redux/entities/stores/constant';
import {
  getStoreListPageNumber,
  getStoreListPageSize,
  getSelectedStoreId,
  getIsSelectedAllStoresDashboard,
} from './selectors';
import { getIsManager, getIsOwner } from '../../../redux/app-v2/user/selectors';
import { getReport } from './api-request';
import { errorMessage } from '../../../common/utils/feedback';

export const loadReport: any = createAsyncThunk(
  'dashboard/loadReport',
  async (_, { getState }) => {
    try {
      const state: any = getState();

      const storeId = getSelectedStoreId(state);
      const isSelectedAllStoresDashboard =
        getIsSelectedAllStoresDashboard(state);

      // If selected all stores, we will not set store id query for report API
      const { data }: any = await getReport(
        isSelectedAllStoresDashboard ? '' : storeId
      );

      return data;
    } catch (e) {
      errorMessage('加载状态概况失败');
    }
  }
);

export const loadDashboardStores: any = createAsyncThunk(
  'dashboard/loadDashboardStores',
  async (_, { dispatch, getState }) => {
    try {
      const state: any = getState();
      const isManager = getIsManager(state);
      const isOwner = getIsOwner(state);
      const offset: number =
        getStoreListPageNumber(state) * getStoreListPageSize(state);
      const limit: number = getStoreListPageSize(state);

      const { payload }: any = await dispatch(
        loadEntitiesStores({ offset, limit })
      );

      if (isManager || isOwner) {
        payload.ids = [ALL_STORES_ENTITIES.id, ...payload.ids];
      }

      return payload;
    } catch (e) {
      errorMessage('加载店铺列表失败');
    }
  }
);
