export const downloadImage = async function downloadImage(url: any) {
  try {
    // 使用 fetch 获取图片数据
    const response = await fetch(url);
    const blob = await response.blob();

    // 创建一个图片元素
    const img = new Image();

    // 使用 URL.createObjectURL 将 Blob 转换为图片的 URL
    img.src = URL.createObjectURL(blob);

    // 等待图片加载完成
    await new Promise((resolve) => {
      img.onload = resolve;
    });

    // 获取图片的宽度和高度
    const width = img.width;
    const height = img.height;

    // 创建一个 canvas 元素用于绘制图片
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx: any = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, width, height);

    // 获取绘制后的图片的 Base64 编码
    const base64 = canvas.toDataURL('image/png');

    return { base64, width, height };
  } catch (error) {
    console.error('Error downloading image:', error);
    throw error;
  }
};
