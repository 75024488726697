import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMerchantName } from '../../../../../redux/app-v2/merchant/selectors';
import { loadPosterList } from '../../../redux/common/thunks';
import {
  getActivityListPageNumber,
  getActivityListPageSize,
} from './selectors';
import {
  getActivities,
  postActivity,
  deleteActivity,
  postPoster,
} from './api-request';

export const loadActivityList = createAsyncThunk(
  'activities/loadActivityList',
  async (_, { getState }) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);
      const offset: number =
        getActivityListPageNumber(state) * getActivityListPageSize(state);
      const limit: number = getActivityListPageSize(state);

      const result = await getActivities({ merchantName, offset, limit });

      return result;
    } catch (e) {
      throw e;
    }
  }
);

export const removeActivity = createAsyncThunk(
  'activities/deleteActivity',
  async (id: string, { dispatch, getState }) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);

      await deleteActivity({ merchantName, id });
      await dispatch(loadActivityList());

      return id;
    } catch (e) {
      throw e;
    }
  }
);

export const changeActivityListPageNumber = createAsyncThunk(
  'activities/changeActivityListPageNumber',
  async (pageNumber: number) => pageNumber
);

export const changeActivityListPageSize = createAsyncThunk(
  'activities/changeActivityListPageSize',
  async (pageSize: number) => pageSize
);

export const updateActivityListPagination = createAsyncThunk(
  'activities/updateActivityListPagination',
  async (
    { pageNumber, pageSize }: { pageNumber?: number; pageSize?: number },
    { dispatch }
  ) => {
    try {
      if (typeof pageNumber === 'number' && pageNumber >= 0) {
        await dispatch(changeActivityListPageNumber(pageNumber));
      } else if (typeof pageSize === 'number' && pageSize > 0) {
        await dispatch(changeActivityListPageSize(pageSize));
      }

      await dispatch(loadActivityList());
    } catch (e) {
      throw e;
    }
  }
);

export const createActivity = createAsyncThunk(
  'activities/createActivity',
  async (
    {
      activity,
      poster,
    }: {
      activity: {
        storeId?: string;
        name: string;
        description: string;
        startTime: string;
        enrollDeadline: string;
        maxPlayers: number;
        posterId?: string;
      };
      poster?: {
        storeId?: string;
        posterName: string;
        content: any;
        isEnabled: boolean;
        imageUrl?: string | null;
        type?: string | null;
      };
    },
    { dispatch, getState }
  ) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);

      if (poster) {
        const posterResult: any = await postPoster({
          merchantName,
          poster,
        });

        activity.posterId = posterResult.data?.id || '';
        dispatch(loadPosterList());
      }

      await postActivity({ merchantName, activity });

      await dispatch(loadActivityList());
    } catch (e) {
      throw e;
    }
  }
);
