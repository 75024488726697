export const priceConvertToIntegerCents = (
  price: string | number,
  accurate: number = 2
) =>
  price
    ? Math.round(parseFloat(String(price)) * Math.pow(10, accurate)) /
      Math.pow(10, 0)
    : price;

export const priceRoundFractionalToDollar = (price: number) =>
  price ? price / 100 : price;
