import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../common/utils/types';
import { getFormatLocalTime } from '../../../../../common/utils/time-lib';
import { getStores } from '../../../../../redux/entities/stores/selectors';
import { getUserAuthorization } from '../../../../../redux/app-v2/user/selectors';
import { getMerchantName } from '../../../../../redux/app-v2/merchant/selectors';

export { getUserAuthorization, getMerchantName };

export const getOriginalActivityList = (state: RootState) =>
  state.activities.list.data;

export const getActivityList = createSelector(
  getOriginalActivityList,
  getStores,
  (activities, stores) => {
    return activities.map(
      (activity: {
        storeId?: string | undefined;
        startTime: string | undefined;
        enrollDeadline: string | undefined;
      }) => {
        const { storeId = '', startTime, enrollDeadline } = activity;
        const { displayName: storeName = '' } =
          stores.find((store) => store.id === storeId) || {};

        return {
          ...activity,
          storeName,
          validTimeFrom: getFormatLocalTime(startTime, 'YYYY/MM/DD HH:mm:ss'),
          validTimeTo: getFormatLocalTime(
            enrollDeadline,
            'YYYY/MM/DD HH:mm:ss'
          ),
        };
      }
    );
  }
);

export const getActivityListPageNumber = (state: RootState) =>
  state.activities.list.pageNumber;

export const getActivityListPageSize = (state: RootState) =>
  state.activities.list.pageSize;

export const getActivityItemsTotal = (state: RootState) =>
  state.activities.list.itemsTotal;
