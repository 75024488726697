import {
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Result,
  Select,
  message,
  Row,
  Col,
  Switch,
  Alert,
} from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import moment from 'moment';
import { getIsStoreManager } from '../../../../../../redux/app-v2/user/selectors';
import { publicIp } from '../../../../../../service/apiIP';
import { AppDispatch } from '../../../../../../redux/store';
import {
  getStoreList,
  getPosterList,
} from '../../../../redux/common/selectors';
import { loadPosterList } from '../../../../redux/common/thunks';
import { getUserAuthorization, getMerchantName } from '../../redux/selectors';
import { createActivity } from '../../redux/thunks';
import RichEditor from '../../../../../../common/components/RichEditor';
import './NewActivity.less';

const { TextArea } = Input;

const NewPoster: React.FC<{
  visible: boolean;
  setVisible: (visibleValue: boolean) => void;
  posterName: string;
  setPosterName: (posterNameValue: string) => void;
  content: any;
  setContent: (contentValue: any) => void;
}> = ({
  visible,
  setVisible,
  posterName,
  setPosterName,
  content,
  setContent,
}) => {
  const userAuthorization = useSelector(getUserAuthorization);
  const merchantName = useSelector(getMerchantName);

  return (
    <>
      <div className="flex flex-middle flex-space-between">
        <span className="formItemLabel">是否新增海报</span>
        <Switch
          checked={visible}
          onChange={() => {
            setVisible(!visible);
          }}
        />
      </div>
      {visible ? (
        <>
          <Form.Item
            className="formItem"
            label="海报标题"
            name="posterName"
            rules={[{ required: true, message: '请填写海报标题' }]}
          >
            <Input
              value={posterName}
              name="posterName"
              onChange={(e) => {
                setPosterName(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item className="formItem" label="海报说明">
            <Alert
              className="NewActivityNewPosterAlert"
              type="warning"
              message="海报内容主要在手机端显示使用，为了加载快速，请使用尽量小的图片，为了整洁美观，建议图片宽度不超过350px，文档总大小请不要超过2M！"
            />
            <RichEditor
              uploadHeader={{
                Authorization: userAuthorization,
              }}
              uploadUrl={`${publicIp}/merchants/${merchantName}/posters/pic`}
              content={content}
              setContent={(content) => {
                setContent(content);
              }}
              savePoster={() => {}}
            />
          </Form.Item>
        </>
      ) : null}
    </>
  );
};

NewPoster.displayName = 'NewPosters';

const NewActivity: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const storeList = useSelector(getStoreList);
  const posterList = useSelector(getPosterList);
  const isStoreManager = useSelector(getIsStoreManager);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [startTime, setStartTime] = useState<any>(moment().endOf('day'));
  const [enrollDeadline, setEnrollDeadline] = useState<any>(
    moment().startOf('day')
  );
  const [selectedStore, setSelectedStore] = useState<any>({});
  const [maxPlayers, setMaxPlayers] = useState(10);
  const [posterId, setPosterId] = useState(null);
  const [newPosterVisible, setNewPosterVisible] = useState(false);
  const [posterName, setPosterName] = useState('');
  const [posterContent, setPosterContent] = useState(null);
  const [createdNewActivity, setCreatedNewActivity] = useState(false);
  const onCloseModal = useCallback(() => {
    setName('');
    setDescription('');
    setStartTime(moment().endOf('day'));
    setEnrollDeadline(moment().startOf('day'));
    setSelectedStore({});
    setMaxPlayers(10);
    setPosterId(null);
    setCreatedNewActivity(false);
    setNewPosterVisible(false);
    setPosterName('');
    setPosterContent(null);
    setIsModalVisible(false);

    form.setFieldsValue({
      name: '',
      description: '',
      startTime: moment().startOf('day'),
      enrollDeadline: moment().endOf('day'),
      selectedStore: {},
      maxPlayers: 10,
      posterId: null,
      posterName: '',
    });
  }, []);

  useMount(async () => {
    dispatch(loadPosterList());
  });

  return (
    <>
      <div className="NewActivityButtonContainer">
        <Button
          className="NewActivityButton"
          onClick={() => {
            setIsModalVisible(true);
          }}
        >
          <PlusCircleOutlined className="NewActivityButtonOutlinedIcon" />
          <span className="NewActivityButtonText">新增活动</span>
        </Button>
      </div>

      <Modal
        title="新增活动"
        visible={isModalVisible}
        onOk={async () => {
          try {
            await form.validateFields();

            const activity: any = {
              name,
              description,
              startTime: new Date(startTime.utc().format()).getTime(),
              enrollDeadline: new Date(enrollDeadline.utc().format()).getTime(),
              maxPlayers,
            };
            const poster: any = {
              posterName,
              content: posterContent,
              isEnabled: true,
            };

            if (selectedStore?.id) {
              activity.storeId = selectedStore.id;
            }

            if (newPosterVisible) {
              await dispatch(createActivity({ activity, poster }));
            } else {
              if (posterId) {
                activity.posterId = posterId;
              }

              await dispatch(createActivity({ activity }));
            }

            onCloseModal();
          } catch (e) {
            message.error('添加活动失败');
          }
        }}
        onCancel={onCloseModal}
        // If footer is undefined, the DOM will use ant.design default modal footer
        footer={createdNewActivity ? null : undefined}
      >
        {createdNewActivity ? (
          <>
            <Result
              className="NewActivityResult"
              status="success"
              title="成功创建活动"
            />
          </>
        ) : (
          <Form
            layout="vertical"
            form={form}
            name="newActivityForm"
            initialValues={{
              name,
              description,
              maxPlayers,
              startTime,
              enrollDeadline,
              selectedStore,
              posterId,
              posterName,
            }}
          >
            <Form.Item
              className="formItem"
              label="活动名称"
              name="name"
              rules={[{ required: true, message: '请填写活动名称' }]}
            >
              <Input
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              className="formItem"
              label="活动说明"
              name="description"
              rules={[{ required: true, message: '请填写活动说明' }]}
            >
              <TextArea
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </Form.Item>
            <Row justify="space-between" gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  className="formItem"
                  label="参加人数限制"
                  name="maxPlayers"
                  rules={[{ required: true, message: '请填写参加人数限制' }]}
                >
                  <InputNumber
                    className="forItemContent"
                    min={1}
                    value={maxPlayers}
                    onChange={(value) => {
                      setMaxPlayers(value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                {!isStoreManager ? (
                  <Form.Item className="formItem" label="所在门店">
                    <Select
                      placeholder="选择一个门店"
                      value={selectedStore.id || '全部门店'}
                      onChange={(id) => {
                        const store: any = storeList.find(
                          (store: any) => store.id === id
                        );
                        setSelectedStore(store);
                      }}
                    >
                      {storeList.map((store: any) => (
                        <Select.Option key={store.id} value={store.id}>
                          {store.displayName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : null}
              </Col>
            </Row>
            <Row justify="space-between" gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item className="formItem" label="报名截止日期">
                  <DatePicker
                    showTime
                    className="forItemContent"
                    format="YYYY/MM/DD HH:mm:ss"
                    placeholder="报名截止日期"
                    value={enrollDeadline}
                    onChange={(value: any) => {
                      setEnrollDeadline(value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className="formItem" label="活动开始日期">
                  <DatePicker
                    showTime
                    className="forItemContent"
                    format="YYYY/MM/DD HH:mm:ss"
                    placeholder="活动开始日期"
                    value={startTime}
                    onChange={(value: any) => {
                      setStartTime(value);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <NewPoster
              visible={newPosterVisible}
              setVisible={setNewPosterVisible}
              posterName={posterName}
              setPosterName={setPosterName}
              content={posterContent}
              setContent={setPosterContent}
            />
            {newPosterVisible ? null : (
              <Form.Item className="formItem" label="活动海报">
                <Select
                  placeholder="选择一个活动海报"
                  value={posterId}
                  onChange={(value) => {
                    setPosterId(value);
                  }}
                >
                  {posterList.map((poster: any) => (
                    <Select.Option key={poster.id} value={poster.id}>
                      {poster.posterName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Form>
        )}
      </Modal>
    </>
  );
};

export default NewActivity;
