import _get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../../service/constants';
import { getEmployeesMapping } from '../../../../../redux/entities/employees/selectors';
import { getStoresMapping } from '../../../../../redux/entities/stores/selectors';
import {
  getIsManager,
  getIsOwner,
  getIsStoreManager,
  getUserBelongToStoreId,
  getUserId,
} from '../../../../../redux/app-v2/user/selectors';
import { NewEmployeeModalStore } from '../components/NewEmployeeModal/types';

export const getEmployeeIds = (state: {
  employee: { list: { ids: string[] } };
}) => _get(state.employee, 'list.ids', []);

export const getNewEmployeeStoreIds = (state: {
  employee: { newEmployee: { storeIds: string[] } };
}) => _get(state.employee, 'list.newEmployee.storeIds', []);

export const getIsNewEmployeeModalShown = (state: {
  employee: { newEmployee: { showModal: boolean } };
}) => _get(state.employee, 'list.newEmployee.showModal', []);

export const getEmployeeListPageNumber = (state: any) =>
  state.employee.list.pageNumber;

export const getEmployeeListPageSize = (state: any) =>
  state.employee.list.pageSize;

export const getEmployeeListTotalEmployees = (state: any) =>
  state.employee.list.totalEmployees;

export const getTransferEmployeeToOwnerStatus = (state: {
  employee: { list: { transferOwner: { transferStatus: string } } };
}) =>
  _get(
    state.employee,
    'list.transferOwner.transferStatus',
    API_REQUEST_STATUS.IDLE
  );

export const getCreatingOtpStatus = (state: {
  employee: { list: { transferOwner: { creatingOtpStatus: string } } };
}) =>
  _get(
    state.employee,
    'list.transferOwner.creatingOtpStatus',
    API_REQUEST_STATUS.IDLE
  );

export const getTransferEmployeeToOwnerModalShown = (state: {
  employee: { list: { transferOwner: { showModal: boolean } } };
}) => _get(state.employee, 'list.transferOwner.showModal', false);

export const getTransferOwnerEmployeeIds = (state: {
  employee: { list: { transferOwner: { employeeSearchIds: string[] } } };
}) => _get(state.employee, 'list.transferOwner.employeeSearchIds', null);

export const getEmployeeIdForTransferAsOwner = (state: {
  employee: { list: { transferOwner: { employeeId: string } } };
}) => _get(state.employee, 'list.transferOwner.employeeId', null);

export const getOtp = (state: {
  employee: { list: { transferOwner: { otp: string } } };
}) => _get(state.employee, 'list.transferOwner.otp', '');

export const getEmployeeList = createSelector(
  getEmployeesMapping,
  getEmployeeIds,
  getUserId,
  (employeesMapping, ids, userId) => {
    const filteredIds = ids.filter((id: string) => employeesMapping[id]);

    return filteredIds.map((id: string) => {
      return {
        ...employeesMapping[id],
        // BE should response a store name on employee item
        disabledDismissOperate: userId === id,
      };
    });
  }
);

export const getEmployeeListParameters = createSelector(
  getEmployeeListPageNumber,
  getEmployeeListPageSize,
  getIsStoreManager,
  getUserBelongToStoreId,
  (pageNumber, pageSize, isStoreManager, userBelongToStoreId) => {
    const offset: number = pageNumber * pageSize;
    const limit: number = pageSize;
    const parameters = {
      offset,
      limit,
      storeId: '',
    };

    if (userBelongToStoreId && isStoreManager) {
      parameters.storeId = userBelongToStoreId;
    }

    return parameters;
  }
);

// Update from routes, some routes will be removed not manager
export const getIsEmployeeAdminModalVisible = createSelector(
  getIsManager,
  getIsOwner,
  (isManager, isOwner) => isManager || isOwner
);

export const getNewEmployeeStores = createSelector(
  getStoresMapping,
  getNewEmployeeStoreIds,
  (storesMapping, newEmployeeStoreIds) =>
    newEmployeeStoreIds.map((storeId: string) => {
      const { city, district, sequenceNum, displayName } =
        storesMapping[storeId] || {};

      return {
        id: storeId,
        city,
        district,
        sequenceNum,
        displayName,
      };
    })
);

export const getDisplayStoreOptions = createSelector(
  getNewEmployeeStores,
  getIsStoreManager,
  getUserBelongToStoreId,
  (newEmployeeStores, isStoreManager, userBelongToStoreId) =>
    newEmployeeStores.map((store: NewEmployeeModalStore) => ({
      label: `${store.city || ''}${store.district || ''}${store.displayName}`,
      value: store.id,
      disabled: isStoreManager ? store.id !== userBelongToStoreId : false,
      storeSeqNum: store.sequenceNum,
    }))
);

export const getSearchEmployeeOptions = createSelector(
  getTransferOwnerEmployeeIds,
  getEmployeesMapping,
  (getTransferOwnerEmployeeIds, employeesMapping) =>
    getTransferOwnerEmployeeIds.map((id: string) => {
      const { name, id: employeeId } = employeesMapping[id] || {};

      return {
        value: employeeId,
        label: name,
      };
    })
);

export const getEmployeeInfoForTransferAsOwner = createSelector(
  getEmployeesMapping,
  getEmployeeIdForTransferAsOwner,
  (employeesMapping, employeeIdForTransferAsOwner) => {
    const { name, storeId } =
      employeesMapping[employeeIdForTransferAsOwner] || {};

    return {
      id: employeeIdForTransferAsOwner,
      name,
      storeId,
      canOperateSensitiveAssets: true,
      dimission: null,
    };
  }
);

export const getIsEmployeeToOwnerTransferring = createSelector(
  getTransferEmployeeToOwnerStatus,
  (transferEmployeeToOwnerStatus) =>
    transferEmployeeToOwnerStatus === API_REQUEST_STATUS.PENDING
);

export const getIsCreatedOtpStatusPending = createSelector(
  getCreatingOtpStatus,
  (creatingOtpStatus) => creatingOtpStatus === API_REQUEST_STATUS.PENDING
);
