import { createSlice } from '@reduxjs/toolkit';
import { isNil } from 'lodash';

type EmployeeType = {
  id: string;
  name: string;
  phone: string;
  dimission: string;
  birthday: string;
  profileImageUrl: string;
  merchantName: string;
  storeId: string;
  storeName?: string;
  roleId: string;
  roleName?: string;
};

type EmployeeMappingType = {
  [key: string]: EmployeeType;
};

const initialState: EmployeeMappingType = {};

export const { reducer, actions } = createSlice({
  name: 'entities/employees',
  initialState,
  reducers: {
    updateEmployeeMapping: (state: any, action) => {
      const list = action.payload;
      const employees: any = state;

      list.forEach((employee: { id: string }) => {
        const { id } = employee || {};
        const { storeId, storeName, roleId, roleName }: any = employee || {};

        if (state[id]) {
          state[id] = {
            ...state[id],
            ...employee,
            storeName: isNil(storeId) || storeId === '' ? undefined : storeName,
            roleName: isNil(roleId) || roleId === '' ? undefined : roleName,
          };
        } else if (id) {
          state[id] = employee;
        }
      });

      return employees;
    },
    updateEmployeeById: (state, action) => {
      const employee = action.payload;
      const { id } = action.payload || {};

      if (state[id]) {
        state[id] = { ...state[id], ...employee };
      } else if (id) {
        state[id] = employee;
      }

      return state;
    },
  },
});

export default reducer;
