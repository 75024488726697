import { createSelector } from '@reduxjs/toolkit';
import { getUrlHash } from '../../../common/utils';
import { MERCHANT_SUBSCRIPTION_STATUS } from '../../../common/constants';
import {
  getFeaturesSetting,
  getIsFeaturesSettingInitialized,
} from '../../../redux/app-v2/featuresSetting/selectors';
import {
  getIsUserInitialized,
  getUserIsLoginStatus,
  getUserRoleName,
} from '../../../redux/app-v2/user/selectors';
import {
  getMerchantType,
  getMerchantSubscriptionStatus,
  getMerchantExpiredDays,
  getIsMerchantInfoInitialized,
  getMerchantBusiness,
} from '../../../redux/app-v2/merchant/selectors';

export const getIsMenuItemsInitialized = createSelector(
  getUserIsLoginStatus,
  getIsUserInitialized,
  getIsMerchantInfoInitialized,
  getIsFeaturesSettingInitialized,
  (
    userIsLogin,
    isUserInitialized,
    isMerchantInfoInitialized,
    isFeaturesSettingInitialized
  ) =>
    userIsLogin &&
    isUserInitialized &&
    isMerchantInfoInitialized &&
    isFeaturesSettingInitialized
);

/**
 * get menu items
 * @return {Array}
 */
export const getMenuOriginalItems = (state: {
  container: { menuItems: Array<any> };
}) => state.container.menuItems.map((item) => ({ ...item, id: item.key }));

export const getMenuItems = createSelector(
  getMerchantType,
  getMerchantBusiness,
  getMenuOriginalItems,
  getUserRoleName,
  getFeaturesSetting,
  getIsMenuItemsInitialized,
  (
    merchantType,
    businessType,
    menuOriginalItems,
    userRoleName,
    featuresSetting: any,
    isMenuItemsInitialized
  ) => {
    if (!isMenuItemsInitialized) {
      return [];
    }

    return menuOriginalItems.filter((item) => {
      const {
        dataIndex,
        isDefault,
        availableMerchantTypes,
        availableRoles,
        businessTypes,
      } = item;
      const isAvailableMerchantType =
        availableMerchantTypes.includes(merchantType);
      const isAvailableRole = availableRoles.includes(userRoleName);
      const isInFeaturesSetting = !!featuresSetting[dataIndex];

      if (businessTypes) {
        return isAvailableMerchantType && businessTypes.includes(businessType);
      }

      if (isDefault) {
        return isAvailableMerchantType && isAvailableRole;
      }

      return isAvailableMerchantType && isAvailableRole && isInFeaturesSetting;
    });
  }
);

export const getCurrentHash = (state: { container: { currentHash: string } }) =>
  state.container.currentHash;

export const getOriginalUserCenterItems = (state: {
  container: { userCenter: any };
}) => state.container.userCenter;

export const getUserCenterItems = createSelector(
  getUserRoleName,
  getMerchantType,
  getOriginalUserCenterItems,
  (userRoleName, merchantType, originalUserCenter) =>
    originalUserCenter.filter(
      (userCenterItem: {
        availableMerchantTypes: any;
        availableRoles: any;
      }) => {
        const { availableMerchantTypes, availableRoles } = userCenterItem;
        const isAvailableMerchantType =
          availableMerchantTypes.includes(merchantType);
        const isAvailableRole = availableRoles.includes(userRoleName);

        return isAvailableMerchantType && isAvailableRole;
      }
    )
);

export const getSelectedItems = createSelector(
  getMenuOriginalItems,
  getUserCenterItems,
  getCurrentHash,
  (menuOriginalItems, operationItems, currentHash) => {
    const selectedItems: any = [...menuOriginalItems, ...operationItems].filter(
      (item) => getUrlHash(/#/g, '', currentHash).startsWith(item.url)
    );

    return selectedItems.map((item: any) => item.key);
  }
);

export const getIsDisplayExpiredDays = createSelector(
  getMerchantSubscriptionStatus,
  getMerchantExpiredDays,
  (merchantSubscriptionStatus, merchantExpiredDays) => {
    if (
      merchantSubscriptionStatus === MERCHANT_SUBSCRIPTION_STATUS.trial &&
      merchantExpiredDays >= 0
    ) {
      return true;
    }

    if (
      merchantSubscriptionStatus === MERCHANT_SUBSCRIPTION_STATUS.active &&
      merchantExpiredDays >= 0 &&
      merchantExpiredDays <= 14
    ) {
      return true;
    }

    return false;
  }
);
