import { get } from '../../../../../service/api';

export const getSearchingOrderList = async (payload: {
  merchantName: string;
  from: number;
  to: number;
  offset: number;
  limit: number;
  status: string;
}) => {
  try {
    const { merchantName, ...params } = payload;
    const result = await get(
      `/merchants/${merchantName}/market/purchase-orders`,
      params,
      {},
      { isShow: false }
    );

    return result;
  } catch (e) {
    throw e;
  }
};

export const getAssets = async (payload: { merchantName: string }) => {
  try {
    const { merchantName } = payload;

    const result = await get(
      `/merchants/${merchantName}/assets/setting`,
      {},
      {},
      { isShow: false }
    );

    return result;
  } catch (e) {
    throw e;
  }
};
