import React from 'react';
import { Button, Tag } from 'antd';
import { useMount } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../common/utils/types';
import { UNPAID_STATUS } from '../../utils/constants';
import {
  getSubscriptionOrderListPageNumber,
  getSubscriptionOrderListPageSize,
  getSubscriptionOrderListTotalOrders,
  getSubscriptionOrderList,
  getSubscriptionShowHistoryAgreementModalVisibility,
  getHistoryAgreementContent,
} from '../../redux/selectors';
import { actions as subscriptionActions } from '../../redux';
import {
  loadHistoryAgreementById,
  loadSubscriptionOrderList,
  pollingSubscriptionOrderStatus,
  updateSubscriptionOrderListPageNumber,
  updateSubscriptionOrderListPageSize,
} from '../../redux/thunks';
import TableResponsiveFrame from '../../../../../common/components/tables/TableResponsiveFrame';
import './SubscriptionHistory.less';
import HistoryAgreement from '../../../../app/components/HistoryAgreement';

const SubscriptionHistory = () => {
  const dispatch = useDispatch<AppDispatch>();
  const pageNumber = useSelector(getSubscriptionOrderListPageNumber);
  const pageSize = useSelector(getSubscriptionOrderListPageSize);
  const totalOrders = useSelector(getSubscriptionOrderListTotalOrders);
  const subscriptionOrderList = useSelector(getSubscriptionOrderList);
  const subscriptionShowHistoryAgreementModalVisibility = useSelector(
    getSubscriptionShowHistoryAgreementModalVisibility
  );
  const historyAgreementContent = useSelector(getHistoryAgreementContent);
  const columns = [
    {
      title: '金额',
      dataIndex: 'displayTotal',
      key: 'displayTotal',
      align: 'center',
      mobileAlign: 'headerLeft',
    },
    {
      title: '购买内容',
      dataIndex: 'planAndStores',
      key: 'planAndStores',
      align: 'center',
    },
    {
      title: '订单号',
      dataIndex: 'orderId',
      key: 'orderId',
      align: 'center',
    },
    {
      title: '创建时间',
      dataIndex: 'displayCreateTime',
      key: 'displayCreateTime',
      align: 'center',
    },
    {
      title: '支付状态',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      mobileAlign: 'contentHeaderLeft',
      render: (status: string) => {
        const statusMapping: {
          [key: string]: {
            title: string;
            color: string;
          };
        } = {
          paid: {
            title: '已支付',
            color: 'success',
          },
          pendingPayment: {
            title: '未支付',
            color: 'error',
          },
          timeout: {
            title: '已取消',
            color: 'default',
          },
          refund: {
            title: '已退款',
            color: 'default',
          },
        };

        if (!statusMapping[status]) {
          return '-';
        }

        return (
          <Tag color={statusMapping[status].color}>
            {statusMapping[status].title}
          </Tag>
        );
      },
    },
    {
      title: '操作',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      mobileAlign: 'contentHeaderLeft',
      render: (id: string, record: { status: string }) => {
        const { status } = record || {};
        if (UNPAID_STATUS.includes(status)) {
          return (
            <Button
              ghost
              type="primary"
              size="small"
              onClick={() => {
                dispatch(pollingSubscriptionOrderStatus({ id }));
              }}
            >
              完成支付
            </Button>
          );
        }

        return '-';
      },
    },
    {
      title: '协议',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      mobileAlign: 'contentHeaderLeft',
      render: (id: string, record: { status: string }) => {
        return (
          <Button
            ghost
            type="primary"
            size="small"
            onClick={async () => {
              await dispatch(loadHistoryAgreementById(id));
              dispatch(subscriptionActions.showHistoryAgreementModal());
            }}
          >
            查看协议
          </Button>
        );
      },
    },
  ];

  useMount(() => {
    dispatch(loadSubscriptionOrderList({}));
  });

  return (
    <section className="SubscriptionHistory">
      <TableResponsiveFrame
        className="SubscriptionHistoryTable"
        paginationSize="small"
        columns={columns}
        pageNumber={pageNumber + 1}
        pageSize={pageSize}
        data={subscriptionOrderList}
        dataItemsTotal={totalOrders}
        onChangePageSize={async (_: number, pageSize: number) => {
          await dispatch(updateSubscriptionOrderListPageSize(pageSize));
        }}
        onChangePageNumber={async (pageNumber: number) => {
          await dispatch(updateSubscriptionOrderListPageNumber(pageNumber - 1));
        }}
      />
      {historyAgreementContent? <HistoryAgreement
        show={subscriptionShowHistoryAgreementModalVisibility}
        content={historyAgreementContent}
        onClose={async () => {
          await dispatch(subscriptionActions.hideHistoryAgreementModal());
          dispatch(subscriptionActions.historyAgreementReset());
        }}
      />:null}
    </section>
  );
};

export default SubscriptionHistory;
