import { get } from '../../../service/api';

export const getAssetsSetting = async (payload: { merchantName: string }) => {
  try {
    const { merchantName } = payload;

    const result = await get(
      `/merchants/${merchantName}/assets/setting`,
      {},
      {},
      { isShow: false }
    );

    return result;
  } catch (error) {
    throw error;
  }
};

export const getMerchantAssetsOperationsFilterOptions = async (
  payload: { merchantName: string },
  options?: { isShow: false }
) => {
  try {
    const { merchantName } = payload;
    const result = await get(
      `/merchants/${merchantName}/assets/operations/filterOptions`,
      {},
      {},
      options
    );

    return result;
  } catch (error) {
    throw error;
  }
};

export const getPlayersList = async (merchantName: string, param: any) => {
  try {
    const { data, pagination }: any = await get(
      `/merchants/${merchantName}/players`,
      param
    );

    return { data, pagination };
  } catch (error) {
    throw error;
  }
};
