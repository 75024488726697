/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import {
  Modal,
  Form,
  FormInstance,
  Input,
  Tooltip,
  Upload,
  message,
  Spin,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import './MemberCardModal.less';

const FORM_RULES = {
  title: [{ required: true, message: '请输入会员卡类型' }],
};

const MemberCardModal = ({
  title = '',
  okText = '',
  imageUrl = '',
  isUploadingSkinImagePending = false,
  imageCoverText = '',
  cardType = '',
  description = '',
  uploadImageAction = '',
  uploadImageHeaders = {},
  deleted = false,
  visible = false,
  closable = true,
  okButtonProps = {},
  changeImage = (info: any) => {},
  changeCardType = (e: any) => {},
  changeCardDescription = (e: any) => {},
  onOk = () => {},
  onCancel = () => {},
}: any) => {
  const [form] = Form.useForm();
  const imageContent = (
    <div className="memberCardModalImageContent">
      <img className="memberCardModalImage" src={imageUrl} alt="" />
      <div
        className={`memberCardModalImageCover flex flex-column flex-middle flex-center${
          isUploadingSkinImagePending ? ' active' : ''
        }`}
      >
        {isUploadingSkinImagePending ? (
          <Spin tip="正在上传图片..." />
        ) : imageCoverText ? (
          <span>{imageCoverText}</span>
        ) : null}
      </div>
    </div>
  );

  useEffect(() => {
    form.setFieldsValue({
      cardType,
      description,
    });
  }, [cardType, description]);

  return (
    <>
      <Modal
        title={title}
        visible={visible}
        okText={okText}
        onOk={async () => {
          try {
            await form.validateFields();

            onOk();
          } catch (e) {
            message.error('修改失败，请正确填写会员卡类型');
          }
        }}
        okButtonProps={okButtonProps}
        onCancel={onCancel}
        closable={closable}
      >
        <Form
          form={form}
          ref={React.createRef<FormInstance>()}
          layout="vertical"
          initialValues={{ cardType: cardType, description: description }}
        >
          <Form.Item>
            {deleted ? (
              imageContent
            ) : (
              <Upload.Dragger
                className="memberCardModalUploader"
                showUploadList={false}
                name="file"
                accept=".png, .jpg, .jpeg"
                action={uploadImageAction}
                headers={uploadImageHeaders}
                defaultFileList={imageUrl ? [imageUrl] : []}
                beforeUpload={(file) => {
                  const validImageTypes = [
                    'image/png',
                    'image/jpeg',
                    'image/jpg',
                  ];

                  if (file.size > 2 * 1024 * 1024) {
                    message.error('图片大小不能超过2M');

                    return false;
                  }

                  if (!validImageTypes.includes(file.type)) {
                    message.error('文件必须为png, jpeg或者jpg的图片');

                    return false;
                  }

                  return true;
                }}
                onChange={(info) => {
                  const { status } = info.file;

                  if (status === 'done') {
                    message.success(`${info.file.name} 会员卡图片上传成功.`);
                  } else if (status === 'error') {
                    message.error(`${info.file.name} 会员卡图片上传失败.`);
                  }

                  changeImage(
                    info.fileList.filter((file) => file.status === status)
                  );
                }}
              >
                {imageUrl ? imageContent : <p>上传会员卡图片</p>}
              </Upload.Dragger>
            )}
          </Form.Item>
          <Form.Item
            name="cardType"
            label="会员卡类型"
            rules={FORM_RULES.title}
          >
            <Input
              disabled={deleted}
              value={cardType}
              type="text"
              allowClear
              onChange={changeCardType}
            />
          </Form.Item>
          <Form.Item
            name="description"
            label={
              <div>
                <span className="memberCardModalFormItemLabel">详细介绍</span>
                <Tooltip placement="right" title="包含会员卡的详细的使用介绍">
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            }
          >
            <Input.TextArea
              value={description}
              disabled={deleted}
              autoSize={{ minRows: 3, maxRows: 5 }}
              maxLength={500}
              onChange={changeCardDescription}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default MemberCardModal;
