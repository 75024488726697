import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEnrollmentList, postDuplicateEnrollment } from './api-request';
import { getMerchantName } from '../../../../../../../redux/app-v2/merchant/selectors';
import {
  getEnrollmentListPageNumber,
  getEnrollmentListPageSize,
  getEnrollmentListSearchInputText,
} from './selectors';
import { errorMessage } from '../../../../../../../common/utils/feedback';

export const loadEnrollmentList = createAsyncThunk(
  'enrollmentList/loadEnrollmentList',
  async (_, { getState }) => {
    try {
      const state = getState();
      const merchantName = getMerchantName(state);
      const offset: number =
        getEnrollmentListPageNumber(state) * getEnrollmentListPageSize(state);
      const text: string = getEnrollmentListSearchInputText(state);
      const limit: number = getEnrollmentListPageSize(state);
      const result: any = await getEnrollmentList({
        text,
        limit,
        offset,
        merchantName,
      });

      return result;
    } catch (e) {
      errorMessage('加载报名列表失败, 请刷新重试');

      throw e;
    }
  }
);

export const duplicateEnrollmentDetail = createAsyncThunk(
  'enrollmentList/duplicateEnrollmentDetail',
  async ({
    id,
    redirectToEnrollmentDetailPage,
  }: {
    id: string;
    redirectToEnrollmentDetailPage: Function;
  }) => {
    try {
      const result: any = await postDuplicateEnrollment(id);
      const { data } = result || {};

      redirectToEnrollmentDetailPage(data.id);

      return data;
    } catch (e) {
      errorMessage('创建报名副本失败');

      throw e;
    }
  }
);
