import moment from 'moment';

export const getFormatLocalTime = (
  time: string | undefined,
  format: string = 'YYYY/MM/DD HH:mm:ss'
) => {
  if (!time) {
    return '';
  }

  return moment(time).local().format(format);
};

export const getTimeMomentWithSecond = (time: string | undefined) => {
  if (!time) {
    return '';
  }

  const localTimeMomentObject = moment(time).local();

  return moment(localTimeMomentObject, 'YYYY/MM/DD HH:mm:ss');
};

export const getUnixTimeMoment = (time: number) => {
  if (!time) {
    return '';
  }

  return moment.unix(time);
};

export const getISODate = (date = new Date()) => {
  return date.toISOString();
};

export const getYearLastISODate = (dateObject = new Date()) => {
  const currentYear = dateObject.getFullYear();
  // 11 is December
  // 31 is the last day of December
  // 11:59 is the last minute of the last day of December
  const lastDate = new Date(currentYear, 11, 31, 23, 59, 59);

  return lastDate.toISOString();
};

export const getFormatRequestDataTime = (
  time: string | undefined,
  format: string = 'YYYY-MM-DD'
) => {
  if (!time) {
    return '';
  }

  return moment(time).format(format);
};

export const formatMilliseconds = (
  milliseconds: number,
  format: string = 'HH:mm:ss'
) => {
  const seconds = Math.floor((milliseconds / 1000) % 60);
  const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  const hours = Math.floor(milliseconds / (1000 * 60 * 60));

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  if (format === 'ss') {
    return `${formattedSeconds}`;
  }

  if (format === 'mm:ss') {
    return `${formattedMinutes}:${formattedSeconds}`;
  }

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};
