import { combineReducers } from '@reduxjs/toolkit';
import { reducer as list } from '../containers/ActivityList/redux';
import { reducer as details } from '../containers/ActivityDetail/redux';
import { reducer as common } from './common';

const reducer = combineReducers({
  common,
  list,
  details,
});

export default reducer;
