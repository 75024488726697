import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Radio } from 'antd';
import {
  getArcheryCompetitionBowTypes,
  getArcheryCompetitionArrowsSets,
  getArcheryCompetitionEnvironments,
  getArcheryCompetitionDistances,
  getArcheryCompetitionTargets,
} from '../../../redux/selectors';

const ArcheryOptions = ({
  data,
  onSelectBow,
  onSelectArrow,
  onSelectEnvironment,
  onSelectDistance,
  onSelectTarget,
}: {
  data: {
    bow: {
      name: any;
      value: string;
    };
    arrow: {
      name: any;
      value: string;
    };
    environment: {
      name: any;
      value: string;
    };
    distance: {
      name: any;
      value: string;
    };
    target: {
      name: any;
      value: string;
    };
  };
  onSelectBow: (bow: string) => void;
  onSelectArrow: (arrow: string) => void;
  onSelectEnvironment: (environment: string) => void;
  onSelectDistance: (distance: string) => void;
  onSelectTarget: (target: string) => void;
}) => {
  const { bow, arrow, environment, distance, target } = data || {};
  const { value: bowValue, name: bowName } = bow || {};
  const { value: arrowValue, name: arrowName } = arrow || {};
  const { value: environmentValue, name: environmentName } = environment || {};
  const { value: distanceValue, name: distanceName } = distance || {};
  const { value: targetValue, name: targetName } = target || {};
  const bowTypes = useSelector(getArcheryCompetitionBowTypes);
  const arrowsSets = useSelector(getArcheryCompetitionArrowsSets);
  const environments = useSelector(getArcheryCompetitionEnvironments);
  const distances = useSelector(getArcheryCompetitionDistances);
  const targets = useSelector(getArcheryCompetitionTargets);

  return (
    <section>
      <Form.Item
        label="弓种"
        name={bowName}
        initialValue={bowValue}
        rules={[
          {
            required: true,
            message: '弓种不能为空',
          },
        ]}
      >
        <Radio.Group
          name="bow"
          value={bowValue}
          onChange={(e) => {
            onSelectBow(e.target.value);
          }}
        >
          {bowTypes.map((bowType: any) => (
            <Radio key={bowType.value} value={bowType.value}>
              {bowType.label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="排位赛箭组"
        name={arrowName}
        initialValue={arrowValue}
        rules={[
          {
            required: true,
            message: '排位赛箭组不能为空',
          },
        ]}
      >
        <Radio.Group
          name="arrow"
          value={arrowValue}
          onChange={(e) => {
            onSelectArrow(e.target.value);
          }}
        >
          {arrowsSets.map((arrowsSet: any) => (
            <Radio key={arrowsSet.value} value={arrowsSet.value}>
              {arrowsSet.label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="场地"
        name={environmentName}
        initialValue={environmentValue}
        rules={[
          {
            required: true,
            message: '场地不能为空',
          },
        ]}
      >
        <Radio.Group
          name="environment"
          value={environmentValue}
          onChange={(e) => {
            onSelectEnvironment(e.target.value);
          }}
        >
          {environments.map((environment: any) => (
            <Radio key={environment.value} value={environment.value}>
              {environment.label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="距离"
        name={distanceName}
        initialValue={distanceValue}
        rules={[
          {
            required: true,
            message: '距离不能为空',
          },
        ]}
      >
        <Radio.Group
          name="distance"
          value={distanceValue}
          onChange={(e) => {
            onSelectDistance(e.target.value);
          }}
        >
          {distances.map((distance: any) => (
            <Radio key={distance.value} value={distance.value}>
              {distance.label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="靶纸"
        name={targetName}
        initialValue={targetValue}
        rules={[
          {
            required: true,
            message: '靶纸不能为空',
          },
        ]}
      >
        <Radio.Group
          name="target"
          value={targetValue}
          onChange={(e) => {
            onSelectTarget(e.target.value);
          }}
        >
          {targets.map((target: any) => (
            <Radio key={target.value} value={target.value}>
              {target.label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
    </section>
  );
};

ArcheryOptions.displayName = 'ArcheryOptions';

export default ArcheryOptions;
