import { get } from '../../../../service/api';

export const getPosters = async (payload: {
  merchantName: string;
  offset: number;
  limit: number;
}) => {
  try {
    const { merchantName, offset, limit } = payload;
    const params = { offset, limit };

    const result = await get(
      `/merchants/${merchantName}/posters`,
      params,
      {},
      { isShow: false }
    );

    return result;
  } catch (e) {
    throw e;
  }
};
