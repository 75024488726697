import { createSlice } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../../../../service/constants';
import { HistoryClubsRankingImportState } from './types';
import {
  loadHistoryClubsRankingImportTasks,
  loadHistoryClubsRankingImportTempTournament,
} from './thunks';

const initialState: HistoryClubsRankingImportState = {
  data: {
    tasks: [],
    headers: [],
    pageNumber: 0,
    pageSize: 10,
    totalHistoryClubsRankingImportTasks: 0,
    associationHistoryTournamentTemplateId: null,
    associationHistoryTournamentTemplateName: null,
  },
  loadHistoryClubsRankingImportTasksStatus: API_REQUEST_STATUS.IDLE,
  loadHistoryClubsRankingImportTempTournamentStatus: API_REQUEST_STATUS.IDLE,
};

export const { reducer, actions } = createSlice({
  name: 'association/histories/clubsRankingImport',
  initialState,
  reducers: {
    associationHistoryTournamentTemplateIdUpdated: (state, { payload }) => {
      state.data.associationHistoryTournamentTemplateId = payload;
    },
  },
  extraReducers: {
    [loadHistoryClubsRankingImportTasks.rejected.type]: (state) => {
      state.loadHistoryClubsRankingImportTasksStatus =
        API_REQUEST_STATUS.REJECTED;
    },
    [loadHistoryClubsRankingImportTasks.pending.type]: (state) => {
      state.loadHistoryClubsRankingImportTasksStatus =
        API_REQUEST_STATUS.PENDING;
    },
    [loadHistoryClubsRankingImportTasks.fulfilled.type]: (
      state,
      { payload }
    ) => {
      state.loadHistoryClubsRankingImportTasksStatus =
        API_REQUEST_STATUS.FULFILLED;
      state.data.tasks = payload;
    },
    [loadHistoryClubsRankingImportTasks.rejected.type]: (state) => {
      state.loadHistoryClubsRankingImportTasksStatus =
        API_REQUEST_STATUS.REJECTED;
    },
    [loadHistoryClubsRankingImportTempTournament.rejected.type]: (state) => {
      state.loadHistoryClubsRankingImportTempTournamentStatus =
        API_REQUEST_STATUS.REJECTED;
    },
    [loadHistoryClubsRankingImportTempTournament.pending.type]: (state) => {
      state.loadHistoryClubsRankingImportTempTournamentStatus =
        API_REQUEST_STATUS.PENDING;
    },
    [loadHistoryClubsRankingImportTempTournament.fulfilled.type]: (
      state,
      { payload }
    ) => {
      const { title } = payload || {};

      state.loadHistoryClubsRankingImportTempTournamentStatus =
        API_REQUEST_STATUS.FULFILLED;
      state.data.associationHistoryTournamentTemplateName = title;
    },
  },
});

export default reducer;
