import _get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { getFormatLocalTime } from '../../../common/utils/time-lib';
import { getStoresMapping } from '../../../redux/entities/stores/selectors';
import { ALL_STORES_ENTITIES } from '../../../redux/entities/stores/constant';

export const getDashboardCardPaidPlayers = (state: {
  dashboard: { playerInfo: object };
}) => _get(state.dashboard, 'playerInfo.cardPaid', 0);

export const getDashboardCardNotPaidPlayers = (state: {
  dashboard: { playerInfo: object };
}) => _get(state.dashboard, 'playerInfo.cardNotPaid', 0);

export const getDashboardCardRegisteredPlayers = createSelector(
  getDashboardCardPaidPlayers,
  getDashboardCardNotPaidPlayers,
  (cardPaidPlayers, cardNotPaidPlayers) => cardPaidPlayers + cardNotPaidPlayers
);

export const getDashboardNewTodayAndCardPaidPlayers = (state: {
  dashboard: { playerInfo: object };
}) => _get(state.dashboard, 'playerInfo.newTodayAndCardPaid', 0);

export const getDashboardNewTodayAndCardNotPaidPlayers = (state: {
  dashboard: { playerInfo: object };
}) => _get(state.dashboard, 'playerInfo.newTodayAndCardNotPaid', 0);

export const getDashboardNewTodayAndCardRegisteredPlayers = (state: {
  dashboard: { playerInfo: object };
}) => _get(state.dashboard, 'playerInfo.newToday', 0);

export const getDashboardTodayPurchasedCardPlayerCount = (state: {
  dashboard: { playerInfo: object };
}) => _get(state.dashboard, 'playerInfo.todayPurchasedCardPlayerCount', 0);

export const getDashboardTodayActivePlayerCount = (state: {
  dashboard: { todayActivePlayerCount: number };
}) => state.dashboard.todayActivePlayerCount || 0;

export const getDashboardDailyNewPlayerReports = (state: {
  dashboard: { dailyNewPlayerReports30days: object[] };
}) => {
  const originalDailyNewPlayerReports = _get(
    state.dashboard,
    'dailyNewPlayerReports30days',
    []
  );

  return originalDailyNewPlayerReports.map((report: any) => {
    const date = getFormatLocalTime(report.startTime, 'YYYY/MM/DD');

    return {
      date,
      totalPlayerCount: report.totalPlayerCount,
      cardPaidPlayerCount: report.cardPaidPlayerCount,
    };
  });
};

export const getStoreListPageNumber = (state: {
  dashboard: { storeInfo: { pageNumber: object } };
}) => _get(state.dashboard, 'storesInfo.pageNumber', 0);

export const getStoreListPageSize = (state: {
  dashboard: { storeInfo: { pageSize: object } };
}) => _get(state.dashboard, 'storesInfo.pageSize', 0);

export const getStoreIds = (state: { dashboard: { storeInfo: { ids: [] } } }) =>
  _get(state.dashboard, 'storesInfo.ids', []);

export const getSelectedStoreId = (state: {
  dashboard: { storeInfo: { selectedStoreId: string } };
}) => _get(state.dashboard, 'storesInfo.selectedStoreId', '');

export const getSelectedStore = createSelector(
  getStoresMapping,
  getSelectedStoreId,
  (storesMapping, selectedStoreId: string) => {
    if (selectedStoreId === ALL_STORES_ENTITIES.id) {
      return ALL_STORES_ENTITIES;
    }

    return storesMapping[selectedStoreId] || {};
  }
);

export const getDashboardStoreList = createSelector(
  getStoresMapping,
  getStoreIds,
  (storesMapping, ids) =>
    ids.map((id: string) => {
      if (id === ALL_STORES_ENTITIES.id) {
        return ALL_STORES_ENTITIES;
      }

      return storesMapping[id as any];
    })
);

export const getIsSelectedAllStoresDashboard = createSelector(
  getSelectedStoreId,
  (selectedStoreId: string) => selectedStoreId === ALL_STORES_ENTITIES.id
);
