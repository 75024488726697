import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Button, Modal, Skeleton } from 'antd';
import { getISODate } from '../../../../common/utils/time-lib';
import { get } from '../../../../service/api';
import './RenewalAgreementModal.less';

const RenewalAgreement = ({
  show,
  data,
  onConfirm,
  onClose,
}: {
  show: boolean;
  data: {
    companyName: string;
    companyAddress: string;
    principalName: string;
    planPrice: number;
    storePrice: number;
  };
  onConfirm: Function;
  onClose: Function;
}) => {
  const { companyName, companyAddress, principalName, planPrice, storePrice } =
    data || {};
  const [isCustomerAgreementLoaded, setIsCustomerAgreementLoaded] =
    useState<any>(false);
  const [customerAgreement, setCustomerAgreement] = useState<any>(null);

  useEffect(() => {
    if (show && companyName && companyAddress && principalName) {
      const getCustomerAgreement = async () => {
        const result: any = await get(
          `/platform/customer-agreement`,
          {
            type: 'saas',
            time: getISODate(),
            companyName,
            companyAddress,
            principalName,
            planPrice,
            storePrice,
          },
          {},
          { isShow: false }
        );
        const { data } = result || {};
        const { content } = data || {};

        setCustomerAgreement(content);
        setIsCustomerAgreementLoaded(true);
      };

      getCustomerAgreement();
    }
  }, [companyAddress, companyName, principalName, show]);

  return (
    <Modal
      centered
      width="100%"
      visible={show}
      className="RenewalAgreementModal"
      title="管理后台续订协议"
      onOk={async () => {
        await onConfirm();
      }}
      onCancel={() => {
        onClose();
      }}
      footer={
        <div className="flex flex-center">
          <Button
            type="primary"
            size="large"
            onClick={async () => {
              await onConfirm();
            }}
          >
            同意续订 & 继续使用
          </Button>
        </div>
      }
    >
      <h2 className="text-center text-bold">SaaS 系统续订协议</h2>
      <Skeleton loading={!isCustomerAgreementLoaded} active>
        <ReactMarkdown
          className="RenewalAgreementModalCustomerAgreement"
          children={customerAgreement}
          remarkPlugins={[remarkGfm]}
        />
      </Skeleton>
    </Modal>
  );
};

RenewalAgreement.displayName = 'RenewalAgreement';

export default RenewalAgreement;
