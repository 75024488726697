/* eslint-disable react-hooks/rules-of-hooks */
import _isNill from 'lodash/isNil';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import * as ExcelJS from 'exceljs';
import { useDispatch, useSelector } from 'react-redux';
import { useMount, useUnmount } from 'react-use';
import { Space, Select, Tag, Input, Button, Modal, Form, Progress } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { downloadCSV } from '../../../../../../common/utils/csv';
import TableResponsiveFrame from '../../../../../../common/components/tables/TableResponsiveFrame';
import { ContentTableColumnPropsType } from '../../../../../../common/components/tables/ContentTable/types';
import ContentDetail from '../../../../../../common/components/ContentDetail';
import BreadcrumbRouter from '../../../../../../common/components/BreadcrumbRouter';
import { ExportOutlined } from '@ant-design/icons';
import {
  ENROLLMENT_RESULT_CATEGORIES,
  ENROLLMENT_RESULT_CATEGORIES_MAP,
  ENROLLMENT_RESULT_IS_PAID_MAP,
} from './utils/constants';
import {
  exportEnrollmentResultForm,
  loadEnrollmentPlayersResult,
  loadEnrollmentTeamsResult,
  loadEnrollmentResultSummary,
  VerifyPassword,
  loadEnrollmentDetailsById,
  exportEnrollmentNotTeamResultForm,
} from './redux/thunks';
import {
  getEnrollmentResultSelectedCategory,
  getDisplayEnrollmentResultPageNumber,
  getDisplayEnrollmentResultPageSize,
  getDisplayEnrollmentResultTableTotal,
  getDisplayEnrollmentResultTableData,
  getEnrollmentResultSummaryCategories,
  getEnrollmentResultSummaryTotal,
  getIsMultiplePlayer,
  getEnrollmentTitle,
  getIsEnrollmentNotTeamCSVLoading,
  getEnrollmentResultIsPaid,
  getIsEnrollmentCSVLoading,
  getVerifyAccount,
  getVerifyPassword,
} from './redux/selector';
import { actions as enrollmentResultActions } from './redux';
import PhoneNumberInput from '../../../../../../common/components/PhoneNumberInput';
import './EnrollmentResult.less';
import { getIsBaseball } from '../../../../../../redux/app-v2/merchant/selectors';
import { downloadImage } from './utils';
import GroupingComponent from './components/GroupingComponent';

const Papa = require('papaparse');
const EnrollmentResult = () => {
  const { id } = useParams() as any;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const progressRef = useRef(0);
  const [csvLoadingTip, setCsvLoadingTip] = React.useState('');
  const [csvLoadingProgress, setCsvLoadingProgress] = React.useState(0);
  const pageNumber = useSelector(getDisplayEnrollmentResultPageNumber);
  const pageSize = useSelector(getDisplayEnrollmentResultPageSize);
  const total = useSelector(getDisplayEnrollmentResultTableTotal);
  const enrollmentResultTableData = useSelector(
    getDisplayEnrollmentResultTableData
  );
  const selectedCategory = useSelector(getEnrollmentResultSelectedCategory);
  const isPaid = useSelector(getEnrollmentResultIsPaid);
  const isMultiplePlayer = useSelector(getIsMultiplePlayer);
  const enrollmentResultSummaryCategories = useSelector(
    getEnrollmentResultSummaryCategories
  );
  const enrollmentResultSummaryTotal: number = useSelector(
    getEnrollmentResultSummaryTotal
  );
  const enrollmentTitle = useSelector(getEnrollmentTitle);
  const isEnrollmentCSVLoading = useSelector(getIsEnrollmentCSVLoading);
  const isEnrollmentNotTeamCSVLoading = useSelector(
    getIsEnrollmentNotTeamCSVLoading
  );
  const verifyAccount = useSelector(getVerifyAccount);
  const verifyPassword = useSelector(getVerifyPassword);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isBaseball = useSelector(getIsBaseball);
  const playerText = isBaseball ? '球员' : '运动员';
  const teamsColumns: ContentTableColumnPropsType[] = [
    {
      title: '俱乐部',
      width: 100,
      dataIndex: 'clubName',
      key: 'clubName',
      align: 'center',
      mobileAlign: 'headerLeft',
      render: (clubName, record) => {
        const { logoImageUrl, leaderName, leaderPhone } = record;

        if (!logoImageUrl && !leaderName && !leaderPhone) {
          return <span>{clubName}</span>;
        }

        return (
          <Button
            type="link"
            onClick={() => {
              Modal.info({
                icon: null,
                className: 'EnrollmentResultClubInfoModal',
                maskClosable: true,
                centered: true,
                content: (
                  <div className="flex flex-column flex-center flex-middle">
                    {record.logoImageUrl ? (
                      <div className="EnrollmentResultClubInfoImageContainer">
                        <img
                          className="EnrollmentResultClubInfoImage"
                          src={logoImageUrl}
                          alt="Zaiye partners logo"
                        />
                      </div>
                    ) : null}

                    <h2>{clubName}</h2>
                    {leaderName || leaderPhone ? (
                      <p className="flex flex-column">
                        {leaderName ? (
                          <span className="EnrollmentResultClubInfoLeaderText">
                            领队：{leaderName}
                          </span>
                        ) : null}
                        {leaderPhone ? (
                          <span className="EnrollmentResultClubInfoLeaderText">
                            联系电话：
                            <a href={`tel:${leaderPhone}`}>{leaderPhone}</a>
                          </span>
                        ) : null}
                      </p>
                    ) : null}
                  </div>
                ),
              });
            }}
          >
            {clubName}
          </Button>
        );
      },
    },
    {
      title: '姓名',
      width: 50,
      dataIndex: 'formPlayers',
      key: 'name',
      render: (formPlayers: any) => {
        return formPlayers.map((player: any) => {
          return (
            <Tag className="EnrollmentResultNameTag" color="processing">
              {player.name}
            </Tag>
          );
        });
      },
    },
    {
      title: '组别',
      width: 50,
      dataIndex: 'categoryName',
      key: 'categoryName',
      align: 'center',
      render: (_, record) => (
        <span>
          {record.categoryName} ( {record.optionName} )
        </span>
      ),
    },
    {
      title: '操作',
      width: 50,
      dataIndex: 'verified',
      key: 'verified',
      align: 'center',
      render: (_, record) => (
        <Button
          type="text"
          onClick={() => {
            const payload = {
              id: record.id,
              enrollmentId: record.enrollmentId,
              formId: record.formId,
            };
            dispatch(enrollmentResultActions.setVerificationModalData(payload));
            setIsModalVisible(true);
          }}
        >
          <Tag color="processing">取消分组</Tag>
        </Button>
      ),
    },
  ];
  const playersColumns: ContentTableColumnPropsType[] = [
    {
      title: '俱乐部',
      dataIndex: 'clubName',
      key: 'clubName',
      align: 'center',
      mobileAlign: 'headerLeft',
      render: (clubName, record) => {
        const { logoImageUrl, leaderName, leaderPhone } = record;

        if (!logoImageUrl && !leaderName && !leaderPhone) {
          return <span>{clubName}</span>;
        }

        return (
          <Button
            type="link"
            onClick={() => {
              Modal.info({
                icon: null,
                className: 'EnrollmentResultClubInfoModal',
                maskClosable: true,
                centered: true,
                content: (
                  <div className="flex flex-column flex-center flex-middle">
                    {record.logoImageUrl ? (
                      <div className="EnrollmentResultClubInfoImageContainer">
                        <img
                          className="EnrollmentResultClubInfoImage"
                          src={logoImageUrl}
                          alt="Zaiye partners logo"
                        />
                      </div>
                    ) : null}

                    <h2>{clubName}</h2>
                    {leaderName || leaderPhone ? (
                      <p className="flex flex-column">
                        {leaderName ? (
                          <span className="EnrollmentResultClubInfoLeaderText">
                            领队：{leaderName}
                          </span>
                        ) : null}
                        {leaderPhone ? (
                          <span className="EnrollmentResultClubInfoLeaderText">
                            联系电话：
                            <a href={`tel:${leaderPhone}`}>{leaderPhone}</a>
                          </span>
                        ) : null}
                      </p>
                    ) : null}
                  </div>
                ),
              });
            }}
          >
            {clubName}
          </Button>
        );
      },
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: '类型',
      dataIndex: 'associationPlayerId',
      key: 'associationPlayerId',
      align: 'center',
      render: (associationPlayerId) =>
        associationPlayerId ? (
          <Tag color="processing">注册{playerText}</Tag>
        ) : (
          <Tag color="error">非注册{playerText}</Tag>
        ),
    },
    {
      title: '电话',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      align: 'center',
    },
    {
      title: '性别',
      dataIndex: 'gender',
      key: 'gender',
      align: 'center',
      mobileAlign: 'contentHeaderLeft',
    },
    {
      title: '组别',
      dataIndex: 'categoryName',
      key: 'categoryName',
      align: 'center',
    },
    {
      title: '证件类型',
      dataIndex: 'idCardType',
      key: 'idCardType',
      align: 'center',
    },
    {
      title: '证件号',
      dataIndex: 'idCardNum',
      key: 'idCardNum',
      align: 'center',
    },
    {
      title: '报名费',
      dataIndex: 'paidByOrderId',
      key: 'paidByOrderId',
      align: 'center',
      render: (paidByOrderId: string, player: any) => {
        const { price } = player || {};
        const { total } = price || {};

        return total === 0 ? (
          <Tag color="default">无需支付</Tag>
        ) : paidByOrderId ? (
          <Tag color="success">已支付</Tag>
        ) : (
          <Tag color="error">未支付</Tag>
        );
      },
    },
    {
      title: '操作',
      dataIndex: 'verified',
      key: 'verified',
      align: 'center',
      render: (_, record) => (
        <Button
          ghost
          type="primary"
          size="small"
          onClick={() => {
            Modal.confirm({
              centered: true,
              title: `确定取消该${playerText}资格？`,
              content: '取消资格将前将先取消选手的缴费订单',
              okText: '取消订单&取消资格',
              cancelText: '保留资格',
              onOk: () => {
                const payload = {
                  id: record.id,
                  enrollmentId: record.enrollmentId,
                  formId: record.formId,
                };
                const { paidByOrderId } = record || {};

                if (paidByOrderId && paidByOrderId !== '') {
                  dispatch(
                    enrollmentResultActions.updateSelectedPaidByOrderId(
                      paidByOrderId
                    )
                  );
                }
                dispatch(
                  enrollmentResultActions.setVerificationModalData(payload)
                );
                setIsModalVisible(true);
              },
            });
          }}
        >
          取消资格
        </Button>
      ),
    },
  ];
  const columns: ContentTableColumnPropsType[] =
    selectedCategory === ENROLLMENT_RESULT_CATEGORIES_MAP.TEAM_VALUE
      ? teamsColumns
      : playersColumns;

  useMount(async () => {
    await dispatch(loadEnrollmentDetailsById(id));
  });

  useUnmount(() => {
    dispatch(enrollmentResultActions.resultDataReset());
  });

  useEffect(() => {
    if (id) {
      dispatch(loadEnrollmentResultSummary(id) as any);
    }
  }, [dispatch, id, selectedCategory, isModalVisible]);

  useEffect(() => {
    if (id) {
      if (selectedCategory === ENROLLMENT_RESULT_CATEGORIES_MAP.PLAYER_VALUE) {
        dispatch(loadEnrollmentPlayersResult(id) as any);
      } else if (
        selectedCategory === ENROLLMENT_RESULT_CATEGORIES_MAP.TEAM_VALUE
      ) {
        dispatch(loadEnrollmentTeamsResult(id) as any);
      }
    }
  }, [dispatch, id, selectedCategory, isPaid, pageNumber, pageSize]);

  const handleCloseGroupingModal = useCallback(() => {
    dispatch(
      enrollmentResultActions.updateSelectedCategory(
        ENROLLMENT_RESULT_CATEGORIES_MAP.TEAM_VALUE
      )
    );
  }, [dispatch]);

  return (
    <ContentDetail
      title="赛事活动报名 / 报名详情 / 数据管理"
      headerLeft={
        <BreadcrumbRouter
          routes={[
            {
              path: '/association-activities/enrollments',
              breadcrumbName: '赛事活动报名',
            },
            {
              path: `${id}`,
              breadcrumbName: '报名详情',
            },
            {
              path: 'players',
              breadcrumbName: '数据管理',
            },
          ]}
        />
      }
      headerRight={
        <Space className="EnrollmentResultButtonContainer">
          {isMultiplePlayer ? (
            <GroupingComponent onClose={handleCloseGroupingModal} />
          ) : null}
          {isMultiplePlayer ? (
            <Button
              className="NewEnrollmentButton"
              loading={isEnrollmentNotTeamCSVLoading}
              onClick={async () => {
                if (id) {
                  await dispatch(
                    exportEnrollmentNotTeamResultForm(id) as any
                  ).then(async ({ payload }: any) => {
                    if (!_isNill(payload)) {
                      await downloadCSV(payload, {
                        filename: `${enrollmentTitle}(未组队个人表).csv`,
                      });
                    }
                  });
                }
              }}
            >
              <ExportOutlined className="NewEnrollmentButtonOutlinedIcon" />
              <span className="NewEnrollmentButtonText">导出未组队名单</span>
            </Button>
          ) : null}

          <Button
            className="NewEnrollmentButton"
            loading={isEnrollmentCSVLoading}
            onClick={async () => {
              if (id) {
                await dispatch(exportEnrollmentResultForm(id) as any).then(
                  async ({ payload }: { payload: any }) => {
                    if (!_isNill(payload)) {
                      progressRef.current = 10;
                      setCsvLoadingProgress(10);
                      setCsvLoadingTip('正在下载图片，请稍等...');

                      // 解析 CSV 字符串
                      const parseResult = Papa.parse(payload, { header: true });
                      const data = parseResult.data;

                      // 创建 Excel 工作簿和工作表
                      const workbook = new ExcelJS.Workbook();
                      const worksheet = workbook.addWorksheet('Sheet 1');

                      // 设置工作表文字大小
                      worksheet.properties.defaultRowHeight = 20;
                      // 添加表头
                      const header = Object.keys(data[0]);
                      worksheet.addRow(header);

                      // 执行完所有遍历后，保存 Excel 文件
                      const promises: any = [];
                      // 遍历数据，下载图片并替换 URL
                      data.forEach((row: any, rowIndex: any) => {
                        // 添加行到 Excel 工作表
                        Object.values(row).forEach(
                          async (cell: any, cellIndex) => {
                            promises.push(
                              new Promise(async (resolve, reject) => {
                                // 判断 cell 是否为图片 URL
                                if (
                                  cell.includes('http') &&
                                  (cell.includes('.png') ||
                                    cell.includes('.jpg') ||
                                    cell.includes('.jpeg'))
                                ) {
                                  // 下载图片文件并获取本地文件URL
                                  const { base64: localImageUrl } =
                                    await downloadImage(cell);
                                  let extension: any = 'gif';

                                  if (cell.includes('.jpg')) {
                                    extension = 'jpeg';
                                  } else if (cell.includes('.jpeg')) {
                                    extension = 'jpeg';
                                  } else if (cell.includes('.png')) {
                                    extension = 'png';
                                  }
                                  const imageId = workbook.addImage({
                                    base64: localImageUrl,
                                    extension,
                                  });
                                  console.log('imageId', imageId);
                                  // 添加单元格到 Excel 工作表
                                  worksheet.addImage(imageId, {
                                    tl: { col: cellIndex, row: rowIndex + 1 },
                                    // 将图片缩放到 200*200 的单元格内
                                    ext: { width: 200, height: 200 },
                                  });
                                  // 设置单元格样式、宽高
                                  const imageCell = worksheet.getCell(
                                    `${String.fromCharCode(65 + cellIndex)}${
                                      rowIndex + 2
                                    }`
                                  );
                                  imageCell.alignment = {
                                    vertical: 'middle',
                                    horizontal: 'center',
                                  };
                                  imageCell.font = { size: 10 };
                                  // 设置列宽
                                  worksheet.getColumn(
                                    String.fromCharCode(65 + cellIndex)
                                  ).width = 30;
                                  // 设置行宽
                                  worksheet.getRow(rowIndex + 2).height = 120;
                                  resolve(true);
                                } else {
                                  // 添加单元格到 Excel 工作表
                                  worksheet.getCell(
                                    `${String.fromCharCode(65 + cellIndex)}${
                                      rowIndex + 2
                                    }`
                                  ).value = cell;
                                  resolve(false);
                                }
                              })
                            );
                          }
                        );
                      });

                      // 等待所有图片下载完成, 每张图片下载完之后更新进度条
                      await Promise.all(
                        promises.map((promise: any, index: any) =>
                          promise.then(() => {
                            let newLoadingTip: any = `正在下载图片(${
                              index + 1
                            }/${promises.length})，请稍等...`;
                            progressRef.current = Math.ceil(
                              progressRef.current + 90 / promises.length
                            );

                            if (progressRef.current >= 100) {
                              newLoadingTip = undefined;
                            }

                            setCsvLoadingProgress((progress) =>
                              progress + 90 / promises.length > 100
                                ? 100
                                : Math.ceil(progress + 90 / promises.length)
                            );
                            setCsvLoadingTip(newLoadingTip);
                          })
                        )
                      );

                      // 保存 Excel 文件
                      const buffer = await workbook.xlsx.writeBuffer();
                      const blob = new Blob([buffer], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                      });
                      const blobUrl = URL.createObjectURL(blob);

                      // 创建下载链接并模拟点击
                      const a = document.createElement('a');
                      a.href = blobUrl;
                      a.download = isMultiplePlayer
                        ? `${enrollmentTitle}(团体表).xlsx`
                        : `${enrollmentTitle}(个人表).xlsx`;
                      document.body.appendChild(a);
                      a.click();
                      document.body.removeChild(a);

                      // 清理URL对象
                      URL.revokeObjectURL(blobUrl);
                    }
                  }
                );
              }
            }}
          >
            <ExportOutlined className="NewEnrollmentButtonOutlinedIcon" />
            <span className="NewEnrollmentButtonText">
              {isMultiplePlayer ? '导出已组队名单' : '导出表格'}
            </span>
          </Button>
        </Space>
      }
    >
      <div className="EnrollmentResultFilter flex flex-middle">
        <Space className="EnrollmentResultFilterCategories flex flex-middle">
          <label>报名列表</label>
          <Select
            style={{ width: 100 }}
            value={selectedCategory}
            onChange={(value) => {
              navigate(`/association-activities/enrollments/${id}/players`, {
                replace: true,
              });
              dispatch(enrollmentResultActions.updateSelectedCategory(value));
            }}
            options={ENROLLMENT_RESULT_CATEGORIES}
          />
          {selectedCategory ===
          ENROLLMENT_RESULT_CATEGORIES_MAP.PLAYER_VALUE ? (
            <Select
              style={{ width: 100 }}
              value={isPaid}
              onChange={(value) => {
                dispatch(enrollmentResultActions.updatePlayersPageNumber(0));
                dispatch(enrollmentResultActions.updateIsPaid(value));
              }}
              options={ENROLLMENT_RESULT_IS_PAID_MAP}
            />
          ) : null}
        </Space>
      </div>

      <Modal
        zIndex={2000}
        centered
        title="请输入密码"
        visible={isModalVisible}
        onOk={async () => {
          try {
            await dispatch(VerifyPassword() as any);
            setIsModalVisible(false);
          } catch (error) {
            throw error;
          }
        }}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        destroyOnClose
      >
        <p>
          取消资格属于敏感操作，为保证安全性，请输入管理员账号密码以继续操作
        </p>

        <Form form={form}>
          <PhoneNumberInput
            name="accountName"
            placeholder="请输入账号（手机号码）"
            phoneNumber={verifyAccount}
            onChange={(phoneNumber: string) => {
              dispatch(enrollmentResultActions.setVerifyAccount(phoneNumber));
            }}
          />
          <Form.Item name="password" initialValue={verifyPassword}>
            <Input.Password
              visibilityToggle={true}
              placeholder="请输入密码"
              value={verifyPassword}
              onChange={(e) => {
                dispatch(
                  enrollmentResultActions.setVerifyPassword(e.target.value)
                );
              }}
            />
          </Form.Item>
        </Form>
      </Modal>

      <TableResponsiveFrame
        columns={columns}
        pageNumber={pageNumber + 1}
        pageSize={pageSize}
        data={enrollmentResultTableData}
        dataItemsTotal={total}
        onChangePageSize={(_: number, pageSize: number) => {
          if (
            selectedCategory === ENROLLMENT_RESULT_CATEGORIES_MAP.PLAYER_VALUE
          ) {
            dispatch(enrollmentResultActions.updatePlayersPageSize(pageSize));
          } else if (
            selectedCategory === ENROLLMENT_RESULT_CATEGORIES_MAP.TEAM_VALUE
          ) {
            dispatch(enrollmentResultActions.updateTeamsPageSize(pageSize));
          }
        }}
        onChangePageNumber={(pageNumber: number) => {
          if (
            selectedCategory === ENROLLMENT_RESULT_CATEGORIES_MAP.PLAYER_VALUE
          ) {
            dispatch(
              enrollmentResultActions.updatePlayersPageNumber(pageNumber - 1)
            );
          } else if (
            selectedCategory === ENROLLMENT_RESULT_CATEGORIES_MAP.TEAM_VALUE
          ) {
            dispatch(
              enrollmentResultActions.updateTeamsPageNumber(pageNumber - 1)
            );
          }
        }}
      />

      <div style={{ margin: '22px 30px' }}>
        <h3 className="EnrollmentResultSummaryTitle">统计数据：</h3>
        <div
          className="flex"
          style={{
            marginTop: '25px',
            height: 'full',
            columnGap: '50px',
          }}
        >
          <div className="EnrollmentResultSummaryBlock flex flex-column">
            <h4 className="EnrollmentResultSummarySubtitle">
              {selectedCategory ===
              ENROLLMENT_RESULT_CATEGORIES_MAP.PLAYER_VALUE
                ? '总人次'
                : '总数'}
            </h4>
            <span className="EnrollmentResultSummaryTotal">
              {enrollmentResultSummaryTotal}
            </span>
          </div>
          {enrollmentResultSummaryCategories.map(
            (category: any, index: number) => (
              <div
                className="EnrollmentResultSummaryBlock flex flex-column"
                key={`enrollment-result-summary-${index}`}
              >
                <h4 className="EnrollmentResultSummarySubtitle">
                  {category.categoryName} ({category.optionName})
                </h4>
                {selectedCategory ===
                ENROLLMENT_RESULT_CATEGORIES_MAP.PLAYER_VALUE ? (
                  <ul className="EnrollmentResultSummaryDataList flex flex-top">
                    <li className="EnrollmentResultSummaryData green">
                      男:{category.male}
                    </li>
                    <li className="EnrollmentResultSummaryData pink">
                      女:{category.female}
                    </li>
                  </ul>
                ) : (
                  <ul className="EnrollmentResultSummaryDataList">
                    <li className="EnrollmentResultSummaryData">
                      {category.other}
                    </li>
                  </ul>
                )}
              </div>
            )
          )}
        </div>
      </div>

      {csvLoadingTip && (
        <div className="EnrollmentResultDownload flex flex-column flex-center flex-middle">
          <div className="EnrollmentResultDownloadProgress flex flex-column flex-middle">
            <div className="EnrollmentResultDownloadProgressTip">
              {csvLoadingTip}
            </div>
            <Progress percent={csvLoadingProgress} />
          </div>
        </div>
      )}
    </ContentDetail>
  );
};

export default EnrollmentResult;
