import { createSlice } from '@reduxjs/toolkit';
import { updateSelectedStoreKeys, requestTableData } from './thunks';

interface IAppAuditState {
  paginateNum: number;
  assetsDictionary: { [key: string]: string };
  tableData: any[];
  total: number;
  pageSize: number;
  selectedStoreKeys: string[];
  selectStatus: string[];
  selectExchangedType: string[];
  merchandiseDic: {};
  loadTableDataStatus: string | null;
  filters: Filters;
}

export type Filters = {
  from?: number;
  to?: number;
  selectedStatus?: string[];
  selectedExchangedType?: string[];
};

const initialState: IAppAuditState = {
  tableData: [],
  assetsDictionary: {},
  total: 0,
  paginateNum: 1,
  pageSize: 10,
  selectedStoreKeys: [],
  selectStatus: ['created', 'paid', 'finished', 'cancelled', 'failed'],
  selectExchangedType: ['assets', 'voucher'],
  merchandiseDic: {},
  loadTableDataStatus: null,
  filters: {},
};

export const { reducer, actions } = createSlice({
  name: 'miniMarket/order',
  initialState,
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setAssetsDictionary: (state, action) => {
      state.assetsDictionary = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setPaginateNum: (state, action) => {
      state.paginateNum = action.payload;
    },
    setItemChecked: (state, action) => {
      state.tableData.map((item: any) => {
        if (item.id === action.payload.id) {
          item.isConfirmed = action.payload.isConfirmed;
          item.confirmatorName = action.payload.confirmatorName;
        }
      });
    },
    setMerchandiseDic: (state, action) => {
      state.merchandiseDic = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
  extraReducers: {
    [updateSelectedStoreKeys.fulfilled.type]: (state, action) => {
      state.selectedStoreKeys = action.payload;
    },
    [requestTableData.pending.type]: (state) => {
      state.loadTableDataStatus = 'pending';
    },
    [requestTableData.fulfilled.type]: (state) => {
      state.loadTableDataStatus = 'fulfilled';
    },
  },
});

export const initType = initialState;

export const setTableData = actions.setTableData;
export const setTotal = actions.setTotal;
export const setPaginateNum = actions.setPaginateNum;
export const setItemChecked = actions.setItemChecked;
export const setPageSize = actions.setPageSize;
export const setAssetsDictionary = actions.setAssetsDictionary;
export const setMerchandiseDic = actions.setMerchandiseDic;
export const setFilters = actions.setFilters;
export default reducer;
