import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { publicIp } from '../../../../service/apiIP';
import { AppDispatch } from '../../../../common/utils/types';
import {
  getMemberCardDetailModalVisible,
  getSelectedMemberCardId,
  getSelectedMemberCardImageUrl,
  getIsUploadingSkinImagePending,
  getSelectedMemberCardType,
  getSelectedMemberCardDescription,
  getSelectedMemberCardDeleted,
} from '../../redux/memberCardDetails/selectors';
import {
  getMerchantName,
  getUserAuthorization,
} from '../../redux/common/selectors';
import { actions as memberCardDetailsActions } from '../../redux/memberCardDetails';
import {
  hideMemberCardDetails,
  updateTheMemberCard,
} from '../../redux/memberCardDetails/thunks';
import MemberCardModal from '../MemberCardModal';

const MemberCardDetailModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const merchantName = useSelector(getMerchantName);
  const userAuthorization = useSelector(getUserAuthorization);
  const selectedMemberCardId = useSelector(getSelectedMemberCardId);
  const memberCardImageUrl = useSelector(getSelectedMemberCardImageUrl);
  const isUploadingSkinImagePending = useSelector(
    getIsUploadingSkinImagePending
  );
  const memberCardType = useSelector(getSelectedMemberCardType);
  const memberCardDescription = useSelector(getSelectedMemberCardDescription);
  const memberCardDeleted = useSelector(getSelectedMemberCardDeleted);
  const memberCardDetailModalVisible = useSelector(
    getMemberCardDetailModalVisible
  );

  return (
    <MemberCardModal
      closable
      title="会员卡详细内容"
      okText="修改"
      imageUrl={memberCardImageUrl}
      isUploadingSkinImagePending={isUploadingSkinImagePending}
      imageCoverText={memberCardDeleted ? null : '点击更换图片'}
      cardType={memberCardType}
      description={memberCardDescription}
      deleted={memberCardDeleted}
      visible={memberCardDetailModalVisible}
      okButtonProps={{ disabled: memberCardDeleted }}
      uploadImageAction={`${publicIp}/merchants/${merchantName}/assets/member-cards/setting/types/${selectedMemberCardId}/pic`}
      uploadImageHeaders={{
        Authorization: userAuthorization,
      }}
      changeImage={(currentInfo: any) => {
        dispatch(
          memberCardDetailsActions.cardSkinImageUploadStatusSet(
            currentInfo[0].status
          )
        );

        if (currentInfo[0].status === 'done') {
          const { imageUrl } = currentInfo[0].response.data;

          dispatch(
            memberCardDetailsActions.cardSkinImageUrlSet({
              skinImageUrl: imageUrl,
            })
          );
        }
      }}
      changeCardType={(e: any) => {
        dispatch(memberCardDetailsActions.cardTypeSet(e.target.value) as any);
      }}
      changeCardDescription={(e: any) => {
        dispatch(
          memberCardDetailsActions.cardDescriptionSet(e.target.value) as any
        );
      }}
      onOk={() => {
        dispatch(updateTheMemberCard() as any);
      }}
      onCancel={() => {
        dispatch(hideMemberCardDetails() as any);
      }}
    />
  );
};

export default MemberCardDetailModal;
