import _uniq from 'lodash/uniq';
import { createSlice } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../../../../../service/constants';
import { AssociationPlayerDetailState } from './types';
import {
  loadAssociationPlayerById,
  loadSearchClubsFilter,
  transferClubForAssociationPlayer,
} from './thunks';

const initialState: AssociationPlayerDetailState = {
  data: {
    id: '',
    name: '',
    gender: '',
    phone: '',
    idCardNum: '',
    idCardType: '',
    registerDate: '',
    expiredDate: '',
    associationClubId: '',
    associationClubName: '',
    associationClubMemberId: '',
    registerNo: '',
  },
  clubs: {
    ids: [],
    pageNumber: 0,
    pageSize: 10,
    loadClubsStatus: API_REQUEST_STATUS.IDLE,
  },
  transferClub: {
    id: null,
    isTransferring: false,
  },
  associationPlayerDetailsVisibility: false,
  loadAssociationPlayerStatus: API_REQUEST_STATUS.IDLE,
  updateAssociationPlayerStatus: API_REQUEST_STATUS.IDLE,
  deleteAssociationPlayerStatus: API_REQUEST_STATUS.IDLE,
  transferAssociationPlayerStatus: API_REQUEST_STATUS.IDLE,
};

export const { reducer, actions } = createSlice({
  name: 'association/players/detail',
  initialState,
  reducers: {
    associationPlayerDetailVisibilityShown: (state) => {
      state.associationPlayerDetailsVisibility = true;
    },
    associationPlayerDetailVisibilityHidden: (state) => {
      state.associationPlayerDetailsVisibility = false;
    },
    associationPlayerDetailIdUpdated: (state, { payload }) => {
      state.data.id = payload;
    },
    associationPlayerDetailNameUpdated: (state, { payload }) => {
      state.data.name = payload;
    },
    associationPlayerDetailGenderUpdated: (state, { payload }) => {
      state.data.gender = payload;
    },
    associationPlayerDetailPhoneUpdated: (state, { payload }) => {
      state.data.phone = payload;
    },
    associationPlayerDetailIdCardNumUpdated: (state, { payload }) => {
      state.data.idCardNum = payload;
    },
    associationPlayerDetailIdCardTypeUpdated: (state, { payload }) => {
      state.data.idCardType = payload;
    },
    associationPlayerDetailRegisterDateUpdated: (state, { payload }) => {
      state.data.registerDate = payload;
    },
    associationPlayerDetailExpiredDateUpdated: (state, { payload }) => {
      state.data.expiredDate = payload;
    },
    associationPlayerDetailClubTransferTurnOn: (state) => {
      state.transferClub.isTransferring = true;
    },
    associationPlayerDetailClubTransferCanceled: (state) => {
      state.transferClub.isTransferring = false;
    },
    associationPlayerDetailTransferClubIdUpdated: (state, { payload }) => {
      state.transferClub.id = payload;
    },
    associationPlayerDetailReset: (state) => {
      state.data = {
        id: '',
        name: '',
        gender: '',
        phone: '',
        idCardNum: '',
        idCardType: '',
        registerDate: '',
        expiredDate: '',
        associationClubId: '',
        associationClubName: '',
        associationClubMemberId: '',
        registerNo: '',
      };
      state.clubs.loadClubsStatus = API_REQUEST_STATUS.IDLE;
      state.transferClub = {
        id: '',
        isTransferring: false,
      };
      state.loadAssociationPlayerStatus = API_REQUEST_STATUS.IDLE;
      state.updateAssociationPlayerStatus = API_REQUEST_STATUS.IDLE;
      state.deleteAssociationPlayerStatus = API_REQUEST_STATUS.IDLE;
      state.transferAssociationPlayerStatus = API_REQUEST_STATUS.IDLE;
    },
  },
  extraReducers: {
    [loadSearchClubsFilter.pending.type]: (state) => {
      state.clubs.loadClubsStatus = API_REQUEST_STATUS.PENDING;
    },
    [loadSearchClubsFilter.fulfilled.type]: (state, { payload }) => {
      state.clubs.loadClubsStatus = API_REQUEST_STATUS.FULFILLED;
      state.clubs.ids = payload || [];
    },
    [loadSearchClubsFilter.rejected.type]: (state) => {
      state.clubs.loadClubsStatus = API_REQUEST_STATUS.REJECTED;
    },
    [loadAssociationPlayerById.pending.type]: (state) => {
      state.loadAssociationPlayerStatus = API_REQUEST_STATUS.PENDING;
    },
    [loadAssociationPlayerById.fulfilled.type]: (state, { payload }) => {
      state.loadAssociationPlayerStatus = API_REQUEST_STATUS.FULFILLED;
      const { associationClubId } = payload || {};
      state.data = payload || {};
      state.clubs.ids = _uniq([...state.clubs.ids, associationClubId]);
    },
    [loadAssociationPlayerById.rejected.type]: (state) => {
      state.loadAssociationPlayerStatus = API_REQUEST_STATUS.REJECTED;
    },
    [transferClubForAssociationPlayer.pending.type]: (state) => {
      state.transferAssociationPlayerStatus = API_REQUEST_STATUS.PENDING;
    },
    [transferClubForAssociationPlayer.fulfilled.type]: (state, payload) => {
      const { associationClubId } = payload || {};

      state.transferAssociationPlayerStatus = API_REQUEST_STATUS.FULFILLED;
      state.data.associationClubId = associationClubId;
    },
    [transferClubForAssociationPlayer.rejected.type]: (state) => {
      state.transferAssociationPlayerStatus = API_REQUEST_STATUS.REJECTED;
    },
  },
});

export default reducer;
