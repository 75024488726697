import { createSlice } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../../service/constants';
import { loadEmployeeById, saveEmployeeById } from './thunks';

const initialState: {
  data: {};
  loadRequestStatus: string;
  saveRequestStatus: string;
} = {
  data: {},
  loadRequestStatus: API_REQUEST_STATUS.IDLE,
  saveRequestStatus: API_REQUEST_STATUS.IDLE,
};

export const { reducer, actions } = createSlice({
  name: 'employee/details',
  initialState,
  reducers: {
    backEmployeeListPage: (state: any) => {
      state.data = {};
      state.loadRequestStatus = API_REQUEST_STATUS.IDLE;
    },
    updateEmployeeDetail: (state: any, action: any) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
  },
  extraReducers: {
    [loadEmployeeById.fulfilled.type]: (
      state: typeof initialState,
      action: any
    ) => {
      state.data = {
        id: action.payload,
      };
      state.loadRequestStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [loadEmployeeById.rejected.type]: (state: typeof initialState) => {
      state.data = {};
      state.loadRequestStatus = API_REQUEST_STATUS.REJECTED;
    },
    [saveEmployeeById.fulfilled.type]: (state: typeof initialState) => {
      state.saveRequestStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [saveEmployeeById.rejected.type]: (state: typeof initialState) => {
      state.saveRequestStatus = API_REQUEST_STATUS.REJECTED;
    },
  },
});

export default reducer;
