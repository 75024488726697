import { createSlice } from '@reduxjs/toolkit';
import { WecomLoginStateTypes } from './types';
import { userWecomLogin } from './thunks';
import { API_REQUEST_STATUS } from '../../../../../service/constants';

const initialState: WecomLoginStateTypes = {
  loginStatus: API_REQUEST_STATUS.IDLE,
};

export const { reducer, actions } = createSlice({
  name: 'wecomLogin',
  initialState,
  reducers: {},
  extraReducers: {
    [userWecomLogin.pending.type]: (state) => {
      state.loginStatus = API_REQUEST_STATUS.PENDING;
    },
    [userWecomLogin.fulfilled.type]: (state) => {
      state.loginStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [userWecomLogin.rejected.type]: (state) => {
      state.loginStatus = API_REQUEST_STATUS.REJECTED;
    },
  },
});

export default reducer;
