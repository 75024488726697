import { post } from '../../service/api';

export const postOtp = async (
  payload: {
    merchantName: string;
    phoneNumber: string;
  },
  options?: { isShow: false }
) => {
  try {
    const { merchantName, ...restPayload } = payload;
    const result = await post(
      `/merchants/${merchantName}/accounts/otp`,
      restPayload,
      options
    );

    return result;
  } catch (e) {
    throw new Error('register failed');
  }
};

export const postUserLogin = async (
  payload: { merchantName: string; accountName: string; password: string },
  options?: { isShow: false }
) => {
  try {
    const { merchantName, ...restPayload } = payload;
    const result = await post(
      `/merchants/${merchantName}/employees.login`,
      restPayload,
      options
    );

    return result;
  } catch (e) {
    throw new Error('login failed');
  }
};
