import { createSlice } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../service/constants';
import { mount, updateNewTournamentSettings } from './thunks';

const initialState: {
  allowedCreators: string[];
  isEnabled: boolean;
  employeeIds: string[];
  updateDetailStatus: string;
} = {
  allowedCreators: [],
  isEnabled: false,
  employeeIds: [],
  updateDetailStatus: API_REQUEST_STATUS.IDLE,
};

export const { reducer, actions } = createSlice({
  name: 'tournament',
  initialState,
  reducers: {
    allowedCreatorsUpdated: (state: any, { payload }) => {
      state.allowedCreators = payload;
    },
    isEnabledUpdated: (state: any, { payload }) => {
      state.isEnabled = payload;
    },
  },
  extraReducers: {
    [mount.fulfilled.type]: (state, { payload }) => {
      state.allowedCreators = payload.allowedCreators;
      state.isEnabled = payload.isEnabled;
      state.employeeIds = payload.employeeList.ids;
    },
    [mount.rejected.type]: (state) => {
      state.allowedCreators = [];
    },
    [updateNewTournamentSettings.pending.type]: (state) => {
      state.updateDetailStatus = API_REQUEST_STATUS.PENDING;
    },
    [updateNewTournamentSettings.fulfilled.type]: (state) => {
      state.updateDetailStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [updateNewTournamentSettings.rejected.type]: (state) => {
      state.updateDetailStatus = API_REQUEST_STATUS.REJECTED;
    },
  },
});

export default reducer;
