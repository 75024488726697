import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import { RootState } from '../../../../../common/utils/types';
import { getMerchantName } from '../../../../../redux/app-v2/merchant/selectors';
import {
  getIsManager,
  getIsOwner,
} from '../../../../../redux/app-v2/user/selectors';
import { ALL_STORES_ENTITIES } from '../../../../../redux/entities/stores/constant';
import { loadStoresV2 as loadEntitiesStores } from '../../../../../redux/entities/stores/thunks';
import {
  getRankListById,
  getRankListInfo,
  getRankSettingsById,
  getRankSettingsList,
} from './api-request';
import {
  getRankingListPageSize,
  getRankingListPageNumber,
  getSelectedTimeInterval,
  getSelectedCategoryTypeName,
  getSelectedStoreId,
  getTimeStamp,
  getSelectedRankingSettingId,
  getStoreIdForRankingSetting,
} from './selectors';

export const loadRankingList: any = createAsyncThunk(
  'rankingList/loadRankingList',
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state) as string;
      const playerOffset: number =
        getRankingListPageNumber(state) * getRankingListPageSize(state);
      const playerLimit: number = getRankingListPageSize(state);
      const categoryTypeName: string = getSelectedCategoryTypeName(state);
      const storeId: any = getStoreIdForRankingSetting(state);
      const timeInterval = getSelectedTimeInterval(state);
      const startDate: any = getTimeStamp(state);
      const rankingSettingId = getSelectedRankingSettingId(state);
      const resInfos: any = await getRankListInfo({
        merchantName,
        startDate,
        storeId,
        timeInterval,
        rankingSettingId,
      });

      const targetRankingInfo = resInfos.find((resInfo: any) => {
        if (resInfo.categories === null) {
          return false;
        } else {
          return resInfo.categories.some(
            (item: any) => item?.value === categoryTypeName
          );
        }
      });

      if (resInfos.length === 0) {
        message.error('此种筛选组合下，没有数据');
        return resInfos;
      }

      const id = targetRankingInfo.id;
      const res: any = await getRankListById({
        merchantName,
        playerOffset,
        playerLimit,
        id,
      });

      return res;
    } catch (e) {
      message.error('加载排行榜失败');

      throw e;
    }
  }
);

export const loadRankingSettingsById: any = createAsyncThunk(
  'rankingList/loadRankingSettingsById',
  async (id: string, { getState }) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state) as string;
      const res: any = await getRankSettingsById({ merchantName, id });

      return res.data;
    } catch (e) {
      message.error('加载排行榜管理详情失败');

      throw e;
    }
  }
);

export const loadRankSettingsList = createAsyncThunk(
  'rankingList/loadRankSettingsList',
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state) as string;
      const offset: number = 0;
      const limit: number = 999;
      const res: any = await getRankSettingsList({
        merchantName,
        limit,
        offset,
      });

      return res.data;
    } catch (e) {
      message.error('加载排行榜管理失败');

      throw e;
    }
  }
);

export const loadStoresFilter = createAsyncThunk(
  'rankingList/stores',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const isManager = getIsManager(state);
      const isOwner = getIsOwner(state);
      const offset: number = 0;
      const limit: number = 1000;

      const { payload }: any = await dispatch(
        loadEntitiesStores({ offset, limit })
      );

      if (isManager || isOwner) {
        payload.ids = [ALL_STORES_ENTITIES.id, ...payload.ids];
      }

      return payload;
    } catch (e) {
      message.error('加载门店列表失败');
    }
  }
);
