import _get from 'lodash/get';
import moment from 'moment';
import { createSelector } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../service/constants';
import { BUSINESS_TYPES, MERCHANT_TYPE } from '../../../common/constants';
import { getFormatLocalTime } from '../../../common/utils/time-lib';
import { getPricePlansMapping } from '../../entities/pricePlans/selectors';

export const getMerchantName = (state: any) =>
  _get(state.appV2, 'merchant.merchantName', '');

export const getMerchantType = (state: any) =>
  _get(state.appV2, 'merchant.type', MERCHANT_TYPE.club) || MERCHANT_TYPE.club;

export const getLoadMerchantInfoStatus = (state: any) =>
  _get(state.appV2, 'merchant.loadMerchantInfoStatus', API_REQUEST_STATUS.IDLE);

export const getIsMerchantInfoInitialized = createSelector(
  getLoadMerchantInfoStatus,
  (loadMerchantInfoStatus) =>
    [API_REQUEST_STATUS.FULFILLED, API_REQUEST_STATUS.REJECTED].includes(
      loadMerchantInfoStatus
    )
);

export const getIsClubMerchant = createSelector(
  getMerchantType,
  (merchantType) => merchantType === MERCHANT_TYPE.club
);

export const getIsAssociationMerchant = createSelector(
  getMerchantType,
  (merchantType) => merchantType === MERCHANT_TYPE.association
);

export const getMerchantDisplayName = (state: any) =>
  state.appV2.merchant.displayName;

export const getMerchantVoteLink = (state: any) =>
  state.appV2.merchant.voteLink;

export const getMerchantEnableLiveStream = (state: any) =>
  state.appV2.merchant.enableLiveStream;

export const getMerchantLiveStreamId = (state: any) =>
  state.appV2.merchant.liveStreamId;

export const getMerchantLogo = (state: any) =>
  state.appV2.merchant.logoImageUrl;

export const getMerchantBanners = (state: any) => state.appV2.merchant.banners;

export const getMerchantAgreement = (state: any) =>
  state.appV2.merchant.userAgreement;

export const getLoadMerchantSubscriptionStatus = (state: any) =>
  state.appV2.merchant.loadMerchantSubscriptionStatus;

export const getIsLoadMerchantSubscriptionPending = createSelector(
  getLoadMerchantSubscriptionStatus,
  (loadMerchantSubscriptionStatus) =>
    loadMerchantSubscriptionStatus === API_REQUEST_STATUS.PENDING
);

export const getIsMerchantSubscriptionInitialized = createSelector(
  getLoadMerchantSubscriptionStatus,
  (loadMerchantSubscriptionStatus) =>
    [API_REQUEST_STATUS.FULFILLED, API_REQUEST_STATUS.REJECTED].includes(
      loadMerchantSubscriptionStatus
    )
);

export const getMerchantInfo = (state: any) => state.appV2.merchant;

export const getMerchantSubscriptionPricePlanType = (state: any) =>
  _get(state.appV2.merchant, 'subscriptions.pricePlanType', '');

export const getMerchantSubscriptionAddonStoreCount = (state: any) =>
  _get(state.appV2.merchant, 'subscriptions.addonStoreCount', 0);

export const getMerchantSubscriptionStatus = (state: any) =>
  _get(state.appV2.merchant, 'subscriptions.status', '');

export const getMerchantSubscriptionEndTime = (state: any) =>
  _get(state.appV2.merchant, 'subscriptions.endTime', '');

export const getMerchantSubscriptionTrialEnded = (state: any) =>
  _get(state.appV2.merchant, 'subscriptions.trialEnded', '');

export const getMerchantSubscriptionPricePlanId = (state: any) =>
  _get(state.appV2.merchant, 'subscriptions.pricePlanId', null);

export const getMerchantSubscriptionCompanyName = (state: any) =>
  _get(state.appV2.merchant, 'subscriptions.companyName', '');

export const getMerchantSubscriptionCompanyAddress = (state: any) =>
  _get(state.appV2.merchant, 'subscriptions.companyAddress', '');

export const getMerchantSubscriptionPrincipalName = (state: any) =>
  _get(state.appV2.merchant, 'subscriptions.principalName', '');

export const getMerchantDisplayEndTime = createSelector(
  getMerchantSubscriptionEndTime,
  (subscriptionEndTime) =>
    getFormatLocalTime(subscriptionEndTime, 'YYYY-MM-DD HH:mm')
);

export const getMerchantExpiredDays = createSelector(
  getMerchantSubscriptionEndTime,
  (subscriptionEndTime) => {
    const endTimeMoment = moment(subscriptionEndTime).utc();
    const currentTimeMoment = moment().utc();

    return endTimeMoment.diff(currentTimeMoment, 'days');
  }
);

export const getMerchantPricePlan = createSelector(
  getMerchantSubscriptionPricePlanId,
  getPricePlansMapping,
  (pricePlanId, pricePlansMapping) => pricePlansMapping[pricePlanId]
);

export const getMerchantLimitedFeatures = createSelector(
  getMerchantSubscriptionPricePlanId,
  getPricePlansMapping,
  (pricePlanId, pricePlansMapping) => {
    const pricePlan = pricePlansMapping[pricePlanId];

    return pricePlan?.limitedFeatures;
  }
);

export const getMerchantBusiness = (state: any) =>
  _get(state.appV2, 'merchant.business', '');

export const getIsBaseball = createSelector(
  getMerchantBusiness,
  (business) => business === BUSINESS_TYPES.Baseball
);

export const getIsArchery = createSelector(
  getMerchantBusiness,
  (business) => business === BUSINESS_TYPES.Archery
);
