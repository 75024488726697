import { createSlice } from '@reduxjs/toolkit';
import {
  createTempTournament,
  loadHistoryClubsRankingImportTemplate,
  loadHistoryPlayersAchievementImportTemplate,
  loadTempTournamentList,
  updateTournamentImage,
} from './thunks';
import { API_REQUEST_STATUS } from '../../../../../service/constants';

const initialState: {
  data: {
    clubHeaders: any[];
    playerHeaders: any[];
    shownComponent: string | null;
    tempTournamentId: string | null;
    tournamentList: any[];
    image: {
      url: string | null;
      status: string | null;
      name: string | null;
    };
  };
  loadHistoryClubsRankingImportTemplateStatus: string;
  loadHistoryPlayersAchievementImportTemplateStatus: string;
  createTempTournamentStatus: string;
  updateTournamentImageStatus: string;
} = {
  data: {
    clubHeaders: [],
    playerHeaders: [],
    shownComponent: null,
    tempTournamentId: null,
    tournamentList: [],
    image: {
      url: null,
      status: null,
      name: null,
    },
  },
  loadHistoryClubsRankingImportTemplateStatus: API_REQUEST_STATUS.IDLE,
  loadHistoryPlayersAchievementImportTemplateStatus: API_REQUEST_STATUS.IDLE,
  createTempTournamentStatus: API_REQUEST_STATUS.IDLE,
  updateTournamentImageStatus: API_REQUEST_STATUS.IDLE,
};

export const { reducer, actions } = createSlice({
  name: 'functions',
  initialState,
  reducers: {
    componentShown: (state, { payload }) => {
      state.data.shownComponent = payload;
    },
    tempTournamentIdUpdated: (state, { payload }) => {
      state.data.tempTournamentId = payload;

      const tournament = state.data.tournamentList.find(
        (item: any) => item.id === payload
      );
      state.data.image.url = tournament?.imageUrl || null;
      state.data.image.status = tournament?.imageUrl ? 'done' : null;
    },
    imageUploadStatusSet: (state, { payload }) => {
      state.data.image = {
        ...state.data.image,
        status: payload,
      };
    },
    imageUrlSet: (state, { payload }) => {
      state.data.image.url = payload;
    },
  },
  extraReducers: {
    [loadHistoryClubsRankingImportTemplate.pending.type]: (state) => {
      state.loadHistoryClubsRankingImportTemplateStatus =
        API_REQUEST_STATUS.PENDING;
    },
    [loadHistoryClubsRankingImportTemplate.fulfilled.type]: (
      state,
      { payload }
    ) => {
      const title = payload?.firstLine?.split(',');

      state.loadHistoryClubsRankingImportTemplateStatus =
        API_REQUEST_STATUS.FULFILLED;
      state.data.clubHeaders = (title || []).map((item: string) => ({
        label: item,
        key: item,
      }));
    },
    [loadHistoryPlayersAchievementImportTemplate.pending.type]: (state) => {
      state.loadHistoryPlayersAchievementImportTemplateStatus =
        API_REQUEST_STATUS.PENDING;
    },
    [loadHistoryPlayersAchievementImportTemplate.fulfilled.type]: (
      state,
      { payload }
    ) => {
      const title = payload?.firstLine?.split(',');

      state.loadHistoryPlayersAchievementImportTemplateStatus =
        API_REQUEST_STATUS.FULFILLED;
      state.data.playerHeaders = (title || []).map((item: string) => ({
        label: item,
        key: item,
      }));
    },
    [loadTempTournamentList.fulfilled.type]: (state, { payload }) => {
      state.data.tournamentList = payload;

      if (state.data.tempTournamentId === '' || !state.data.tempTournamentId) {
        state.data.tempTournamentId = payload[0]?.id || null;
        state.data.image.url = payload[0]?.imageUrl || null;
        state.data.image.status = payload[0]?.imageUrl ? 'done' : null;
      }
    },
    [createTempTournament.fulfilled.type]: (state, { payload }: any) => {
      const { id } = payload;
      const newTournamentList: any = [
        payload,
        ...state.data.tournamentList.filter((item: any) => item.id !== id),
      ];
      state.data.tempTournamentId = id;
      state.data.tournamentList = newTournamentList;

      state.createTempTournamentStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [createTempTournament.pending.type]: (state) => {
      state.createTempTournamentStatus = API_REQUEST_STATUS.PENDING;
    },
    [createTempTournament.rejected.type]: (state) => {
      state.createTempTournamentStatus = API_REQUEST_STATUS.REJECTED;
    },
    [updateTournamentImage.pending.type]: (state) => {
      state.updateTournamentImageStatus = API_REQUEST_STATUS.PENDING;
    },
    [updateTournamentImage.fulfilled.type]: (state, { payload }) => {
      const { id, imageUrl } = payload;
      const newTournamentList: any[] = state.data.tournamentList.map(
        (item: any) => {
          if (item.id === id) {
            return {
              ...item,
              imageUrl,
            };
          }

          return item;
        }
      );

      state.data.tournamentList = newTournamentList;
      state.updateTournamentImageStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [updateTournamentImage.rejected.type]: (state) => {
      state.updateTournamentImageStatus = API_REQUEST_STATUS.REJECTED;
    },
  },
});

export default reducer;
