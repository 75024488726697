import { get } from '../../../../../../../service/api';
import { HISTORIES_TYPES } from '../../../utils/constants';

export const getHistoryClubsRankingImportTasks = async (
  merchantName: string,
  parameters: { offset: number; limit: number; tempTournamentId: string }
) => {
  try {
    const result = await get(
      `/merchants/${merchantName}/baseball/stats/${HISTORIES_TYPES.BASEBALL_STAT_CLUB}/import/tasks`,
      parameters
    );
    return result;
  } catch (error) {
    throw error;
  }
};

export const getHistoryTempTournamentById = async (
  merchantName: string,
  tempTournamentId: string
) => {
  try {
    const result = await get(
      `/merchants/${merchantName}/baseball/tournaments/${tempTournamentId}`,
      {}
    );

    return result;
  } catch (error) {
    throw error;
  }
};
