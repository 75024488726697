import { combineReducers } from '@reduxjs/toolkit';
import employees from './employees';
import stores from './stores';
import players from './players';
import pricePlans from './pricePlans';

const reducer = combineReducers({
  employees,
  stores,
  players,
  pricePlans,
});

export default reducer;
