import React, { useEffect, useState } from 'react';
import { useMount } from 'react-use';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Form, Input, Result, message, Select } from 'antd';
import { AppDispatch } from '../../../../../../common/utils/types';
import { errorMessage } from '../../../../../../common/utils/feedback';
import { ROLES } from '../../../../../../common/constants';
import { loadEmployeeStoresFilter } from '../../../../redux/common/thunks';
import { getEmployeeFilterStores } from '../../../../redux/common/selectors';
import { createEmployeeAdmin } from '../../redux/thunks';
import {
  getIsOwner,
  getIsStoreManager,
} from '../../../../../../redux/app-v2/user/selectors';
import PhoneNumberInput from '../../../../../../common/components/PhoneNumberInput';
import './EmployeeAdminModal.less';

const EmployeeAdminModal: React.FC<{
  roleName: string;
  openModal: () => void;
}> = ({ roleName, openModal }) => {
  const dispatch = useDispatch<AppDispatch>();
  const employeeFilterStores = useSelector(getEmployeeFilterStores);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [addedEmployeeAdmin, setAddedEmployeeAdmin] = useState(false);
  const [selectedStore, setSelectedStore] = useState(
    employeeFilterStores[0]?.value
  );
  const ROLE_MAPPING = {
    [ROLES.Manager]: '全局管理员',
    [ROLES.StoreManager]: '门店管理员',
    [ROLES.Operation]: '运营人员',
    [ROLES.Judge]: '裁判',
  };
  const isOwner = useSelector(getIsOwner);
  const isStoreManager = useSelector(getIsStoreManager);

  useEffect(() => {
    if (!isModalVisible) {
      setName('');
      setPhone('');
      setAddedEmployeeAdmin(false);
    }
  }, [isModalVisible]);

  useMount(() => {
    dispatch(loadEmployeeStoresFilter());
  });

  useEffect(() => {
    if (employeeFilterStores.length) {
      setSelectedStore(employeeFilterStores[0].value);
    }
  }, [employeeFilterStores]);

  const getVisible = () => {
    switch (roleName) {
      case ROLES.Crew:
      case ROLES.Operation:
      case ROLES.Judge:
        return true;
      case ROLES.StoreManager:
        return !isStoreManager;
      case ROLES.Manager:
        return isOwner;
      default:
        return false;
    }
  };

  if (!getVisible() || employeeFilterStores.length <= 0) {
    return null;
  }

  return (
    <>
      <Button
        className="insertButton"
        onClick={() => {
          openModal();
          setIsModalVisible(true);
        }}
      >
        <span>添加{ROLE_MAPPING[roleName]}</span>
      </Button>

      <Modal
        centered
        title={`添加${ROLE_MAPPING[roleName]}`}
        visible={isModalVisible}
        onOk={async () => {
          try {
            await form.validateFields();
            const { error }: any = await dispatch(
              createEmployeeAdmin({
                phone,
                name,
                roleName,
                storeId: selectedStore,
              })
            );
            if (error?.message) {
              errorMessage(error.message || '添加失败');
            } else {
              setAddedEmployeeAdmin(true);
            }
          } catch (e) {
            message.error('添加失败');
          }
        }}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        // If footer is undefined, the DOM will use ant.design default modal footer
        footer={addedEmployeeAdmin ? null : undefined}
      >
        {addedEmployeeAdmin ? (
          <>
            <Result
              className="employeeAdminModalResult"
              status="success"
              title={`成功添加了${ROLE_MAPPING[roleName]}`}
              subTitle={
                <p className="employeeAdminModalSuccessText">
                  请通知新添加的{ROLE_MAPPING[roleName]}第一次登陆前到
                  <Link to="/forget-password" target="_blank">
                    忘记密码页面
                  </Link>
                  中设置密码
                </p>
              }
            />
          </>
        ) : (
          <Form layout="vertical" form={form} preserve={false}>
            <Form.Item
              className="formItem"
              label={ROLE_MAPPING[roleName]}
              name="name"
              rules={[
                {
                  required: true,
                  message: `请填写${ROLE_MAPPING[roleName]}的名称`,
                },
              ]}
            >
              <Input
                placeholder={`请输入${ROLE_MAPPING[roleName]}名称`}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Form.Item>
            <PhoneNumberInput
              name="phone"
              label="联系电话(账号)"
              placeholder={`请输入${ROLE_MAPPING[roleName]}手机号码`}
              phoneNumber={phone}
              onChange={(phone: string) => {
                setPhone(phone);
              }}
            />
            <Form.Item className="formItem" label="所在门店">
              <Select
                placeholder="选择一个门店"
                value={selectedStore}
                onChange={(value: string) => {
                  setSelectedStore(value);
                }}
                options={employeeFilterStores}
              />
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default EmployeeAdminModal;
