import { createSlice } from '@reduxjs/toolkit';
import {
  updateSelectedStoreKeys,
  requestTableData,
  mounted,
  loadSearchingPlayers,
} from './thunks';

interface IAppAuditState {
  paginateNum: number;
  assetsDictionary: { [key: string]: string };
  tableData: any[];
  total: number;
  pageSize: number;
  selectedStoreKeys: string[];
  loadTableDataStatus: string | null;
  auditOptions: object;
  storesInfo: {
    end: boolean;
    total: number;
    pageSize: number;
    pageNumber: number;
    ids: string[];
  };
  selectedPlayerId: string | undefined;
  selectedPlayerKeyword: string;
  players: [];
  playersPagination: {
    end: boolean;
    total: number;
  };
  memberSearchFrom: number;
  memberSearchTo: number;
  employeePageSize: number;
  employeePaginate: number;
}

const initialState: IAppAuditState = {
  tableData: [],
  assetsDictionary: {},
  total: 0,
  paginateNum: 1,
  pageSize: 10,
  selectedStoreKeys: [],
  loadTableDataStatus: null,
  auditOptions: {},
  storesInfo: {
    end: true,
    total: 0,
    pageSize: 10,
    pageNumber: 0,
    ids: [],
  },
  selectedPlayerId: undefined,
  selectedPlayerKeyword: '',
  players: [],
  playersPagination: {
    end: true,
    total: 0,
  },
  memberSearchFrom: 0,
  memberSearchTo: 0,
  employeePageSize: 10,
  employeePaginate: 1,
};

export const { reducer, actions } = createSlice({
  name: 'audit',
  initialState,
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setAssetsDictionary: (state, action) => {
      state.assetsDictionary = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setPaginateNum: (state, action) => {
      state.paginateNum = action.payload;
    },
    setItemChecked: (state, action) => {
      state.tableData.map((item: any) => {
        if (item.id === action.payload.id) {
          item.isConfirmed = action.payload.isConfirmed;
          item.confirmatorName = action.payload.confirmatorName;
        }
      });
    },
    setSelectedPlayerId: (state, { payload }) => {
      state.selectedPlayerId = payload;
    },
    changeMemberSearchTime(state, action: any) {
      state.memberSearchFrom = action.payload.searchFrom;
      state.memberSearchTo = action.payload.searchTo;
    },
    changeEmployeeSearchPage(state, action: any) {
      state.employeePageSize = action.payload.pageSize;
      state.employeePaginate = action.payload.paginate;
    },
  },
  extraReducers: {
    [updateSelectedStoreKeys.fulfilled.type]: (state, action) => {
      state.selectedStoreKeys = action.payload;
    },
    [requestTableData.pending.type]: (state) => {
      state.loadTableDataStatus = 'pending';
    },
    [requestTableData.fulfilled.type]: (state) => {
      state.loadTableDataStatus = 'fulfilled';
    },
    [mounted.fulfilled.type]: (state, { payload }) => {
      const { auditOptions, storesInfo } = payload || {};

      state.auditOptions = auditOptions;

      if (storesInfo) {
        state.storesInfo = {
          ...state.storesInfo,
          ...storesInfo,
        };
      }
    },
    [loadSearchingPlayers.fulfilled.type]: (state, { payload }) => {
      state.players = payload.data;
      state.playersPagination = payload.pagination;
    },
  },
});

export const initType = initialState;
export default reducer;
