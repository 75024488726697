import { createSlice } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../../../../service/constants';
import { ENROLLMENT_LIMITATION_TYPES_MAPPING } from '../../../utils/constants';
import {
  loadArcheryCompetitionOptions,
  addNewEnrollmentDetails,
  loadEnrollmentDetailsById,
  loadPosterList,
  updateSelectedClubNestItemSetting,
} from './thunks';

const initialState = {
  enrollmentDetailsTurnOnInitial: false,
  enrollmentDetailsData: {
    id: null,
    creatorId: null,
    title: '',
    merchantName: 'zyas',
    coverImageUrl: '',
    maxIndividualEnrolledCount: null,
    maxClubEnrolledCount: null,
    maxPlayerPerClubEnrolledCount: null,
    enrollmentLimitType: ENROLLMENT_LIMITATION_TYPES_MAPPING.INDIVIDUAL,
    deadline: undefined,
    turnedOn: false,
    posterId: '',
  },
  clubFormInfo: [],
  playerInfo: [
    {
      propertyName: 'name',
      propertyType: 'charactor',
      required: true,
      default: true,
      optionTypes: [],
    },
    {
      propertyName: 'phoneNumber',
      propertyType: 'phoneNumber',
      required: true,
      default: true,
      optionTypes: [],
    },
    {
      propertyName: 'idCardType',
      propertyType: 'option',
      required: true,
      default: true,
      optionTypes: ['IDCard', 'passport', 'HongKong/Macau/Taiwan'],
    },
    {
      propertyName: 'idCardNum',
      propertyType: 'charactor',
      required: true,
      default: true,
      optionTypes: [],
    },
    {
      propertyName: 'gender',
      propertyType: 'option',
      required: true,
      default: true,
      optionTypes: ['male', 'female'],
    },
  ],
  gameInfo: {
    allowCrossCategories: false,
    categories: [],
  },
  postersInfo: {
    data: [],
    status: API_REQUEST_STATUS.IDLE,
  },
  selectedGameOptionSetting: {
    id: null,
  },
  newGameOptionSetting: {
    categoryId: null,
    showGameOptionSettingNewModal: false,
    showArcheryGameOptionSettingNewModal: false,
  },
  selectedClubNestItemSetting: {
    id: null,
    propertyName: '',
    description: '',
    propertyType: '',
    maxValue: 0,
    minValue: 0,
    enableChargePrice: false,
    pricingInfo: {
      price: null,
    },
    showModal: false,
  },
  clubNestItemSetting: {
    clubId: null,
    showNewModal: false,
  },
  loadEnrollmentDetailsByIdStatus: API_REQUEST_STATUS.IDLE,
  loadArcheryCompetitionOptions: {
    data: null,
    status: API_REQUEST_STATUS.IDLE,
  },
};

export const { reducer, actions } = createSlice({
  name: 'enrollmentDetails',
  initialState,
  reducers: {
    updateEnrollmentDetailsData: (state: any, action: any) => {
      state.enrollmentDetailsData = {
        ...state.enrollmentDetailsData,
        ...action.payload,
      };
    },
    resetAll: () => initialState,
    updateTurnOn: (state: any, { payload }) => {
      state.enrollmentDetailsData.turnedOn = payload;
    },
    setPlayInfoSaved: (state: any) => {
      state.playerInfo = (state.playerInfo || []).map((playInfoItem: any) => {
        if (state.enrollmentDetailsData.turnedOn) {
          playInfoItem = { ...playInfoItem, saved: true };
        }

        return playInfoItem;
      });
    },
    setGameInfoSaved: (state: any) => {
      state.gameInfo.categories = state.gameInfo.categories.map(
        (category: {
          id: string;
          isOpenOrYouthGroup: string;
          name: string;
          options: Array<any>;
        }) => {
          const { id, isOpenOrYouthGroup, name, options } = category;

          const newOptions = options.map((option: any) => {
            if (state.enrollmentDetailsData.turnedOn) {
              return { ...option, saved: true };
            } else {
              return option;
            }
          });
          if (state.enrollmentDetailsData.turnedOn) {
            return {
              id,
              name,
              isOpenOrYouthGroup,
              options: newOptions,
              saved: true,
            };
          } else {
            return category;
          }
        }
      );
    },
    // ClubInfo
    showClubNestItemSettingNewModal: (state: any) => {
      state.clubNestItemSetting.showNewModal = true;
    },
    hideClubNestItemSettingNewModal: (state: any) => {
      state.clubNestItemSetting.showNewModal = false;
    },
    updateNewClubNestItemSettingClubId: (state: any, { payload }: any) => {
      state.clubNestItemSetting.clubId = payload;
    },
    addNewClubInfoItem: (state: any) => {
      const timeStampId = Date.now();
      state.clubFormInfo.push({
        id: `club-item-${timeStampId}`,
        propertyName: '',
        propertyType: 'charactor',
        required: true,
        default: false,
        optionTypes: [],
        items: [],
      });
    },
    updateClubInfoProperty: (state: any, { payload }: any) => {
      const { currentClubFormInfoId, clubFormInfoItemProperty } = payload;

      state.clubFormInfo = state.clubFormInfo.map((clubInfoItem: any) => {
        if (clubInfoItem.id === currentClubFormInfoId) {
          clubInfoItem = { ...clubInfoItem, ...clubFormInfoItemProperty };
        }

        return clubInfoItem;
      });
    },
    removeClubInfoItem: (state: any, { payload }: any) => {
      const currentClubFormInfoId = payload;

      state.clubFormInfo = state.clubFormInfo.filter(
        (element: any) => element.id !== currentClubFormInfoId
      );

      return state;
    },
    // ClubInfoOptionType
    updateClubInfoOptionType: (state: any, { payload }: any) => {
      const { currentClubInfoId, optionTypes } = payload;

      state.clubFormInfo = state.clubFormInfo.map((clubInfoItem: any) => {
        if (clubInfoItem.id === currentClubInfoId) {
          clubInfoItem.optionTypes = optionTypes;
        }

        return clubInfoItem;
      });
    },
    addNewClubInfoNestItemsOption: (state: any, { payload }) => {
      const { currentClubInfoId, option } = payload;
      const {
        propertyName,
        description,
        propertyType,
        maxValue,
        enableChargePrice,
        pricingInfo,
      } = option;

      state.clubFormInfo = state.clubFormInfo.map((clubInfo: any) => {
        if (clubInfo.id === currentClubInfoId) {
          clubInfo.items.push({
            id: `club-item-${currentClubInfoId}-${clubInfo.items.length}`,
            propertyName,
            description,
            propertyType,
            maxValue,
            enableChargePrice,
            pricingInfo,
          });
        }

        return clubInfo;
      });
    },
    showSelectedClubInfoNestItemSettingModal: (state: any) => {
      state.selectedClubNestItemSetting.showModal = true;
    },
    hideSelectedClubInfoNestItemSettingModal: (state: any) => {
      state.selectedClubNestItemSetting.showModal = false;
    },
    updateClubInfoNestItemPropertyName: (state: any, { payload }: any) => {
      state.selectedClubNestItemSetting.propertyName = payload;
    },
    updateClubInfoNestItemDescription: (state: any, { payload }: any) => {
      state.selectedClubNestItemSetting.description = payload;
    },
    updateClubInfoNestItemPropertyType: (state: any, { payload }: any) => {
      state.selectedClubNestItemSetting.propertyType = payload;
    },
    updateClubInfoNestItemMaxValue: (state: any, { payload }: any) => {
      state.selectedClubNestItemSetting.maxValue = payload;
    },
    updateClubInfoNestItemEnableChargePrice: (state: any, { payload }: any) => {
      state.selectedClubNestItemSetting.enableChargePrice = payload;
    },
    updateClubInfoNestItemPrice: (state: any, { payload }: any) => {
      if (state.selectedClubNestItemSetting.pricingInfo === undefined) {
        state.selectedClubNestItemSetting.pricingInfo = {};
      }

      state.selectedClubNestItemSetting.pricingInfo.price = payload;
    },
    removeSelectedClubNestItemSetting: (state: any, { payload }) => {
      state.clubFormInfo.forEach((clubInfo: any) => {
        clubInfo.items = clubInfo.items.filter(
          (item: { id: string }) => item.id !== payload
        );
      });
    },
    updateSelectedClubNestItemSetting: (state: any, { payload }: any) => {
      const {
        id,
        propertyName,
        description,
        propertyType,
        maxValue,
        enableChargePrice,
        pricingInfo,
      } = payload || {};

      state.clubFormInfo.forEach((clubInfo: any) => {
        clubInfo.items.forEach((item: any) => {
          if (item.id === id) {
            item.propertyName = propertyName;
            item.description = description;
            item.propertyType = propertyType;
            item.maxValue = maxValue;
            item.enableChargePrice = enableChargePrice;
            item.pricingInfo = pricingInfo;
          }
        });
      });
    },
    resetSelectedClubNestItemSetting: (state: any) => {
      state.selectedClubNestItemSetting = {
        id: null,
        propertyName: '',
        description: '',
        propertyType: '',
        maxValue: 0,
        enableChargePrice: false,
        pricingInfo: {
          price: null,
        },
      };
    },
    // PlayInfo
    updatePlayInfoProperty: (state: any, { payload }: any) => {
      const { currentPlayInfoId, playInfoItemProperty } = payload;

      state.playerInfo = state.playerInfo.map((playInfoItem: any) => {
        if (playInfoItem.id === currentPlayInfoId) {
          playInfoItem = { ...playInfoItem, ...playInfoItemProperty };
        }

        return playInfoItem;
      });
    },
    addNewPlayInfoItem: (state: any) => {
      const timeStampId = Date.now();
      state.playerInfo.push({
        id: `player-item-${timeStampId}`,
        propertyName: '',
        propertyType: 'charactor',
        required: true,
        default: false,
        optionTypes: [],
      });
    },
    removePlayInfoItem: (state: any, { payload }: any) => {
      const currentPlayInfoId = payload;

      state.playerInfo = state.playerInfo.filter(
        (element: any) => element.id !== currentPlayInfoId
      );

      return state;
    },
    //PlayInfoOptionType
    updatePlayInfoOptionType: (state: any, { payload }: any) => {
      const { currentPlayInfoId, optionTypes } = payload;

      state.playerInfo = state.playerInfo.map((playInfoItem: any) => {
        if (playInfoItem.id === currentPlayInfoId) {
          playInfoItem.optionTypes = optionTypes;
        }

        return playInfoItem;
      });
    },
    // GameInfo
    updateGameInfoAllowCrossCategories: (state: any, { payload }) => {
      state.gameInfo.allowCrossCategories = payload;
    },
    // GameInfoCategory
    updateGameInfoCategoryIsOpenOrYouthGroup: (
      state: any,
      { payload }: any
    ) => {
      const { currentGameInfoCategoryId, isOpenOrYouthGroup } = payload;

      state.gameInfo.categories = state.gameInfo.categories.map(
        (category: any) => {
          if (category.id === currentGameInfoCategoryId) {
            category.isOpenOrYouthGroup = isOpenOrYouthGroup;
          }

          return category;
        }
      );
    },
    updateGameInfoCategoryName: (state: any, { payload }: any) => {
      const { currentGameInfoCategoryId, GameInfoCategoryName } = payload;

      state.gameInfo.categories = state.gameInfo.categories.map(
        (category: any) => {
          if (category.id === currentGameInfoCategoryId) {
            category.name = GameInfoCategoryName;
          }

          return category;
        }
      );
    },

    addNewGameInfoCategory: (
      state: any,
      {
        payload,
      }: {
        payload:
          | {
              isArchery: boolean;
            }
          | undefined;
      }
    ) => {
      const { isArchery }: any = payload || {};

      const timeStampId = Date.now();
      state.gameInfo.categories.push({
        id: `category-item-${timeStampId}`,
        allowCrossCategories: false,
        isArchery,
        options: [],
      });
    },
    removeGameInfoCategory: (state: any, { payload }: any) => {
      const { currentGameInfoCategoryId } = payload;
      const targetIndex = state.gameInfo.categories.findIndex(
        (element: any) => element.id === currentGameInfoCategoryId
      );

      state.gameInfo.categories.splice(targetIndex, 1);
    },

    // GameInfoCategoryOption
    updateSelectedGameOptionId: (state: any, { payload }: any) => {
      state.selectedGameOptionSetting.id = payload;
    },
    updateGameInfoCategoryOptionName: (state: any, { payload }: any) => {
      state.selectedGameOptionSetting.name = payload;
    },
    updateGameInfoCategoryOptionTeamPlayerCount: (
      state: any,
      { payload }: any
    ) => {
      state.selectedGameOptionSetting.teamPlayerCount = payload;
    },
    updateGameInfoCategoryOptionEnableChargePrice: (
      state: any,
      { payload }: any
    ) => {
      state.selectedGameOptionSetting.enableChargePrice = payload;
    },
    updateGameInfoCategoryOptionPrice: (state: any, { payload }: any) => {
      state.selectedGameOptionSetting.price = payload;
    },
    updateGameInfoCategoryOptionAssociationPlayerPrice: (
      state: any,
      { payload }: any
    ) => {
      state.selectedGameOptionSetting.associationPlayerPrice = payload;
    },
    resetSelectedGameOption: (state: any) => {
      state.selectedGameOptionSetting = {
        id: null,
      };
    },
    updateGameInfoCategoryOption: (state: any, { payload }: any) => {
      const {
        currentGameInfoCategoryId,
        currentGameInfoCategoryOptionId,
        option,
      } = payload;

      state.gameInfo.categories = state.gameInfo.categories.map(
        (category: any) => {
          const targetIndex = category.options.findIndex(
            (element: any) => element.id === currentGameInfoCategoryOptionId
          );
          if (category.id === currentGameInfoCategoryId) {
            category.options[targetIndex] = {
              ...category.options[targetIndex],
              ...option,
            };
          }

          return category;
        }
      );
    },
    showGameOptionSettingNewModal: (state: any) => {
      state.newGameOptionSetting.showGameOptionSettingNewModal = true;
    },
    hideGameOptionSettingNewModal: (state: any) => {
      state.newGameOptionSetting.showGameOptionSettingNewModal = false;
    },
    updateNewGameOptionSettingCategoryId: (state: any, { payload }: any) => {
      state.newGameOptionSetting.categoryId = payload;
    },
    addNewGameInfoCategoryOption: (state: any, { payload }) => {
      const { currentGameInfoCategoryId, option } = payload;
      const {
        name,
        teamPlayerCount,
        enableChargePrice,
        pricingInfo,
        isArchery,
        archerySettings,
      } = option;

      state.gameInfo.categories = state.gameInfo.categories.map(
        (category: any) => {
          if (category.id === currentGameInfoCategoryId) {
            category.options.push({
              id: `category-option-item-${category.options.length}`,
              name,
              teamPlayerCount,
              enableChargePrice,
              pricingInfo,
              isArchery,
              archerySettings,
            });
          }

          return category;
        }
      );
    },
    removeGameInfoCategoryOption: (state: any, { payload }) => {
      const { currentGameInfoCategoryId, currentGameInfoCategoryOptionId } =
        payload;

      state.gameInfo.categories = state.gameInfo.categories.map(
        (category: any) => {
          const targetIndex = category.options.findIndex(
            (element: any) => element.id === currentGameInfoCategoryOptionId
          );
          if (category.id === currentGameInfoCategoryId) {
            category.options.splice(targetIndex, 1);
          }

          return category;
        }
      );
    },
    showArcheryGameOptionSettingNewModal: (state: any) => {
      state.newGameOptionSetting.showArcheryGameOptionSettingNewModal = true;
    },
    hideArcheryGameOptionSettingNewModal: (state: any) => {
      state.newGameOptionSetting.showArcheryGameOptionSettingNewModal = false;
    },
  },
  extraReducers: {
    [loadArcheryCompetitionOptions.pending.type]: (state) => {
      state.loadArcheryCompetitionOptions.status = API_REQUEST_STATUS.PENDING;
    },
    [loadArcheryCompetitionOptions.fulfilled.type]: (state, { payload }) => {
      state.loadArcheryCompetitionOptions.data = payload;
      state.loadArcheryCompetitionOptions.status = API_REQUEST_STATUS.FULFILLED;
    },
    [loadArcheryCompetitionOptions.rejected.type]: (state) => {
      state.loadArcheryCompetitionOptions.status = API_REQUEST_STATUS.REJECTED;
    },
    [loadEnrollmentDetailsById.pending.type]: (state) => {
      state.loadEnrollmentDetailsByIdStatus = API_REQUEST_STATUS.PENDING;
    },
    [loadEnrollmentDetailsById.fulfilled.type]: (state, { payload }) => {
      const {
        id,
        creatorId,
        title,
        deadline,
        coverImageUrl,
        merchantName,
        maxIndividualEnrolledCount,
        maxClubEnrolledCount,
        maxPlayerPerClubEnrolledCount,
        turnedOn,
        firstEnabledDate,
        posterId,
        playerInfo,
        gameInfo,
        clubFormInfo,
      } = payload;

      const enrollmentDetailsData = {
        id,
        creatorId,
        title,
        deadline,
        merchantName,
        maxIndividualEnrolledCount,
        maxClubEnrolledCount,
        maxPlayerPerClubEnrolledCount,
        enrollmentLimitType:
          maxClubEnrolledCount && maxPlayerPerClubEnrolledCount
            ? ENROLLMENT_LIMITATION_TYPES_MAPPING.CLUB
            : ENROLLMENT_LIMITATION_TYPES_MAPPING.INDIVIDUAL,
        coverImageUrl,
        turnedOn,
        firstEnabledDate,
        posterId,
      };

      state.enrollmentDetailsTurnOnInitial = turnedOn;
      state.enrollmentDetailsData = enrollmentDetailsData;
      state.playerInfo = playerInfo.map((player: any) => {
        if (player.propertyType === '' || !player.propertyType) {
          return {
            ...player,
            propertyType: 'charactor',
          };
        }

        return { ...player, saved: turnedOn || !!firstEnabledDate };
      });
      state.gameInfo = gameInfo;
      state.gameInfo.categories = (gameInfo.categories || []).map(
        (gameInfoCategoryItem: any) => ({
          ...gameInfoCategoryItem,
          saved: turnedOn || !!firstEnabledDate,
        })
      );
      state.clubFormInfo = clubFormInfo.map((clubSettingItem: any) => ({
        ...clubSettingItem,
        saved: turnedOn || !!firstEnabledDate,
        items: clubSettingItem.items.map((item: any, index: number) => ({
          id: `club-setting-item-${clubSettingItem.id}-${index}`,
          ...item,
        })),
      }));
      state.loadEnrollmentDetailsByIdStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [loadEnrollmentDetailsById.rejected.type]: (state) => {
      state = initialState;
      state.loadEnrollmentDetailsByIdStatus = API_REQUEST_STATUS.REJECTED;
    },
    [addNewEnrollmentDetails.fulfilled.type]: (state) => {},
    [addNewEnrollmentDetails.rejected.type]: (state) => {
      state = initialState;
    },
    [loadPosterList.pending.type]: (state: any) => {
      state.postersInfo.status = API_REQUEST_STATUS.PENDING;
    },
    [loadPosterList.fulfilled.type]: (state: typeof initialState, action) => {
      state.postersInfo.data = action.payload;
      state.postersInfo.status = API_REQUEST_STATUS.FULFILLED;
    },
    [loadPosterList.rejected.type]: (state: typeof initialState) => {
      state.postersInfo.status = API_REQUEST_STATUS.REJECTED;
    },
    [updateSelectedClubNestItemSetting.fulfilled.type]: (
      state,
      { payload }
    ) => {
      const {
        id,
        propertyName,
        description,
        propertyType,
        maxValue,
        minValue,
        enableChargePrice,
        pricingInfo,
      } = payload;

      state.selectedClubNestItemSetting.id = id;
      state.selectedClubNestItemSetting.propertyName = propertyName;
      state.selectedClubNestItemSetting.description = description;
      state.selectedClubNestItemSetting.propertyType = propertyType;
      state.selectedClubNestItemSetting.maxValue = maxValue;
      state.selectedClubNestItemSetting.minValue = minValue;
      state.selectedClubNestItemSetting.enableChargePrice = enableChargePrice;
      state.selectedClubNestItemSetting.pricingInfo = pricingInfo;
    },
  },
});
