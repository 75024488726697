import { createSlice } from '@reduxjs/toolkit';
import { BusinessTypesStateTypes } from './types';
import { loadBusinessType } from './thunks';
import { API_REQUEST_STATUS } from '../../../../service/constants';

const initialState: BusinessTypesStateTypes = {
  data: [],
  loadBusinessTypeStatus: API_REQUEST_STATUS.IDLE,
};

export const { reducer, actions } = createSlice({
  name: 'app/common/businessTypes',
  initialState,
  reducers: {},
  extraReducers: {
    [loadBusinessType.pending.type]: (state) => {
      state.loadBusinessTypeStatus = API_REQUEST_STATUS.PENDING;
    },
    [loadBusinessType.fulfilled.type]: (state, { payload }) => {
      state.data = payload;
      state.loadBusinessTypeStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [loadBusinessType.rejected.type]: (state) => {
      state.loadBusinessTypeStatus = API_REQUEST_STATUS.REJECTED;
    },
  },
});

export default reducer;
