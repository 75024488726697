import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../common/utils/types';
import { getStores } from '../../../../../redux/entities/stores/selectors';
import {
  getIsUserInitialized,
  getUserBelongToStoreId,
  getIsManager,
  getIsOwner,
} from '../../../../../redux/app-v2/user/selectors';
import { Filters } from '.';

export {
  getIsUserInitialized,
  getUserBelongToStoreId,
  getIsManager,
  getIsOwner,
};

const statusNameMap: { [key: string]: string } = {
  created: '创建',
  paid: '已付款',
  finished: '已完成',
  cancelled: '已取消',
  failed: '失败',
};
const exchangedTypeMap: { [key: string]: string } = {
  assets: '权益',
  voucher: '券码',
};

export const getPaginateNum = (state: RootState) =>
  state.marketOrder.paginateNum;

export const getTableData = (state: RootState) => state.marketOrder.tableData;

export const getTotal = (state: RootState) => state.marketOrder.total;

export const getPageSize = (state: RootState) => state.marketOrder.pageSize;

export const getAssetsDictionary = (state: RootState) =>
  state.marketOrder.assetsDictionary;

export const getSelectStatus = (state: RootState) => {
  return state.marketOrder.selectStatus.map((status) => ({
    key: status,
    label: statusNameMap[status],
  }));
};

export const getSelectExchangedType = (state: RootState) => {
  return state.marketOrder.selectExchangedType.map((exchangedType) => ({
    key: exchangedType,
    label: exchangedTypeMap[exchangedType],
  }));
};

export const getIsStoreListFilterVisible = createSelector(
  getIsManager,
  getIsOwner,
  (isManager, isOwner) => isManager || isOwner
);

export const getStoreList = createSelector(getStores, (stores) => {
  return stores.map((store: any) => ({
    key: store.id,
    label: store.displayName,
  }));
});

export const getSelectedStoreKeys = (state: RootState) =>
  state.marketOrder.selectedStoreKeys;

export const getMerchandiseDic = (state: RootState) =>
  state.marketOrder.merchandiseDic;

export const getFilters = (state: RootState) => state.marketOrder.filters;
