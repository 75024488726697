import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Input, Modal, ModalProps, Tag } from 'antd';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';

import { getEmployeeCheckBoxList } from '../../redux/selectors';
import './index.less';
import { useEvent, useMount } from 'react-use';
import { loadEmployeeList } from '../../redux/thunks';

interface JudgesSelectionModalProps extends ModalProps {
  demandIds: any[];
  onFinish: (demandIds: any[]) => void;
}
const JudgesSelectionModal: React.FC<JudgesSelectionModalProps> = (props) => {
  const { onFinish, demandIds: originalDemandIds } = props;
  const dispatch = useDispatch();
  const employeeCheckBoxList = useSelector(getEmployeeCheckBoxList);
  const [demandIds, setDemandIds] = useState<any[]>([]);
  const [filter, setFilter] = useState<string>('');
  const targetCheckBoxList = employeeCheckBoxList.filter((item) =>
    item.label.includes(filter)
  );
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    setDemandIds(originalDemandIds);
  }, [originalDemandIds]);

  const onChange = (list: any[]) => {
    setDemandIds(list);
    setIndeterminate(
      !!list.length && list.length < employeeCheckBoxList.length
    );
    setCheckAll(list.length === employeeCheckBoxList.length);
  };

  const onCheckAllChange = (e: any) => {
    setDemandIds(
      e.target.checked ? employeeCheckBoxList.map((e) => e.value) : []
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };
  useMount(() => {
    dispatch(loadEmployeeList() as any);
  });
  useEvent(
    'visibilitychange',
    (event) => {
      dispatch(loadEmployeeList() as any);
    },
    document
  );

  return (
    <Modal
      {...props}
      centered
      maskClosable={false}
      closeIcon
      onOk={() => onFinish(demandIds)}
    >
      <div className="JudgesSelectionModalContainer">
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          全选
        </Checkbox>
        已选裁判：
        {demandIds.length > 0 && (
          <div className="JudgesSelectionModalContainerTop">
            {demandIds.map((d) => (
              <Tag
                key={'tag_' + d}
                closable
                onClose={() => {
                  setDemandIds(demandIds.filter((item) => item !== d));
                }}
              >
                {employeeCheckBoxList.find((e) => e.value === d)?.label}
              </Tag>
            ))}
          </div>
        )}
        <div className="JudgesSelectionModalContainerBottom">
          <Input.Group compact>
            <Input
              addonBefore="姓名筛选"
              style={{ width: 'calc(100% - 125px)' }}
              onChange={(e) => setFilter(e.target.value)}
            />
            <Button
              style={{ width: '125px' }}
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                window.open(
                  `${window.location.origin}${window.location.pathname}#/employees`,
                  '_blank'
                );
              }}
            >
              添加新裁判
            </Button>
          </Input.Group>

          <Checkbox.Group
            className="JudgesSelectionModalContainerBottomGroup"
            value={demandIds}
            onChange={onChange}
          >
            {targetCheckBoxList.length > 0 ? (
              targetCheckBoxList.map((item) => (
                <Checkbox
                  className="JudgesSelectionModalContainerBottomGroupItem"
                  key={item.value}
                  value={item.value}
                >
                  <div className="JudgesSelectionModalContainerBottomGroupItemRight">
                    <div className="JudgesSelectionModalContainerBottomGroupItemRightTitle">
                      {item.label}
                    </div>
                    <div className="JudgesSelectionModalContainerBottomGroupItemRightSubtitle">
                      {item.phone}
                    </div>
                  </div>
                </Checkbox>
              ))
            ) : employeeCheckBoxList.length > 0 ? (
              `没有名字包含“${filter}”的裁判`
            ) : (
              <LoadingOutlined />
            )}
          </Checkbox.Group>
        </div>
      </div>
    </Modal>
  );
};
export default JudgesSelectionModal;
