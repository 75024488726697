import { combineReducers } from '@reduxjs/toolkit';
import { reducer as management } from '../containers/RankingManagement/redux';
import { reducer as details } from '../containers/RankingDetails/redux';
import { reducer as list } from '../containers/RankingList/redux';

const reducer = combineReducers({
  management,
  details,
  list,
});

export default reducer;
