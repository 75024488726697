import { get, post, deleteRequest } from '../../../../../service/api';

export const getActivities = async (payload: {
  merchantName: string;
  offset: number;
  limit: number;
  configOptions?: any;
}) => {
  try {
    const { merchantName, offset, limit, configOptions = {} } = payload;
    const params = { offset, limit };

    const result = await get(
      `/merchants/${merchantName}/activities`,
      params,
      {},
      configOptions
    );

    return result;
  } catch (e) {
    console.error('get activities', e);

    throw e;
  }
};

export const postActivity = async (payload: {
  merchantName: string;
  activity: any;
}) => {
  try {
    const { merchantName, activity } = payload;

    const result = await post(
      `/merchants/${merchantName}/activities`,
      activity,
      { isShow: true }
    );

    return result;
  } catch (e) {
    console.error('post activity', e);

    throw e;
  }
};

export const deleteActivity = async (payload: {
  merchantName: string;
  id: string;
}) => {
  try {
    const { merchantName, id } = payload;

    const result = await deleteRequest(
      `/merchants/${merchantName}/activities/${id}`,
      {
        isShow: false,
      }
    );

    return result;
  } catch (e) {
    console.error('delete activity', e);

    throw e;
  }
};

export const postPoster = async (payload: {
  merchantName: string;
  poster: any;
}) => {
  try {
    const { merchantName, poster } = payload;

    const result = await post(`/merchants/${merchantName}/posters`, poster, {
      isShow: false,
    });

    return result;
  } catch (e) {
    console.error('get posters', e);

    throw e;
  }
};
