import { createAsyncThunk } from '@reduxjs/toolkit';
import { PricePlanType } from './types';
import { getPricePlan, getPricePlans } from './api-request';
import { actions as pricePlansEntitiesActions } from './index';

export const loadPricePlanList = createAsyncThunk(
  'pricePlans/loadPricePlanList',
  async (_, { dispatch }) => {
    try {
      const result: any = await getPricePlans();
      const { data } = result || {};
      const typeIdMapping: { [type: string]: string } = {};

      dispatch(pricePlansEntitiesActions.updatePricePlanMapping(data));

      data.forEach((item: PricePlanType) => {
        const { id, type } = item || {};

        typeIdMapping[type] = id;
      });

      return typeIdMapping;
    } catch (e) {
      throw e;
    }
  }
);

export const loadPricePlan = createAsyncThunk(
  'pricePlans/loadPricePlan',
  async (type: string, { dispatch }) => {
    try {
      const result: any = await getPricePlan({ type });
      const { data } = result || {};
      const { id } = data || {};

      await dispatch(pricePlansEntitiesActions.updatePricePlanById(data));

      return id;
    } catch (e) {
      throw e;
    }
  }
);
