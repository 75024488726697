import React from 'react';
import { Skeleton } from 'antd';

const SkeletonList = ({
  skeletonNumber,
  replaceComponentName,
}: {
  skeletonNumber: number;
  replaceComponentName: string;
}) => {
  return (
    <>
      {Array.from({ length: skeletonNumber }).map((_, index) => (
        <Skeleton
          key={`${replaceComponentName}-skeleton-list-${index}`}
          active
        />
      ))}
    </>
  );
};

SkeletonList.displayName = 'SkeletonList';

export default SkeletonList;
