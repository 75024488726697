import _isNil from 'lodash/isNil';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Form,
  Modal,
  Input,
  Radio,
  Select,
  DatePicker,
  Button,
  Row,
  Col,
} from 'antd';
import { FileAddTwoTone, InboxOutlined } from '@ant-design/icons';
import { AppDispatch } from '../../../../../../../../common/utils/types';
import { getYearLastISODate } from '../../../../../../../../common/utils/time-lib';
import {
  getIsIDNumberValidated,
  getIsPassportNumberValidated,
} from '../../../../../../../../common/utils';
import {
  getAssociationAddPlayerAssociationClubId,
  getAssociationAddPlayerRegisterDate,
  getAssociationAddPlayerExpiredDate,
  getAssociationAddPlayerGender,
  getAssociationAddPlayerIdCardNum,
  getAssociationAddPlayerIdCardType,
  getAssociationAddPlayerName,
  getAssociationAddPlayerPhone,
  getSearchClubsFilterOptions,
  getIsSearchClubsFilterLoading,
  getIsAssociationAddPlayerCreating,
} from '../../redux/add/selectors';
import { actions as addPlayerActions } from '../../redux/add';
import { useMount } from 'react-use';
import {
  loadSearchClubsFilter,
  createAssociationPlayer,
} from '../../redux/add/thunks';
import PhoneNumberInput from '../../../../../../../../common/components/PhoneNumberInput';
import './AddPlayer.less';
import { getIsBaseball } from '../../../../../../../../redux/app-v2/merchant/selectors';

let timer: any = null;
const getDisabledDate = (current: any, registerDate: any) => {
  return current && current <= registerDate;
};

const AddPlayer = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const [addAssociationPlayerVisible, setAddAssociationPlayerVisible] =
    React.useState(false);
  const name = useSelector(getAssociationAddPlayerName);
  const gender = useSelector(getAssociationAddPlayerGender);
  const idCardType = useSelector(getAssociationAddPlayerIdCardType);
  const idCardNum = useSelector(getAssociationAddPlayerIdCardNum);
  const phone = useSelector(getAssociationAddPlayerPhone);
  const registerDate: any = useSelector(getAssociationAddPlayerRegisterDate);
  const expiredDate: any = useSelector(getAssociationAddPlayerExpiredDate);
  const associationClubId = useSelector(
    getAssociationAddPlayerAssociationClubId
  );
  const searchClubsFilterOptions = useSelector(getSearchClubsFilterOptions);
  const isSearchClubsFilterLoading = useSelector(getIsSearchClubsFilterLoading);
  const isAssociationAddPlayerCreating = useSelector(
    getIsAssociationAddPlayerCreating
  );
  const handleCreateNewAssociationPlayer = async () => {
    try {
      await form.validateFields();

      await dispatch(
        createAssociationPlayer(() => {
          setAddAssociationPlayerVisible(false);
        })
      );
    } catch (e) {
      throw e;
    }
  };
  const resetData = async () => {
    await form.resetFields();
    dispatch(addPlayerActions.newAssociationAddPlayerReset());
  };

  const isBaseball = useSelector(getIsBaseball);
  const clubText = isBaseball ? '联盟球队' : '成员单位';
  const playerText = isBaseball ? '球员' : '运动员';

  useMount(async () => {
    await dispatch(loadSearchClubsFilter());
  });

  return (
    <>
      <Button
        className="AssociationPlayerListAddButton"
        onClick={() => {
          setAddAssociationPlayerVisible(true);
        }}
      >
        <div className="AssociationPlayerListAddButtonText flex">
          <FileAddTwoTone twoToneColor="#4fc7e4" />
          新增注册{playerText}
        </div>
      </Button>
      <Modal
        centered
        visible={addAssociationPlayerVisible}
        width={630}
        title={`新增注册${playerText}`}
        onCancel={() => {
          setAddAssociationPlayerVisible(false);
        }}
        onOk={handleCreateNewAssociationPlayer}
        afterClose={resetData}
        footer={
          <div className="flex flex-middle flex-space-between">
            <span className="AddPlayerFooterPromptText">
              <sub className="AddPlayerFooterPromptTextRequired">*</sub>{' '}
              点击保存之后，注册{playerText}编号将自动生成
            </span>
            <div>
              <Button
                key="submit"
                type="primary"
                loading={isAssociationAddPlayerCreating}
                onClick={handleCreateNewAssociationPlayer}
              >
                保存
              </Button>
              <Button
                type="primary"
                ghost
                onClick={() => {
                  setAddAssociationPlayerVisible(false);
                }}
              >
                返回
              </Button>
            </div>
          </div>
        }
      >
        <Form scrollToFirstError layout="vertical" form={form}>
          <Row gutter={40}>
            <Col xs={24} sm={12}>
              <Form.Item
                className="formItem"
                label="姓名"
                name="name"
                rules={[{ required: true, message: '姓名不能为空' }]}
              >
                <Input
                  value={name}
                  type="text"
                  onChange={(e) => {
                    dispatch(
                      addPlayerActions.associationAddPlayerNameUpdated(
                        e.target.value
                      )
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={40}>
            <Col xs={24} sm={12}>
              <Form.Item
                className="formItem"
                label="性别"
                name="gender"
                rules={[{ required: true, message: '性别不能为空' }]}
                initialValue={gender}
              >
                <Radio.Group
                  name="gender"
                  value={gender}
                  onChange={(e) => {
                    dispatch(
                      addPlayerActions.associationAddPlayerGenderUpdated(
                        e.target.value
                      )
                    );
                  }}
                >
                  <Radio value="male">男</Radio>
                  <Radio value="female">女</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={40}>
            <Col xs={24} sm={12}>
              <Form.Item className="AddPlayer formItem" label="证件号" required>
                <Input.Group compact>
                  <Select
                    dropdownMatchSelectWidth={120}
                    value={idCardType}
                    onChange={(value) => {
                      dispatch(
                        addPlayerActions.associationAddPlayerIdCardTypeUpdated(
                          value
                        )
                      );
                    }}
                  >
                    <Select.Option value="IDCard">身份证</Select.Option>
                    <Select.Option value="passport">护照</Select.Option>
                    <Select.Option value="HongKong/Macau/Taiwan">
                      港澳台人士证件
                    </Select.Option>
                  </Select>
                  <Form.Item
                    name="idCardNum"
                    noStyle
                    rules={[
                      { required: true, message: '证件号不能为空' },
                      () => ({
                        validator(_, value) {
                          const isValidIDCardNumber =
                            idCardType === 'IDCard' &&
                            getIsIDNumberValidated(value);
                          const isValidPassportNumber =
                            idCardType === 'passport' &&
                            getIsPassportNumberValidated(value);
                          const isValidExitEntryPermitNumber =
                            idCardType === 'HongKong/Macau/Taiwan';

                          if (
                            isValidIDCardNumber ||
                            isValidPassportNumber ||
                            isValidExitEntryPermitNumber ||
                            value === '' ||
                            _isNil(value)
                          ) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error('请输入正确的证件号')
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      value={idCardNum}
                      onChange={(e) => {
                        dispatch(
                          addPlayerActions.associationAddPlayerIdCardNumUpdated(
                            e.target.value
                          )
                        );
                      }}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <PhoneNumberInput
                name="phone"
                label="联系电话"
                placeholder="请输入会员联系电话"
                phoneNumber={phone}
                onChange={(phoneNumber: string) => {
                  dispatch(
                    addPlayerActions.associationAddPlayerPhoneUpdated(
                      phoneNumber
                    )
                  );
                }}
              />
            </Col>
          </Row>
          <Row gutter={40}>
            <Col xs={24} sm={12}>
              <Form.Item className="formItem" label="注册日期">
                <DatePicker
                  allowClear={false}
                  style={{ width: '100%' }}
                  value={registerDate}
                  format="YYYY/MM/DD"
                  onChange={(dateMomentObject, dateString) => {
                    const newRegisterDateIOSString = (
                      dateMomentObject || new Date(dateString)
                    ).toISOString();

                    if (dateMomentObject) {
                      const registerYearLastDay = getYearLastISODate(
                        new Date(dateString)
                      );
                      const newExpiredDate = dateMomentObject.isBefore(
                        registerYearLastDay
                      )
                        ? registerYearLastDay
                        : moment(registerYearLastDay)
                            .add(1, 'year')
                            .toISOString();

                      dispatch(
                        addPlayerActions.associationAddPlayerExpiredDateUpdated(
                          newExpiredDate
                        )
                      );
                    }

                    dispatch(
                      addPlayerActions.associationAddPlayerRegisterDateUpdated(
                        newRegisterDateIOSString
                      )
                    );
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item className="formItem" label="有效期">
                <DatePicker
                  allowClear={false}
                  style={{ width: '100%' }}
                  value={expiredDate}
                  format="YYYY/MM/DD"
                  disabledDate={(current: any) => {
                    return getDisabledDate(current, registerDate);
                  }}
                  onChange={(_, dateString) => {
                    dispatch(
                      addPlayerActions.associationAddPlayerExpiredDateUpdated(
                        dateString
                      )
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={40}>
            <Col xs={24} sm={12}>
              <Form.Item
                className="formItem"
                label={`所属${clubText}`}
                name="associationClubId"
                rules={[{ required: true, message: `${clubText}不能为空` }]}
              >
                <Select
                  showSearch
                  loading={isSearchClubsFilterLoading}
                  placeholder={`请输入${clubText}名称`}
                  value={associationClubId}
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  onSearch={(value) => {
                    clearTimeout(timer);

                    timer = setTimeout(() => {
                      dispatch(loadSearchClubsFilter(value));
                    }, 400);
                  }}
                  onChange={(value) => {
                    dispatch(
                      addPlayerActions.associationAddPlayerAssociationClubIdUpdated(
                        value
                      )
                    );
                  }}
                  notFoundContent={
                    <div className="flex flex-column flex-middle flex-center">
                      <InboxOutlined />
                      <span>未搜到此{clubText}</span>
                    </div>
                  }
                  options={searchClubsFilterOptions}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default AddPlayer;
