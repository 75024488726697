import { get } from '../../../../../service/api';

export const getRankListInfo = async (payload: {
  merchantName: string;
  timeInterval: string;
  startDate?: number;
  storeId: string;
  rankingSettingId: string;
}) => {
  const { merchantName, startDate, rankingSettingId, timeInterval, storeId } =
    payload;

  try {
    const params = { rankingSettingId, startDate, timeInterval, storeId };
    const result: any = await get(
      `/merchants/${merchantName}/ranking`,
      params,
      {},
      {}
    );

    return result.data;
  } catch (e) {
    throw new Error('get ranking setting by id failed');
  }
};

export const getRankListById = async (payload: {
  merchantName: string;
  playerOffset: number;
  playerLimit: number;
  id: string;
}) => {
  const { merchantName, id, playerLimit, playerOffset } = payload;

  const params = { playerOffset, playerLimit };
  try {
    const { data, pagination }: any = await get(
      `/merchants/${merchantName}/ranking/${id}`,
      params,
      {},
      {}
    );

    return { data, pagination };
  } catch (e) {
    throw new Error('get ranking setting by id failed');
  }
};

export const getRankSettingsById = async (payload: {
  merchantName: string;
  id: string;
}) => {
  try {
    const { merchantName, id } = payload;
    const result: any = await get(
      `/merchants/${merchantName}/ranking-settings/${id}`,
      {},
      {}
    );

    return result;
  } catch (e) {
    throw new Error('get ranking setting by id failed');
  }
};

export const getRankSettingsList = async (payload: {
  merchantName: string;
  offset: number;
  limit: number;
}) => {
  try {
    const { merchantName, offset, limit } = payload;
    const params = { offset, limit };
    const res = await get(
      `/merchants/${merchantName}/ranking-settings`,
      params,
      {},
      {}
    );

    return res;
  } catch (e) {
    throw new Error('get ranking setting failed');
  }
};
