import React from 'react';
import { Breadcrumb, Typography } from 'antd';
import { BreadcrumbRouterPropsType } from './types';
import { Link } from 'react-router-dom';
import './BreadcrumbRouter.less';

function itemRender(route: any, _: any, routes: any, paths: any) {
  const { params } = route || {};
  const isLastRoute = routes.indexOf(route) === routes.length - 1;
  let search = '';

  Object.keys(params || {}).forEach((paramKey: any) => {
    search += `${paramKey}=${params[paramKey]}`;
  });

  return isLastRoute ? (
    <Typography.Title className="BreadcrumbRouterLastTitle" level={2}>
      {route.breadcrumbName}
    </Typography.Title>
  ) : (
    <Link
      className="BreadcrumbRouterLink"
      to={`/${paths.join('/')}${search !== '' ? `?${search}` : ''}`}
    >
      {route.breadcrumbName}
    </Link>
  );
}

const BreadcrumbRouter = ({ routes }: BreadcrumbRouterPropsType) => {
  return (
    <Breadcrumb
      className="BreadcrumbRouter"
      itemRender={itemRender}
      routes={routes}
    />
  );
};

BreadcrumbRouter.displayName = 'BreadcrumbRouter';

export default BreadcrumbRouter;
