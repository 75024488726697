export const TRANSLATION_NAME_MAPPING: { [x: string]: any } = {
  name: '姓名',
  phoneNumber: '电话',
  idCardType: '证件类型',
  idCardNum: '证件号',
  gender: '性别',
  male: '男',
  female: '女',
  IDCard: '身份证',
  passport: '护照',
  'HongKong/Macau/Taiwan': '港澳台人士证件',
};

export const ENROLLMENT_LIMITATION_TYPES_MAPPING = {
  INDIVIDUAL: 'individual',
  CLUB: 'club',
};

export const ENROLLMENT_LIMITATION_TYPES = [
  {
    value: ENROLLMENT_LIMITATION_TYPES_MAPPING.INDIVIDUAL,
    label: '按照总人数',
  },
  {
    value: ENROLLMENT_LIMITATION_TYPES_MAPPING.CLUB,
    label: '按照俱乐部',
  },
];
