import _isUndefined from 'lodash/isUndefined';
import React from 'react';
import { Button, Card, Image, Tag, message } from 'antd';
import { FunctionCardType } from './types';
import './FunctionCardType.less';

const FunctionCard = ({
  img,
  title,
  isAvailable = false,
  isTrial = false,
  statusText,
  description,
  onButtonClick,
}: FunctionCardType) => {
  let colorStyleClassName = 'default';

  if (isTrial) {
    colorStyleClassName = 'trial';
  }

  const titleRightContent = !_isUndefined(statusText) ? (
    isAvailable ? (
      <Tag color="success" className="FunctionCardTag">
        {statusText}
      </Tag>
    ) : (
      <Button
        className={`FunctionCardButton ${colorStyleClassName}`}
        onClick={(e) => {
          e.stopPropagation();

          onButtonClick ? onButtonClick() : message.error('请联系管理员开通');
        }}
      >
        {statusText}
      </Button>
    )
  ) : null;

  return (
    <Card
      hoverable
      className="FunctionCard"
      cover={
        <Image
          preview={false}
          className="FunctionCardImage"
          alt="Function Card Image - Zaiye"
          src={img}
        />
      }
      bordered={true}
    >
      <div
        className={`FunctionCardTitleContainer flex flex-middle ${
          titleRightContent ? 'flex-space-between' : 'flex-center'
        }`}
      >
        <span className="FunctionCardTitle">{title}</span>
        {titleRightContent}
      </div>
      <p className="FunctionCardDescription text__omit">{description}</p>
    </Card>
  );
};

export default FunctionCard;
