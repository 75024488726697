import React from 'react';
import { Form, Input, Modal } from 'antd';

const BusinessLicense = ({
  show,
  data,
  onUpdate,
  onReset,
  onConfirm,
  onClose,
}: {
  show: boolean;
  data: {
    companyName: string;
    companyAddress: string;
    principalName: string;
  };
  onUpdate: Function;
  onReset: Function;
  onConfirm: Function;
  onClose: Function;
}) => {
  const [form] = Form.useForm();
  const { companyName, companyAddress, principalName } = data || {};

  return (
    <Modal
      centered
      title="工商营业执照注册信息"
      visible={show}
      afterClose={() => {
        form.resetFields();
      }}
      onCancel={async () => {
        form.resetFields();
        onReset();
        onClose();
      }}
      onOk={async () => {
        try {
          await form.validateFields();
          await onConfirm();
          form.resetFields();
          onClose();
        } catch (error) {
          throw error;
        }
      }}
    >
      <Form form={form} layout="vertical" preserve={false}>
        <Form.Item
          label="公司名称"
          name="companyName"
          initialValue={companyName}
          rules={[{ required: true, message: '请输入公司名称' }]}
        >
          <Input
            placeholder="请输入公司名称"
            value={companyName}
            onChange={(e) => {
              onUpdate({
                companyName: e.target.value,
              });
            }}
          />
        </Form.Item>
        <Form.Item
          label="公司注册地址"
          name="companyAddress"
          initialValue={companyAddress}
          rules={[{ required: true, message: '请输入公司注册地址' }]}
        >
          <Input
            placeholder="请输入公司注册地址"
            value={companyAddress}
            onChange={(e) => {
              onUpdate({
                companyAddress: e.target.value,
              });
            }}
          />
        </Form.Item>
        <Form.Item
          label="法人/负责人姓名"
          name="principalName"
          initialValue={principalName}
          rules={[{ required: true, message: '请输入法人/负责人姓名' }]}
        >
          <Input
            placeholder="请输入法人/负责人姓名"
            value={principalName}
            onChange={(e) => {
              onUpdate({
                principalName: e.target.value,
              });
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BusinessLicense;
