import _isNil from 'lodash/isNil';
import {
  Button,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Space,
  Spin,
  Upload,
} from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useMount, useUnmount } from 'react-use';
import {
  getIsBaseball,
  getMerchantName,
} from '../../../../../../redux/app-v2/merchant/selectors';
import { getUserAuthorization } from '../../../../../../redux/app-v2/user/selectors';
import { publicIp } from '../../../../../../service/apiIP';
import {
  errorMessage,
  successMessage,
} from '../../../../../../common/utils/feedback';
import ContentDetail from '../../../../../../common/components/ContentDetail';
import BreadcrumbRouter from '../../../../../../common/components/BreadcrumbRouter';
import { actions as associationClubDetailActions } from './redux';
import TextArea from 'antd/lib/input/TextArea';
import { AppDispatch } from '../../../../../../redux/store';
import {
  getClubAddress,
  getClubApplyingDate,
  getClubCompanyEmail,
  getClubCompanyPhoneNumber,
  getClubContactEmail,
  getClubContactName,
  getClubContactPhoneNumber,
  getClubCompanyName,
  getClubMemberId,
  getClubName,
  getClubMerchantDisplayName,
  getClubMerchantName,
  getClubRemark,
  getIsClubDetailLoaded,
  getClubLogo,
  getIsClubMemberIdNotEditable,
  getIsClubMemberIdRequired,
} from './redux/selectors';
import { clubDetailByIdLoaded, updateClubDetail } from './redux/thunks';
import './ClubDetail.less';

const MEMBER_ID_REGEX = /^[0-9]*$/;
const ClubDetail = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [isUploadingLogoPending, setIsUploadingLogoPending] = useState(false);

  const logo: any = useSelector(getClubLogo);
  const name = useSelector(getClubName);
  const address = useSelector(getClubAddress);
  const memberId = useSelector(getClubMemberId);
  const isMemberIdNotEditable = useSelector(getIsClubMemberIdNotEditable);
  const isClubMemberIdRequired = useSelector(getIsClubMemberIdRequired);
  const applyingDate = useSelector(getClubApplyingDate);
  const companyName = useSelector(getClubCompanyName);
  const companyPhoneNumber = useSelector(getClubCompanyPhoneNumber);
  const companyEmail = useSelector(getClubCompanyEmail);
  const contactName = useSelector(getClubContactName);
  const contactPhoneNumber = useSelector(getClubContactPhoneNumber);
  const contactEmail = useSelector(getClubContactEmail);
  const merchantDisplayName = useSelector(getClubMerchantDisplayName);
  const clubMerchantName = useSelector(getClubMerchantName);
  const remark = useSelector(getClubRemark);
  const isClubDetailLoaded = useSelector(getIsClubDetailLoaded);
  const applyingDateValue = applyingDate ? moment(applyingDate) : undefined;
  const merchantName = useSelector(getMerchantName);
  const userAuthorization = useSelector(getUserAuthorization);
  const uploadImageAction = `${publicIp}/merchants/${merchantName}/association-clubs/pic`;
  const uploadImageHeaders = {
    Authorization: userAuthorization,
  };
  const imageContent = (
    <div className="AddClubImageContent">
      <img className="AddClubImage" src={logo} alt="" />
      <div
        className={`AddClubImageCover flex flex-column flex-middle flex-center${
          isUploadingLogoPending ? ' active' : ''
        }`}
      >
        {isUploadingLogoPending ? <Spin tip="正在上传图片..." /> : null}
      </div>
    </div>
  );
  const getIsValidationPhone = (phone: string) => {
    const reg = /^[\d+-]{1,20}$/;
    return reg.test(phone);
  };
  const getIsValidationEmail = (email: string) => {
    const reg =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return reg.test(email);
  };
  const isBaseball = useSelector(getIsBaseball);
  const clubText = isBaseball ? '联盟球队' : '成员单位';

  useMount(async () => {
    if (id) {
      await dispatch(
        clubDetailByIdLoaded({
          id,
          backToListPage: () => {
            navigate('/association-activities/clubs');
          },
        })
      );
    }
  });

  useUnmount(async () => {
    await dispatch(associationClubDetailActions.clubDetailReset());
  });

  if (!isClubDetailLoaded) {
    return null;
  }

  return (
    <ContentDetail
      title="协会活动 / 俱乐部认证 / 认证详情"
      headerLeft={
        <BreadcrumbRouter
          routes={[
            {
              path: '/association-activities/clubs',
              breadcrumbName: `${clubText}认证`,
            },
            {
              path: `${id}`,
              breadcrumbName: '认证详情',
            },
          ]}
        />
      }
    >
      <section className="ClubDetailFormContainer">
        <Form
          scrollToFirstError
          layout="vertical"
          form={form}
          ref={React.createRef<FormInstance>()}
          initialValues={{
            logo,
            name,
            memberId,
            applyingDate: applyingDateValue,
            companyPhoneNumber,
            companyEmail,
            contactPhoneNumber,
            contactEmail,
          }}
        >
          <Form.Item
            className="formItem"
            label="俱乐部名称"
            name="merchantDisplayName"
            initialValue={merchantDisplayName}
          >
            <Input
              placeholder="小程序未注册"
              disabled
              value={merchantDisplayName}
              type="text"
              onChange={(e) => {
                dispatch(
                  associationClubDetailActions.clubMerchantDisplayNameUpdated(
                    e.target.value
                  )
                );
              }}
            />
          </Form.Item>
          <Form.Item
            className="formItem"
            label="俱乐部商户号"
            name="clubMerchantNumber"
            initialValue={clubMerchantName}
          >
            <Input
              placeholder="小程序未注册"
              disabled
              value={clubMerchantName}
              type="text"
              onChange={(e) => {
                dispatch(
                  associationClubDetailActions.clubMerchantNameUpdated(
                    e.target.value
                  )
                );
              }}
            />
          </Form.Item>
          <Form.Item
            name="logo"
            className="ClubDetailFormItem formItem"
            label={`${clubText}Logo`}
            rules={[{ required: true, message: `${clubText}Logo不能为空` }]}
          >
            <Upload.Dragger
              className="ClubDetailFormItemUploader formItem"
              showUploadList={false}
              name="file"
              accept=".png, .jpg, .jpeg"
              action={uploadImageAction}
              headers={uploadImageHeaders}
              defaultFileList={logo ? [logo] : []}
              beforeUpload={(file) => {
                const validImageTypes = [
                  'image/png',
                  'image/jpeg',
                  'image/jpg',
                ];

                if (file.size > 2 * 1024 * 1024) {
                  errorMessage('图片大小不能超过2M');

                  return false;
                }

                if (!validImageTypes.includes(file.type)) {
                  errorMessage('文件必须为png, jpeg或者jpg的图片');

                  return false;
                }

                return true;
              }}
              onChange={(info) => {
                const { status } = info.file;

                if (status === 'done') {
                  setIsUploadingLogoPending(false);
                  successMessage(`${info.file.name} ${clubText}Logo更换成功.`);
                } else if (status === 'error') {
                  setIsUploadingLogoPending(false);
                  errorMessage(`${info.file.name} ${clubText}Logo更换失败.`);
                }

                const currentInfo = info.fileList.filter(
                  (file) => file.status === status
                );

                if (currentInfo[0].status === 'done') {
                  const { imageUrl } = currentInfo[0].response.data;

                  dispatch(
                    associationClubDetailActions.clubLogoUpdated(imageUrl)
                  );
                }
              }}
            >
              {logo ? imageContent : <p>上传Logo</p>}
            </Upload.Dragger>
          </Form.Item>
          <Form.Item
            className="formItem"
            label="成员单位全称（行业名称）"
            name="name"
            rules={[
              { required: true, message: '成员单位全称（行业名称）不能为空' },
            ]}
          >
            <Input
              value={name}
              type="text"
              onChange={(e) => {
                dispatch(
                  associationClubDetailActions.clubNameUpdated(e.target.value)
                );
              }}
            />
          </Form.Item>
          <Form.Item className="formItem" label="地址">
            <Input.TextArea
              autoSize
              placeholder="例子：上海市静安区南京西路1号楼1弄1室"
              value={address}
              onChange={(e) => {
                dispatch(
                  associationClubDetailActions.clubAddressUpdated(
                    e.target.value
                  )
                );
              }}
              allowClear
            />
          </Form.Item>
          <Form.Item
            className="formItem"
            label={`${clubText}号`}
            name="memberId"
            rules={
              isClubMemberIdRequired
                ? [
                    { required: true, message: `${clubText}号不能为空` },
                    {
                      pattern: MEMBER_ID_REGEX,
                      message: `请填写正确的${clubText}号，${clubText}号为数字组成`,
                    },
                  ]
                : [
                    {
                      pattern: MEMBER_ID_REGEX,
                      message: `请填写正确的${clubText}号，${clubText}号为数字组成`,
                    },
                  ]
            }
          >
            <Input
              value={memberId}
              type="text"
              disabled={isMemberIdNotEditable}
              placeholder="例子：12345678"
              onChange={(e) => {
                dispatch(
                  associationClubDetailActions.clubMemberIdUpdated(
                    e.target.value
                  )
                );
              }}
            />
          </Form.Item>
          <Form.Item
            name="applyingDate"
            className="formItem"
            label="申请时间"
            rules={[{ required: true, message: '申请时间不能为空' }]}
          >
            <DatePicker
              format="YYYY-MM-DD HH:mm:ss"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
              disabled
              value={applyingDate ? moment(applyingDate) : undefined}
            />
          </Form.Item>
          <section className="EnrollmentDetailsAttribute">
            <h4 className="EnrollmentDetailsAttributeTitle">单位信息</h4>
            <Form.Item className="formItem" label="单位名称">
              <Input
                value={companyName || ''}
                type="text"
                onChange={(e) => {
                  dispatch(
                    associationClubDetailActions.clubCompanyNameUpdated(
                      e.target.value
                    )
                  );
                }}
              />
            </Form.Item>
            <div className="ClubDetailFormGroup flex flex-top">
              <Form.Item
                className="formItem flex__fluid-content"
                label="单位电话"
                name="companyPhoneNumber"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (
                        getIsValidationPhone(value) ||
                        value === '' ||
                        _isNil(value)
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('请输入正确的单位电话'));
                    },
                  }),
                ]}
              >
                <Input
                  value={companyPhoneNumber}
                  type="text"
                  onChange={(e) => {
                    dispatch(
                      associationClubDetailActions.clubCompanyPhoneNumberUpdated(
                        e.target.value
                      )
                    );
                  }}
                />
              </Form.Item>
              <Form.Item
                className="formItem flex__fluid-content"
                label="单位邮箱"
                name="companyEmail"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (
                        getIsValidationEmail(value) ||
                        value === '' ||
                        _isNil(value)
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('请输入正确的单位邮箱'));
                    },
                  }),
                ]}
              >
                <Input
                  value={companyEmail}
                  type="text"
                  onChange={(e) => {
                    dispatch(
                      associationClubDetailActions.clubCompanyEmailUpdated(
                        e.target.value
                      )
                    );
                  }}
                />
              </Form.Item>
            </div>
          </section>
          <section className="EnrollmentDetailsAttribute">
            <h4 className="EnrollmentDetailsAttributeTitle">负责人信息</h4>
            <Form.Item className="formItem" label="负责人">
              <Input
                value={contactName}
                type="text"
                onChange={(e) => {
                  dispatch(
                    associationClubDetailActions.clubContactNameUpdated(
                      e.target.value
                    )
                  );
                }}
              />
            </Form.Item>
            <div className="ClubDetailFormGroup flex flex-top">
              <Form.Item
                className="formItem flex__fluid-content"
                label="负责人电话"
                name="contactPhoneNumber"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (
                        getIsValidationPhone(value) ||
                        value === '' ||
                        _isNil(value)
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('请输入正确的负责人电话')
                      );
                    },
                  }),
                ]}
              >
                <Input
                  value={contactPhoneNumber}
                  type="text"
                  onChange={(e) => {
                    dispatch(
                      associationClubDetailActions.clubContactPhoneNumberUpdated(
                        e.target.value
                      )
                    );
                  }}
                />
              </Form.Item>
              <Form.Item
                className="formItem flex__fluid-content"
                label="负责人邮箱"
                name="contactEmail"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (
                        getIsValidationEmail(value) ||
                        value === '' ||
                        _isNil(value)
                      ) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error('请输入正确的负责人邮箱')
                      );
                    },
                  }),
                ]}
              >
                <Input
                  value={contactEmail}
                  type="email"
                  onChange={(e) => {
                    dispatch(
                      associationClubDetailActions.clubContactEmailUpdated(
                        e.target.value
                      )
                    );
                  }}
                />
              </Form.Item>
            </div>
          </section>
          <Form.Item className="formItem" label="备注">
            <TextArea
              rows={4}
              value={remark}
              onChange={(e) => {
                dispatch(
                  associationClubDetailActions.clubRemarkUpdated(e.target.value)
                );
              }}
            />
          </Form.Item>

          <Form.Item style={{ marginBottom: '20px' }}>
            <div>
              <Space size="middle">
                <Button
                  type="primary"
                  className="operateButton"
                  htmlType="submit"
                  onClick={async () => {
                    try {
                      await form.validateFields();

                      dispatch(
                        updateClubDetail(() => {
                          navigate('/association-activities/clubs');
                        })
                      );
                    } catch (error) {
                      throw error;
                    }
                  }}
                >
                  保存
                </Button>
                <Button
                  type="default"
                  className="cancelButton"
                  style={{ marginLeft: '0px' }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  返回
                </Button>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </section>
    </ContentDetail>
  );
};

export default ClubDetail;
