import { get, put } from '../../../../../../../service/api';
import { ClubDetailUpdateTypes } from './types';

export const getClubDetailById = async (merchantName: string, id: string) => {
  try {
    const result = await get(
      `/merchants/${merchantName}/association-clubs/${id}`,
      {},
      {},
      {}
    );

    return result;
  } catch (error) {
    throw error;
  }
};

export const putClubDetail = async (
  merchantName: string,
  id: string,
  payload: ClubDetailUpdateTypes
) => {
  try {
    const result = await put(
      `/merchants/${merchantName}/association-clubs/${id}`,
      payload,
      {}
    );

    return result;
  } catch (error) {
    throw error;
  }
};
