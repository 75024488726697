import { get } from '../../../../../service/api';

export const getAllPlayersExport = async (merchantName: string) => {
  try {
    const res = await get(`/merchants/${merchantName}/players/export/all`, '');
    return res;
  } catch (error) {
    throw error;
  }
};
