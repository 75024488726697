import { createSlice } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../service/constants';
import { getLocaleData } from '../../../service/local-api-request';
import { MerchantType } from './types';
import { loadMerchantInfo, loadMerchantSubscription } from './thunks';

const initialState: MerchantType = {
  id: '',
  displayName: '',
  voteLink: '',
  liveStreamId: null,
  merchantName: getLocaleData('long-term', 'merchant')?.merchantName || '',
  business: '',
  deactivated: null,
  type: null,
  userAgreement: '',
  banners: [],
  logoImageUrl: null,
  loadMerchantInfoStatus: API_REQUEST_STATUS.IDLE,
  loadMerchantSubscriptionStatus: API_REQUEST_STATUS.IDLE,
  updateMerchantSubscriptionStatus: API_REQUEST_STATUS.IDLE,
  subscriptions: {
    addonStoreCount: 0,
    status: null,
    startTime: '',
    endTime: '',
    periodType: '',
    pricePlanType: '',
    test: true,
    trialEnded: false,
    agreementNotRead: false,
    pricePlanId: '',
    companyName: '',
    companyAddress: '',
    principalName: '',
  },
  enableLiveStream: false,
};

export const { reducer, actions } = createSlice({
  name: 'app/merchant',
  initialState,
  reducers: {
    merchantSet: (state: { merchantName: string }) => {
      state.merchantName =
        getLocaleData('long-term', 'merchant')?.merchantName || '';
    },
    displayNameSet: (state: { displayName: string }, { payload }) => {
      state.displayName = payload;
    },
    voteLinkSet: (state: { voteLink: string }, { payload }) => {
      state.voteLink = payload;
    },
    liveStreamIdSet: (state: { liveStreamId: string | null }, { payload }) => {
      state.liveStreamId = payload;
    },
    enableLiveStreamSet: (state: any, { payload }) => {
      state.enableLiveStream = payload;
    },
    userAgreementSet: (state: { userAgreement: string }, { payload }) => {
      state.userAgreement = payload;
    },
    merchantRest: (state) => {
      state.id = '';
      state.displayName = '';
      state.voteLink = '';
      state.liveStreamId = null;
      state.merchantName =
        getLocaleData('long-term', 'merchant')?.merchantName || '';
      state.business = '';
      state.deactivated = null;
      state.type = '';
      state.userAgreement = '';
      state.banners = [];
      state.logoImageUrl = null;
      state.loadMerchantInfoStatus = API_REQUEST_STATUS.IDLE;
      state.loadMerchantSubscriptionStatus = API_REQUEST_STATUS.IDLE;
      state.subscriptions = {
        addonStoreCount: 0,
        status: null,
        startTime: '',
        endTime: '',
        periodType: '',
        pricePlanType: '',
        test: true,
        trialEnded: false,
        agreementNotRead: false,
        pricePlanId: '',
        companyName: undefined,
        companyAddress: undefined,
        principalName: undefined,
      };
      state.enableLiveStream = false;
    },
  },
  extraReducers: {
    [loadMerchantInfo.rejected.type]: (state) => {
      state.loadMerchantInfoStatus = API_REQUEST_STATUS.REJECTED;
    },
    [loadMerchantInfo.fulfilled.type]: (state, { payload }) => {
      state.id = payload.id;
      state.displayName = payload.displayName;
      state.voteLink = payload.voteLink;
      state.liveStreamId = payload.liveStreamId;
      state.enableLiveStream = !!payload.liveStreamId;
      state.merchantName = payload.merchantName;
      state.business = payload.business;
      state.deactivated = payload.deactivated;
      state.type = payload.type;
      state.banners = payload.banners;
      state.logoImageUrl = payload.logoImageUrl;
      state.userAgreement = payload.userAgreement;
      state.loadMerchantInfoStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [loadMerchantInfo.pending.type]: (state) => {
      state.loadMerchantInfoStatus = API_REQUEST_STATUS.PENDING;
    },
    [loadMerchantSubscription.rejected.type]: (state) => {
      state.loadMerchantSubscriptionStatus = API_REQUEST_STATUS.REJECTED;
    },
    [loadMerchantSubscription.fulfilled.type]: (state, { payload }) => {
      const {
        addonStoreCount,
        status,
        startTime,
        endTime,
        periodType,
        pricePlanType,
        test,
        agreementNotRead,
        trialEnded,
        pricePlanId,
        companyName,
        companyAddress,
        principalName,
      } = payload || {};

      state.subscriptions.addonStoreCount = addonStoreCount;
      state.subscriptions.status = status;
      state.subscriptions.startTime = startTime;
      state.subscriptions.endTime = endTime;
      state.subscriptions.periodType = periodType;
      state.subscriptions.pricePlanType = pricePlanType;
      state.subscriptions.test = test;
      state.subscriptions.agreementNotRead = agreementNotRead;
      state.subscriptions.trialEnded = trialEnded;
      state.subscriptions.pricePlanId = pricePlanId;
      state.subscriptions.companyName = companyName || '';
      state.subscriptions.companyAddress = companyAddress || '';
      state.subscriptions.principalName = principalName || '';
      state.loadMerchantSubscriptionStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [loadMerchantSubscription.pending.type]: (state) => {
      state.loadMerchantSubscriptionStatus = API_REQUEST_STATUS.PENDING;
    },
  },
});

export default reducer;
