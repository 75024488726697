import { createSelector } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../../../../service/constants';

export const getEnrollmentTableDataSource = (state: any) =>
  state.enrollment.list.tableDataSource;

export const getEnrollmentListTableData = createSelector(
  getEnrollmentTableDataSource,
  (enrollmentTableDataSource) => {
    return enrollmentTableDataSource?.map(
      (rowDataSource: {
        id: string;
        title: string | undefined;
        enrolledCount: number | undefined;
        maxIndividualEnrolledCount: number | undefined;
        maxClubEnrolledCount: number | undefined;
        maxPlayerPerClubEnrolledCount: number | undefined;
        individualEnrolledCount: number | undefined;
        teamEnrolledCount: number | undefined;
        deadline: string | undefined;
        turnedOn: boolean | undefined;
      }) => {
        const {
          id,
          turnedOn,
          title,
          maxIndividualEnrolledCount,
          maxClubEnrolledCount,
          maxPlayerPerClubEnrolledCount,
          individualEnrolledCount,
          teamEnrolledCount,
          enrolledCount,
          deadline,
        } = rowDataSource;
        return {
          id,
          turnedOn,
          title,
          maxIndividualEnrolledCount,
          maxClubEnrolledCount,
          maxPlayerPerClubEnrolledCount,
          individualEnrolledCount,
          teamEnrolledCount,
          enrolledCount,
          deadline: deadline?.substring(0, 10),
        };
      }
    );
  }
);

export const getEnrollmentListSearchInputText = (state: any) =>
  state.enrollment.list.searchInputText;

export const getEnrollmentListPageNumber = (state: any) =>
  state.enrollment.list.pageNumber;

export const getEnrollmentListPageSize = (state: any) =>
  state.enrollment.list.pageSize;

export const getEnrollmentListTotal = (state: any) =>
  state.enrollment.list.total;

export const getDuplicateEnrollmentDetailStatus = (state: any) =>
  state.enrollment.list.duplicateEnrollmentStatus;

export const getIsEnrollmentDetailDuplicating = createSelector(
  getDuplicateEnrollmentDetailStatus,
  (duplicateEnrollmentDetailStatus) =>
    duplicateEnrollmentDetailStatus === API_REQUEST_STATUS.PENDING
);
