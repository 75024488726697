import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMount } from 'react-use';
import { Link } from 'react-router-dom';
import { Button, Form, FormInstance, Input, Divider } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { AppDispatch } from '../../../../common/utils/types';
import { PHONE_NUMBER_REGEX } from '../../../../common/constants';
import {
  getAccountName,
  getMerchantName,
  getPassword,
} from './redux/selectors';
import { actions as loginActions } from './redux';
import { getIsLoginStatusPending } from './redux/selectors';
import { userLogin, userLoginStatusChecked } from './redux/thunks';
import LoginImage from '../../../../images/entrance-image.png';
import LogoImage from '../../../../images/logo-2x.png';
import PhoneNumberInput from '../../../../common/components/PhoneNumberInput';
import './index.less';

// form verification rules
const FORM_RULES = {
  merchantName: [{ required: true, message: '请输入商户账号' }],
  accountName: [
    { required: true, message: '请输入账号' },
    {
      pattern: PHONE_NUMBER_REGEX,
      message: '请填写正确账号格式',
    },
  ],
  password: [{ required: true, message: '请输入密码' }],
};

export default function WebLogin() {
  const dispatch = useDispatch<AppDispatch>();
  // get login status pending
  const isLoginStatusPending = useSelector(getIsLoginStatusPending);
  // get merchant name
  const merchantName = useSelector(getMerchantName);
  // get account name
  const accountName = useSelector(getAccountName);
  // get password
  const password = useSelector(getPassword);
  // update merchant name
  const handleMerchantNameChange = (e: { target: { value: any } }) => {
    dispatch(loginActions.merchantNameUpdated(e.target.value));
  };
  // update account name
  const handleAccountNameChange = (phoneNumber: string) => {
    dispatch(loginActions.accountNameUpdated(phoneNumber));
  };
  // update password
  const handlePasswordChange = (e: { target: { value: any } }) => {
    dispatch(loginActions.passwordUpdated(e.target.value));
  };
  // navigate to home page
  const navigateToHomePage = () => {
    const { origin } = window.location;

    window.location.href = origin;
  };
  // submit login form
  const handleLoginSubmit = async () => {
    await dispatch(userLogin(navigateToHomePage));
  };

  useMount(() => {
    document.title = '在也｜登录';
    dispatch(userLoginStatusChecked(navigateToHomePage));
  });

  return (
    <div className="login">
      <div className="logoImageContainer">
        <img className="logoImage" src={LogoImage} alt="Zaiye logo" />
      </div>
      <div className="loginContentContainer flex flex-middle">
        <div className="loginImageContainer">
          <img className="loginImage" src={LoginImage} alt="Zaiye login" />
        </div>
        <div className="flex__fluid-content">
          <div className="loginContent">
            <div>
              <h2 className="loginTitle">欢迎回来</h2>
              <p className="loginDescription">
                请填写您订阅时申请的商家账户与登陆账号，登陆账号一般为员工注册电话
              </p>
              <Divider className="loginTextDivider" />
            </div>
            <Form
              className="loginForm"
              layout="vertical"
              ref={React.createRef<FormInstance>()}
              onFinish={handleLoginSubmit}
            >
              <Form.Item
                label="商户账号"
                name="merchantId"
                rules={FORM_RULES.merchantName}
              >
                <Input
                  size="large"
                  placeholder="商户账号"
                  value={merchantName}
                  onChange={handleMerchantNameChange}
                />
              </Form.Item>
              <PhoneNumberInput
                label="账号"
                placeholder="手机号码"
                size="large"
                phoneNumber={accountName}
                name="accountName"
                onChange={(phoneNumber: string) => {
                  handleAccountNameChange(phoneNumber);
                }}
              />
              <Form.Item
                label="密码"
                name="password"
                rules={FORM_RULES.password}
              >
                <Input.Password
                  size="large"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </Form.Item>
              <div className="text-right">
                <Link to="/forget-password">忘记密码?</Link>
              </div>

              <Form.Item className="loginButton">
                <Button
                  block
                  loading={isLoginStatusPending}
                  type="primary"
                  size="large"
                  htmlType="submit"
                >
                  登录
                </Button>
              </Form.Item>
            </Form>

            <div className="flex flex-center flex-middle">
              <LockOutlined />
              <span className="loginSecurityText">您的信息正在被保护中</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
