import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMount } from 'react-use';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Form,
  FormInstance,
  Input,
  Divider,
  Space,
  Modal,
  Select,
  message,
  notification,
} from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { AppDispatch } from '../../../../common/utils/types';
import {
  PHONE_NUMBER_REGEX,
  MERCHANT_ID_REGEX,
  BUSINESS,
} from '../../../../common/constants';
import {
  getAccountName,
  getMerchantName,
  getPassword,
  getOtp,
} from './redux/selectors';
import { actions as registerActions } from './redux';
import {
  getIsRegisterStatusPending,
  getIsCreatedOtpStatusPending,
  getBusiness,
  getDisplayBusinessTypes,
} from './redux/selectors';
import { loadBusinessType, userRegister, createOtp } from './redux/thunks';
import RegisterImage from '../../../../images/entrance-image.png';
import LogoImage from '../../../../images/logo-2x.png';
import PhoneNumberInput from '../../../../common/components/PhoneNumberInput';
import './Registered.less';

const OTP_COUNT_DOWN_DURATION = 60;
// form verification rules
const FORM_RULES = {
  merchantName: [
    { required: true, message: '请输入商户账号' },
    {
      pattern: MERCHANT_ID_REGEX,
      message: '请填写正确商户账号格式',
    },
  ],
  accountName: [
    { required: true, message: '请输入手机号码' },
    {
      pattern: PHONE_NUMBER_REGEX,
      message: '请填写正确手机号码',
    },
  ],
  otp: [{ required: true, message: '请输入手机验证码' }],
  password: [{ required: true, message: '请输入密码' }],
  confirmPassword: [
    { required: true, message: '请输入确认密码' },
    ({ getFieldValue }: { getFieldValue: Function }) => ({
      validator(_: any, value: string) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }

        return Promise.reject(new Error('两个密码不一致！'));
      },
    }),
  ],
};

export default function Registered() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [confirmPassword, setConfirmPassword] = useState();
  const [protocolAgreement, setProtocolAgreement] = useState(false);
  const [protocolModalVisible, setProtocolModalVisible] = useState(false);
  const [countingDown, setCountingDown] = useState(false);
  const [countingDownTime, setCountingDownTime] = useState(
    OTP_COUNT_DOWN_DURATION
  );
  // get register status pending
  const isRegisterStatusPending = useSelector(getIsRegisterStatusPending);
  // get otp status pending
  const isCreatedOtpStatusPending = useSelector(getIsCreatedOtpStatusPending);
  // get merchant name
  const merchantName = useSelector(getMerchantName);
  // get account name
  const accountName = useSelector(getAccountName);
  // get business
  const business = useSelector(getBusiness);
  // get password
  const password = useSelector(getPassword);
  // get otp
  const otp = useSelector(getOtp);
  // get business types
  const businessTypes = useSelector(getDisplayBusinessTypes);
  // update merchant name
  const handleMerchantNameChange = (e: { target: { value: any } }) => {
    dispatch(registerActions.merchantNameUpdated(e.target.value));
  };
  // update account name
  const handleAccountNameChange = (phoneNumber: string) => {
    dispatch(registerActions.accountNameUpdated(phoneNumber));
  };
  // update password
  const handlePasswordChange = (e: { target: { value: any } }) => {
    dispatch(registerActions.passwordUpdated(e.target.value));
  };
  const handleOtpChange = (e: { target: { value: any } }) => {
    dispatch(registerActions.otpUpdated(e.target.value));
  };
  // navigate to home page
  const navigateToHomePage = () => {
    Modal.success({
      centered: true,
      title: '已经成功注册在也',
      content:
        '注册的场馆账户、登录账号和密码分别对应登录页面的商户账号、账号和密码',
      okText: '去首页',
      cancelText: '取消',
      onOk: () => {
        navigate('/dashboard');
      },
    });
  };
  // otp resend count down
  let timeoutTimer: any = null;
  const otpResendCountDown = (time: number) => {
    if (time === 0) {
      setCountingDown(false);
      clearTimeout(timeoutTimer);
      return;
    }

    timeoutTimer = setTimeout(() => {
      time -= 1;
      setCountingDownTime(time);
      otpResendCountDown(time);
    }, 1000);
  };
  // selected a business
  const handleBusinessSelected = (value: string) => {
    dispatch(registerActions.businessUpdated(value));
  };
  // create a new otp
  const handleOtpCreate = async () => {
    try {
      await form.validateFields(['merchantId', 'accountName']);
      setCountingDown(true);
      otpResendCountDown(OTP_COUNT_DOWN_DURATION);
      dispatch(createOtp());
    } catch (error: any) {
      message.error('获取验证码失败');
    }
  };
  // submit register form
  const handleRegisterSubmit = () => {
    if (protocolAgreement) {
      dispatch(userRegister(navigateToHomePage));
    } else {
      notification.info({ message: '需要您先同意场馆注册协议' });
      setProtocolModalVisible(true);
    }
  };

  useMount(() => {
    document.title = '在也｜注册';

    dispatch(loadBusinessType());
  });

  return (
    <>
      <div className="register">
        <div className="logoImageContainer">
          <img className="logoImage" src={LogoImage} alt="Zaiye logo" />
        </div>
        <div className="registerContentContainer flex flex-middle">
          <div className="registerImageContainer">
            <img
              className="registerImage"
              src={RegisterImage}
              alt="Zaiye register"
            />
          </div>
          <div className="flex__fluid-content">
            <div className="registerContent">
              <div>
                <h2 className="registerTitle">注册新场馆/俱乐部</h2>
                <Divider className="registerTextDivider" />
              </div>
              <Form
                className="registerForm"
                layout="vertical"
                form={form}
                ref={React.createRef<FormInstance>()}
                onFinish={handleRegisterSubmit}
              >
                <Space
                  size={24}
                  className="registerAccountInfo flex flex-middle"
                >
                  <Form.Item
                    label="场馆/俱乐部账户"
                    className="registerMerchantNameItem"
                    name="merchantId"
                    rules={FORM_RULES.merchantName}
                  >
                    <Input
                      size="large"
                      placeholder="小写英文字符 不超过10位"
                      value={merchantName}
                      onChange={handleMerchantNameChange}
                    />
                  </Form.Item>

                  <Form.Item
                    label="场馆/俱乐部类型"
                    className="flex__fluid-content"
                  >
                    <Select
                      className="registerBusinessSelect"
                      size="large"
                      value={business}
                      options={
                        businessTypes.length > 0 ? businessTypes : BUSINESS
                      }
                      onChange={handleBusinessSelected}
                    />
                  </Form.Item>
                </Space>

                <PhoneNumberInput
                  label="登录账号"
                  placeholder="手机号码"
                  size="large"
                  name="accountName"
                  phoneNumber={accountName}
                  onChange={(phoneNumber: string) => {
                    handleAccountNameChange(phoneNumber);
                  }}
                />

                <Form.Item name="otp" rules={FORM_RULES.otp}>
                  <Space size={40} className="registerOtp flex flex-middle">
                    <Input
                      type="tel"
                      size="large"
                      allowClear
                      placeholder="短信验证码"
                      value={otp}
                      onChange={handleOtpChange}
                    />
                    <Button
                      block
                      loading={isCreatedOtpStatusPending}
                      type="primary"
                      size="large"
                      htmlType="button"
                      disabled={countingDown}
                      onClick={handleOtpCreate}
                    >
                      获取验证码{countingDown ? `(${countingDownTime}s)` : ''}
                    </Button>
                  </Space>
                </Form.Item>
                <Form.Item
                  label="密码"
                  name="password"
                  rules={FORM_RULES.password}
                >
                  <Input.Password
                    size="large"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                </Form.Item>
                <Form.Item
                  label="确认密码"
                  name="confirmPassword"
                  dependencies={['password']}
                  rules={FORM_RULES.confirmPassword}
                >
                  <Input.Password
                    size="large"
                    value={confirmPassword}
                    onChange={(e: { target: { value: any } }) => {
                      setConfirmPassword(e.target.value);
                    }}
                  />
                </Form.Item>

                <Form.Item className="registerButton">
                  <Button
                    block
                    loading={isRegisterStatusPending}
                    type="primary"
                    size="large"
                    htmlType="submit"
                  >
                    注册
                  </Button>
                </Form.Item>
              </Form>

              <div>
                <div className="flex flex-center flex-middle">
                  <CheckCircleFilled
                    className={`primary-color ${
                      protocolAgreement ? '' : 'registerProtocolDisabledIcon'
                    }`}
                  />
                  <p className="registerProtocolText">
                    <span>已阅读并同意</span>
                    <Button
                      className="registerProtocolButton"
                      type="link"
                      size="small"
                      onClick={() => setProtocolModalVisible(true)}
                    >
                      场馆俱乐部注册协议
                    </Button>
                  </p>
                </div>
                <Modal
                  title="场馆注册协议"
                  cancelText="不同意"
                  okText="同意并继续"
                  width="100%"
                  className="registerProtocolModal"
                  visible={protocolModalVisible}
                  onOk={() => {
                    setProtocolAgreement(true);
                    setProtocolModalVisible(false);
                  }}
                  onCancel={(e) => {
                    setProtocolAgreement(false);
                    setProtocolModalVisible(false);
                  }}
                >
                  <div className="registerProtocolTermsContent">
                    <h2 className="text-center text-bold">SaaS 系统服务协议</h2>
                    <p className="text-right">
                      最新版本生效日期：2022年10月21日
                    </p>
                    <section>
                      <p>
                        &emsp;&emsp;本《SaaS系统服务协议》（以下简称“本协议”）是上海寻路猫信息科技有限公司（以下简称“寻路猫”）和您（以下亦称“乙方”）注册、使用附随本协议的【在也场馆管理
                        SaaS 系统】（包括在也场馆管理 SaaS
                        平台和／或商家管理后台、会员端小程序、店员端小程序等）（以下简称“系统”／“本系统”）订立的具有法律约束力的协议。
                      </p>
                      <p>
                        &emsp;&ensp;【审慎阅读】请您务必审慎阅读、充分理解本协议各条款内容。如您与寻路猫系在线上签署本协议的，在您不同意本协议或其中任何条款约定的情况下，您应立即停止注册程序。当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、完全理解并接受本协议的全部内容。
                      </p>
                    </section>
                    <section>
                      <h4 className="text-center text-bold">
                        第一章 总则及双方主体资格
                      </h4>
                      <p>
                        &emsp;&ensp;【在也场馆管理 SaaS
                        系统】是指由寻路猫自主开发并享有知识产权、所有权等全部合法权益的软件系统。本协议项下乙方可使用的系统类型以乙方实际向
                        寻路猫购买或通过寻路猫公布的官方渠道下载或以其他合法方式获取的特定软件系统为准。
                      </p>
                      <p>
                        &emsp;&emsp;寻路猫，系一家根据中国法律有效设立并合法存续的股份有限公司，其统一社会信用代码为91310120MA7EECDP93,注册地址为上海市奉贤区青村镇青高路890号1幢。
                      </p>
                      <p>
                        &emsp;&emsp;乙方确认，乙方应当是具备相应民事行为能力的自然人、法人或其他组织。乙方应当根据寻路猫的要求或指示的方式，向寻路猫提供符合签署本协议所需提供的主体资格证明文件，包括但不限于：身份证、营业执照等。若乙方不具备前述主体资格或行为能力的，本协议不应当发生效力。
                      </p>
                    </section>
                    <section>
                      <h4 className="text-center text-bold">第二章 系统功能</h4>
                      <div>
                        <p>
                          <strong>第一条</strong>
                          &emsp;&ensp;寻路猫提供的系统包含以下业务板块及功能，乙方在此同意，下述系统类型及功能可能因寻路猫的单方判断而被增加或修改，或因定期、不定期的维护而暂缓提供：
                        </p>
                        <ul>
                          <li>
                            (1)
                            会籍管理系统，乙方可以通过系统进行会员会籍管理，会员卡管理等操作，并通过系统的商家管理后台、店员端小程序进行代会员成绩登记、比赛验证、会员管理等多种操作；
                          </li>
                          <li>
                            (2)
                            员工管理系统，乙方可以通过系统进行员工档案管理、员工权限管理、
                            员工操作审计跟踪管理等多种操作；
                          </li>
                          <li>
                            (3)
                            会员积分系统，乙方可以通过系统进行会员积分管理，包括但不限于会员积分登记、会员积分查询等多种操作；
                          </li>
                          <li>
                            (4)
                            其他寻路猫同意加入的由其开发、补充的新业务板块和功能。
                          </li>
                        </ul>
                      </div>
                      <div>
                        <p>
                          <strong>第二条</strong>
                          &emsp;&ensp;寻路猫提供的系统包含以下子产品：
                        </p>
                        <ul>
                          <li>(1) 商家管理后台；</li>
                          <li>(2) 会员端小程序；</li>
                          <li>(3) 店员端小程序。</li>
                        </ul>
                        <p>
                          &emsp;&emsp;寻路猫提供的“在也场馆”客户端、商家端系统，应能正常在各终端微信客户端中应用，包括但不限于各终端微信最新版本，因微信版本更新导致本产品无法正常使用的情况下甲方应及时对产品做出调整以适应各系统的正常使用。寻路猫提供的“在也场馆”后台管理系统，应能正常在各终端浏览器应用，包括但不限于Windows系统或者苹果Mac操作系统上安装的Chrome、QQ浏览器、搜狗浏览器、火狐、猎豹、Safari浏览器最新版本，因浏览器版本更新导致【在也场馆管理
                          SaaS
                          系统】无法正常使用的情况下甲方应及时对其做出调整以适应各系统的正常使用。同时，乙方应当积极配合寻路猫的更新，对产品进行更适当的使用以提升乙方的使用体验，包括但不限于：及时更新微信客户端、浏览器版本，避免使用的微信客户端、浏览器版本过于老旧等。
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第三条</strong>
                          &emsp;&ensp;寻路猫保留在任何时候自行决定对系统及其相关功能变更、升级、修
                          改、转移的权利。寻路猫进一步保留开发新的模块、功能和软件或其它语种服务
                          的权利。上述所有新的模块、功能、软件及服务的提供，除非寻路猫另有说明，
                          否则仍适用本协议。
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第四条</strong>
                          &emsp;&ensp;
                          寻路猫提供的各项业务板块和功能均有其相应的操作规则，乙方需
                          按照具体的页面提示或规则进行操作和使用，包括但不限于提交信息、进行验证、
                          交纳相关费用等；否则，乙方可能无法使用全部或部分系统功能。乙方在此同意
                          并确认，寻路猫在任何情况下都无需向乙方在使用本系统时发生的在传输或联
                          络中的迟延、不准确、错误或疏漏及因此而致使的损害负责，甲方应配合乙方找回及修复相应的数据，但甲方不对乙方的数据是否能够最终找回做出任何承诺。
                        </p>
                      </div>
                    </section>
                    <section>
                      <h4 className="text-center text-bold">
                        第三章 系统使用规则
                      </h4>
                      <div>
                        <p>
                          <strong>第五条</strong>
                          &emsp;&ensp;
                          系统的获取：乙方应当从寻路猫公布的官方渠道下载或以其他合法
                          方式获取本系统。如果乙方从未经寻路猫授权的第三方获取本系统或与本系统名
                          称相同的安装程序，寻路猫无法保证该安装后的系统能够正常使用，并对因此给
                          乙方造成的损失不予负责。
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第六条</strong>
                          &emsp;&ensp;
                          注册账号：乙方应当按照寻路猫显示的注册页面提示进行账号注册的相关操作并提供注册所需的信息、资料。乙方应妥善保管注册账号和密码，同时对注册账号和密码安全承担全部责任。
                          <u>
                            除有充足的相反证据外，通过乙方的注
                            册账号和密码进行的全部操作均视为乙方自行操作的行为。
                          </u>
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第七条</strong>
                          &emsp;&ensp;
                          系统升级功能：基于改进服务、提高用户体验的目的，寻路猫有权不
                          定时对系统进行更新；同时，乙方可以通过寻路猫公布的官方渠道或者寻路猫指
                          定的其他方式下载更新过的软件系统版本。
                        </p>
                      </div>
                    </section>
                    <section>
                      <h4 className="text-center text-bold">
                        第四章 系统使用费用
                      </h4>
                      <p>
                        <strong>第八条</strong>
                        &emsp;&ensp;
                        本系统供乙方免费使用，寻路猫不向乙方收取任何费用。
                      </p>
                    </section>
                    <section>
                      <h4 className="text-center text-bold">
                        第五章 寻路猫的权利义务
                      </h4>
                      <div>
                        <p>
                          <strong>第九条</strong>
                          &emsp;&ensp;
                          寻路猫将按照本协议的规定许可乙方免费使用本系统；
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第十条</strong>
                          &emsp;&ensp;
                          寻路猫有权随时删除含有任何违反法律法规、本协议及寻路猫发布
                          的各项规则的信息或链接，包括寻路猫对此有合理怀疑的信息。寻路猫有权单独
                          对乙方提供的信息是否属于上述范围做出判断；
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第十一条</strong>
                          &emsp;&ensp;
                          <u>
                            如果寻路猫发现或收到他人举报乙方发布的信息或提供的服务违
                            反相关法律规定或侵犯任何第三方的合法权益的，寻路猫有权进行独立判断并采取技术手段予以删除、屏蔽或断开链接。同时，寻路猫有权视乙方的行为性质，
                            采取包括但不限于暂停或终止服务，限制、冻结或终止乙方使用本系统的权利，
                            追究乙方的法律责任等措施；同时，寻路猫在采取上述措施之后将以适当的方式（包括但不限于邮件、电话、公告等）通知乙方。乙方违反本协议约定或乙方与
                            其他第三方的约定，导致任何第三方损害的，乙方应当独立承担责任；寻路猫因
                            此遭受损失的，乙方也应当一并赔偿。
                          </u>
                        </p>
                      </div>
                    </section>
                    <section>
                      <h4 className="text-center text-bold">
                        第六章 乙方的权利义务
                      </h4>
                      <div>
                        <p>
                          <strong>第十二条</strong>
                          &emsp;&ensp;
                          乙方应当保证向寻路猫提交的注册信息均真实、准确、及时、详尽和
                          完整，并根据实际情况变化更新注册资料，以符合前述要求；乙方保证乙方提交的信息不含有任何违反国家法律、法规及中华人民共和国承认或加入的国际条约
                          的内容，且乙方通过本系统所从事的一切活动都是合法、真实、有效的，不侵犯
                          任何第三方的合法权益；
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第十三条</strong>
                          &emsp;&ensp;
                          因黑客攻击、木马、病毒等行为或乙方的保管疏忽等导致注册账号、
                          密码遭他人非法使用所造成的损失由乙方自行承担，寻路猫不承担任何责任；
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第十四条</strong>
                          &emsp;&ensp;
                          乙方理解并保证不利用且不允许他人利用本系统传输或发表包含以下内容的言论：
                        </p>
                        <ul>
                          <li>
                            (1)
                            反对宪法所确定的基本原则，煽动、抗拒、破坏宪法和法律、行政法规实施的；
                          </li>
                          <li>
                            (2)
                            煽动颠覆国家政权，推翻社会主义制度，煽动、分裂国家，破坏国家统
                            一的；
                          </li>
                          <li>
                            (3)
                            损害国家机关信誉的、损害国家荣誉和利益的、损害社会公共利益和涉及国家安全的；
                          </li>
                          <li>(4) 煽动民族仇恨、民族歧视，破坏民族团结的；</li>
                          <li>(5) 对种族、性别、宗教、地域等歧视的；</li>
                          <li>
                            (6) 捏造或者歪曲事实，散布谣言，扰乱社会秩序的；
                          </li>
                          <li>
                            (7)
                            宣扬封建迷信、邪教、淫秽、色情、赌博、暴力、凶杀、恐怖、教唆犯罪的；
                          </li>
                          <li>
                            (8)
                            骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的、公然侮辱他人或者捏造事实诽谤他人的，或者进行其他恶意攻击的；
                          </li>
                          <li>(9) 其他违反宪法和法律行政法规的。</li>
                        </ul>
                      </div>
                      <div>
                        <p>
                          <strong>第十五条</strong>
                          &emsp;&ensp;乙方在此声明并保证：
                        </p>
                        <ul>
                          <li>
                            (1)
                            乙方通过本系统发布的所有信息（包括但不限于乙方使用的企业名称、
                            Logo、服务信息、服务介绍等），均系真实、完整、合法的信息，不侵
                            犯任何第三方的知识产权、所有权、肖像权、名誉权、姓名权、商誉等
                            合法权益，但寻路猫及其关联公司无义务对此等信息进行审核。
                          </li>
                          <li>
                            (2)
                            乙方通过本系统所发布的任何信息或提供的任何资料都必须符合寻路猫公示或未来公示的发布须知及相关文字说明。
                          </li>
                          <li>
                            (3)
                            乙方将通过本系统维护并及时更新上述资料，以保证其真实、准确、及时、合法和完整性。
                          </li>
                        </ul>
                      </div>
                      <div>
                        <p>
                          <strong>第十六条</strong>
                          &emsp;&ensp;
                          乙方进一步理解并同意，如果乙方提供的信息不真实、不准确、不及
                          时、不合法或不完全，或寻路猫有合理的理由怀疑其真实性、准确性、及时性、
                          合法性和完整性，则寻路猫有权拒绝乙方使用本系统及以后可能提供的系统或服务。
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第十七条</strong>
                          &emsp;&ensp;为保证寻路猫的合法权益不受侵害，以下是寻路猫禁止乙方进行的
                          一些有害活动的示例，包括但不限于：
                        </p>
                        <ul>
                          <li>
                            (1) 禁止出售、转售或复制、开发寻路猫授予的使用权限；
                          </li>
                          <li>(2) 禁止基于商业目的模仿寻路猫的产品和服务；</li>
                          <li>
                            (3)
                            禁止复制和模仿寻路猫的设计理念、界面、功能和图表；
                          </li>
                          <li>
                            (4)
                            禁止未经寻路猫许可基于系统或其内容进行修改或制造派生其他产品；
                          </li>
                          <li>
                            (5)
                            乙方只能处于乙方商业范围内使用本系统，禁止发送违反法律的信息，
                            禁止发送和储存带有病毒的、蠕虫的、木马的和其他有害的计算机代码、
                            文件、脚本和程序。
                          </li>
                        </ul>
                      </div>
                      <div>
                        <p>
                          <strong>第十八条</strong>
                          &emsp;&ensp;
                          <u>
                            乙方进一步同意对本系统和/或本系统任何部分（包括但不限于系统部件、页面标识、服务品牌、资讯、信息等）不进行复制、翻译、修改、适
                            应、增强、反编译、反汇编、反向工程、分解拆卸、出售、转租或作任何商业目
                            的的使用。乙方同意约束其有必要使用本系统的员工、代理、咨询者或顾问等合作方遵守前述之义务，并就其违反前述规定的行为对寻路猫承担如同乙方自身违反所产生的责任。
                          </u>
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第十九条</strong>
                          &emsp;&ensp;
                          乙方必须自行配备上网所需的设备，包括计算机、调制解调器或
                          其它必备的上网设备，并自行负担上网所需的电话费用、网络服务费用等。
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第二十条</strong>
                          &emsp;&ensp;
                          乙方承诺通过本系统进行的活动所引发的一切法律后果，由乙方
                          承担全部责任。如因乙方使用本系统的行为，导致寻路猫或任何第三方为此承担
                          了相关的责任，则乙方需全额赔偿寻路猫或任何第三方的相关支出及损失，包括
                          合理的律师费用。
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第二十一条</strong>
                          &emsp;&ensp;
                          乙方同意如因乙方违反本协议规定的任何条款或寻路猫不时发
                          布的各项规则、通告，寻路猫有权单方判断立即终止乙方使用本系统，而无需提
                          前通知乙方。
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第二十二条</strong>
                          &emsp;&ensp;
                          乙方同意在使用本系统的同时，接受寻路猫提供的各类信息服务。
                        </p>
                      </div>
                    </section>
                    <section>
                      <h4 className="text-center text-bold">
                        第七章 信息使用规则
                      </h4>
                      <div>
                        <p>
                          <strong>第二十三条</strong>
                          &emsp;&ensp;
                          乙方在此同意，在乙方使用本系统的过程中，寻路猫将会按照本协议规定的目的、方式和范围收集和使用与乙方及乙方客户的相关信息，包括但
                          不限于姓名／名称、身份证号、手机号、紧急联系人、运营商数据、社交
                          ID 号、
                          地址、联系方式、简介、求职招聘信息、场馆安排及教练、服务人员安排、场馆
                          相关信息、培训及比赛活动安排与开展情况、与第三方开展合作的情况、交易记
                          录及交易流水等。在使用本系统前，请乙方仔细阅读以下条款。本章中以下条款
                          的规定可能将取代寻路猫与乙方在本协议签署之前达成的其他协议或约定（如有）。
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第二十四条</strong>
                          &emsp;&ensp;
                          一般情况下，乙方可根据系统页面的提示或规则浏览、访问、修改、删除自己提交的信息。
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第二十五条</strong>
                          &emsp;&ensp;
                          乙方理解并同意，寻路猫会基于以下目的收集和使用乙方和/或
                          乙方客户的相关信息：
                        </p>
                        <ul>
                          <li>
                            (1) 为乙方和/或乙方客户提供与系统使用相关的服务；
                          </li>
                          <li>(2) 帮助寻路猫设计新服务，提升现有系统体验；</li>
                          <li>
                            (3)
                            在寻路猫提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、
                            存档和备份用途等，确保寻路猫向乙方提供的系统和服务的安全性；
                          </li>
                          <li>
                            (4)
                            向乙方和/或乙方客户发送乙方和/或乙方客户可能感兴趣的产品和服务的信息；向乙方和/或乙方客户提供与乙和/或乙方客户方更加相关的定向广告以替
                            代普遍投放的广告；邀请乙方和/或乙方客户参与寻路猫的活动和市场调查；
                          </li>
                          <li>
                            (5)
                            向乙方和/或乙方客户推荐、提供潜在的商业合作伙伴及商业合作机会，
                            促进乙方或寻路猫商业活动的开展；
                          </li>
                          <li>
                            (6)
                            开展内部审计、数据分析和研究，改善寻路猫的产品、服务及与用户之间的沟通。
                          </li>
                        </ul>
                        <p>
                          &emsp;&ensp;
                          需要注意的是，如寻路猫就上述第（4）、（5）条的目的下拟收集和使用乙方和/或乙方客户的相关信息，则寻路猫除应取得乙方理解并同意外，还应就此事项的具体细节与乙方另行签署相关协议。
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第二十六条</strong>
                          &emsp;&ensp;
                          <u>
                            寻路猫不会在未经乙方同意或授权的情况下将乙方和/或乙方客户的相关信息提供给第三方。但是，乙方在此同意，寻路猫可在以下情况下共享或披露乙方和/或乙方客户的相关信息：
                          </u>
                        </p>
                        <ul>
                          <li>
                            (1)
                            在获取同意的情况下共享：在获得乙方和/或乙方客户的同意后，寻路猫会与其他方共享乙方和/或乙方客户的相关信息。
                          </li>
                          <li>
                            (2)
                            共享给寻路猫的关联公司：在本协议的使用目的范围内，乙方和/或乙方客户的相关信息可能会与寻路猫的关联公司共享。作为一项政策，寻路猫只会共享必要的信息。上述“关联公司”系指：寻路猫直接或间接控制
                            地其他企业任何形态的经营组织，或者寻路猫受其控制的企业或任何形
                            态的经营组织，以及与寻路猫同受某一企业控制的企业或任何形态的经营组织。
                          </li>
                          <li>
                            (3)
                            共享给授权合作伙伴：寻路猫可能会与合作伙伴共享乙方或乙方客户的
                            相关信息，以向乙方或乙方客户提供更好的客户服务和用户体验，或者
                            向乙方或乙方客户推荐、提供潜在的商业合作伙伴及商业合作机会等目
                            的。{' '}
                          </li>
                          <li>
                            (4)
                            基于法律或合理依据的披露：在法律、法律程序、诉讼或公共利益及政
                            府主管部门要求的情况下，寻路猫可能会披露乙方和/或乙方客户的相关信息。在特殊情况下，如果寻路猫牵涉到重组、合并或破产和清理诉讼，
                            那么乙方和/或乙方客户的相关信息还会根据相关法律法规的要求披露
                            给相关交易方。寻路猫还会在存在合理需求的情况下披露乙方和/或乙方客户的信息，例如出于保护客户的目的之情况下。但寻路猫在合法和可行的情况下应提前向乙方或乙方客户通知该披露事宜并与乙方或乙方客户协商披露的方式和内容。
                          </li>
                          <li>
                            (5)
                            受共享方不能与乙方属于同行业关系，如受共享方与乙方属于同行业关系，则必须经由乙方书面同意后方可共享。【在也场馆管理
                            SaaS
                            系统】中已有的如对战房间功能等不在此限制之列，如未来寻路猫拟开发类似的信息、资源共享型功能，则应当事先告知乙方并与乙方另行签署相关协议。
                          </li>
                        </ul>
                      </div>
                      <div>
                        <p>
                          <strong>第二十七条</strong>
                          &emsp;&ensp;乙方同意寻路猫可在其网站、小程序及其他展示平台和/或宣传文件中使用其名称、商标、商号或Logo以展示寻路猫与乙方的合作关系。
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第二十八条</strong>
                          &emsp;&ensp;寻路猫将在其网络系统内建立合理的安全体系，包括身份识别体
                          系、内部安全防范体系，以使乙方和/或乙方客户数据保持完整及使用本系统的
                          安全性。但乙方了解并同意技术手段在不断更新，寻路猫无法杜绝全部的非安全
                          因素，就因技术水平限制等非因寻路猫原因产生的数据问题，寻路猫不承担责任。但寻路猫会及时更新安全体系，妥善维护网络及相关数据。
                        </p>
                      </div>
                    </section>

                    <section>
                      <h4 className="text-center text-bold">第八章 保密条款</h4>
                      <div>
                        <p>
                          <strong>第二十九条</strong>
                          &emsp;&ensp;除本协议约定的信息使用规则以外，甲乙双方应互相遵守保密条
                          款，任何一方（“接收方”）对于本协议内容及因履行本协议而获知的另一方（“披
                          露方”）的商业秘密、技术秘密及其他需要保密的资料和信息负有保密义务，上述保密信息包括：
                        </p>
                        <ul>
                          <li>
                            (1)
                            披露方的企业发展战略及实施计划，披露方的经营、投资计划；
                          </li>
                          <li>
                            (2)
                            披露方在合作期间提供的经营、业务、产品技术等所有相关的各种形式的文件、图纸、信息、软件等资料；
                          </li>
                          <li>(3) 披露方的组织架构、系统结构与团队构成；</li>
                          <li>
                            (4)
                            披露方的内部管理信息及有关财务数据、状况等财务信息；
                          </li>
                          <li>(5) 披露方的服务信息、服务入口、服务类型等；</li>
                          <li>
                            (6)
                            披露方不时指定为具有秘密性质而需受本协议保护的任何其他信息；
                          </li>
                          <li>
                            (7)
                            按照法律和协议，披露方对第三方负有保密责任的第三方的上述保密信息；
                          </li>
                          <li>
                            (8)
                            双方另行共同约定的其他信息或资料等。对该信息是否属于保密的判定除披露方在披露时注明是保密信息外，接收方还应基于合理善意判断该信息的保密属性。
                          </li>
                        </ul>
                      </div>
                      <div>
                        <p>
                          <strong>第三十条</strong>
                          &emsp;&ensp;双方知晓本协议项下相关保密信息的唯一目的是本协议约定的合作目的，双方均承诺不将因此而取得的披露方保密信息用于其他用途。除信息使用规则外，任何将披露方保密信息用于其他用途的行为均被视为对保密义务的违反。
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第三十一条</strong>
                          &emsp;&ensp;接收方应以不低于对待自己的保密信息的谨慎来妥善保管和使
                          用披露方的保密信息。非经对方书面同意，任何一方不得向第三方泄露、给予或
                          转让该保密信息或应用于与本协议无关之工作。
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第三十二条</strong>
                          &emsp;&ensp;未经披露方事先书面允许，接收方应保证其董事、监事、高级管
                          理人员、雇员及外部聘请顾问等相关人员，不得在任何地点以任何形式将其获悉
                          的保密信息分发、披露或散布给特定的或不特定的任何第三方机构和个人。但以
                          下情况除外：在法律、法律程序、诉讼或公共利益及政府主管部门要求的情况下，
                          但接收方在合法和可行的情况下应提前向披露方通知该披露事宜并与披露方协
                          商披露的方式和内容。
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第三十三条</strong>
                          &emsp;&ensp;如一方提出要求，另一方均应将载有对方保密信息的任何文件、
                          资料或软件，按对方要求归还对方、或予以销毁、或进行其他处置，并且不得继
                          续使用这些保密信息。
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第三十四条</strong>
                          &emsp;&ensp;任何一方违反本协议约定的保密义务的，守约方均有权要求违约
                          方赔偿损失。
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第三十五条</strong>
                          &emsp;&ensp;各方在本协议项下的保密义务在本协议履行期间及履行完毕后【三】年内持续有效。
                        </p>
                      </div>
                    </section>
                    <section>
                      <h4 className="text-center text-bold">
                        第九章 第三方服务说明
                      </h4>
                      <div>
                        <p>
                          <strong>第三十六条</strong>
                          &emsp;&ensp;乙方了解并确认本协议中可能包含由第三方提供的服务，寻路猫
                          只是为了乙方的便利而提供该功能模块，但是寻路猫并不控制第三方的服务内容，
                          也不对其负责。乙方如需使用此等服务，应另行与第三方服务提供方达成服务协
                          议，支付相应费用并承担可能的风险。寻路猫对第三方提供的服务不提供任何形
                          式的保证。
                        </p>
                      </div>
                    </section>
                    <section>
                      <h4 className="text-center text-bold">第十章 知识产权</h4>
                      <div>
                        <p>
                          <strong>第三十七条</strong>
                          &emsp;&ensp;双方确认，寻路猫及关联公司拥有本协议所规定的系统所包括但不限于的以下知识产权：著作权、商标权、专利权、专利申请权、专有技术、商业
                          秘密以及其他相关的知识产权等，包括与该系统有关的各种文档资料。其它本协
                          议中未经提及的权利亦由寻路猫保留。
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第三十八条</strong>
                          &emsp;&ensp;未经寻路猫事先书面同意，乙方不得为任何营利性或非营利性的
                          目的自行实施、利用、修改、反向编译、复制、公开传播、改变、散布、发行或
                          公开发表、转让或许可任何第三方实施、利用、修改、反向编译、复制、公开传
                          播、改变、散布、发行或公开发表上述知识产权。
                        </p>
                      </div>
                    </section>
                    <section>
                      <h4 className="text-center text-bold">
                        第十一章 免责及责任的限制与排除
                      </h4>
                      <div>
                        <p>
                          <strong>第三十九条</strong>
                          &emsp;&ensp;寻路猫以“现状”、“有缺陷”和“当前功能”的状态提供本系统。寻路猫不保证系统在操作上不会中断或没有错误，不保证其会纠正系统的所有缺陷，亦不保证系统能满足乙方的所有要求。乙方承担所有关于令人满意的质量、性能、准确性的风险。
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第四十条</strong>
                          &emsp;&ensp;在所适用的法律允许的范围内，寻路猫不做任何明示的或默示的声明，也不给予任何明示的或默示的保证或条件，包括但不限于：
                        </p>
                        <ul>
                          <li>
                            (1)
                            关于适销性、特定用途适用性、准确性和无侵权行为的任何保证或条件；
                          </li>
                          <li>
                            (2) 在交易过程或行业惯例中产生的任何保证或条件；
                          </li>
                          <li>
                            (3)
                            在访问或使用系统时不受干扰、没有错误的任何保证或条件。{' '}
                          </li>
                        </ul>
                      </div>
                      <div>
                        <p>
                          <strong>第四十一条</strong>
                          &emsp;&ensp;
                          <u>
                            除寻路猫存在故意或重大过失外，寻路猫不就因乙方使用本系统引起的，或在任何方面与寻路猫的产品和服务有关的任何意外的、非直接的、特殊的、或间接的损害或请求（包括但不限于因人身伤害、因隐私泄漏、因未能履行包括诚信或合理谨慎在内的任何义务、因过失和因任何其他金钱上的损失或其他损失而造成的损害赔偿）承担任何责任。
                          </u>
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第四十二条</strong>
                          &emsp;&ensp;
                          寻路猫不就电讯系统或互联网的中断或无法运作、技术故障、计
                          算机错误或病毒、信息损坏或丢失或其它在寻路猫合理控制范围之外的原因而产
                          生的其他任何性质的破坏而向乙方或任何第三方承担损害赔偿责任。
                        </p>
                      </div>
                    </section>
                    <section>
                      <h4 className="text-center text-bold">
                        第十二章 协议的解除与终止
                      </h4>
                      <div>
                        <p>
                          <strong>第四十三条</strong>
                          &emsp;&ensp;
                          <u>
                            寻路猫有权在下列情形下拒绝乙方的订购或终止本协议，而无需承担任何责任：
                          </u>
                        </p>
                        <ul>
                          <li>
                            (1)
                            乙方违反了本协议的任一条款，且自寻路猫通知乙方纠正后的合理期限内仍未纠正的；
                          </li>
                          <li>
                            (2)
                            寻路猫或寻路猫授权的第三方无法确认乙方提供的注册信息的真实性的；
                          </li>
                          <li>
                            (3)
                            寻路猫授予乙方的系统使用许可期限届满。系统使用许可期限届满后如
                            需继续使用本协议约定之系统的，乙方需与寻路猫另行签署有关购买该
                            系统的标准订单或按照寻路猫公布的规则获取系统使用权，并且可能需
                            要乙方向寻路猫或寻路猫指定的第三方支付相应的费用。
                          </li>
                        </ul>
                      </div>
                      <div>
                        <p>
                          <strong>第四十四条</strong>
                          &emsp;&ensp;
                          如协议一方，除为重组或兼并的目的外，通过决议或由法院判决
                          解散清算，则接收方或指定的债权人代表有权经通知后终止本协议。
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第四十五条</strong>
                          &emsp;&ensp;
                          尽管协议终止，但本协议约定的保密条款不因本协议的终止而终
                          止。
                        </p>
                      </div>
                    </section>
                    <section>
                      <h4 className="text-center text-bold">
                        第十三章 不可抗力
                      </h4>
                      <div>
                        <p>
                          <strong>第四十六条</strong>
                          &emsp;&ensp;
                          对于黑客攻击或政府管制或网络通讯瘫痪等对其发生和后果不
                          能预见的事件，双方均确认此属不可抗力；双方应按照不可抗力对影响履行本协
                          议的程度，协商决定是否解除本协议、免除履行本协议的部分义务，或者延期履
                          行本协议。
                        </p>
                      </div>
                    </section>

                    <section>
                      <h4 className="text-center text-bold">
                        第十四章 违约责任
                      </h4>
                      <div>
                        <p>
                          <strong>第四十七条</strong>
                          &emsp;&ensp; 双方一致同意接受以下违约条款：
                        </p>
                        <ul>
                          <li>
                            (1)
                            若任何一方未能履行其在本合同中规定的相关义务，或未能遵守其在本合同中所作的任何承诺，或者任何一方在本合同中所做陈述或保证不真实或有重大遗漏均被视为违约。
                          </li>
                          <li>
                            (2)
                            任何一方违反本合同约定的，应承担违约责任。当违约行为的处理方法未在本协议其他条款中作具体规定时，违约方须支付给对方本协议使用费用金额的30％作为违约金。上述规定并不影响守约者根据法律、法规或本协议其它条款之规定，就本条规定所不能补偿之损失，请求损害赔偿的权利。违约方需承担守约方为主张权利所支付的全部费用，包括律师费用、损害补偿费用、政府机关处罚费用和其它侵权赔偿费用等。
                          </li>
                          <li>
                            (3)
                            由于不可抗力或意外事件而导致的乙方软件服务延迟或中断，不视作乙方违约。不可抗力是指所有超出本合同双方合理控制范围的事件，该事件应不可预见，或虽然可以预见，但通过合理努力无法阻止或避免其发生，且这类事件发生于本合同签署之后，并且阻止任何一方全部或部分履行本合同。
                          </li>
                        </ul>
                      </div>
                    </section>
                    <section>
                      <h4 className="text-center text-bold">
                        第十五章 法律及争议解决
                      </h4>
                      <div>
                        <p>
                          <strong>第四十八条</strong>
                          &emsp;&ensp;
                          本协议的订立、效力、履行、解释和争议的解除均适用中华人民
                          共和国法律。
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第四十九条</strong>
                          &emsp;&ensp;
                          双方同意，凡因执行本协议所发生的或与本协议有关的一切争议，
                          甲乙双方应通过友好协商形式解决。如协商无效的，则甲乙双方一致同意提交寻路猫所在地法院解决。
                        </p>
                      </div>
                    </section>

                    <section>
                      <h4 className="text-center text-bold">第十六章 其他</h4>
                      <div>
                        <p>
                          <strong>第五十条</strong>
                          &emsp;&ensp;
                          <strong>
                            本协议构成双方就本协议项下事宜达成的完整协议，并取代双方此前关于【在也场馆管理
                            SaaS
                            系统】服务事宜所达成的任何协议、框架协议、意向书、谅解备忘录、陈述或其他义务（无论以书面或口头形式，包括各类沟通形式、网站宣传等，如有）。
                          </strong>
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第五十一条</strong>
                          &emsp;&ensp;
                          如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余部分则仍具有法律效力。
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第五十二条</strong>
                          &emsp;&ensp;
                          寻路猫于乙方过失或违约时放弃本协议规定的权利的，不得视为
                          其对乙方的其他或以后同类之过失或违约行为弃权。
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第五十三条</strong>
                          &emsp;&ensp;
                          寻路猫有权随时根据中华人民共和国有关法律、法规的变化、互
                          联网的发展以及公司经营状况和经营策略的调整等修改本协议，本协议条款一旦发生变动，将会在【在也场馆管理
                          SaaS
                          系统】网站重要页面上提示修改内容。如果不同意所改动的内容，乙方可以主动取消获得的在也场馆管理系统信息服务。如果乙方继续享用在也场馆管理系统信息服务，则视为接受服务条款的变动。本协议条款变动后，【在也场馆管理
                          SaaS
                          系统】网站重要页面上公示的修改内容与本协议共同构成各方对使用【在也场馆管理
                          SaaS 系统】事宜的唯一和完整的协议。
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>第五十四条</strong>
                          &emsp;&ensp;
                          如本协议为线下签署的，则本协议经寻路猫与乙方适当签署后生效，且双方各自保留壹份纸质协议文本；如本协议为线上签署的，乙方注册或登录即视为乙方完全接受本协议，在点击注册或登录之前请乙方再次确认已知悉并完全理解本协议的全部内容。
                          <strong>
                            寻路猫保留随时修改或中断服务而不需通知乙方的权利。寻路猫行使修改或中断服务的权利，不需对乙方或第三方负责。
                          </strong>
                        </p>
                      </div>
                    </section>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
