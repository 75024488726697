import { get, post, patch, deleteRequest } from '../../../service/api';

export const getAssetsList = async (payload: {
  merchantName: string;
  configOptions?: any;
}) => {
  try {
    const { merchantName, configOptions = {} } = payload;

    const result = await get(
      `/merchants/${merchantName}/assets/setting`,
      {},
      {},
      configOptions
    );

    return result;
  } catch (e) {
    throw e;
  }
};

export const postAssets = async (payload: {
  merchantName: string;
  asset: any;
}) => {
  try {
    const { merchantName, asset } = payload;

    const result = await post(
      `/merchants/${merchantName}/assets/setting/types`,
      asset,
      { isShow: true }
    );

    return result;
  } catch (e) {
    throw e;
  }
};

export const deleteAsset = async (payload: {
  merchantName: string;
  id: string;
}) => {
  try {
    const { merchantName, id } = payload;

    const result = await deleteRequest(
      `/merchants/${merchantName}/assets/setting/types/${id}`,
      {
        isShow: false,
      }
    );

    return result;
  } catch (e) {
    throw e;
  }
};

export const patchAsset = async (payload: {
  merchantName: string;
  id: string;
  data: any;
}) => {
  try {
    const { merchantName, id, data } = payload;

    const result = await patch(
      `/merchants/${merchantName}/assets/setting/types/${id}`,
      data,
      {
        isShow: false,
      }
    );

    return result;
  } catch (e) {
    throw e;
  }
};
