import React from 'react';
import { Button, Space, Upload, UploadProps } from 'antd';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../../../../common/utils/types';
import { HISTORIES_TYPES } from '../../../../utils/constants';
import { getUserAuthorization } from '../../../../../../../../redux/app-v2/user/selectors';
import { getMerchantName } from '../../../../../../../../redux/app-v2/merchant/selectors';
import { loadHistoryPlayersAchievementImportTasks } from '../../redux/thunks';
import { getHistoryPlayersAchievementImportTournamentTemplateName } from '../../redux/selectors';
import { publicIp } from '../../../../../../../../service/apiIP';

const ImportDescription = ({
  tempTournamentId,
}: {
  tempTournamentId: string;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const userAuthorization = useSelector(getUserAuthorization);
  const merchantName = useSelector(getMerchantName);
  const tournamentTemplateName = useSelector(
    getHistoryPlayersAchievementImportTournamentTemplateName
  );
  const props: UploadProps = {
    name: 'file',
    accept: '.xlsx, .xls',
    headers: {
      Authorization: userAuthorization,
    },
    action: `${publicIp}/merchants/${merchantName}/baseball/stats/${HISTORIES_TYPES.BASEBALL_STAT_PLAYER}/import/tasks`,
    data: {
      tempTournamentId,
    },
    onChange({ file }) {
      if (file.status === 'done') {
        dispatch(loadHistoryPlayersAchievementImportTasks({}) as any);

        setTimeout(() => {
          dispatch(loadHistoryPlayersAchievementImportTasks({}) as any);
        }, 5000);
      }
    },
  };

  return (
    <div className="PlayersAchievementImportDescription">
      <p>
        提供运动员成绩的表格以便于快速导入。
        <br />
        阅读帮助文档可获取更多信息以便能成功导入所需信息。
      </p>
      <div className="flex flex-top">
        <h4>重要提示: </h4>
        <p>
          请不要更改任何默认表头，否则导入会发生错误
          <br />
          当文件被验证没有任何错误的时候才会开始导入，不会出现导入一半的情况
          <br />
          如果导入成功请勿重复导入，否则会员权益会重复导入
          <br />
          如果导入失败，请下载文件检查错误提示
        </p>
      </div>
      <div>
        <span>当前导入锦标赛：</span>
        <span>{tournamentTemplateName}</span>
      </div>

      <Space size={10} className="PlayersAchievementImportImportButtons">
        <Upload {...props}>
          <Button type="primary">导入</Button>
        </Upload>
        <Button
          type="default"
          className="cancelButton"
          onClick={() => {
            navigate(-1);
          }}
        >
          返回
        </Button>
      </Space>
    </div>
  );
};

export default ImportDescription;
