import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { useSearchParams } from 'react-router-dom';
import { Spin, Result } from 'antd';
import { AppDispatch } from '../../../../common/utils/types';
import { getURLSearchParam } from '../../../../common/utils';
import { userWecomLogin } from './redux/thunks';
import {
  getIsLoginStatusPending,
  getIsLoginStatusRejected,
} from './redux/selectors';
import './index.less';

export default function WecomLogin() {
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams();
  const authCode =
    searchParams.get('auth_code') || getURLSearchParam('auth_code');
  const code = searchParams.get('code') || getURLSearchParam('code');
  const authType =
    searchParams.get('state') || getURLSearchParam('state') || 'scan';
  const isLoginStatusPending = useSelector(getIsLoginStatusPending);
  const isLoginStatusRejected = useSelector(getIsLoginStatusRejected);
  // navigate to home page
  const navigateToHomePage = () => {
    const { origin } = window.location;

    window.location.href = origin;
  };

  useMount(() => {
    document.title = '在也｜登录';
    dispatch(
      userWecomLogin({
        authCode: authCode || code,
        authType,
        navigateToHomePage,
      })
    );
  });

  return (
    <div className="wecomLogin flex flex-middle flex-center">
      {isLoginStatusPending ? (
        <Spin tip="正在登录企业微信" size="large" />
      ) : isLoginStatusRejected ? (
        <Result
          status="500"
          title="通过企业微信登录失败"
          subTitle="糟糕，服务似乎出了一些问题！"
        />
      ) : null}
    </div>
  );
}
