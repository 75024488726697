import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../common/utils/types';
import { getEmployeeStoresFilterPagination } from './selectors';
import { StorePagination } from '../../../../redux/entities/stores/types';
import { loadStoresV2 as loadEntitiesStores } from '../../../../redux/entities/stores/thunks';

export const loadEmployeeStoresFilter = createAsyncThunk(
  'employee/stores',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const employeeStoresFilterPagination =
        getEmployeeStoresFilterPagination(state);

      const { payload } = (await dispatch(
        loadEntitiesStores(employeeStoresFilterPagination)
      )) as {
        payload: {
          pagination: StorePagination;
          ids: string[];
        };
      };

      return payload;
    } catch (e) {
      throw e;
    }
  }
);
