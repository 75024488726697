import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
// must add two lines for: date picker
// in future should move to date picker components
import 'moment/locale/zh-cn';
import zh_CN from 'antd/es/locale/zh_CN';
import reportWebVitals from './reportWebVitals';
import GlobalRouter from './GlobalRouter';
import store from './redux/store';

const root = createRoot(document!.getElementById('root')!);
root.render(
  <Provider store={store}>
    <ConfigProvider key="cn" locale={zh_CN}>
      <GlobalRouter />
    </ConfigProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
