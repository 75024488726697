import { post } from '../../../../service/api';

export const postEmployeeVerify = async (
  merchantName: string,
  payload: {
    accountName: string;
    password: string;
  }
) => {
  try {
    const { accountName, password } = payload;
    const res = await post(`/merchants/${merchantName}/employees.verify`, {
      accountName,
      password,
    });
    return res;
  } catch (error) {
    throw error;
  }
};
