import { createSlice } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../../service/constants';
import {
  loadStoresForNewEmployee,
  loadEmployeeList,
  updateEmployeeListPageNumber,
  updateEmployeeListPageSize,
  loadSearchEmployeeListForTransferOwner,
  transferOwnerToOtherEmployee,
} from './thunks';

const initialState: {
  pageSize: number;
  pageNumber: number;
  totalEmployees: number;
  end: boolean;
  ids: string[];
  loadEmployeeStatus: string;
  roleList: any[];
  newEmployee: {
    loadStoresStatus: string;
    showModal: boolean;
    storeIds: any[];
  };
  transferOwner: {
    searchEmployeeStatus: string;
    transferStatus: string;
    creatingOtpStatus: string;
    showModal: boolean;
    employeeSearchIds: string[];
    employeeId: string;
    otp: string;
  };
} = {
  pageSize: 10,
  pageNumber: 0,
  totalEmployees: 0,
  end: false,
  ids: [],
  loadEmployeeStatus: API_REQUEST_STATUS.IDLE,
  roleList: [],
  newEmployee: {
    loadStoresStatus: API_REQUEST_STATUS.IDLE,
    showModal: false,
    storeIds: [],
  },
  transferOwner: {
    searchEmployeeStatus: API_REQUEST_STATUS.IDLE,
    transferStatus: API_REQUEST_STATUS.IDLE,
    creatingOtpStatus: API_REQUEST_STATUS.IDLE,
    showModal: false,
    employeeSearchIds: [],
    employeeId: '',
    otp: '',
  },
};

export const { reducer, actions } = createSlice({
  name: 'employee/list',
  initialState,
  reducers: {
    showNewEmployeeModal: (state) => {
      state.newEmployee.showModal = true;
    },
    hideNewEmployeeModal: (state) => {
      state.newEmployee.showModal = false;
    },
    ownerTransferEmployeeIdUpdated: (state, { payload }) => {
      state.transferOwner.employeeId = payload;
    },
    showTransferOwnerModal: (state) => {
      state.transferOwner.showModal = true;
    },
    hideTransferOwnerModal: (state) => {
      state.transferOwner.showModal = false;
    },
    otpUpdated: (state, { payload }) => {
      state.transferOwner.otp = payload;
    },
  },
  extraReducers: {
    [loadEmployeeList.fulfilled.type]: (state, action) => {
      const { pagination, ids } = action.payload || {};
      const { end, total } = pagination || {};

      state.end = end;
      state.totalEmployees = total;
      state.ids = ids;
    },
    [loadEmployeeList.rejected.type]: (state: {
      loadEmployeeStatus: string;
    }) => {
      state.loadEmployeeStatus = API_REQUEST_STATUS.REJECTED;
    },
    [updateEmployeeListPageNumber.fulfilled.type]: (
      state: { pageNumber: number },
      action: { payload: number }
    ) => {
      state.pageNumber = action.payload;
    },
    [updateEmployeeListPageSize.fulfilled.type]: (
      state: { pageSize: number; pageNumber: number },
      action: { payload: { pageSize: number; pageNumber: number } }
    ) => {
      state.pageSize = action.payload.pageSize;
      state.pageNumber = action.payload.pageNumber;
    },
    [loadSearchEmployeeListForTransferOwner.fulfilled.type]: (
      state,
      { payload }
    ) => {
      const { ids } = payload || {};

      if (
        !state.transferOwner.employeeId ||
        state.transferOwner.employeeId === ''
      ) {
        state.transferOwner.employeeId = ids[0];
      }

      state.transferOwner.employeeSearchIds = ids;
      state.transferOwner.searchEmployeeStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [loadSearchEmployeeListForTransferOwner.rejected.type]: (state) => {
      state.transferOwner.searchEmployeeStatus = API_REQUEST_STATUS.REJECTED;
    },
    [loadSearchEmployeeListForTransferOwner.pending.type]: (state) => {
      state.transferOwner.searchEmployeeStatus = API_REQUEST_STATUS.PENDING;
    },
    [transferOwnerToOtherEmployee.fulfilled.type]: (state) => {
      state.transferOwner = {
        searchEmployeeStatus: API_REQUEST_STATUS.IDLE,
        transferStatus: API_REQUEST_STATUS.IDLE,
        creatingOtpStatus: API_REQUEST_STATUS.IDLE,
        showModal: false,
        employeeSearchIds: [],
        employeeId: '',
        otp: '',
      };
    },
    [transferOwnerToOtherEmployee.rejected.type]: (state) => {
      state.transferOwner.transferStatus = API_REQUEST_STATUS.REJECTED;
    },
    [transferOwnerToOtherEmployee.pending.type]: (state) => {
      state.transferOwner.transferStatus = API_REQUEST_STATUS.PENDING;
    },
    [loadStoresForNewEmployee.fulfilled.type]: (state, { payload }) => {
      const { ids } = payload || {};

      state.newEmployee.loadStoresStatus = API_REQUEST_STATUS.FULFILLED;
      state.newEmployee.storeIds = ids;
    },
    [loadStoresForNewEmployee.rejected.type]: (state) => {
      state.newEmployee.loadStoresStatus = API_REQUEST_STATUS.REJECTED;
    },
    [loadStoresForNewEmployee.pending.type]: (state) => {
      state.newEmployee.loadStoresStatus = API_REQUEST_STATUS.PENDING;
    },
  },
});

export default reducer;
