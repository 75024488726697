/* eslint-disable react-hooks/rules-of-hooks */
import { Button, Col, Input, Modal, Row, Space } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import ContentDetail from '../../../../../common/components/ContentDetail';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { cancelTheOrder, requestData } from './redux/thunks';
import { getOrderState } from './redux/selectors';
import TableResponsiveFrame from '../../../../../common/components/tables/TableResponsiveFrame';
import BreadcrumbRouter from '../../../../../common/components/BreadcrumbRouter';
import './OrderDetails.less';

const OrderDetails = () => {
  const columns: any = [
    {
      title: '兑换项',
      width: 180,
      dataIndex: 'exchangedType',
      key: 'exchangedType',
      align: 'center',
      mobileAlign: 'contentHeaderLeft',
      render: (name: any, record: any) => {
        const { merchandiseId } = record;
        return (
          <div
            onClick={() => {
              navigate(
                `/redemption-market/merchandise-management/${merchandiseId}`
              );
            }}
            dangerouslySetInnerHTML={{ __html: name }}
          />
        );
      },
    },
    {
      title: '数量',
      width: 180,
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
    },
    {
      title: '兑换价格',
      width: 180,
      dataIndex: 'exchangeByValue',
      key: 'exchangeByValue',
      align: 'center',
    },
  ];

  const param: any = useLocation().state;
  const { id, exchangeByType, playerDisplayName, total } = param;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const order = useSelector(getOrderState);

  useEffect(() => {
    dispatch(requestData({ id }) as any);
  }, []);

  return (
    <>
      <Modal
        title={<span style={{ fontSize: '18px', color: 'red' }}>警告</span>}
        visible={isModalVisible}
        closable={false}
        onOk={() => {
          dispatch(cancelTheOrder({ id }) as any);
          setIsModalVisible(false);
        }}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      >
        <p style={{ fontWeight: 600 }}>确定取消订单？</p>
      </Modal>
      <ContentDetail
        title="订单管理 / 订单详情"
        headerLeft={
          <BreadcrumbRouter
            routes={[
              {
                path: '/redemption-market',
                breadcrumbName: '兑换商城',
              },
              {
                path: '/order-management',
                breadcrumbName: '订单管理',
              },
              {
                path: `${id}`,
                breadcrumbName: '订单详情',
              },
            ]}
          />
        }
        headerRight={
          <Button
            className="cancelOrderButton"
            onClick={() => {
              setIsModalVisible(true);
            }}
            disabled={order.status === '已取消' ? true : false}
          >
            <div className="cancelOrderButtonText">
              <MoreOutlined style={{ fontSize: '19px' }} />
              取消订单
            </div>
          </Button>
        }
        children={
          <>
            <Row>
              <Col span={12}>
                <div className="settingsInputsContainer">
                  <div
                    className="inputDiv"
                    style={{ fontSize: '12px', padding: '0px' }}
                  >
                    <div>
                      <span>订单号</span>
                    </div>
                    <div>
                      <Input
                        value={order.orderNumber}
                        onChange={(e) => {}}
                        id="memberId"
                        className="searchInput"
                        allowClear
                        disabled
                        style={{ marginTop: '8px' }}
                      />
                    </div>
                  </div>

                  <div
                    className="inputDiv"
                    style={{ fontSize: '12px', padding: '0px' }}
                  >
                    <div>
                      <span>状态</span>
                    </div>
                    <div>
                      <Input
                        value={order.status}
                        onChange={(e) => {}}
                        id="memberName"
                        className="searchInput"
                        allowClear
                        disabled
                        style={{ marginTop: '8px' }}
                      />
                    </div>
                  </div>

                  <div
                    className="inputDiv"
                    style={{ fontSize: '12px', padding: '0px' }}
                  >
                    <div>
                      <span>兑换方式</span>
                    </div>
                    <div>
                      <Input
                        value={exchangeByType}
                        onChange={(e) => {}}
                        id="memberName"
                        className="searchInput"
                        allowClear
                        disabled
                        style={{ marginTop: '8px' }}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="settingsInputsContainer">
                  <div
                    className="inputDiv"
                    style={{ fontSize: '12px', padding: '0px' }}
                  >
                    <div>
                      <span>创建时间</span>
                    </div>
                    <div>
                      <Input
                        value={order.createTime}
                        onChange={(e) => {}}
                        id="memberId"
                        className="searchInput"
                        allowClear
                        disabled
                        style={{ marginTop: '8px' }}
                      />
                    </div>
                  </div>

                  <div
                    className="inputDiv"
                    style={{ fontSize: '12px', padding: '0px' }}
                  >
                    <div>
                      <span>会员</span>
                    </div>
                    <div>
                      <Input
                        value={playerDisplayName}
                        onChange={(e) => {}}
                        id="memberName"
                        className="searchInput"
                        allowClear
                        disabled
                        style={{ marginTop: '8px' }}
                      />
                    </div>
                  </div>

                  <div
                    className="inputDiv"
                    style={{ fontSize: '12px', padding: '0px' }}
                  >
                    <div>
                      <span>总计</span>
                    </div>
                    <div>
                      <Input
                        value={total}
                        onChange={(e) => {}}
                        id="memberName"
                        className="searchInput"
                        allowClear
                        disabled
                        style={{ marginTop: '8px' }}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <TableResponsiveFrame
              columns={columns}
              pageNumber={1}
              pageSize={100}
              data={order.items}
              dataItemsTotal={order.items.length}
              contentTablePaginationConfig={false}
              useDefaultPagination={true}
            />

            <div style={{ marginTop: '36px' }}>
              <Space size={10} style={{ marginLeft: '10px' }}>
                <Button
                  type="default"
                  className="operateButton cancelButton"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  返回
                </Button>
              </Space>
            </div>
          </>
        }
      ></ContentDetail>
    </>
  );
};

export default OrderDetails;
