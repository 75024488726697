import { combineReducers } from '@reduxjs/toolkit';
import { reducer as list } from '../containers/EnrollmentList/redux';
import { reducer as details } from '../containers/EnrollmentDetails/redux';
import { reducer as result } from '../containers/EnrollmentResult/redux';
import { reducer as clubs } from '../containers/EnrollmentClubs/redux';

const reducer = combineReducers({
  result,
  details,
  list,
  clubs,
});

export default reducer;
