import { createSlice } from '@reduxjs/toolkit';
import { getThePoster } from './thunks';

const initialState = {
  id: '',
  merchantName: '',
  storeId: '',
  posterName: '',
  content: '',
  isEnabled: true,
  imageUrl: '',
  type: '',
  creatorId: null,
};

export const { reducer, actions } = createSlice({
  name: 'poster',
  initialState,
  reducers: {
    setContent: (state, action) => {
      state.content = action.payload;
    },
    setImageUrl: (state, action) => {
      state.imageUrl = action.payload;
    },
    setIsEnabled: (state, action) => {
      state.isEnabled = action.payload;
    },
    setPosterName: (state, action) => {
      state.posterName = action.payload;
    },
    setStoreId: (state, action) => {
      state.storeId = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setPoster: (state, action) => {
      state.id = action.payload.id;
      state.merchantName = action.payload.merchantName;
      state.storeId = action.payload.storeId;
      state.posterName = action.payload.posterName;
      state.content = action.payload.content;
      state.isEnabled = action.payload.isEnabled;
      state.imageUrl = action.payload.imageUrl;
      state.type = action.payload.type;
    },
  },
  extraReducers: {
    [getThePoster.fulfilled.type]: (state, action) => {
      state.id = action.payload.id;
      state.merchantName = action.payload.merchantName;
      state.storeId = action.payload.storeId;
      state.posterName = action.payload.posterName;
      state.content = action.payload.content;
      state.isEnabled = action.payload.isEnabled;
      state.imageUrl = action.payload.imageUrl;
      state.type = action.payload.type;
      state.creatorId = action.payload.creatorId;
    },
  },
});

export const setContent = actions.setContent;
export const setImageUrl = actions.setImageUrl;
export const setIsEnabled = actions.setIsEnabled;
export const setPosterName = actions.setPosterName;
export const setStoreId = actions.setStoreId;
export const setType = actions.setType;
export const setPoster = actions.setPoster;

export default reducer;
