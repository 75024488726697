import { get, post, put } from '../../../service/api';

export const getPlayers = async (payload: {
  merchantName: string;
  memberCardPaid: boolean;
  storeId: string;
  offset: number;
  limit: number;
}) => {
  try {
    const { merchantName, ...params } = payload;

    const { data, pagination }: any = await get(
      `/merchants/${merchantName}/players`,
      params,
      {},
      { isShow: false }
    );

    return { data, pagination };
  } catch (error: any) {
    throw error;
  }
};

export const postPlayer = async (payload: {
  merchantName: string;
  storeId: string;
  name: string;
  remarkName: string;
  phone: string;
  gender: string;
}) => {
  try {
    const { merchantName, ...othersData } = payload;
    const { data }: any = await post(
      `/merchants/${merchantName}/players`,
      othersData,
      {}
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const getPlayerById = async (payload: {
  merchantName: string;
  id: string;
}) => {
  try {
    const { merchantName, id } = payload;
    const { data }: any = await get(
      `/merchants/${merchantName}/players/${id}`,
      {},
      {},
      { isShow: false }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const putPlayerById = async (payload: {
  merchantName: string;
  id: string;
  data: any;
}) => {
  try {
    const { merchantName, id, data } = payload;
    const result: any = await put(
      `/merchants/${merchantName}/players/${id}`,
      data,
      { isShow: false }
    );

    return result?.data || {};
  } catch (error) {
    throw error;
  }
};
