import { createSlice } from '@reduxjs/toolkit';
import { AddAssociationPlayerState } from './types';
import { API_REQUEST_STATUS } from '../../../../../../../../service/constants';
import {
  getYearLastISODate,
  getISODate,
} from '../../../../../../../../common/utils/time-lib';
import { loadSearchClubsFilter, createAssociationPlayer } from './thunks';

const initialState: AddAssociationPlayerState = {
  data: {
    name: '',
    gender: 'male',
    phone: '',
    idCardNum: '',
    idCardType: 'IDCard',
    registerDate: getISODate(),
    expiredDate: getYearLastISODate(),
    associationClubId: '',
  },
  clubs: {
    ids: [],
    pageNumber: 0,
    pageSize: 10,
    loadClubsStatus: API_REQUEST_STATUS.IDLE,
  },
  createAssociationPlayerStatus: API_REQUEST_STATUS.IDLE,
};

export const { reducer, actions } = createSlice({
  name: 'association/players/add',
  initialState,
  reducers: {
    associationAddPlayerNameUpdated: (state, { payload }) => {
      state.data.name = payload;
    },
    associationAddPlayerGenderUpdated: (state, { payload }) => {
      state.data.gender = payload;
    },
    associationAddPlayerPhoneUpdated: (state, { payload }) => {
      state.data.phone = payload;
    },
    associationAddPlayerIdCardNumUpdated: (state, { payload }) => {
      state.data.idCardNum = payload;
    },
    associationAddPlayerIdCardTypeUpdated: (state, { payload }) => {
      state.data.idCardType = payload;
    },
    associationAddPlayerRegisterDateUpdated: (state, { payload }) => {
      state.data.registerDate = payload;
    },
    associationAddPlayerExpiredDateUpdated: (state, { payload }) => {
      state.data.expiredDate = payload;
    },
    associationAddPlayerAssociationClubIdUpdated: (state, { payload }) => {
      state.data.associationClubId = payload;
    },
    newAssociationAddPlayerReset: (state) => {
      state.data = {
        name: '',
        gender: 'male',
        phone: '',
        idCardNum: '',
        idCardType: 'IDCard',
        registerDate: getISODate(),
        expiredDate: getYearLastISODate(),
        associationClubId: '',
      };
      state.clubs.loadClubsStatus = API_REQUEST_STATUS.IDLE;
      state.createAssociationPlayerStatus = API_REQUEST_STATUS.IDLE;
    },
  },
  extraReducers: {
    [loadSearchClubsFilter.pending.type]: (state) => {
      state.clubs.loadClubsStatus = API_REQUEST_STATUS.PENDING;
    },
    [loadSearchClubsFilter.fulfilled.type]: (state, { payload }) => {
      state.clubs.loadClubsStatus = API_REQUEST_STATUS.FULFILLED;
      const ids = payload || [];
      state.clubs.ids = ids;
    },
    [loadSearchClubsFilter.rejected.type]: (state) => {
      state.clubs.loadClubsStatus = API_REQUEST_STATUS.REJECTED;
    },
    [createAssociationPlayer.pending.type]: (state) => {
      state.createAssociationPlayerStatus = API_REQUEST_STATUS.PENDING;
    },
    [createAssociationPlayer.fulfilled.type]: (state) => {
      state.createAssociationPlayerStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [createAssociationPlayer.rejected.type]: (state) => {
      state.createAssociationPlayerStatus = API_REQUEST_STATUS.REJECTED;
    },
  },
});

export default reducer;
