import { get, put } from '../../../service/api';
import { StoresApiResult } from './types';

export const getStores = async (payload: {
  merchantName: string;
  offset: number;
  limit: number;
}) => {
  try {
    const { merchantName, offset, limit } = payload;
    const params = { offset, limit };

    const { data, pagination }: any = (await get(
      `/merchants/${merchantName}/stores`,
      params,
      {},
      { isShow: false }
    )) as StoresApiResult;

    return {
      pagination,
      data,
    };
  } catch (error) {
    throw error;
  }
};

export const getStoreById = async (payload: {
  merchantName: string;
  id: string;
}) => {
  try {
    const { merchantName, id } = payload;
    const { data } = (await get(
      `/merchants/${merchantName}/stores/${id}`,
      {},
      {},
      { isShow: false }
    )) as { data: { id: string } };

    return data;
  } catch (error) {
    throw error;
  }
};

export const putStoreById = async (payload: {
  merchantName: string;
  id: string;
  data: any;
}) => {
  try {
    const { merchantName, id, data } = payload;
    const result = (await put(`/merchants/${merchantName}/stores/${id}`, data, {
      isShow: false,
    })) as { data: any };

    return result?.data || {};
  } catch (error) {
    throw error;
  }
};
