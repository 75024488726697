/* eslint-disable react-hooks/rules-of-hooks */
import { useMount } from 'react-use';
import { useSelector, useDispatch } from 'react-redux';

import ActivitiesImage from '../../../../images/order-management.png';
import AssociationTournament from '../../../../images/archery-competition.png';
import AssociationClubs from '../../../../images/association-clubs.png';
import AssociationPlayers from '../../../../images/association-players.png';
import FunctionCard from '../../components/FunctionCard';
import ContentDetail from '../../../../common/components/ContentDetail';
import { loadTheFeaturesSetting } from '../../../../redux/app-v2/featuresSetting/thunks';
import {
  getFeatureSettingVisible,
  getIsClubAuthorizationVisible,
  getIsPlayersVisible,
} from './redux/selectors';
import './AssociationFunctions.less';
import { useNavigate } from 'react-router-dom';
import { getIsBaseball } from '../../../../redux/app-v2/merchant/selectors';

const AssociationFunctions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isClubAuthorizationVisible = useSelector(getIsClubAuthorizationVisible);
  const isPlayersVisible = useSelector(getIsPlayersVisible);
  const featureSettingVisible = useSelector(getFeatureSettingVisible);

  useMount(() => {
    dispatch(loadTheFeaturesSetting() as any);
  });

  const isBaseball = useSelector(getIsBaseball);
  const clubText = isBaseball ? '联盟球队' : '成员单位';
  const playerText = isBaseball ? '球员' : '运动员';

  if (!featureSettingVisible) {
    return null;
  }

  return (
    <ContentDetail title="赛事活动">
      <div className="AssociationFunctionsContainer flex flex__wrap">
        {isClubAuthorizationVisible && (
          <div
            className="AssociationFunctionsCardContainer"
            onClick={() => {
              navigate('/association-activities/clubs');
            }}
          >
            <FunctionCard
              img={AssociationClubs}
              title={`${clubText}认证`}
              isAvailable
              isSubscription={false}
              description={`对${clubText}资格进行认证管理`}
            />
          </div>
        )}

        {isPlayersVisible && (
          <div
            className="AssociationFunctionsCardContainer"
            onClick={() => {
              navigate('/association/players');
            }}
          >
            <FunctionCard
              img={AssociationPlayers}
              title={`注册${playerText}管理`}
              isAvailable
              isSubscription={false}
              description={`对联赛注册${playerText}资格进行认证管理`}
            />
          </div>
        )}

        <div
          className="AssociationFunctionsCardContainer"
          onClick={() => {
            navigate('/association-activities/enrollments');
          }}
        >
          <FunctionCard
            img={ActivitiesImage}
            title="报名"
            isAvailable
            isSubscription={false}
            description="专门针对赛事/活动的报名系统，动态统计，方便快捷"
          />
        </div>

        <div
          className="AssociationFunctionsCardContainer"
          onClick={() => {
            navigate('/association-activities/tournaments');
          }}
        >
          <FunctionCard
            img={AssociationTournament}
            title="联赛"
            isAvailable
            isSubscription={false}
            description="根据活动报名数据创建比赛，查看比赛数据，实时监控"
          />
        </div>
      </div>
    </ContentDetail>
  );
};

export default AssociationFunctions;
