import React from 'react';
import { useMount } from 'react-use';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Dropdown, Menu, Modal, Tag } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { AppDispatch } from '../../common/utils/types';
import { actions as assetsActions } from './redux';
import { getAssetsList } from './redux/selectors';
import { removeAsset, loadAssetList, archiveAsset } from './redux/thunks';
import ContentDetail from '../../common/components/ContentDetail';
import TableResponsiveFrame from '../../common/components/tables/TableResponsiveFrame';
import NewPlayerAssets from './components/NewPlayerAssets';
import './AssetList.less';
import PlayerAssetsDetails from './components/PlayerAssetsDetails';

const AssetList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const assetList = useSelector(getAssetsList);

  useMount(() => {
    dispatch(loadAssetList() as any);
  });

  const columns: any = [
    {
      title: '权益名称',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      mobileAlign: 'contentHeaderLeft',
    },
    {
      title: '单位',
      dataIndex: 'unit',
      key: 'unit',
      align: 'center',
    },
    {
      title: '是否为敏感权益',
      dataIndex: 'isSensitive',
      key: 'isSensitive',
      align: 'center',
      render: (isSensitive: boolean) => (
        <span>{isSensitive ? '敏感权益' : '非敏感权益'}</span>
      ),
    },
    {
      title: '描述',
      width: 400,
      dataIndex: 'description',
      key: 'description',
      align: 'center',
    },
    {
      title: '状态',
      dataIndex: 'archived',
      key: 'archived',
      align: 'center',
      mobileAlign: 'contentHeaderRight',
      render: (archived: any) => (
        <Tag color={archived ? 'error' : 'success'}>
          {archived ? '已停用' : '正常使用'}
        </Tag>
      ),
    },
    {
      title: '操作',
      dataIndex: 'id',
      key: 'operator',
      align: 'center',
      mobileAlign: 'headerRight',
      render: (id: string, record: any) => {
        const items = [
          {
            key: `AssetsDelete-${id}`,
            danger: true,
            label: (
              <Button
                size="small"
                type="text"
                onClick={(event) => {
                  event.stopPropagation();

                  Modal.confirm({
                    centered: true,
                    title: `是否确认删除${record.type}权益？`,
                    content:
                      '若此权益已在店员端进行过任何加减操作，则不可被删除',
                    okType: 'danger',
                    onOk: async () => {
                      await dispatch(removeAsset(id) as any);
                    },
                  });
                }}
              >
                删除
              </Button>
            ),
          },
        ];
        const assetsArchivedItem = {
          key: `AssetsArchived-${id}`,
          danger: true,
          label: (
            <Button
              size="small"
              type="text"
              onClick={(event) => {
                event.stopPropagation();

                Modal.confirm({
                  centered: true,
                  title: '是否确认停用？',
                  content: `${record.type}停用之后无法再恢复此权益标签`,
                  okType: 'danger',
                  onOk: async () => {
                    await dispatch(
                      archiveAsset({ ...record, archived: true }) as any
                    );
                  },
                });
              }}
            >
              停用
            </Button>
          ),
        };

        if (!record.archived) {
          items.push(assetsArchivedItem);
        }

        return (
          <Dropdown
            placement="bottomRight"
            overlay={<Menu items={items} />}
            trigger={['click']}
          >
            <Button size="small" onClick={(event) => event.stopPropagation()}>
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <ContentDetail title="会员权益管理" headerRight={<NewPlayerAssets />}>
      <TableResponsiveFrame
        useDefaultPagination
        columns={columns}
        data={assetList}
        onRowClick={async (record: any) => {
          await dispatch(assetsActions.setSelectedAssetsId(record.id));
          dispatch(assetsActions.showPlayerAssesDetailsDetailsVisibility());

          return true;
        }}
      />
      <PlayerAssetsDetails />
    </ContentDetail>
  );
};

AssetList.displayName = 'AssetList';

export default AssetList;
