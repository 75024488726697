import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useMount } from 'react-use';
import { Link } from 'react-router-dom';
import { AppDispatch } from '../../../../../../redux/store';
import {
  getMerchandiseListPageNumber,
  getMerchandiseListPageSize,
  getMerchandiseList,
  getDataCountTotalMerchandises,
} from '../../redux/selectors';
import {
  loadMerchandiseList,
  removeMerchandise,
  updateMerchandiseListPagination,
  loadAssetsSetting,
} from '../../redux/thunks';
import ContentDetail from '../../../../../../common/components/ContentDetail';
import BreadcrumbRouter from '../../../../../../common/components/BreadcrumbRouter';
import TableResponsiveFrame from '../../../../../../common/components/tables/TableResponsiveFrame';
import NewMerchandiseModal from './NewMerchandiseModal';
import './MerchandiseList.less';

const Products = () => {
  const dispatch = useDispatch<AppDispatch>();
  const pageNumber = useSelector(getMerchandiseListPageNumber);
  const pageSize = useSelector(getMerchandiseListPageSize);
  const data = useSelector(getMerchandiseList);
  const dataItemTotal = useSelector(getDataCountTotalMerchandises);
  const onChangePageSize = useCallback(
    async (_: number, pageSize: number) => {
      await dispatch(
        updateMerchandiseListPagination({
          pageSize,
        })
      );
    },
    [dispatch]
  );
  const onChangePageNumber = useCallback(
    async (pageNumber: number) => {
      dispatch(updateMerchandiseListPagination({ pageNumber: pageNumber - 1 }));
    },
    [dispatch]
  );
  useMount(async () => {
    await dispatch(loadAssetsSetting());
    dispatch(loadMerchandiseList());
  });

  const columns: any = [
    {
      title: '礼品名称',
      className: 'merchandiseNameColumn',
      dataIndex: 'displayName',
      key: 'displayName',
      mobileAlign: 'contentHeaderLeft',
      render: (_: any, { id, displayName }: any) => (
        <Link to={`/redemption-market/merchandise-management/${id}`}>
          {displayName}
        </Link>
      ),
    },
    {
      title: '兑换物品',
      dataIndex: 'exchangedType',
      key: 'exchangedType',
      render: (_: any, { exchangedTypeLabel }: any) => {
        return <span>{exchangedTypeLabel}</span>;
      },
    },
    {
      title: '兑换方式',
      dataIndex: 'exchangeByAssetsType',
      key: 'exchangeByAssetsType',
      render: (_: any, { exchangeByTypeLabel }: any) => {
        return <span>权益 : {exchangeByTypeLabel}</span>;
      },
    },
    {
      title: '兑换价格',
      dataIndex: 'exchangeByValue',
      key: 'exchangeByValue',
      render: (_: any, { exchangeByValue }: any) => {
        return exchangeByValue;
      },
    },
    {
      title: '操作',
      dataIndex: 'id',
      key: 'operator',
      align: 'center',
      render: (_: any, { id }: any) => {
        return (
          <Button
            danger
            size="small"
            onClick={() => {
              Modal.confirm({
                centered: true,
                title: '确定删除该礼品？',
                icon: <ExclamationCircleOutlined />,
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                  dispatch(removeMerchandise(id));
                },
              });
            }}
          >
            删除
          </Button>
        );
      },
    },
  ];

  return (
    <ContentDetail
      title="礼品管理"
      headerLeft={
        <BreadcrumbRouter
          routes={[
            {
              path: '/redemption-market',
              breadcrumbName: '兑换商城',
            },
            {
              path: '/merchandise-management',
              breadcrumbName: '礼品管理',
            },
          ]}
        />
      }
      headerRight={<NewMerchandiseModal />}
    >
      <TableResponsiveFrame
        columns={columns}
        pageNumber={pageNumber + 1}
        pageSize={pageSize}
        data={data}
        dataItemsTotal={dataItemTotal}
        onChangePageSize={onChangePageSize}
        onChangePageNumber={onChangePageNumber}
      />
    </ContentDetail>
  );
};

export default Products;
