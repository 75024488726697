export const ENROLLMENT_RESULT_CATEGORIES = [
  {
    value: 'players',
    label: '单人',
  },
  {
    value: 'team',
    label: '团体',
  },
];

export const ENROLLMENT_RESULT_CATEGORIES_MAP = {
  PLAYER_VALUE: ENROLLMENT_RESULT_CATEGORIES[0].value,
  TEAM_VALUE: ENROLLMENT_RESULT_CATEGORIES[1].value,
};

export const ENROLLMENT_RESULT_IS_PAID_MAP = [
  {
    value: null,
    label: '全部',
  },
  {
    value: true,
    label: '已支付',
  },
  {
    value: false,
    label: '未支付',
  },
];

export const ENROLLMENT_RESULT_IS_PAID_ALL_VALUE =
  ENROLLMENT_RESULT_IS_PAID_MAP[0].value;
