import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../../common/utils/types';
import {
  errorMessage,
  successMessage,
} from '../../../../../common/utils/feedback';
import {
  getUserBelongToStoreId,
  getUserPhone,
  getIsStoreManager,
} from '../../../../../redux/app-v2/user/selectors';
import { actions as userActions } from '../../../../../redux/app-v2/user';
import { saveUserInfoToLocale } from '../../../../../redux/app-v2/user/thunks';
import { getMerchantName } from '../../../../../redux/app-v2/merchant/selectors';
import { actions as merchantActions } from '../../../../../redux/app-v2/merchant';
import { actions as featuresSettingActions } from '../../../../../redux/app-v2/featuresSetting';
import {
  loadEmployeesV2 as loadEntitiesEmployees,
  updateEmployeeByIdV2 as updateEntitiesEmployeeById,
  createAnEmployee as createEntitiesAnEmployee,
} from '../../../../../redux/entities/employees/thunks';
import { loadStoresV2 as loadEntitiesStores } from '../../../../../redux/entities/stores/thunks';
import { patchTransferOwner, postLogout, postOtp } from './api-request';
import {
  getEmployeeIdForTransferAsOwner,
  getEmployeeListPageSize,
  getEmployeeListParameters,
  getOtp,
} from './selectors';

export const changeEmployeeListPagination = createAsyncThunk(
  'employee/changeEmployeeListPagination',
  async (pageNumber, pageSize) => ({ pageNumber, pageSize })
);

export const loadEmployeeList = createAsyncThunk(
  'employee/loadEmployeeList',
  async (
    changedParameters: {
      limit?: number;
      offset?: number;
      search?: string;
      storeId?: string;
    },
    { dispatch, getState }
  ) => {
    try {
      const state: any = getState();
      const employeeListParameters = getEmployeeListParameters(state);

      const { payload } = await dispatch(
        loadEntitiesEmployees({
          ...employeeListParameters,
          ...changedParameters,
        })
      );

      return payload;
    } catch (e) {
      throw e;
    }
  }
);

export const updateEmployeeListPageNumber = createAsyncThunk(
  'employee/updateEmployeeListPageNumber',
  async (pageNumber: number, { dispatch, getState }) => {
    try {
      const state: any = getState();
      const pageSize = getEmployeeListPageSize(state);

      await dispatch(
        loadEmployeeList({
          offset: pageNumber * pageSize,
        })
      );

      return pageNumber;
    } catch (error) {
      throw error;
    }
  }
);

export const updateEmployeeListPageSize = createAsyncThunk(
  'employee/updateEmployeeListPageSize',
  async (pageSize: number, { dispatch }) => {
    try {
      await dispatch(
        loadEmployeeList({
          offset: 0,
          limit: pageSize,
        })
      );

      return {
        pageNumber: 0,
        pageSize,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const createEmployeeAdmin = createAsyncThunk(
  'employee/createEmployeeAdmin',
  async (
    {
      phone,
      name,
      roleName,
      storeId,
    }: { phone: string; name: string; roleName: string; storeId: string },
    { dispatch }
  ) => {
    try {
      const { payload, error } = await dispatch(
        createEntitiesAnEmployee({
          accountName: phone,
          name,
          roleName,
          storeId,
        })
      );

      if (error?.message) {
        throw new Error(error.message);
      }
      dispatch(loadEmployeeList({}));

      return payload;
    } catch (error) {
      throw error;
    }
  }
);

export const updateEmployeeDimission = createAsyncThunk(
  'employee/saveEmployeeById',
  async (
    { id, dimission }: { id: string; dimission: boolean },
    { dispatch }
  ) => {
    try {
      await dispatch(
        updateEntitiesEmployeeById({ id, payload: { dimission } })
      );
      dispatch(loadEmployeeList({}));

      if (dimission) {
        successMessage('停职成功');
      } else {
        successMessage('复职成功');
      }
    } catch (error: any) {
      errorMessage('操作失败');
    }
  }
);

export const loadSearchEmployeeListForTransferOwner = createAsyncThunk(
  'employee/loadSearchEmployeeListForTransferOwner',
  async (search: string = '', { dispatch, getState }) => {
    try {
      const state: any = getState();
      const userBelongToStoreId = getUserBelongToStoreId(state);
      const isStoreManager = getIsStoreManager(state);
      const parameters = {
        offset: 0,
        limit: 10,
        search,
        storeId: '',
      };

      if (userBelongToStoreId && isStoreManager) {
        parameters.storeId = userBelongToStoreId;
      }

      const { payload } = await dispatch(loadEntitiesEmployees(parameters));

      return payload;
    } catch (e) {
      throw e;
    }
  }
);

/**
 * Otp created
 */
export const createOtp = createAsyncThunk(
  'register/createOtp',
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);
      const phoneNumber = getUserPhone(state);

      await postOtp({
        merchantName,
        phoneNumber,
      });
    } catch (e) {
      errorMessage('获取验证码失败');
    }
  }
);

export const transferOwnerToOtherEmployee = createAsyncThunk(
  'employee/saveEmployeeById',
  async (
    {
      navigateToLoginPage,
    }: {
      navigateToLoginPage: Function;
    },
    { dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);
      const employeeId = getEmployeeIdForTransferAsOwner(state);
      const otp = getOtp(state);

      await patchTransferOwner(merchantName, {
        toEmployeeId: employeeId,
        otp,
      });

      successMessage('顶级管理员权限移交成功，您需要重新登录', {
        handleConfirm: async () => {
          await postLogout({ merchantName });
          dispatch(featuresSettingActions.featureSettingReset());
          dispatch(userActions.userReset());
          dispatch(merchantActions.merchantRest());
          dispatch(
            saveUserInfoToLocale({
              id: '',
              authorization: '',
              isLogin: false,
            })
          );

          navigateToLoginPage();
        },
      });

      return true;
    } catch (e) {
      errorMessage('顶级管理员权限移交失败，请重试');
    }
  }
);

export const loadStoresForNewEmployee = createAsyncThunk(
  'employee/loadStoresForNewEmployee',
  async (_, { dispatch }) => {
    try {
      const { payload } = await dispatch(
        loadEntitiesStores({ offset: 0, limit: 500 })
      );

      return payload;
    } catch (error) {
      throw error;
    }
  }
);
