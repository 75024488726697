import React, { useEffect, useState, useCallback } from 'react';
import { useWindowSize } from 'react-use';
import { List, Card } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { ListTablePropsType } from './types';
import './ListTable.less';

const getMobileHeaderKeysMapping = (columns: any) => {
  const mobileHeaderItemColumns = columns.filter(
    (column: any) => column.mobileAlign
  );
  const keysMapping: any = {};

  mobileHeaderItemColumns.forEach(
    (item: { mobileAlign: string; dataIndex: string }) => {
      keysMapping[item.mobileAlign] = item.dataIndex;
    }
  );

  return keysMapping;
};

const getListRestKeys = (
  item: {},
  columns: any,
  mobileHeaderKeysMapping: {}
) => {
  const displayDataIndexList = columns.map(
    (column: { dataIndex: string }) => column.dataIndex
  );

  return Object.keys(item).filter(
    (itemKey) =>
      displayDataIndexList.includes(itemKey) &&
      !Object.values(mobileHeaderKeysMapping).includes(itemKey) &&
      itemKey !== 'id' &&
      itemKey !== 'key'
  );
};

const getCardItemLabel = (key: string, columns: any) => {
  const currentColumn = columns.find(
    (column: { dataIndex: string }) => key === column.dataIndex
  );

  return currentColumn.title;
};

const getCardItemContent = (key: string, item: any, columns: any) => {
  const currentColumn = columns.find(
    (column: { dataIndex: string }) => key === column.dataIndex
  );

  if (!currentColumn) {
    return null;
  }

  if (currentColumn.render && typeof currentColumn.render === 'function') {
    return currentColumn.render(item[key], item);
  }

  return item[key] || '-';
};

const HeaderContent = ({
  item,
  columns = [],
  header,
  headerLeft,
  headerRight,
}: any) => {
  const headerLeftContent = getCardItemContent(headerLeft, item, columns);
  const headerRightContent = getCardItemContent(headerRight, item, columns);

  if (
    !header &&
    (!headerLeft || !headerLeftContent) &&
    (!headerRight || !headerRightContent)
  ) {
    return null;
  }

  return (
    <div className="ListTableCardHeader flex flex-middle flex-space-between">
      {header || (
        <>
          <span className="ListTableCardHeaderLeft">
            {headerLeft ? getCardItemContent(headerLeft, item, columns) : null}
          </span>
          <div>
            {headerRight
              ? getCardItemContent(headerRight, item, columns)
              : null}
          </div>
        </>
      )}
    </div>
  );
};

const ContentHeaderContent = ({
  item,
  columns,
  contentHeader,
  contentHeaderLeft,
  contentHeaderRight,
  onRowClick,
}: any) => {
  const contentHeaderLeftContent = getCardItemContent(
    contentHeaderLeft,
    item,
    columns
  );
  const contentHeaderRightContent = getCardItemContent(
    contentHeaderRight,
    item,
    columns
  );

  if (
    !contentHeader &&
    (!contentHeaderLeft || !contentHeaderLeftContent) &&
    (!contentHeaderRight || !contentHeaderRightContent)
  ) {
    return null;
  }

  return (
    <div className="ListTableCardContentHeader flex flex-middle flex-space-between">
      {contentHeader || (
        <>
          <h3 className="ListTableCardContentHeaderLeft">
            {contentHeaderLeft
              ? getCardItemContent(contentHeaderLeft, item, columns)
              : null}
          </h3>
          <div className="ListTableCardContentHeaderRight flex flex-middle">
            {contentHeaderRight
              ? getCardItemContent(contentHeaderRight, item, columns)
              : null}
            {onRowClick ? (
              <RightOutlined className="ListTableCardContentHeaderRightIcon" />
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

const ListTable = ({
  useDefaultPagination = false,
  pageNumber,
  pageSize,
  columns,
  data,
  dataItemsTotal,
  onChangePageSize,
  onChangePageNumber,
  onRowClick = () => {},
}: ListTablePropsType) => {
  const { height: windowHeight } = useWindowSize();
  const [maxTableHeight, setMaxTableHeight] = useState(0);
  const mobileHeaderKeysMapping = getMobileHeaderKeysMapping(columns);
  const setMaxAndMinTableHeight = useCallback(
    (windowHeight: number) => {
      const headerHeight =
        document.querySelector('.ant-layout-header')?.clientHeight || 0;
      const titleHeight =
        document.querySelector('.content__title')?.clientHeight || 0;

      setMaxTableHeight(windowHeight - headerHeight - titleHeight);
    },
    [windowHeight]
  );

  useEffect(() => {
    setMaxAndMinTableHeight(windowHeight);
  }, [windowHeight]);

  return (
    <>
      <List
        rowKey={(record: any) => {
          return record.id ? record.id + Date.now() : 1;
        }}
        className="ListTable"
        style={{
          minHeight: maxTableHeight,
        }}
        itemLayout="vertical"
        size="small"
        bordered={false}
        pagination={
          useDefaultPagination
            ? false
            : {
                simple: true,
                showQuickJumper: true,
                className: 'ListTablePagination',
                current: pageNumber,
                pageSize: pageSize,
                total: dataItemsTotal,
                onShowSizeChange: onChangePageSize,
                onChange: onChangePageNumber,
              }
        }
        dataSource={data}
        renderItem={(item) => {
          const { id, header, contentHeader } = item;
          const listKeys = getListRestKeys(
            item,
            columns,
            mobileHeaderKeysMapping
          );

          return (
            <List.Item
              key={id}
              className="ListTableItem"
              onClick={() => {
                onRowClick(item);
              }}
            >
              <Card className="ListTableItemCard" bordered={false}>
                <HeaderContent
                  item={item}
                  columns={columns}
                  header={header}
                  headerLeft={mobileHeaderKeysMapping.headerLeft}
                  headerRight={mobileHeaderKeysMapping.headerRight}
                />
                <ContentHeaderContent
                  item={item}
                  columns={columns}
                  contentHeader={contentHeader}
                  contentHeaderLeft={mobileHeaderKeysMapping.contentHeaderLeft}
                  contentHeaderRight={
                    mobileHeaderKeysMapping.contentHeaderRight
                  }
                  onRowClick={onRowClick}
                />
                {
                  <ul>
                    {listKeys.map((key, index) => (
                      <li
                        key={`${id}${index}`}
                        className="ListTableItemCardItem flex flex-middle flex-space-between"
                      >
                        <h5 className="ListTableItemCardItemTitle">
                          {getCardItemLabel(key, columns)}
                        </h5>
                        <div className="ListTableItemCardItemDescription">
                          {getCardItemContent(key, item, columns)}
                        </div>
                      </li>
                    ))}
                  </ul>
                }
              </Card>
            </List.Item>
          );
        }}
      />
    </>
  );
};

ListTable.displayName = 'ListTable';

export default ListTable;
