/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tag, Button, Modal } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useMount } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../../../../../common/utils/types';
import {
  getEnrollmentListPageNumber,
  getEnrollmentListPageSize,
  getEnrollmentListTableData,
  getEnrollmentListTotal,
  getIsEnrollmentDetailDuplicating,
} from './redux/selectors';
import { duplicateEnrollmentDetail, loadEnrollmentList } from './redux/thunks';
import { actions as enrollmentListActions } from './redux';
import TableResponsiveFrame from '../../../../../../common/components/tables/TableResponsiveFrame';
import { ContentTableColumnPropsType } from '../../../../../../common/components/tables/ContentTable/types';
import ContentDetail from '../../../../../../common/components/ContentDetail';
import './EnrollmentList.less';

const EnrollmentList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const enrollmentList = useSelector(getEnrollmentListTableData);
  const pageNumber = useSelector(getEnrollmentListPageNumber);
  const pageSize = useSelector(getEnrollmentListPageSize);
  const dataTotal = useSelector(getEnrollmentListTotal);
  const isEnrollmentDetailDuplicating = useSelector(
    getIsEnrollmentDetailDuplicating
  );

  const columns: ContentTableColumnPropsType[] = [
    {
      title: '活动标题',
      width: 400,
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      mobileAlign: 'headerLeft',
    },
    {
      title: '人次限制',
      dataIndex: 'maxIndividualEnrolledCount',
      key: 'maxIndividualEnrolledCount',
      align: 'center',
      render: (_: any, record) => {
        const {
          maxIndividualEnrolledCount,
          maxClubEnrolledCount,
          maxPlayerPerClubEnrolledCount,
        } = record;

        if (!!maxIndividualEnrolledCount) {
          return maxIndividualEnrolledCount;
        }

        if (!!maxClubEnrolledCount && !!maxPlayerPerClubEnrolledCount) {
          return `限${maxClubEnrolledCount}个俱乐部/每俱乐部${maxPlayerPerClubEnrolledCount}人次`;
        }

        return '无限制人数';
      },
    },
    {
      title: '单人（人次）',
      dataIndex: 'individualEnrolledCount',
      key: 'individualEnrolledCount',
      align: 'center',
    },
    {
      title: '团体（数量）',
      dataIndex: 'teamEnrolledCount',
      key: 'teamEnrolledCount',
      align: 'center',
    },
    {
      title: '报名截止日期',
      dataIndex: 'deadline',
      key: 'deadline',
      align: 'center',
    },
    {
      title: '报名状态',
      dataIndex: 'turnedOn',
      key: 'turnedOn',
      align: 'center',
      mobileAlign: 'contentHeaderLeft',
      render: (turnedOn: boolean) => (
        <Tag color={turnedOn ? 'success' : 'error'}>
          {turnedOn ? '开启' : '未开启'}
        </Tag>
      ),
    },
    {
      title: '操作',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (id: string) => (
        <Button
          ghost
          type="primary"
          size="small"
          className="EnrollmentListOperationButton"
          loading={isEnrollmentDetailDuplicating}
          onClick={async (event) => {
            event.stopPropagation();
            Modal.confirm({
              centered: true,
              title: '确认创建报名副本？',
              content: '创建副本后，将会跳转到副本详情页',
              okText: '确认',
              cancelText: '取消',
              onOk: async () => {
                await dispatch(
                  duplicateEnrollmentDetail({
                    id,
                    redirectToEnrollmentDetailPage: (redirectId: string) => {
                      navigate(
                        `/association-activities/enrollments/${redirectId}`
                      );
                    },
                  })
                );
              },
            });
          }}
        >
          创建副本
        </Button>
      ),
    },
  ];

  useMount(() => {});

  useEffect(() => {
    dispatch(loadEnrollmentList() as any);
  }, [pageNumber, pageSize]);

  return (
    <ContentDetail
      title="赛事活动报名"
      headerRight={
        <div className="NewEnrollmentButtonContainer">
          <Button
            className="NewEnrollmentButton"
            onClick={() => {
              navigate('/association-activities/enrollments/new');
            }}
          >
            <PlusCircleOutlined className="NewEnrollmentButtonOutlinedIcon" />
            <span className="NewEnrollmentButtonText">新增报名</span>
          </Button>
        </div>
      }
    >
      <TableResponsiveFrame
        columns={columns}
        pageNumber={pageNumber + 1}
        pageSize={pageSize}
        data={enrollmentList}
        dataItemsTotal={dataTotal}
        onRowClick={(record: any) => {
          navigate(`/association-activities/enrollments/${record.id}`);
          return true;
        }}
        onChangePageSize={async (_: number, pageSize: number) => {
          await dispatch(enrollmentListActions.updatePageSize(pageSize));
        }}
        onChangePageNumber={async (pageNumber: number) => {
          await dispatch(
            enrollmentListActions.updatePageNumber(pageNumber - 1)
          );
        }}
      />
    </ContentDetail>
  );
};

export default EnrollmentList;
