import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getMerchantBusiness,
  getMerchantName,
} from '../../../../../../../../redux/app-v2/merchant/selectors';
import { postAssociationPlayer } from './api-request';
import {
  errorMessage,
  successMessage,
} from '../../../../../../../../common/utils/feedback';
import { actions as associationPlayersCommonActions } from '../common';
import { loadAssociationClubs } from '../common/thunks';
import {
  getSearchClubsParameters,
  getAddAssociationPlayerData,
} from './selectors';
import { AddAssociationPlayerData } from './types';
import { loadAssociationPlayerList } from '../list/thunks';
import { BUSINESS_TYPES } from '../../../../../../../../common/constants';

export const loadSearchClubsFilter = createAsyncThunk(
  'association/players/add/loadSearchClubsFilter',
  async (keyword: string | undefined | null, { dispatch, getState }) => {
    try {
      const state: any = getState();
      const searchClubsParameters = getSearchClubsParameters(state);
      const payload = {
        ...searchClubsParameters,
        text: keyword || '',
        isMember: true,
      };
      const data: any = await dispatch(loadAssociationClubs(payload)).unwrap();

      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const createAssociationPlayer = createAsyncThunk(
  'association/players/add/createAssociationPlayer',
  async (closeCreateModal: Function, { dispatch, getState }) => {
    const state: any = getState();
    const business = getMerchantBusiness(state);
    const playerText = business === BUSINESS_TYPES.Baseball ? '球员' : '运动员';
    try {
      const merchantName = getMerchantName(state);

      const payload: AddAssociationPlayerData =
        getAddAssociationPlayerData(state);
      const result: any = await postAssociationPlayer(merchantName, payload);
      const { data } = result;

      dispatch(
        associationPlayersCommonActions.associationPlayerUpdatedById({
          ...data,
          ...payload,
        })
      );
      dispatch(loadAssociationPlayerList({}));

      successMessage(`新增注册${playerText}成功`, {
        handleConfirm: () => {
          closeCreateModal();
        },
      });

      return data;
    } catch (error) {
      errorMessage(`新增注册${playerText}失败`);

      throw error;
    }
  }
);

export const mounted = createAsyncThunk(
  'association/players/add/mounted',
  async (_, { dispatch }) => {
    try {
      await dispatch(loadSearchClubsFilter(''));
    } catch (error) {
      throw error;
    }
  }
);
