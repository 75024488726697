import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tag } from 'antd';
import { AppDispatch } from '../../../../common/utils/types';
import TableResponsiveFrame from '../../../../common/components/tables/TableResponsiveFrame';
import { ContentTableColumnPropsType } from '../../../../common/components/tables/ContentTable/types';
import ContentDetail from '../../../../common/components/ContentDetail';
import { getIsUserInitialized } from '../../../../redux/app-v2/user/selectors';
import { actions as storeActions } from './redux';
import { loadStoreList } from './redux/thunks';
import {
  getStoreList,
  getStoreListPageNumber,
  getStoreListPageSize,
  getStoreListTotalStores,
} from './redux/selectors';

const StoreList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isUserInitialized = useSelector(getIsUserInitialized);
  const storeList = useSelector(getStoreList);
  const pageNumber = useSelector(getStoreListPageNumber);
  const pageSize = useSelector(getStoreListPageSize);
  const dataTotal = useSelector(getStoreListTotalStores);
  const columns: ContentTableColumnPropsType[] = [
    {
      title: '门店总称',
      dataIndex: 'displayName',
      key: 'displayName',
      align: 'center',
      mobileAlign: 'contentHeaderLeft',
      render: (_: any, { id, isDefault, displayName }: any) => (
        <Link to={`/stores/${id}`}>
          {isDefault ? (
            <>
              {displayName} <Tag color="processing">默认</Tag>
            </>
          ) : (
            displayName
          )}
        </Link>
      ),
    },
    {
      title: '城市',
      width: 150,
      dataIndex: 'city',
      key: 'city',
      align: 'center',
    },
    {
      title: '地址',
      dataIndex: 'displayAddress',
      key: 'displayAddress',
      align: 'center',
    },
  ];

  useEffect(() => {
    if (isUserInitialized) {
      dispatch(loadStoreList());
    }
  }, [dispatch, isUserInitialized, pageNumber, pageSize]);

  return (
    <ContentDetail title="门店">
      <TableResponsiveFrame
        columns={columns}
        pageNumber={pageNumber + 1}
        pageSize={pageSize}
        data={storeList}
        dataItemsTotal={dataTotal}
        onChangePageSize={async (_: number, pageSize: number) => {
          await dispatch(storeActions.updatePageSize(pageSize));
        }}
        onChangePageNumber={async (pageNumber: number) => {
          await dispatch(storeActions.updatePageNumber(pageNumber - 1));
        }}
      />
    </ContentDetail>
  );
};

export default StoreList;
