export const formatAuth = (bearerToken: string) => `Bearer ${bearerToken}`;

export const getUrlHash = (
  targetRegex: RegExp,
  replaceValue = '',
  url = window.location.hash
) => url.replace(targetRegex, replaceValue);

/**
 *
 * @param searchKey: want get url search's key in query
 * @param url: url resource
 * @returns search value of key in argument
 */
export const getURLSearchParam = (
  searchKey: string,
  url: string = window.location.href
) => {
  if (!searchKey) {
    return null;
  }

  const urlObject = new URL(url);
  const searchParams: any = new URLSearchParams(urlObject.search);

  if (searchParams.size !== 0) {
    return searchParams.get(searchKey);
  } else if (urlObject.hash) {
    const hashSearchString = urlObject.hash?.split('?')?.[1];
    const hashSearchList = hashSearchString?.split('&');

    if (hashSearchList && hashSearchList.length > 0) {
      const targetSearch = hashSearchList.find((hashSearch: string) => {
        if (hashSearch.includes(`${searchKey}=`)) {
          return true;
        }
      });

      return targetSearch ? targetSearch.replace(`${searchKey}=`, '') : null;
    } else {
      return null;
    }
  }
};

/**
 *
 * @param idNumber: China idCard number
 * @returns validate result
 */
export const getIsIDNumberValidated = (idNumber: string) => {
  const idReg18 =
    /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  const idReg15 =
    /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/;

  return idReg18.test(idNumber) || idReg15.test(idNumber);
};

/**
 *
 * @param passportNumber: any passport number
 * @returns validate result
 */
export const getIsPassportNumberValidated = (passportNumber: string) => {
  //护照（包括香港和澳门）
  const passportReg = /^[a-zA-Z0-9\s]{7,10}$/;

  return passportReg.test(passportNumber);
};

/**
 *
 * @param phoneNumber: chinese phone number
 * @returns validate result
 */
export const getIsPhoneNumberValidated = (phoneNumber: string) => {
  // 中国手机号(严谨), 根据工信部2019年最新公布的手机号段
  const strickPhoneReg =
    /^((\+|00)86)?1((3[\d])|(4[5,6,7,9])|(5[0-3,5-9])|(6[5-7])|(7[0-8])|(8[\d])|(9[1,8,9]))\d{8}$/;

  // 中国手机号(宽松), 只要是13,14,15,16,17,18,19开头即可
  const phoneReg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;

  // 中国手机号(最宽松), 只要是1开头即可
  // const loosePhoneReg = /^((\+|00)86)?1\d{10}$/;

  return strickPhoneReg.test(phoneNumber) || phoneReg.test(phoneNumber);
};

export const hasChinese = (text: string) => {
  var chinesePattern = /[\u4e00-\u9fa5]/; // 中文字符的正则表达式
  return chinesePattern.test(text); // 返回true或false，表示是否包含中文字符
};
