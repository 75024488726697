import { createSlice } from '@reduxjs/toolkit';
import {
  loadRankingList,
  loadRankingSettingsById,
  loadRankSettingsList,
} from './thunks';

const initialState = {
  rankingSettingsList: [],
  rankListData: [],
  dataSource: {},
  pageSize: 10,
  pageNumber: 0,
  total: 0,
  end: true,
  rankingSettings: {
    id: '',
    name: '',
    description: '',
    storeIds: [],
    enabled: true,
    timeInterval: [],
    scoreUnit: '',
    ascending: true,
  },
  selectedRankingSettingId: '',
  selectedTimeInterval: '',
  categories: [],
  selectedCategoryTypeName: '',
  selectedStoreId: '',
  timeStamp: '',
};

export const { reducer, actions } = createSlice({
  name: 'ranking',
  initialState,
  reducers: {
    updatePageNumber: (state: any, action: any) => {
      state.pageNumber = action.payload;
    },
    updatePageSize: (state: any, action: any) => {
      state.pageSize = action.payload;
    },
    updateSelectedTimeInterval: (state: any, action: any) => {
      state.selectedTimeInterval = action.payload.value;
    },
    updateSelectedRankingSettingId: (state: any, action: any) => {
      state.selectedRankingSettingId = action.payload.value;
    },
    updateRankingSettings: (state: any, action: any) => {
      state.rankingSettings = action.payload;
    },
    updateSelectedCategoryTypeName: (state: any, action: any) => {
      state.selectedCategoryTypeName = action.payload.value;
    },
    updateSelectedStoreId: (state: any, action: any) => {
      state.selectedStoreId = action.payload.value;
    },
    updateTimeStamp: (state: any, action: any) => {
      state.timeStamp = action.payload;
    },
  },
  extraReducers: {
    [loadRankingList.fulfilled.type]: (state, { payload }) => {
      const { pagination, data } = payload;
      const { players, ...resData } = data || {};

      state.rankListData = players || [];
      state.dataSource = resData;
      state.total = pagination?.total || 0;
      state.end = pagination?.end || true;
    },
    [loadRankSettingsList.fulfilled.type]: (state, { payload }) => {
      const { rankingSettings, total } = payload || {};

      state.rankingSettingsList = rankingSettings;
      state.total = total || 0;
    },
    [loadRankingSettingsById.fulfilled.type]: (state, { payload }) => {
      const { id, merchantName, categories, scoreLimit, ...resPayload } =
        payload;
      state.selectedRankingSettingId = id;
      state.rankingSettings = resPayload;
      state.selectedTimeInterval = resPayload.timeInterval[0];
      const categoryType = (categories[0]?.categoryTypes||[]).find(
        (categoryType: any) => categoryType?.isOutdated !== true 
      );
      state.selectedCategoryTypeName = categoryType?.name || '';
      state.categories = categories.map((category: any, index: number) => {
        category.disable = true;
       category.categoryTypes = category.categoryTypes.map(
          (categoryType: any) => {
            categoryType.disable = true;

            return categoryType;
          }
        );
        return category;
      });
      
    },
  },
});

export default reducer;
