import _get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../../../../../service/constants';
import { getFormatLocalTime } from '../../../../../../../../common/utils/time-lib';
import { getAssociationClubsMapping } from '../common/selectors';
import moment from 'moment';

export const getAssociationPlayerDetailStateData = (state: {
  association: { players: { detail: any } };
}) => state.association.players.detail;

/* Star Transfer Club */
export const getSearchClubsPageNumber = createSelector(
  getAssociationPlayerDetailStateData,
  (associationPlayerDetailStateData) =>
    _get(associationPlayerDetailStateData, 'clubs.pageNumber', [])
);

export const getSearchClubsPageSize = createSelector(
  getAssociationPlayerDetailStateData,
  (associationPlayerDetailStateData) =>
    _get(associationPlayerDetailStateData, 'clubs.pageSize', [])
);

export const getSearchClubsPagination = createSelector(
  getSearchClubsPageNumber,
  getSearchClubsPageSize,
  (pageNumber, pageSize) => {
    return { limit: pageSize, offset: pageNumber * pageSize };
  }
);

export const getSearchClubsIsRegisteredMerchant = createSelector(
  getAssociationPlayerDetailStateData,
  (associationPlayerDetailStateData) =>
    _get(
      associationPlayerDetailStateData,
      'clubs.isRegisteredMerchant',
      undefined
    )
);

export const getSearchClubsParameters = createSelector(
  getSearchClubsPagination,
  getSearchClubsIsRegisteredMerchant,
  (pagination, isRegisteredMerchant) => {
    return { ...pagination, isRegisteredMerchant };
  }
);

export const getSearchClubIds = createSelector(
  getAssociationPlayerDetailStateData,
  (associationPlayerDetailStateData) =>
    _get(associationPlayerDetailStateData, 'clubs.ids', [])
);

export const getSearchClubsFilterOptions = createSelector(
  getAssociationClubsMapping,
  getSearchClubIds,
  (associationClubsMapping, searchClubIds) =>
    searchClubIds.map((id: string) => {
      const { name, memberId } = associationClubsMapping[id] || {};

      return {
        value: id,
        label: `${name} ${memberId ? `（${memberId}）` : ''}`,
      };
    })
);

export const getLoadClubsStatus = createSelector(
  getAssociationPlayerDetailStateData,
  (associationPlayerDetailStateData) =>
    _get(associationPlayerDetailStateData, 'clubs.loadClubsStatus', [])
);

export const getIsSearchClubsFilterLoading = createSelector(
  getLoadClubsStatus,
  (loadClubsStatus) => loadClubsStatus === API_REQUEST_STATUS.PENDING
);

export const getIsAssociationPlayerDetailClubTransferring = createSelector(
  getAssociationPlayerDetailStateData,
  (associationPlayerDetailStateData) =>
    _get(associationPlayerDetailStateData, 'transferClub.isTransferring', false)
);

export const getAssociationPlayerTransferClubId = createSelector(
  getAssociationPlayerDetailStateData,
  (associationPlayerDetailStateData) =>
    _get(associationPlayerDetailStateData, 'transferClub.id', null)
);

export const getAssociationPlayerTransferClubName = createSelector(
  getAssociationPlayerTransferClubId,
  getAssociationClubsMapping,
  (associationPlayerTransferClubId, associationClubsMapping) =>
    _get(associationClubsMapping, `${associationPlayerTransferClubId}.name`, '')
);
/* End Transfer Club */

export const getAssociationPlayerDetailData = createSelector(
  getAssociationPlayerDetailStateData,
  (associationPlayerDetailStateData) =>
    _get(associationPlayerDetailStateData, 'data', {})
);

export const getAssociationPlayerDetailId = createSelector(
  getAssociationPlayerDetailData,
  (associationPlayerDetailData) => _get(associationPlayerDetailData, 'id', '')
);

export const getAssociationPlayerDetailName = createSelector(
  getAssociationPlayerDetailData,
  (associationPlayerDetailData) => _get(associationPlayerDetailData, 'name', '')
);

export const getAssociationPlayerDetailGender = createSelector(
  getAssociationPlayerDetailData,
  (associationPlayerDetailData) =>
    _get(associationPlayerDetailData, 'gender', '')
);

export const getAssociationPlayerDetailPhone = createSelector(
  getAssociationPlayerDetailData,
  (associationPlayerDetailData) =>
    _get(associationPlayerDetailData, 'phone', '')
);

export const getAssociationPlayerDetailIdCardNum = createSelector(
  getAssociationPlayerDetailData,
  (associationPlayerDetailData) =>
    _get(associationPlayerDetailData, 'idCardNum', '')
);

export const getAssociationPlayerDetailIdCardType = createSelector(
  getAssociationPlayerDetailData,
  (associationPlayerDetailData) =>
    _get(associationPlayerDetailData, 'idCardType', '')
);

export const getAssociationPlayerDetailRegisterDate = createSelector(
  getAssociationPlayerDetailData,
  (associationPlayerDetailData) => {
    const registerDate = _get(
      associationPlayerDetailData,
      'registerDate',
      null
    );

    if (registerDate) {
      return moment(getFormatLocalTime(registerDate));
    } else {
      return moment();
    }
  }
);

export const getAssociationPlayerDetailExpiredDate = createSelector(
  getAssociationPlayerDetailData,
  (associationPlayerDetailData) => {
    const expiredDate = _get(associationPlayerDetailData, 'expiredDate', null);

    if (expiredDate) {
      return moment(getFormatLocalTime(expiredDate));
    } else {
      return moment();
    }
  }
);

export const getIsAssociationPlayerDetailAssociationClubDisabled = (_: any) =>
  true;

export const getAssociationPlayerDetailAssociationClubId = createSelector(
  getAssociationPlayerDetailData,
  (associationPlayerDetailData) =>
    _get(associationPlayerDetailData, 'associationClubId', '')
);

export const getAssociationPlayerDetailAssociationClubName = createSelector(
  getAssociationPlayerDetailData,
  (associationPlayerDetailData) =>
    _get(associationPlayerDetailData, 'associationClubName', '')
);

export const getAssociationPlayerDetailAssociationClubMemberId = createSelector(
  getAssociationPlayerDetailData,
  (associationPlayerDetailData) =>
    _get(associationPlayerDetailData, 'associationClubMemberId', '')
);

export const getAssociationPlayerDetailAssociationClubMemberName =
  createSelector(
    getAssociationPlayerDetailAssociationClubName,
    getAssociationPlayerDetailAssociationClubMemberId,
    (associationClubName, associationClubMemberId) =>
      `${associationClubName}（${associationClubMemberId}）`
  );

export const getAssociationPlayerDetailRegisterNo = createSelector(
  getAssociationPlayerDetailData,
  (associationPlayerDetailData) =>
    _get(associationPlayerDetailData, 'registerNo', '')
);

export const getIsAssociationPlayerDetailRegisterNoNotEditable = (_: any) =>
  true;

export const getLoadAssociationPlayerDetailStatus = (state: {
  association: { players: { detail: { loadAssociationPlayerStatus: string } } };
}) => state.association.players.detail.loadAssociationPlayerStatus;

export const getIsAssociationPlayerDetailLoaded = createSelector(
  getLoadAssociationPlayerDetailStatus,
  (loadAssociationPlayerDetailStatus) =>
    loadAssociationPlayerDetailStatus === API_REQUEST_STATUS.FULFILLED
);

export const getUpdateAssociationPlayerDetailStatus = (state: {
  association: {
    players: { detail: { updateAssociationPlayerStatus: string } };
  };
}) => state.association.players.detail.updateAssociationPlayerStatus;

export const getIsAssociationPlayerDetailUpdating = createSelector(
  getUpdateAssociationPlayerDetailStatus,
  (updateAssociationPlayerStatus) =>
    updateAssociationPlayerStatus === API_REQUEST_STATUS.PENDING
);

export const getDeleteAssociationPlayerDetailStatus = (state: {
  association: {
    players: { detail: { deleteAssociationPlayerStatus: string } };
  };
}) => state.association.players.detail.deleteAssociationPlayerStatus;

export const getIsAssociationPlayerDetailDeleting = createSelector(
  getDeleteAssociationPlayerDetailStatus,
  (deleteAssociationPlayerStatus) =>
    deleteAssociationPlayerStatus === API_REQUEST_STATUS.PENDING
);

export const getTransferAssociationPlayerDetailStatus = (state: {
  association: {
    players: { detail: { transferAssociationPlayerStatus: string } };
  };
}) => state.association.players.detail.transferAssociationPlayerStatus;

export const getIsAssociationPlayerDetailTransferring = createSelector(
  getTransferAssociationPlayerDetailStatus,
  (transferAssociationPlayerStatus) =>
    transferAssociationPlayerStatus === API_REQUEST_STATUS.PENDING
);

export const getIsAssociationPlayerDetailVisibility = createSelector(
  getAssociationPlayerDetailStateData,
  (associationPlayerDetailStateData) =>
    _get(
      associationPlayerDetailStateData,
      'associationPlayerDetailsVisibility',
      false
    )
);
