/**
 * 路由组件出口文件
 */

import index from '../pages/index/index';
import Settings from '../pages/userSettings/Settings';
import Subscription from '../pages/userSettings/Subscription';
import Dashboard from '../pages/DashboardPage';
import Audit from '../pages/Audits';
import EmployeeList from '../pages/employees/containers/EmployeeList';
import EmployeeDetail from '../pages/employees/containers/EmployeeDetail';

import StoreList from '../pages/stores/containers/StoreList';
import StoreDetail from '../pages/stores/containers/StoreDetails';
import PlayerList from '../pages/players/containers/PlayerList';
import PlayerDetails from '../pages/players/containers/PlayerDetails';
import NewPlayer from '../pages/players/containers/NewPlayer';
import PlayerImport from '../pages/players/containers/PlayerImport';

import TournamentDetails from '../pages/Tournament';

import ClubAuthentication from '../pages/association/containers/clubs/containers/ClubAuthentication';
import ClubAuthenticationImport from '../pages/association/containers/clubs/containers/ClubAuthenticationImport';
import AssociationPlayerList from '../pages/association/containers/players/containers/AssociationPlayerList';
import AssociationPlayersImport from '../pages/association/containers/players/containers/AssociationPlayersImport';
import PlayersAchievementImport from '../pages/association/containers/histories/containers/PlayersAchievementImport';
import ClubsRankingImport from '../pages/association/containers/histories/containers/ClubsRankingImport';
import AddClub from '../pages/association/containers/clubs/containers/AddClub';
import ClubDetail from '../pages/association/containers/clubs/containers/ClubDetail';
import EnrollmentClubs from '../pages/association/containers/enrollments/containers/EnrollmentClubs';
import EnrollmentDetails from '../pages/association/containers/enrollments/containers/EnrollmentDetails';
import EnrollmentList from '../pages/association/containers/enrollments/containers/EnrollmentList';
import EnrollmentResult from '../pages/association/containers/enrollments/containers/EnrollmentResult';
import TournamentList from '../pages/association/containers/tournaments/containers/TournamentList';
import AssociationFunctions from '../pages/functions/containers/AssociationFunctions';

import Functions from '../pages/functions/containers/Functions';
import Poster from '../pages/posters/containers/Poster';
import PosterList from '../pages/posters/containers/PosterList';
import ActivityList from '../pages/activities/containers/ActivityList';
import ActivityDetail from '../pages/activities/containers/ActivityDetail';
import MemberCards from '../pages/MemberCards';

import AssetList from '../pages/PlayerAssets';
import RankingManagement from '../pages/rankings/containers/RankingManagement';
import RankingDetails from '../pages/rankings/containers/RankingDetails';
import Ranking from '../pages/rankings/containers/RankingList';

import RedemptionMarket from '../pages/redemptionMarkets/containers/main';
import RedemptionMarketOrders from '../pages/redemptionMarkets/containers/orders';
import RedemptionMarketVouchers from '../pages/redemptionMarkets/containers/vouchers';
import RedemptionMarketOrderDetails from '../pages/redemptionMarkets/containers/orders/orderDetails';
import RedemptionMarketMerchandises from '../pages/redemptionMarkets/containers/merchandises/containers/list';
import RedemptionMarketMerchandiseDetails from '../pages/redemptionMarkets/containers/merchandises/containers/details';

export default {
  index,
  Settings,
  Subscription,
  Dashboard,
  Audit,
  PlayerList,
  PlayerDetails,
  PlayerImport,
  NewPlayer,
  EmployeeList,
  EmployeeDetail,
  StoreList,
  StoreDetail,
  Functions,
  ActivityList,
  ActivityDetail,
  TournamentDetails,
  AssetList,
  Poster,
  PosterList,
  MemberCards,
  RedemptionMarket,
  RedemptionMarketOrders,
  RedemptionMarketVouchers,
  RedemptionMarketOrderDetails,
  RedemptionMarketMerchandises,
  RedemptionMarketMerchandiseDetails,
  Ranking,
  RankingManagement,
  RankingDetails,
  EnrollmentList,
  EnrollmentClubs,
  EnrollmentDetails,
  EnrollmentResult,
  TournamentList,
  AssociationFunctions,
  ClubAuthentication,
  ClubAuthenticationImport,
  AssociationPlayerList,
  AssociationPlayersImport,
  PlayersAchievementImport,
  ClubsRankingImport,
  AddClub,
  ClubDetail,
} as any;
