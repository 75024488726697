import _get from 'lodash/get';
import {
  getStores,
  getStoresMapping,
} from '../../../../../redux/entities/stores/selectors';
import { createSelector } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../../service/constants';
import { RootState } from '../../../../../common/utils/types';

export const getRankingSettingRawData = (state: RootState) =>
  state.ranking.details.rankingSettings;

export const getCategoriesData = (state: RootState) =>
  _get(state.ranking, 'details.categories', []);

export const getScoreLimitData = (state: RootState) =>
  state.ranking.details.scoreLimit;

export const getLoadRankingDetailsByIdStatus = (state: RootState) =>
  _get(state.ranking.details, 'loadRankingDetailsByIdStatus', '');

export const getStoreList = createSelector(getStores, (stores) => stores);

export const getRankingSettingData = createSelector(
  getRankingSettingRawData,
  getStoresMapping,
  (rankingSettings, storesMapping) => {
    const { storeIds, ...restRankingSettings } = rankingSettings;
    const newStoreIds = storeIds.filter(
      (storeId: string) => storesMapping[storeId]
    );
    return {
      ...restRankingSettings,
      storeIds: newStoreIds,
    };
  }
);

export const getCategoriesDisplayData = createSelector(
  getCategoriesData,
  (categories) => {
    if (categories.length <= 0) {
      return [
        {
          id: `ranking-detail-category-0`,
          name: '',
          categoryTypes: [
            {
              id: `ranking-detail-category-type-0`,
              name: '',
            },
          ],
        },
      ];
    }

    return categories.map((category: any, index: number) => {
      let displayCategoryTypes = [];

      if (category.categoryTypes) {
        displayCategoryTypes = category.categoryTypes.filter(
          (categoryType: any) => !categoryType.isOutdated
        );
      }

      return {
        id: `ranking-detail-category-${index}`,
        displayCategoryTypes: displayCategoryTypes.map(
          (type: any, typeIndex: number) => ({
            id: `ranking-detail-category-type-${typeIndex}`,
            ...type,
          })
        ),
        ...category,
      };
    });
  }
);

export const getIsRankingDetailsLoaded = createSelector(
  getLoadRankingDetailsByIdStatus,
  (loadRankingDetailsByIdStatus) =>
    loadRankingDetailsByIdStatus === API_REQUEST_STATUS.FULFILLED
);
