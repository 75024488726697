import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import {
  DashboardOutlined,
  AuditOutlined,
  ContactsOutlined,
  SettingOutlined,
  LogoutOutlined,
  ShopOutlined,
  AppstoreAddOutlined,
  TeamOutlined,
  SolutionOutlined,
  SafetyCertificateOutlined,
  FireOutlined,
  ShoppingOutlined,
  QuestionCircleOutlined,
  IdcardOutlined,
  FormatPainterOutlined,
  NotificationOutlined,
  OrderedListOutlined,
  TrophyOutlined,
  ContainerOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { MERCHANT_SUBSCRIPTION_STATUS } from '../../common/constants';
import LogoImage from '../../images/logo.svg';
import LogoCollapsedImage from '../../images/logo-2.svg';
import {
  getMenuItems,
  getSelectedItems,
  getUserCenterItems,
  getIsDisplayExpiredDays,
  getIsMenuItemsInitialized,
} from './redux/selectors';
import {
  getIsBaseball,
  getMerchantDisplayName,
  getMerchantExpiredDays,
  getMerchantSubscriptionStatus,
} from '../../redux/app-v2/merchant/selectors';
import { AppDispatch } from '../../redux/store';
import { appMounted, userLogout } from './redux/thunks';
import { actions as frameActions } from './redux';
import FrameTemplate from '../../common/components/FrameTemplate';
import './index.less';

const ICONS_MAPPING: {
  [key: string]: any;
} = {
  DashboardOutlined: <DashboardOutlined className="frameMenuIcon" />,
  AuditOutlined: <AuditOutlined className="frameMenuIcon" />,
  ContactsOutlined: <ContactsOutlined className="frameMenuIcon" />,
  SettingOutlined: <SettingOutlined className="frameMenuIcon" />,
  ShopOutlined: <ShopOutlined className="frameMenuIcon" />,
  AppstoreAddOutlined: <AppstoreAddOutlined />,
  TeamOutlined: <TeamOutlined />,
  SolutionOutlined: <SolutionOutlined />,
  SafetyCertificateOutlined: <SafetyCertificateOutlined />,
  FireOutlined: <FireOutlined />,
  ShoppingOutlined: <ShoppingOutlined />,
  QuestionCircleOutlined: <QuestionCircleOutlined />,
  IdcardOutlined: <IdcardOutlined />,
  FormatPainterOutlined: <FormatPainterOutlined />,
  NotificationOutlined: <NotificationOutlined />,
  AssociationPlayerList: <TeamOutlined />,
  ContainerOutlined: <ContainerOutlined />,
  OrderedListOutlined: <OrderedListOutlined />,
  UserAddOutlined: <UserAddOutlined />,
  TrophyOutlined: <TrophyOutlined />,
};

const Index = ({ routers }: { routers: any }) => {
  const subscriptionModal: any = () =>
    Modal.warning({
      centered: true,
      maskClosable: false,
      title: '试用已到期',
      content: '请到订阅中心完成续费',
      onOk: () => {
        navigate('/subscription', { replace: true });
      },
    });
  const dispatch = useDispatch<AppDispatch>();
  const isMenuItemsInitialized = useSelector(getIsMenuItemsInitialized);
  const menuItems = useSelector(getMenuItems);
  const originalUserCenterItems = useSelector(getUserCenterItems);
  const displayName = useSelector(getMerchantDisplayName);
  const selectedItems = useSelector(getSelectedItems);
  const isDisplayExpiredDays = useSelector(getIsDisplayExpiredDays);
  const merchantExpiredDays = useSelector(getMerchantExpiredDays);
  const merchantSubscriptionStatus = useSelector(getMerchantSubscriptionStatus);
  const navigate = useNavigate();
  const userCenterItems = originalUserCenterItems.map(
    (item: { [x: string]: any; iconName: any }) => {
      const { iconName, ...others } = item;

      return {
        ...others,
        icon: ICONS_MAPPING[item.iconName],
      };
    }
  );
  const navigateToLoginPage = () => {
    navigate('/login', { replace: true });
  };
  const navigateToDashboardPage = () => {
    navigate('/dashboard', { replace: true });
  };
  const navigateToFeaturesPage = () => {
    navigate('/functions', { replace: true });
  };
  const navigateToStoresPage = () => {
    navigate('/stores', { replace: true });
  };

  useMount(async () => {
    await dispatch(
      appMounted({
        navigateToLoginPage,
        navigateToDashboardPage,
        navigateToFeaturesPage,
        navigateToStoresPage,
      }) as any
    );
  });

  useEffect(() => {
    dispatch(frameActions.setCurrentHash(window.location.hash));
  }, [dispatch, window.location.hash]);

  useEffect(() => {
    if (
      merchantSubscriptionStatus === MERCHANT_SUBSCRIPTION_STATUS.expired &&
      !window.location.hash.includes('subscription')
    ) {
      subscriptionModal();
    } else {
      if (
        subscriptionModal &&
        typeof subscriptionModal.destroy === 'function'
      ) {
        subscriptionModal.destroy();
      }
    }
  }, [merchantSubscriptionStatus, window.location.hash]);

  const isBaseball = useSelector(getIsBaseball);

  return (
    <FrameTemplate
      selectedKeys={selectedItems}
      logo={{
        image: LogoImage,
        collapsedImage: LogoCollapsedImage,
        alt: 'Zaiye Logo',
      }}
      menuItemsInitialized={isMenuItemsInitialized}
      menuItems={menuItems.map((item) => {
        item.icon = ICONS_MAPPING[item.iconName];
        if (isBaseball) {
          if (item.label.includes('成员单位')) {
            item.label = item.label.replace('成员单位', '联盟球队');
          } else if (item.label.includes('运动员')) {
            item.label = item.label.replace('运动员', '球员');
          }
        }
        return item;
      })}
      userCenterItems={userCenterItems}
      logOutInfo={{
        icon: <LogoutOutlined />,
        label: '退出登录',
        logOut: function (): void {
          dispatch(userLogout(navigateToLoginPage));
        },
      }}
      searchOnGlobal={() => {}}
      content={<div className="appLayout__content">{routers}</div>}
      menuTitle="管理后台"
      headerRightContent={
        <>
          {isDisplayExpiredDays ? (
            <span className="headerUserExpiredDays">
              离试用结束还剩{merchantExpiredDays}天
            </span>
          ) : null}
          <span className="headerUserName">{displayName || ''}</span>
        </>
      }
    />
  );
};

Index.displayName = 'Index';

Index.propTypes = {
  routers: PropTypes.element,
};

Index.defaultProps = {
  routers: null,
};

export default Index;
