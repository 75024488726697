import { get, put } from '../../../../../../../service/api';

export const getClubAuthentication = async (payload: {
  text: string;
  isRegisteredMerchant?: boolean;
  isMember?: boolean;
  merchantName: string;
  offset: number;
  limit: number;
}) => {
  try {
    const {
      text,
      isRegisteredMerchant,
      isMember,
      merchantName,
      offset,
      limit,
    } = payload;
    const params = { isRegisteredMerchant, isMember, text, offset, limit };
    const res = await get(
      `/merchants/${merchantName}/association-clubs`,
      params,
      {},
      {}
    );

    return res;
  } catch (e) {
    throw new Error('get association club authentication failed');
  }
};

export const putClubAuthentication = async (payload: {
  id: string;
  body: any;
  merchantName: string;
}) => {
  try {
    const { merchantName, id, body } = payload;
    const res = await put(
      `/merchants/${merchantName}/association-clubs/${id}`,
      body,
      {}
    );

    return res;
  } catch (error) {
    throw error;
  }
};
