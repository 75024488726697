/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';
import { useNavigate } from 'react-router-dom';
import TableResponsiveFrame from '../../../../common/components/tables/TableResponsiveFrame';
import { ContentTableColumnPropsType } from '../../../../common/components/tables/ContentTable/types';
import ContentDetail from '../../../../common/components/ContentDetail';
import { actions as rankingActions } from './redux';
import { loadRankSettingsList } from './redux/thunks';

import {
  getRankingManagementPageNumber,
  getRankingManagementPageSize,
  getRankingManagementTotalStores,
} from './redux/selectors';
import { getRankingManagementTableData } from './redux/selectors';
import './RankingManagement.less';

const rankingManagement = () => {
  const dispatch = useDispatch();
  const rankingManagementList = useSelector(getRankingManagementTableData);

  const pageNumber = useSelector(getRankingManagementPageNumber);
  const pageSize = useSelector(getRankingManagementPageSize);
  const dataTotal = useSelector(getRankingManagementTotalStores);
  const navigate = useNavigate();

  const columns: ContentTableColumnPropsType[] = [
    {
      title: '修改时间',
      width: 500,
      dataIndex: 'validModifiedTime',
      key: 'validModifiedTime',
      align: 'center',
    },
    {
      title: '名称',
      width: 120,
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      mobileAlign: 'contentHeaderLeft',
    },
    {
      title: '状态',
      dataIndex: 'enabled',
      key: 'enabled',
      align: 'center',
      mobileAlign: 'contentHeaderRight',
      render: (enabled: boolean) => (
        <Tag color={enabled ? 'success' : 'error'}>
          {enabled ? '开启' : '关闭'}
        </Tag>
      ),
    },
  ];

  useEffect(() => {
    dispatch(loadRankSettingsList() as any);
  }, [pageNumber, pageSize]);

  return (
    <ContentDetail
      title="排行榜管理"
      headerRight={
        <div className="NewRankingManagementButtonContainer">
          <Button
            className="NewRankingManagementButton"
            onClick={() => {
              navigate('/ranking-management/new');
            }}
          >
            <PlusCircleOutlined className="NewRankingManagementButtonOutlinedIcon" />
            <span className="NewRankingManagementButtonText">添加新榜单</span>
          </Button>
        </div>
      }
    >
      <TableResponsiveFrame
        columns={columns}
        pageNumber={pageNumber + 1}
        pageSize={pageSize}
        data={rankingManagementList}
        dataItemsTotal={dataTotal}
        onRowClick={(record: any) => {
          navigate(`/ranking-management/${record.id}`);
          return true;
        }}
        onChangePageSize={async (_: number, pageSize: number) => {
          await dispatch(rankingActions.updatePageSize(pageSize));
        }}
        onChangePageNumber={async (pageNumber: number) => {
          await dispatch(rankingActions.updatePageNumber(pageNumber - 1));
        }}
      />
    </ContentDetail>
  );
};

export default rankingManagement;
