import { get } from '../../../service/api';

export const getPricePlan = async (
  payload: { type: string },
  options?: { isShow: false }
) => {
  try {
    const { type } = payload;
    const result = await get(`/price-plans/${type}`, {}, {}, options);

    return result;
  } catch (e) {
    throw e;
  }
};

export const getPricePlans = async () => {
  try {
    const result = await get('/price-plans', {}, {}, {});

    return result;
  } catch (e) {
    throw e;
  }
};
