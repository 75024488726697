import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../../../../common/utils/types';
import { priceConvertToIntegerCents } from '../../../../../../../../common/utils/currency';
import { TEAM_PLAYER_COUNTS } from './utils/constants';
import { actions as enrollmentDetailActions } from '../../redux';
import {
  getNewGameOptionSettingCategoryId,
  getNewGameOptionSettingCategoryOptions,
  getShowGameOptionSettingNewModal,
  getShowArcheryGameOptionSettingNewModal,
  getArcheryCompetitionBowTypes,
  getArcheryCompetitionArrowsSets,
  getArcheryCompetitionEnvironments,
  getArcheryCompetitionDistances,
  getArcheryCompetitionTargets,
} from '../../redux/selectors';
import NormalOptionSettingModal from './components/NormalOptionSettingModal';
import CompetitionOptionSettingModal from './components/CompetitionOptionSettingModal';

const getCategoryName = (
  bowValue: any,
  distanceValue: any,
  bowList: any[],
  distanceList: any[]
) => {
  const bow = bowList.find((bowItem: any) => bowItem.value === bowValue);
  const distance = distanceList.find(
    (distanceItem: any) => distanceItem.value === distanceValue
  );

  const bowName = bow ? bow.label : '';
  const distanceName = distance ? distance.label : '';

  return `${bowName}${distanceName}`;
};
const GameOptionSettingNewModal = (props: { onAdd: (option: any) => void }) => {
  const dispatch = useDispatch<AppDispatch>();
  const newGameOptionSettingCategoryId = useSelector(
    getNewGameOptionSettingCategoryId
  );
  const newGameOptionSettingCategoryOptions = useSelector(
    getNewGameOptionSettingCategoryOptions
  );
  const showGameOptionSettingNewModal = useSelector(
    getShowGameOptionSettingNewModal
  );
  const showArcheryGameOptionSettingNewModal = useSelector(
    getShowArcheryGameOptionSettingNewModal
  );
  const archeryCompetitionBowTypes = useSelector(getArcheryCompetitionBowTypes);
  const archeryCompetitionArrowsSets = useSelector(
    getArcheryCompetitionArrowsSets
  );
  const archeryCompetitionDistances = useSelector(
    getArcheryCompetitionDistances
  );
  const archeryCompetitionEnvironments = useSelector(
    getArcheryCompetitionEnvironments
  );
  const archeryCompetitionTargets = useSelector(getArcheryCompetitionTargets);
  const { onAdd } = props;
  const [name, setName] = useState('');
  const [bow, setBow] = useState() as any;
  const [arrow, setArrow] = useState() as any;
  const [environment, setEnvironment] = useState() as any;
  const [distance, setDistance] = useState() as any;
  const [target, setTarget] = useState() as any;
  const [teamPlayerCount, setTeamPlayerCount] = useState(1);
  const [enableChargePrice, setEnableChargePrice] = useState(false);
  const [price, setPrice] = useState();
  const [associationPlayerPrice, setAssociationPlayerPrice] = useState();
  const [teamPlayerCountList, setTeamPlayerCountList] = useState([
    { name: '个人', teamPlayerCount: 1 },
  ]);
  const [archeryPriceSettings, setArcheryPriceSettings] = useState([
    {
      teamPlayerCount: 1,
      name: '个人',
      gameOptionEnableChargePrice: false,
      gameOptionPrice: undefined,
      gameOptionAssociationPlayerPrice: undefined,
    },
  ]);
  const handleCloseModal = async (form: any) => {
    await resetData(form);
    dispatch(enrollmentDetailActions.hideArcheryGameOptionSettingNewModal());
    dispatch(enrollmentDetailActions.hideGameOptionSettingNewModal());
  };
  const handleAddNewCompetitionGameOption = async (form: any) => {
    try {
      await form.validateFields();

      dispatch(
        enrollmentDetailActions.updateGameInfoCategoryName({
          currentGameInfoCategoryId: newGameOptionSettingCategoryId,
          GameInfoCategoryName: name,
        })
      );

      teamPlayerCountList.forEach((teamPlayerCountItem: any) => {
        const settings = archeryPriceSettings.find(
          (s) =>
            s.name === teamPlayerCountItem.name &&
            s.teamPlayerCount === teamPlayerCountItem.teamPlayerCount
        );
        const option = {
          categoryId: newGameOptionSettingCategoryId,
          // @ts-ignore
          name: teamPlayerCountItem.name,
          teamPlayerCount: teamPlayerCountItem.teamPlayerCount,
          enableChargePrice: settings?.gameOptionEnableChargePrice || false,
          pricingInfo: {
            price: priceConvertToIntegerCents(settings?.gameOptionPrice || 0),
            associationPlayerPrice: priceConvertToIntegerCents(
              settings?.gameOptionAssociationPlayerPrice || 0
            ),
          },
          isArchery: true,
          archerySettings: {
            bow,
            target,
            distance,
            environment,
            arrows: arrow,
          },
        };
        onAdd(option);
      });

      await resetData(form);
      dispatch(enrollmentDetailActions.hideArcheryGameOptionSettingNewModal());
    } catch (error) {
      throw error;
    }
  };
  const handleAddNewNormalGameOption = async (form: any) => {
    try {
      await form.validateFields();

      onAdd({
        categoryId: newGameOptionSettingCategoryId,
        name,
        teamPlayerCount,
        enableChargePrice,
        pricingInfo: {
          price: priceConvertToIntegerCents(price || 0),
          associationPlayerPrice: priceConvertToIntegerCents(
            associationPlayerPrice || 0
          ),
        },
      });
      await resetData(form);
      dispatch(enrollmentDetailActions.hideGameOptionSettingNewModal());
    } catch (error) {
      throw error;
    }
  };
  const resetData = async (form: any) => {
    await form.resetFields();
    setName('');
    setTeamPlayerCount(1);
    setEnableChargePrice(false);
    setPrice(undefined);
    setAssociationPlayerPrice(undefined);
  };

  useEffect(() => {
    if (!bow && archeryCompetitionBowTypes.length > 0) {
      setBow(archeryCompetitionBowTypes[0]?.value);
    }
  }, [bow, archeryCompetitionBowTypes, distance, archeryCompetitionDistances]);

  useEffect(() => {
    if (!arrow && archeryCompetitionArrowsSets.length > 0) {
      setArrow(archeryCompetitionArrowsSets[0]?.value);
    }
  }, [arrow, archeryCompetitionArrowsSets]);

  useEffect(() => {
    if (!environment && archeryCompetitionEnvironments.length > 0) {
      setEnvironment(archeryCompetitionEnvironments[0]?.value);
    }
  }, [environment, archeryCompetitionEnvironments]);

  useEffect(() => {
    if (!distance && archeryCompetitionDistances.length > 0) {
      setDistance(archeryCompetitionDistances[0]?.value);
    }
  }, [distance, archeryCompetitionDistances, archeryCompetitionBowTypes]);

  useEffect(() => {
    if (!target && archeryCompetitionTargets.length > 0) {
      setTarget(archeryCompetitionTargets[0]?.value);
    }
  }, [target, archeryCompetitionTargets]);

  useEffect(() => {
    if (showArcheryGameOptionSettingNewModal && (bow || distance)) {
      setName(
        getCategoryName(
          bow,
          distance,
          archeryCompetitionBowTypes,
          archeryCompetitionDistances
        )
      );
    }
  }, [
    archeryCompetitionBowTypes,
    archeryCompetitionDistances,
    bow,
    distance,
    showArcheryGameOptionSettingNewModal,
    showGameOptionSettingNewModal,
  ]);

  useEffect(() => {
    if (showGameOptionSettingNewModal) {
      setName('');
    }
  }, [showGameOptionSettingNewModal]);

  if (showArcheryGameOptionSettingNewModal) {
    return (
      <CompetitionOptionSettingModal
        show={showArcheryGameOptionSettingNewModal}
        deletable={false}
        preserve={true}
        data={{
          gameOptionName: name,
          gameOptionTeamPlayerCountList: teamPlayerCountList,
          gameOptionArcheryPriceSettings: archeryPriceSettings,
          gameArcherySettings: {
            bow: {
              name: 'bow',
              value: bow,
            },
            arrow: {
              name: 'arrow',
              value: arrow,
            },
            environment: {
              name: 'environment',
              value: environment,
            },
            distance: {
              name: 'distance',
              value: distance,
            },
            target: {
              name: 'target',
              value: target,
            },
          },
        }}
        onSave={handleAddNewCompetitionGameOption}
        onReset={resetData}
        onClose={handleCloseModal}
        onSelectBow={(value: any) => {
          setBow(value);
        }}
        onSelectArrow={(value: any) => {
          setArrow(value);
        }}
        onSelectEnvironment={(value: any) => {
          setEnvironment(value);
        }}
        onSelectDistance={(value: any) => {
          setDistance(value);
        }}
        onSelectTarget={(value: any) => {
          setTarget(value);
        }}
        onChangeCategoryName={(value: string) => setName(value)}
        onChangeGameInfoCategoryOptionTeamPlayerCountList={(values) => {
          setTeamPlayerCountList(values);
        }}
        onChangeArcheryPriceSettings={(values) => {
          setArcheryPriceSettings(values);
        }}
      />
    );
  }

  return (
    <NormalOptionSettingModal
      show={showGameOptionSettingNewModal}
      deletable={false}
      preserve={true}
      data={{
        gameOptionName: name,
        gameOptionTeamPlayerCount: teamPlayerCount,
        gameOptionEnableChargePrice: enableChargePrice,
        gameOptionPrice: price,
        gameOptionAssociationPlayerPrice: associationPlayerPrice,
      }}
      categoryOptions={newGameOptionSettingCategoryOptions}
      onSave={handleAddNewNormalGameOption}
      onReset={resetData}
      onClose={handleCloseModal}
      onChangeGameInfoCategoryOptionName={(value) => {
        setName(value);
      }}
      onChangeGameInfoCategoryOptionTeamPlayerCount={(value) => {
        setTeamPlayerCount(value);
      }}
      onChangeGameInfoCategoryOptionEnableChargePrice={(value) => {
        setEnableChargePrice(value);
      }}
      onChangeGameInfoCategoryOptionPrice={(value: any) => {
        setPrice(value);
      }}
      onChangeGameInfoCategoryOptionAssociationPlayerPrice={(value: any) => {
        setAssociationPlayerPrice(value);
      }}
    />
  );
};

GameOptionSettingNewModal.displayName = 'GameOptionSettingNewModal';

export default GameOptionSettingNewModal;
