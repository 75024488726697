import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Space } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { AppDispatch } from '../../../../../../common/utils/types';
import { MERCHANT_TYPE, ROLES } from '../../../../../../common/constants';
import {
  getIsOwner,
  getIsManager,
  getIsStoreManager,
} from '../../../../../../redux/app-v2/user/selectors';
import {
  getMerchantName,
  getMerchantType,
} from '../../../../../../redux/app-v2/merchant/selectors';
import { actions as employeeListActions } from '../../redux';
import { getIsNewEmployeeModalShown } from '../../redux/selectors';
import EmployeeAdminModal from '../EmployeeAdminModal';
import NewEmployeeModal from '../NewEmployeeModal';

const NewMemberModal: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isOwner = useSelector(getIsOwner);
  const isManager = useSelector(getIsManager);
  const isStoreManager = useSelector(getIsStoreManager);
  const merchantType = useSelector(getMerchantType);
  const merchantName = useSelector(getMerchantName);
  const isNewEmployeeModalShown = useSelector(getIsNewEmployeeModalShown);
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <Button
        className="insertButton"
        onClick={() => {
          setIsModalVisible(true);
        }}
      >
        <PlusCircleOutlined />
        <span>添加新成员</span>
      </Button>

      <Modal
        centered
        title="添加新成员"
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      >
        <Space
          direction="vertical"
          size="middle"
          style={{
            width: '100%',
            textAlign: 'center',
          }}
        >
          {(isOwner || isManager || isStoreManager) &&
          merchantType !== MERCHANT_TYPE.association ? (
            <Button
              className="insertButton"
              onClick={() => {
                setIsModalVisible(false);
                dispatch(employeeListActions.showNewEmployeeModal());
              }}
            >
              <span>添加新店员</span>
            </Button>
          ) : null}
          <EmployeeAdminModal
            openModal={() => setIsModalVisible(false)}
            roleName={ROLES.Manager}
          />
          {(isOwner || isManager || isStoreManager) &&
          merchantType !== MERCHANT_TYPE.association ? (
            <EmployeeAdminModal
              openModal={() => setIsModalVisible(false)}
              roleName={ROLES.StoreManager}
            />
          ) : null}

          <EmployeeAdminModal
            openModal={() => setIsModalVisible(false)}
            roleName={ROLES.Operation}
          />

          <EmployeeAdminModal
            openModal={() => setIsModalVisible(false)}
            roleName={ROLES.Judge}
          />
        </Space>
      </Modal>

      <NewEmployeeModal
        visible={isNewEmployeeModalShown}
        merchantName={merchantName}
        onClose={() => {
          dispatch(employeeListActions.hideNewEmployeeModal());
        }}
      />
    </>
  );
};

export default NewMemberModal;
