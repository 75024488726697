/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { useMount, useUnmount } from 'react-use';
import {
  Button,
  Card,
  Col,
  Descriptions,
  Form,
  Input,
  Modal,
  Row,
  Skeleton,
  Space,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { AppDispatch } from '../../../../common/utils/types';
import { getFormatLocalTime } from '../../../../common/utils/time-lib';
import {
  // getPlayerDetailName,
  getPlayerDetailPhone,
  getPlayerDetailRemarkName,
  getPlayerDetailRemarks,
  getIsPlayerDetailRequestLoaded,
  getPlayerMemberCards,
} from './redux/selectors';
import { actions as playerDetailActions } from './redux';
import { mount, savePlayerById } from './redux/thunks';
import { errorMessage } from '../../../../common/utils/feedback';
import ContentDetail from '../../../../common/components/ContentDetail';
import BreadcrumbRouter from '../../../../common/components/BreadcrumbRouter';
import './PlayerDetails.less';

const playerDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const { id }: any = useParams();
  const isPlayerDetailRequestLoaded = useSelector(
    getIsPlayerDetailRequestLoaded
  );
  // const name = useSelector(getPlayerDetailName);
  const phone = useSelector(getPlayerDetailPhone);
  const remarkName = useSelector(getPlayerDetailRemarkName);
  const remarks = useSelector(getPlayerDetailRemarks);
  const memberCards = useSelector(getPlayerMemberCards);

  useMount(async () => {
    await dispatch(mount(id));
  });

  useUnmount(async () => {
    form.resetFields();
    await dispatch(playerDetailActions.resetPlayerDetail());
  });

  const headerHeight =
    document.querySelector('.ant-layout-header')?.clientHeight || 0;
  const titleHeight =
    document.querySelector('.content__title')?.clientHeight || 0;

  return (
    <ContentDetail
      title="会员管理/会员详情"
      headerLeft={
        <BreadcrumbRouter
          routes={[
            {
              path: '/players',
              breadcrumbName: '会员管理',
            },
            {
              path: `${id}`,
              breadcrumbName: '会员详情',
            },
          ]}
        />
      }
    >
      <section className="PlayerDetailsContainer">
        <Row>
          <Col span={12}>
            {isPlayerDetailRequestLoaded ? (
              <Form
                className="PlayerDetailsInfo"
                layout="vertical"
                form={form}
                preserve={false}
              >
                {/* <Form.Item label="会员昵称">
                  <Input placeholder="请输入会员昵称" disabled value={name} />
                </Form.Item> */}
                <Form.Item label="会员电话">
                  <Input placeholder="请输入会员电话" disabled value={phone} />
                </Form.Item>
                <Form.Item
                  label="会员备注名称"
                  name={[id, 'remarkName']}
                  initialValue={remarkName}
                >
                  <Input
                    placeholder="请输入会员备注名称"
                    value={remarkName}
                    onChange={(e) => {
                      dispatch(
                        playerDetailActions.updatePlayerDetail({
                          remarkName: e.target.value,
                        })
                      );
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="会员备注"
                  name={[id, 'remarks']}
                  initialValue={remarks}
                >
                  <Input
                    placeholder="请输入会员备注"
                    value={remarks}
                    onChange={(e) => {
                      dispatch(
                        playerDetailActions.updatePlayerDetail({
                          remarks: e.target.value,
                        })
                      );
                    }}
                  />
                </Form.Item>
              </Form>
            ) : (
              <Skeleton active />
            )}
            <Space>
              <Button
                type="primary"
                className="operateButton"
                onClick={() => {
                  Modal.confirm({
                    centered: true,
                    title: '确认保存吗？',
                    content: '确定修改玩家信息吗？',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: async () => {
                      try {
                        form.validateFields();
                        await dispatch(savePlayerById());
                      } catch (err) {
                        errorMessage('请检查输入信息是否正确！');
                      }
                    },
                  });
                }}
              >
                保存
              </Button>
              <Button
                type="default"
                className="operateButton cancelButton"
                onClick={() => {
                  navigate(-1);
                }}
              >
                返回
              </Button>
            </Space>
          </Col>
          <Col span={12}>
            <div
              style={{
                maxHeight:
                  'calc(100vh - ' + (headerHeight + titleHeight) + 'px)',
                overflowY: 'scroll',
              }}
            >
              <Space direction="vertical">
                {memberCards.map((card: any) => {
                  const {
                    id,
                    memberCardType,
                    memberCardNum,
                    validFrom,
                    validTo,
                  } = card || {};
                  const validFromFormatDate = validFrom
                    ? getFormatLocalTime(validFrom, 'YYYY/MM/DD')
                    : '';
                  const validToFormatDate = validTo
                    ? ` ～ ${getFormatLocalTime(validTo, 'YYYY/MM/DD')}`
                    : ' ～ 长期有效';

                  return (
                    <Card
                      key={id}
                      title={memberCardType}
                      style={{ width: 400 }}
                    >
                      <Descriptions column={1}>
                        <Descriptions.Item label="卡号">
                          {memberCardNum}
                        </Descriptions.Item>
                        <Descriptions.Item label="有效期">
                          {validFromFormatDate}
                          {validToFormatDate}
                        </Descriptions.Item>
                      </Descriptions>
                    </Card>
                  );
                })}
              </Space>
            </div>
          </Col>
        </Row>
      </section>
    </ContentDetail>
  );
};

export default playerDetails;
