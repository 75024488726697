import React, { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import ListTable from '../ListTable';
import ContentTable from '../ContentTable';

const TableResponsiveFrame = (props: any) => {
  const { width: windowWidth } = useWindowSize();
  const [isSwitchToListTable, setIsSwitchToListTable] = useState(
    windowWidth < 768
  );

  useEffect(() => {
    if (windowWidth < 768) {
      setIsSwitchToListTable(true);
    } else {
      setIsSwitchToListTable(false);
    }
  }, [windowWidth]);

  return isSwitchToListTable ? (
    <ListTable {...props} />
  ) : (
    <ContentTable {...props} />
  );
};

TableResponsiveFrame.displayName = 'TableResponsiveFrame';

export default TableResponsiveFrame;
