import React, { useEffect } from 'react';
import { Button, Modal, Tag } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { AppDispatch } from '../../../../redux/store';
import { ROLES } from '../../../../common/constants';
import { ROLE_MAPPING } from '../../constants';
import {
  getIsUserInitialized,
  getIsOwner,
  getIsManager,
  getIsCrew,
} from '../../../../redux/app-v2/user/selectors';
import { getOwnerRoleId } from '../../../../redux/app-v2/roles/selectors';
import { loadRoles } from '../../../../redux/app-v2/roles/thunks';
import { actions as employeeListActions } from './redux';
import {
  getEmployeeListPageSize,
  getEmployeeListPageNumber,
  getEmployeeListTotalEmployees,
  getEmployeeList,
} from './redux/selectors';
import {
  loadEmployeeList,
  updateEmployeeDimission,
  updateEmployeeListPageNumber,
  updateEmployeeListPageSize,
} from './redux/thunks';
import TableResponsiveFrame from '../../../../common/components/tables/TableResponsiveFrame';
import './EmployeeList.less';
import ContentDetail from '../../../../common/components/ContentDetail';
import NewMemberModal from './components/NewMemberModal';
import OwnerTransferModal from './components/OwnerTransferModal';

const EmployeeList: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isUserInitialized = useSelector(getIsUserInitialized);
  const isOwner = useSelector(getIsOwner);
  const isManager = useSelector(getIsManager);
  const isCrew = useSelector(getIsCrew);
  const ownerRoleId = useSelector(getOwnerRoleId);
  const employeeList = useSelector(getEmployeeList);
  const pageNumber = useSelector(getEmployeeListPageNumber);
  const pageSize = useSelector(getEmployeeListPageSize);
  const dataTotal = useSelector(getEmployeeListTotalEmployees);
  const columns: any = [
    {
      title: '昵称',
      dataIndex: 'name',
      key: 'name',
      className: 'employeeNameColumn',
      mobileAlign: 'contentHeaderLeft',
      render: (_: any, { id, name }: any) => (
        <Link to={`/employees/${id}`}>{name}</Link>
      ),
    },
    {
      title: '门店',
      dataIndex: 'storeName',
      key: 'storeName',
      render: (_: any, { storeName }: any) => storeName,
    },
    {
      title: '手机号码',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: '权限',
      dataIndex: 'roleName',
      key: 'roleName',
      mobileAlign: 'headerLeft',
      render: (_: any, record: any) => {
        return (
          <span>
            {ROLE_MAPPING[record.roleName as keyof typeof ROLE_MAPPING] || ''}
          </span>
        );
      },
    },
    {
      title: '状态',
      dataIndex: 'dimission',
      key: 'dimission',
      mobileAlign: 'contentHeaderRight',
      render: (_: any, { dimission }: any) => {
        return (
          <Tag color={dimission ? 'red' : 'green'}>
            {dimission ? '停职' : '在职'}
          </Tag>
        );
      },
    },
    {
      title: '操作',
      dataIndex: 'id',
      key: 'operator',
      mobileAlign: 'headerRight',
      render: (_: any, record: any) => {
        const { roleName, roleId } = record || {};
        const isUnAvailableDimissionOperate =
          ((roleName === ROLES.Owner || roleName === ROLES.Manager) &&
            !isOwner) ||
          (roleName === ROLES.StoreManager && !isOwner && !isManager) ||
          (roleName === ROLES.Crew && isCrew);

        if (isUnAvailableDimissionOperate) {
          return '-';
        }

        if ((roleName === ROLES.Owner || ownerRoleId === roleId) && isOwner) {
          return (
            <Button
              type="primary"
              danger
              size="small"
              onClick={async () => {
                Modal.confirm({
                  centered: true,
                  icon: <WarningOutlined color="#ff4d4f" />,
                  title: '危险操作，确定移交你的权限吗？',
                  content: '移交权限后你将无法再次登陆系统，且无法恢复。',
                  okText: '确认',
                  cancelText: '取消',
                  okButtonProps: {
                    danger: true,
                  },
                  onOk: () => {
                    dispatch(employeeListActions.showTransferOwnerModal());
                  },
                });
              }}
            >
              移交权限
            </Button>
          );
        }

        return (
          <>
            {record.dimission ? (
              <Button
                ghost
                type="primary"
                size="small"
                onClick={() => {
                  Modal.confirm({
                    centered: true,
                    title: '确定恢复该员工？',
                    content: '员工被复职后将可以继续登陆系统并进行操作',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: () => {
                      dispatch(
                        updateEmployeeDimission({
                          id: record.id,
                          dimission: false,
                        })
                      );
                    },
                  });
                }}
              >
                操作复职
              </Button>
            ) : (
              <Button
                ghost
                danger
                size="small"
                onClick={() => {
                  Modal.confirm({
                    centered: true,
                    title: '确定停职员工？',
                    content:
                      '员工被停职后会立即自动退出登陆，且无法再次登陆系统直到复职。',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: () => {
                      dispatch(
                        updateEmployeeDimission({
                          id: record.id,
                          dimission: true,
                        })
                      );
                    },
                  });
                }}
                disabled={record.disabledDismissOperate}
              >
                操作停职
              </Button>
            )}
          </>
        );
      },
      align: 'center',
    },
  ];

  useMount(async () => {
    await dispatch(loadRoles());
  });

  useEffect(() => {
    if (isUserInitialized) {
      dispatch(loadEmployeeList({}));
    }
  }, [dispatch, isUserInitialized]);

  return (
    <ContentDetail title="员工管理" headerRight={<NewMemberModal />}>
      <TableResponsiveFrame
        columns={columns}
        pageNumber={pageNumber + 1}
        pageSize={pageSize}
        data={employeeList}
        dataItemsTotal={dataTotal}
        onChangePageSize={async (_: number, pageSize: number) => {
          await dispatch(updateEmployeeListPageSize(pageSize));
        }}
        onChangePageNumber={async (pageNumber: number) => {
          await dispatch(updateEmployeeListPageNumber(pageNumber - 1));
        }}
      />
      <OwnerTransferModal />
    </ContentDetail>
  );
};

export default EmployeeList;
