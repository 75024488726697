import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  errorMessage,
  successMessage,
} from '../../../../../common/utils/feedback';
import { getMerchantName } from '../../../../../redux/app-v2/merchant/selectors';
import { getIsMemberCardsAvailable } from '../../../../../redux/app-v2/featuresSetting/selectors';
import {
  loadPlayerByIdV2 as loadEntitiesPlayers,
  updatePlayerByIdV2 as updateEntitiesPlayerByIdV2,
} from '../../../../../redux/entities/players/thunks';
import { getPlayerChangedDetail } from './selectors';
import { getPlayerMemberCards } from './api-request';
import { PlayerMemberCard } from './types';

export const loadPlayerById = createAsyncThunk(
  'player/loadPlayerById',
  async (id: string | undefined, { dispatch }) => {
    try {
      if (id) {
        await dispatch(loadEntitiesPlayers(id));

        return id;
      }
    } catch (e) {
      errorMessage('加载用户信息失败');
    }
  }
);

export const savePlayerById = createAsyncThunk(
  'player/savePlayerById',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState();
      const { id, ...otherData } = getPlayerChangedDetail(state);

      await dispatch(updateEntitiesPlayerByIdV2({ id, payload: otherData }));

      successMessage('保存用户信息成功');
    } catch (e) {
      errorMessage('保存用户信息失败');
    }
  }
);

export const loadPlayerMemberCards = createAsyncThunk(
  'player/getPlayerMemberCards',
  async (id: string | undefined, { getState }) => {
    try {
      const state = getState();
      const merchantName = getMerchantName(state);

      const result = (await getPlayerMemberCards(merchantName, id)) as {
        data: PlayerMemberCard[];
      };
      const { data } = result || {};

      return data;
    } catch (e) {
      errorMessage('加载会员卡列表失败');
    }
  }
);

export const mount = createAsyncThunk(
  'player/mount',
  async (id: string | undefined, { getState, dispatch }) => {
    const state: any = getState();
    const isMemberCardsAvailable = getIsMemberCardsAvailable(state);

    await dispatch(loadPlayerById(id));

    if (isMemberCardsAvailable) {
      dispatch(loadPlayerMemberCards(id));
    }
  }
);
