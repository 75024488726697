import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Modal, Table, Steps, Popover, Spin, Tag } from 'antd';
import { NodeIndexOutlined, PlusOutlined } from '@ant-design/icons';
import { ContentTableColumnPropsType } from '../../../../../../../common/components/tables/ContentTable/types';

import './GroupingComponent.less';
import {
  loadUnclassifiedPlayers,
  createTeamById,
  removeEnrollmentResultItem,
} from '../redux/thunks';
import { actions as enrollmentResultActions } from '../redux';
import { isNumber } from 'lodash';
import { getLocaleData } from '../../../../../../../service/local-api-request';

interface FormPlayersType {
  name: string;
  id: String;
  form: any[];
}

interface UnclassifiedDataType {
  categoryName: string;
  displayName: string;
  optionName: number;
  formPlayers: FormPlayersType[];
  teamPlayers: number;
}

const GroupingComponent = ({ onClose }: { onClose: () => void }) => {
  const { id } = useParams() as any;
  const merchantName = getLocaleData('long-term', 'merchant')?.merchantName;
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [step, setStep] = useState(0);
  const [unclassifiedData, setUnclassifiedData] = useState<
    UnclassifiedDataType[]
  >([]);

  const [categoryIndex, setCategoryIndex] = useState<number | null>();
  const [players, setPlayers] = useState<number[]>([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const teamsColumns: ContentTableColumnPropsType[] = [
    {
      title: '俱乐部',
      width: 100,
      dataIndex: 'clubName',
      key: 'clubName',
      align: 'center',
      mobileAlign: 'headerLeft',
      render: (clubName, record) => {
        const { logoImageUrl, leaderName, leaderPhone } = record;

        if (!logoImageUrl && !leaderName && !leaderPhone) {
          return <span>{clubName}</span>;
        }

        return (
          <Button
            type="link"
            onClick={() => {
              Modal.info({
                icon: null,
                className: 'EnrollmentResultClubInfoModal',
                maskClosable: true,
                centered: true,
                content: (
                  <div className="flex flex-column flex-center flex-middle">
                    {record.logoImageUrl ? (
                      <div className="EnrollmentResultClubInfoImageContainer">
                        <img
                          className="EnrollmentResultClubInfoImage"
                          src={logoImageUrl}
                          alt="Zaiye partners logo"
                        />
                      </div>
                    ) : null}

                    <h2>{clubName}</h2>
                    {leaderName || leaderPhone ? (
                      <p className="flex flex-column">
                        {leaderName ? (
                          <span className="EnrollmentResultClubInfoLeaderText">
                            领队：{leaderName}
                          </span>
                        ) : null}
                        {leaderPhone ? (
                          <span className="EnrollmentResultClubInfoLeaderText">
                            联系电话：
                            <a href={`tel:${leaderPhone}`}>{leaderPhone}</a>
                          </span>
                        ) : null}
                      </p>
                    ) : null}
                  </div>
                ),
              });
            }}
          >
            {clubName}
          </Button>
        );
      },
    },
    {
      title: '姓名',
      width: 50,
      dataIndex: 'formPlayers',
      key: 'name',
      render: (formPlayers: any) => {
        return formPlayers.map((player: any) => {
          return (
            <Tag className="EnrollmentResultNameTag" color="processing">
              {player.name}
            </Tag>
          );
        });
      },
    },
    {
      title: '组别',
      width: 50,
      dataIndex: 'categoryName',
      key: 'categoryName',
      align: 'center',
      render: (_, record) => (
        <span>
          {record.categoryName} ( {record.optionName} )
        </span>
      ),
    },
    {
      title: '操作',
      width: 50,
      dataIndex: 'verified',
      key: 'verified',
      align: 'center',
      render: (_, record) => (
        <Button
          type="text"
          onClick={() => {
            console.log(record);
            Modal.confirm({
              centered: true,
              title: '确定取消该分组吗？',
              okText: '确认',
              cancelText: '取消',
              onOk: () => {
                const setDataFunc = () => {
                  setData(data.filter((item) => item.id !== record.id));
                };

                const payload = {
                  id: record.id,
                  enrollmentId: record.enrollmentId,
                  formId: record.formId,
                };
                dispatch(
                  enrollmentResultActions.setVerificationModalData(payload)
                );
                dispatch(
                  removeEnrollmentResultItem({
                    verificationModalData: payload,
                    selectedPaidByOrderId: '',
                    setDataFunc,
                  }) as any
                );
              },
            });
          }}
        >
          <Tag color="processing">取消分组</Tag>
        </Button>
      ),
    },
  ];

  const initState = () => {
    setStep(0);
    setUnclassifiedData([]);
    setCategoryIndex(null);
    setPlayers([]);
    setIsLoadingData(false);
    setIsCreating(false);
  };

  useEffect(() => {
    setPlayers([]);
  }, [categoryIndex]);

  useEffect(() => {
    setData([]);
    initState();

    if (!isModalVisible) {
      onClose();
    }
  }, [isModalVisible, onClose]);

  useEffect(() => {
    initState();
  }, [isPopoverVisible]);

  const fetchUnclassifiedPlayers = async () => {
    if (id) {
      setIsLoadingData(true);
      const res = await dispatch(loadUnclassifiedPlayers(id));
      setIsLoadingData(false);

      if (res.payload) {
        setUnclassifiedData(
          res.payload.map((unclassifiedItem: any) => {
            const { optionName } = unclassifiedItem || {};
            const playersNumber = Number(optionName.replace(/[^0-9]/gi, ''));

            if (playersNumber > 0) {
              return { ...unclassifiedItem, teamPlayers: playersNumber };
            }

            return unclassifiedItem;
          })
        );
      }
    }
  };

  const createTeam = async () => {
    if (id && merchantName && players.length && isNumber(categoryIndex)) {
      setIsCreating(true);
      const res = await dispatch(
        createTeamById({
          category: unclassifiedData[categoryIndex],
          merchantName,
          id,
          players,
        })
      );
      setIsCreating(false);
      if (res.payload) {
        setData(data.concat(res.payload));
        setIsPopoverVisible(false);
      }
    }
  };

  const stepsContent = isLoadingData ? (
    <Spin />
  ) : (
    <div className="GroupingStepsContainer">
      <Steps
        current={step}
        className="GroupingSteps"
        onChange={(newStep) => {
          isNumber(categoryIndex) && setStep(newStep);
        }}
      >
        <Steps.Step title="选择比赛项目" />
        <Steps.Step title="选择运动员" />
      </Steps>
      <div className="GroupingStepContent">
        {step === 0 ? (
          unclassifiedData.map(({ displayName }, index) => (
            <Button
              className="NewEnrollmentButton"
              size="small"
              onClick={() => {
                setStep(step + 1);
                setCategoryIndex(index);
              }}
              key={`${displayName}${index}`}
              type={index === categoryIndex ? 'primary' : 'ghost'}
            >
              <span className="GroupingCategory">{displayName}</span>
            </Button>
          ))
        ) : isNumber(categoryIndex) ? (
          <>
            {unclassifiedData[categoryIndex]?.formPlayers?.map(
              ({ id, name, form }: any) => {
                const { teamPlayers }: any =
                  unclassifiedData[categoryIndex] || {};
                const disabled =
                  players?.length === teamPlayers && !players.includes(id);

                return (
                  <Button
                    type={players.includes(id) ? 'primary' : 'ghost'}
                    disabled={disabled}
                    key={id}
                    onClick={() => {
                      if (players.includes(id)) {
                        setPlayers(
                          players.filter((playerId) => playerId !== id)
                        );
                      } else {
                        setPlayers(players.concat(id));
                      }
                    }}
                    className="NewEnrollmentButton GroupingPlayerButton"
                  >
                    <p className="GroupingPlayerName">{name}</p>
                    <span>{form?.name}</span>
                  </Button>
                );
              }
            )}
            <div className="CreateTeamButtonContainer">
              <Button
                size="large"
                type="primary"
                className="NewEnrollmentButton CreateTeamButton"
                disabled={
                  players.length !== unclassifiedData[categoryIndex].teamPlayers
                }
                onClick={createTeam}
                loading={isCreating}
              >
                创建
              </Button>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );

  return (
    <>
      <Button
        className="NewEnrollmentButton"
        onClick={() => setIsModalVisible(true)}
      >
        <NodeIndexOutlined className="NewEnrollmentButtonOutlinedIcon" />
        <span className="NewEnrollmentButtonText">选手分组</span>
      </Button>
      <Modal
        centered
        title="选手分组"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        destroyOnClose
        width={1200}
        className="GroupingComponentModal"
        footer={false}
      >
        <Table
          rowKey={(record: any) => {
            return record.id;
          }}
          columns={teamsColumns}
          dataSource={data}
          pagination={false}
          scroll={{ y: 400 }}
        />
        <Popover
          placement="bottom"
          content={stepsContent}
          trigger="click"
          visible={isPopoverVisible}
          onVisibleChange={(visible) => setIsPopoverVisible(visible)}
          afterVisibleChange={(visible) => {
            !visible && initState();
          }}
        >
          <Button
            className="GroupingCreateButton"
            onClick={() => !isPopoverVisible && fetchUnclassifiedPlayers()}
          >
            <PlusOutlined />
            添加分组
          </Button>
        </Popover>
      </Modal>
    </>
  );
};

export default GroupingComponent;
