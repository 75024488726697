import { get, put, deleteRequest } from '../../../../../../../../service/api';
import { AssociationPlayerDetailData } from './types';

export const getAssociationPlayerById = async (
  merchantName: string,
  id: string
) => {
  try {
    const result = await get(
      `/merchants/${merchantName}/association-players/${id}`,
      {},
      {},
      {}
    );

    return result;
  } catch (error) {
    throw error;
  }
};

export const putAssociationPlayer = async (
  merchantName: string,
  id: string,
  payload: AssociationPlayerDetailData
) => {
  try {
    const result = await put(
      `/merchants/${merchantName}/association-players/${id}`,
      payload,
      {}
    );

    return result;
  } catch (error) {
    throw error;
  }
};

export const putAssociationPlayerClub = async (
  merchantName: string,
  id: string,
  payload: { toClubId: string }
) => {
  try {
    const result = await put(
      `/merchants/${merchantName}/association-players/${id}/transfer`,
      payload,
      {}
    );

    return result;
  } catch (error) {
    throw error;
  }
};

export const delAssociationPlayerById = async (
  merchantName: string,
  id: string
) => {
  try {
    const result = await deleteRequest(
      `/merchants/${merchantName}/association-players/${id}`,
      {},
      {}
    );

    return result;
  } catch (error) {
    throw error;
  }
};
