/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { Statistic } from 'antd';
import './StatisticCard.less';

export default function StatisticCard({
  title = '',
  value = 0,
  suffix = '',
  color = '',
  valueStyle = {},
}) {
  return (
    <div className="StatisticCard" style={{ color }}>
      <Statistic
        title={title}
        value={value}
        suffix={suffix}
        valueStyle={{ color, ...valueStyle }}
      />
    </div>
  );
}
