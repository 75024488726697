import { get } from '../../../service/api';

export const getReport = async (storeId: string) => {
  try {
    const result = await get(
      `/reports/dashboard`,
      { storeId },
      {},
      { isShow: false }
    );

    return result;
  } catch (e) {
    throw new Error('Failed to get report');
  }
};
