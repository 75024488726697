import React from 'react';
import { Input, Modal, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../common/utils/types';
import {
  getVerifyEmployeeModalVisibility,
  getActionName,
} from '../../redux/common/selectors';
import { verifyEmployee } from '../../redux/common/thunks';
import { actions as playerCommonActions } from '../../redux/common';
import PhoneNumberInput from '../../../../common/components/PhoneNumberInput';

const EmployeeVerifyModal = ({
  nextStepFunction,
}: {
  nextStepFunction: () => void;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const [accountName, setAccountName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const verifyEmployeeModalVisibility = useSelector(
    getVerifyEmployeeModalVisibility
  );
  const actionName = useSelector(getActionName);
  const handleVerifyEmployee = async () => {
    try {
      await form.validateFields();
      await dispatch(
        verifyEmployee({ accountName, password, nextStepFunction })
      );
      dispatch(playerCommonActions.hideVerifyEmployeeModal());
    } catch (error) {
      throw error;
    }
  };
  const handleCancel = () => {
    resetData();
    dispatch(playerCommonActions.hideVerifyEmployeeModal());
  };
  const resetData = async () => {
    await form.resetFields();
  };

  return (
    <Modal
      centered
      destroyOnClose
      title="请先验证管理员身份"
      visible={verifyEmployeeModalVisibility}
      onOk={handleVerifyEmployee}
      onCancel={handleCancel}
      afterClose={resetData}
    >
      <p>
        {actionName}
        属非正常流程，为保证安全性，请输入管理员账号密码以继续执行操作
      </p>
      <Form form={form}>
        <PhoneNumberInput
          name="accountName"
          placeholder="请输入账号（手机号码）"
          phoneNumber={accountName}
          onChange={(phoneNumber: string) => {
            setAccountName(phoneNumber);
          }}
        />
        <Form.Item name="password" rules={[{ required: true }]}>
          <Input
            type="password"
            value={password}
            placeholder="请输入密码"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EmployeeVerifyModal;
