import _get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../../common/utils/types';
import { getFormatLocalTime } from '../../../../../../../common/utils/time-lib';

export const getHistoryPlayersAchievementImportStateData = (state: RootState) =>
  state.association.histories.playersAchievementImport;

export const getHistoryPlayersAchievementImportTaskList = createSelector(
  getHistoryPlayersAchievementImportStateData,
  (historyPlayersAchievementImportStateData) => {
    const tasks = _get(
      historyPlayersAchievementImportStateData,
      'data.tasks',
      []
    );

    return tasks.map((task: any) => ({
      key: task.id,
      id: task.id,
      createdTime: getFormatLocalTime(task.createdTime),
      operatorName: task.operatorName,
      status: task.status,
      fileUrl: task.fileUrl,
    }));
  }
);

export const getHistoryPlayersAchievementImportTasksPageNumber = createSelector(
  getHistoryPlayersAchievementImportStateData,
  (historyPlayersAchievementImportStateData) =>
    _get(historyPlayersAchievementImportStateData, 'data.pageNumber', [])
);

export const getHistoryPlayersAchievementImportTasksPageSize = createSelector(
  getHistoryPlayersAchievementImportStateData,
  (historyPlayersAchievementImportStateData) =>
    _get(historyPlayersAchievementImportStateData, 'data.pageSize', [])
);

export const getHistoryPlayersAchievementImportTasksPagination = createSelector(
  getHistoryPlayersAchievementImportTasksPageNumber,
  getHistoryPlayersAchievementImportTasksPageSize,
  (pageNumber, pageSize) => ({
    offset: pageNumber * pageSize,
    limit: pageSize,
  })
);

export const getHistoryPlayersAchievementImportTasksTotal = createSelector(
  getHistoryPlayersAchievementImportStateData,
  (historyPlayersAchievementImportStateData) =>
    _get(
      historyPlayersAchievementImportStateData,
      'data.totalHistoryPlayersAchievementImportTasks',
      []
    )
);

export const getHistoryPlayersAchievementImportTemplateHeaders = createSelector(
  getHistoryPlayersAchievementImportStateData,
  (historyPlayersAchievementImportStateData) =>
    _get(historyPlayersAchievementImportStateData, 'data.headers', [])
);

export const getHistoryPlayersAchievementImportTournamentTemplateId =
  createSelector(
    getHistoryPlayersAchievementImportStateData,
    (historyPlayersAchievementImportStateData) =>
      _get(
        historyPlayersAchievementImportStateData,
        'data.associationHistoryTournamentTemplateId',
        null
      )
  );

export const getHistoryPlayersAchievementImportTournamentTemplateName =
  createSelector(
    getHistoryPlayersAchievementImportStateData,
    (historyPlayersAchievementImportStateData) =>
      _get(
        historyPlayersAchievementImportStateData,
        'data.associationHistoryTournamentTemplateName',
        null
      )
  );
