import { get, post } from '../../../../service/api';

export const postPreviewOrder = (
  data: {
    merchantName: string;
    planId: string;
    purchaseType: string;
    addonStoreCount: number;
    paymentMethod: string;
    paymentProvider: string;
    client: string;
  },
  options = { isShow: true }
) => post(`/subscription-orders/preview`, data, options);

export const postSubscriptionOrders = (
  data: {
    merchantName: string;
    planId: string;
    purchaseType: string;
    addonStoreCount: number;
    paymentMethod: string;
    paymentProvider: string;
    client: string;
    agreementInfo: {
      companyName: string;
      companyAddress: string;
      principalName: string;
    };
  },
  options = { isShow: true }
) => post(`/subscription-orders/`, data, options);

export const getSubscriptionOrderList = (
  parameters: {
    merchantName: string;
    offset: number;
    limit: number;
  },
  options = { isShow: true }
) => get(`/subscription-orders`, parameters, options);

export const getSubscriptionOrderStatus = (
  orderId: string,
  options = { isShow: true }
) => get(`/subscription-orders/${orderId}`, {}, options);

export const getHistoryAgreement = async (id: string) => {
  try {
    const result: any = await get(
      `subscription-orders/${id}/agreement`,
      {},
      {}
    );

    return result;
  } catch (error) {
    throw error;
  }
};
