import React from 'react';
import { useUnmount } from 'react-use';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Button, Form, Input, Space } from 'antd';
import { AppDispatch } from '../../../../common/utils/types';
import { actions as newPlayerActions } from '../../redux/newPlayer';
import {
  getName,
  getPhone,
  getRemarkName,
  getRemarks,
} from '../../redux/newPlayer/selectors';
import { addTheNewPlayer } from '../../redux/newPlayer/thunks';
import ContentDetail from '../../../../common/components/ContentDetail';
import './index.less';
import BreadcrumbRouter from '../../../../common/components/BreadcrumbRouter';
import PhoneNumberInput from '../../../../common/components/PhoneNumberInput';

const NewPlayer = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const remarkName = useSelector(getRemarkName);
  const phone = useSelector(getPhone);
  const name = useSelector(getName);
  const remarks = useSelector(getRemarks);

  useUnmount(async () => {
    await dispatch(
      newPlayerActions.setPlayerInfo({
        name: '',
        phone: '',
        remarkName: '',
        remarks: '',
      })
    );
    await form.resetFields();
  });

  return (
    <ContentDetail
      title="会员管理/新增会员"
      headerLeft={
        <BreadcrumbRouter
          routes={[
            {
              path: '/players',
              breadcrumbName: '会员管理',
            },
            {
              path: 'new-player',
              breadcrumbName: '新增会员',
            },
          ]}
        />
      }
    >
      <section className="NewPlayerContainer">
        <Form form={form} layout="vertical" preserve={false}>
          <Form.Item
            name="remarkName"
            label="会员备注名称"
            rules={[{ required: true, message: '请输入会员备注名称' }]}
          >
            <Input
              allowClear
              placeholder="请输入会员备注名称"
              value={remarkName}
              onChange={(e) => {
                dispatch(newPlayerActions.setRemarkName(e.target.value));
              }}
            />
          </Form.Item>
          <PhoneNumberInput
            name="phone"
            label="会员手机号码"
            placeholder="请输入会员的手机号码"
            phoneNumber={phone}
            onChange={(phone: string) => {
              dispatch(newPlayerActions.setPhone(phone));
            }}
          />
          <Form.Item name="name" label="会员昵称">
            <Input
              placeholder="请输入会员昵称"
              value={name}
              onChange={(e) => {
                dispatch(newPlayerActions.setName(e.target.value));
              }}
              allowClear
            />
          </Form.Item>
          <Form.Item name="remarks" label="会员备注">
            <Input
              placeholder="请输入会员备注"
              value={remarks}
              onChange={(e) => {
                dispatch(newPlayerActions.setRemarks(e.target.value));
              }}
            />
          </Form.Item>
          <Space size={10}>
            <Button
              type="primary"
              className="operateButton"
              onClick={async () => {
                try {
                  await form.validateFields();

                  dispatch(addTheNewPlayer() as any);
                } catch (error) {
                  throw error;
                }
              }}
            >
              保存
            </Button>
            <Button
              type="default"
              className="operateButton cancelButton"
              onClick={() => {
                navigate(-1);
              }}
            >
              返回
            </Button>
          </Space>
        </Form>
      </section>
    </ContentDetail>
  );
};

export default NewPlayer;
