import _get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../common/utils/types';
import { getStoresMapping } from '../../../../redux/entities/stores/selectors';

export const getEmployeeFilterStoresData = (state: RootState) =>
  _get(state.employee, 'common', {});

export const getEmployeeFilterStoreIds = createSelector(
  getEmployeeFilterStoresData,
  (common) => _get(common, 'ids', [])
);

export const getEmployeeFilterStores = createSelector(
  getStoresMapping,
  getEmployeeFilterStoreIds,
  (allStoresMapping, employeeFilterStoreIds) =>
    employeeFilterStoreIds.map((id: string) => {
      return {
        value: allStoresMapping[id]?.id || '',
        label: allStoresMapping[id]?.displayName || '',
      };
    })
);

export const getEmployeeFilterStorePageNumber = createSelector(
  getEmployeeFilterStoresData,
  (common) => _get(common, 'pageNumber', 0)
);

export const getEmployeeFilterStorePageSize = createSelector(
  getEmployeeFilterStoresData,
  (common) => _get(common, 'pageSize', 0)
);

export const getEmployeeStoresFilterPagination = createSelector(
  getEmployeeFilterStorePageNumber,
  getEmployeeFilterStorePageSize,
  (pageNumber, pageSize) => {
    const offset: number = pageNumber * pageSize;
    const limit: number = pageSize;

    return {
      offset,
      limit,
    };
  }
);
