import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../common/utils/types';
import { getFormatLocalTime } from '../../../../../common/utils/time-lib';

export const getRankingTableDataSource = (state: RootState) =>
  state.ranking.management.tableDataSource;

export const getRankingManagementTableData = createSelector(
  getRankingTableDataSource,
  (rankingTableDataSource) => {
    return rankingTableDataSource?.map(
      (rowDataSource: {
        id: string;
        name: string | undefined;
        modifiedTime: string | undefined;
        enabled: boolean | undefined;
      }) => {
        const { id, name, modifiedTime, enabled } = rowDataSource;
        return {
          id,
          name,
          enabled,
          validModifiedTime: getFormatLocalTime(
            modifiedTime,
            'YYYY/MM/DD HH:mm:ss'
          ),
        };
      }
    );
  }
);

export const getRankingManagementPageNumber = (state: RootState) =>
  state.ranking.management.pageNumber;

export const getRankingManagementPageSize = (state: RootState) =>
  state.ranking.management.pageSize;

export const getRankingManagementTotalStores = (state: RootState) =>
  state.ranking.management.total;
