import { get, put } from '../../../../../service/api';

export const putActivity = async (payload: {
  merchantName: string;
  id: string;
  activity: any;
}) => {
  try {
    const { merchantName, id, activity } = payload;

    const result = await put(
      `/merchants/${merchantName}/activities/${id}`,
      activity,
      {
        isShow: false,
      }
    );

    return result;
  } catch (e) {
    console.error('put activity', e);

    throw e;
  }
};

export const getActivityById = async (payload: {
  merchantName: string;
  id: string;
}) => {
  try {
    const { merchantName, id } = payload;

    const result = await get(
      `/merchants/${merchantName}/activities/${id}`,
      {},
      {},
      { isShow: false }
    );

    return result;
  } catch (e) {
    console.error('get activity by id', e);

    throw e;
  }
};
