import _get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../../service/constants';
import { RootState } from '../../../../../common/utils/types';
import { getFormatLocalTime } from '../../../../../common/utils/time-lib';
import { getUserAuthorization } from '../../../../../redux/app-v2/user/selectors';
import { getMerchantName } from '../../../../../redux/app-v2/merchant/selectors';

export { getUserAuthorization, getMerchantName };

export const getOriginalPosterList = (state: RootState) =>
  _get(state.poster, 'list.posterList', []);

export const getPosterItemsTotal = (state: RootState) =>
  state.poster.list.itemsTotal;

export const getPosterListPaginateNumber = (state: RootState) =>
  state.poster.list.pageNumber;

export const getPosterListPageSize = (state: RootState) =>
  state.poster.list.pageSize;

export const getDuplicatePosterDetailStatus = (state: any) =>
  state.poster.list.duplicatePosterStatus;

export const getPosterList = createSelector(
  getOriginalPosterList,
  (posterList) =>
    posterList.map(
      ({ id, type, posterName, isEnabled, modifiedTime }: any) => ({
        id,
        type,
        posterName,
        isEnabled,
        displayModifiedTime: getFormatLocalTime(
          modifiedTime,
          'YYYY/MM/DD HH:mm:ss'
        ),
      })
    )
);

export const getIsPosterDetailDuplicating = createSelector(
  getDuplicatePosterDetailStatus,
  (duplicatePosterDetailStatus) =>
    duplicatePosterDetailStatus === API_REQUEST_STATUS.PENDING
);
