import CRouter from './routes';
import Index from './pages/index/index';
import './style/App.less';

function App() {
  // initialize the pages' router
  return <Index routers={<CRouter />} />;
}

export default App;
