import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMerchantName } from '../../../../../../../redux/app-v2/merchant/selectors';
import { errorMessage } from '../../../../../../../common/utils/feedback';
import {
  getHistoryPlayersAchievementImportTasks,
  getHistoryTempTournamentById,
} from './api-request';
import {
  getHistoryPlayersAchievementImportTasksPagination,
  getHistoryPlayersAchievementImportTasksPageSize,
  getHistoryPlayersAchievementImportTournamentTemplateId,
} from './selectors';

export const loadHistoryPlayersAchievementImportTasks = createAsyncThunk(
  'association/history/loadHistoryPlayersAchievementImportTasks',
  async (
    changedParameters: {
      limit?: number;
      offset?: number;
    },
    { getState }
  ) => {
    try {
      const state = getState() as any;
      const merchantName: string = getMerchantName(state);
      const pagination =
        getHistoryPlayersAchievementImportTasksPagination(state);
      const tournamentTemplateId =
        getHistoryPlayersAchievementImportTournamentTemplateId(state);
      const result: any = await getHistoryPlayersAchievementImportTasks(
        merchantName,
        {
          ...pagination,
          ...changedParameters,
          tempTournamentId: tournamentTemplateId,
        }
      );
      const { data } = result || {};

      return data;
    } catch (error) {
      errorMessage('获取导入任务列表失败');
    }
  }
);

export const updateHistoryPlayersAchievementImportTasksPageNumber =
  createAsyncThunk(
    'clubAuthentication/import/updateHistoryPlayersAchievementImportTasksPageNumber',
    async (pageNumber: number, { dispatch, getState }) => {
      try {
        const state: any = getState();
        const pageSize = getHistoryPlayersAchievementImportTasksPageSize(state);

        await dispatch(
          loadHistoryPlayersAchievementImportTasks({
            offset: pageNumber * pageSize,
          })
        );

        return pageNumber;
      } catch (error) {
        throw error;
      }
    }
  );

export const updateHistoryPlayersAchievementImportTasksPageSize =
  createAsyncThunk(
    'clubAuthentication/import/updateHistoryPlayersAchievementImportTasksPageSize',
    async (pageSize: number, { dispatch }) => {
      try {
        await dispatch(
          loadHistoryPlayersAchievementImportTasks({
            offset: 0,
            limit: pageSize,
          })
        );

        return {
          pageNumber: 0,
          pageSize,
        };
      } catch (error) {
        throw error;
      }
    }
  );

export const loadHistoryPlayerAchievementImportTempTournament =
  createAsyncThunk(
    'association/history/loadHistoryPlayerAchievementImportTempTournament',
    async (_, { getState }) => {
      try {
        const state = getState() as any;
        const merchantName: string = getMerchantName(state);
        const tournamentTemplateId =
          getHistoryPlayersAchievementImportTournamentTemplateId(state);
        const result: any = await getHistoryTempTournamentById(
          merchantName,
          tournamentTemplateId
        );
        const { data } = result || {};

        return data;
      } catch (error) {
        throw error;
      }
    }
  );
