import { createSelector } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../../service/constants';
import { WecomLoginStateTypes } from './types';

export const getLoginStatus = (state: { login: WecomLoginStateTypes }) =>
  state.login.loginStatus;

export const getIsLoginStatusPending = createSelector(
  getLoginStatus,
  (loginStatus) => loginStatus === API_REQUEST_STATUS.PENDING
);

export const getIsLoginStatusRejected = createSelector(
  getLoginStatus,
  (loginStatus) => loginStatus === API_REQUEST_STATUS.REJECTED
);
