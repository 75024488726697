/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  PlusCircleOutlined,
  CloseCircleOutlined,
  ProfileOutlined,
} from '@ant-design/icons';
import {
  Button,
  Space,
  Input,
  Form,
  Radio,
  Select,
  Checkbox,
  Modal,
  FormInstance,
  notification,
  InputNumber,
  message,
} from 'antd';
import { STATUS_OPTIONS, RANKING_OPTIONS, INTERVAL_OPTIONS } from './constants';
import {
  getCategoriesDisplayData,
  getIsRankingDetailsLoaded,
  getScoreLimitData,
  getStoreList,
} from './redux/selectors';
import { AppDispatch } from '../../../../redux/store';
import { useMount, useUnmount } from 'react-use';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useNavigate } from 'react-router-dom';
import {
  addNewRankingSettings,
  loadRankingSettingsById,
  loadStoresFilter,
  removeRankingSettingsById,
  updateRankingSettingsById,
} from './redux/thunks';
import ContentDetail from '../../../../common/components/ContentDetail';
import BreadcrumbRouter from '../../../../common/components/BreadcrumbRouter';
import './RankingDetail.less';
import { getRankingSettingData } from './redux/selectors';
import { actions as rankingDetailActions } from './redux';
import { successMessage } from '../../../../common/utils/feedback';
import {
  getIsStoreManager,
  getUserBelongToStoreId,
} from '../../../../redux/app-v2/user/selectors';

const RankingDetails = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const storeList = useSelector(getStoreList);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const isRankingDetailsLoaded = useSelector(getIsRankingDetailsLoaded);
  const rankingSettings = useSelector(getRankingSettingData);
  const categories = useSelector(getCategoriesDisplayData);
  const scoreLimits: any = useSelector(getScoreLimitData);
  const userStoreId: string = useSelector(getUserBelongToStoreId);
  const userManagerStoreId = [userStoreId];
  const isStoreManager = useSelector(getIsStoreManager);
  const [isNew, setIsNew] = useState(false);

  const onChangeCheckBox = (checkedValues: CheckboxValueType[]) => {
    dispatch(
      rankingDetailActions.updateRankingSettings({
        timeInterval: checkedValues,
      })
    );
  };

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  const onChangScoreLimit = (values: { highest?: number; lowest?: number }) => {
    if (
      (scoreLimits.lowest as number) >= (values.highest as number) ||
      (values.lowest as number) >= (scoreLimits.highest as number)
    ) {
      message.error('左侧最小值大于右侧最大值');
    } else {
      dispatch(rankingDetailActions.updateScoreLimit(values));
    }
  };

  useMount(async () => {
    await dispatch(rankingDetailActions.resetAll());
    dispatch(loadStoresFilter());

    if (id) {
      dispatch(loadRankingSettingsById(id));
    } else {
      setIsNew(true);
    }
  });

  useUnmount(async () => {
    await dispatch(rankingDetailActions.resetAll());
  });

  if (!isRankingDetailsLoaded && !isNew) {
    return null;
  }

  return (
    <ContentDetail
      title="排行榜管理 / 排行榜详情"
      headerLeft={
        <BreadcrumbRouter
          routes={[
            {
              path: '/ranking-management',
              breadcrumbName: '排行榜管理',
            },
            {
              path: `${id}`,
              breadcrumbName: '排行榜详情',
            },
          ]}
        />
      }
      headerRight={
        <div className="RankingDetailCheckListButtonContainer">
          <Button
            className="RankingDetailCheckListButton"
            onClick={() => {
              navigate(`/ranking-management/${id}/ranking`);
            }}
          >
            <ProfileOutlined className="RankingDetailCheckListButtonProfileIcon" />
            <span className="RankingDetailCheckListButtonText">
              查看榜单数据
            </span>
          </Button>
        </div>
      }
    >
      <Modal
        title="请确认"
        visible={isModalVisible}
        onOk={() => {
          dispatch(removeRankingSettingsById(id));
          hideModal();
          navigate(-1);
        }}
        okText="确认删除"
        onCancel={hideModal}
        cancelText="取消"
      >
        <p>
          您将要删除现在的排行榜详情，一旦确认数据将永久删除，请确定是否要进行这项操作
        </p>
      </Modal>
      <section className="RankingDetailFormContainer">
        <Form
          style={{ maxWidth: 400 }}
          layout="vertical"
          form={form}
          key={id}
          ref={React.createRef<FormInstance>()}
          initialValues={{
            name: rankingSettings.name,
            description: rankingSettings.description,
            timeInterval: rankingSettings.timeInterval,
            categories,
            scoreLimits,
            ascending: rankingSettings.ascending,
            scoreUnit: rankingSettings.scoreUnit,
          }}
        >
          <Form.Item
            className="RankingDetailFormItem formItem"
            label="榜单名称"
            name="name"
            rules={[{ required: true, message: '榜单名称不能为空' }]}
          >
            <Input
              value={rankingSettings.name}
              onChange={(e) => {
                dispatch(
                  rankingDetailActions.updateRankingSettings({
                    name: e.target.value,
                  })
                );
              }}
              className="searchInput"
              allowClear
              style={{ marginTop: '8px' }}
            />
          </Form.Item>
          <Form.Item
            className="RankingDetailFormItem formItem"
            label="榜单描述"
            name="description"
            rules={[{ required: true, message: '榜单描述不能为空' }]}
          >
            <Input
              value={rankingSettings.description}
              onChange={(e) => {
                dispatch(
                  rankingDetailActions.updateRankingSettings({
                    description: e.target.value,
                  })
                );
              }}
              className="searchInput"
              allowClear
              style={{ marginTop: '8px' }}
            />
          </Form.Item>
          <Form.Item className="RankingDetailFormItem formItem" label="状态">
            <Radio.Group
              options={STATUS_OPTIONS}
              onChange={(e) => {
                dispatch(
                  rankingDetailActions.updateRankingSettings({
                    enabled: e.target.value === STATUS_OPTIONS[0].value,
                  })
                );
              }}
              value={
                rankingSettings.enabled
                  ? STATUS_OPTIONS[0].value
                  : STATUS_OPTIONS[1].value
              }
            />
          </Form.Item>
          {!isStoreManager ? (
            <Form.Item
              className="RankingDetailFormItem formItem"
              label="适用门店"
            >
              <Space size="middle">
                <Select
                  placeholder="不选择任何门店，此排行榜将显示在全部门店"
                  mode="multiple"
                  value={rankingSettings.storeIds}
                  allowClear={true}
                  className="RankingSettingsStoreSelector"
                  style={{ width: 330 }}
                  onChange={(value) => {
                    dispatch(
                      rankingDetailActions.updateRankingSettings({
                        storeIds: value,
                      })
                    );
                  }}
                >
                  {storeList.map((store: any) => (
                    <Select.Option key={store.id} value={store.id}>
                      {store.displayName}
                    </Select.Option>
                  ))}
                </Select>
              </Space>
            </Form.Item>
          ) : (
            <Form.Item
              className="RankingDetailFormItem formItem"
              label="适用门店"
            >
              <Space size="middle">
                <Select
                  mode="multiple"
                  value={
                    rankingSettings.storeIds.length
                      ? rankingSettings.storeIds
                      : userManagerStoreId
                  }
                  allowClear={true}
                  disabled={true}
                  className="RankingSettingsStoreSelector"
                >
                  {storeList.map((store: any) => (
                    <Select.Option key={store.id} value={store.id}>
                      {store.displayName}
                    </Select.Option>
                  ))}
                </Select>
              </Space>
            </Form.Item>
          )}
          <Form.Item
            className="RankingDetailFormItem formItem"
            label="榜单时限"
            name="timeInterval"
            rules={[{ required: true, message: '榜单时限不能为空' }]}
          >
            <Checkbox.Group
              options={INTERVAL_OPTIONS}
              value={rankingSettings.timeInterval}
              onChange={onChangeCheckBox}
            />
          </Form.Item>
          <Form.Item
            className="RankingDetailFormItem formItem"
            label="榜单子类"
            name="categories"
          >
            {categories.map((category: any) => (
              <div key={category.id}>
                <div
                  className="inputsInRow"
                  style={{ display: 'flex', marginTop: '12px' }}
                >
                  <span style={{ marginRight: '25px' }}>子类名称</span>
                  <Input
                    maxLength={5}
                    disabled={category.disable}
                    value={category.name}
                    onChange={(e) => {
                      dispatch(
                        rankingDetailActions.updateCategoryName({
                          currentName: category.name,
                          name: e.target.value,
                        })
                      );
                    }}
                    className="searchInput"
                    style={{ width: 83 }}
                  />
                </div>
                <div
                  className="inputsInRow flex flex-middle"
                  style={{ marginTop: '12px' }}
                >
                  <span style={{ marginRight: '25px' }}>子类项目</span>
                  {(category.displayCategoryTypes || []).map(
                    (categoryType: any) => (
                      <Input
                        key={categoryType.id}
                        value={categoryType.name}
                        disabled={categoryType.disable}
                        onChange={(e) => {
                          dispatch(
                            rankingDetailActions.updateCategoryTypeName({
                              name: category.name,
                              currentType: categoryType,
                              typeName: e.target.value,
                            })
                          );
                        }}
                        className="searchInput"
                        style={{ width: 83, marginRight: '25px' }}
                        suffix={
                          <CloseCircleOutlined
                            style={{
                              fontSize: 16,
                              color: '#FF0000',
                            }}
                            onClick={() => {
                              const updateCategoryType = () =>
                                dispatch(
                                  rankingDetailActions.updateCategoryTypeOutdated(
                                    {
                                      name: category.name,
                                      currentType: categoryType,
                                    }
                                  )
                                );

                              if (!categoryType.disable) {
                                updateCategoryType();
                              } else {
                                Modal.confirm({
                                  centered: true,
                                  title: `确定删除子类项目：${categoryType.name}吗？`,
                                  content:
                                    '删除后此项目将不再在小程序排行榜中显示',
                                  okText: '确认',
                                  cancelText: '取消',
                                  onOk: () => {
                                    updateCategoryType();
                                  },
                                });
                              }
                            }}
                          />
                        }
                      />
                    )
                  )}
                  <Button
                    type="primary"
                    ghost
                    style={{ border: '0', boxShadow: 'none' }}
                  >
                    <PlusCircleOutlined
                      className="addButtonText"
                      disabled
                      onClick={() => {
                        if (!category.name) {
                          notification.warn({
                            message: '不能添加子类项目',
                            description: '请先填写子类名称',
                          });
                        } else {
                          dispatch(
                            rankingDetailActions.addNewCategoryType({
                              currentType: {
                                name: '',
                              },
                              name: category.name,
                            })
                          );
                        }
                      }}
                    />
                  </Button>
                </div>
              </div>
            ))}
          </Form.Item>
          <Form.Item
            className="RankingDetailFormItem formItem"
            label="计分限额"
            name="scoreLimits"
          >
            <div style={{ marginTop: '8px', display: 'flex' }}>
              <InputNumber
                defaultValue={0}
                min={0}
                max={999999}
                value={scoreLimits?.lowest}
                onChange={(value) => {
                  onChangScoreLimit({ lowest: value });
                }}
                className="searchInput"
                style={{ width: 83 }}
              />
              <div style={{ margin: '0px 25px' }}>至</div>
              <InputNumber
                defaultValue={9999}
                min={0}
                max={999999}
                type="number"
                value={scoreLimits?.highest}
                onChange={(value) => {
                  onChangScoreLimit({ highest: value });
                }}
                className="searchInput"
                style={{ width: 83 }}
              />
            </div>
          </Form.Item>
          <Form.Item
            className="RankingDetailFormItem formItem"
            label="排名方式"
            name="ascending"
          >
            <Radio.Group
              options={RANKING_OPTIONS}
              onChange={(e) => {
                dispatch(
                  rankingDetailActions.updateRankingSettings({
                    ascending: e.target.value === RANKING_OPTIONS[0].value,
                  })
                );
              }}
              value={
                rankingSettings.ascending
                  ? RANKING_OPTIONS[0].value
                  : RANKING_OPTIONS[1].value
              }
            />
          </Form.Item>
          <Form.Item
            className="RankingDetailFormItem formItem"
            label="计分单位"
            name="scoreUnit"
            rules={[{ required: true, message: '计分单位不能为空' }]}
          >
            <Input
              value={rankingSettings.scoreUnit}
              maxLength={4}
              onChange={(e) => {
                dispatch(
                  rankingDetailActions.updateRankingSettings({
                    scoreUnit: e.target.value,
                  })
                );
              }}
              className="unitInput"
              style={{ width: 83 }}
            />
          </Form.Item>

          <Form.Item style={{ marginBottom: '20px' }}>
            <Space size="middle">
              <Button
                type="primary"
                htmlType="submit"
                className="operateButton"
                onClick={async () => {
                  try {
                    await form.validateFields();

                    if (id) {
                      dispatch(updateRankingSettingsById(id));
                    } else {
                      await dispatch(addNewRankingSettings());

                      successMessage('新增榜单详情成功', {
                        handleConfirm: () => {
                          navigate(-1);
                        },
                      });
                    }
                  } catch (error) {
                    throw error;
                  }
                }}
              >
                保存
              </Button>
              <Button
                type="default"
                className="cancelButton"
                style={{ marginLeft: '0px' }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                返回
              </Button>
              {isNew ? null : (
                <Button
                  type="primary"
                  danger
                  className="deleteButton"
                  onClick={showModal}
                >
                  删除
                </Button>
              )}
            </Space>
          </Form.Item>
        </Form>
      </section>
    </ContentDetail>
  );
};

export default RankingDetails;
