import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Space, SpaceProps, Table, Tooltip } from 'antd';
import { UpOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { TARGET_LEGEND_COMPETITION_HALF } from '../../../utils/constants';
import {
  getCreateGameRequestData,
  getGameDetailData,
  getIsCreateTargetsSettingModal,
  getIsTargetsSettingEnabled,
  getTargetLegends,
} from '../../../redux/selectors';
import SetTargetTypePopover from './SetTargetTypePopover';
import { useDrag, DragPreviewImage, useDragDropManager } from 'react-dnd';
import { DraggingImage } from '../../../../../../../../../images/dragging-image';
import { updateGameDetailSummary } from '../../../redux/thunks';
const TournamentLegend = () => {
  const dispatch = useDispatch();
  const createGameRequestData = useSelector(getCreateGameRequestData);
  const gameDetailData = useSelector(getGameDetailData);
  const isTargetsSettingEnabled = useSelector(getIsTargetsSettingEnabled);

  const isCreateTargetsSettingModal = useSelector(
    getIsCreateTargetsSettingModal
  );

  const gameData = isCreateTargetsSettingModal
    ? createGameRequestData
    : gameDetailData;

  const targetLegends = useSelector(getTargetLegends);
  const [allShown, setAllShown] = useState(false);
  const legendsFormatTableData = targetLegends.map(
    (
      targetLegend: {
        categoryName: string;
        optionName: string;
        color: string;
        disabledColor: string;
        rounds: object[];
        enrolledCount: number;
        teamPlayerCount: number;
      },
      index: number
    ) => {
      const { categoryName, optionName, color, rounds, disabledColor } =
        targetLegend;
      const enrollmentSummary = (gameData.enrollmentSummary as any[])?.find(
        (e) => e.categoryName === categoryName && e.optionName === optionName
      );
      return {
        key: `${categoryName}-${optionName}-${index}`,
        project: `${categoryName}${optionName}`,
        rounds,
        color,
        disabledColor,
        enrolledCount: targetLegend.enrolledCount,
        teamPlayerCount: targetLegend.teamPlayerCount,
        targetLegend,
        enrollmentSummary,
      };
    }
  );
  const columns = [
    {
      title: '项目',
      dataIndex: 'project',
      key: 'project',
      className: 'TournamentLegendProjectColumn',
      width: 250,
      render: (project: string, record: any) => {
        const { color, enrolledCount, teamPlayerCount } = record;
        const playerCountString =
          teamPlayerCount <= 1 ? `${enrolledCount}人` : `${enrolledCount}队`;
        const splitFinalRound = record.enrollmentSummary?.splitFinalRound;

        const canSplitOrMergeFinalRound = !record.rounds.some(
          (r: any) => r.isSelected
        );
        const hasFinalRound = record.rounds.some((r: any) => !r.isQualifying);
        return (
          <div
            className="TournamentLegendRoundTitle"
            style={{
              color,
            }}
          >
            <div>
              {`${project} (${playerCountString})  `}
              <Tooltip
                title={`${
                  isTargetsSettingEnabled
                    ? canSplitOrMergeFinalRound
                      ? hasFinalRound
                        ? '拆分后金牌赛和铜牌赛不能设置在同一轮'
                        : '此项目不能设置'
                      : '已设置靶位无法更改'
                    : '现阶段无法更改'
                }`}
              >
                <InfoCircleOutlined />
              </Tooltip>
            </div>
            <Button
              type="primary"
              style={
                isTargetsSettingEnabled &&
                canSplitOrMergeFinalRound &&
                hasFinalRound
                  ? { background: color, border: 'none' }
                  : {}
              }
              size="small"
              disabled={
                !canSplitOrMergeFinalRound ||
                !hasFinalRound ||
                !isTargetsSettingEnabled
              }
              onClick={() =>
                dispatch(
                  updateGameDetailSummary({
                    ...record.enrollmentSummary,
                    splitFinalRound: !record.enrollmentSummary.splitFinalRound,
                  }) as any
                )
              }
            >
              {`${splitFinalRound ? '合并' : '拆分'}决赛`}
            </Button>
          </div>
        );
      },
    },
    {
      title: '比赛阶段',
      dataIndex: 'rounds',
      key: 'rounds',
      className: 'TournamentLegendProjectColumn',
      render: (rounds: any[], record: any) => (
        <LegendProjects
          key={`Legend_Projects_${record.key}_${record.optionName}`}
          rounds={rounds}
          record={record}
        />
      ),
    },
  ];
  const handleAllShownButtonClick = useCallback(() => {
    setAllShown(!allShown);
  }, [allShown]);

  return (
    <section
      id="TournamentLegend"
      className="TournamentLegend flex flex-middle flex-space-between"
    >
      <Table
        bordered={false}
        pagination={false}
        className={`TournamentLegendTable flex__fluid-content${
          legendsFormatTableData.length > 4
            ? ' TournamentLegendTableAllShownAnimationInitial'
            : ''
        }${allShown ? ' TournamentLegendTableAllShown' : ''}`}
        style={
          allShown
            ? {
                maxHeight: '100%',
                height: `${70 * (legendsFormatTableData.length + 1)}px`,
                overflow: 'auto',
                transformOrigin: 'top',
                transition: 'all 0.2s ease-in-out',
              }
            : { maxHeight: '100%' }
        }
        columns={columns}
        dataSource={legendsFormatTableData}
      />

      {legendsFormatTableData.length > 4 && (
        <Button
          type="text"
          className="TournamentLegendTableButton flex__shrink-fixed"
          onClick={handleAllShownButtonClick}
        >
          <UpOutlined
            className={`TournamentLegendTableButtonIcon ${
              allShown ? 'TournamentLegendTableCollapseButtonIcon' : ''
            }`}
          />
          <span>{allShown ? '收起' : '全部'}</span>
        </Button>
      )}
    </section>
  );
};

TournamentLegend.displayName = 'TournamentLegend';

export default TournamentLegend;

interface LegendProjectsProps extends SpaceProps {
  rounds: any[];
  record: any;
}

const LegendProjects: React.FC<LegendProjectsProps> = ({ rounds, record }) => {
  return (
    <>
      <Space key={`Projects_${record.key}`} className="flex flex-middle">
        {rounds.map((round, index) => {
          return (
            <div key={`Projects_item_${record.key}_${index}`}>
              <LegendProjectItem record={record} round={round} />
            </div>
          );
        })}
      </Space>
    </>
  );
};

interface LegendProjectItemProps {
  round: any;
  record: any;
}
const LegendProjectItem: React.FC<LegendProjectItemProps> = ({
  round,
  record,
}) => {
  const { isSelected, targetNumChildScope } = round;
  const targetType = useRef<string[]>(targetNumChildScope);

  const [popover, setPopover] = React.useState(false);

  useEffect(() => {
    targetType.current = targetNumChildScope;
  }, [targetNumChildScope]);

  return (
    <>
      <SetTargetTypePopover
        visible={popover}
        onVisibleChange={setPopover}
        onFinishPicker={(t) => {
          targetType.current = t.targetNumChildScope;
        }}
      />
      <DragItem
        record={record}
        round={round}
        targetType={targetType}
        onClick={() => !isSelected && setPopover(true)}
      />
    </>
  );
};

const DragItem: React.FC<{
  round: any;
  record: any;
  targetType: any;
  onClick: () => void;
}> = ({ round, record, targetType, onClick }) => {
  const { categoryName, optionName, color, disabledColor, teamPlayerCount } =
    record.targetLegend;

  const {
    roundName,
    competitionHalf,
    participants,
    isSelected,
    roundNumber,
    isQualifying,
  } = round;
  const targetSet = Boolean(targetType.current.length > 0);
  const style = isSelected
    ? {
        backgroundColor: targetSet ? color : disabledColor,
        color: 'white',
      }
    : {
        color,
      };
  const [dragStyle, drag, preview] = useDrag(
    {
      type: 'targetSetting',
      item: {
        ...round,
        targetNumChildScope: targetType,
        archeryTournamentRoundSettings: {
          roundNumber,
          isQualifying,
          competitionHalf,
          categoryName,
          optionName,
          roundName,
          teamPlayerCount,
        },
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
        cursor: monitor.canDrag() ? 'pointer' : 'not-allowed',
      }),
      canDrag: (monitor) => {
        return targetSet && !isSelected;
      },
      previewOptions: {
        captureDraggingState: true,
        offsetY: 0,
        offsetX: 0,
        anchorY: 0,
        anchorX: 0,
      },
    },
    [round, record, isSelected, targetType]
  );

  return (
    <>
      <i
        className="flex flex-column TournamentLegendRoundName"
        ref={drag}
        onClick={onClick}
        style={{
          ...style,
          ...dragStyle,
          userSelect: 'none',
        }}
      >
        <DragPreviewImage connect={preview} src={DraggingImage} />
        <i>{`${roundName}${
          competitionHalf === TARGET_LEGEND_COMPETITION_HALF.FIRSTHALF
            ? '(上)'
            : competitionHalf === TARGET_LEGEND_COMPETITION_HALF.SECONDHALF
            ? '(下)'
            : ''
        } (${participants}${teamPlayerCount > 1 ? '组' : '人'})`}</i>
        <i>{`${
          targetSet
            ? `${targetType.current.join('')}(需${Math.ceil(
                participants / targetType.current.length
              )}个靶)`
            : '靶位未设置'
        }`}</i>
      </i>
    </>
  );
};
