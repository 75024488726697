import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'antd';
import { publicIp } from '../../../../service/apiIP';
import { AppDispatch } from '../../../../common/utils/types';
import {
  getSelectedMemberCardImageUrl,
  getIsUploadingSkinImagePending,
  getSelectedMemberCardType,
  getSelectedMemberCardDescription,
} from '../../redux/memberCardDetails/selectors';
import {
  getMerchantName,
  getUserAuthorization,
} from '../../redux/common/selectors';
import { actions as memberCardDetailsActions } from '../../redux/memberCardDetails';
import {
  hideMemberCardDetails,
  createMemberCard,
} from '../../redux/memberCardDetails/thunks';
import MemberCardModal from '../MemberCardModal';

const NewMemberCardModal = ({ imageUrl }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const merchantName = useSelector(getMerchantName);
  const userAuthorization = useSelector(getUserAuthorization);
  const memberCardImageUrl = useSelector(getSelectedMemberCardImageUrl);
  const isUploadingSkinImagePending = useSelector(
    getIsUploadingSkinImagePending
  );
  const memberCardType = useSelector(getSelectedMemberCardType);
  const memberCardDescription = useSelector(getSelectedMemberCardDescription);
  const [newMemberCardModalVisible, setNewMemberCardModalVisible] =
    useState(false);

  return (
    <>
      <Button
        className="memberCardAddButton"
        onClick={() => {
          setNewMemberCardModalVisible(true);
        }}
      >
        {imageUrl}
      </Button>
      <MemberCardModal
        closable
        title="新建会员卡"
        okText="新建"
        imageUrl={memberCardImageUrl}
        isUploadingSkinImagePending={isUploadingSkinImagePending}
        imageCoverText="点击更换图片"
        cardType={memberCardType}
        description={memberCardDescription}
        deleted={false}
        visible={newMemberCardModalVisible}
        okButtonProps={{ disabled: false }}
        uploadImageAction={`${publicIp}/merchants/${merchantName}/assets/member-cards/setting/types/new/pic`}
        uploadImageHeaders={{
          Authorization: userAuthorization,
        }}
        changeImage={(currentInfo: any) => {
          dispatch(
            memberCardDetailsActions.cardSkinImageUploadStatusSet(
              currentInfo[0].status
            )
          );

          if (currentInfo[0].status === 'done') {
            const { imageUrl } = currentInfo[0].response.data;

            dispatch(
              memberCardDetailsActions.cardSkinImageUrlSet({
                skinImageUrl: imageUrl,
              })
            );
          }
        }}
        changeCardType={(e: any) => {
          dispatch(memberCardDetailsActions.cardTypeSet(e.target.value) as any);
        }}
        changeCardDescription={(e: any) => {
          dispatch(
            memberCardDetailsActions.cardDescriptionSet(e.target.value) as any
          );
        }}
        onOk={async () => {
          try {
            await dispatch(createMemberCard() as any);
            setNewMemberCardModalVisible(false);
          } catch (e) {
            throw e;
          }
        }}
        onCancel={() => {
          dispatch(hideMemberCardDetails() as any);
          setNewMemberCardModalVisible(false);
        }}
      />
    </>
  );
};

export default NewMemberCardModal;
