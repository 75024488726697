import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { ROUTE_LIST } from './constants';
import { IFMenuBase } from './types';
import Pages from './pages';
import { isFunction } from 'lodash';
import { useSelector } from 'react-redux';
import { getIsBaseball } from '../redux/app-v2/merchant/selectors';

const CRouter = () => {
  const isBaseball = useSelector(getIsBaseball);

  return (
    <Routes>
      {ROUTE_LIST.map((r: IFMenuBase) => {
        const Component = r.component && Pages[r.component];
        const title = isFunction(r.title) ? r.title(isBaseball) : r.title;

        return (
          <Route
            key={r.key}
            path={r.key}
            element={
              <DocumentTitle title={title}>
                <Component />
              </DocumentTitle>
            }
          />
        );
      })}
      <Route element={<Navigate to="/404" />} />
    </Routes>
  );
};

export default CRouter;
