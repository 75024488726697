/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Space, Select, DatePicker, DatePickerProps } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import TableResponsiveFrame from '../../../../common/components/tables/TableResponsiveFrame';
import { ContentTableColumnPropsType } from '../../../../common/components/tables/ContentTable/types';
import ContentDetail from '../../../../common/components/ContentDetail';
import BreadcrumbRouter from '../../../../common/components/BreadcrumbRouter';
import { actions as rankingActions } from './redux';

import {
  getRankingListPageNumber,
  getRankingListPageSize,
  getRankingListTableData,
  getRankingListTotalItems,
  getSelectedCategoryTypeName,
  getSelectedTimeInterval,
  getRankingSettingsList,
  getStoreList,
  getTimeIntervalArray,
  getSelectedStoreId,
  getTimeStamp,
  getSelectedRankingSettingId,
  getCategoriesOptionData,
  getStoreIdForRankingSetting,
} from './redux/selectors';
import {
  loadRankingList,
  loadRankingSettingsById,
  loadRankSettingsList,
  loadStoresFilter,
} from './redux/thunks';
import moment from 'moment';
import { useMount } from 'react-use';
import { getIsStoreManager } from '../../../../redux/app-v2/user/selectors';
const TIME_MAPPING: any = {
  daily: 'date',
  weekly: 'week',
  monthly: 'month',
  allTime: 'allTime',
};

const ranking = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const storeList = useSelector(getStoreList);
  const pageNumber = useSelector(getRankingListPageNumber);
  const pageSize = useSelector(getRankingListPageSize);
  const dataTotal = useSelector(getRankingListTotalItems);
  const categories = useSelector(getCategoriesOptionData);
  const selectedCategoryTypeName = useSelector(getSelectedCategoryTypeName);
  const timeIntervalArray = useSelector(getTimeIntervalArray);
  const selectedTimeInterval = useSelector(getSelectedTimeInterval);
  const selectedRankingSettingId = useSelector(getSelectedRankingSettingId);
  const rankingListTableData: any = useSelector(getRankingListTableData);
  const rankingSettingsList = useSelector(getRankingSettingsList);
  const selectedStoreId = useSelector(getStoreIdForRankingSetting);
  const selectedTimeStamp = useSelector(getTimeStamp);
  const isStoreManager = useSelector(getIsStoreManager);
  const navigate = useNavigate();

  const onChangeStartDate: DatePickerProps['onChange'] = (date) => {
    if (!date) {
      dispatch(rankingActions.updateTimeStamp(null));
    } else {
      if (selectedTimeInterval === 'daily') {
        const timeStamp = (
          moment(date).startOf('day').unix() * 1000
        ).toString();
        dispatch(rankingActions.updateTimeStamp(timeStamp));
      } else if (selectedTimeInterval === 'weekly') {
        const timeStamp = (
          moment(date).startOf('isoWeek').isoWeekday(1).unix() * 1000
        ).toString();

        dispatch(rankingActions.updateTimeStamp(timeStamp));
      } else if (selectedTimeInterval === 'monthly') {
        const timeStamp = (
          moment(date).startOf('month').unix() * 1000
        ).toString();

        dispatch(rankingActions.updateTimeStamp(timeStamp));
      } else if (selectedTimeInterval === 'allTime') {
        const timeStamp = (
          moment(date).startOf('day').unix() * 1000
        ).toString();

        dispatch(rankingActions.updateTimeStamp(timeStamp));
      }
    }
  };

  const columns: ContentTableColumnPropsType[] = [
    {
      title: '录入时间',
      width: 120,
      dataIndex: 'recordedTime',
      key: 'recordedTime',
      align: 'center',
      mobileAlign: 'headerLeft',
    },
    {
      title: '排名',
      width: 50,
      dataIndex: 'rank',
      key: 'rank',
      align: 'center',
    },
    {
      title: '距离',
      width: 50,
      dataIndex: 'categoryValue',
      key: 'categoryValue',
      align: 'center',
    },
    {
      title: '会员',
      width: 120,
      dataIndex: 'player',
      key: 'player',
      align: 'center',
      mobileAlign: 'contentHeaderLeft',
      render: (player: any, row: any) => {
        return (
          <div
            onClick={() => {
              navigate(`/players/${row.playerId}`, {
                state: { id: row.playerId },
              });
            }}
            dangerouslySetInnerHTML={{ __html: player }}
          />
        );
      },
    },
    {
      title: '得分',
      width: 50,
      dataIndex: 'bestScore',
      key: 'bestScore',
      align: 'center',
    },
    {
      title: '门店',
      width: 50,
      dataIndex: 'stores',
      key: 'stores',
      align: 'center',
    },
  ];

  useMount(() => {
    dispatch(loadStoresFilter() as any);
    dispatch(loadRankSettingsList() as any);
  });

  useEffect(() => {
    if (id) {
      dispatch(loadRankingSettingsById(id) as any);
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (
      selectedTimeInterval ||
      selectedCategoryTypeName ||
      selectedTimeStamp ||
      selectedStoreId ||
      selectedRankingSettingId
    ) {
      dispatch(loadRankingList() as any);
    }
  }, [
    dispatch,
    selectedRankingSettingId,
    selectedTimeInterval,
    selectedCategoryTypeName,
    selectedTimeStamp,
    selectedStoreId,
  ]);

  return (
    <ContentDetail
      title="排行榜管理 / 排行榜详情 / 排名"
      headerLeft={
        <BreadcrumbRouter
          routes={[
            {
              path: '/ranking-management',
              breadcrumbName: '排行榜管理',
            },
            {
              path: `${id}`,
              breadcrumbName: '排行榜详情',
            },
            {
              path: `ranking`,
              breadcrumbName: '排名',
            },
          ]}
        />
      }
    >
      <div className="content__filter flex flex-middle">
        <div className="form__group">
          <label className="form__label">筛选:</label>
          <Space size="middle">
            <Select
              placeholder="默认榜单"
              value={selectedRankingSettingId || '默认榜单'}
              style={{ width: 98 }}
              onChange={(value) => {
                navigate(`/ranking-management/${value}/ranking`, {
                  replace: true,
                });
                dispatch(
                  rankingActions.updateSelectedRankingSettingId({
                    value,
                  })
                );
              }}
            >
              {rankingSettingsList.map((rankingSettingsItem: any) => (
                <Select.Option
                  key={rankingSettingsItem.id}
                  value={rankingSettingsItem.id}
                >
                  {rankingSettingsItem.name}
                </Select.Option>
              ))}
            </Select>
            <Select
              placeholder="不选择任何门店，此排行榜将显示在全部门店"
              value={selectedStoreId || null}
              allowClear={true}
              disabled={isStoreManager}
              style={{ width: 330}}
              onChange={(value) => {
                dispatch(
                  rankingActions.updateSelectedStoreId({
                    value,
                  })
                );
              }}
            >
              {storeList.map((store: any) => (
                <Select.Option key={store.id} value={store.id}>
                  {store.displayName}
                </Select.Option>
              ))}
            </Select>
            <Select
              placeholder="默认榜单时限"
              value={selectedTimeInterval || '默认榜单时限'}
              style={{ width: 98 }}
              onChange={(value) => {
                dispatch(
                  rankingActions.updateSelectedTimeInterval({
                    value,
                  })
                );
              }}
            >
              {timeIntervalArray.map((timeInterval: any) => (
                <Select.Option
                  key={timeInterval?.key}
                  value={timeInterval?.key}
                >
                  {timeInterval?.label}
                </Select.Option>
              ))}
            </Select>
            <Select
              placeholder="默认子类"
              value={selectedCategoryTypeName || '默认子类'}
              style={{ width: 98 }}
              onChange={(value) => {
                dispatch(
                  rankingActions.updateSelectedCategoryTypeName({
                    value,
                  })
                );
              }}
            >
              {(categories[0].displayCategoryTypes || []).map(
                (categoryType: any) => (
                  <Select.Option
                    key={categoryType.id}
                    value={categoryType.name}
                  >
                    {categoryType.name}
                  </Select.Option>
                )
              )}
            </Select>
            <DatePicker
              onChange={onChangeStartDate}
              style={{ marginLeft: '8px' }}
              picker={TIME_MAPPING[selectedTimeInterval]}
              defaultValue={moment(new Date())}
            />
          </Space>
        </div>
      </div>

      <TableResponsiveFrame
        columns={columns}
        pageNumber={pageNumber + 1}
        pageSize={pageSize}
        data={rankingListTableData}
        dataItemsTotal={dataTotal}
        onChangePageSize={async (_: number, pageSize: number) => {
          await dispatch(rankingActions.updatePageSize(pageSize));
        }}
        onChangePageNumber={async (pageNumber: number) => {
          await dispatch(rankingActions.updatePageNumber(pageNumber - 1));
        }}
      />
    </ContentDetail>
  );
};

export default ranking;
