/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { DatePicker, Button, Menu, Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { AppDispatch } from '../../../../redux/store';
import { useSelector, useDispatch } from 'react-redux';
import {
  getPageSize,
  getPaginateNum,
  getSelectStatus,
  getTableData,
  getTotal,
} from './redux/selectors';
import { setFilters, setPageSize, setPaginateNum } from './redux';
import {
  requestTableData,
  mounted,
  getTheAssets,
  getAllMerchandises,
} from './redux/thunks';
import {
  getIsManager,
  getIsOwner,
  getUserBelongToStoreId,
  getIsUserInitialized,
  getFilters,
} from './redux/selectors';
import TableResponsiveFrame from '../../../../common/components/tables/TableResponsiveFrame';
import './index.less';
import ContentDetail from '../../../../common/components/ContentDetail';
import BreadcrumbRouter from '../../../../common/components/BreadcrumbRouter';
import { useNavigate } from 'react-router-dom';
import { getIsOperationsManager } from '../../../../redux/app-v2/user/selectors';

const RangePicker: any = DatePicker.RangePicker;

export default function orders() {
  const columns: any = [
    {
      title: '时间',
      width: 180,
      dataIndex: 'createdTime',
      key: 'time',
      align: 'center',
    },
    {
      title: '订单号',
      width: 300,
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      align: 'center',
      mobileAlign: 'headerLeft',
    },
    {
      title: '状态',
      width: 90,
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      mobileAlign: 'contentHeaderRight',
    },
    {
      title: '会员',
      width: 200,
      dataIndex: 'playerName',
      key: 'playerName',
      align: 'center',
    },
    {
      title: '兑换方式',
      width: 160,
      dataIndex: 'exchangeByType',
      key: 'exchangeByType',
      align: 'center',
    },
    {
      title: '兑换物品',
      width: 160,
      dataIndex: 'exchangedType',
      key: 'exchangedType',
      align: 'center',
      mobileAlign: 'contentHeaderLeft',
    },
    {
      title: '总计',
      width: 160,
      dataIndex: 'total',
      key: 'total',
      align: 'center',
    },
  ];
  const filters = useSelector(getFilters);
  const isManager = useSelector(getIsManager);
  const isOwner = useSelector(getIsOwner);
  const isOperation = useSelector(getIsOperationsManager);
  const isUserInitialized = useSelector(getIsUserInitialized);
  const userBelongToStoreId = useSelector(getUserBelongToStoreId);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const dataSource = useSelector(getTableData);

  const paginateSize = useSelector(getPageSize);
  const paginateNum = useSelector(getPaginateNum);
  const total = useSelector(getTotal);

  const selectStatusArray = useSelector(getSelectStatus);

  const [today, setToday] = useState(
    moment().startOf('day').subtract(6, 'days').unix()
  );
  const [tomorrow, setTomorrow] = useState(moment().startOf('day').unix());
  const [searchTextByTimeFrom, setSearchTextByTimeFrom] = useState(
    filters.from || today
  );
  const [searchTextByTimeTo, setSearchTextByTimeTo] = useState(
    filters.to || tomorrow
  );

  const [selectedStatus, setSelectedStatus] = useState<Array<string>>(
    filters.selectedStatus || []
  );
  const [selectedExchangedType, setSelectedExchangedType] = useState<
    Array<string>
  >(filters.selectedExchangedType || []);

  const filterClick = (prop: string, data: string) => {
    if (prop === 'Status') {
      selectedStatus?.includes(data)
        ? setSelectedStatus([...selectedStatus.filter((item) => item !== data)])
        : setSelectedStatus([...(selectedStatus as string[]), data]);
    } else {
      selectedExchangedType?.includes(data)
        ? setSelectedExchangedType([
            ...selectedExchangedType.filter((item) => item !== data),
          ])
        : setSelectedExchangedType([
            ...(selectedExchangedType as string[]),
            data,
          ]);
    }
  };

  const selectStatusArrayList = (
    <Menu
      onClick={(e) => filterClick('Status', e.key)}
      items={selectStatusArray}
      selectedKeys={selectedStatus}
    />
  );

  const onRangePickerChange = (dates: any, dateStrings: [string, string]) => {
    setSearchTextByTimeFrom(moment(dateStrings[0]).unix());
    setSearchTextByTimeTo(moment(dateStrings[1]).add(1, 'days').unix());

    dispatch(setPaginateNum(1));
  };

  const onShowSizeChange = (current: number, pageSize: number) => {
    dispatch(setPageSize(pageSize));
  };

  const paginateChange = (page: number) => {
    dispatch(setPaginateNum(page));
  };

  useEffect(() => {
    dispatch(getTheAssets());
    dispatch(getAllMerchandises());
  }, []);

  useEffect(() => {
    dispatch(
      mounted({
        isManager,
        isOwner,
        userBelongToStoreId,
      })
    );
  }, [isManager, isOwner, userBelongToStoreId]);

  useEffect(() => {
    if (!isUserInitialized) {
      return;
    }
    let param_status: string = '';
    for (let item of selectedStatus) {
      param_status += item + ',';
    }
    let param_exchangedType: string = '';
    for (let item of selectedExchangedType) {
      param_exchangedType += item + ',';
    }
    const param = {
      from: searchTextByTimeFrom,
      to: searchTextByTimeTo,
      offset: (paginateNum - 1) * paginateSize,
      limit: paginateSize,
      status: param_status,
      exchangedType: param_exchangedType,
    };

    if (!isManager && !isOwner && !isOperation) {
      return;
    }

    //https request for search API;
    dispatch(requestTableData(param) as any);
  }, [
    searchTextByTimeFrom,
    searchTextByTimeTo,
    paginateNum,
    paginateSize,
    selectedStatus,
    selectedExchangedType,
    isManager,
    isUserInitialized,
  ]);

  return (
    <ContentDetail
      title="订单管理"
      headerLeft={
        <BreadcrumbRouter
          routes={[
            {
              path: '/redemption-market',
              breadcrumbName: '兑换商城',
            },
            {
              path: '/order-management',
              breadcrumbName: '订单管理',
            },
          ]}
        />
      }
    >
      <section>
        <div className="content__filter flex flex-middle">
          <div className="form__group">
            <label className="form__label">日期:</label>
            <RangePicker
              className="form__item"
              onChange={onRangePickerChange}
              placeholder={['开始日期', '结束日期']}
              defaultValue={[
                moment.unix(searchTextByTimeFrom),
                moment.unix(searchTextByTimeTo),
              ]}
            />
          </div>

          <div className="form__group">
            <label className="form__label">筛选:</label>
            <Dropdown
              overlay={selectStatusArrayList}
              arrow
              className="form__item"
            >
              <Button shape="round">
                <Space>
                  状态
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </div>
        </div>
        <TableResponsiveFrame
          columns={columns}
          pageNumber={paginateNum}
          pageSize={paginateSize}
          data={dataSource}
          dataItemsTotal={total}
          onRowClick={(record: any) => {
            navigate(`/redemption-market/order-management/${record.id}`, {
              state: {
                id: record.id,
                exchangeByType: record.exchangeByType,
                playerDisplayName: record.playerName,
                total: record.total,
              },
            });
            dispatch(
              setFilters({
                from: searchTextByTimeFrom,
                to: searchTextByTimeTo,
                selectedStatus,
                selectedExchangedType,
              })
            );
            return true;
          }}
          onChangePageSize={onShowSizeChange}
          onChangePageNumber={paginateChange}
        />
      </section>
    </ContentDetail>
  );
}
