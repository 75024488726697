import { get, post } from '../../../../../service/api';

export const postUserRegister = async (
  payload: {
    merchantName: string;
    accountName: string;
    password: string;
    otp: string;
    type: string;
    business: string;
    trial: boolean;
    pricePlanType: string;
    subscriptionPeriod: string;
  },
  options?: object
) => {
  try {
    const result = await post(`/merchants`, payload, options);

    return result;
  } catch (e) {
    throw new Error(typeof e === 'string' ? e : JSON.stringify(e));
  }
};

export const getBusinessType = async (options?: object) => {
  try {
    const result = await get(`/business-types`, {}, {}, options);

    return result;
  } catch (e) {
    throw new Error(typeof e === 'string' ? e : JSON.stringify(e));
  }
};
