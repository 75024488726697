/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import {
  DatePicker,
  Button,
  Menu,
  Dropdown,
  Space,
  Tooltip,
  Select,
} from 'antd';
import { CheckOutlined, DownOutlined, InfoOutlined } from '@ant-design/icons';
import moment from 'moment';
import { AppDispatch } from '../../redux/store';
import { useSelector, useDispatch } from 'react-redux';
import { checkTicket } from '../../service/http';
import {
  getAssetsDictionary,
  getDisplayPlayerList,
  getEmployeeList,
  getFrom,
  getOptions,
  getPageSize,
  getPaginateNum,
  getSelectedPlayerId,
  getTableData,
  getTo,
  getTotal,
  getUserName,
  getIsStoreListFilterVisible,
  getIsPlayersFilterVisible,
  getStoreList,
  getSelectedStoreKeys,
  getUserBelongToStoreId,
  getIsUserInitialized,
} from './redux/selectors';
import { actions as auditsActions } from './redux';
import {
  requestTableData,
  updateSelectedStoreKeys,
  mounted,
  getTheAssets,
  loadSearchingPlayers,
} from './redux/thunks';
import TableResponsiveFrame from '../../common/components/tables/TableResponsiveFrame';
import ContentDetail from '../../common/components/ContentDetail';
import './Audits.less';
import { getIsManager, getIsOwner } from '../../redux/app-v2/user/selectors';

const RangePicker: any = DatePicker.RangePicker;

const transferFromPaperTicketMap: { [key: string]: string } = {
  buy: '购买',
  use: '消费',
  fromPhysical: '从实体箭票转移',
  toPhysical: '转移到实体箭票',
  cancel: '取消',
  reward: '奖励',
  add: '添加',
  remedy: '补救措施',
  import: '导入',
};
let AuditTimer: any = null;
const Audits = () => {
  const dispatch = useDispatch<AppDispatch>();
  const userName = useSelector(getUserName);
  const employeeList = useSelector(getEmployeeList);
  const options = useSelector(getOptions);
  const isStoreListFilterVisible = useSelector(getIsStoreListFilterVisible);
  const isPlayersFilterVisible = useSelector(getIsPlayersFilterVisible);
  const selectedStoreKeys: string[] = useSelector(getSelectedStoreKeys);
  const selectedPlayerId = useSelector(getSelectedPlayerId);
  const storeList = useSelector(getStoreList);
  const playerList = useSelector(getDisplayPlayerList);
  const isManager = useSelector(getIsManager);
  const isOwner = useSelector(getIsOwner);
  const isUserInitialized = useSelector(getIsUserInitialized);
  const userBelongToStoreId = useSelector(getUserBelongToStoreId);
  const dataSource = useSelector(getTableData);
  const [searchTextByTimeFrom, setSearchTextByTimeFrom] = useState(
    useSelector(getFrom)
  );
  const [searchTextByTimeTo, setSearchTextByTimeTo] = useState(
    useSelector(getTo)
  );
  const paginateSize = useSelector(getPageSize);
  const paginateNum = useSelector(getPaginateNum);
  const total = useSelector(getTotal);
  const assetsDictionary: { [key: string]: string } =
    useSelector(getAssetsDictionary);
  const [today] = useState(moment().startOf('day').unix());
  const [tomorrow] = useState(moment().startOf('day').add(1, 'days').unix());
  const [selectedEvents, setSelectedEvents] = useState<Array<string>>([]);
  const [selectedCategories, setSelectedCategories] = useState<Array<string>>(
    []
  );
  const [selectedOperator, setSelectedOperator] = useState<string>('');
  const filterClick = (prop: string, data: string) => {
    if (prop === 'Events') {
      selectedEvents?.includes(data)
        ? setSelectedEvents([...selectedEvents.filter((item) => item !== data)])
        : setSelectedEvents([...(selectedEvents as string[]), data]);
    } else if (prop === 'Categories') {
      selectedCategories.includes(data)
        ? setSelectedCategories([
            ...selectedCategories.filter((item) => item !== data),
          ])
        : setSelectedCategories([...(selectedCategories as string[]), data]);
    } else {
      selectedOperator === data
        ? setSelectedOperator('')
        : setSelectedOperator(data);
    }
  };
  const columns: any = [
    {
      title: '时间',
      width: 180,
      dataIndex: 'operateTime',
      key: 'time',
      align: 'center',
      mobileAlign: 'headerLeft',
    },
    {
      title: '数量',
      width: 90,
      dataIndex: 'count',
      key: 'quantity',
      align: 'center',
    },
    {
      title: '操作项目',
      width: 120,
      dataIndex: 'event',
      key: 'event',
      align: 'center',
      mobileAlign: 'contentHeaderRight',
      render: (_: any, record: any) =>
        record.remark ? (
          <Tooltip placement="top" title={'备注：' + record.remark}>
            <div className="event_container">
              {record.event}
              <div className="event_icon">
                <InfoOutlined className="event_icon_item" />
              </div>
            </div>
          </Tooltip>
        ) : (
          <>{record.event}</>
        ),
    },
    {
      title: '权益',
      width: 90,
      dataIndex: 'assetsType',
      key: 'assetsType',
      align: 'center',
      mobileAlign: 'contentHeaderLeft',
    },
    {
      title: '会员',
      dataIndex: 'playerName',
      key: 'menber',
      align: 'center',
    },
    {
      title: '会员电话',
      width: 160,
      dataIndex: 'playerPhone',
      key: 'menber',
      align: 'center',
    },
    {
      title: '操作人',
      dataIndex: 'operatorName',
      key: 'operator',
      align: 'center',
    },
    {
      title: '核验',
      width: 90,
      dataIndex: 'isConfirmed',
      key: 'check',
      mobileAlign: 'headerRight',
      render: (text: any, record: any) => {
        return (
          <>
            {record.isConfirmed ? (
              <div style={{ height: '22px' }}>
                <CheckOutlined style={{ color: 'green' }} />
              </div>
            ) : (
              <div style={{ height: '22px' }}>
                <Button
                  shape="round"
                  size="small"
                  onClick={() => {
                    return checkTheItem(record.id);
                  }}
                >
                  核验
                </Button>
              </div>
            )}
          </>
        );
      },
      align: 'center',
    },
    {
      title: '核验人',
      dataIndex: 'confirmatorName',
      key: 'checkMan',
      render: (text: any, record: any) => {
        return (
          <>
            {record.isConfirmed ? (
              <span>{record.confirmatorName}</span>
            ) : (
              <span></span>
            )}
          </>
        );
      },
      align: 'center',
    },
  ];

  const eventsList = (
    <Menu
      onClick={(e) => filterClick('Events', e.key)}
      items={
        options &&
        options.event &&
        options.event.map((item: string) => {
          return { label: transferFromPaperTicketMap[item], key: item };
        })
      }
      selectedKeys={selectedEvents}
    />
  );

  const categoriesList = (
    <Menu
      onClick={(e) => filterClick('Categories', e.key)}
      items={
        // 遍历assetsDictionary，生成菜单
        Object.keys(assetsDictionary).map((item: string) => {
          return { label: assetsDictionary[item], key: item };
        })
      }
      selectedKeys={selectedCategories}
    />
  );

  const employeeSelectList = (
    <Menu
      onClick={(e) => filterClick('Employee', e.key)}
      items={employeeList}
      selectedKeys={[selectedOperator]}
    />
  );

  function checkTheItem(id: string) {
    const param = {
      operationId: id,
      data: {
        isConfirmed: true,
      },
    };
    checkTicket(param).then((res) => {
      dispatch(
        auditsActions.setItemChecked({
          id,
          isConfirmed: true,
          confirmatorName: userName,
        })
      );
    });
  }

  const onRangePickerChange = (dates: any, dateStrings: [string, string]) => {
    setSearchTextByTimeFrom(moment(dateStrings[0]).unix());
    setSearchTextByTimeTo(moment(dateStrings[1]).add(1, 'days').unix());

    dispatch(auditsActions.setPaginateNum(1));
  };

  const onShowSizeChange = (current: number, pageSize: number) => {
    dispatch(auditsActions.setPageSize(pageSize));
  };

  const paginateChange = (page: number) => {
    dispatch(auditsActions.setPaginateNum(page));
  };

  useEffect(() => {
    dispatch(getTheAssets());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      mounted({
        isManager: isManager || isOwner,
        userBelongToStoreId,
      })
    );
  }, [dispatch, isManager, isOwner, userBelongToStoreId]);

  useEffect(() => {
    if (!isUserInitialized) {
      return;
    }

    let param_events: string = '';
    for (let item of selectedEvents) {
      param_events += item + ',';
    }
    let param_categories: string = '';
    for (let item of selectedCategories) {
      param_categories += item + ',';
    }
    const param = {
      from: searchTextByTimeFrom ? searchTextByTimeFrom : today,
      to: searchTextByTimeTo ? searchTextByTimeTo : tomorrow,
      offset: (paginateNum - 1) * paginateSize,
      limit: paginateSize,
      assetsTypeId: param_categories.slice(0, -1),
      event: param_events.slice(0, -1),
      operatorId: selectedOperator,
      storeId: selectedStoreKeys[0] || '',
      playerId: selectedPlayerId || '',
    };

    if (!isManager && !isOwner && !selectedStoreKeys[0]) {
      return;
    }
    //https request for search API;
    dispatch(requestTableData(param) as any);

    return () => {
      dispatch(
        auditsActions.changeMemberSearchTime({
          searchFrom: searchTextByTimeFrom,
          searchTo: searchTextByTimeTo,
        })
      );
    };
  }, [
    searchTextByTimeFrom,
    searchTextByTimeTo,
    paginateNum,
    paginateSize,
    selectedEvents,
    selectedCategories,
    selectedOperator,
    isManager,
    isUserInitialized,
    today,
    tomorrow,
    selectedStoreKeys,
    selectedPlayerId,
    dispatch,
    isOwner,
  ]);

  return (
    <ContentDetail title="审计跟踪">
      <div className="content__filter flex flex-middle">
        <div className="form__group">
          <label className="form__label">日期:</label>
          <RangePicker
            className="form__item"
            onChange={onRangePickerChange}
            placeholder={['开始日期', '结束日期']}
            defaultValue={
              searchTextByTimeFrom === 0
                ? [moment.unix(today), moment.unix(tomorrow - 1)]
                : [
                    moment.unix(searchTextByTimeFrom),
                    moment.unix(searchTextByTimeTo - 1),
                  ]
            }
          />
        </div>

        <div className="form__group flex__fluid-content">
          <label className="form__label">筛选:</label>
          {isPlayersFilterVisible ? (
            <Select
              showSearch
              allowClear
              placeholder="搜索会员名字/手机号码"
              className="AuditPlayerSearch"
              value={selectedPlayerId}
              defaultActiveFirstOption={false}
              filterOption={false}
              onSearch={(value) => {
                clearTimeout(AuditTimer);

                AuditTimer = setTimeout(() => {
                  dispatch(loadSearchingPlayers(value));
                }, 400);
              }}
              onChange={(value) => {
                dispatch(auditsActions.setSelectedPlayerId(value));
              }}
              options={playerList}
            />
          ) : null}
          <Dropdown overlay={eventsList} arrow className="form__item">
            <Button shape="round">
              <Space>
                事件(多选)
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
          <Dropdown overlay={categoriesList} arrow className="form__item">
            <Button shape="round">
              <Space>
                类型(多选)
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>

          <Dropdown overlay={employeeSelectList} arrow className="form__item">
            <Button shape="round">
              <Space>
                操作人(单选)
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
          {isStoreListFilterVisible ? (
            <Select
              value={selectedStoreKeys || '全部门店'}
              options={storeList}
              onChange={(value) => {
                dispatch(updateSelectedStoreKeys([value]));
              }}
            />
          ) : null}
        </div>
      </div>
      <TableResponsiveFrame
        columns={columns}
        pageNumber={paginateNum}
        pageSize={paginateSize}
        data={dataSource}
        dataItemsTotal={total}
        onChangePageSize={onShowSizeChange}
        onChangePageNumber={paginateChange}
      />
    </ContentDetail>
  );
};

export default Audits;
