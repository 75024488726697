import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import {
  getMerchantName,
  getMerchantDisplayName,
  getMerchantAgreement,
  getMerchantVoteLink,
  getMerchantLiveStreamId,
} from '../../../../redux/app-v2/merchant/selectors';
import { loadMerchantInfo } from '../../../../redux/app-v2/merchant/thunks';
import { putMerchantInfo } from './api-request';
import {
  errorMessage,
  successMessage,
} from '../../../../common/utils/feedback';

export { loadMerchantInfo };

export const updateMerchant = createAsyncThunk(
  'merchant/updateMerchant',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState();
      const merchantName = getMerchantName(state);
      const displayName = getMerchantDisplayName(state);
      const voteLink = getMerchantVoteLink(state);
      const liveStreamId = getMerchantLiveStreamId(state);
      const userAgreement = getMerchantAgreement(state);
      const result: any = await putMerchantInfo({
        merchantName,
        data: {
          displayName,
          voteLink,
          liveStreamId,
          userAgreement,
        },
      });
      const { data } = result;

      await dispatch(loadMerchantInfo());

      successMessage('更新设置成功');

      return data;
    } catch (error) {
      errorMessage('更新设置失败');

      throw error;
    }
  }
);
