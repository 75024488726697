import { post, put } from '../../../../service/api';

export const postMemberCardSetting = async ({
  merchantName,
  payload,
}: {
  merchantName: string;
  payload: object;
}) => {
  try {
    const res = await post(
      `/merchants/${merchantName}/assets/member-cards/setting/types`,
      payload
    );

    return res;
  } catch (e) {
    throw new Error('post member card setting failed');
  }
};

export const putMemberCardSetting = async ({
  merchantName,
  id,
  payload,
}: {
  merchantName: string;
  id: string;
  payload: object;
}) => {
  try {
    const res = await put(
      `/merchants/${merchantName}/assets/member-cards/setting/types/${id}`,
      payload
    );
    return res;
  } catch (e) {
    throw new Error('put member card setting failed');
  }
};
