import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  getIsManager,
  getIsOperationsManager,
  getIsOwner,
} from '../../../../redux/app-v2/user/selectors';
import GoodsManagementImage from '../../../../images/goods-management.png';
import OrderManagementImage from '../../../../images/order-management.png';
import VoucherManagementImage from '../../../../images/voucher-management.png';
import MarketFunctionCard from '../../components/MarketFunctionCard';
import ContentDetail from '../../../../common/components/ContentDetail';
import './RedemptionMarket.less';

const RedemptionMarket = () => {
  const navigate = useNavigate();

  const isOwner = useSelector(getIsOwner);
  const isManager = useSelector(getIsManager);
  const isOperation = useSelector(getIsOperationsManager);
  const miniMarketVisible = isOwner || isManager || isOperation;

  if (!miniMarketVisible) {
    return null;
  }

  return (
    <ContentDetail title="兑换商城">
      <div className="RedemptionMarketContainer flex flex__wrap">
        <div className="RedemptionMarketCardContainer">
          <MarketFunctionCard
            img={GoodsManagementImage}
            title={'礼品管理'}
            desc={'添加、删除、修改兑换商城中可兑换的礼品信息'}
            onclick={() => {
              navigate('/redemption-market/merchandise-management');
            }}
          />
        </div>
        <div className="RedemptionMarketCardContainer">
          <MarketFunctionCard
            img={OrderManagementImage}
            title={'订单管理'}
            desc={'查看和编辑兑换商城中的会员订单'}
            onclick={() => {
              navigate('/redemption-market/order-management');
            }}
          />
        </div>
        <div className="RedemptionMarketCardContainer">
          <MarketFunctionCard
            img={VoucherManagementImage}
            title={'券码管理'}
            desc={'查看和编辑兑换商城中实体礼品兑换券码'}
            onclick={() => {
              navigate('/redemption-market/voucher-management');
            }}
          />
        </div>
      </div>
    </ContentDetail>
  );
};

export default RedemptionMarket;
