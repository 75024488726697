import { createSelector } from '@reduxjs/toolkit';
import { getMemberImageUrl } from '../../utils';
import { getOriginalMemberCards } from '../common/selectors';
import { API_REQUEST_STATUS } from '../../../../service/constants';
import { RootState } from '../../../../common/utils/types';

export const getSelectedMemberCardId = (state: RootState) =>
  state.memberCards.details.selectedMemberCardDetailsId;

export const getSelectedMemberCardDetails = createSelector(
  getSelectedMemberCardId,
  getOriginalMemberCards,
  (selectedMemberCardDetailsId, memberCards) => {
    const selectedMemberCard = memberCards.find(
      ({ id }: { id: string }) => id === selectedMemberCardDetailsId
    );

    return selectedMemberCard || {};
  }
);

export const getSelectedMemberCardDeleted = createSelector(
  getSelectedMemberCardDetails,
  (selectedMemberCardDetails) => selectedMemberCardDetails.deleted
);

export const getSelectedMemberCardSkinImageUrl = (state: RootState) =>
  state.memberCards.details.skinImageUrl;

export const getSelectedMemberCardImageUrl = createSelector(
  getSelectedMemberCardSkinImageUrl,
  (skinImageUrl) => getMemberImageUrl(skinImageUrl)
);

export const getIsUploadingSkinImagePending = (state: RootState) =>
  state.memberCards.details.uploadSkinImageStatus ===
  API_REQUEST_STATUS.PENDING;

export const getSelectedMemberCardType = (state: RootState) =>
  state.memberCards.details.type;

export const getSelectedMemberCardDescription = (state: RootState) =>
  state.memberCards.details.description;

export const getMemberCardDetailModalVisible = (state: RootState) =>
  state.memberCards.details.memberCardDetailsVisibility;

export const getSelectedMemberCardUpdatedDetails = createSelector(
  getSelectedMemberCardSkinImageUrl,
  getSelectedMemberCardType,
  getSelectedMemberCardDescription,
  getMemberCardDetailModalVisible,
  (skinImageUrl, type, description) => ({
    skinImageUrl,
    type,
    description,
  })
);
