import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  errorMessage,
  successMessage,
} from '../../../../common/utils/feedback';
import { RootState } from '../../../../common/utils/types';
import { getMerchantName } from '../../../../redux/app-v2/merchant/selectors';
import { postEmployeeVerify } from './api-request';

export const verifyEmployee = createAsyncThunk(
  'players/verifyEmployee',
  async (
    {
      accountName,
      password,
      nextStepFunction,
    }: {
      accountName: string;
      password: string;
      nextStepFunction?: () => void;
    },
    { getState }
  ) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state);

      const result = await postEmployeeVerify(merchantName, {
        accountName,
        password,
      });

      successMessage('验证成功', {
        handleConfirm: () => {
          if (nextStepFunction) {
            nextStepFunction();
          }
        },
      });

      return result;
    } catch (error) {
      errorMessage('账号或者密码验证失败');

      throw error;
    }
  }
);
