import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../common/utils/types';
import { API_REQUEST_STATUS } from '../../../service/constants';

export const getFeaturesSetting = (state: RootState) =>
  state.appV2.featuresSetting;

export const getLoadFeaturesSettingStatus = (state: RootState) =>
  state.appV2.featuresSetting.loadTheFeaturesSettingStatus;

export const getIsFeaturesSettingInitialized = createSelector(
  getLoadFeaturesSettingStatus,
  (loadTheFeaturesSettingStatus) =>
    [API_REQUEST_STATUS.FULFILLED, API_REQUEST_STATUS.REJECTED].includes(
      loadTheFeaturesSettingStatus
    )
);

export const getIsFeaturesSettingPending = createSelector(
  getLoadFeaturesSettingStatus,
  (loadTheFeaturesSettingStatus) =>
    loadTheFeaturesSettingStatus === API_REQUEST_STATUS.PENDING
);

export const getIsMemberCardsAvailable = createSelector(
  getFeaturesSetting,
  (featuresSetting) => featuresSetting.memberCards === true
);
