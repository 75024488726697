import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../../common/utils/types';
import {
  getEnrollmentClubsEnrollmentId,
  getEnrollmentClubsFiltersIsSubmitted,
  getEnrollmentClubsPageSize,
  getEnrollmentClubsParameters,
} from './selectors';
import { getEnrollmentClubs, getEnrollmentClubsExport } from './api-request';

export const loadEnrollmentClubs = createAsyncThunk(
  'association/enrollmentClubs/loadEnrollmentClubs',
  async (
    changeParams: {
      offset?: number;
      limit?: number;
      text?: string;
      isSubmitted?: boolean | undefined;
    },
    { getState }
  ) => {
    try {
      const state = getState() as RootState;
      const enrollmentId = getEnrollmentClubsEnrollmentId(state);
      const enrollmentClubsParameters = getEnrollmentClubsParameters(state);

      const result: any = await getEnrollmentClubs(enrollmentId, {
        ...enrollmentClubsParameters,
        ...changeParams,
      });

      return {
        data: result.data,
        pagination: result.pagination,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const updateEnrollmentClubsPageNumber = createAsyncThunk(
  'association/enrollmentClubs/updateEnrollmentClubsPageNumber',
  async (pageNumber: number, { dispatch, getState }) => {
    try {
      const state: any = getState();
      const pageSize = getEnrollmentClubsPageSize(state);

      await dispatch(
        loadEnrollmentClubs({
          offset: pageNumber * pageSize,
        })
      );

      return pageNumber;
    } catch (error) {
      throw error;
    }
  }
);

export const updateEnrollmentClubsPageSize = createAsyncThunk(
  'association/enrollmentClubs/updateEnrollmentClubsPageSize',
  async (pageSize: number, { dispatch }) => {
    try {
      await dispatch(
        loadEnrollmentClubs({
          offset: 0,
          limit: pageSize,
        })
      );

      return {
        pageNumber: 0,
        pageSize,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const filterEnrollmentClubs = createAsyncThunk(
  'association/enrollmentClubs/filterEnrollmentClubs',
  async (
    filters: {
      text?: string;
      isSubmitted?: boolean | null;
    },
    { dispatch }
  ) => {
    try {
      const { text, isSubmitted } = filters || {};
      const params = {
        offset: 0,
        text,
        isSubmitted: isSubmitted === null ? undefined : isSubmitted,
      };

      await dispatch(loadEnrollmentClubs(params));

      return {
        ...filters,
        pageNumber: 0,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const exportEnrollmentClubs = createAsyncThunk(
  'association/enrollmentClubs/exportEnrollmentClubs',
  async (_, { getState }) => {
    try {
      const state: any = getState();
      const enrollmentId = getEnrollmentClubsEnrollmentId(state);
      const isSubmitted = getEnrollmentClubsFiltersIsSubmitted(state);
      const result: any = await getEnrollmentClubsExport(enrollmentId, {
        isSubmitted,
      });

      return result;
    } catch (error) {
      throw error;
    }
  }
);
