import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getIsBaseball,
  getMerchantName,
} from '../../../../../../../redux/app-v2/merchant/selectors';
import { postClub } from './api-request';
import { getAddClubData } from './selectors';
import { AddClubState } from './types';
import {
  errorMessage,
  successMessage,
} from '../../../../../../../common/utils/feedback';

export const createClub = createAsyncThunk(
  'association/clubs/add/createClub',
  async (backToListPage: Function, { getState }) => {
    try {
      const state: any = getState();
      const isBaseball = getIsBaseball(state);
      const clubText = isBaseball ? '联盟球队' : '成员单位';
      const merchantName = getMerchantName(state);
      const payload: AddClubState = getAddClubData(state);
      const result: any = await postClub(merchantName, payload);
      const { data } = result;

      successMessage(`新增${clubText}认证成功`, {
        handleConfirm: () => {
          backToListPage();
        },
      });

      return data;
    } catch (error) {
      const state: any = getState();
      const isBaseball = getIsBaseball(state);
      const clubText = isBaseball ? '联盟球队' : '成员单位';

      errorMessage(`新增${clubText}认证失败`);

      throw error;
    }
  }
);
