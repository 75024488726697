import { createSelector } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../../service/constants';
import { LoginStateTypes } from './types';

/**
 * get merchant name
 * @return {string}
 */
export const getMerchantName = (state: { login: LoginStateTypes }) =>
  state.login.merchantName;

/**
 * get account name
 * @return {string}
 */
export const getAccountName = (state: { login: LoginStateTypes }) =>
  state.login.accountName;

/**
 * get password
 * @return {string}
 */
export const getPassword = (state: { login: LoginStateTypes }) =>
  state.login.password;

/**
 * get login info
 * @return {merchantName: string, accountName: string, password: string}
 */

export const getLoginInfo = createSelector(
  getMerchantName,
  getAccountName,
  getPassword,
  (merchantName, accountName, password) => {
    return { merchantName, accountName, password };
  }
);

export const getIsLoginStatusPending = (state: { login: LoginStateTypes }) =>
  state.login.loginStatus === API_REQUEST_STATUS.PENDING;
