import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  MERCHANT_SUBSCRIPTION_PRICE_PLANS,
  MERCHANT_SUBSCRIPTION_STATUS,
} from '../../../../../common/constants';
import { getMerchantName } from '../../../../../redux/app-v2/merchant/selectors';
import { upgradeMerchantBasicToEliteTrail } from '../../../../../redux/app-v2/merchant/thunks';
import {
  getMerchantSubscriptionPricePlanType,
  getMerchantSubscriptionStatus,
  getMerchantSubscriptionTrialEnded,
} from '../../../../../redux/app-v2/merchant/selectors';
import {
  loadTheFeaturesSetting,
  updateTheFeatureSetting,
} from '../../../../../redux/app-v2/featuresSetting/thunks';
import {
  errorMessage,
  successMessage,
} from '../../../../../common/utils/feedback';
import {
  getHistoryClubsRankingImportTemplate,
  getHistoryPlayersAchievementImportTemplate,
  getTempTournamentList,
  patchTournamentImage,
  postTempTournament,
} from './api-request';

export const updateBasicToEliteTrail = createAsyncThunk(
  'featureSettings/updateBasicToEliteTrail',
  async (_, { dispatch, getState }) => {
    try {
      await dispatch(upgradeMerchantBasicToEliteTrail() as any).then(
        async () => {
          const state = getState() as any;
          const pricePlanType = getMerchantSubscriptionPricePlanType(state);
          const trialEnded = getMerchantSubscriptionTrialEnded(state);
          const subscriptionStatus = getMerchantSubscriptionStatus(state);

          if (
            pricePlanType === MERCHANT_SUBSCRIPTION_PRICE_PLANS.elite &&
            !trialEnded &&
            subscriptionStatus === MERCHANT_SUBSCRIPTION_STATUS.trial
          ) {
            await dispatch(
              updateTheFeatureSetting({
                activities: true,
                archeryCompetitions: true,
                assets: true,
                market: true,
                memberCards: true,
                posters: true,
                rankings: true,
              })
            );
          }
        }
      );

      await dispatch(loadTheFeaturesSetting() as any);

      successMessage('升级为精英俱乐部试用计划成功');
    } catch (e) {
      errorMessage('升级为精英俱乐部试用计划失败');
      throw e;
    }
  }
);

export const loadHistoryClubsRankingImportTemplate = createAsyncThunk(
  'clubAuthentication/import/loadHistoryClubsRankingImportTemplate',
  async (_, { getState }) => {
    try {
      const state = getState() as any;
      const merchantName: string = getMerchantName(state);
      const result: any = await getHistoryClubsRankingImportTemplate(
        merchantName
      );

      return result;
    } catch (error) {
      errorMessage('获取模板失败');
    }
  }
);

export const loadHistoryPlayersAchievementImportTemplate = createAsyncThunk(
  'clubAuthentication/import/loadHistoryPlayersAchievementImportTemplate',
  async (_, { getState }) => {
    try {
      const state = getState() as any;
      const merchantName: string = getMerchantName(state);
      const result: any = await getHistoryPlayersAchievementImportTemplate(
        merchantName
      );

      return result;
    } catch (error) {
      errorMessage('获取模板失败');
    }
  }
);

export const loadTempTournamentList = createAsyncThunk(
  'clubAuthentication/import/loadTempTournamentList',
  async (title: string | undefined, { getState }) => {
    try {
      const state = getState() as any;
      const merchantName: string = getMerchantName(state);
      const result: any = await getTempTournamentList(merchantName, {
        limit: 10,
        offset: 0,
        title,
      });
      const { data } = result || {};

      return data;
    } catch (error) {
      errorMessage('获取锦标赛列表失败');
    }
  }
);

export const createTempTournament = createAsyncThunk(
  'clubAuthentication/import/createTempTournament',
  async (title: string, { getState, dispatch }) => {
    try {
      const state = getState() as any;
      const merchantName: string = getMerchantName(state);
      const result: any = await postTempTournament(merchantName, { title });
      const { data } = result || {};

      await dispatch(loadTempTournamentList() as any);

      return data;
    } catch (error) {
      errorMessage('创建锦标赛列表失败');
    }
  }
);

export const updateTournamentImage = createAsyncThunk(
  'clubAuthentication/import/updateTournamentImage',
  async (data: any, { getState }) => {
    try {
      const state = getState() as any;
      const merchantName: string = getMerchantName(state);
      const { id, imageUrl } = data;
      const result: any = await patchTournamentImage(merchantName, id, {
        imageUrl,
      });
      const { data: tournament } = result || {};

      return tournament;
    } catch (error) {
      errorMessage('更新锦标赛图片失败');
    }
  }
);
