import { createSlice } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../../service/constants';
import {
  loadPlayerById,
  loadPlayerMemberCards,
  savePlayerById,
} from './thunks';
import { PlayerMemberCard } from './types';

const initialState: {
  data: {};
  memberCards: PlayerMemberCard[];
  loadRequestStatus: string;
  saveRequestStatus: string;
} = {
  data: {},
  memberCards: [],
  loadRequestStatus: API_REQUEST_STATUS.IDLE,
  saveRequestStatus: API_REQUEST_STATUS.IDLE,
};

export const { reducer, actions } = createSlice({
  name: 'player/details',
  initialState,
  reducers: {
    backPlayerListPage: (state: any) => {
      state.data = {};
      state.loadRequestStatus = API_REQUEST_STATUS.IDLE;
    },
    updatePlayerDetail: (state: any, action: any) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    resetPlayerDetail: (state: any) => {
      state.data = {};
      state.memberCards = [];
      state.loadRequestStatus = API_REQUEST_STATUS.IDLE;
      state.saveRequestStatus = API_REQUEST_STATUS.IDLE;
    },
  },
  extraReducers: {
    [loadPlayerById.fulfilled.type]: (
      state: typeof initialState,
      action: any
    ) => {
      state.data = {
        id: action.payload,
      };
      state.loadRequestStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [loadPlayerById.rejected.type]: (state: typeof initialState) => {
      state.data = {};
      state.loadRequestStatus = API_REQUEST_STATUS.REJECTED;
    },
    [savePlayerById.fulfilled.type]: (state: typeof initialState) => {
      state.saveRequestStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [savePlayerById.rejected.type]: (state: typeof initialState) => {
      state.saveRequestStatus = API_REQUEST_STATUS.REJECTED;
    },
    [loadPlayerMemberCards.fulfilled.type]: (
      state,
      { payload }: { payload: PlayerMemberCard[] }
    ) => {
      state.memberCards = payload;
    },
  },
});

export default reducer;
