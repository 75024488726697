import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  errorMessage,
  successMessage,
} from '../../../../common/utils/feedback';
import { getMerchantName } from '../../../../redux/app-v2/merchant/selectors';
import { addNewPlayer } from '../../redux/api-request';
import { getPlayerInfo } from './selectors';

export const addTheNewPlayer = createAsyncThunk(
  'player/addTheNewPlayer',
  async (_, { getState }) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);
      const player = getPlayerInfo(state);
      const data = {
        name: player.name,
        phone: player.phone,
        remarkName: player.remarkName,
        remarks: player.remarks,
      };
      const res: any = await addNewPlayer(merchantName, data);

      if (res.message === 'OK') {
        successMessage('添加成功');
      } else {
        errorMessage('账户已存在');
      }
    } catch (e) {
      errorMessage('添加会员失败');
      throw e;
    }
  }
);
