import { RootState } from '../../../../common/utils/types';
import { getUserAuthorization } from '../../../../redux/app-v2/user/selectors';
import { getMerchantName } from '../../../../redux/app-v2/merchant/selectors';

export { getUserAuthorization, getMerchantName };

export const getName = (state: RootState) =>
  state.player.newPlayer.playerInfo.name;

export const getPhone = (state: RootState) =>
  state.player.newPlayer.playerInfo.phone;

export const getRemarkName = (state: RootState) =>
  state.player.newPlayer.playerInfo.remarkName;

export const getRemarks = (state: RootState) =>
  state.player.newPlayer.playerInfo.remarks;

export const getPlayerInfo = (state: RootState) =>
  state.player.newPlayer.playerInfo;
