import { createSlice } from '@reduxjs/toolkit';
import { SubscriptionTypes } from './types';
import {
  createPreviewOrder,
  createSubscriptionOrder,
  loadHistoryAgreementById,
  loadPricePlanList,
  loadSubscriptionOrderList,
  pollingSubscriptionOrderStatus,
  updateActivePricePlanId,
  updateAddonStoreCount,
  updateSubscriptionOrderListPageNumber,
  updateSubscriptionOrderListPageSize,
} from './thunks';
import { API_REQUEST_STATUS } from '../../../../service/constants';
import { MERCHANT_SUBSCRIPTION_PRICE_PLANS } from '../../../../common/constants';

const initialState: SubscriptionTypes = {
  pricePlans: {
    pricePlanTypeIds: {},
    activeId: null,
    differentAddonStoreCount: {
      elite: 0,
      mega: 0,
    },
    loadPricePlanStatus: API_REQUEST_STATUS.IDLE,
  },
  previewPlanOrder: {
    purchaseType: 'plan', // plan/addonStore
    paymentMethod: 'wechatPay',
    paymentProvider: 'wechatOfficial',
    client: 'web',
    loadPreviewPlanOrderStatus: API_REQUEST_STATUS.IDLE,
  },
  order: {
    id: null,
    total: 0,
    discount: 0,
    currency: '',
    items: [],
    existingSubscriptionOffsetAmount: 0,
    status: null,
    paymentQRCodeUrl: null,
    orderCreated: API_REQUEST_STATUS.IDLE,
  },
  orderList: {
    pageSize: 10,
    pageNumber: 0,
    totalOrders: 0,
    end: false,
    data: [],
    loadOrderListStatus: API_REQUEST_STATUS.IDLE,
  },
  agreementInfo: {
    companyName: '',
    companyAddress: '',
    principalName: '',
  },
  historyAgreement: {
    content: '',
  },
  addonStoreUnitPrice: 0,
  businessLicenseModalVisible: false,
  renewalAgreementModalVisible: false,
  historyAgreementModelVisible: false,
};

export const { reducer, actions } = createSlice({
  name: 'subscription/subscriptionPlan',
  initialState,
  reducers: {
    showRenewalAgreementModal: (state) => {
      state.renewalAgreementModalVisible = true;
    },
    hideRenewalAgreementModal: (state) => {
      state.renewalAgreementModalVisible = false;
    },
    showBusinessLicenseModal: (state) => {
      state.businessLicenseModalVisible = true;
    },
    hideBusinessLicenseModal: (state) => {
      state.businessLicenseModalVisible = false;
    },
    showHistoryAgreementModal: (state) => {
      state.historyAgreementModelVisible = true;
    },
    hideHistoryAgreementModal: (state) => {
      state.historyAgreementModelVisible = false;
    },
    historyAgreementReset: (state) => {
      state.historyAgreement.content = '';
    },
    businessLicenseUpdated: (state, { payload }) => {
      const { companyName, companyAddress, principalName } = payload || {};

      if (companyName) {
        state.agreementInfo.companyName = companyName;
      }

      if (companyAddress) {
        state.agreementInfo.companyAddress = companyAddress;
      }

      if (principalName) {
        state.agreementInfo.principalName = principalName;
      }
    },
    businessLicenseReset: (state) => {
      state.agreementInfo.companyName = '';
      state.agreementInfo.companyAddress = '';
      state.agreementInfo.principalName = '';
    },
    orderReset: (state) => {
      state.order.id = null;
      state.order.total = 0;
      state.order.discount = 0;
      state.order.currency = '';
      state.order.items = [];
      state.order.existingSubscriptionOffsetAmount = 0;
      state.order.status = null;
      state.order.paymentQRCodeUrl = null;
      state.order.orderCreated = API_REQUEST_STATUS.IDLE;
    },
  },
  extraReducers: {
    [loadPricePlanList.fulfilled.type]: (state, action) => {
      const { payload } = action || {};
      const { pricePlanTypeIds, subscriptionAddonStoreCount } = payload || {};

      state.pricePlans.pricePlanTypeIds = pricePlanTypeIds;
      state.pricePlans.differentAddonStoreCount.elite =
        subscriptionAddonStoreCount;
      state.pricePlans.differentAddonStoreCount.mega =
        subscriptionAddonStoreCount;
      state.pricePlans.loadPricePlanStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [loadPricePlanList.pending.type]: (state) => {
      state.pricePlans.loadPricePlanStatus = API_REQUEST_STATUS.PENDING;
    },
    [loadPricePlanList.rejected.type]: (state) => {
      state.pricePlans.loadPricePlanStatus = API_REQUEST_STATUS.REJECTED;
    },
    [loadHistoryAgreementById.fulfilled.type]: (state, { payload }) => {
      const { content } = payload || {};
      state.historyAgreement.content = content;
    },
    [loadHistoryAgreementById.pending.type]: (state, { payload }) => {
      state.historyAgreement.content = '';
    },
    [loadHistoryAgreementById.rejected.type]: (state, { payload }) => {
      state.historyAgreement.content = '';
    },
    [createPreviewOrder.fulfilled.type]: (state, { payload }) => {
      const {
        total,
        discount,
        currency,
        items,
        addonStoreCount,
        existingSubscriptionOffsetAmount,
        pricePlanCopy,
      } = payload || {};
      const { addonStorePrice, type, planId } = pricePlanCopy || {};
      const { unitPrice } = addonStorePrice || {};

      if (type === MERCHANT_SUBSCRIPTION_PRICE_PLANS.elite) {
        state.pricePlans.differentAddonStoreCount.elite = addonStoreCount;
      } else if (type === MERCHANT_SUBSCRIPTION_PRICE_PLANS.mega) {
        state.pricePlans.differentAddonStoreCount.mega = addonStoreCount;
      }

      state.pricePlans.activeId = planId;
      state.order.total = total;
      state.order.discount = discount;
      state.order.currency = currency;
      state.order.items = items;
      state.order.existingSubscriptionOffsetAmount =
        existingSubscriptionOffsetAmount;
      state.previewPlanOrder.loadPreviewPlanOrderStatus =
        API_REQUEST_STATUS.FULFILLED;
      state.addonStoreUnitPrice = unitPrice;
    },
    [createPreviewOrder.pending.type]: (state) => {
      state.previewPlanOrder.loadPreviewPlanOrderStatus =
        API_REQUEST_STATUS.PENDING;
    },
    [createPreviewOrder.rejected.type]: (state) => {
      state.previewPlanOrder.loadPreviewPlanOrderStatus =
        API_REQUEST_STATUS.REJECTED;
    },
    [updateActivePricePlanId.fulfilled.type]: (state, action) => {
      const { payload } = action || {};

      state.pricePlans.activeId = payload;
    },
    [updateAddonStoreCount.fulfilled.type]: (state, { payload }) => {
      const { activeType, addonStoreCount } = payload || {};

      if (activeType === MERCHANT_SUBSCRIPTION_PRICE_PLANS.elite) {
        state.pricePlans.differentAddonStoreCount.elite = addonStoreCount;
      } else if (activeType === MERCHANT_SUBSCRIPTION_PRICE_PLANS.mega) {
        state.pricePlans.differentAddonStoreCount.mega = addonStoreCount;
      }
    },
    [createSubscriptionOrder.fulfilled.type]: (state, { payload }) => {
      const {
        status,
        id,
        total,
        discount,
        currency,
        items,
        addonStoreCount,
        paymentQRCodeUrl,
        existingSubscriptionOffsetAmount,
        pricePlanCopy,
      } = payload || {};
      const { type } = pricePlanCopy || {};

      if (type === MERCHANT_SUBSCRIPTION_PRICE_PLANS.elite) {
        state.pricePlans.differentAddonStoreCount.elite = addonStoreCount;
      } else if (type === MERCHANT_SUBSCRIPTION_PRICE_PLANS.mega) {
        state.pricePlans.differentAddonStoreCount.mega = addonStoreCount;
      }

      state.order.id = id;
      state.order.total = total;
      state.order.discount = discount;
      state.order.currency = currency;
      state.order.items = items;
      state.order.existingSubscriptionOffsetAmount =
        existingSubscriptionOffsetAmount;
      state.order.paymentQRCodeUrl = paymentQRCodeUrl;
      state.order.status = status;
      state.order.orderCreated = API_REQUEST_STATUS.FULFILLED;
    },
    [createSubscriptionOrder.pending.type]: (state) => {
      state.order.orderCreated = API_REQUEST_STATUS.PENDING;
    },
    [createSubscriptionOrder.rejected.type]: (state) => {
      state.order.orderCreated = API_REQUEST_STATUS.REJECTED;
    },
    [pollingSubscriptionOrderStatus.fulfilled.type]: (state, { payload }) => {
      const { status, paymentQRCodeUrl } = payload || {};

      if (!state.order.paymentQRCodeUrl) {
        state.order.paymentQRCodeUrl = paymentQRCodeUrl;
      }

      state.order.status = status;
    },
    [loadSubscriptionOrderList.fulfilled.type]: (state, { payload }) => {
      const { pagination, data } = payload || {};
      const { total, end } = pagination || {};

      state.orderList.totalOrders = total;
      state.orderList.end = end;
      state.orderList.data = data;
      state.orderList.loadOrderListStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [loadSubscriptionOrderList.pending.type]: (state) => {
      state.orderList.loadOrderListStatus = API_REQUEST_STATUS.PENDING;
    },
    [loadSubscriptionOrderList.rejected.type]: (state) => {
      state.orderList.loadOrderListStatus = API_REQUEST_STATUS.REJECTED;
    },
    [updateSubscriptionOrderListPageSize.fulfilled.type]: (
      state,
      { payload }
    ) => {
      const { pageSize, pageNumber } = payload || {};

      state.orderList.pageSize = pageSize;
      state.orderList.pageNumber = pageNumber;
    },
    [updateSubscriptionOrderListPageNumber.fulfilled.type]: (
      state,
      { payload }
    ) => {
      state.orderList.pageNumber = payload;
    },
  },
});

export default reducer;
