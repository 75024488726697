import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMerchantName } from '../merchant/selectors';
import { getFeaturesSetting, putFeatureSettings } from './api-request';
import { errorMessage } from '../../../common/utils/feedback';

export const loadTheFeaturesSetting = createAsyncThunk(
  'app/function/loadTheFeaturesSetting',
  async (_, { getState }) => {
    try {
      const state = getState() as any;
      const merchantName = getMerchantName(state);
      const result: any = await getFeaturesSetting(merchantName);

      return result.data;
    } catch (e) {
      errorMessage('获取功能设置失败');
      throw e;
    }
  }
);

export const updateTheFeatureSetting = createAsyncThunk(
  'app/function/updateTheFeatureSetting',
  async (payload: any, { getState, dispatch }) => {
    try {
      const state = getState() as any;
      const merchantName = getMerchantName(state);

      await putFeatureSettings(merchantName, payload);
      dispatch(loadTheFeaturesSetting() as any);
    } catch (e) {
      errorMessage('更新功能设置失败');
      throw e;
    }
  }
);
