import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMerchantName } from '../../../../../../../../redux/app-v2/merchant/selectors';
import {
  getAssociationClubById,
  getAssociationClubs,
  getAssociationPlayers,
} from './api-request';
import { actions } from './index';

export const loadAssociationClubs = createAsyncThunk(
  'association/players/common/loadAssociationClubs',
  async (
    payload: {
      offset: number;
      limit: number;
      text?: string;
      isMember?: boolean;
      isRegisteredMerchant?: boolean;
    },
    { dispatch, getState }
  ) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);
      const result: any = await getAssociationClubs(merchantName, payload);
      const { data } = result;

      dispatch(actions.associationClubMappingUpdated(data));

      return data.map((item: { id: string }) => item.id);
    } catch (error) {
      throw error;
    }
  }
);

export const loadAssociationClubById = createAsyncThunk(
  'association/players/common/loadAssociationClubById',
  async (id: string, { dispatch, getState }) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);
      const result: any = await getAssociationClubById(merchantName, id);
      const { data } = result;

      dispatch(actions.associationClubUpdatedById(data));

      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const loadAssociationPlayers = createAsyncThunk(
  'association/players/common/loadAssociationPlayers',
  async (
    payload: {
      offset: number;
      limit: number;
      text?: string;
      associationClubId?: string;
      gender?: string;
    },
    { dispatch, getState }
  ) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);
      const result: any = await getAssociationPlayers(merchantName, payload);
      const { data, pagination } = result;

      dispatch(actions.associationPlayersMappingUpdated(data));

      return {
        ids: data.map((item: { id: string }) => item.id),
        pagination,
      };
    } catch (error) {
      throw error;
    }
  }
);
