import { post } from '../../../service/api';

export const postLogout = async (
  payload: { merchantName: string },
  options = { isShow: true }
) => {
  try {
    const { merchantName } = payload;

    await post(`/merchants/${merchantName}/employees.logout`, {}, options);
  } catch (e) {
    throw new Error('employee error');
  }
};
