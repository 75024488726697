import _isBoolean from 'lodash/isBoolean';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getMerchantBusiness,
  getMerchantName,
} from '../../../../../../../redux/app-v2/merchant/selectors';
import { getClubAuthentication, putClubAuthentication } from './api-request';
import {
  getClubAuthenticationFiltersIsMember,
  getClubAuthenticationPageNumber,
  getClubAuthenticationPageSize,
  getClubAuthenticationSearchInputText,
  getClubAuthenticationTransferMemberData,
  getClubAuthenticationTransferMemberId,
} from './selector';
import { errorMessage } from '../../../../../../../common/utils/feedback';
import { BUSINESS_TYPES } from '../../../../../../../common/constants';

export const loadClubAuthentication = createAsyncThunk(
  'clubAuthentication/loadClubAuthentication',
  async (_, { getState }) => {
    try {
      const state = getState();
      const text: string = getClubAuthenticationSearchInputText(state);
      const isMember = getClubAuthenticationFiltersIsMember(state);
      const merchantName = getMerchantName(state);
      const isRegisteredMerchant = undefined;
      const offset: number =
        getClubAuthenticationPageNumber(state) *
        getClubAuthenticationPageSize(state);
      const limit: number = getClubAuthenticationPageSize(state);

      const res: any = await getClubAuthentication({
        text,
        isRegisteredMerchant,
        isMember: _isBoolean(isMember) ? isMember : undefined,
        merchantName,
        offset,
        limit,
      });

      return res;
    } catch (e) {
      const state: any = getState();
      const business = getMerchantBusiness(state);
      const isBaseball = business === BUSINESS_TYPES.Baseball;
      errorMessage(`加载${isBaseball ? '联盟球队' : '成员单位'}认证列表失败`);

      throw e;
    }
  }
);

export const transferToClubAuthentication = createAsyncThunk(
  'clubAuthentication/updateClubAuthentication',
  async (_, { getState }) => {
    const state: any = getState();

    try {
      const merchantName = getMerchantName(state);
      const memberId = getClubAuthenticationTransferMemberId(state);
      const transferMemberData = getClubAuthenticationTransferMemberData(state);
      const { id } = transferMemberData;
      const result: any = await putClubAuthentication({
        id,
        merchantName,
        body: { ...transferMemberData, memberId },
      });
      const { data } = result || {};

      return data;
    } catch (error) {
      const business = getMerchantBusiness(state);
      const isBaseball = business === BUSINESS_TYPES.Baseball;
      errorMessage(`更新${isBaseball ? '联盟球队' : '成员单位'}认证失败`);

      throw error;
    }
  }
);
