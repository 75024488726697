import React, { useCallback } from 'react';
import { Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { Link } from 'react-router-dom';
import { AppDispatch } from '../../../../redux/store';
import {
  getActivityListPageNumber,
  getActivityListPageSize,
  getActivityList,
  getActivityItemsTotal,
} from './redux/selectors';
import { loadStoreList } from '../../redux/common/thunks';
import {
  loadActivityList,
  removeActivity,
  updateActivityListPagination,
} from './redux/thunks';
import ContentDetail from '../../../../common/components/ContentDetail';
import TableResponsiveFrame from '../../../../common/components/tables/TableResponsiveFrame';
import NewActivity from './components/NewActivity';
import './ActivityList.less';
import { getIsStoreManager } from '../../../../redux/app-v2/user/selectors';

const ActivityList: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const pageNumber = useSelector(getActivityListPageNumber);
  const pageSize = useSelector(getActivityListPageSize);
  const activityList = useSelector(getActivityList);
  const dataItemsTotal = useSelector(getActivityItemsTotal);
  const isStoreManager = useSelector(getIsStoreManager);
  const onChangePageSize = useCallback(
    async (_: number, pageSize: number) => {
      await dispatch(
        updateActivityListPagination({
          pageSize,
        })
      );
    },
    [updateActivityListPagination]
  );
  const onChangePageNumber = useCallback(
    async (pageNumber: number) => {
      dispatch(updateActivityListPagination({ pageNumber: pageNumber - 1 }));
    },
    [updateActivityListPagination]
  );
  useMount(async () => {
    await dispatch(loadStoreList());
    dispatch(loadActivityList());
  });

  const columns: any = !isStoreManager
    ? [
        {
          title: '名称',
          className: 'activityNameColumn',
          dataIndex: 'name',
          key: 'name',
          mobileAlign: 'contentHeaderLeft',
          render: (_: any, { id, name }: any) => (
            <Link to={`/activities/${id}`}>{name}</Link>
          ),
        },
        {
          title: '参加人数限制',
          dataIndex: 'maxPlayers',
          key: 'maxPlayers',
          align: 'center',
        },
        {
          title: '报名截止时间',
          dataIndex: 'validTimeTo',
          key: 'validTimeTo',
          render: (_: any, { validTimeTo }: any) => {
            if (validTimeTo === '') {
              return '-';
            } else {
              return validTimeTo;
            }
          },
        },
        {
          title: '活动开始时间',
          dataIndex: 'validTimeFrom',
          key: 'validTimeFrom',
          render: (_: any, { validTimeFrom }: any) => {
            if (validTimeFrom === '') {
              return '-';
            } else {
              return validTimeFrom;
            }
          },
        },
        {
          title: '所属商铺',
          dataIndex: 'storeName',
          key: 'storeName',
          mobileAlign: 'headerLeft',
          render: (_: any, { storeName }: any) => {
            if (!storeName) {
              return '全部门店';
            }
            return storeName;
          },
        },
        {
          title: '操作',
          dataIndex: 'id',
          key: 'operator',
          align: 'center',
          mobileAlign: 'headerRight',
          render: (_: any, { id }: any) => {
            return (
              <Button
                danger
                size="small"
                onClick={() => {
                  Modal.confirm({
                    centered: true,
                    title: '确定删除该活动？',
                    icon: <ExclamationCircleOutlined />,
                    okText: '确认',
                    cancelText: '取消',
                    onOk: () => {
                      dispatch(removeActivity(id));
                    },
                  });
                }}
              >
                删除
              </Button>
            );
          },
        },
      ]
    : [
        {
          title: '名称',
          className: 'activityNameColumn',
          dataIndex: 'name',
          key: 'name',
          mobileAlign: 'contentHeaderLeft',
          render: (_: any, { id, name }: any) => (
            <Link to={`/activities/${id}`}>{name}</Link>
          ),
        },
        {
          title: '参加人数限制',
          dataIndex: 'maxPlayers',
          key: 'maxPlayers',
          align: 'center',
        },
        {
          title: '报名截止时间',
          dataIndex: 'validTimeTo',
          key: 'validTimeTo',
          render: (_: any, { validTimeTo }: any) => {
            if (validTimeTo === '') {
              return '-';
            } else {
              return validTimeTo;
            }
          },
        },
        {
          title: '活动开始时间',
          dataIndex: 'validTimeFrom',
          key: 'validTimeFrom',
          render: (_: any, { validTimeFrom }: any) => {
            if (validTimeFrom === '') {
              return '-';
            } else {
              return validTimeFrom;
            }
          },
        },
        {
          title: '操作',
          dataIndex: 'id',
          key: 'operator',
          align: 'center',
          mobileAlign: 'headerRight',
          render: (_: any, { id }: any) => {
            return (
              <Button
                danger
                size="small"
                onClick={() => {
                  Modal.confirm({
                    centered: true,
                    title: '确定删除该活动？',
                    icon: <ExclamationCircleOutlined />,
                    okText: '确认',
                    cancelText: '取消',
                    onOk: () => {
                      dispatch(removeActivity(id));
                    },
                  });
                }}
              >
                删除
              </Button>
            );
          },
        },
      ];

  return (
    <ContentDetail title="活动管理" headerRight={<NewActivity />}>
      <TableResponsiveFrame
        columns={columns}
        pageNumber={pageNumber + 1}
        pageSize={pageSize}
        data={activityList}
        dataItemsTotal={dataItemsTotal}
        onChangePageSize={onChangePageSize}
        onChangePageNumber={onChangePageNumber}
      />
    </ContentDetail>
  );
};

export default ActivityList;
