import { createSlice } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../service/constants';
import { getLocaleData } from '../../../service/local-api-request';
import { loadUserAndRoleById, saveUserInfoToLocale } from './thunks';
import { UserType } from './types';

const initialState: UserType = {
  id: getLocaleData('long-term', 'user')?.id || '',
  isLogin: getLocaleData('long-term', 'user')?.isLogin === true,
  authorization: getLocaleData('long-term', 'Auth') || '',
  name: '',
  phone: '',
  birthday: null,
  profileImageUrl: null,
  roleId: null,
  roleName: null,
  roleType: null,
  limitedAuthorities: [],
  storeId: null,
  loadUserAndRoleByIdStatus: API_REQUEST_STATUS.IDLE,
};

export const { reducer, actions } = createSlice({
  name: 'app/user',
  initialState,
  reducers: {
    userSet: (state: {
      id: any;
      isLogin: any;
      name: any;
      authorization: any;
      phone: string;
    }) => {
      const { id, isLogin } = getLocaleData('long-term', 'user') || {};

      state.id = id;
      state.isLogin = isLogin;
      state.authorization = getLocaleData('long-term', 'Auth');
    },
    userReset: (state) => {
      state.id = getLocaleData('long-term', 'user')?.id || '';
      state.isLogin = getLocaleData('long-term', 'user')?.isLogin === true;
      state.authorization = getLocaleData('long-term', 'Auth') || '';
      state.name = '';
      state.phone = '';
      state.birthday = null;
      state.profileImageUrl = null;
      state.roleId = null;
      state.roleName = null;
      state.roleType = null;
      state.limitedAuthorities = [];
      state.storeId = null;
      state.loadUserAndRoleByIdStatus = API_REQUEST_STATUS.IDLE;
    },
  },
  extraReducers: {
    [loadUserAndRoleById.pending.type]: (state) => {
      state.loadUserAndRoleByIdStatus = API_REQUEST_STATUS.PENDING;
    },
    [loadUserAndRoleById.fulfilled.type]: (state, action) => {
      const {
        name,
        phone,
        roleId,
        storeId = null,
        roleName = '',
        roleType,
        limitedAuthorities,
      } = action.payload;

      state.name = name;
      state.phone = phone;
      state.roleId = roleId;
      state.storeId = storeId;
      state.roleName = roleName;
      state.roleType = roleType;
      state.limitedAuthorities = limitedAuthorities;
      state.loadUserAndRoleByIdStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [loadUserAndRoleById.rejected.type]: (state) => {
      state.loadUserAndRoleByIdStatus = API_REQUEST_STATUS.REJECTED;
    },
    [saveUserInfoToLocale.fulfilled.type]: (state, { payload }) => {
      state.authorization = payload.authorization;
      state.id = payload.id;
      state.isLogin = payload.isLogin;
    },
  },
});

export default reducer;
