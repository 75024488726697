import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  errorMessage,
  successMessage,
} from '../../../../../common/utils/feedback';
import {
  loadEmployeeByIdV2 as loadEntitiesEmployees,
  updateEmployeeByIdV2 as updateEntitiesEmployeeByIdV2,
} from '../../../../../redux/entities/employees/thunks';
import { getEmployeeChangedDetail } from './selectors';

export const loadEmployeeById = createAsyncThunk(
  'employee/loadEmployeeById',
  async (id: string | undefined, { dispatch }) => {
    try {
      if (id) {
        await dispatch(loadEntitiesEmployees(id));

        return id;
      }
    } catch (e) {
      errorMessage('加载员工信息失败');
    }
  }
);

export const saveEmployeeById = createAsyncThunk(
  'employee/saveEmployeeById',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState();
      const { id, ...otherData } = getEmployeeChangedDetail(state);

      await dispatch(updateEntitiesEmployeeByIdV2({ id, payload: otherData }));

      successMessage('保存员工信息成功');
    } catch (e) {
      errorMessage('保存员工信息失败');
    }
  }
);
