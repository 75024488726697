import { createSlice } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../service/constants';
import { StoreFilterTypes } from './types';
import { loadEmployeeStoresFilter } from './thunks';

const initialState: StoreFilterTypes = {
  loadStoreStatus: API_REQUEST_STATUS.IDLE,
  pageSize: 10,
  pageNumber: 0,
  end: true,
  total: 0,
  ids: [],
};

export const { reducer, actions } = createSlice({
  name: 'employee/common',
  initialState,
  reducers: {},
  extraReducers: {
    [loadEmployeeStoresFilter.fulfilled.type]: (state, { payload }) => {
      const { pagination, ids } = payload || {};
      const { end, total } = pagination || {};

      state.end = end;
      state.total = total;
      state.ids = ids;
    },
  },
});

export default reducer;
