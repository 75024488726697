import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMerchantName } from '../../../../../../../redux/app-v2/merchant/selectors';
import { errorMessage } from '../../../../../../../common/utils/feedback';
import {
  getAssociationPlayersImportTasks,
  getAssociationPlayersImportTemplate,
} from './api-request';
import {
  getAssociationPlayersImportTasksPagination,
  getAssociationPlayersImportTasksPageSize,
} from './selectors';

export const loadAssociationPlayersImportTasks = createAsyncThunk(
  'clubAuthentication/import/loadAssociationPlayersImportTasks',
  async (
    changedParameters: {
      limit?: number;
      offset?: number;
    },
    { getState }
  ) => {
    try {
      const state = getState() as any;
      const merchantName: string = getMerchantName(state);
      const pagination = getAssociationPlayersImportTasksPagination(state);
      const result: any = await getAssociationPlayersImportTasks(merchantName, {
        ...pagination,
        ...changedParameters,
      });
      const { data } = result || {};

      return data;
    } catch (error) {
      errorMessage('获取导入任务列表失败');
    }
  }
);

export const updateAssociationPlayersImportTasksPageNumber = createAsyncThunk(
  'clubAuthentication/import/updateAssociationPlayersImportTasksPageNumber',
  async (pageNumber: number, { dispatch, getState }) => {
    try {
      const state: any = getState();
      const pageSize = getAssociationPlayersImportTasksPageSize(state);

      await dispatch(
        loadAssociationPlayersImportTasks({
          offset: pageNumber * pageSize,
        })
      );

      return pageNumber;
    } catch (error) {
      throw error;
    }
  }
);

export const updateAssociationPlayersImportTasksPageSize = createAsyncThunk(
  'clubAuthentication/import/updateAssociationPlayersImportTasksPageSize',
  async (pageSize: number, { dispatch }) => {
    try {
      await dispatch(
        loadAssociationPlayersImportTasks({
          offset: 0,
          limit: pageSize,
        })
      );

      return {
        pageNumber: 0,
        pageSize,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const loadAssociationPlayersImportTemplate = createAsyncThunk(
  'clubAuthentication/import/getImportTemplate',
  async (_, { getState }) => {
    try {
      const state = getState() as any;
      const merchantName: string = getMerchantName(state);
      const result: any = await getAssociationPlayersImportTemplate(
        merchantName
      );
      const { data } = result || {};

      return data;
    } catch (error) {
      errorMessage('获取模板失败');
    }
  }
);
