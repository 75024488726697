import _isEmpty from 'lodash/isEmpty';
import { createSlice } from '@reduxjs/toolkit';
import { loadReport, loadDashboardStores } from './thunks';

interface DashboardType {
  playerInfo: {
    cardPaid: number;
    cardNotPaid: number;
    newTodayAndCardPaid: number;
    newTodayAndCardNotPaid: number;
    todayPurchasedCardPlayerCount: number;
    newToday: number;
  };
  todayActivePlayerCount: number;
  dailyNewPlayerReports30days: object[];
  storesInfo: {
    pageNumber: number;
    pageSize: number;
    end: boolean;
    total: number;
    ids: string[];
    selectedStoreId: string;
  };
}

const initialState: DashboardType = {
  playerInfo: {
    cardPaid: 0,
    cardNotPaid: 0,
    newTodayAndCardPaid: 0,
    newTodayAndCardNotPaid: 0,
    todayPurchasedCardPlayerCount: 0,
    newToday: 0,
  },
  todayActivePlayerCount: 0,
  dailyNewPlayerReports30days: [],
  storesInfo: {
    pageNumber: 0,
    pageSize: 10,
    end: true,
    total: 0,
    ids: [],
    selectedStoreId: '',
  },
};

export const { reducer, actions } = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    storeSelected: (state, { payload }) => {
      state.storesInfo.selectedStoreId = payload;
    },
  },
  extraReducers: {
    [loadReport.fulfilled.type]: (state, { payload }) => {
      const {
        cardPaid,
        cardNotPaid,
        newTodayAndCardPaid,
        newTodayAndCardNotPaid,
        newToday,
        todayPurchasedCardPlayerCount,
      } = payload?.playerInfo || {};

      state.playerInfo.cardPaid = cardPaid || 0;
      state.playerInfo.cardNotPaid = cardNotPaid || 0;
      state.playerInfo.newTodayAndCardPaid = newTodayAndCardPaid || 0;
      state.playerInfo.newTodayAndCardNotPaid = newTodayAndCardNotPaid || 0;
      state.playerInfo.todayPurchasedCardPlayerCount =
        todayPurchasedCardPlayerCount || 0;
      state.playerInfo.newToday = newToday || 0;
      state.todayActivePlayerCount = payload?.todayActivePlayerCount || 0;
      state.dailyNewPlayerReports30days =
        payload?.dailyNewPlayerReports30days || [];
    },
    [loadDashboardStores.fulfilled.type]: (state, { payload }) => {
      const { pagination, ids } = payload || {};
      const { end, total } = pagination || {};

      state.storesInfo.end = end;
      state.storesInfo.total = total;

      (ids || []).forEach((id: string) => {
        const isExistedId = state.storesInfo.ids.includes(id);

        if (!isExistedId) {
          state.storesInfo.ids.push(id);
        }
      });

      if (_isEmpty(state.storesInfo.selectedStoreId) && ids) {
        state.storesInfo.selectedStoreId = ids[0];
      }
    },
  },
});

export default reducer;
