import _get from 'lodash/get';
import moment from 'moment';
import { createSelector } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../../../../../service/constants';
import { getAssociationClubsMapping } from '../common/selectors';

export const getAssociationAddPlayerStateData = (state: {
  association: { players: { add: any } };
}) => state.association.players.add;

export const getSearchClubsPageNumber = createSelector(
  getAssociationAddPlayerStateData,
  (associationAddPlayerStateData) =>
    _get(associationAddPlayerStateData, 'clubs.pageNumber', [])
);

export const getSearchClubsPageSize = createSelector(
  getAssociationAddPlayerStateData,
  (associationAddPlayerStateData) =>
    _get(associationAddPlayerStateData, 'clubs.pageSize', [])
);

export const getSearchClubsPagination = createSelector(
  getSearchClubsPageNumber,
  getSearchClubsPageSize,
  (pageNumber, pageSize) => {
    return { limit: pageSize, offset: pageNumber * pageSize };
  }
);

export const getSearchClubsIsRegisteredMerchant = createSelector(
  getAssociationAddPlayerStateData,
  (associationAddPlayerStateData) =>
    _get(associationAddPlayerStateData, 'clubs.isRegisteredMerchant', undefined)
);

export const getSearchClubsParameters = createSelector(
  getSearchClubsPagination,
  getSearchClubsIsRegisteredMerchant,
  (pagination, isRegisteredMerchant) => {
    return { ...pagination, isRegisteredMerchant };
  }
);

export const getSearchClubIds = createSelector(
  getAssociationAddPlayerStateData,
  (associationAddPlayerStateData) =>
    _get(associationAddPlayerStateData, 'clubs.ids', [])
);

export const getSearchClubsFilterOptions = createSelector(
  getAssociationClubsMapping,
  getSearchClubIds,
  (associationClubsMapping, searchClubIds) =>
    searchClubIds.map((id: string) => {
      const { name, memberId } = associationClubsMapping[id] || {};

      return {
        value: id,
        label: `${name} ${memberId ? `（${memberId}）` : ''}`,
      };
    })
);

export const getLoadClubsStatus = createSelector(
  getAssociationAddPlayerStateData,
  (associationAddPlayerStateData) =>
    _get(associationAddPlayerStateData, 'clubs.loadClubsStatus', [])
);

export const getIsSearchClubsFilterLoading = createSelector(
  getLoadClubsStatus,
  (loadClubsStatus) => loadClubsStatus === API_REQUEST_STATUS.PENDING
);

export const getAddAssociationPlayerData = createSelector(
  getAssociationAddPlayerStateData,
  (associationAddPlayerStateData) =>
    _get(associationAddPlayerStateData, 'data', {})
);

export const getAssociationAddPlayerName = createSelector(
  getAddAssociationPlayerData,
  (addPlayer) => _get(addPlayer, 'name', '')
);

export const getAssociationAddPlayerGender = createSelector(
  getAddAssociationPlayerData,
  (addPlayer) => _get(addPlayer, 'gender', '')
);

export const getAssociationAddPlayerPhone = createSelector(
  getAddAssociationPlayerData,
  (addPlayer) => _get(addPlayer, 'phone', '')
);

export const getAssociationAddPlayerIdCardNum = createSelector(
  getAddAssociationPlayerData,
  (addPlayer) => _get(addPlayer, 'idCardNum', '')
);

export const getAssociationAddPlayerIdCardType = createSelector(
  getAddAssociationPlayerData,
  (addPlayer) => _get(addPlayer, 'idCardType', '')
);

export const getAssociationAddPlayerRegisterDate = createSelector(
  getAddAssociationPlayerData,
  (addPlayer) => moment(_get(addPlayer, 'registerDate', ''))
);

export const getAssociationAddPlayerExpiredDate = createSelector(
  getAddAssociationPlayerData,
  (addPlayer) => moment(_get(addPlayer, 'expiredDate', ''))
);

export const getAssociationAddPlayerAssociationClubId = createSelector(
  getAddAssociationPlayerData,
  (addPlayer) => _get(addPlayer, 'associationClubId', '')
);

export const getCreateAssociationPlayerStatus = (state: {
  association: { players: { add: { createAssociationPlayerStatus: string } } };
}) => state.association.players.add.createAssociationPlayerStatus;

export const getIsAssociationAddPlayerCreating = createSelector(
  getCreateAssociationPlayerStatus,
  (createAssociationPlayerStatus) =>
    createAssociationPlayerStatus === API_REQUEST_STATUS.PENDING
);
