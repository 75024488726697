import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, Input, InputNumber, Modal, Radio, Switch } from 'antd';
import {
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { getIsBaseball } from '../../../../../../../../../redux/app-v2/merchant/selectors';

const NormalOptionSettingModal = (props: {
  id?: string;
  show?: boolean;
  preserve: boolean;
  deletable: boolean;
  data: any;
  categoryOptions: object[];
  onDelete?: () => void;
  onSave: (form: any) => void;
  onReset: (form: any) => void;
  onClose?: (form: any) => void;
  onChangeGameInfoCategoryOptionName: (name: string) => void;
  onChangeGameInfoCategoryOptionTeamPlayerCount: (
    teamPlayerCount: number
  ) => void;
  onChangeGameInfoCategoryOptionEnableChargePrice: (
    enableChargePrice: boolean
  ) => void;
  onChangeGameInfoCategoryOptionPrice: (price: number) => void;
  onChangeGameInfoCategoryOptionAssociationPlayerPrice: (
    associationPlayerPrice: number
  ) => void;
}) => {
  const [form] = Form.useForm();
  const {
    id,
    show,
    deletable,
    preserve = true,
    data,
    categoryOptions,
    onDelete,
    onSave,
    onReset,
    onClose,
    onChangeGameInfoCategoryOptionName,
    onChangeGameInfoCategoryOptionTeamPlayerCount,
    onChangeGameInfoCategoryOptionEnableChargePrice,
    onChangeGameInfoCategoryOptionPrice,
    onChangeGameInfoCategoryOptionAssociationPlayerPrice,
  } = props;
  const {
    gameOptionName,
    gameOptionTeamPlayerCount,
    gameOptionEnableChargePrice,
    gameOptionPrice,
    gameOptionAssociationPlayerPrice,
  } = data || {};
  const resetData = async () => {
    await form.resetFields();
    onReset(form);
  };

  const isBaseball = useSelector(getIsBaseball);
  const playerText = isBaseball ? '球员' : '运动员';

  return (
    <Modal
      centered
      className="GameOptionSettingDetailModal"
      maskClosable={false}
      visible={show}
      footer={[
        deletable && (
          <Button
            danger
            key="EnrollmentDetailsOptionModalDeleteButton"
            type="text"
            icon={<DeleteOutlined />}
            onClick={onDelete}
          >
            删除
          </Button>
        ),
        <Button
          key="EnrollmentDetailsOptionModalDeleteSubmit"
          type="primary"
          loading={false}
          onClick={() => {
            onSave(form);
          }}
        >
          保存
        </Button>,
        <Button
          ghost
          key="EnrollmentDetailsOptionModalDeleteCancel"
          type="primary"
          onClick={() => {
            onClose && onClose(form);
          }}
        >
          取消
        </Button>,
      ]}
      onOk={() => {
        onSave(form);
      }}
      onCancel={() => {
        onClose && onClose(form);
      }}
      afterClose={resetData}
    >
      <Form form={form} preserve={preserve}>
        <Form.Item
          label="项目名称"
          name={id ? [id, 'selectedGameOptionName'] : 'name'}
          initialValue={gameOptionName}
          rules={[
            {
              required: true,
              message: '项目名称不能为空',
            },
            {
              validator(_, optionName) {
                const duplicatedNameOptions = categoryOptions.filter(
                  (option: any) =>
                    option.name === optionName && option.id !== id
                );

                if (duplicatedNameOptions?.length > 0) {
                  return Promise.reject(new Error('项目名称不能重复'));
                }

                if (optionName && optionName.includes('.')) {
                  return Promise.reject(new Error('项目名称不能包含点'));
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            value={gameOptionName}
            placeholder="请输入项目名称（例如: 团体赛）"
            onChange={(e) => {
              onChangeGameInfoCategoryOptionName(e.target.value);
            }}
          />
        </Form.Item>
        <div className="flex flex-middle">
          <Form.Item label="人数">
            <Radio.Group
              onChange={(e) => {
                onChangeGameInfoCategoryOptionTeamPlayerCount(e.target.value);
              }}
              value={gameOptionTeamPlayerCount}
            >
              <Radio value={1}>1人</Radio>
              <Radio value={2}>2人</Radio>
              <Radio value={3}>3人</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="自定义">
            <InputNumber
              style={{ width: '100px', marginLeft: '10px' }}
              addonAfter="人"
              placeholder="4-60"
              controls={false}
              min={4}
              precision={0}
              value={
                gameOptionTeamPlayerCount >= 4
                  ? gameOptionTeamPlayerCount
                  : undefined
              }
              onChange={(value: any) => {
                onChangeGameInfoCategoryOptionTeamPlayerCount(value || 1);
              }}
            />
          </Form.Item>
        </div>
        <Form.Item
          label="设置价格"
          name={
            id
              ? [id, 'selectedGameOptionEnableChargePrice']
              : 'selectedGameOptionEnableChargePrice'
          }
          initialValue={gameOptionEnableChargePrice}
        >
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={gameOptionEnableChargePrice}
            onChange={(checked) => {
              onChangeGameInfoCategoryOptionEnableChargePrice(checked);
            }}
          />
        </Form.Item>
        {gameOptionEnableChargePrice ? (
          <>
            <Form.Item
              className="NestItemOptionNewModalFormPrice NestItemOptionNewModalFormItem"
              label={`非注册${playerText}价格（元）`}
              name={
                id ? [id, 'selectedGameOptionPrice'] : 'selectedGameOptionPrice'
              }
              initialValue={gameOptionPrice}
              rules={[
                {
                  required: true,
                  message: `非注册${playerText}价格不能为空`,
                },
                () => ({
                  validator(_, value) {
                    if (typeof value === 'number' && value <= 0) {
                      return Promise.reject(
                        new Error(`非注册${playerText}价格不能为0.00`)
                      );
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber
                addonBefore="￥"
                placeholder="356.50"
                controls={false}
                min={0}
                precision={2}
                value={gameOptionPrice}
                onChange={(value: any) => {
                  onChangeGameInfoCategoryOptionPrice(value);
                }}
              />
            </Form.Item>

            <Form.Item
              className="NestItemOptionNewModalFormPrice NestItemOptionNewModalFormItem"
              label={`注册${playerText}价格（元）`}
              initialValue={gameOptionAssociationPlayerPrice}
              name={
                id
                  ? [id, 'selectedGameOptionAssociationPlayerPrice']
                  : 'selectedGameOptionAssociationPlayerPrice'
              }
              rules={[
                {
                  required: true,
                  message: `注册${playerText}价格不能为空`,
                },
                () => ({
                  validator(_, value) {
                    if (typeof value === 'number' && value <= 0) {
                      return Promise.reject(
                        new Error(`注册${playerText}价格不能为0.00`)
                      );
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber
                addonBefore="￥"
                placeholder="356.50"
                controls={false}
                min={0}
                precision={2}
                value={gameOptionAssociationPlayerPrice}
                onChange={(value: any) => {
                  onChangeGameInfoCategoryOptionAssociationPlayerPrice(value);
                }}
              />
            </Form.Item>
          </>
        ) : null}
      </Form>
    </Modal>
  );
};

NormalOptionSettingModal.displayName = 'NormalOptionSettingModal';

export default NormalOptionSettingModal;
