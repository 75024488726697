import _isBoolean from 'lodash/isBoolean';
import { createSelector } from '@reduxjs/toolkit';
import {
  getFeaturesSetting,
  getIsFeaturesSettingPending,
} from '../../../../../redux/app-v2/featuresSetting/selectors';
import {
  getIsManager,
  getIsOperationsManager,
  getIsOwner,
} from '../../../../../redux/app-v2/user/selectors';
import {
  getIsLoadMerchantSubscriptionPending,
  getMerchantLimitedFeatures,
  getMerchantSubscriptionStatus,
  getMerchantSubscriptionTrialEnded,
} from '../../../../../redux/app-v2/merchant/selectors';
import { FUNCTION_FEATURES } from '../utils/constants';
import { MERCHANT_SUBSCRIPTION_STATUS } from '../../../../../common/constants';
import { RootState } from '../../../../../common/utils/types';

export const getFeatureSettingShownComponent = (state: RootState) =>
  state.functions.functions.data.shownComponent;

export const getAssociationHistoryTempTournamentId = (state: RootState) =>
  state.functions.functions.data.tempTournamentId;

export const getAssociationHistoryTournamentList = (state: RootState) =>
  state.functions.functions.data.tournamentList;

export const getAssociationHistoryImage = (state: RootState) =>
  state.functions.functions.data.image;

export const getDisplayAssociationHistoryTournamentList = createSelector(
  getAssociationHistoryTournamentList,
  (tournamentList) =>
    tournamentList.map((tournament: any) => ({
      label: tournament.title,
      value: tournament.id,
    }))
);

export const getDisplayFeatureSetting = createSelector(
  getFeaturesSetting,
  getMerchantLimitedFeatures,
  getMerchantSubscriptionStatus,
  getMerchantSubscriptionTrialEnded,
  (
    featuresSetting: any,
    merchantLimitedFeatures,
    merchantSubscriptionStatus,
    merchantSubscriptionTrialEnded
  ) => {
    const featuresSettingList: any = [];
    const featuresSettingKeys = Object.keys(featuresSetting).filter((key) =>
      _isBoolean(featuresSetting[key])
    );

    if (featuresSettingKeys.length <= 0) {
      return [];
    }

    featuresSettingKeys.forEach((key: string) => {
      const index = FUNCTION_FEATURES.indexOf(key);

      featuresSettingList[index] = {
        key,
        available: featuresSetting[key],
        trial:
          (!featuresSetting[key] &&
            !merchantLimitedFeatures?.includes(key) &&
            !merchantSubscriptionTrialEnded &&
            merchantSubscriptionStatus ===
              MERCHANT_SUBSCRIPTION_STATUS.trial) ||
          (!featuresSetting[key] &&
            merchantLimitedFeatures?.includes(key) &&
            merchantSubscriptionStatus === MERCHANT_SUBSCRIPTION_STATUS.active),
        provisioning:
          !featuresSetting[key] &&
          !merchantLimitedFeatures?.includes(key) &&
          merchantSubscriptionStatus === MERCHANT_SUBSCRIPTION_STATUS.active,
      };
    });

    return featuresSettingList.filter(
      (featuresSettingItem: any) => !!featuresSettingItem
    );
  }
);

export const getFeatureSettingVisible = createSelector(
  getDisplayFeatureSetting,
  getIsManager,
  getIsOwner,
  getIsOperationsManager,
  (displayFeaturesSetting: any, isManager, isOwner, isOperation) => {
    const featureSettingFilteredKeys = Object.keys(displayFeaturesSetting);

    return (
      featureSettingFilteredKeys.length > 0 &&
      (isManager || isOwner || isOperation)
    );
  }
);

export const getIsPosterAvailable = createSelector(
  getFeaturesSetting,
  (featuresSetting) => !!featuresSetting.posters
);

export const getIsAllFunctionsDataPending = createSelector(
  getIsFeaturesSettingPending,
  getIsLoadMerchantSubscriptionPending,
  (isFeaturesSettingPending, isLoadMerchantSubscriptionPending) => {
    return isFeaturesSettingPending || isLoadMerchantSubscriptionPending;
  }
);

export const getAssociationHistoryImageUploaderHidden = createSelector(
  getAssociationHistoryImage,
  (image: any) => {
    if (image.url) {
      return true;
    }

    if (image.status === 'uploading') {
      return true;
    }

    if (image.status === 'done') {
      return true;
    }

    return false;
  }
);
