import { get, put } from '../../../../../../service/api';

export const getOrder = async (payload: {
  merchantName: string;
  id: string;
}) => {
  try {
    const { merchantName, id } = payload;

    const result = await get(
      `/merchants/${merchantName}/market/purchase-orders/${id}`,
      {}
    );
    return result;
  } catch (e) {
    throw e;
  }
};

export const putOrder = async (payload: {
  merchantName: string;
  id: string;
  status: string;
}) => {
  try {
    const { merchantName, id, status } = payload;
    const result = await put(
      `/merchants/${merchantName}/market/purchase-orders/${id}`,
      { status }
    );
    return result;
  } catch (e) {
    throw e;
  }
};
