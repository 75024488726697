import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  playerInfo: {
    name: '',
    phone: '',
    remarkName: '',
    remarks: '',
  },
};

export const { reducer, actions } = createSlice({
  name: 'players/newPlayer',
  initialState,
  reducers: {
    setName: (state, action) => {
      state.playerInfo.name = action.payload;
    },
    setPhone: (state, action) => {
      state.playerInfo.phone = action.payload;
    },
    setRemarkName: (state, action) => {
      state.playerInfo.remarkName = action.payload;
    },
    setRemarks: (state, action) => {
      state.playerInfo.remarks = action.payload;
    },
    setPlayerInfo: (state, action) => {
      state.playerInfo.name = action.payload.name;
      state.playerInfo.phone = action.payload.phone;
      state.playerInfo.remarkName = action.payload.remarkName;
      state.playerInfo.remarks = action.payload.remarks;
    },
  },
  extraReducers: {},
});

export default reducer;
