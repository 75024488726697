import React, { useState } from 'react';
import { useWindowSize } from 'react-use';
import { useLocation, useNavigate } from 'react-router-dom';
import { Layout, Menu, Divider, Button } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { FrameTemplatePropsType } from './types';
import SkeletonList from '../skeletons/SkeletonList';
import './FrameTemplate.less';
import { isFunction } from 'lodash';

const { Header, Sider, Content } = Layout;

const FrameTemplate = ({
  logo,
  menuItemsInitialized,
  menuItems,
  userCenterItems,
  logOutInfo,
  selectedKeys,
  content,
  menuTitle,
  headerRightContent,
}: FrameTemplatePropsType) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const isMobileWidth = width < 768;
  const [collapsed, setCollapsed] = useState(isMobileWidth ? true : false);
  const defaultSelectedKeys = menuItems
    .filter((item) => (item.url ? location.pathname.includes(item.url) : false))
    .map((item) => item.key);

  return (
    <Layout className="frame">
      <Sider
        className={`frameMenuSide ${
          collapsed ? 'frameMenuSideCollapsed' : null
        }${isMobileWidth ? ' frameMenuSideMobile' : null}`}
        trigger={
          isMobileWidth ? (
            <>
              {collapsed ? (
                <MenuUnfoldOutlined style={{ color: '#595959' }} />
              ) : (
                <MenuFoldOutlined style={{ color: '#595959' }} />
              )}
            </>
          ) : null
        }
        collapsible
        onCollapse={(value) => setCollapsed(value)}
        collapsed={collapsed}
        collapsedWidth={isMobileWidth ? 0 : 80}
        width={256}
      >
        <div>
          <div className="frameMenuSlogan">
            <div className="frameMenuLogoContainer flex flex-bottom">
              <img
                className="frameLogo"
                src={collapsed ? logo.collapsedImage : logo.image}
                alt={logo.alt}
              />
              {collapsed ? null : (
                <span className="frameLogoText">{menuTitle}</span>
              )}
            </div>
          </div>

          <Divider className="frameMenuTitleDivider" />
          <Menu
            className="frameMenu"
            mode="inline"
            defaultSelectedKeys={defaultSelectedKeys}
            selectedKeys={selectedKeys}
            onClick={({ key }) => {
              const menuItem = menuItems.find((item) => item.key === key);
              const { url } = menuItem || {};

              if (url) {
                isMobileWidth && setCollapsed(true);
                navigate(url);
              }
            }}
          >
            {menuItemsInitialized ? (
              menuItems.map((item) => {
                const label = isFunction(item.label)
                  ? item.label(true)
                  : item.label;
                return (
                  <Menu.Item className="frameMenuItem" key={item.key}>
                    <>
                      {item.icon}
                      <span className="frameMenuItemText">{label}</span>
                    </>
                  </Menu.Item>
                );
              })
            ) : (
              <div className="frameMenuItemSkeletonContainer flex flex-column">
                <SkeletonList
                  skeletonNumber={3}
                  replaceComponentName="menu-items"
                />
              </div>
            )}
          </Menu>
        </div>

        <div>
          <Menu
            className="frameMenu frameMenuUserCenter"
            mode="inline"
            defaultSelectedKeys={['1']}
            selectedKeys={selectedKeys}
            onClick={({ key }) => {
              const userCenterItem = userCenterItems.find(
                (item) => item.key === key
              );
              const { url, redirectUrl } = userCenterItem || {};

              if (url) {
                isMobileWidth && setCollapsed(true);
                navigate(url);
              }

              if (redirectUrl) {
                window.location.href = redirectUrl;
              }
            }}
          >
            {userCenterItems.map((item) => {
              return (
                <Menu.Item className="frameMenuItem" key={item.key}>
                  <>
                    {item.icon}
                    <span className="frameMenuItemText">{item.label}</span>
                  </>
                </Menu.Item>
              );
            })}
          </Menu>

          <div className="frameMenuLogoutContainer">
            <div className="frameMenuLogout">
              <Button type="link" className="logoutButton">
                {logOutInfo.icon}
                {collapsed ? null : (
                  <span
                    className="frameMenuLogoutText"
                    onClick={logOutInfo.logOut}
                  >
                    {logOutInfo.label}
                  </span>
                )}
              </Button>
            </div>
          </div>
        </div>
      </Sider>
      <Layout>
        <Header className="header flex flex-middle flex-space-between">
          {isMobileWidth ? (
            <div />
          ) : (
            <>
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: 'trigger',
                  onClick: () => setCollapsed(!collapsed),
                }
              )}
            </>
          )}

          {headerRightContent ? (
            <div className="headerUserInfo flex flex-middle">
              {headerRightContent}
            </div>
          ) : null}
        </Header>
        <Content style={{ overflowY: 'scroll' }}>{content}</Content>
      </Layout>
    </Layout>
  );
};

FrameTemplate.displayName = 'FrameTemplate';

export default FrameTemplate;
