import { post } from '../../../../../service/api';

export const postUserWecomLogin = async (
  payload: { authCode: string | null; authType: string | null },
  options?: { isShow: false }
) => {
  try {
    const result = await post(
      `/merchants/employees.login-wecom`,
      payload,
      options
    );

    return result;
  } catch (e) {
    throw new Error('wecom login failed');
  }
};
