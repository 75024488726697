import React from 'react';
import { Tag } from 'antd';
import { useMount, useUnmount } from 'react-use';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../../common/utils/types';
import ContentDetail from '../../../../../../common/components/ContentDetail';
import TableResponsiveFrame from '../../../../../../common/components/tables/TableResponsiveFrame';
import {
  getHistoryPlayersAchievementImportTaskList,
  getHistoryPlayersAchievementImportTasksPageNumber,
  getHistoryPlayersAchievementImportTasksPageSize,
  getHistoryPlayersAchievementImportTasksTotal,
} from './redux/selectors';
import { actions as playerAchievementImportActions } from './redux';
import {
  loadHistoryPlayerAchievementImportTempTournament,
  loadHistoryPlayersAchievementImportTasks,
  updateHistoryPlayersAchievementImportTasksPageNumber,
  updateHistoryPlayersAchievementImportTasksPageSize,
} from './redux/thunks';
import ImportDescription from './components/ImportDescription';
import BreadcrumbRouter from '../../../../../../common/components/BreadcrumbRouter';
import './PlayersAchievementImport.less';

const createContentMarkup = (content: any) => {
  return { __html: content };
};
const PlayersAchievementImport = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { associationHistoryTournamentTemplateId }: any = useParams();
  const historyPlayersAchievementImportTaskList = useSelector(
    getHistoryPlayersAchievementImportTaskList
  );
  const pageNumber = useSelector(
    getHistoryPlayersAchievementImportTasksPageNumber
  );
  const pageSize = useSelector(getHistoryPlayersAchievementImportTasksPageSize);
  const total = useSelector(getHistoryPlayersAchievementImportTasksTotal);
  const columns: any = [
    {
      title: '开始时间',
      width: 140,
      dataIndex: 'createdTime',
      key: 'time',
      align: 'center',
    },
    {
      title: '操作人',
      width: 120,
      dataIndex: 'operatorName',
      key: 'name',
      align: 'center',
      render: (name: any) => (
        <div dangerouslySetInnerHTML={createContentMarkup(name)} />
      ),
    },
    {
      title: '状态',
      width: 120,
      dataIndex: 'status',
      key: 'archived',
      align: 'center',
      render: (status: any) => (
        <Tag
          color={
            status === 'imported'
              ? 'success'
              : status === 'failed'
              ? 'error'
              : 'processing'
          }
        >
          {status === 'imported'
            ? '已导入'
            : status === 'failed'
            ? '导入失败'
            : '导入中'}
        </Tag>
      ),
    },
    {
      title: '文件(含错误提示)',
      width: 120,
      dataIndex: 'fileUrl',
      key: 'remarkName',
      align: 'center',
      render: (_: any, record: any) =>
        record.status !== 'pending' ? (
          <a
            href={
              record.fileUrl.startsWith('http://')
                ? record.fileUrl.replace('http', 'https')
                : record.fileUrl
            }
          >
            点击下载
          </a>
        ) : null,
    },
  ];

  useMount(async () => {
    await dispatch(
      playerAchievementImportActions.associationHistoryTournamentTemplateIdUpdated(
        associationHistoryTournamentTemplateId
      )
    );
    dispatch(loadHistoryPlayersAchievementImportTasks({}));
    dispatch(loadHistoryPlayerAchievementImportTempTournament());
  });

  useUnmount(() => {
    dispatch(
      playerAchievementImportActions.associationHistoryTournamentTemplateIdUpdated(
        null
      )
    );
  });

  return (
    <ContentDetail
      title="导入运动员成绩表"
      headerLeft={
        <BreadcrumbRouter
          routes={[
            {
              path: 'functions',
              breadcrumbName: '功能',
            },
            {
              path: `history/players-achievement-import/${associationHistoryTournamentTemplateId}`,
              breadcrumbName: '导入运动员成绩表',
            },
          ]}
        />
      }
    >
      <div className="PlayersAchievementImport">
        <ImportDescription
          tempTournamentId={associationHistoryTournamentTemplateId}
        />
        <TableResponsiveFrame
          columns={columns}
          pageNumber={pageNumber}
          pageSize={pageSize}
          data={historyPlayersAchievementImportTaskList}
          dataItemsTotal={total}
          onChangePageSize={async (_: number, pageSize: number) => {
            await dispatch(
              updateHistoryPlayersAchievementImportTasksPageSize(pageSize)
            );
          }}
          onChangePageNumber={async (pageNumber: number) => {
            await dispatch(
              updateHistoryPlayersAchievementImportTasksPageNumber(pageNumber)
            );
          }}
          onRowClick={(record: any) => {
            return record.id ? record.id + Date.now() : 1;
          }}
        />
      </div>
    </ContentDetail>
  );
};

export default PlayersAchievementImport;
