import { get, patch } from '../../../service/api';
import { saveLocaleData } from '../../../service/local-api-request';

export const getMerchantInfo = async (
  payload: { merchantName: string },
  options?: { isShow: false }
) => {
  try {
    const { merchantName } = payload;
    const result = await get(`/merchants/${merchantName}`, {}, {}, options);

    return result;
  } catch (error) {
    throw error;
  }
};

export const getMerchantSubscription = async (
  payload: { merchantName: string },
  options?: { isShow: false }
) => {
  try {
    const { merchantName } = payload;
    const result = await get(`/subscriptions/${merchantName}`, {}, {}, options);

    return result;
  } catch (error) {
    throw error;
  }
};

export const patchBasicToTrail = async (
  merchantName: string,
  { pricePlanType }: any
) => {
  try {
    const result: any = await patch(
      `/subscriptions/${merchantName}/basic-to-trial`,
      {
        pricePlanType,
      }
    );

    return result;
  } catch (error) {
    throw error;
  }
};

export const saveLocaleMerchantInfo = (merchant: { merchantName: string }) =>
  saveLocaleData('long-term', 'merchant', merchant);
