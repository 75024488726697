import { createSelector } from '@reduxjs/toolkit';
import { getFeaturesSetting } from '../../../../../redux/app-v2/featuresSetting/selectors';
import {
  getIsManager,
  getIsOperationsManager,
  getIsOwner,
} from '../../../../../redux/app-v2/user/selectors';

export { getFeaturesSetting };

export const getFeatureSettingVisible = createSelector(
  getFeaturesSetting,
  getIsManager,
  getIsOwner,
  getIsOperationsManager,
  (featuresSetting: any, isManager, isOwner, isOperationsManager) => {
    const featureSettingFilteredKeys = Object.keys(featuresSetting).filter(
      (key) => !!featuresSetting[key]
    );

    return (
      featureSettingFilteredKeys.length > 0 &&
      (isManager || isOwner || isOperationsManager)
    );
  }
);

export const getIsClubAuthorizationVisible = createSelector(
  getIsOperationsManager,
  (isOperationsManager) => {
    return !isOperationsManager;
  }
);

export const getIsPlayersVisible = createSelector(
  getIsOperationsManager,
  (isOperationsManager) => {
    return !isOperationsManager;
  }
);
