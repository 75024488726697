import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Modal,
  Form,
  Input,
  Result,
  Alert,
  Radio,
  notification,
} from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { publicIp } from '../../../../../../service/apiIP';
import { AppDispatch } from '../../../../../../redux/store';
import { getUserAuthorization, getMerchantName } from '../../redux/selectors';
import { createOnePoster } from '../../redux/thunks';
import RichEditor from '../../../../../../common/components/RichEditor';
import './NewPoster.less';

const NewPoster: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const userAuthorization = useSelector(getUserAuthorization);
  const merchantName = useSelector(getMerchantName);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [posterName, setPosterName] = useState('');
  const [isEnabled, setIsEnabled] = useState(true);
  const [posterContent, setPosterContent] = useState(null);
  const [createdNewPoster, setCreatedNewPoster] = useState(false);
  const onCloseModal = useCallback(() => {
    setCreatedNewPoster(false);
    setPosterName('');
    setPosterContent(null);
    setIsModalVisible(false);

    form.setFieldsValue({ posterName: '' });
  }, []);

  return (
    <>
      <div className="NewPosterButtonContainer">
        <Button
          className="NewPosterButton"
          onClick={() => {
            setIsModalVisible(true);
          }}
        >
          <PlusCircleOutlined className="NewPosterButtonOutlinedIcon" />
          <span className="NewPosterButtonText">新增海报</span>
        </Button>
      </div>

      <Modal
        title="新增海报"
        visible={isModalVisible}
        onOk={async () => {
          try {
            await form.validateFields();
            const poster: any = {
              posterName,
              content: posterContent,
              isEnabled,
            };
            await dispatch(createOnePoster(poster));

            onCloseModal();
          } catch (e) {
            notification.error({ message: '添加海报失败' });
          }
        }}
        onCancel={onCloseModal}
        // If footer is undefined, the DOM will use ant.design default modal footer
        footer={createdNewPoster ? null : undefined}
      >
        {createdNewPoster ? (
          <>
            <Result
              className="NewPosterModalResult"
              status="success"
              title="成功创建海报"
            />
          </>
        ) : (
          <Form
            layout="vertical"
            form={form}
            name="newPosterForm"
            initialValues={{ posterName }}
          >
            <Form.Item
              className="formItem"
              label="海报标题"
              name="posterName"
              rules={[{ required: true, message: '请填写海报标题' }]}
            >
              <Input
                value={posterName}
                name="posterName"
                onChange={(e) => {
                  setPosterName(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item className="formItem" label="状态" name="isEnabled">
              <Radio.Group
                onChange={(e) => {
                  setIsEnabled(e.target.value);
                }}
                value={isEnabled}
                defaultValue={true}
              >
                <Radio value={true}>开启</Radio>
                <Radio value={false}>关闭</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item className="formItem" label="海报说明">
              <Alert
                className="NewPosterAlert"
                type="warning"
                message="海报内容主要在手机端显示使用，为了加载快速，请使用尽量小的图片，为了整洁美观，建议图片宽度不超过350px，文档总大小请不要超过2M！"
              />
              <RichEditor
                uploadHeader={{
                  Authorization: userAuthorization,
                }}
                uploadUrl={`${publicIp}/merchants/${merchantName}/posters/pic`}
                content={posterContent}
                setContent={(content) => {
                  setPosterContent(content);
                }}
                savePoster={() => {}}
              />
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default NewPoster;
