import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Button, Input, Form, Select, Checkbox, Tag } from 'antd';
import { AppDispatch } from '../../../../../../../../redux/store';
import {
  getClubInfoMappedData,
  getIsDisabledContent,
} from '../../redux/selectors';
import { updateSelectedClubNestItemSetting } from '../../redux/thunks';
import { actions as enrollmentDetailActions } from '../../redux';
import NestItemOptionNewModal from './NestItemOptionNewModal';
import NestItemOptionDetailModal from './NestItemOptionDetailModal';
import './EnrollmentClubFormSetting.less';

const EnrollmentClubFormSetting = ({ isNew }: { isNew: boolean }) => {
  const dispatch = useDispatch<AppDispatch>();
  const isDisabledContent = useSelector(getIsDisabledContent);
  const clubInfoData = useSelector(getClubInfoMappedData);

  return (
    <section className="EnrollmentDetailsAttribute EnrollmentClubFormSetting">
      <h5 className="EnrollmentDetailsAttributeTitle">俱乐部参赛信息</h5>
      <ul>
        {(clubInfoData || []).map((clubInfoItem: any) => {
          const {
            key: clubKey,
            id,
            description,
            default: clubDefault,
            saved: clubSaved,
            propertyName,
            propertyType,
            required,
            optionTypes,
            items,
          } = clubInfoItem;
          const unmodifiedClubItemSetting =
            clubDefault === false && clubSaved !== true;
          const isHiddenAttributeItem = clubDefault || clubSaved;

          if (clubDefault) {
            return null;
          }

          return (
            <li key={clubKey} className="EnrollmentDetailsAttributeItem">
              <div className="flex flex-top">
                <div className="EnrollmentDetailsAttributeBasic flex flex-middle flex__shrink-fixed">
                  <Form.Item initialValue={required}>
                    <Checkbox
                      checked={required}
                      disabled={clubSaved || (isDisabledContent && !isNew)}
                      onChange={(e) => {
                        dispatch(
                          enrollmentDetailActions.updateClubInfoProperty({
                            currentClubFormInfoId: id,
                            clubFormInfoItemProperty: {
                              required: e.target.checked,
                            },
                          })
                        );
                      }}
                    >
                      必填
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    className="EnrollmentDetailsAttributeNameItem"
                    name={[id, 'propertyName']}
                    initialValue={propertyName}
                    rules={
                      isHiddenAttributeItem
                        ? []
                        : [
                            {
                              required: true,
                              message: '填写项目名称',
                            },
                            () => ({
                              validator(_, value) {
                                if (value && value.includes('.')) {
                                  return Promise.reject(
                                    new Error('项目名称不能包含点')
                                  );
                                }

                                return Promise.resolve();
                              },
                            }),
                          ]
                    }
                  >
                    <Input
                      placeholder="项目名称(最多10个字)"
                      maxLength={10}
                      disabled={
                        isHiddenAttributeItem || (isDisabledContent && !isNew)
                      }
                      value={propertyName}
                      onChange={(e) => {
                        dispatch(
                          enrollmentDetailActions.updateClubInfoProperty({
                            currentClubFormInfoId: id,
                            clubFormInfoItemProperty: {
                              propertyName: e.target.value,
                            },
                          })
                        );
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    className="EnrollmentDetailsAttributeDescriptionItem"
                    name={[id, 'description']}
                    initialValue={description}
                  >
                    <Input
                      placeholder="项目描述(最多15个字)"
                      maxLength={15}
                      disabled={
                        isHiddenAttributeItem || (isDisabledContent && !isNew)
                      }
                      value={description}
                      onChange={(e) => {
                        dispatch(
                          enrollmentDetailActions.updateClubInfoProperty({
                            currentClubFormInfoId: id,
                            clubFormInfoItemProperty: {
                              description: e.target.value,
                            },
                          })
                        );
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    className="EnrollmentDetailsAttributeTypeItem"
                    rules={[{ required: true, message: '选择项目类别' }]}
                  >
                    <Select
                      dropdownMatchSelectWidth={100}
                      disabled={
                        isHiddenAttributeItem || (isDisabledContent && !isNew)
                      }
                      placeholder="选择项目类别"
                      value={propertyType || 'charactor'}
                      options={[
                        { value: 'charactor', label: '字符' },
                        { value: 'phoneNumber', label: '电话号码' },
                        { value: 'number', label: '数字' },
                        { value: 'option', label: '单选' },
                        { value: 'multipleOption', label: '多选' },
                        { value: 'nestedItems', label: '子项目' },
                        { value: 'imageUrl', label: '图片' },
                      ]}
                      onChange={(value) => {
                        dispatch(
                          enrollmentDetailActions.updateClubInfoProperty({
                            currentClubFormInfoId: clubKey,
                            clubFormInfoItemProperty: {
                              propertyType: value,
                            },
                          })
                        );
                      }}
                    />
                  </Form.Item>

                  {propertyType === 'option' ||
                  propertyType === 'multipleOption' ? (
                    <Form.Item
                      name={[id, 'optionTypes']}
                      initialValue={optionTypes}
                      rules={
                        isHiddenAttributeItem
                          ? []
                          : [
                              {
                                required: true,
                                message: '填写属性选项',
                              },
                              {
                                validator(_, optionValues) {
                                  if (!optionValues) {
                                    return Promise.resolve();
                                  }

                                  const inValidValues = optionValues.filter(
                                    (value: string) => value.length > 8
                                  );

                                  if (
                                    inValidValues &&
                                    inValidValues.length > 0
                                  ) {
                                    return Promise.reject(
                                      new Error('属性选项单个最多8个字')
                                    );
                                  }

                                  return Promise.resolve();
                                },
                              },
                            ]
                      }
                    >
                      <Select
                        mode="tags"
                        className="EnrollmentDetailsAttributeTypeOptionList"
                        disabled={
                          isHiddenAttributeItem || (isDisabledContent && !isNew)
                        }
                        placeholder="回车添加新标签，选项最多8个字"
                        options={optionTypes}
                        onChange={(value) => {
                          dispatch(
                            enrollmentDetailActions.updateClubInfoOptionType({
                              currentClubInfoId: id,
                              optionTypes: value,
                            })
                          );
                        }}
                      />
                    </Form.Item>
                  ) : null}
                  {propertyType === 'nestedItems' ? (
                    <Form.Item
                      className="EnrollmentDetailsOptionListFromItem"
                      name={[id, 'items']}
                      initialValue={items}
                      rules={
                        clubSaved
                          ? []
                          : [
                              {
                                validator() {
                                  if (items.length <= 0) {
                                    return Promise.reject(
                                      new Error('至少添加一个选项')
                                    );
                                  }

                                  return Promise.resolve();
                                },
                              },
                            ]
                      }
                    >
                      <ol className="EnrollmentDetailsOptionList flex flex-middle">
                        {items.map((item: any) => {
                          const {
                            id: nestItemId,
                            propertyName,
                            maxValue,
                          } = item || {};

                          return clubSaved ? (
                            <Tag key={nestItemId}>
                              <span className="EnrollmentDetailsOptionItemText">
                                {propertyName}
                              </span>
                              <span className="EnrollmentDetailsOptionItemText">{` (${maxValue})`}</span>
                              {clubSaved ? null : <EditOutlined />}
                            </Tag>
                          ) : (
                            <li
                              key={nestItemId}
                              className="EnrollmentDetailsOptionItem"
                            >
                              <Button
                                type="text"
                                size="small"
                                className="EnrollmentDetailsOptionItemButton"
                                onClick={async () => {
                                  await dispatch(
                                    updateSelectedClubNestItemSetting(
                                      nestItemId
                                    )
                                  );
                                  dispatch(
                                    enrollmentDetailActions.showSelectedClubInfoNestItemSettingModal()
                                  );
                                }}
                              >
                                <Tag>
                                  <span className="EnrollmentDetailsOptionItemText">
                                    {propertyName}
                                  </span>
                                  <span className="EnrollmentDetailsOptionItemText">{` (${maxValue})`}</span>
                                  {clubSaved ? null : <EditOutlined />}
                                </Tag>
                              </Button>
                            </li>
                          );
                        })}
                        {clubSaved ? null : (
                          <li className="EnrollmentDetailsOptionAddFieldItem flex flex-middle">
                            <Button
                              className="EnrollmentDetailsOptionAddFieldItemButton flex__fluid-content"
                              shape="circle"
                              size="small"
                              icon={<PlusOutlined />}
                              onClick={() => {
                                dispatch(
                                  enrollmentDetailActions.showClubNestItemSettingNewModal()
                                );
                                dispatch(
                                  enrollmentDetailActions.updateNewClubNestItemSettingClubId(
                                    id
                                  )
                                );
                              }}
                            />
                          </li>
                        )}
                      </ol>
                    </Form.Item>
                  ) : null}
                </div>

                {unmodifiedClubItemSetting ? (
                  <Button
                    type="link"
                    onClick={() => {
                      dispatch(enrollmentDetailActions.removeClubInfoItem(id));
                    }}
                  >
                    <MinusCircleOutlined className="EnrollmentDetailsRemoveIcon" />
                  </Button>
                ) : null}
              </div>
            </li>
          );
        })}
      </ul>
      {isDisabledContent && !isNew ? null : (
        <div className="EnrollmentDetailsAttributeItemAddButton">
          <Button
            type="dashed"
            block
            className="EnrollmentDetailsAttributeItemAddButtonContent"
            onClick={() => {
              dispatch(enrollmentDetailActions.addNewClubInfoItem());
            }}
          >
            添加俱乐部信息
            <PlusCircleOutlined />
          </Button>
        </div>
      )}

      <NestItemOptionNewModal
        onAdd={({ currentClubInfoId, option }) => {
          dispatch(
            enrollmentDetailActions.addNewClubInfoNestItemsOption({
              currentClubInfoId,
              option,
            })
          );
        }}
      />
      <NestItemOptionDetailModal
        onDelete={(itemId: string) => {
          dispatch(
            enrollmentDetailActions.removeSelectedClubNestItemSetting(itemId)
          );
        }}
        onUpdate={(item) => {
          dispatch(
            enrollmentDetailActions.updateSelectedClubNestItemSetting(item)
          );
        }}
      />
    </section>
  );
};

export default EnrollmentClubFormSetting;
