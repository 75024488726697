import { useDeepCompareEffect } from "react-use";

export const generateColor = (index: number): string => {
  const hue = (index * 137.5) % 360; // 使用素数以增加颜色差异性
  const saturation = 70; // 饱和度
  let lightness = 40 + (index % 7); // 亮度，每相邻3个索引增加一次

  // 设置亮度下限，确保颜色不会太淡
  lightness = Math.min(Math.max(lightness, 40), 60);

  // 转换为 HSL 颜色
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

export const generateDisabledColor = (index: number): string => {
  const baseColor = generateColor(index);

  // 将原始颜色转换为 HSL
  const hslRegex = /hsl\((\d+(\.\d+)?),\s*(\d+)%,\s*(\d+)%\)/;
  const match = baseColor.match(hslRegex);

  if (match) {
    const hue = parseFloat(match[1]);
    const saturation = parseInt(match[3]);
    let lightness = parseInt(match[4]);

    // 增加亮度，确保不超过 90%
    lightness = Math.max(lightness + 20, 80);

    // 生成新的 HSL 颜色
    const disabledColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    return disabledColor;
  } else {
    // 如果无法解析原始颜色，则返回原始颜色
    return baseColor;
  }
};

export const useEvents = (
  events: string | string[],
  handler: (event: Event) => void,
  target: Window | Document = document,
  options: boolean | AddEventListenerOptions = false,
) => {
  useDeepCompareEffect(() => {
    const eventList = Array.isArray(events) ? events : [events];

    const eventListener = (event: Event) => handler(event);

    eventList.forEach((eventName) => {
      target.addEventListener(eventName, eventListener, options);
    });

    return () => {
      eventList.forEach((eventName) => {
        target.removeEventListener(eventName, eventListener, options);
      });
    };
  }, [events, handler, options, target]);
};
