import { createSlice } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../service/constants';
import { loadStoreList, loadPosterList } from './thunks';

const initialState: {
  loadStoreStatus: string;
  postersInfo: {
    data: [];
    status: string;
  };
} = {
  loadStoreStatus: API_REQUEST_STATUS.IDLE,
  postersInfo: {
    data: [],
    status: API_REQUEST_STATUS.IDLE,
  },
};

export const { reducer, actions } = createSlice({
  name: 'activities/common',
  initialState,
  reducers: {},
  extraReducers: {
    [loadStoreList.pending.type]: (state: any) => {
      state.loadStoreStatus = API_REQUEST_STATUS.PENDING;
    },
    [loadStoreList.fulfilled.type]: (state: typeof initialState) => {
      state.loadStoreStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [loadStoreList.rejected.type]: (state: typeof initialState) => {
      state.loadStoreStatus = API_REQUEST_STATUS.REJECTED;
    },
    [loadPosterList.pending.type]: (state: any) => {
      state.postersInfo.status = API_REQUEST_STATUS.PENDING;
    },
    [loadPosterList.fulfilled.type]: (state: typeof initialState, action) => {
      state.postersInfo.data = action.payload;
      state.postersInfo.status = API_REQUEST_STATUS.FULFILLED;
    },
    [loadPosterList.rejected.type]: (state: typeof initialState) => {
      state.postersInfo.status = API_REQUEST_STATUS.REJECTED;
    },
  },
});

export default reducer;
