import { createAsyncThunk } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import { getMerchantName } from '../../../../../redux/app-v2/merchant/selectors';
import { getPoster } from '../../../../../service/http';
import { getPosterRequestData } from './selectors';
import { putPoster } from './api-request';

export const updateThePoster = createAsyncThunk(
  'poster/modifyPoster',
  async (_, { getState }) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);
      const poster = getPosterRequestData(state);
      const data = {
        posterName: poster.posterName,
        isEnabled: poster.isEnabled,
        content: poster.content,
        imageUrl: poster.imageUrl,
      };
      const res: any = await putPoster({
        merchantName,
        id: poster.id,
        poster: data,
      });

      message.success('修改成功');

      return res.data;
    } catch (e) {
      notification.error({
        message: '修改失败',
      });

      throw e;
    }
  }
);

export const getThePoster = createAsyncThunk(
  'poster/getPoster',
  async (id: any) => {
    try {
      const res: any = await getPoster(id);

      return res.data;
    } catch (e) {
      notification.error({
        message: '获取海报信息失败',
      });

      throw e;
    }
  }
);
