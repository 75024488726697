import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dataSource: [],
  total: 0,
  paginateNum: 1,
  pageSize: 10,
  header: [],
};

export const { reducer, actions } = createSlice({
  name: 'clubAuthentication/import',
  initialState,
  reducers: {
    setDataSource: (state, action) => {
      state.dataSource = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setPaginateNum: (state, action) => {
      state.paginateNum = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setHeader: (state, action) => {
      state.header = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  setDataSource,
  setTotal,
  setPaginateNum,
  setPageSize,
  setHeader,
} = actions;

export default reducer;
