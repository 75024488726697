import { post } from '../../../../../../../../service/api';
import { AddAssociationPlayerData } from './types';

export const postAssociationPlayer = async (
  merchantName: string,
  payload: AddAssociationPlayerData
) => {
  try {
    const result = await post(
      `/merchants/${merchantName}/association-players`,
      payload,
      {}
    );

    return result;
  } catch (error) {
    throw new Error('create association player failed');
  }
};
