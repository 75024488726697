import { PricePlanMappingType } from './types';

export const getPricePlansMapping = (state: {
  entities: { pricePlans: PricePlanMappingType };
}) => state.entities.pricePlans;

export const getPricePlans = (state: {
  entities: { pricePlans: PricePlanMappingType };
}) => {
  const pricePlansMapping = state.entities.pricePlans;
  const keys = Object.keys(pricePlansMapping);

  return keys.map((key) => pricePlansMapping[key]);
};

export const getPricePlanById = (
  state: { entities: { pricePlans: PricePlanMappingType } },
  id: string
) => state.entities.pricePlans[id];
