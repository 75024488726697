import { createSlice } from '@reduxjs/toolkit';
import { ALL_STORES_ENTITIES } from '../../../../../redux/entities/stores/constant';
import { API_REQUEST_STATUS } from '../../../../../service/constants';
import { loadRankingSettingsById } from './thunks';

const initialState = {
  id: '',
  rankingSettings: {
    name: '',
    description: '',
    storeIds: [],
    enabled: true,
    timeInterval: [],
    scoreUnit: '',
    ascending: true,
  },
  scoreLimit: {
    lowest: 0,
    highest: 9999999,
  },
  categories: [],
  stores: [ALL_STORES_ENTITIES],
  loadRankingDetailsByIdStatus: API_REQUEST_STATUS.IDLE,
};

export const { reducer, actions } = createSlice({
  name: 'rankingManagement',
  initialState,
  reducers: {
    updateRankingSettings: (state: any, action: any) => {
      state.rankingSettings = {
        ...state.rankingSettings,
        ...action.payload,
      };
    },
    resetAll: () => {
      return {
        id: '',
        rankingSettings: {
          name: '',
          description: '',
          storeIds: [],
          enabled: true,
          timeInterval: [],
          scoreUnit: '',
          ascending: true,
        },
        scoreLimit: {
          lowest: 0,
          highest: 9999999,
        },
        categories: [],
        stores: [ALL_STORES_ENTITIES],
        loadRankingDetailsByIdStatus: API_REQUEST_STATUS.IDLE,
      };
    },
    updateCategoryName: (state: any, { payload }: any) => {
      const { name, currentName } = payload;

      if (state.categories.length > 0) {
        state.categories = state.categories.map((category: any) => {
          if (category.name === currentName) {
            category.name = name;
          }

          return category;
        });
      } else {
        state.categories = [{ name }];
      }
    },

    updateCategoryTypeName: (state: any, { payload }: any) => {
      const { name, currentType, typeName } = payload;

      state.categories = state.categories.map((category: any) => {
        const targetIndex = category.categoryTypes.findIndex(
          (element: any) => element.name === currentType.name
        );
        if (category.name === name) {
          category.categoryTypes[targetIndex].name = typeName;
        }

        return category;
      });
    },
    updateCategoryTypeOutdated: (state: any, { payload }: any) => {
      const { name, currentType } = payload;
      const { name: typeName, disable } = currentType;

      state.categories = state.categories.map((category: any) => {
        const isTargetCategory = category.name === name;
        const targetTypeIndex = category.categoryTypes.findIndex(
          (element: any) => element.name === typeName
        );

        if (isTargetCategory && targetTypeIndex > -1 && disable) {
          category.categoryTypes[targetTypeIndex] = {
            isOutdated: true,
            ...category.categoryTypes[targetTypeIndex],
          };
        } else if (isTargetCategory && targetTypeIndex > -1 && !disable) {
          category.categoryTypes = category.categoryTypes.filter(
            (categoryType: any) => categoryType.name !== typeName
          );
        }

        return category;
      });
    },
    addNewCategoryType: (state: any, { payload }: any) => {
      const { name, currentType } = payload;

      state.categories = state.categories.map((category: any) => {
        let newCategoryTypes =
          category.categoryTypes && category.categoryTypes.length > 0
            ? category.categoryTypes
            : [];

        if (category.name === name) {
          newCategoryTypes.push(currentType);

          category.categoryTypes = newCategoryTypes;
        }

        return category;
      });
    },
    updateScoreLimit: (state: any, action: any) => {
      state.scoreLimit = {
        ...state.scoreLimit,
        ...action.payload,
      };
    },
  },
  extraReducers: {
    [loadRankingSettingsById.pending.type]: (state) => {
      state.loadRankingDetailsByIdStatus = API_REQUEST_STATUS.PENDING;
    },
    [loadRankingSettingsById.fulfilled.type]: (state, { payload }) => {
      const { id, merchantName, categories, scoreLimit, ...resPayload } =
        payload;

      state.id = id;
      state.rankingSettings = resPayload;
      state.categories = categories.map((category: any) => {
        category.disable = true;

        category.categoryTypes = category.categoryTypes.map(
          (categoryType: any) => {
            categoryType.disable = true;

            return categoryType;
          }
        );

        return category;
      });
      state.scoreLimit = scoreLimit;
      state.loadRankingDetailsByIdStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [loadRankingSettingsById.rejected.type]: (state) => {
      state = initialState;
      state.loadRankingDetailsByIdStatus = API_REQUEST_STATUS.REJECTED;
    },
  },
});
