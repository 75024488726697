import React, { useEffect } from 'react';
import { useMount, useUnmount } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Checkbox, Space, Skeleton, Select } from 'antd';
import { AppDispatch } from '../../../../redux/store';
import { ROLES } from '../../../../common/constants';
import './EmployeeDetail.less';
import {
  getIsManager,
  getIsOwner,
  getIsStoreManager,
  getIsUserInitialized,
} from '../../../../redux/app-v2/user/selectors';
import { ROLE_MAPPING } from '../../constants';
import { getEmployeeFilterStores } from '../../redux/common/selectors';
import { ALL_STORES_ENTITIES } from '../../../../redux/entities/stores/constant';
import { loadRoles } from '../../../../redux/app-v2/roles/thunks';
import { loadEmployeeStoresFilter } from '../../redux/common/thunks';
import { getRoles } from '../../../../redux/app-v2/roles/selectors';
import { getIsClubMerchant } from '../../../../redux/app-v2/merchant/selectors';
import { actions as employeeActions } from './redux';
import { loadEmployeeById, saveEmployeeById } from './redux/thunks';
import {
  getEmployeeDetailCanOperateSensitiveAssets,
  getIsEmployeeDetailRequestLoaded,
  getEmployeeDetail,
} from './redux/selectors';
import ContentDetail from '../../../../common/components/ContentDetail';
import BreadcrumbRouter from '../../../../common/components/BreadcrumbRouter';
import PhoneNumberInput from '../../../../common/components/PhoneNumberInput';
import './EmployeeDetail.less';

const EmployeeDetail: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { id = '' }: { id?: string } = useParams();
  const [form] = Form.useForm();
  const isUserInitialized = useSelector(getIsUserInitialized);
  const detail = useSelector(getEmployeeDetail);
  const { name, phone, storeId, roleName: detailRoleName, roleId } = detail;
  const canOperateSensitiveAssets = useSelector(
    getEmployeeDetailCanOperateSensitiveAssets
  );
  const isEmployeeDetailRequestLoaded = useSelector(
    getIsEmployeeDetailRequestLoaded
  );
  const isOwner = useSelector(getIsOwner);
  const isManager = useSelector(getIsManager);
  const isStoreManager = useSelector(getIsStoreManager);
  const roles = useSelector(getRoles);
  const roleName =
    detailRoleName || roles.find((role) => role.id === roleId)?.roleName || '';
  const canEditStore =
    isOwner ||
    (isManager && (roleName === ROLES.StoreManager || roleName === ROLES.Crew));
  const canEditRole =
    (isOwner && roleName !== ROLES.Owner) ||
    (isManager &&
      (roleName === ROLES.StoreManager || roleName === ROLES.Crew)) ||
    (isStoreManager && roleName === ROLES.Crew) ||
    roleName === ROLES.Crew;
  const employeeFilterStores = useSelector(getEmployeeFilterStores);

  useEffect(() => {
    if (isUserInitialized) {
      dispatch(loadEmployeeStoresFilter());
    }
  }, [dispatch, isUserInitialized]);

  const isClubMerchant = useSelector(getIsClubMerchant);

  useMount(async () => {
    await dispatch(loadRoles());
    dispatch(loadEmployeeById(id));
  });

  useUnmount(() => {
    dispatch(employeeActions.backEmployeeListPage());
  });

  const getSelectable = (option: string) => {
    switch (option) {
      case ROLES.Crew:
      case ROLES.Operation:
        return !isStoreManager;
      case ROLES.Manager:
        return isOwner;
      case ROLES.StoreManager:
        return isOwner || isManager;
      default:
        return false;
    }
  };

  return (
    <ContentDetail
      title="员工管理 / 员工详情"
      headerLeft={
        <BreadcrumbRouter
          routes={[
            {
              path: '/employees',
              breadcrumbName: '员工管理',
            },
            {
              path: `${id}`,
              breadcrumbName: '员工详情',
            },
          ]}
        />
      }
    >
      <section className="employeeDetailFormContainer">
        {isEmployeeDetailRequestLoaded ? (
          <Form layout="vertical" form={form} preserve={false}>
            <Form.Item
              className="formItem"
              label="员工昵称"
              initialValue={name}
              name={[id, 'name']}
              rules={[{ required: true, message: '请填写员工昵称' }]}
            >
              <Input
                value={name}
                onChange={(e) => {
                  dispatch(
                    employeeActions.updateEmployeeDetail({
                      name: e.target.value,
                    })
                  );
                }}
              />
            </Form.Item>
            <PhoneNumberInput
              name={[id, 'phone']}
              label="联系电话"
              placeholder="请输入员工联系电话"
              disabled={true}
              phoneNumber={phone}
              onChange={(phoneNumber: string) => {
                dispatch(
                  employeeActions.updateEmployeeDetail({ phone: phoneNumber })
                );
              }}
            />
            <Form.Item label="所在门店">
              <Select
                placeholder="请选择所在门店"
                value={
                  employeeFilterStores.length === 0
                    ? null
                    : storeId || ALL_STORES_ENTITIES.id
                }
                disabled={!canEditStore}
                onChange={(value) => {
                  dispatch(
                    employeeActions.updateEmployeeDetail({
                      storeId: value === ALL_STORES_ENTITIES.id ? '' : value,
                    })
                  );
                }}
                options={employeeFilterStores}
              />
            </Form.Item>
            <Form.Item label="权限">
              <Select
                value={roleName}
                disabled={!canEditRole}
                onChange={(value, role) => {
                  dispatch(
                    employeeActions.updateEmployeeDetail({
                      roleId: role.id,
                      roleName: value,
                    })
                  );
                }}
                options={roles
                  .filter(
                    (role: { roleName: string }) => ROLE_MAPPING[role.roleName]
                  )
                  .map(({ roleName, ...others }) => ({
                    value: roleName,
                    label: ROLE_MAPPING[roleName],
                    ...others,
                    disabled: !getSelectable(roleName),
                  }))}
              />
            </Form.Item>
            {isClubMerchant ? (
              <Form.Item>
                <Checkbox
                  checked={canOperateSensitiveAssets}
                  onChange={(e) => {
                    dispatch(
                      employeeActions.updateEmployeeDetail({
                        canOperateSensitiveAssets: e.target.checked,
                      })
                    );
                  }}
                >
                  可操作敏感权益
                </Checkbox>
              </Form.Item>
            ) : null}
            <Form.Item>
              <Space size="middle">
                <Button
                  type="primary"
                  onClick={async () => {
                    try {
                      await form.validateFields();

                      await dispatch(saveEmployeeById());
                    } catch (error) {
                      throw error;
                    }
                  }}
                >
                  保存
                </Button>
                <Button
                  className="outlineButton"
                  onClick={async () => {
                    await dispatch(employeeActions.backEmployeeListPage());

                    navigate(-1);
                  }}
                >
                  返回
                </Button>
              </Space>
            </Form.Item>
          </Form>
        ) : (
          <Skeleton active />
        )}
      </section>
    </ContentDetail>
  );
};

EmployeeDetail.displayName = 'EmployeeDetail';

export default EmployeeDetail;
