import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../service/constants';
import {
  getIsStoreManager,
  getUserBelongToStoreId,
} from '../../app-v2/user/selectors';
import { getMerchantName } from '../../../redux/app-v2/merchant/selectors';
import {
  getPlayers,
  getPlayerById,
  postPlayer,
  putPlayerById,
} from './api-request';
import { actions as playersEntitiesActions } from '../../entities/players';

export const loadPlayersV2: any = createAsyncThunk(
  'players/loadPlayersV2',
  async (
    {
      memberCardPaid = false,
      search = '',
      storeId = '',
      offset,
      limit,
    }: {
      memberCardPaid: boolean;
      search: string;
      storeId: string;
      offset: number;
      limit: number;
    },
    { dispatch, getState }
  ) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);
      const isStoreManager = getIsStoreManager(state);
      const userBelongToStoreId = getUserBelongToStoreId(state);
      const payload = {
        merchantName,
        memberCardPaid,
        search,
        storeId,
        offset,
        limit,
      };

      if (isStoreManager) {
        payload.storeId = userBelongToStoreId;
      }

      const { data, pagination }: any = await getPlayers(payload);

      dispatch(playersEntitiesActions.updatePlayerMapping(data));

      return { pagination, ids: data.map((item: any) => item.id) };
    } catch (e) {
      throw e;
    }
  }
);

export const loadPlayerByIdV2 = createAsyncThunk(
  'players/loadPlayerByIdV2',
  async (id: string, { dispatch, getState }) => {
    try {
      const state = getState();
      const merchantName = getMerchantName(state);
      const data: any = await getPlayerById({ merchantName, id });

      await dispatch(playersEntitiesActions.updatePlayerById(data));

      return id;
    } catch (e) {
      throw e;
    }
  }
);

export const createAnPlayer: any = createAsyncThunk(
  'players/createAnPlayer',
  async (payload: any, { getState }) => {
    try {
      const state = getState();
      const merchantName = getMerchantName(state);
      const result: any = await postPlayer({
        merchantName,
        ...payload,
      });
      const { data } = result;

      return data;
    } catch (e) {
      throw e;
    }
  }
);

export const updatePlayerByIdV2: any = createAsyncThunk(
  'players/updatePlayerByIdV2',
  async (
    { id, payload }: { id: string; payload: any },
    { dispatch, getState }
  ) => {
    try {
      const state = getState();
      const merchantName = getMerchantName(state);
      await putPlayerById({
        merchantName,
        id,
        data: payload,
      });

      await dispatch(
        playersEntitiesActions.updatePlayerById({ id, ...payload })
      );

      return {
        status: API_REQUEST_STATUS.FULFILLED,
      };
    } catch (e) {
      throw e;
    }
  }
);
