import _isNill from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import {
  PlusCircleOutlined,
  ExportOutlined,
  ImportOutlined,
} from '@ant-design/icons';
import { Input, Button, Tag, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppDispatch } from '../../../../common/utils/types';
import { downloadCSV } from '../../../../common/utils/csv';
import { getIsUserInitialized } from '../../../../redux/app-v2/user/selectors';
import { getMerchantDisplayName } from '../../../../redux/app-v2/merchant/selectors';
import { actions as playerListActions } from './redux';
import {
  getPlayerList,
  getPlayerListPageNumber,
  getPlayerListPageSize,
  getPlayerListTotalPlayers,
  getPlayerListFiltersMemberCardPaid,
} from './redux/selectors';
import {
  exportAllPlayers,
  loadPlayerList,
  updatePlayerListFilters,
  updatePlayerListPageNumber,
  updatePlayerListPageSize,
} from './redux/thunks';
import ContentDetail from '../../../../common/components/ContentDetail';
import TableResponsiveFrame from '../../../../common/components/tables/TableResponsiveFrame';
import EmployeeVerifyModal from '../../components/EmployeeVerifyModal';
import './PlayerList.less';

const PlayerList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [nextStepAction, setNextStepAction] = useState('');
  const isUserInitialized = useSelector(getIsUserInitialized);
  const merchantDisplayName = useSelector(getMerchantDisplayName);
  const playerList = useSelector(getPlayerList);
  const pageNumber = useSelector(getPlayerListPageNumber);
  const pageSize = useSelector(getPlayerListPageSize);
  const totalPlayers = useSelector(getPlayerListTotalPlayers);
  const filtersMemberCardPaid = useSelector(getPlayerListFiltersMemberCardPaid);
  const navigate = useNavigate();
  const columns: any = [
    {
      title: '会员备注名称',
      dataIndex: 'remarkName',
      key: 'remarkName',
      align: 'center',
      mobileAlign: 'contentHeaderLeft',
      render: (name: any) => {
        return <div dangerouslySetInnerHTML={{ __html: name }} />;
      },
    },
    {
      title: '会员联系电话',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      render: (phone: any) => (
        <div dangerouslySetInnerHTML={{ __html: phone }} />
      ),
    },
    {
      title: '状态',
      dataIndex: 'memberCardPaid',
      key: 'memberCardPaid',
      align: 'center',
      mobileAlign: 'contentHeaderRight',
      render: (memberCardPaid: any) =>
        memberCardPaid ? (
          <Tag color="success">已购</Tag>
        ) : (
          <Tag color="processing">未购</Tag>
        ),
    },
  ];
  const handleExportAllPlayers = async () => {
    await dispatch(exportAllPlayers()).then(async ({ payload }: any) => {
      if (!_isNill(payload)) {
        await downloadCSV(payload, {
          filename: `${merchantDisplayName}-用户信息列表.csv`,
        });
      }
    });
  };
  const handleGoToNewPlayerPage = () => {
    navigate('/players/new-player');
  };

  useEffect(() => {
    if (isUserInitialized) {
      dispatch(loadPlayerList({}));
    }
  }, [dispatch, isUserInitialized]);

  return (
    <ContentDetail
      title="会员管理"
      headerRight={
        <div className="text-right">
          <Button
            ghost
            type="primary"
            className="addButton"
            onClick={() => {
              navigate('/players/import');
            }}
          >
            <ImportOutlined />
            表格导入
          </Button>

          <Button
            ghost
            type="primary"
            className="addButton"
            onClick={() => {
              dispatch(playerListActions.showVerifyEmployeeModal());
              dispatch(playerListActions.setActionName('导出表格'));
              setNextStepAction('handleExportAllPlayers');
            }}
          >
            <ExportOutlined />
            导出表格
          </Button>
          <Button
            ghost
            type="primary"
            className="addButton"
            onClick={handleGoToNewPlayerPage}
          >
            <PlusCircleOutlined />
            补增会员
          </Button>
        </div>
      }
    >
      <div className="PlayerListFilterForm flex flex-middle flex__wrap">
        <div className="PlayerListSearchItem FormItem">
          <Input.Search
            allowClear
            placeholder="请输入关键字查找相关用户"
            onSearch={(value: string) => {
              dispatch(
                updatePlayerListFilters({
                  search: value,
                })
              );
            }}
          />
        </div>

        <div className="form__group">
          <Select
            dropdownMatchSelectWidth={100}
            value={filtersMemberCardPaid === null ? '' : filtersMemberCardPaid}
            options={[
              { value: '', label: '全部' },
              { value: true, label: '会员' },
              { value: false, label: '非会员' },
            ]}
            onChange={async (value: any) => {
              await dispatch(
                updatePlayerListFilters({
                  memberCardPaid: value === '' ? null : value,
                })
              );
            }}
          />
        </div>
      </div>
      <TableResponsiveFrame
        columns={columns}
        pageNumber={pageNumber + 1}
        pageSize={pageSize}
        data={playerList}
        dataItemsTotal={totalPlayers}
        onChangePageNumber={async (pageNumber: number) => {
          await dispatch(updatePlayerListPageNumber(pageNumber - 1));
        }}
        onChangePageSize={async (_: number, pageSize: number) => {
          await dispatch(updatePlayerListPageSize(pageSize));
        }}
        onRowClick={(record: any) => {
          navigate(`/players/${record.id}`);
        }}
      />
      <EmployeeVerifyModal
        nextStepFunction={() => {
          if (nextStepAction === 'handleExportAllPlayers') {
            handleExportAllPlayers();
          }
        }}
      />
    </ContentDetail>
  );
};

export default PlayerList;
