import { createSelector } from '@reduxjs/toolkit';
import _get from 'lodash/get';
import { RootState } from '../../../common/utils/types';
import { API_REQUEST_STATUS } from '../../../service/constants';
import { AssetsStateType } from './types';

export const getAssetsList = (state: RootState) =>
  _get(state.assets, 'dataItems', []);

export const getIsPlayerAssesDetailsDetailsVisible = (state: RootState) =>
  _get(state.assets, 'playerAssetsDetailsDetailsVisibility', false);

export const getSelectedAssetsId = (state: RootState) =>
  _get(state.assets, 'selectedAssetsId', []);

export const getDetails = (state: RootState) =>
  _get(state.assets, 'details', {});

export const getUpdateAssetsDetailsStatus = (state: RootState) =>
  _get(state.assets, 'updateAssetsDetails.status', null);

export const getCreateAssetsStatus = (state: RootState) =>
  _get(state.assets, 'createAssets.status', null);

export const getSelectedAssets = createSelector(
  getAssetsList,
  getSelectedAssetsId,
  getDetails,
  (assetList, selectedAssetsId, details) => {
    const selectedAssets = assetList.find(
      (assetsItem: AssetsStateType) => assetsItem.id === selectedAssetsId
    );

    return selectedAssets ? { ...selectedAssets, ...details } : {};
  }
);

export const getIsAssetsDetailsUpdating = createSelector(
  getUpdateAssetsDetailsStatus,
  (status) => status === API_REQUEST_STATUS.PENDING
);

export const getIsAssetsCreating = createSelector(
  getCreateAssetsStatus,
  (status) => status === API_REQUEST_STATUS.PENDING
);
