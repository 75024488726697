import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { RootState } from '../../../../../common/utils/types';
import { getMerchantName } from '../../../../../redux/app-v2/merchant/selectors';
import {
  setAssetsDictionary,
  setMerchandiseDic,
  setPaginateNum,
  setTableData,
  setTotal,
} from './index';
import { loadStoresV2 as loadEntitiesStores } from '../../../../../redux/entities/stores/thunks';
import { getAssets, getSearchingOrderList } from './api-request';
import { getSelectedStoreKeys } from './selectors';
import { message } from 'antd';
import { getMerchandises } from '../../merchandises/redux/api-request';

const statusNameMap: { [key: string]: string } = {
  created: '创建',
  paid: '已付款',
  finished: '已完成',
  cancelled: '已取消',
  failed: '失败',
};

const exchangeByTypeMap: { [key: string]: string } = {
  assets: '权益',
  voucher: '券码',
};

const exchangedTypeMap: { [key: string]: string } = {
  assets: '权益',
  voucher: '券码',
};

export const getTheAssets = createAsyncThunk(
  'miniMarket/orders/getAssets',
  async (_, { dispatch, getState }) => {
    const state: any = getState();
    const merchantName = getMerchantName(state);

    try {
      const res: any = await getAssets({ merchantName });
      if (res.message === 'OK') {
        // 将资产类型数组中的id作为key，资产类型名称作为value，存入redux
        const assetsDictionary = res.data.types.reduce(
          (acc: { [key: string]: string }, cur: any) => {
            acc[cur.id] = cur.type;
            return acc;
          },
          {}
        );
        dispatch(setAssetsDictionary(assetsDictionary));
      }
    } catch (e) {
      console.error('get stores error', e);
      throw e;
    }
  }
);

export const requestTableData = createAsyncThunk(
  'miniMarket/orders/requestTableData',
  async (param: any, { dispatch, getState }) => {
    const state: any = getState();
    const merchantName = getMerchantName(state);

    if (!param.from || !param.to) {
      dispatch(setTableData([]));
      dispatch(setTotal(0));
      return;
    }

    try {
      const list: any = await getSearchingOrderList({ merchantName, ...param });
      dispatch(
        setTableData(
          list.data.map((data: any) => {
            let newData = data;
            newData.createdTime = moment(data.createdTime).format(
              'YYYY/MM/DD HH:mm:ss'
            );
            newData.status = statusNameMap[data.status];
            newData.exchangeByType =
              exchangeByTypeMap[data.exchangeByType] +
              '/' +
              data.exchangeByAssetsType;
            newData.exchangedType = exchangedTypeMap[data.exchangedType];
            return newData;
          })
        )
      );
      dispatch(setTotal(list.pagination.total));
      if (list.pagination.end) {
        const PageSize = (getState() as RootState).marketOrder.pageSize;
        const newPage = Math.ceil(list.pagination.total / PageSize);
        newPage === 0
          ? dispatch(setPaginateNum(1))
          : dispatch(setPaginateNum(newPage));
      }
    } catch (e) {
      console.error('request table data error', e);
      throw e;
    }
  }
);

export const updateSelectedStoreKeys = createAsyncThunk(
  'miniMarket/orders/updateSelectedStoreKeys',
  async (keys: any, { getState }) => {
    const state: any = getState();
    const selectedStoreKeys: string[] = getSelectedStoreKeys(state);

    return keys.filter((item: string) => {
      if (!selectedStoreKeys.includes(item)) {
        return item;
      }
    });
  }
);

export const mounted = createAsyncThunk(
  'miniMarket/orders/mounted',
  async (
    payload: {
      isManager: boolean;
      isOwner: boolean;
      userBelongToStoreId: string;
    },
    { dispatch }
  ) => {
    try {
      if (payload.isManager || payload.isOwner) {
        await dispatch(
          loadEntitiesStores({
            offset: 0,
            limit: 500,
          }) as any
        );
      }

      await dispatch(updateSelectedStoreKeys([payload.userBelongToStoreId]));

      return;
    } catch (e) {
      console.error('audit error', e);

      throw e;
    }
  }
);

export const getAllMerchandises = createAsyncThunk(
  'miniMarket/orders/details/allMerchandises',
  async (_, { dispatch, getState }) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);

      const res: any = await getMerchandises({
        merchantName,
        offset: 0,
        limit: 100000,
      });
      if (res.message === 'OK') {
        const merchandises = res.data.reduce(
          (acc: { [key: string]: string }, cur: any) => {
            acc[cur.id] = cur.displayName;
            return acc;
          },
          {}
        );
        dispatch(setMerchandiseDic(merchandises));
      } else {
        message.error('获取礼品对照表失败');
      }
    } catch (e) {
      console.error('request table data error', e);
      throw e;
    }
  }
);
