import { configureStore, combineReducers } from '@reduxjs/toolkit';

import audits from '../../pages/Audits/redux';
import stores from '../../pages/stores/redux';

import marketOrder from '../../pages/redemptionMarkets/containers/orders/redux';
import marketOrderDetails from '../../pages/redemptionMarkets/containers/orders/orderDetails/redux';
import marketVoucher from '../../pages/redemptionMarkets/containers/vouchers/redux';

import player from '../../pages/players/redux';
import employee from '../../pages/employees/redux';
import merchandise from '../../pages/redemptionMarkets/containers/merchandises/redux';

import entities from '../entities';
import appV2 from '../app-v2';
import login from '../../pages/entrances/containers/WebLogin/redux';
import wecomLogin from '../../pages/entrances/containers/WecomLogin/redux';
import register from '../../pages/entrances/containers/Registered/redux';
import forgetPassword from '../../pages/entrances/containers/ForgetPassword/redux';
import container from '../../pages/index/redux';
import functions from '../../pages/functions/redux';
import ranking from '../../pages/rankings/redux';
import enrollment from '../../pages/association/containers/enrollments/redux';
import clubs from '../../pages/association/containers/clubs/redux';
import association from '../../pages/association/redux';
import dashboard from '../../pages/DashboardPage/redux';
import memberCards from '../../pages/MemberCards/redux';
import tournament from '../../pages/Tournament/redux';
import activities from '../../pages/activities/redux';
import poster from '../../pages/posters/redux';
import assets from '../../pages/PlayerAssets/redux';
import subscription from '../../pages/userSettings/Subscription/redux';

export const reducer = combineReducers({
  entities,
  appV2,
  login,
  wecomLogin,
  register,
  forgetPassword,
  container,
  functions,
  dashboard,
  ranking,
  enrollment,
  clubs,
  association,
  subscription,
  audits,
  stores,
  player,
  marketOrder,
  marketOrderDetails,
  marketVoucher,
  employee,
  merchandise,
  memberCards,
  tournament,
  activities,
  poster,
  assets,
});

const store = configureStore({
  reducer,
});

export default store;

export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
