import { createSlice } from '@reduxjs/toolkit';
import { AssociationClubState, AssociationPlayerState } from './types';

const initialState: {
  associationClubsMapping: {
    [clubId: string]: AssociationClubState;
  };
  associationPlayersMapping: {
    [associationPlayerId: string]: AssociationPlayerState;
  };
} = {
  associationClubsMapping: {},
  associationPlayersMapping: {},
};

export const { reducer, actions } = createSlice({
  name: 'association/players/common',
  initialState,
  reducers: {
    associationClubMappingUpdated: (
      state: { associationClubsMapping: { [x: string]: any } },
      action: { payload: any }
    ) => {
      const list = action.payload;
      const clubs: any = state.associationClubsMapping;

      list.forEach((club: { id: string }) => {
        const { id } = club || {};

        if (state.associationClubsMapping[id]) {
          clubs[id] = { ...state.associationClubsMapping[id], ...club };
        } else if (id) {
          clubs[id] = club;
        }
      });

      state.associationClubsMapping = clubs;
    },
    associationClubUpdatedById: (
      state: { associationClubsMapping: { [x: string]: any } },
      action: { payload: any }
    ) => {
      const { id } = action.payload || {};

      if (state.associationClubsMapping[id]) {
        state.associationClubsMapping[id] = {
          ...state.associationClubsMapping[id],
          ...action.payload,
        };
      } else if (id) {
        state.associationClubsMapping[id] = action.payload;
      }
    },
    associationPlayersMappingUpdated: (
      state: { associationPlayersMapping: { [x: string]: any } },
      action: { payload: any }
    ) => {
      const list = action.payload;
      const players: any = state.associationPlayersMapping;

      list.forEach((player: { id: string }) => {
        const { id } = player || {};

        if (state.associationPlayersMapping[id]) {
          players[id] = { ...state.associationPlayersMapping[id], ...player };
        } else if (id) {
          players[id] = player;
        }
      });

      state.associationPlayersMapping = players;
    },
    associationPlayerUpdatedById: (
      state: { associationPlayersMapping: { [x: string]: any } },
      action: { payload: any }
    ) => {
      const { id } = action.payload || {};

      if (state.associationPlayersMapping[id]) {
        state.associationPlayersMapping[id] = {
          ...state.associationPlayersMapping[id],
          ...action.payload,
        };
      } else if (id) {
        state.associationPlayersMapping[id] = action.payload;
      }
    },
  },
});

export default reducer;
