import _get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../../common/utils/types';
import { API_REQUEST_STATUS } from '../../../../../../../service/constants';

export const getEnrollmentClubsData = (state: RootState) =>
  state.enrollment.clubs;

export const getEnrollmentClubsPageNumber = createSelector(
  getEnrollmentClubsData,
  (enrollmentClubsData) => _get(enrollmentClubsData, 'pageNumber', 0)
);

export const getEnrollmentClubsPageSize = createSelector(
  getEnrollmentClubsData,
  (enrollmentClubsData) => _get(enrollmentClubsData, 'pageSize', 10)
);

export const getEnrollmentClubsFiltersText = createSelector(
  getEnrollmentClubsData,
  (enrollmentClubsData) => _get(enrollmentClubsData, 'filters.text', '')
);

export const getEnrollmentClubsFiltersIsSubmitted = createSelector(
  getEnrollmentClubsData,
  (enrollmentClubsData) =>
    _get(enrollmentClubsData, 'filters.isSubmitted', undefined)
);

export const getEnrollmentClubsParameters = createSelector(
  getEnrollmentClubsPageNumber,
  getEnrollmentClubsPageSize,
  getEnrollmentClubsFiltersText,
  getEnrollmentClubsFiltersIsSubmitted,
  (pageNumber, pageSize, text, isSubmitted) => ({
    offset: pageNumber * pageSize,
    limit: pageSize,
    text,
    isSubmitted,
  })
);

export const getEnrollmentClubsTotal = createSelector(
  getEnrollmentClubsData,
  (enrollmentClubsData) => _get(enrollmentClubsData, 'total', 0)
);

export const getEnrollmentClubsTableData = createSelector(
  getEnrollmentClubsData,
  (enrollmentClubsData) => _get(enrollmentClubsData, 'data', [])
);

export const getEnrollmentClubsEnrollmentId = createSelector(
  getEnrollmentClubsData,
  (enrollmentClubsData) => _get(enrollmentClubsData, 'enrollmentId', '')
);

export const getExportEnrollmentClubsStatus = createSelector(
  getEnrollmentClubsData,
  (enrollmentClubsData) =>
    _get(
      enrollmentClubsData,
      'exportEnrollmentClubsStatus',
      API_REQUEST_STATUS.IDLE
    )
);

export const getIsExportEnrollmentClubsLoading = createSelector(
  getExportEnrollmentClubsStatus,
  (exportEnrollmentClubsStatus) =>
    exportEnrollmentClubsStatus === API_REQUEST_STATUS.PENDING
);
