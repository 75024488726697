import _get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../../common/utils/types';
import { getFormatLocalTime } from '../../../../../../../common/utils/time-lib';

export const getHistoryClubsRankingImportStateData = (state: RootState) =>
  state.association.histories.clubsRankingImport;

export const getHistoryClubsRankingImportTaskList = createSelector(
  getHistoryClubsRankingImportStateData,
  (historyClubsRankingImportStateData) => {
    const tasks = _get(historyClubsRankingImportStateData, 'data.tasks', []);

    return tasks.map((task: any) => ({
      key: task.id,
      id: task.id,
      createdTime: getFormatLocalTime(task.createdTime),
      operatorName: task.operatorName,
      status: task.status,
      fileUrl: task.fileUrl,
    }));
  }
);

export const getHistoryClubsRankingImportTasksPageNumber = createSelector(
  getHistoryClubsRankingImportStateData,
  (historyClubsRankingImportStateData) =>
    _get(historyClubsRankingImportStateData, 'data.pageNumber', [])
);

export const getHistoryClubsRankingImportTasksPageSize = createSelector(
  getHistoryClubsRankingImportStateData,
  (historyClubsRankingImportStateData) =>
    _get(historyClubsRankingImportStateData, 'data.pageSize', [])
);

export const getHistoryClubsRankingImportTasksPagination = createSelector(
  getHistoryClubsRankingImportTasksPageNumber,
  getHistoryClubsRankingImportTasksPageSize,
  (pageNumber, pageSize) => ({
    offset: pageNumber * pageSize,
    limit: pageSize,
  })
);

export const getHistoryClubsRankingImportTasksTotal = createSelector(
  getHistoryClubsRankingImportStateData,
  (historyClubsRankingImportStateData) =>
    _get(
      historyClubsRankingImportStateData,
      'data.totalHistoryClubsRankingImportTasks',
      []
    )
);

export const getHistoryClubsRankingImportTemplateHeaders = createSelector(
  getHistoryClubsRankingImportStateData,
  (historyClubsRankingImportStateData) =>
    _get(historyClubsRankingImportStateData, 'data.headers', [])
);

export const getHistoryClubsRankingImportTournamentTemplateId = createSelector(
  getHistoryClubsRankingImportStateData,
  (historyClubsRankingImportStateData) =>
    _get(
      historyClubsRankingImportStateData,
      'data.associationHistoryTournamentTemplateId',
      null
    )
);

export const getHistoryClubsRankingImportTempTournamentName = createSelector(
  getHistoryClubsRankingImportStateData,
  (historyClubsRankingImportStateData) =>
    _get(
      historyClubsRankingImportStateData,
      'data.associationHistoryTournamentTemplateName',
      null
    )
);
