import { get, put } from '../../../service/api';
import { errorMessage, successMessage } from '../../../common/utils/feedback';

export const getFeaturesSetting = async (merchantName: string) => {
  try {
    const res = await get(
      `/merchants/${merchantName}/featureSetting`,
      {},
      {},
      { isShow: false }
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const putFeatureSettings = async (merchantName: string, param: any) => {
  try {
    const res: any = await put(
      `/merchants/${merchantName}/featureSetting`,
      param
    );
    if (res.code === 4) {
      errorMessage('开通失败');
      throw new Error('put feature settings failed');
    }
    if (res.code !== 0) {
      errorMessage('开通失败, 服务器错误');
      throw new Error('put feature settings failed');
    } else {
      successMessage('开通成功');
    }
    return res;
  } catch (error) {
    return error;
  }
};
