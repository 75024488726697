import React, { useEffect, useState } from 'react';
import { InfoCircleOutlined, FileImageOutlined } from '@ant-design/icons';
import {
  Button,
  Input,
  message,
  Modal,
  Radio,
  Space,
  Tooltip,
  Upload,
} from 'antd';
import { useMount } from 'react-use';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

// 引入编辑器样式
import 'braft-editor/dist/index.css';
// 引入编辑器组件
import BraftEditor from 'braft-editor';
import { ContentUtils } from 'braft-utils';
import { SYSTEM_CREATED_POSTER_TYPE } from '../../utils/constants';
import { getThePoster, updateThePoster } from './redux/thunks';
import { RcFile, UploadChangeParam, UploadProps } from 'antd/es/upload';
import { UploadFile } from 'antd/es/upload/interface';
import { publicIp } from '../../../../service/apiIP';
import {
  getContent,
  getIsEnabled,
  getMerchantName,
  getType,
  getPosterName,
  getUserAuthorization,
  getIsDisabledContent,
} from './redux/selectors';
import { setContent, setIsEnabled, setPosterName } from './redux';
import ContentDetail from '../../../../common/components/ContentDetail';
import BreadcrumbRouter from '../../../../common/components/BreadcrumbRouter';
import './Poster.less';

const Poster = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const posterType = useSelector(getType);
  const posterName = useSelector(getPosterName);
  const isEnabled = useSelector(getIsEnabled);
  const content = useSelector(getContent);
  const isDisabledContent = useSelector(getIsDisabledContent);
  const [editorState, setEditorState] = useState(
    BraftEditor.createEditorState(content)
  );

  const userAuthorization = useSelector(getUserAuthorization);
  const merchantName = useSelector(getMerchantName);

  useMount(() => {
    dispatch(getThePoster(id) as any);

    Modal.info({
      title: '文本编辑提示',
      content: (
        <div>
          <p>
            海报内容主要在手机端显示使用，为了加载快速，请使用尽量小的图片，为了整洁美观，建议图片宽度不超过350px，文档总大小请不要超过2M！
          </p>
        </div>
      ),
      onOk() {},
    });
  });

  useEffect(() => {
    if (content != editorState.toHTML()) {
      setEditorState(BraftEditor.createEditorState(content));
    }
  }, [content]);

  useEffect(() => {
    const bfContentEl = document.querySelector('.bf-content');
    const bfButtonsEl = document.querySelectorAll('.bf-container button');
    if (isDisabledContent && bfContentEl && bfButtonsEl) {
      bfContentEl.setAttribute('class', 'bf-content disabled');
      bfButtonsEl.forEach((el) => {
        el.setAttribute('disabled', 'true');
      });
    } else if (!isDisabledContent && bfContentEl && bfButtonsEl) {
      bfContentEl.setAttribute('class', 'bf-content');
      bfButtonsEl.forEach((el) => {
        el.removeAttribute('disabled');
      });
    }
  }, [isDisabledContent]);

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error('图片必须小于2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps['onChange'] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === 'done') {
      if (
        info.file.response.hasOwnProperty('error') &&
        'request entity too large' in info.file.response.error.message
      ) {
        message.error('上传的文件不能超过2MB!');
        return;
      }
      // Get this url from response in real world.
      dispatch(
        setContent(
          ContentUtils.insertMedias(editorState, [
            {
              type: 'IMAGE',
              url: info.file.response.data.imageUrl,
            },
          ])
        )
      );
    }
  };

  const extendControls: any = [
    {
      key: 'antd-uploader',
      type: 'component',
      component: (
        <Upload
          name="file"
          showUploadList={false}
          action={`${publicIp}/merchants/${merchantName}/posters/pic`}
          headers={{
            Authorization: userAuthorization,
          }}
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          <button
            type="button"
            className="control-item button upload-button"
            data-title="插入图片"
          >
            <FileImageOutlined />
          </button>
        </Upload>
      ),
    },
  ];

  const handleEditorChange = (editorState: any) => {
    setEditorState(editorState);
    dispatch(setContent(editorState.toHTML()) as any);
  };

  const controls: any = ['media', 'link', 'hr'];
  return (
    <ContentDetail
      title={`海报编辑 / ${posterName}`}
      headerLeft={
        <BreadcrumbRouter
          routes={[
            {
              path: '/posters',
              breadcrumbName: '海报编辑',
            },
            {
              path: `${id}`,
              breadcrumbName: `${posterName}`,
            },
          ]}
        />
      }
    >
      <div className="settingsInputsContainer">
        <div className="inputDiv" style={{ fontSize: '12px', padding: '0px' }}>
          <div>
            <span style={{ color: 'red' }}>*</span>
            <span>标题</span>
          </div>
          <div>
            <Input
              value={posterName}
              onChange={(e) => {
                dispatch(setPosterName(e.target.value));
              }}
              id="merchantName"
              className="searchInput"
              allowClear
              disabled={
                posterType === SYSTEM_CREATED_POSTER_TYPE || isDisabledContent
              }
              style={{ marginTop: '8px' }}
            />
          </div>
        </div>

        <div className="inputDiv" style={{ fontSize: '12px', padding: '0px' }}>
          <div>
            <span>状态</span>
          </div>
          <div style={{ marginTop: '8px' }}>
            <Radio.Group
              onChange={(e) => {
                dispatch(setIsEnabled(e.target.value));
              }}
              disabled
              value={isEnabled}
            >
              <Radio value={true}>开启</Radio>
              <Radio value={false}>关闭</Radio>
            </Radio.Group>
          </div>
        </div>

        <div
          className="richTextInput"
          style={{ fontSize: '12px', padding: '0px' }}
        >
          <div>
            <Space>
              <span>内容</span>
              <Tooltip
                placement="topLeft"
                title="海报内容主要在手机端显示使用，为了加载快速，请使用尽量小的图片，为了整洁美观，建议图片宽度不超过350px，文档总大小请不要超过2M！"
              >
                <InfoCircleOutlined style={{ color: 'red' }} />
              </Tooltip>
            </Space>
          </div>
          <div style={{ marginTop: '8px' }}>
            <BraftEditor
              style={{
                border: '1px solid #e8e8e8',
                borderRadius: '4px',
              }}
              contentStyle={{
                height: '300px',
              }}
              excludeControls={controls}
              extendControls={extendControls}
              value={editorState || '<p>请输入内容</p>'}
              onChange={handleEditorChange}
              onSave={() => {
                dispatch(updateThePoster() as any);
              }}
            />
          </div>
          <div style={{ marginTop: '36px', marginBottom: '20px' }}>
            <Space size={10}>
              {isDisabledContent ? null : (
                <Button
                  type="primary"
                  className="operateButton"
                  onClick={() => {
                    dispatch(updateThePoster() as any);
                  }}
                >
                  保存
                </Button>
              )}
            </Space>
            <Space size={10} style={{ marginLeft: '10px' }}>
              <Button
                type="default"
                className="operateButton cancelButton"
                onClick={() => {
                  navigate(-1);
                }}
              >
                返回
              </Button>
            </Space>
          </div>
        </div>
      </div>
    </ContentDetail>
  );
};

Poster.displayName = 'Poster';

export default Poster;
