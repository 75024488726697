import React from 'react';
import ContentDetail from '../../../common/components/ContentDetail';
import SubscriptionInfo from './components/SubscriptionInfo';
import SubscriptionPlan from './components/SubscriptionPlan';
import SubscriptionBill from './components/SubscriptionBill';
import SubscriptionHistory from './components/SubscriptionHistory';
import './Subscription.less';

const Subscription = () => (
  <ContentDetail className="Subscription flex flex-column" title="订阅">
    <div className="SubscriptionContent flex flex__fluid-content">
      <div className="SubscriptionPayment flex__shrink-fixed">
        <SubscriptionInfo />
        <SubscriptionPlan />
        <SubscriptionBill />
      </div>
      <div className="SubscriptionAfterPayment flex flex-column flex__fluid-content">
        <div
          typeof="primary"
          style={{ visibility: 'hidden', margin: '5px 0', minHeight: '32px' }}
        ></div>
        <SubscriptionHistory />
      </div>
    </div>
  </ContentDetail>
);

export default Subscription;
