import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMerchantName } from '../../../../../../../redux/app-v2/merchant/selectors';
import { errorMessage } from '../../../../../../../common/utils/feedback';
import {
  getHistoryClubsRankingImportTasks,
  getHistoryTempTournamentById,
} from './api-request';
import {
  getHistoryClubsRankingImportTournamentTemplateId,
  getHistoryClubsRankingImportTasksPagination,
  getHistoryClubsRankingImportTasksPageSize,
} from './selectors';

export const loadHistoryClubsRankingImportTasks = createAsyncThunk(
  'association/history/loadHistoryClubsRankingImportTasks',
  async (
    changedParameters: {
      limit?: number;
      offset?: number;
    },
    { getState }
  ) => {
    try {
      const state = getState() as any;
      const merchantName: string = getMerchantName(state);
      const tournamentTemplateId =
        getHistoryClubsRankingImportTournamentTemplateId(state);
      const pagination = getHistoryClubsRankingImportTasksPagination(state);
      const result: any = await getHistoryClubsRankingImportTasks(
        merchantName,
        {
          ...pagination,
          ...changedParameters,
          tempTournamentId: tournamentTemplateId,
        }
      );
      const { data } = result || {};

      return data;
    } catch (error) {
      errorMessage('获取导入任务列表失败');
    }
  }
);

export const updateHistoryClubsRankingImportTasksPageNumber = createAsyncThunk(
  'clubAuthentication/import/updateHistoryClubsRankingImportTasksPageNumber',
  async (pageNumber: number, { dispatch, getState }) => {
    try {
      const state: any = getState();
      const pageSize = getHistoryClubsRankingImportTasksPageSize(state);

      await dispatch(
        loadHistoryClubsRankingImportTasks({
          offset: pageNumber * pageSize,
        })
      );

      return pageNumber;
    } catch (error) {
      throw error;
    }
  }
);

export const updateHistoryClubsRankingImportTasksPageSize = createAsyncThunk(
  'clubAuthentication/import/updateHistoryClubsRankingImportTasksPageSize',
  async (pageSize: number, { dispatch }) => {
    try {
      await dispatch(
        loadHistoryClubsRankingImportTasks({
          offset: 0,
          limit: pageSize,
        })
      );

      return {
        pageNumber: 0,
        pageSize,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const loadHistoryClubsRankingImportTempTournament = createAsyncThunk(
  'association/history/loadHistoryClubsRankingImportTempTournament',
  async (_, { getState }) => {
    try {
      const state = getState() as any;
      const merchantName: string = getMerchantName(state);
      const tournamentTemplateId =
        getHistoryClubsRankingImportTournamentTemplateId(state);
      const result: any = await getHistoryTempTournamentById(
        merchantName,
        tournamentTemplateId
      );
      const { data } = result || {};

      return data;
    } catch (error) {
      throw error;
    }
  }
);
