import { get } from '../../../service/api';

export const getRoles = async (merchantName: string) => {
  try {
    const result: any = await get(
      `/merchants/${merchantName}/roles`,
      { offset: 0, limit: 10 },
      {},
      { isShow: false }
    );

    return result;
  } catch (error) {
    throw error;
  }
};
