import { useMount } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Form,
  Input,
  DatePicker,
  Button,
  Space,
  Row,
  Col,
  InputNumber,
  Select,
} from 'antd';
import { AppDispatch } from '../../../../redux/store';
import {
  getStoreList,
  getPosterList,
  getIsStoreListReloaded,
  getIsPosterListReload,
} from '../../redux/common/selectors';
import { loadStoreList, loadPosterList } from '../../redux/common/thunks';
import { actions as activityDetailActions } from './redux';
import { loadActivityById, saveActivityById } from './redux/thunks';
import { getActivityDetail } from './redux/selectors';
import ContentDetail from '../../../../common/components/ContentDetail';
import BreadcrumbRouter from '../../../../common/components/BreadcrumbRouter';
import './ActivityDetail.less';
import { getIsStoreManager } from '../../../../redux/app-v2/user/selectors';

const { TextArea } = Input;

const ActivityDetail: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const activity = useSelector(getActivityDetail);
  const storeList = useSelector(getStoreList);
  const posterList = useSelector(getPosterList);
  const isStoreListReloaded = useSelector(getIsStoreListReloaded);
  const isPosterListReload = useSelector(getIsPosterListReload);
  const isStoreManager = useSelector(getIsStoreManager);
  useMount(async () => {
    if (isStoreListReloaded) {
      dispatch(loadStoreList());
    }

    if (isPosterListReload) {
      dispatch(loadPosterList());
    }

    await dispatch(loadActivityById(id));
  });

  return (
    <ContentDetail
      title="活动 / 活动详情"
      headerLeft={
        <BreadcrumbRouter
          routes={[
            {
              path: '/activities',
              breadcrumbName: '活动',
            },
            {
              path: `${id}`,
              breadcrumbName: '活动详情',
            },
          ]}
        />
      }
    >
      <section className="activityDetailFormContainer">
        <Form
          layout="vertical"
          form={form}
          key={activity.id}
          initialValues={{ ...activity }}
        >
          <Form.Item className="formItem" label="活动名称" required>
            <Input
              value={activity.name}
              onChange={(e) => {
                dispatch(
                  activityDetailActions.updateActivityDetail({
                    name: e.target.value,
                  })
                );
              }}
            />
          </Form.Item>
          <Form.Item className="formItem" label="活动说明" required>
            <TextArea
              value={activity.description}
              onChange={(e) => {
                dispatch(
                  activityDetailActions.updateActivityDetail({
                    description: e.target.value,
                  })
                );
              }}
            />
          </Form.Item>
          <Row justify="space-between" gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                className="formItem"
                label="参加人数限制"
                name="maxPlayers"
                rules={[{ required: true, message: '请填写参加人数限制' }]}
              >
                <InputNumber
                  className="forItemContent"
                  min={1}
                  value={activity.maxPlayers}
                  onChange={(value) => {
                    dispatch(
                      activityDetailActions.updateActivityDetail({
                        maxPlayers: value,
                      })
                    );
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {!isStoreManager ? (
                <Form.Item className="formItem" label="所在门店">
                  <Select
                    placeholder="选择一个门店"
                    value={activity.storeId || '全部门店'}
                    onChange={(value) => {
                      dispatch(
                        activityDetailActions.updateActivityDetail({
                          storeId: value,
                        })
                      );
                    }}
                  >
                    {storeList.map((store: any) => (
                      <Select.Option key={store.id} value={store.id}>
                        {store.displayName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : null}
            </Col>
          </Row>
          <Row justify="space-between" gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item className="formItem" label="报名截止日期">
                <DatePicker
                  format="YYYY/MM/DD HH:mm:ss"
                  placeholder="报名截止日期"
                  defaultValue={activity.enrollDeadlineMoment}
                  value={activity.enrollDeadlineMoment}
                  onChange={(value: any) => {
                    dispatch(
                      activityDetailActions.updateActivityDetail({
                        enrollDeadline: value.format('YYYY/MM/DD HH:mm:ss'),
                      })
                    );
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item className="formItem" label="活动开始日期">
                <DatePicker
                  format="YYYY/MM/DD HH:mm:ss"
                  placeholder="活动开始日期"
                  defaultValue={activity.startTimeMoment}
                  value={activity.startTimeMoment}
                  onChange={(value: any) => {
                    dispatch(
                      activityDetailActions.updateActivityDetail({
                        startTime: value.format('YYYY/MM/DD HH:mm:ss'),
                      })
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item className="formItem" label="活动海报">
            <Select
              placeholder="选择一个活动海报"
              value={activity.posterId}
              onChange={(value) => {
                dispatch(
                  activityDetailActions.updateActivityDetail({
                    posterId: value,
                  })
                );
              }}
            >
              {posterList.map((poster: any) => (
                <Select.Option key={poster.id} value={poster.id}>
                  {poster.posterName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Space size="middle">
              <Button
                type="primary"
                onClick={() => {
                  dispatch(saveActivityById());
                }}
              >
                保存
              </Button>
              <Button
                className="outlineButton"
                onClick={async () => {
                  await dispatch(activityDetailActions.backActivityListPage());

                  navigate(-1);
                }}
              >
                返回
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </section>
    </ContentDetail>
  );
};

ActivityDetail.displayName = 'ActivityDetail';

export default ActivityDetail;
