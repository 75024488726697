import { combineReducers } from '@reduxjs/toolkit';
import { reducer as playersAchievementImport } from '../containers/PlayersAchievementImport/redux';
import { reducer as clubsRankingImport } from '../containers/ClubsRankingImport/redux';

const histories = combineReducers({
  playersAchievementImport,
  clubsRankingImport,
});

export default histories;
