import { get } from '../../../../../../../service/api';

export const getEmployeeList = async (merchantName: string, param: any) => {
  try {
    const res = await get(
      `/merchants/${merchantName}/employees`,
      param,
      {},
      { isShow: false }
    );
    return res;
  } catch (e) {
    throw e;
  }
};

export const getImportLogs = async (merchantName: string, param: any) => {
  try {
    const res = await get(
      `/merchants/${merchantName}/association-clubs/import/tasks`,
      param
    );
    return res;
  } catch (e) {
    throw e;
  }
};

export const getImportTemplate = async (merchantName: string) => {
  try {
    const res = await get(
      `/merchants/${merchantName}/association-clubs/import/template`,
      {}
    );
    return res;
  } catch (e) {
    throw e;
  }
};
