import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'antd';
import Poller from '../../../../../../../../common/utils/poller';
import { actions as tournamentsActions } from '../../redux';
import {
  getIsTargetsSettingModalShow,
  getIsDetailTargetsSettingModal,
  getIsGameNotStarted,
  getIsTargetsSettingEnabled,
  getQueryUpdateGameDetailRequestError,
} from '../../redux/selectors';
import {
  targetsSettingMounted,
  updateGameDetail,
  queryUpdateGameDetail,
  loadGameDetailRequestTargetsSettingModalHidden,
} from '../../redux/thunks';
import TargetSettings from './TargetSettings';
import TournamentLegend from './TournamentLegend';
import './TargetsSettingModal.less';
import { useInterval } from 'react-use';
import {DndContext,DndProvider}  from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'

let poller = null;
const TargetsSettingModal = () => {
  const dispatch = useDispatch();
  const isDetailTargetsSettingModal = useSelector(
    getIsDetailTargetsSettingModal
  );
  const isTargetsSettingModalShow = useSelector(getIsTargetsSettingModalShow);
  const isGameNotStarted = useSelector(getIsGameNotStarted);
  const queryUpdateGameDetailRequestError = useSelector(
    getQueryUpdateGameDetailRequestError
  );
  const handleCancelButtonClick = useCallback(() => {
    poller?.stop();
    if (isDetailTargetsSettingModal) {
      dispatch(loadGameDetailRequestTargetsSettingModalHidden());
    } else {
      dispatch(tournamentsActions.createGameRequestTargetsSettingModalHidden());
    }
    dispatch(tournamentsActions.loadGameDetailRequestReset());
    dispatch(tournamentsActions.createGameRequestTargetSettingsReset());
  }, [dispatch, isDetailTargetsSettingModal]);
  const handleUpdateTargetSetting = async () => {
    poller?.stop();
    if (isDetailTargetsSettingModal) {
      await dispatch(updateGameDetail());

      dispatch(tournamentsActions.loadGameDetailRequestReset());
    } else {
      dispatch(tournamentsActions.createGameRequestTargetsSettingModalHidden());
    }
  };

  useInterval(() => {
    isGameNotStarted &&
      isTargetsSettingModalShow &&
      !queryUpdateGameDetailRequestError && dispatch(queryUpdateGameDetail());
  }, 1000 * 30);

  useEffect(() => {
    if (isTargetsSettingModalShow) {
      dispatch(targetsSettingMounted());
    }
  }, [dispatch, isTargetsSettingModalShow]);

  return (
    <Modal
      centered
      // 不要改这个className有js在使用
      className="TournamentsTargetsSettingModal"
      width="98%"
      bodyStyle={{ display:'flex',flexFlow: 'column', gap: '10px'}}
      closable={false}
      visible={isTargetsSettingModalShow}
      footer={null}
    >
      <header className="flex flex-middle flex-space-between">
        <Button onClick={handleCancelButtonClick}>取消</Button>
        <h1>设置靶位排布</h1>
        {isGameNotStarted?<Button
          type="primary"
          onClick={() => {
            handleUpdateTargetSetting();
          }}
        >
          保存
        </Button> : <div />}
      </header>
      <DndProvider backend={HTML5Backend}>
        <TargetSettings />
        <TournamentLegend />
      </DndProvider>
    </Modal>
  );
};

TargetsSettingModal.displayName = 'TargetsSettingModal';

export default TargetsSettingModal;
