import React, { useState } from 'react';
import { Button, Popover, PopoverProps, Steps } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import TargetA from '../../../../../../../../../images/target-type-A.svg';
import TargetAB from '../../../../../../../../../images/target-type-AB.svg';
import TargetABC from '../../../../../../../../../images/target-type-ABC.svg';
import TargetABCD from '../../../../../../../../../images/target-type-ABCD.svg';
import { TARGET_LEGEND_COMPETITION_HALF } from '../../../utils/constants';
import {
  getGameLegendTournaments,
  getTargetLegends,
  getIsTargetsSettingEnabled,
} from '../../../redux/selectors';
// import '../TargetSettings/index.less';
import { generateColor } from '../../../utils';

interface SetTargetTypePopoverProps extends PopoverProps {
  onFinishPicker?: (value: {
    targetNumChildScope: ('A' | 'B' | 'C' | 'D')[];
  }) => void;
}

const SetTargetTypePopover: React.FC<SetTargetTypePopoverProps> = ({
  visible,
  trigger = 'click',
  onVisibleChange,
  onFinishPicker,
  children,
}) => {
  const isTargetsSettingEnabled = useSelector(getIsTargetsSettingEnabled);

  const onFinishPickerInternal = (type: 'A' | 'AB' | 'ABC' | 'ABCD') => {
    onVisibleChange && onVisibleChange(false);
    onFinishPicker &&
      onFinishPicker({
        targetNumChildScope: type.split('') as ('A' | 'B' | 'C' | 'D')[],
      });
  };

  return isTargetsSettingEnabled ? (
    <Popover
      visible={visible}
      trigger={trigger}
      destroyTooltipOnHide
      onVisibleChange={onVisibleChange}
      content={
        <div className="SetProjectTargetPopoverTarget">
          <div
            className="SetProjectTargetPopoverTargetItem"
            onClick={() => onFinishPickerInternal('A')}
          >
            <img
              className="SetProjectTargetPopoverTargetItemImage"
              src={TargetA}
              alt=""
            />
            <div className="SetProjectTargetPopoverTargetItemTitle">{`A型`}</div>
          </div>
          <div
            className="SetProjectTargetPopoverTargetItem"
            onClick={() => onFinishPickerInternal('AB')}
          >
            <img
              className="SetProjectTargetPopoverTargetItemImage"
              src={TargetAB}
              alt=""
            />
            <div className="SetProjectTargetPopoverTargetItemTitle">{`AB型`}</div>
          </div>
          <div
            className="SetProjectTargetPopoverTargetItem"
            onClick={() => onFinishPickerInternal('ABC')}
          >
            <img
              className="SetProjectTargetPopoverTargetItemImage"
              src={TargetABC}
              alt=""
            />
            <div className="SetProjectTargetPopoverTargetItemTitle">{`ABC型`}</div>
          </div>
          <div
            className="SetProjectTargetPopoverTargetItem"
            onClick={() => onFinishPickerInternal('ABCD')}
          >
            <img
              className="SetProjectTargetPopoverTargetItemImage"
              src={TargetABCD}
              alt=""
            />
            <div className="SetProjectTargetPopoverTargetItemTitle">{`ABCD型`}</div>
          </div>
        </div>
      }
    >
      {children}
    </Popover>
  ) : (
    <>{children}</>
  );
};

export default SetTargetTypePopover;
