import { createSlice } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../../../../../service/constants';
import { AssociationPlayerListState } from './types';
import {
  loadAssociationPlayerList,
  loadSearchClubsFilter,
  selectAssociationPlayerListSearchAssociationClubId,
  selectAssociationPlayerListSearchGender,
  updateAssociationPlayerListPageNumber,
  updateAssociationPlayerListPageSize,
  updateAssociationPlayerListSearchKeyword,
} from './thunks';

const initialState: AssociationPlayerListState = {
  data: {
    ids: [],
    pageNumber: 0,
    pageSize: 10,
    totalAssociationPlayers: 0,
  },
  clubs: {
    ids: [],
    pageNumber: 0,
    pageSize: 10,
    loadClubsStatus: API_REQUEST_STATUS.IDLE,
  },
  searchKeyword: '',
  selectedAssociationClubId: null,
  selectedGender: null,
  loadAssociationPlayerList: API_REQUEST_STATUS.IDLE,
};

export const { reducer, actions } = createSlice({
  name: 'association/players/list',
  initialState,
  reducers: {
    filtersDataReset: (state) => {
      state.searchKeyword = '';
      state.selectedAssociationClubId = null;
    },
  },
  extraReducers: {
    [loadSearchClubsFilter.pending.type]: (state) => {
      state.clubs.loadClubsStatus = API_REQUEST_STATUS.PENDING;
    },
    [loadSearchClubsFilter.fulfilled.type]: (state, { payload }) => {
      state.clubs.loadClubsStatus = API_REQUEST_STATUS.FULFILLED;
      const ids = payload || [];
      state.clubs.ids = ids;
    },
    [loadSearchClubsFilter.rejected.type]: (state) => {
      state.clubs.loadClubsStatus = API_REQUEST_STATUS.REJECTED;
    },
    [loadAssociationPlayerList.pending.type]: (state) => {
      state.loadAssociationPlayerList = API_REQUEST_STATUS.PENDING;
    },
    [loadAssociationPlayerList.fulfilled.type]: (state, { payload }) => {
      state.loadAssociationPlayerList = API_REQUEST_STATUS.FULFILLED;
      state.data.ids = payload.ids;
      state.data.totalAssociationPlayers = payload.pagination?.total;
    },
    [loadAssociationPlayerList.rejected.type]: (state) => {
      state.loadAssociationPlayerList = API_REQUEST_STATUS.REJECTED;
    },
    [updateAssociationPlayerListPageNumber.fulfilled.type]: (
      state,
      { payload }
    ) => {
      state.data.pageNumber = payload;
    },
    [updateAssociationPlayerListPageSize.fulfilled.type]: (
      state,
      { payload }
    ) => {
      state.data.pageNumber = payload.pageNumber;
      state.data.pageSize = payload.pageSize;
    },
    [updateAssociationPlayerListSearchKeyword.fulfilled.type]: (
      state,
      { payload }
    ) => {
      state.data.pageNumber = payload.pageNumber;
      state.searchKeyword = payload.keyword;
    },
    [selectAssociationPlayerListSearchAssociationClubId.fulfilled.type]: (
      state,
      { payload }
    ) => {
      state.data.pageNumber = payload.pageNumber;
      state.selectedAssociationClubId = payload.associationClubId || null;
    },
    [selectAssociationPlayerListSearchGender.fulfilled.type]: (
      state,
      { payload }
    ) => {
      state.data.pageNumber = payload.pageNumber;
      state.selectedGender = payload.gender;
    },
  },
});

export default reducer;
