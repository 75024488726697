import { useState } from 'react';
import { Button, Form, Input, Modal, Checkbox } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { createAssets } from '../../redux/thunks';
import { getIsAssetsCreating } from '../../redux/selectors';

const NewPlayerAsset = () => {
  const dispatch = useDispatch();
  const isAssetsCreating = useSelector(getIsAssetsCreating);
  const [showNewPlayerAssets, setShowNewPlayerAssets] = useState(false);
  const [form] = Form.useForm();
  const [type, setType] = useState('');
  const [unit, setUnit] = useState('');
  const [description, setDescription] = useState('');
  const [isSensitive, setIsSensitive] = useState(false);
  const handleCancelCreatePlayerAssets = async () => {
    setShowNewPlayerAssets(false);
  };
  const handleCreateNewPlayerAssets = async () => {
    try {
      await form.validateFields();
      await dispatch(
        createAssets({
          type,
          unit,
          description,
          isSensitive,
        }) as any
      ).then(async (response: any) => {
        const { payload } = response || {};
        const { code } = payload || {};

        if (code === 0) {
          setShowNewPlayerAssets(false);
        }
      });
    } catch (e) {
      throw e;
    }
  };
  const resetData = async () => {
    setType('');
    setUnit('');
    setDescription('');
    setIsSensitive(false);
    await form.resetFields();
  };

  return (
    <>
      <div className="NewAssetButtonContainer">
        <Button
          className="NewAssetButton"
          onClick={() => {
            setShowNewPlayerAssets(true);
          }}
        >
          <PlusCircleOutlined className="NewAssetButtonOutlinedIcon" />
          <span className="NewAssetButtonText">新增权益</span>
        </Button>
      </div>
      <Modal
        title="新增权益"
        destroyOnClose
        visible={showNewPlayerAssets}
        onCancel={handleCancelCreatePlayerAssets}
        onOk={handleCreateNewPlayerAssets}
        afterClose={() => {
          resetData();
        }}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={isAssetsCreating}
            onClick={handleCreateNewPlayerAssets}
          >
            新建
          </Button>,
          <Button key="back" onClick={handleCancelCreatePlayerAssets}>
            取消
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" preserve={false}>
          <Form.Item
            label="权益类型"
            name="type"
            initialValue={type}
            rules={[
              { required: true, message: '请输入权益类型' },
              {
                validator(_, value) {
                  if (!value) {
                    return Promise.resolve();
                  }

                  const maxCharacterLength = 10;
                  const cnCharacters = value.match(/[^\x00-\xff]/g);
                  let totalLength = value.length;

                  if (cnCharacters) {
                    const cnCharactersLength = cnCharacters.length;
                    const calculateCnLength = cnCharactersLength * 2;
                    const calculateEnLength = value.length - cnCharactersLength;
                    totalLength = calculateCnLength + calculateEnLength;
                  }

                  if (totalLength > maxCharacterLength) {
                    return Promise.reject(
                      new Error('权益类型最多为5个汉字或者10个英文字母')
                    );
                  }

                  const reg = /[`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/im;
                  if (reg.test(value)) {
                    return Promise.reject(
                      new Error('类型名称不能包含特殊字符')
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              showCount
              allowClear
              value={type}
              onChange={(e) => {
                setType(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item
            label="权益单位"
            name="unit"
            initialValue={unit}
            rules={[
              {
                validator(_, value) {
                  const maxCharacterLength = 6;
                  const cnCharacters = value.match(/[^\x00-\xff]/g);
                  let totalLength = value.length;

                  if (cnCharacters) {
                    const cnCharactersLength = cnCharacters.length;
                    const calculateCnLength = cnCharactersLength * 2;
                    const calculateEnLength = value.length - cnCharactersLength;
                    totalLength = calculateCnLength + calculateEnLength;
                  }

                  if (totalLength > maxCharacterLength) {
                    return Promise.reject(
                      new Error('权益类型最多为3个汉字或者6个英文字母')
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              showCount
              allowClear
              value={unit}
              onChange={(e) => {
                setUnit(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item label="描述" name="description" initialValue={description}>
            <Input.TextArea
              rows={4}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Form.Item>

          <Checkbox
            checked={isSensitive}
            onChange={(e) => {
              setIsSensitive(e.target.checked);
            }}
          >
            敏感权益
          </Checkbox>
        </Form>
      </Modal>
    </>
  );
};

NewPlayerAsset.displayName = 'NewPlayerAsset';

export default NewPlayerAsset;
