import { createSlice } from '@reduxjs/toolkit';
import {
  duplicatePosterDetail,
  loadPosterList,
  updatePageNumber,
  updatePageSize,
} from './thunks';
import { API_REQUEST_STATUS } from '../../../../../service/constants';

const initialState = {
  posterList: [],
  itemsTotal: 0,
  end: false,
  pageNumber: 0,
  pageSize: 10,
  loadPosterListStatus: API_REQUEST_STATUS.IDLE,
  duplicatePosterStatus: API_REQUEST_STATUS.IDLE,
  saveStatus: false,
};

export const { reducer, actions } = createSlice({
  name: 'posterList',
  initialState,
  reducers: {},
  extraReducers: {
    [updatePageNumber.fulfilled.type]: (state, { payload }) => {
      state.pageNumber = payload;
    },
    [updatePageSize.fulfilled.type]: (state, { payload }) => {
      state.pageNumber = 0;
      state.pageSize = payload;
    },
    [loadPosterList.pending.type]: (state) => {
      state.loadPosterListStatus = API_REQUEST_STATUS.PENDING;
    },
    [loadPosterList.fulfilled.type]: (state, { payload }) => {
      const { data, pagination } = payload;

      state.posterList = data;
      state.itemsTotal = pagination.total || data.length;
      state.end = pagination.end || false;
      state.loadPosterListStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [loadPosterList.rejected.type]: (state) => {
      state.loadPosterListStatus = API_REQUEST_STATUS.REJECTED;
    },
    [duplicatePosterDetail.pending.type]: (state) => {
      state.duplicatePosterStatus = API_REQUEST_STATUS.PENDING;
    },
    [duplicatePosterDetail.fulfilled.type]: (state) => {
      state.duplicatePosterStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [duplicatePosterDetail.rejected.type]: (state) => {
      state.duplicatePosterStatus = API_REQUEST_STATUS.REJECTED;
    },
  },
});

export default reducer;
