import _isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Modal, Popover, Row, Tooltip } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { getFormatRequestDataTime } from '../../../../../../../../../common/utils/time-lib';
import {
  Segment,
  Target,
  actions as createGameRequestActions,
} from '../../../redux';
import SetProjectTargetPopover from './SetProjectTargetPopover';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIsCreateTargetsSettingModal,
  getCreateGameRequestSelectedTargets,
  getGameDetailSelectedTargets,
  getCreateGameRequestShowSelectedTargetsSettingPopover,
  getGameDetailShowSelectedTargetsSettingPopover,
  getGameLegendTournaments,
  getGameDetailSelectedSetTargetsInfo,
  getCreateGameRequestSelectedSetTargetsInfo,
  getIsTargetsSettingEnabled,
  getTargetNums,
  getIsGameNotStarted,
} from '../../../redux/selectors';
import { generateColor, useEvents } from '../../../utils';
import SegmentSelectionPopover, {
  SegmentEditingView,
} from './SegmentSelectionPopover';
import './index.less';
import { TARGET_LEGEND_COMPETITION_HALF } from '../../../utils/constants';
import moment from 'moment';
import { useEvent, useInterval } from 'react-use';
import SegmentCellHeader from './SegmentHeader';

interface SegmentNormalCellProps {
  segment: Segment;
  segmentIndex: number;
  targetNums: string[];
  onInsertSegment?: (segment: Segment, position: 'top' | 'bottom') => void;
  onUpdateSegment?: (segment: Segment) => void;
  onDeleteSegment?: (segment: Segment) => void;
}
const SegmentNormalCell: React.FC<SegmentNormalCellProps> = ({
  segment,
  segmentIndex,
  targetNums,
  onInsertSegment,
  onDeleteSegment,
  onUpdateSegment,
}) => {
  const isTargetsSettingEnabled = useSelector(getIsTargetsSettingEnabled);
  return (
    <Row gutter={4} wrap={false} align="middle">
      <SegmentCellHeader
        segment={segment}
        segmentIndex={segmentIndex}
        onInsertSegment={onInsertSegment}
        onUpdateSegment={onUpdateSegment}
      />
      <Col
        style={{
          flex: `0 0 ${
            (525 / 6 / Math.min(22, segment.targets?.length || 1)) *
            (segment.targets?.length || 1)
          }%`,
          // flex: `0 0 ${Math.floor(length / 24) * 100 + (length % 24) / 0.24}%`,
          maxWidth: 'none',
        }}
      >
        <div
          className={`TargetSettingTableRound TargetSettingTable${segment.segmentType}`}
        />
      </Col>
      {isTargetsSettingEnabled ? (
        <Col
          style={{
            position: 'sticky',
            right: 0,
            zIndex: 1,
            background: 'white',
          }}
        >
          <Button
            disabled={!isTargetsSettingEnabled}
            onClick={(e) => onDeleteSegment && onDeleteSegment(segment)}
            shape="default"
            danger
            ghost
            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            onFocus={(e) => {
              e.currentTarget.blur();
            }}
            icon={<CloseOutlined style={{ color: '#ff0000' }} />}
          />
        </Col>
      ) : null}
    </Row>
  );
};

export default SegmentNormalCell;
