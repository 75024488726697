import _get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../../service/constants';
import { getPlayerById as getPlayerEntityById } from '../../../../../redux/entities/players/selectors';

export const getPlayerDetailId = (state: any) =>
  _get(state.player, 'details.data.id', null);

export const getPlayerChangedDetail = (state: any) =>
  _get(state.player, 'details.data', {});

export const getPlayerEntityDetail = (state: any) => {
  const id = _get(state.player, 'details.data.id', {});

  return getPlayerEntityById(state, id);
};

export const getPlayerDetailName = createSelector(
  getPlayerChangedDetail,
  getPlayerEntityDetail,
  (changedDetail, originalDetail) => {
    const { name } = changedDetail || {};
    const { name: originalDetailName } = originalDetail || {};

    return name || originalDetailName;
  }
);

export const getPlayerDetailPhone = createSelector(
  getPlayerChangedDetail,
  getPlayerEntityDetail,
  (changedDetail, originalDetail) => {
    const { phone } = changedDetail || {};
    const { phone: originalDetailPhone } = originalDetail || {};

    return phone || originalDetailPhone;
  }
);

export const getPlayerDetailRemarkName = createSelector(
  getPlayerChangedDetail,
  getPlayerEntityDetail,
  (changedDetail, originalDetail) => {
    const { remarkName } = changedDetail || {};
    const { remarkName: originalRemarkName } = originalDetail || {};

    return remarkName || originalRemarkName;
  }
);

export const getPlayerDetailRemarks = createSelector(
  getPlayerChangedDetail,
  getPlayerEntityDetail,
  (changedDetail, originalDetail) => {
    const { remarks } = changedDetail || {};
    const { remarks: originalRemarks } = originalDetail || {};

    return remarks || originalRemarks;
  }
);

export const getLoadPlayerDetailRequestStatus = (state: any) =>
  _get(state.player, 'details.loadRequestStatus', '');

export const getIsPlayerDetailRequestLoaded = createSelector(
  getLoadPlayerDetailRequestStatus,
  (loadRequestStatus) => loadRequestStatus === API_REQUEST_STATUS.FULFILLED
);

export const getPlayerMemberCards = (state: any) =>
  _get(state.player, 'details.memberCards', []);
