import _get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { ROLES } from '../../../common/constants';
import { API_REQUEST_STATUS } from '../../../service/constants';
import { UserSelectorsTypes } from './types';
import { getRoles } from '../roles/selectors';

export const getUser = (state: UserSelectorsTypes) =>
  _get(state.appV2, 'user', '');

export const getUserId = (state: UserSelectorsTypes) =>
  _get(state.appV2, 'user.id', '');

export const getUserName = (state: UserSelectorsTypes) =>
  _get(state.appV2, 'user.name', '');

export const getUserPhone = (state: UserSelectorsTypes) =>
  _get(state.appV2, 'user.phone', '');

export const getUserIsLoginStatus = (state: UserSelectorsTypes) =>
  _get(state.appV2, 'user.isLogin', false);

export const getUserAuthorization = (state: UserSelectorsTypes) =>
  _get(state.appV2, 'user.authorization', '');

export const getUserBelongToStoreId = (state: UserSelectorsTypes) =>
  _get(state.appV2, 'user.storeId', null);

export const getUserRoleId = (state: UserSelectorsTypes) =>
  _get(state.appV2, 'user.roleId', null);

export const getUserRoleName = (state: UserSelectorsTypes) =>
  _get(state.appV2, 'user.roleName', null);

export const getLoadUserAndRoleByIdStatus = (state: UserSelectorsTypes) =>
  _get(state.appV2, 'user.loadUserAndRoleByIdStatus', API_REQUEST_STATUS.IDLE);

export const getIsUserInitialized = createSelector(
  getLoadUserAndRoleByIdStatus,
  (loadUserAndRoleByIdStatus) =>
    [API_REQUEST_STATUS.FULFILLED, API_REQUEST_STATUS.REJECTED].includes(
      loadUserAndRoleByIdStatus
    )
);

export const getIsOwner = createSelector(
  getUserRoleName,
  getUserRoleId,
  getRoles,
  (roleName, roleId, roles) => {
    const roleOfOwnerInfo = roles.find((role) => role.roleName === ROLES.Owner);

    return roleOfOwnerInfo?.id === roleId || roleName === ROLES.Owner;
  }
);

export const getIsManager = createSelector(
  getUserRoleName,
  (roleName) => roleName === ROLES.Manager
);

export const getIsStoreManager = createSelector(
  getUserRoleName,
  (roleName) => roleName === ROLES.StoreManager
);

export const getIsOperationsManager = createSelector(
  getUserRoleName,
  (roleName) => roleName === ROLES.Operation
);

export const getIsCrew = createSelector(
  getUserRoleName,
  (roleName) => roleName === ROLES.Crew
);
