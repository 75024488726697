import { combineReducers } from '@reduxjs/toolkit';
import { reducer as details } from '../containers/EmployeeDetail/redux';
import { reducer as list } from '../containers/EmployeeList/redux';
import { reducer as common } from './common';

const reducer = combineReducers({
  common,
  details,
  list,
});

export default reducer;
