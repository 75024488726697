import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { Form } from 'antd';
import { PhoneNumber } from 'libphonenumber-js/mobile';
import zh from 'world_countries_lists/data/countries/zh/world.json';
import CountryPhoneInput, {
  CountryPhoneInputValue,
  ConfigProvider,
} from 'antd-country-phone-input';
import { COUNTRIES_PHONE_SHORT_CODE } from '../../constants/phone';
import 'antd-country-phone-input/dist/index.css';
import {
  getInfoByPhoneNumber,
  getInternationalPhoneNumberObject,
} from '../../utils/phone';

const PhoneNumberInput = ({
  name,
  size = 'middle',
  label,
  placeholder = '',
  disabled = false,
  phoneNumber = '',
  onChange,
}: {
  name: string | Array<any>;
  size?: 'large' | 'middle' | 'small';
  label?: string;
  placeholder: string;
  disabled?: boolean;
  phoneNumber?: any;
  onChange: Function;
}) => {
  let inputPhoneNumber = phoneNumber;

  useEffect(() => {
    if (
      (_isNil(phoneNumber) || phoneNumber === '') &&
      typeof name === 'string' &&
      document.getElementById(name)
    ) {
      const countryPhoneInput = document.getElementById(
        name
      ) as HTMLInputElement;

      if (countryPhoneInput && countryPhoneInput.value) {
        inputPhoneNumber = countryPhoneInput.value;
      }
    }
  }, [name, phoneNumber]);

  const infoParsePhoneNumber = getInfoByPhoneNumber(inputPhoneNumber);
  let countryShortCode = COUNTRIES_PHONE_SHORT_CODE.CN;
  let countryPhoneCode: number = 86;
  let phone =
    typeof infoParsePhoneNumber === 'string'
      ? infoParsePhoneNumber
      : phoneNumber;

  if (typeof infoParsePhoneNumber === 'object') {
    const { country, countryCallingCode, nationalNumber } =
      infoParsePhoneNumber;

    countryShortCode = typeof country === 'string' ? country : '';
    countryPhoneCode = parseInt(countryCallingCode);
    phone = nationalNumber;
  }

  const [phoneNumberObject, setPhoneNumberObject] =
    useState<CountryPhoneInputValue>({
      phone,
      code: countryPhoneCode,
      short: countryShortCode,
    });

  return (
    <ConfigProvider locale={zh}>
      <Form.Item
        className="ConsolePhoneNumberInput"
        label={label}
        initialValue={phoneNumberObject}
        name={name}
        rules={[
          { required: true, message: '请填写手机号码' },
          () => ({
            validator(_, value) {
              const { phone, code } = value;
              const internationalPhoneNumberObject: PhoneNumber | undefined =
                getInternationalPhoneNumberObject(code, phone);

              if (phone === '' || _isNil(phone)) {
                return Promise.reject(new Error(`请填写手机号码`));
              }

              if (
                !internationalPhoneNumberObject ||
                !internationalPhoneNumberObject.isValid()
              ) {
                return Promise.reject(new Error(`请填写正确格式的手机号码`));
              }

              return Promise.resolve();
            },
          }),
        ]}
      >
        <CountryPhoneInput
          inline
          size={size}
          disabled={disabled}
          placeholder={placeholder}
          value={phoneNumberObject}
          onChange={async (value) => {
            const { phone, code } = value;
            const internationalPhoneNumberObject: PhoneNumber | undefined =
              getInternationalPhoneNumberObject(code, phone);

            if (
              !internationalPhoneNumberObject ||
              !internationalPhoneNumberObject.isValid()
            ) {
              setPhoneNumberObject({
                short: countryShortCode,
              });
              onChange(phoneNumber);

              return;
            }

            setPhoneNumberObject(value);
            onChange(`+${code}${phone}`);
          }}
        />
      </Form.Item>
    </ConfigProvider>
  );
};

export default PhoneNumberInput;
