import _get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { ClubDetailState } from './types';
import { API_REQUEST_STATUS } from '../../../../../../../service/constants';

// get detail club data
export const getClubDetailData = (state: {
  clubs: { detail: { data: ClubDetailState } };
}) => state.clubs.detail.data;

export const getClubName = createSelector(getClubDetailData, (clubDetailData) =>
  _get(clubDetailData, 'name', '')
);

export const getClubAddress = createSelector(
  getClubDetailData,
  (clubDetailData) => _get(clubDetailData, 'address', '')
);

export const getClubMemberId = createSelector(
  getClubDetailData,
  (clubDetailData) => _get(clubDetailData, 'memberId', '')
);

export const getIsClubMemberIdNotEditable = (state: {
  clubs: { detail: { memberIdDisabled: boolean } };
}) => state.clubs.detail.memberIdDisabled;

export const getIsClubMemberIdRequired = (state: {
  clubs: { detail: { isMemberIdRequired: boolean } };
}) => state.clubs.detail.isMemberIdRequired;

export const getClubCompanyPhoneNumber = createSelector(
  getClubDetailData,
  (clubDetailData) => _get(clubDetailData, 'companyPhoneNumber', '')
);

export const getClubCompanyEmail = createSelector(
  getClubDetailData,
  (clubDetailData) => _get(clubDetailData, 'companyEmail', '')
);

export const getIsConfirmedMemberShipClub = createSelector(
  getClubDetailData,
  (clubDetailData) => _get(clubDetailData, 'confirmed', '')
);

export const getIsConfirmedMemberShipClubNotSwitchable = (_: any) => false;

export const getClubContactName = createSelector(
  getClubDetailData,
  (clubDetailData) => _get(clubDetailData, 'contactName', '')
);

export const getClubContactPhoneNumber = createSelector(
  getClubDetailData,
  (clubDetailData) => _get(clubDetailData, 'contactPhoneNumber', '')
);

export const getClubContactEmail = createSelector(
  getClubDetailData,
  (clubDetailData) => _get(clubDetailData, 'contactEmail', '')
);

export const getClubApplyingDate = createSelector(
  getClubDetailData,
  (clubDetailData) => _get(clubDetailData, 'applyingDate', '')
);

export const getIsClubApplyingDateNotEditable = (_: any) => true;

export const getClubCompanyName = createSelector(
  getClubDetailData,
  (clubDetailData) => _get(clubDetailData, 'companyName', '')
);

export const getClubMerchantDisplayName = createSelector(
  getClubDetailData,
  (clubDetailData) => _get(clubDetailData, 'merchantDisplayName', '')
);

export const getClubMerchantName = createSelector(
  getClubDetailData,
  (clubDetailData) => _get(clubDetailData, 'clubMerchantName', '')
);

export const getClubRemark = createSelector(
  getClubDetailData,
  (clubDetailData) => _get(clubDetailData, 'remark', '')
);

export const getClubLogo = createSelector(getClubDetailData, (clubDetailData) =>
  _get(clubDetailData, 'logo', '')
);

export const getLoadClubDetailStatus = (state: any) =>
  state.clubs.detail.loadClubDetailStatus;

export const getUpdateClubDetailStatus = (state: {
  clubs: { detail: { updateClubDetailStatus: string } };
}) => state.clubs.detail.updateClubDetailStatus;

export const getIsClubDetailUpdating = createSelector(
  getUpdateClubDetailStatus,
  (status) => status === API_REQUEST_STATUS.PENDING
);

export const getIsClubDetailLoaded = createSelector(
  getLoadClubDetailStatus,
  (loadClubDetailStatus) =>
    loadClubDetailStatus === API_REQUEST_STATUS.FULFILLED
);
