import _isEqual from 'lodash/isEqual';
import { createSlice, current } from '@reduxjs/toolkit';
import {
  loadTournamentList,
  loadEnrollmentList,
  loadEnrollmentDetailsById,
  loadEnrollmentPlayersSummaryById,
  loadEnrollmentPlayersUnclassified,
  loadEmployeeList,
  createGame,
  loadGameById,
  updateGameDetail,
  loadGameLegend,
  goToNextSegment,
  resetQueryUpdateGameDetailError,
  queryUpdateGameDetail,
  loadGameDetailRequestTargetsSettingModalHidden,
  loadGameDetailRequestDetailModalHidden,
  createGameRequestCreateModalHidden,
  updateGameDetailSummary,
} from './thunks';
import { API_REQUEST_STATUS } from '../../../../../../../service/constants';
import moment from 'moment';

type loadTournamentListRequestTypes = {
  data: {
    list: any[];
    searchInputText: string;
    pageSize: number;
    pageNumber: number;
    total: number;
  };
  status: string | null;
  error: string | null;
};
type loadEnrollmentListRequestTypes = {
  data: {
    list: any[];
    searchInputText: string;
    pageSize: number;
    pageNumber: number;
  };
  status: string | null;
  error: string | null;
};
type loadEnrollmentPlayersSummaryByIdRequestTypes = {
  status: string | null;
  error: string | null;
};
type loadEnrollmentPlayersUnclassifiedRequestTypes = {
  data: any[];
  status: string | null;
  error: string | null;
};
type loadEmployeeListRequestTypes = {
  data: {
    ids: any[];
    pageSize: number;
    pageNumber: number;
  };
  status: string | null;
  error: string | null;
};
type loadGameLegendRequestTypes = {
  data: any;
  status: string | null;
  error: string | null;
};
type loadGameDetailRequestTypes = {
  data: any;
  localData: {
    selectedGameId: string | null;
    showDetailModal: boolean;
    showTargetsSettingModal: boolean;
    selectedTargets: { [key: number]: string[] };
    showSelectedTargetSettingPopover: boolean;
    selectedSetTargetsInfo: object | null;
  };
  status: string | null;
  error: string | null;
};
type createGameRequestTypes = {
  data: {
    targetNums: string[];
    segments: Segment[];
  };
  localData: {
    selectedEnrollmentId: string | null;
    showCreateModal: boolean;
    showTargetsSettingModal: boolean;
    selectedTargets: { [key: number]: string[] };
    showSelectedTargetSettingPopover: boolean;
    selectedSetTargetsInfo: object | null;
  };
  status: string | null;
  error: string | null;
};
type goToNextSegmentRequestTypes = {
  status: string | null;
  error: string | null;
};
type queryUpdateGameDetailTypes = {
  status: string | null;
  error: string | null;
};

export type SegmentTypes =
  | 'rest'
  | 'preCompetitionPractice'
  | 'preCompetitionAdjustment'
  | 'competition'
  | 'customization';

export const allSegments: SegmentTypes[] = [
  'preCompetitionPractice',
  'preCompetitionAdjustment',
  'rest',
  'competition',
  'customization',
];

export const getRoundName = (segmentType: SegmentTypes) => {
  let roundName = '';
  switch (segmentType) {
    case 'rest':
      roundName = '赛间休息';
      break;
    case 'preCompetitionPractice':
      roundName = '赛前练习';
      break;
    case 'preCompetitionAdjustment':
      roundName = '靶位调整';
      break;
    case 'competition':
      roundName = '正式比赛';
      break;
    case 'customization':
      roundName = '其他事件';
      break;
  }
  return roundName;
};


export type Segment = {
  segmentType: SegmentTypes;
  displayName: string;
  startTime: string;
  endTime: string;
  targets?: Target[];
};

export type Target = {
  targetNum: string;
  targetNumChildScope?: string[];
  archeryTournamentRoundSettings?: ArcheryTournamentRoundSettings;
};

export type ArcheryTournamentRoundSettings = {
  roundNumber: number;
  isQualifying: boolean;
  competitionHalf: string;
  categoryName: string;
  optionName: string;
  roundName?: string;
  teamPlayerCount: number;
};
const initialState: {
  loadTournamentListRequest: loadTournamentListRequestTypes;
  loadEnrollmentListRequest: loadEnrollmentListRequestTypes;
  loadEnrollmentPlayersSummaryByIdRequest: loadEnrollmentPlayersSummaryByIdRequestTypes;
  loadEnrollmentPlayersUnclassifiedRequest: loadEnrollmentPlayersUnclassifiedRequestTypes;
  loadEmployeeListRequest: loadEmployeeListRequestTypes;
  loadGameLegendRequest: loadGameLegendRequestTypes;
  loadGameDetailRequest: loadGameDetailRequestTypes;
  createGameRequest: createGameRequestTypes;
  goToNextSegmentRequest: goToNextSegmentRequestTypes;
  queryUpdateGameDetailRequest: queryUpdateGameDetailTypes;
} = {
  loadTournamentListRequest: {
    data: {
      list: [],
      searchInputText: '',
      pageSize: 10,
      pageNumber: 0,
      total: 0,
    },
    status: null,
    error: null,
  },
  loadEnrollmentListRequest: {
    data: {
      list: [],
      searchInputText: '',
      pageSize: 10,
      pageNumber: 0,
    },
    status: null,
    error: null,
  },
  loadEnrollmentPlayersSummaryByIdRequest: {
    status: null,
    error: null,
  },
  loadEnrollmentPlayersUnclassifiedRequest: {
    data: [],
    status: null,
    error: null,
  },
  loadEmployeeListRequest: {
    data: {
      ids: [],
      pageSize: 200,
      pageNumber: 0,
    },
    status: null,
    error: null,
  },
  loadGameLegendRequest: {
    data: null,
    status: null,
    error: null,
  },
  loadGameDetailRequest: {
    data: null,
    localData: {
      selectedGameId: null,
      showDetailModal: false,
      showTargetsSettingModal: false,
      selectedTargets: {},
      showSelectedTargetSettingPopover: false,
      selectedSetTargetsInfo: null,
    },
    status: null,
    error: null,
  },
  createGameRequest: {
    data: {
      targetNums: ['1'],
      segments: [],
    },
    localData: {
      selectedEnrollmentId: null,
      showCreateModal: false,
      showTargetsSettingModal: false,
      selectedTargets: {},
      showSelectedTargetSettingPopover: false,
      selectedSetTargetsInfo: null,
    },
    status: null,
    error: null,
  },
  goToNextSegmentRequest: {
    status: null,
    error: null,
  },
  queryUpdateGameDetailRequest: {
    status: null,
    error: null,
  },
};

export const { reducer, actions } = createSlice({
  name: 'association/tournaments',
  initialState,
  reducers: {
    updatePageNumber: (state: any, action: any) => {
      state.loadTournamentListRequest.data.pageNumber = action.payload;
    },
    updatePageSize: (state: any, action: any) => {
      state.loadTournamentListRequest.data.pageSize = action.payload;
    },
    updateSearchText: (state: any, action: any) => {
      state.loadEnrollmentListRequest.data.searchInputText = action.payload;
    },
    enrollmentListSearchTextUpdated: (state: any, { payload }) => {
      state.loadEnrollmentListRequest.data.searchInputText = payload;
    },
    loadGameDetailRequestDataUpdated: (state: any, { payload }) => {
      state.loadGameDetailRequest.data = {
        ...state.loadGameDetailRequest.data,
        ...payload,
      };
    },
    loadGameDetailRequestSelectedGameIdUpdated: (state: any, { payload }) => {
      state.loadGameDetailRequest.localData.selectedGameId = payload;
    },
    loadGameDetailRequestSelectedTargetsUpdate: (state: any, { payload }) => {
      state.loadGameDetailRequest.localData.selectedTargets = {
        ...state.loadGameDetailRequest.localData.selectedTargets,
        ...payload,
      };
    },
    loadGameDetailRequestShowSelectedTargetsSettingPopover: (
      state: any,
      { payload }
    ) => {
      state.loadGameDetailRequest.localData.showSelectedTargetSettingPopover =
        payload;
    },
    loadGameDetailRequestSelectedSetTargetsInfo: (state: any, { payload }) => {
      const { selectedItem, segmentIndex } = payload || {};
      state.loadGameDetailRequest.localData.selectedSetTargetsInfo =
        selectedItem;
      if (!selectedItem) {
        state.loadGameDetailRequest.localData.selectedTargets[segmentIndex] =
          [];
      } else {
        const segments = state.loadGameDetailRequest.data.segments;
        const {
          archeryTournamentRoundSettings:
          selectedTargetsArcheryTournamentRoundSettings,
          targetNumChildScope: selectedTargetsTargetNumChildScope,
        } = selectedItem || {};
        const {
          categoryName: selectedTargetsCategory,
          competitionHalf: selectedTargetsCompetitionHalf,
          isQualifying: selectedTargetsIsQualifying,
          optionName: selectedTargetsOptionName,
          roundName: selectedTargetsRoundName,
        } = selectedTargetsArcheryTournamentRoundSettings || {};
        const { targets } = segments[segmentIndex] || {};

        if (targets && targets.length > 0) {
          const selectedTargetsObjects = targets.filter((target: any) => {
            const { targetNumChildScope, archeryTournamentRoundSettings } =
              target || {};
            const isSameTargetNumChildScope = _isEqual(
              targetNumChildScope,
              selectedTargetsTargetNumChildScope
            );

            if (isSameTargetNumChildScope) {
              const {
                categoryName,
                competitionHalf,
                isQualifying,
                optionName,
                roundName,
              } = archeryTournamentRoundSettings || {};

              return (
                categoryName === selectedTargetsCategory &&
                competitionHalf === selectedTargetsCompetitionHalf &&
                isQualifying === selectedTargetsIsQualifying &&
                optionName === selectedTargetsOptionName &&
                roundName === selectedTargetsRoundName
              );
            }

            return false;
          });

          state.loadGameDetailRequest.localData.selectedTargets[segmentIndex] =
            selectedTargetsObjects.map((object: any) => {
              return object.targetNum;
            });
        }
      }
    },
    selectedDetailSetTargetsDeleted: (state, { payload }) => {
      const { segmentIndex, selectedTargets } = payload;
      const currentSegment =
        state.loadGameDetailRequest.data.segments[segmentIndex];
      const { targets } = currentSegment || {};

      state.loadGameDetailRequest.localData.selectedTargets[segmentIndex] = [];

      const newTargets = targets.map((target: any) => {
        const { id, targetNum } = target || {};

        if (selectedTargets.includes(targetNum)) {
          return {
            id,
            targetNum,
            targetNumChildScope: [],
            archeryTournamentRoundSettings: null,
          };
        }

        return target;
      });

      state.loadGameDetailRequest.data.segments[segmentIndex] = {
        ...state.loadGameDetailRequest.data.segments[segmentIndex],
        targets: newTargets,
      };
    },
    loadGameDetailRequestDetailModalShown: (state: any) => {
      state.loadGameDetailRequest.localData.showDetailModal = true;
    },
    loadGameDetailRequestTargetsSettingModalShown: (state: any) => {
      state.loadGameDetailRequest.localData.showTargetsSettingModal = true;
    },
    loadGameDetailRequestReset: (state: any) => {
      state.loadGameDetailRequest = initialState.loadGameDetailRequest;
    },
    createGameRequestCreateModalShown: (state: any) => {
      state.createGameRequest.localData.showCreateModal = true;
    },
    createGameRequestTargetsSettingModalShown: (state: any) => {
      state.createGameRequest.localData.showTargetsSettingModal = true;
    },
    createGameRequestTargetsSettingModalHidden: (state: any) => {
      state.createGameRequest.localData.showTargetsSettingModal = false;
    },
    createGameRequestSelectedEnrollmentIdUpdated: (state: any, { payload }) => {
      state.createGameRequest.localData.selectedEnrollmentId = payload;
    },
    createGameRequestSelectedTargetsUpdate: (state: any, { payload }) => {
      state.createGameRequest.localData.selectedTargets = {
        ...state.createGameRequest.localData.selectedTargets,
        ...payload,
      };
    },
    createGameRequestShowSelectedTargetsSettingPopover: (
      state: any,
      { payload }
    ) => {
      state.createGameRequest.localData.showSelectedTargetSettingPopover =
        payload;
    },
    createGameRequestSelectedSetTargetsInfo: (state: any, { payload }) => {
      const { selectedItem, segmentIndex } = payload || {};

      state.createGameRequest.localData.selectedSetTargetsInfo = selectedItem;

      if (!selectedItem) {
        state.createGameRequest.localData.selectedTargets[segmentIndex] = [];
      } else {
        const segments = state.createGameRequest.data.segments;
        const {
          archeryTournamentRoundSettings:
          selectedTargetsArcheryTournamentRoundSettings,
          targetNumChildScope: selectedTargetsTargetNumChildScope,
        } = selectedItem || {};
        const {
          categoryName: selectedTargetsCategory,
          competitionHalf: selectedTargetsCompetitionHalf,
          isQualifying: selectedTargetsIsQualifying,
          optionName: selectedTargetsOptionName,
          roundName: selectedTargetsRoundName,
        } = selectedTargetsArcheryTournamentRoundSettings || {};
        const { targets } = segments[segmentIndex] || {};

        if (targets && targets.length > 0) {
          const selectedTargetsObjects = targets.filter((target: any) => {
            const { targetNumChildScope, archeryTournamentRoundSettings } =
              target || {};
            const isSameTargetNumChildScope = _isEqual(
              targetNumChildScope,
              selectedTargetsTargetNumChildScope
            );

            if (isSameTargetNumChildScope) {
              const {
                categoryName,
                competitionHalf,
                isQualifying,
                optionName,
                roundName,
              } = archeryTournamentRoundSettings || {};

              return (
                categoryName === selectedTargetsCategory &&
                competitionHalf === selectedTargetsCompetitionHalf &&
                isQualifying === selectedTargetsIsQualifying &&
                optionName === selectedTargetsOptionName &&
                roundName === selectedTargetsRoundName
              );
            }

            return false;
          });

          state.createGameRequest.localData.selectedTargets[segmentIndex] =
            selectedTargetsObjects.map((object: any) => {
              return object.targetNum;
            });
        }
      }
    },
    selectedCreateSetTargetsDeleted: (state, { payload }) => {
      const { segmentIndex, selectedTargets } = payload;
      const currentSegment =
        state.createGameRequest.data.segments[segmentIndex];
      const { targets }: any = currentSegment || {};

      state.createGameRequest.localData.selectedTargets[segmentIndex] = [];

      const newTargets = targets.map((target: any) => {
        const { id, targetNum } = target || {};

        if (selectedTargets.includes(targetNum)) {
          return {
            id,
            targetNum,
            targetNumChildScope: [],
            archeryTournamentRoundSettings: null,
          };
        }

        return target;
      });

      state.createGameRequest.data.segments[segmentIndex] = {
        ...state.createGameRequest.data?.segments[segmentIndex],
        targets: newTargets,
      };
    },
    createGameRequestTargetNumsUpdated: (state, { payload }) => {
      // 过滤掉已经被删除的targetNum
      state.createGameRequest.localData.selectedTargets = {};
      // segment中的targets,如果长度不够,则补充，如果长度超过，则删除
      const currentSegments = current(state.createGameRequest.data.segments);
      const newSegments = currentSegments.map((item: any) => {
        const { targets = [] } = item;

        if (targets.length > payload.length) {
          return { ...item, targets: targets.slice(0, payload.length) };
        } else if (targets.length < payload.length) {
          const addItem = Array.from({
            length: payload.length - targets.length,
          }).map((_, index) => ({
            targetNum: `${index + targets.length + 1}`,
          }));
          return { ...item, targets: [...targets, ...addItem] };
        } else {
          return item;
        }
      });

      state.createGameRequest.data.segments = newSegments;
      state.createGameRequest.data.targetNums = payload;
    },
    createGameRequestSegmentsUpdated: (state, { payload }) => {
      state.createGameRequest.data.segments = payload;
    },
    createGameRequestSegmentUpdated: (state, { payload }) => {
      const { segment, segmentIndex } = payload;
      state.createGameRequest.data.segments[segmentIndex] = segment;
    },
    createGameRequestSegmentSetTargets: (state, { payload }) => {
      const { segmentIndex, startIndex, length, archeryTournamentRoundSettings, targetNumChildScope } = payload;
      const oldTargets = current(state.createGameRequest.data.segments[segmentIndex]).targets || [];
      const arranges = oldTargets?.map(i => !i.archeryTournamentRoundSettings);
      const targetsToSet = arranges?.slice(startIndex, startIndex + length);
      if (targetsToSet.some(i => !i) || targetsToSet.length !== length) {
        return;
      }

      const newTargets = oldTargets.map((target: any, index: number) => {
        if (index >= startIndex && index < startIndex + length) {
          return {
            ...target,
            archeryTournamentRoundSettings,
            targetNumChildScope,
          };
        }
        return target;
      });
      state.createGameRequest.data.segments[segmentIndex].targets = newTargets;
    },
    createGameRequestSegmentInsert: (state, { payload }) => {
      const { segment, segmentIndex } = payload;
      // 检查 segmentIndex 是否在有效范围内
      if (segmentIndex >= 0 && segmentIndex <= state.createGameRequest.data.segments.length) {
        // 插入新 segment
        state.createGameRequest.data.segments.splice(segmentIndex, 0, segment);
      } else {
        // 可选：处理 segmentIndex 超出范围的情况
        console.error('Segment index is out of bounds');
      }
    },
    createGameRequestTargetSettingsReset: (state) => {
      state.createGameRequest.data = initialState.createGameRequest.data;
    },
    detailGameTargetNumsUpdated: (state, { payload }) => {
      // 过滤掉已经被删除的targetNum
      // segment中的targets,如果长度不够,则补充，如果长度超过，则删除
      const currentSegments = current(
        state.loadGameDetailRequest.data.segments
      );

      const newSegments = currentSegments.map((item: any) => {
        const { targets = [] } = item;

        if (targets.length > payload.length) {
          return { ...item, targets: targets.slice(0, payload.length) };
        } else if (targets.length < payload.length) {
          const addItem = Array.from({
            length: payload.length - targets.length,
          }).map((_, index) => ({
            targetNum: `${index + targets.length + 1}`,
          }));
          return { ...item, targets: [...targets, ...addItem] };
        } else {
          return item;
        }
      });

      state.loadGameDetailRequest.data.segments = newSegments;
      state.loadGameDetailRequest.data.targetNums = payload;
    },
    detailGameSegmentsUpdated: (state, { payload }) => {
      state.loadGameDetailRequest.data.segments = payload;
    },
    detailGameSegmentUpdated: (state, { payload }) => {
      const { segment, segmentIndex } = payload;
      state.loadGameDetailRequest.data.segments[segmentIndex] = segment;
    },
    detailGameSegmentSetTargets: (state, { payload }) => {
      const { segmentIndex, startIndex, length, archeryTournamentRoundSettings, targetNumChildScope } = payload;
      const oldTargets: any[] = current(state.loadGameDetailRequest.data.segments[segmentIndex]).targets || [];
      const arranges = oldTargets?.map((t: any) => !t.archeryTournamentRoundSettings);
      const targetsToSet = arranges?.slice(startIndex, startIndex + length);
      if (targetsToSet.some((i: any) => !i) || targetsToSet.length !== length) {
        return;
      }

      const newTargets = oldTargets.map((target: any, index: number) => {
        if (index >= startIndex && index < startIndex + length) {
          return {
            ...target,
            archeryTournamentRoundSettings,
            targetNumChildScope,
          };
        }
        return target;
      });
      state.loadGameDetailRequest.data.segments[segmentIndex].targets = newTargets;
    },
    detailGameSegmentInsert: (state, { payload }) => {
      const { segment, segmentIndex } = payload;
      // 检查 segmentIndex 是否在有效范围内
      if (segmentIndex >= 0 && segmentIndex <= state.loadGameDetailRequest.data.segments.length) {
        // 插入新 segment
        state.loadGameDetailRequest.data.segments.splice(segmentIndex, 0, segment);
      } else {
        // 可选：处理 segmentIndex 超出范围的情况
        console.error('Segment index is out of bounds');
      }
    },
    resetData: (state) => {
      state = initialState;
    },
    resetGameDetailData: (state) => {
      state.loadGameDetailRequest = initialState.loadGameDetailRequest;
    },
  },
  extraReducers: {
    [loadTournamentList.pending.type]: (state) => {
      state.loadTournamentListRequest.status = API_REQUEST_STATUS.PENDING;
      state.loadTournamentListRequest.error = null;
    },
    [loadTournamentList.fulfilled.type]: (state, { payload }) => {
      const { data, pagination } = payload || {};

      state.loadTournamentListRequest.data.list = data;
      state.loadTournamentListRequest.data.total = pagination?.total || 0;
      state.loadTournamentListRequest.status = API_REQUEST_STATUS.FULFILLED;
      state.loadTournamentListRequest.error = null;
    },
    [loadTournamentList.rejected.type]: (state, { error }) => {
      state.loadTournamentListRequest.status = API_REQUEST_STATUS.REJECTED;
      state.loadTournamentListRequest.error = error;
    },
    [loadEnrollmentList.pending.type]: (state) => {
      state.loadEnrollmentListRequest.status = API_REQUEST_STATUS.PENDING;
      state.loadEnrollmentListRequest.error = null;
    },
    [loadEnrollmentList.fulfilled.type]: (state, { payload }) => {
      state.loadEnrollmentListRequest.data.list = payload.map((item: any) => ({
        ...item,
        canCreateGame: moment().isAfter(moment(item.deadline)),
      }));
      state.loadEnrollmentListRequest.status = API_REQUEST_STATUS.FULFILLED;
      state.loadEnrollmentListRequest.error = null;
    },
    [loadEnrollmentList.rejected.type]: (state, { error }) => {
      state.loadEnrollmentListRequest.status = API_REQUEST_STATUS.REJECTED;
      state.loadEnrollmentListRequest.error = error;
    },
    [loadEnrollmentDetailsById.pending.type]: (state) => {
      state.loadEnrollmentListRequest.status = API_REQUEST_STATUS.PENDING;
      state.loadEnrollmentListRequest.error = null;
    },
    [loadEnrollmentDetailsById.fulfilled.type]: (state, { payload }) => {
      state.loadEnrollmentListRequest.data.list =
        state.loadEnrollmentListRequest.data.list.concat([{ ...payload, canCreateGame: false }]);
      state.loadEnrollmentListRequest.status = API_REQUEST_STATUS.FULFILLED;
      state.loadEnrollmentListRequest.error = null;
    },
    [loadEnrollmentDetailsById.rejected.type]: (state, { error }) => {
      state.loadEnrollmentListRequest.status = API_REQUEST_STATUS.REJECTED;
      state.loadEnrollmentListRequest.error = error;
    },
    [loadEnrollmentPlayersSummaryById.pending.type]: (state) => {
      state.loadEnrollmentPlayersSummaryByIdRequest.status =
        API_REQUEST_STATUS.PENDING;
      state.loadEnrollmentPlayersSummaryByIdRequest.error = null;
    },
    [loadEnrollmentPlayersSummaryById.fulfilled.type]: (state, { payload }) => {
      const { enrollmentId, paidFormPlayerCount, multiPlayerTeamCount } =
        payload || {};

      state.loadEnrollmentListRequest.data.list =
        state.loadEnrollmentListRequest.data.list.map((enrollment) => {
          const { id } = enrollment || {};

          if (id === enrollmentId) {
            return {
              ...enrollment,
              paidFormPlayerCount,
              multiPlayerTeamCount,
            };
          }

          return enrollment;
        });

      state.loadEnrollmentPlayersSummaryByIdRequest.status =
        API_REQUEST_STATUS.FULFILLED;
      state.loadEnrollmentPlayersSummaryByIdRequest.error = null;
    },
    [loadEnrollmentPlayersSummaryById.rejected.type]: (state, { error }) => {
      state.loadEnrollmentPlayersSummaryByIdRequest.status =
        API_REQUEST_STATUS.FULFILLED;
      state.loadEnrollmentPlayersSummaryByIdRequest.error = error;
    },
    [loadEnrollmentPlayersUnclassified.pending.type]: (state) => {
      state.loadEnrollmentPlayersUnclassifiedRequest.status =
        API_REQUEST_STATUS.PENDING;
      state.loadEnrollmentPlayersUnclassifiedRequest.error = null;
    },
    [loadEnrollmentPlayersUnclassified.fulfilled.type]: (
      state,
      { payload }
    ) => {
      state.loadEnrollmentPlayersUnclassifiedRequest.data = payload;
      state.loadEnrollmentPlayersUnclassifiedRequest.status =
        API_REQUEST_STATUS.FULFILLED;
      state.loadEnrollmentPlayersUnclassifiedRequest.error = null;
    },
    [loadEnrollmentPlayersUnclassified.rejected.type]: (state, { error }) => {
      state.loadEnrollmentPlayersUnclassifiedRequest.status =
        API_REQUEST_STATUS.REJECTED;
      state.loadEnrollmentPlayersUnclassifiedRequest.error = error;
    },
    [loadEmployeeList.fulfilled.type]: (state, action) => {
      const { ids } = action.payload || {};

      state.loadEmployeeListRequest.data.ids = ids;
    },
    [loadEmployeeList.rejected.type]: (state) => {
      state.loadEmployeeListRequest.status = API_REQUEST_STATUS.REJECTED;
    },
    [createGame.pending.type]: (state) => {
      state.createGameRequest.status = API_REQUEST_STATUS.PENDING;
      state.createGameRequest.error = null;
    },
    [createGame.fulfilled.type]: (state) => {
      state.createGameRequest.status = API_REQUEST_STATUS.FULFILLED;
      state.createGameRequest.error = null;
    },
    [createGame.rejected.type]: (state, { error }) => {
      state.createGameRequest.status = API_REQUEST_STATUS.REJECTED;
      state.createGameRequest.error = error;
    },
    [loadGameById.pending.type]: (state) => {
      state.loadGameDetailRequest.status = API_REQUEST_STATUS.PENDING;
      state.loadGameDetailRequest.error = null;
    },
    [loadGameById.fulfilled.type]: (state, { payload }) => {
      const { targetNums } = payload || {};

      state.loadGameDetailRequest.data = {
        ...payload,
        targetNums: !targetNums || targetNums.length === 0 ? ['1'] : targetNums,
      };

      state.loadGameDetailRequest.status = API_REQUEST_STATUS.FULFILLED;
      state.loadGameDetailRequest.error = null;
    },
    [loadGameById.rejected.type]: (state, { error }) => {
      state.loadGameDetailRequest.status = API_REQUEST_STATUS.REJECTED;
      state.loadGameDetailRequest.error = error;
    },
    [updateGameDetail.pending.type]: (state) => {
      state.loadGameDetailRequest.status = API_REQUEST_STATUS.PENDING;
      state.loadGameDetailRequest.error = null;
    },
    [updateGameDetail.fulfilled.type]: (state, { payload }) => {
      const { data } = payload || {};

      state.loadGameDetailRequest.data = data;
      state.loadGameDetailRequest.status = API_REQUEST_STATUS.FULFILLED;
      state.loadGameDetailRequest.error = null;
    },
    [updateGameDetail.rejected.type]: (state, { error }) => {
      state.loadGameDetailRequest.status = API_REQUEST_STATUS.REJECTED;
      state.loadGameDetailRequest.error = error;
    },
    [loadGameLegend.pending.type]: (state) => {
      state.loadGameLegendRequest.status = API_REQUEST_STATUS.PENDING;
      state.loadGameLegendRequest.error = null;
    },
    [loadGameLegend.fulfilled.type]: (state, { payload }) => {
      state.loadGameLegendRequest.data = payload;
      state.loadGameLegendRequest.status = API_REQUEST_STATUS.FULFILLED;
      state.loadGameLegendRequest.error = null;
    },
    [loadGameLegend.rejected.type]: (state, { error }) => {
      state.loadGameLegendRequest.status = API_REQUEST_STATUS.REJECTED;
      state.loadGameLegendRequest.error = error;
    },
    [goToNextSegment.pending.type]: (state) => {
      state.goToNextSegmentRequest.status = API_REQUEST_STATUS.PENDING;
      state.goToNextSegmentRequest.error = null;
    },
    [goToNextSegment.fulfilled.type]: (state) => {
      state.goToNextSegmentRequest.status = API_REQUEST_STATUS.FULFILLED;
      state.goToNextSegmentRequest.error = null;
    },
    [goToNextSegment.rejected.type]: (state, { error }) => {
      state.goToNextSegmentRequest.status = API_REQUEST_STATUS.REJECTED;
      state.goToNextSegmentRequest.error = error;
    },
    [queryUpdateGameDetail.pending.type]: (state) => {
      state.queryUpdateGameDetailRequest.status = API_REQUEST_STATUS.PENDING;
      state.queryUpdateGameDetailRequest.error = null;
    },
    [queryUpdateGameDetail.fulfilled.type]: (state, { payload }) => {
      const { targetNums, data } = payload || {};

      state.loadGameDetailRequest.data = {
        ...payload,
        ...data,
        targetNums: !targetNums || targetNums.length === 0 ? ['1'] : targetNums,
      };
      state.queryUpdateGameDetailRequest.status = API_REQUEST_STATUS.FULFILLED;
      state.queryUpdateGameDetailRequest.error = null;
    },
    [queryUpdateGameDetail.rejected.type]: (state, { error }) => {
      state.queryUpdateGameDetailRequest.status = API_REQUEST_STATUS.REJECTED;
      state.queryUpdateGameDetailRequest.error = error;
    },
    [resetQueryUpdateGameDetailError.fulfilled.type]: (state) => {
      state.queryUpdateGameDetailRequest.error = null;
    },
    [loadGameDetailRequestTargetsSettingModalHidden.fulfilled.type]: (
      state
    ) => {
      state.loadGameDetailRequest.localData.showTargetsSettingModal = false;
    },
    [loadGameDetailRequestDetailModalHidden.fulfilled.type]: (state) => {
      state.loadGameDetailRequest.localData.showDetailModal = false;
    },
    [createGameRequestCreateModalHidden.fulfilled.type]: (state) => {
      state.createGameRequest.localData.showCreateModal = false;
    },
  },
});

export default reducer;
