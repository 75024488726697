export const FUNCTION_FEATURES = [
  'memberCards',
  'posters',
  'activities',
  'assets',
  'archeryCompetitions',
  'market',
  'rankings',
  'associationActivities',
  'scoreHistoryStats',
];

export const FUNCTION_URLS_MAP = {
  [FUNCTION_FEATURES[0]]: {
    pathname: '/member-cards',
  },
  [FUNCTION_FEATURES[1]]: {
    pathname: '/posters',
  },
  [FUNCTION_FEATURES[2]]: {
    pathname: '/activities',
  },
  [FUNCTION_FEATURES[3]]: {
    pathname: '/assets',
  },
  [FUNCTION_FEATURES[4]]: {
    pathname: '/competition',
  },
  [FUNCTION_FEATURES[5]]: {
    pathname: '/redemption-market',
  },
  [FUNCTION_FEATURES[6]]: {
    pathname: '/ranking-management',
  },
  [FUNCTION_FEATURES[7]]: {
    pathname: '/association-activities',
  },
  [FUNCTION_FEATURES[8]]: {
    component: 'AssociationHistoriesModal',
  },
};
