import { createSelector } from '@reduxjs/toolkit';
import { StoreMappingType } from './types';

export const getStoresMapping = (state: {
  entities: { stores: StoreMappingType };
}) => state.entities.stores;

export const getStores = createSelector(getStoresMapping, (storesMapping) => {
  const keys = Object.keys(storesMapping);

  return keys.map((key) => storesMapping[key]);
});

export const getStoreById = (
  state: { entities: { stores: StoreMappingType } },
  id: string
) => state.entities.stores[id];
