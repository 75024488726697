import { get } from '../../../../../../../service/api';

export const getAssociationPlayersImportTasks = async (
  merchantName: string,
  parameters: { offset: number; limit: number }
) => {
  try {
    const result = await get(
      `/merchants/${merchantName}/association-players/import/tasks`,
      parameters
    );
    return result;
  } catch (error) {
    throw error;
  }
};

export const getAssociationPlayersImportTemplate = async (
  merchantName: string
) => {
  try {
    const result = await get(
      `/merchants/${merchantName}/association-players/import/template`,
      {}
    );
    return result;
  } catch (error) {
    throw error;
  }
};
