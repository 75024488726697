import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { AppDispatch } from '../../../../../../../../common/utils/types';
import {
  priceConvertToIntegerCents,
  priceRoundFractionalToDollar,
} from '../../../../../../../../common/utils/currency';
import { actions as enrollmentDetailActions } from '../../redux';
import {
  getArcheryCompetitionBowTypes,
  getArcheryCompetitionDistances,
} from '../../redux/selectors';
import CompetitionOptionSettingModal from './components/CompetitionOptionSettingModal';

const getCategoryName = (
  bowValue: any,
  distanceValue: any,
  bowList: any[],
  distanceList: any[]
) => {
  const bow = bowList.find((bowItem: any) => bowItem.value === bowValue);
  const distance = distanceList.find(
    (distanceItem: any) => distanceItem.value === distanceValue
  );

  const bowName = bow ? bow.label : '';
  const distanceName = distance ? distance.label : '';

  return `${bowName}${distanceName}`;
};
const GameArcheryOptionSettingDetailModal = (props: {
  id: string;
  disabled?: boolean;
  name: string;
  saved: boolean;
  categoryOptions: any[];
  onDelete: () => void;
  onDeleteOption: (option: any) => void;
  onAddOption: (option: any) => void;
  onUpdateOption: (option: any) => void;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    id,
    name,
    saved,
    categoryOptions,
    onDelete,
    onDeleteOption,
    onAddOption,
    onUpdateOption,
  } = props;
  const [categoryName, setCategoryName] = useState() as any;
  const [bow, setBow] = useState() as any;
  const [arrow, setArrow] = useState() as any;
  const [environment, setEnvironment] = useState() as any;
  const [distance, setDistance] = useState() as any;
  const [target, setTarget] = useState() as any;
  const [teamPlayerCountList, setTeamPlayerCountList] = useState([
    { name: '个人', teamPlayerCount: 1 },
  ]);
  const [archeryPriceSettings, setArcheryPriceSettings] = useState([
    {
      name: '个人',
      teamPlayerCount: 1,
      gameOptionEnableChargePrice: false,
      gameOptionPrice: undefined as number | undefined,
      gameOptionAssociationPlayerPrice: undefined as number | undefined,
    },
  ]);
  const [
    gameArcheryOptionSettingDetailModalVisible,
    setGameArcheryOptionSettingDetailModalVisible,
  ] = useState(false);
  const archeryCompetitionBowTypes = useSelector(getArcheryCompetitionBowTypes);
  const archeryCompetitionDistances = useSelector(
    getArcheryCompetitionDistances
  );
  const handleUpdateGameOption = async (form: any) => {
    try {
      await form.validateFields();

      dispatch(
        enrollmentDetailActions.updateGameInfoCategoryName({
          currentGameInfoCategoryId: id,
          GameInfoCategoryName: categoryName,
        })
      );

      const removedOptions = categoryOptions.filter(
        (option) => !teamPlayerCountList.includes(option.teamPlayerCount)
      );
      const addedOptions = teamPlayerCountList.filter((teamPlayerCount) => {
        return categoryOptions.every(
          (option) => option.teamPlayerCount !== teamPlayerCount
        );
      });
      const updatedOptions = categoryOptions.filter((option) =>
        teamPlayerCountList.includes(option.teamPlayerCount)
      );

      removedOptions.forEach((option) => {
        onDeleteOption(option);
      });

      addedOptions.forEach((teamPlayerCountItem: any) => {
        const settings = archeryPriceSettings.find(
          (s) =>
            s.name === teamPlayerCountItem.name &&
            s.teamPlayerCount === teamPlayerCountItem.teamPlayerCount
        );
        onAddOption({
          // @ts-ignore
          name: teamPlayerCountItem.name,
          teamPlayerCount: teamPlayerCountItem.teamPlayerCount,
          enableChargePrice: settings?.gameOptionEnableChargePrice || false,
          pricingInfo: {
            price: priceConvertToIntegerCents(settings?.gameOptionPrice || 0),
            associationPlayerPrice: priceConvertToIntegerCents(
              settings?.gameOptionAssociationPlayerPrice || 0
            ),
          },
          isArchery: true,
          archerySettings: {
            bow,
            target,
            distance,
            environment,
            arrows: arrow,
          },
        });
      });

      updatedOptions.forEach((option) => {
        const { name, teamPlayerCount } = option;
        const settings = archeryPriceSettings.find(
          (s) => s.name === name && s.teamPlayerCount === teamPlayerCount
        );
        onUpdateOption({
          id: option.id,
          // @ts-ignore
          name,
          teamPlayerCount,
          enableChargePrice: settings?.gameOptionEnableChargePrice || false,
          pricingInfo: {
            price: priceConvertToIntegerCents(settings?.gameOptionPrice || 0),
            associationPlayerPrice: priceConvertToIntegerCents(
              settings?.gameOptionAssociationPlayerPrice || 0
            ),
          },
          isArchery: true,
          archerySettings: {
            bow,
            target,
            distance,
            environment,
            arrows: arrow,
          },
        });
      });

      setGameArcheryOptionSettingDetailModalVisible(false);
    } catch (error) {
      throw error;
    }
  };
  const resetData = async (form: any) => {
    await form.resetFields();
    setCategoryName('');
    setBow('');
    setArrow('');
    setEnvironment('');
    setDistance('');
    setTarget('');
    setTeamPlayerCountList([{ name: '个人', teamPlayerCount: 1 }]);
    setArcheryPriceSettings([
      {
        name: '个人',
        teamPlayerCount: 1,
        gameOptionEnableChargePrice: false,
        gameOptionPrice: undefined,
        gameOptionAssociationPlayerPrice: undefined,
      },
    ]);
    setGameArcheryOptionSettingDetailModalVisible(false);
    dispatch(enrollmentDetailActions.resetSelectedGameOption());
  };
  const handleCloseModal = async (form: any) => {
    await resetData(form);
    setGameArcheryOptionSettingDetailModalVisible(false);
  };

  useEffect(() => {
    if (gameArcheryOptionSettingDetailModalVisible) {
      const { bow, arrows, environment, distance, target } =
        categoryOptions[0]?.archerySettings || {};

      const newTeamPlayerCountList = categoryOptions.map((option) => {
        return { teamPlayerCount: option.teamPlayerCount, name: option.name };
      });
      const newArcheryPriceSettings = newTeamPlayerCountList.map(
        (newTeamPlayerCount) => {
          const foundOption = categoryOptions.find(
            (option) =>
              option.teamPlayerCount === newTeamPlayerCount.teamPlayerCount &&
              option.name === newTeamPlayerCount.name
          );
          const { enableChargePrice, pricingInfo } = foundOption || {};
          return {
            ...newTeamPlayerCount,
            gameOptionEnableChargePrice: enableChargePrice,
            gameOptionPrice: priceRoundFractionalToDollar(pricingInfo?.price),
            gameOptionAssociationPlayerPrice: priceRoundFractionalToDollar(
              pricingInfo?.associationPlayerPrice
            ),
          };
        }
      );

      setCategoryName(name);
      setBow(bow);
      setArrow(arrows);
      setEnvironment(environment);
      setDistance(distance);
      setTarget(target);
      setTeamPlayerCountList(newTeamPlayerCountList);
      setArcheryPriceSettings(newArcheryPriceSettings);
    }
  }, [categoryOptions, gameArcheryOptionSettingDetailModalVisible, name]);

  useEffect(() => {
    if (gameArcheryOptionSettingDetailModalVisible && (bow || distance)) {
      setCategoryName(
        getCategoryName(
          bow,
          distance,
          archeryCompetitionBowTypes,
          archeryCompetitionDistances
        )
      );
    }
  }, [
    archeryCompetitionBowTypes,
    archeryCompetitionDistances,
    bow,
    distance,
    gameArcheryOptionSettingDetailModalVisible,
  ]);

  if (saved) {
    return null;
  }

  return (
    <>
      <Button
        className="EnrollmentDetailsOptionAddFieldItemButton flex__fluid-content"
        shape="circle"
        size="small"
        icon={<EditOutlined />}
        onClick={async () => {
          setGameArcheryOptionSettingDetailModalVisible(true);
        }}
      />
      {gameArcheryOptionSettingDetailModalVisible && (
        <CompetitionOptionSettingModal
          id={id}
          deletable={true}
          preserve={false}
          show={gameArcheryOptionSettingDetailModalVisible}
          data={{
            gameOptionName: categoryName,
            gameOptionTeamPlayerCountList: teamPlayerCountList,
            gameOptionArcheryPriceSettings: archeryPriceSettings,
            gameArcherySettings: {
              bow: {
                name: [id, 'bow'],
                value: bow,
              },
              arrow: {
                name: [id, 'arrow'],
                value: arrow,
              },
              environment: {
                name: [id, 'environment'],
                value: environment,
              },
              distance: {
                name: [id, 'distance'],
                value: distance,
              },
              target: {
                name: [id, 'target'],
                value: target,
              },
            },
          }}
          onDelete={onDelete}
          onSave={handleUpdateGameOption}
          onReset={resetData}
          onClose={handleCloseModal}
          onSelectBow={(value: any) => {
            setBow(value);
          }}
          onSelectArrow={(value: any) => {
            setArrow(value);
          }}
          onSelectEnvironment={(value: any) => {
            setEnvironment(value);
          }}
          onSelectDistance={(value: any) => {
            setDistance(value);
          }}
          onSelectTarget={(value: any) => {
            setTarget(value);
          }}
          onChangeCategoryName={(value: string) => setCategoryName(value)}
          onChangeGameInfoCategoryOptionTeamPlayerCountList={(values) => {
            setTeamPlayerCountList(values);
          }}
          onChangeArcheryPriceSettings={(values) => {
            setArcheryPriceSettings(values);
          }}
        />
      )}
    </>
  );
};

GameArcheryOptionSettingDetailModal.displayName =
  'GameArcheryOptionSettingDetailModal';

export default GameArcheryOptionSettingDetailModal;
