export const getEmployeesMapping = (state: { entities: { employees: any } }) =>
  state.entities.employees;

export const getEmployees = (state: { entities: { employees: any } }) => {
  const employeesMapping = state.entities.employees;
  const keys = Object.keys(employeesMapping);

  return keys.map((key) => employeesMapping[key]);
};

export const getEmployeeById = (
  state: { entities: { employees: any } },
  id: string
) => state.entities.employees[id];
