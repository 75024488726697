import { CURRENCY_SYMBOLS } from '../../../../common/constants';

export const PRICE_PLAN_LEVEL: { [type: string]: number } = {
  elite: 1,
  mega: 2,
};

export const PRICE_PLAN_TYPE_NAMES: { [type: string]: string } = {
  elite: '精英俱乐部计划',
  mega: '超大俱乐部计划',
};

export const PRICE_PLAN_TYPES_MAPPING: {
  [type: string]: (
    addonOneStorePrice: number,
    addonStoreCurrency: string
  ) => {
    name: string;
    description: string;
    period: string;
    unAvailableIndexList: number[];
  };
} = {
  elite: (addonOneStorePrice: number, addonStoreCurrency: string) => ({
    name: PRICE_PLAN_TYPE_NAMES.elite,
    description: `包含所有基础功能以及获客套组 支持连锁店，附加费 +${CURRENCY_SYMBOLS[addonStoreCurrency]}${addonOneStorePrice}/月/店`,
    period: '每月',
    unAvailableIndexList: [4],
  }),
  mega: (addonOneStorePrice: number, addonStoreCurrency: string) => ({
    name: PRICE_PLAN_TYPE_NAMES.mega,
    description: `包含所有精英计划功能 +${CURRENCY_SYMBOLS[addonStoreCurrency]}${addonOneStorePrice}/月/店专业一对一服务指导，助力场馆腾飞`,
    period: '每月',
    unAvailableIndexList: [],
  }),
};

export const UNPAID_STATUS = ['pendingPayment'];

export const PAID_STATUS = ['paid'];

export const PAYMENT_FAILED_STATUS = ['timeout', 'refund'];
