import { createSlice } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../../service/constants';
import {
  loadActivityList,
  changeActivityListPageNumber,
  changeActivityListPageSize,
} from './thunks';

const initialState: {
  data: [];
  pageSize: number;
  pageNumber: number;
  itemsTotal: number;
  end: boolean;
  loadActivityListStatus: string;
  poster: {
    posterName: string;
    isEnabled: boolean;
    content: any;
    imageUrl: string | null;
    type: string | null;
  };
} = {
  data: [],
  pageSize: 10,
  pageNumber: 0,
  itemsTotal: 0,
  end: false,
  loadActivityListStatus: API_REQUEST_STATUS.IDLE,
  poster: {
    posterName: '',
    isEnabled: true,
    content: null,
    imageUrl: null,
    type: null,
  },
};

export const { reducer, actions } = createSlice({
  name: 'activity/list',
  initialState,
  reducers: {
    updatePageNumber: (state: any, action: any) => {
      state.pageNumber = action.payload;
    },
    updatePageSize: (state: any, action: any) => {
      state.pageSize = action.payload;
    },
    posterUpdated: (state: any, action: any) => {
      state.poster = {
        ...state.poster,
        ...action.payload,
      };
    },
  },
  extraReducers: {
    [loadActivityList.fulfilled.type]: (state: any, action) => {
      const { pagination, data } = action.payload;

      state.data = data;
      state.end = pagination.end;
      state.itemsTotal = pagination.total;
    },
    [loadActivityList.rejected.type]: (state: {
      loadActivityListStatus: string;
    }) => {
      state.loadActivityListStatus = API_REQUEST_STATUS.REJECTED;
    },
    [changeActivityListPageNumber.fulfilled.type]: (
      state: any,
      action: any
    ) => {
      state.pageNumber = action.payload;
    },
    [changeActivityListPageSize.fulfilled.type]: (state: any, action: any) => {
      state.pageSize = action.payload;
    },
  },
});

export default reducer;
