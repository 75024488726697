import { createSlice } from '@reduxjs/toolkit';
import { loadTheFeaturesSetting } from './thunks';
import { featureSettingStateTypes } from './types';
import { API_REQUEST_STATUS } from '../../../service/constants';

const initialState: featureSettingStateTypes = {
  id: null,
  loadTheFeaturesSettingStatus: API_REQUEST_STATUS.IDLE,
};

export const { reducer, actions } = createSlice({
  name: 'app/featuresSetting',
  initialState,
  reducers: {
    featureSettingReset: (state) => {
      state = {
        id: null,
        loadTheFeaturesSettingStatus: API_REQUEST_STATUS.IDLE,
      };

      return state;
    },
  },
  extraReducers: {
    [loadTheFeaturesSetting.pending.type]: (state) => {
      state.loadTheFeaturesSettingStatus = API_REQUEST_STATUS.PENDING;
    },
    [loadTheFeaturesSetting.fulfilled.type]: (state, { payload }) => {
      state.id = payload.id;
      state.rankings = payload.rankings;
      state.archeryCompetitions = payload.archeryCompetitions;
      state.assets = payload.assets;
      state.activities = payload.activities;
      state.posters = payload.posters;
      state.memberCards = payload.memberCards;
      state.market = payload.market;
      state.associationActivities = payload.associationActivities;
      state.scoreHistoryStats = payload.scoreHistoryStats;
      state.loadTheFeaturesSettingStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [loadTheFeaturesSetting.rejected.type]: (state) => {
      state.loadTheFeaturesSettingStatus = API_REQUEST_STATUS.REJECTED;
    },
  },
});

export default reducer;
