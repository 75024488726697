import { createSlice } from '@reduxjs/toolkit';
import { loadStoreById } from './thunks';

const initialState = {
  id: null,
  isDefault: false,
  displayName: '',
  province: '',
  city: '',
  district: '',
  addressDetail: '',
};

export const { reducer, actions } = createSlice({
  name: 'stores',
  initialState,
  reducers: {
    displayNameSet: (state: { displayName: string }, { payload }) => {
      state.displayName = payload;
    },
    isDefaultSet: (state: { isDefault: boolean }, { payload }) => {
      state.isDefault = payload;
    },
    provinceSet: (state: { province: string }, { payload }) => {
      state.province = payload;
    },
    citySet: (state: { city: string }, { payload }) => {
      state.city = payload;
    },
    districtSet: (state: { district: string }, { payload }) => {
      state.district = payload;
    },
    addressDetailSet: (state: { addressDetail: string }, { payload }) => {
      state.addressDetail = payload;
    },
  },
  extraReducers: {
    [loadStoreById.fulfilled.type]: (state, { payload }) => {
      state.id = payload.id;
      state.isDefault = payload.isDefault;
      state.displayName = payload.displayName;
      state.province = payload.province;
      state.city = payload.city;
      state.district = payload.district;
      state.addressDetail = payload.addressDetail;
    },
  },
});

export default reducer;
