import _isNumber from 'lodash/isNumber';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMerchantName } from '../../../../../redux/app-v2/merchant/selectors';
import {
  getPosterList,
  postPoster,
  deletePoster,
  postDuplicatePoster,
} from './api-request';
import {
  getPosterListPaginateNumber,
  getPosterListPageSize,
} from './selectors';
import {
  errorMessage,
  successMessage,
} from '../../../../../common/utils/feedback';

export const loadPosterList = createAsyncThunk(
  'posterList/loadPosterList',
  async (
    {
      pageNumber,
      pageSize,
    }: { pageNumber?: number; pageSize?: number } | undefined = {},
    { getState }
  ) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);
      const currentPageNumber =
        _isNumber(pageNumber) && pageNumber >= 0
          ? pageNumber
          : getPosterListPaginateNumber(state);
      const currentPageSize =
        _isNumber(pageSize) && pageSize >= 0
          ? pageSize
          : getPosterListPageSize(state);
      const offset: number = currentPageNumber * currentPageSize;
      const limit: number = currentPageSize;

      const result: any = await getPosterList({ merchantName, offset, limit });

      return result;
    } catch (e) {
      errorMessage('加载海报编辑详情失败');

      throw e;
    }
  }
);

export const updatePageNumber = createAsyncThunk(
  'posterList/updatePageNumber',
  async (pageNumber: number, { dispatch }) => {
    try {
      await dispatch(loadPosterList({ pageNumber }));

      return pageNumber;
    } catch (e) {
      throw e;
    }
  }
);

export const updatePageSize = createAsyncThunk(
  'posterList/updatePageSize',
  async (pageSize: number, { dispatch }) => {
    try {
      await dispatch(loadPosterList({ pageNumber: 0, pageSize }));

      return pageSize;
    } catch (e) {
      throw e;
    }
  }
);

export const createOnePoster = createAsyncThunk(
  'posterList/createOnePoster',
  async (poster: any, { dispatch, getState }) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);

      await postPoster({ merchantName, poster });
      dispatch(loadPosterList());
    } catch (e) {
      throw e;
    }
  }
);

export const removePosterById = createAsyncThunk(
  'posterList/removePosterById',
  async (id: string, { dispatch, getState }) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);

      await deletePoster({ merchantName, id });
      successMessage('海报已删除');

      dispatch(loadPosterList());
    } catch (e) {
      throw e;
    }
  }
);

export const duplicatePosterDetail = createAsyncThunk(
  'posterList/duplicatePosterDetail',
  async (
    {
      id,
      redirectToPosterDetailPage,
    }: {
      id: string;
      redirectToPosterDetailPage: Function;
    },
    { getState }
  ) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);
      const result: any = await postDuplicatePoster(merchantName, id);
      const { data } = result || {};

      redirectToPosterDetailPage(data.id);

      return data;
    } catch (e) {
      errorMessage('创建海报副本失败');

      throw e;
    }
  }
);
