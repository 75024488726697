import { createSlice } from '@reduxjs/toolkit';
import {
  BUSINESS_TYPES,
  MERCHANT_TYPE,
  ROLES,
} from '../../../common/constants';

const initialState = {
  currentHash: window.location.hash,
  menuItems: [
    {
      key: `menu-${0}`,
      url: '/dashboard',
      iconName: 'DashboardOutlined',
      label: '状态概况',
      component: 'Dashboard',
      dataIndex: 'dashboard',
      isDefault: true,
      availableMerchantTypes: [MERCHANT_TYPE.club],
      availableRoles: [ROLES.Owner, ROLES.Manager, ROLES.StoreManager],
    },
    {
      key: `menu-${1}`,
      url: '/functions',
      iconName: 'AppstoreAddOutlined',
      label: '功能',
      component: 'Functions',
      dataIndex: 'functions',
      isDefault: true,
      availableMerchantTypes: [MERCHANT_TYPE.club, MERCHANT_TYPE.association],
      availableRoles: [ROLES.Owner, ROLES.Manager, ROLES.Operation],
    },
    {
      key: `menu-${2}`,
      url: '/stores',
      iconName: 'ShopOutlined',
      label: '门店',
      component: 'StoreList',
      dataIndex: 'stores',
      isDefault: true,
      availableMerchantTypes: [MERCHANT_TYPE.club],
      availableRoles: [ROLES.Owner, ROLES.Manager, ROLES.StoreManager],
    },
    {
      key: `menu-${3}`,
      url: '/audits',
      iconName: 'AuditOutlined',
      label: '审计跟踪',
      component: 'Audit',
      dataIndex: 'audit',
      isDefault: true,
      availableMerchantTypes: [MERCHANT_TYPE.club],
      availableRoles: [ROLES.Owner, ROLES.Manager, ROLES.StoreManager],
    },
    {
      key: `menu-${4}`,
      url: '/players',
      iconName: 'TeamOutlined',
      label: '会员管理',
      component: 'PlayerList',
      dataIndex: 'players',
      isDefault: true,
      availableMerchantTypes: [MERCHANT_TYPE.club],
      availableRoles: [ROLES.Owner, ROLES.Manager, ROLES.StoreManager],
    },
    {
      key: `menu-${5}`,
      url: '/employees',
      iconName: 'ContactsOutlined',
      label: '员工管理',
      component: 'Employee',
      dataIndex: 'employees',
      isDefault: true,
      availableMerchantTypes: [MERCHANT_TYPE.club, MERCHANT_TYPE.association],
      availableRoles: [ROLES.Owner, ROLES.Manager, ROLES.StoreManager],
    },
    {
      key: `menu-${6}`,
      url: '/member-cards',
      iconName: 'IdcardOutlined',
      label: '个性化会员卡',
      component: 'MemberCard',
      dataIndex: 'memberCards',
      isDefault: false,
      availableMerchantTypes: [MERCHANT_TYPE.club],
      availableRoles: [
        ROLES.Owner,
        ROLES.Manager,
        ROLES.StoreManager,
        ROLES.Operation,
      ],
    },
    {
      key: `menu-${7}`,
      url: '/posters',
      iconName: 'FormatPainterOutlined',
      label: '海报编辑',
      component: 'PosterList',
      dataIndex: 'posters',
      isDefault: false,
      availableMerchantTypes: [MERCHANT_TYPE.club, MERCHANT_TYPE.association],
      availableRoles: [
        ROLES.Owner,
        ROLES.Manager,
        ROLES.StoreManager,
        ROLES.Operation,
      ],
    },
    {
      key: `menu-${8}`,
      url: '/activities',
      iconName: 'NotificationOutlined',
      label: '活动管理',
      component: 'ActivityList',
      dataIndex: 'activities',
      isDefault: false,
      availableMerchantTypes: [MERCHANT_TYPE.club],
      availableRoles: [
        ROLES.Owner,
        ROLES.Manager,
        ROLES.StoreManager,
        ROLES.Operation,
      ],
    },
    {
      key: `menu-${9}`,
      url: '/assets',
      iconName: 'ContainerOutlined',
      label: '会员权益管理',
      component: 'AssetList',
      dataIndex: 'assets',
      isDefault: false,
      availableMerchantTypes: [MERCHANT_TYPE.club],
      availableRoles: [
        ROLES.Owner,
        ROLES.Manager,
        ROLES.StoreManager,
        ROLES.Operation,
      ],
    },
    {
      key: `menu-${10}`,
      url: '/redemption-market',
      iconName: 'ShoppingOutlined',
      label: '兑换商城',
      component: 'RedemptionMarket',
      dataIndex: 'market',
      isDefault: false,
      availableMerchantTypes: [MERCHANT_TYPE.club],
      availableRoles: [ROLES.Owner, ROLES.Manager, ROLES.Operation],
    },
    {
      key: `menu-${11}`,
      url: '/ranking-management',
      iconName: 'OrderedListOutlined',
      label: '排行榜管理',
      component: 'RankingManagement',
      dataIndex: 'rankings',
      isDefault: false,
      availableMerchantTypes: [MERCHANT_TYPE.club],
      availableRoles: [
        ROLES.Owner,
        ROLES.Manager,
        ROLES.StoreManager,
        ROLES.Operation,
      ],
    },
    {
      key: `menu-${12}`,
      url: '/competition',
      iconName: 'TrophyOutlined',
      label: '射箭比赛',
      component: 'TournamentDetails',
      dataIndex: 'archeryCompetitions',
      isDefault: false,
      availableMerchantTypes: [MERCHANT_TYPE.club],
      availableRoles: [
        ROLES.Owner,
        ROLES.Manager,
        ROLES.StoreManager,
        ROLES.Operation,
      ],
    },
    {
      key: `menu-${13}`,
      url: '/association-activities/clubs',
      iconName: 'SafetyCertificateOutlined',
      label: '成员单位认证',
      component: 'ClubAuthentication',
      dataIndex: 'clubs',
      isDefault: true,
      availableMerchantTypes: [MERCHANT_TYPE.association],
      availableRoles: [ROLES.Owner, ROLES.Manager],
    },
    {
      key: `menu-${14}`,
      url: '/association-activities/enrollments',
      iconName: 'SolutionOutlined',
      label: '赛事活动报名',
      component: 'EnrollmentList',
      dataIndex: 'enrollments',
      isDefault: true,
      availableMerchantTypes: [MERCHANT_TYPE.association],
      availableRoles: [ROLES.Owner, ROLES.Manager, ROLES.Operation],
    },
    {
      key: `menu-${15}`,
      url: '/association-activities/tournaments',
      iconName: 'TrophyOutlined',
      label: '赛事活动联赛',
      component: 'TournamentList',
      dataIndex: 'tournaments',
      isDefault: true,
      availableMerchantTypes: [MERCHANT_TYPE.association],
      availableRoles: [ROLES.Owner, ROLES.Manager, ROLES.Operation],
      businessTypes: [BUSINESS_TYPES.Archery],
    },
    {
      key: `menu-${16}`,
      url: '/association/players',
      iconName: 'TeamOutlined',
      label: '注册运动员',
      component: 'AssociationPlayerList',
      dataIndex: 'athletes',
      isDefault: true,
      availableMerchantTypes: [MERCHANT_TYPE.association],
      availableRoles: [ROLES.Owner, ROLES.Manager],
    },
  ],
  userCenter: [
    {
      key: `operation-${0}`,
      url: '/subscription',
      iconName: 'UserAddOutlined',
      label: '订阅',
      component: 'Subscription',
      availableMerchantTypes: [MERCHANT_TYPE.club],
      availableRoles: [ROLES.Owner, ROLES.Manager],
    },
    {
      key: `operation-${1}`,
      url: '/settings',
      iconName: 'SettingOutlined',
      label: '设置',
      component: 'Settings',
      availableMerchantTypes: [MERCHANT_TYPE.club, MERCHANT_TYPE.association],
      availableRoles: [ROLES.Owner, ROLES.Manager],
    },
    {
      key: `operation-${2}`,
      redirectUrl:
        process.env.REACT_APP_ENV === 'test'
          ? process.env.REACT_APP_HELP_CENTER_TEST_URL
          : process.env.REACT_APP_HELP_CENTER_PROD_URL,
      iconName: 'QuestionCircleOutlined',
      label: '帮助',
      availableMerchantTypes: [MERCHANT_TYPE.club, MERCHANT_TYPE.association],
      availableRoles: [
        ROLES.Owner,
        ROLES.Manager,
        ROLES.StoreManager,
        ROLES.Operation,
      ],
    },
  ],
};

export const { reducer, actions } = createSlice({
  name: 'container',
  initialState,
  reducers: {
    setCurrentHash: (state, action) => {
      state.currentHash = action.payload;
    },
  },
  extraReducers: {},
});

export default reducer;
