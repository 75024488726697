import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Space, Form, Select, Switch } from 'antd';
import {
  getDisplayAllowedCreators,
  getEmployeeList,
  getIsEnabled,
  getIsUpdatingDetails,
} from './redux/selectors';
import { AppDispatch } from '../../redux/store';
import { getIsUserInitialized } from '../../redux/app-v2/user/selectors';
import { useNavigate } from 'react-router-dom';
import ContentDetail from '../../common/components/ContentDetail';
import { actions as tournamentActions } from './redux';
import { mount, updateNewTournamentSettings } from './redux/thunks';
import './Tournament.less';

const TournamentDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const employeeList = useSelector(getEmployeeList);
  const displayAllowedCreators: string[] = useSelector(
    getDisplayAllowedCreators
  );
  const isUpdatingDetail = useSelector(getIsUpdatingDetails);
  const isEnabled = useSelector(getIsEnabled);
  const isUserInitialized = useSelector(getIsUserInitialized);

  useEffect(() => {
    if (isUserInitialized) {
      dispatch(mount());
    }
  }, [dispatch, isUserInitialized]);

  return (
    <ContentDetail title="射箭比赛">
      <section className="tournamentDetailContainer">
        <Form
          layout="vertical"
          form={form}
          key={id}
          initialValues={[displayAllowedCreators]}
        >
          <Form.Item className="formItem">
            <div className="flex">
              <Switch
                checked={isEnabled}
                onChange={(checked: boolean) => {
                  dispatch(tournamentActions.isEnabledUpdated(checked));
                }}
              />

              <div className="tournamentEnableSwitchDescription">
                开通锦标赛功能（限时免费）
              </div>
            </div>
          </Form.Item>
        </Form>
        <Form.Item className="formItem" label="可创建锦标赛店员">
          <Space size="middle">
            <Select
              placeholder="选择店员"
              mode="multiple"
              value={displayAllowedCreators}
              className="tournamentDetailEmployeeSelector"
              onChange={(value) => {
                dispatch(tournamentActions.allowedCreatorsUpdated(value));
              }}
            >
              {employeeList.map(
                ({ id, name }: { id: string; name: string }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                )
              )}
            </Select>
          </Space>
        </Form.Item>
        <div className="buttonSection">
          <Space size="middle">
            <Button
              type="primary"
              className="operateButton"
              loading={isUpdatingDetail}
              onClick={() => {
                dispatch(updateNewTournamentSettings());
              }}
            >
              保存
            </Button>
            <Button
              type="primary"
              ghost
              onClick={() => {
                navigate(-1);
              }}
            >
              返回
            </Button>
          </Space>
        </div>
      </section>
    </ContentDetail>
  );
};

export default TournamentDetails;
