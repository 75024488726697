import { message } from 'antd';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMerchantName } from '../../../../../redux/app-v2/merchant/selectors';
import {
  getMerchandiseListPageNumber,
  getMerchandiseListPageSize,
  getOriginalMerchandiseDetail,
} from './selectors';
import {
  getMerchandises,
  postMerchandise,
  deleteMerchandise,
  getMerchandiseById,
  putMerchandise,
  getAssetsSetting,
} from './api-request';
import { successMessage } from '../../../../../common/utils/feedback';

export const loadMerchandiseList = createAsyncThunk(
  'merchandise/loadMerchandiseList',
  async (_, { getState }) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);
      const offset: number =
        getMerchandiseListPageNumber(state) * getMerchandiseListPageSize(state);
      const limit: number = getMerchandiseListPageSize(state);

      const result = await getMerchandises({
        merchantName,
        offset,
        limit,
      });

      return result;
    } catch (e) {
      throw e;
    }
  }
);

export const loadAssetsSetting = createAsyncThunk(
  'merchandise/loadAssetsSetting',
  async (_, { getState }) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);

      const result = await getAssetsSetting({ merchantName });

      return result;
    } catch (e) {
      throw e;
    }
  }
);

export const removeMerchandise = createAsyncThunk(
  'merchandise/deleteMerchandise',
  async (id: string, { dispatch, getState }) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);

      await deleteMerchandise({ merchantName, id });
      successMessage('该礼品删除成功');
      await dispatch(loadMerchandiseList());

      return id;
    } catch (e) {
      throw e;
    }
  }
);

export const changeMerchandiseListPageNumber = createAsyncThunk(
  'merchandise/changeMerchandiseListPageNumber',
  async (pageNumber: number) => pageNumber
);

export const changeMerchandiseListPageSize = createAsyncThunk(
  'merchandise/changeMerchandiseListPageSize',
  async (pageSize: number) => pageSize
);

export const updateMerchandiseListPagination = createAsyncThunk(
  'merchandise/updateMerchandiseListPagination',
  async (
    { pageNumber, pageSize }: { pageNumber?: number; pageSize?: number },
    { dispatch }
  ) => {
    try {
      if (typeof pageNumber === 'number' && pageNumber >= 0) {
        await dispatch(changeMerchandiseListPageNumber(pageNumber));
      } else if (typeof pageSize === 'number' && pageSize > 0) {
        await dispatch(changeMerchandiseListPageSize(pageSize));
      }

      await dispatch(loadMerchandiseList());
    } catch (e) {
      throw e;
    }
  }
);

export const loadMerchandiseById = createAsyncThunk(
  'merchandise/loadMerchandiseById',
  async (id: string | undefined, { getState }) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);

      if (id) {
        const result: any = await getMerchandiseById({
          merchantName,
          id,
        });

        return result.data;
      }
    } catch (e) {
      message.error('加载活动信息失败');
    }
  }
);

export const saveMerchandiseById = createAsyncThunk(
  'merchandise/saveMerchandiseById',
  async (_, { getState }) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);
      const { id, ...otherData } = getOriginalMerchandiseDetail(state);

      await putMerchandise({
        merchantName,
        id,
        merchandise: otherData,
      });

      message.success('保存成功');
    } catch (e) {
      message.error('保存活动信息失败');
    }
  }
);

export const createMerchandise = createAsyncThunk(
  'merchandise/createMerchandise',
  async (merchandise: any, { dispatch, getState }) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);
      const { exchangeBy, exchanged, ...restData } = merchandise;

      await postMerchandise({
        merchantName,
        merchandise: {
          ...restData,
          exchangeBy: {
            type: exchangeBy.type,
            assetsTypeId: exchangeBy.id,
            value: exchangeBy.value,
          },
          exchanged: {
            type: exchanged.type,
            assetsTypeId: exchanged.id,
            value: exchanged.value,
          },
        },
      });

      await dispatch(loadMerchandiseList());
    } catch (e) {
      throw e;
    }
  }
);
