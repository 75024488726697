export const TARGET_LEGEND_COMPETITION_HALF = {
  FIRSTHALF: 'firstHalf',
  SECONDHALF: 'secondHalf',
};

export const TOURNAMENT_STATUS = {
  CREATED: 'created',
  TARGET_SET: 'targetSet',
  STARTED: 'started',
  ENDED: 'ended',
};

export const TOURNAMENT_NOT_START_STATUS = [
  TOURNAMENT_STATUS.CREATED,
  TOURNAMENT_STATUS.TARGET_SET,
];
