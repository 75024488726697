import { get, post } from '../../../../../../../service/api';

export const getEnrollmentList = async (payload: {
  offset: number;
  limit: number;
  text: string;
  merchantName: string;
}) => {
  try {
    const { text, offset, limit, merchantName: assoMerchantName } = payload;
    const params = { offset, limit, assoMerchantName, text };
    const result = await get(`/association-enrolls`, params, {}, {});

    return result;
  } catch (error) {
    throw new Error('get enrollment list failed');
  }
};

export const postDuplicateEnrollment = async (id: string) => {
  try {
    const result = await post(`/association-enrolls/${id}/duplicate`, {}, {});

    return result;
  } catch (error) {
    throw error;
  }
};
