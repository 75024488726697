import React from 'react';
import ReactDOM, { render } from 'react-dom';
import { CSVLink } from 'react-csv';

const destroyTarget = (target: any) => {
  ReactDOM.unmountComponentAtNode(target);

  target.remove();
};

const csvIdsSet = new Set();
const csvOptions: Function = ({
  container = document.body,
  id,
  filename,
}: {
  container?: Element;
  id?: string;
  filename?: string;
}) => ({
  container,
  id,
  filename,
});

const createCSV = (
  data: any,
  options: { container: Element; filename?: string; id?: string }
) =>
  new Promise((resolve) => {
    const { container, id, filename } = options || {};
    let csvElement: any = null;

    if (csvIdsSet.has(id)) {
      // Reject an error that will throw an unhandled error to warn.
      // The issue should be caught, so resolving an error to reference.
      resolve(new Error('Failed to create csv: id existed'));

      return;
    }

    const rootDOM = document.createElement('div');
    const csvInstance = React.createElement(CSVLink, {
      ref: (csvRef: any) => (csvElement = csvRef),
      data,
      filename,
    });

    if (id) {
      csvIdsSet.add(id);
      rootDOM.setAttribute('id', id);
    }

    container.appendChild(rootDOM);
    render(csvInstance, rootDOM);
    csvElement.link.click();

    resolve({ rootDOM, id });
  });

export const downloadCSV = (
  data: object,
  options?: { container?: Element; id?: string; filename?: string }
) =>
  createCSV(data, csvOptions(options)).then(({ target, id }: any) => {
    if (target) {
      destroyTarget(target);
      csvIdsSet.delete(id);
    }
  });
