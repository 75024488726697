import { createSelector } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../../../service/constants';
import { RegisterStateTypes } from './types';

/**
 * get merchant name
 * @return {string}
 */
export const getMerchantName = (state: { register: RegisterStateTypes }) =>
  state.register.merchantName;

/**
 * get account name
 * @return {string}
 */
export const getAccountName = (state: { register: RegisterStateTypes }) =>
  state.register.accountName;

/**
 * get password
 * @return {string}
 */
export const getPassword = (state: { register: RegisterStateTypes }) =>
  state.register.password;

/**
 * get otp
 * @return {string}
 */
export const getOtp = (state: { register: RegisterStateTypes }) =>
  state.register.otp;

/**
 * get business
 * @return {string}
 */
export const getBusiness = (state: { register: RegisterStateTypes }) =>
  state.register.business;

/**
 * get type
 * @return {string}
 */
export const getType = (state: { register: RegisterStateTypes }) =>
  state.register.type;

/**
 * get registerFrom
 * @return {string}
 */
export const getRegisterFrom = (state: { register: RegisterStateTypes }) =>
  state.register.registerFrom;

/**
 * get merchant plan info
 * @return {string}
 */
export const getMerchantPlanInfo = (state: {
  register: RegisterStateTypes;
}) => ({
  trial: state.register.trial,
  pricePlanType: state.register.pricePlanType,
  subscriptionPeriod: state.register.subscriptionPeriod,
  addonStoreCount: state.register.addonStoreCount,
});

/**
 * get login info
 * @return {merchantName: string, accountName: string, password: string}
 */
export const getRegisterInfo = createSelector(
  getMerchantName,
  getAccountName,
  getPassword,
  getOtp,
  getBusiness,
  getType,
  getRegisterFrom,
  getMerchantPlanInfo,
  (
    merchantName,
    accountName,
    password,
    otp,
    business,
    type,
    registerFrom,
    merchantPlanInfo
  ) => ({
    merchantName,
    accountName,
    password,
    otp,
    business,
    type,
    registerFrom,
    ...merchantPlanInfo,
  })
);

export const getIsRegisterStatusPending = (state: {
  register: RegisterStateTypes;
}) => state.register.registerStatus === API_REQUEST_STATUS.PENDING;

export const getIsCreatedOtpStatusPending = (state: {
  register: RegisterStateTypes;
}) => state.register.createOtpStatus === API_REQUEST_STATUS.PENDING;

export const getBusinessTypes = (state: { register: RegisterStateTypes }) =>
  state.register.businessTypes;

export const getDisplayBusinessTypes = createSelector(
  getBusinessTypes,
  (types: any) => {
    const keys = Object.keys(types);

    return keys.map((key: string) => ({
      value: key,
      label: types[key],
    }));
  }
);
