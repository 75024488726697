import { createAsyncThunk } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import { RootState } from '../../../common/utils/types';
import { getMerchantName } from '../../../redux/app-v2/merchant/selectors';
import { loadEmployeesV2 as loadEntitiesEmployees } from '../../../redux/entities/employees/thunks';
import {
  getIsStoreManager,
  getUserBelongToStoreId,
} from '../../../redux/app-v2/user/selectors';
import { getTournamentSettings, putNewTournamentSettings } from './api-request';
import { getAllowedCreators, getIsEnabled } from './selectors';

export const updateNewTournamentSettings = createAsyncThunk(
  'tournament/updateNewTournamentSettings',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const allowedCreators: string[] = getAllowedCreators(state);
      const isEnabled: Boolean = getIsEnabled(state);
      const result = await putNewTournamentSettings({
        allowedCreators,
        isEnabled,
      });

      message.success('更新锦标赛详情成功');

      dispatch(loadTournamentSettings());

      return result;
    } catch (error) {
      notification.error({ message: '更新锦标赛详情失败' });

      throw error;
    }
  }
);

export const loadTournamentSettings: any = createAsyncThunk(
  'tournament/loadTournamentSettings',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const merchantName = getMerchantName(state) as string;

      const result: any = await getTournamentSettings({ merchantName });

      message.success('加载锦标赛详情成功');
      return result;
    } catch (error) {
      notification.error({ message: '加载锦标赛详情失败' });

      throw error;
    }
  }
);

export const mount = createAsyncThunk(
  'tournaments/initalDetails',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const offset: number = 0;
      const limit: number = 1000;
      const isStoreManager = getIsStoreManager(state);
      const userBelongToStoreId = getUserBelongToStoreId(state);
      const parameters = {
        offset,
        limit,
        storeId: '',
      };

      if (isStoreManager) {
        parameters.storeId = userBelongToStoreId;
      }

      const { payload: employeeList }: any = await dispatch(
        loadEntitiesEmployees(parameters)
      );

      const { payload } = await dispatch(loadTournamentSettings());
      return { ...payload, employeeList };
    } catch (error) {
      notification.error({ message: '加载店员列表失败' });

      throw error;
    }
  }
);
