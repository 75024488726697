import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMerchantName } from '../../../../redux/app-v2/merchant/selectors';
import { loadMemberCards } from '../common/thunks';
import { getOriginalMemberCards } from '../common/selectors';
import {
  getSelectedMemberCardId,
  getSelectedMemberCardUpdatedDetails,
} from './selectors';
import { putMemberCardSetting, postMemberCardSetting } from './api-request';
import {
  errorMessage,
  successMessage,
} from '../../../../common/utils/feedback';
import { RootState } from '../../../../common/utils/types';

export const showMemberCardDetails = createAsyncThunk(
  'memberCards/showMemberCardDetails',
  async (id: string, { getState }) => {
    const state = getState() as RootState;
    const memberCards = getOriginalMemberCards(state);

    return memberCards.find(
      (memberCard: { id: string }) => memberCard.id === id
    );
  }
);

export const hideMemberCardDetails = createAsyncThunk(
  'memberCards/hideMemberCardDetails',
  async () => {}
);

export const updateTheMemberCard = createAsyncThunk(
  'memberCards/updateTheMemberCard',
  async (_, { dispatch, getState }) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);
      const id = getSelectedMemberCardId(state);
      const payload = getSelectedMemberCardUpdatedDetails(state);
      const { type } = payload;

      await putMemberCardSetting({
        merchantName,
        id,
        payload,
      });

      successMessage(`修改${type}会员卡成功!`);

      dispatch(loadMemberCards() as any);
      dispatch(hideMemberCardDetails() as any);
    } catch (e) {
      errorMessage('修改会员卡失败，请重试!');
      throw e;
    }
  }
);

export const createMemberCard = createAsyncThunk(
  'memberCards/createMemberCard',
  async (_, { dispatch, getState }) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);
      const payload = getSelectedMemberCardUpdatedDetails(state);
      const { type } = payload;

      await postMemberCardSetting({
        merchantName,
        payload,
      });

      successMessage(`新建${type}会员卡成功!`);

      dispatch(loadMemberCards() as any);
    } catch (e) {
      errorMessage('新建会员卡失败，请重试!');
      throw e;
    }
  }
);
