import _get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { ClubFormInfoItemTypes } from './type';
import { TRANSLATION_NAME_MAPPING } from '../../../utils/constants';
import { RootState } from '../../../../../../../common/utils/types';
import { priceRoundFractionalToDollar } from '../../../../../../../common/utils/currency';
import { API_REQUEST_STATUS } from '../../../../../../../service/constants';
import {
  getIsOperationsManager,
  getUserId,
} from '../../../../../../../redux/app-v2/user/selectors';

export const getLoadArcheryCompetitionOptions = (state: RootState) =>
  state.enrollment.details.loadArcheryCompetitionOptions;

export const getLoadArcheryCompetitionOptionsData = createSelector(
  getLoadArcheryCompetitionOptions,
  (loadArcheryCompetitionOptions) =>
    _get(loadArcheryCompetitionOptions, 'data', {})
);

export const getEnrollmentDetailInitialTurnOn = (state: any) =>
  state.enrollment.details.enrollmentDetailsTurnOnInitial;

export const getEnrollmentDetailsData = (state: any) =>
  state.enrollment.details.enrollmentDetailsData;

export const getEnrollmentDetailsCreatorId = (state: any) =>
  _get(state.enrollment.details, 'enrollmentDetailsData.creatorId', null);

export const getClubFromInfoData = (state: any) =>
  _get(state.enrollment, 'details.clubFormInfo', []);

export const getClubInfoMappedData = createSelector(
  getClubFromInfoData,
  (clubFormInfoData) =>
    clubFormInfoData.map((clubFormInfoItem: ClubFormInfoItemTypes) => {
      const {
        id,
        propertyName,
        description,
        propertyType,
        required,
        default: clubFormInfoItemDefault,
        saved,
        items = [],
        optionTypes,
      } = clubFormInfoItem || {};

      return {
        key: id,
        id,
        propertyName,
        description,
        required,
        propertyType,
        default: clubFormInfoItemDefault,
        saved,
        items,
        optionTypes: optionTypes.map((optionType: any) => {
          return {
            value: optionType,
            label: optionType,
          };
        }),
      };
    })
);

export const getClubInfoOutputData = createSelector(
  getClubFromInfoData,
  (clubFromInfoData) => {
    return clubFromInfoData?.map(
      ({
        propertyName,
        description,
        propertyType,
        required,
        default: newDefault,
        optionTypes,
        items,
      }: {
        propertyName: string;
        description?: string;
        propertyType: string;
        required: boolean;
        default: boolean;
        optionTypes: Array<string>;
        items: Array<any>;
      }) => {
        return {
          propertyName,
          description,
          propertyType,
          required,
          default: newDefault,
          optionTypes,
          items,
        };
      }
    );
  }
);

export const getShowNestItemSettingNewModal = (state: any) =>
  _get(state.enrollment.details, 'clubNestItemSetting.showNewModal', false);

export const getNewClubNestItemSettingClubId = (state: any) =>
  _get(state.enrollment.details, 'clubNestItemSetting.clubId', undefined);

export const getNestItemSettingOptions = createSelector(
  getNewClubNestItemSettingClubId,
  getClubFromInfoData,
  (newClubNestItemSettingClubId, clubFromInfoData) => {
    return (
      clubFromInfoData?.find(
        (club: any) => club.id === newClubNestItemSettingClubId
      )?.items || []
    );
  }
);

export const getSelectedClubNestItemSetting = (state: any) =>
  _get(state.enrollment.details, 'selectedClubNestItemSetting', {});

export const getSelectedClubNestItemSettingShowModal = createSelector(
  getSelectedClubNestItemSetting,
  (selectedClubNestItemSetting) => selectedClubNestItemSetting.showModal
);

export const getSelectedClubNestItemSettingId = createSelector(
  getSelectedClubNestItemSetting,
  (selectedClubNestItemSetting) => selectedClubNestItemSetting.id
);

export const getSelectedClubNestItemSettingPropertyName = createSelector(
  getSelectedClubNestItemSetting,
  (selectedClubNestItemSetting) => selectedClubNestItemSetting.propertyName
);

export const getSelectedClubNestItemSettingDescription = createSelector(
  getSelectedClubNestItemSetting,
  (selectedClubNestItemSetting) => selectedClubNestItemSetting.description
);

export const getSelectedClubNestItemSettingPropertyType = createSelector(
  getSelectedClubNestItemSetting,
  (selectedClubNestItemSetting) => selectedClubNestItemSetting.propertyType
);

export const getSelectedClubNestItemSettingMaxValue = createSelector(
  getSelectedClubNestItemSetting,
  (selectedClubNestItemSetting) => selectedClubNestItemSetting.maxValue
);

export const getSelectedClubNestItemSettingEnableChargePrice = createSelector(
  getSelectedClubNestItemSetting,
  (selectedClubNestItemSetting) => selectedClubNestItemSetting.enableChargePrice
);

export const getSelectedClubNestItemSettingPricingInfo = createSelector(
  getSelectedClubNestItemSetting,
  (selectedClubNestItemSetting) => selectedClubNestItemSetting.pricingInfo
);

export const getSelectedClubNestItemSettingPricingInfoPrice = createSelector(
  getSelectedClubNestItemSettingPricingInfo,
  (selectedClubNestItemSettingPricingInfo) =>
    selectedClubNestItemSettingPricingInfo?.price
);

export const getSelectedClubNestItemSettingPricingInfoAssociationPlayerPrice =
  createSelector(
    getSelectedClubNestItemSettingPricingInfo,
    (selectedClubNestItemSettingPricingInfo) =>
      selectedClubNestItemSettingPricingInfo?.associationPlayerPrice
  );

export const getSelectedClubNestItemsSetting = createSelector(
  getSelectedClubNestItemSettingId,
  getClubFromInfoData,
  (selectedClubNestItemSettingId, clubFromInfoData) => {
    return (
      clubFromInfoData?.find((club: any) => {
        const selectedClubItemInfo = club.items?.find(
          (item: any) => item.id === selectedClubNestItemSettingId
        );

        if (selectedClubItemInfo) {
          return selectedClubItemInfo;
        }
      })?.items || []
    );
  }
);

export const getPlayerInfoData = (state: any) =>
  _get(state.enrollment, 'details.playerInfo', []);

export const getPlayerInfoMappedData = createSelector(
  getPlayerInfoData,
  (playerInfoData) => {
    return playerInfoData?.map(
      (playerInfoItem: {
        id?: string;
        propertyName: string;
        propertyType: string;
        required: boolean;
        default: boolean;
        optionTypes: Array<string>;
        saved: boolean;
        enableChargePrice: boolean;
        pricingInfo: {
          price: number;
          associationPlayerPrice: number;
        };
      }) => {
        const { id, propertyName, optionTypes, pricingInfo, ...rest } =
          playerInfoItem;
        const newOptionTypes = optionTypes.map((optionType) => {
          return {
            value: TRANSLATION_NAME_MAPPING[optionType],
            label: TRANSLATION_NAME_MAPPING[optionType],
          };
        });

        if (playerInfoItem.default) {
          return {
            ...rest,
            key: id,
            optionTypes: newOptionTypes,
            propertyName: TRANSLATION_NAME_MAPPING[propertyName],
            pricingInfo: pricingInfo && {
              price: priceRoundFractionalToDollar(pricingInfo?.price),
              associationPlayerPrice: priceRoundFractionalToDollar(
                pricingInfo?.associationPlayerPrice
              ),
            },
          };
        } else {
          return {
            ...rest,
            key: id,
            propertyName,
            optionTypes: optionTypes.map((optionType: any) => {
              return {
                value: optionType,
                label: optionType,
              };
            }),
            pricingInfo: pricingInfo && {
              price: priceRoundFractionalToDollar(pricingInfo?.price),
              associationPlayerPrice: priceRoundFractionalToDollar(
                pricingInfo?.associationPlayerPrice
              ),
            },
          };
        }
      }
    );
  }
);

export const getPlayerInfoOutputData = createSelector(
  getPlayerInfoData,
  (playerInfoData) => {
    return playerInfoData?.map(
      ({
        propertyName,
        propertyType,
        required,
        default: newDefault,
        optionTypes,
        enableChargePrice,
        pricingInfo,
      }: {
        propertyName: string;
        propertyType: string;
        required: boolean;
        default: boolean;
        optionTypes: Array<string>;
        enableChargePrice: boolean;
        pricingInfo: {
          price: number;
          associationPlayerPrice: number;
        };
      }) => {
        return {
          propertyName,
          propertyType,
          required,
          default: newDefault,
          optionTypes,
          enableChargePrice,
          pricingInfo,
        };
      }
    );
  }
);

export const getGameInfoData = (state: any) =>
  _get(state.enrollment, 'details.gameInfo', []);

export const getGameInfoOutputData = createSelector(
  getGameInfoData,
  (gameInfoData) => {
    const newCategories = gameInfoData?.categories.map(
      (category: {
        isOpenOrYouthGroup: boolean;
        name: string;
        options: Array<any>;
      }) => {
        const { isOpenOrYouthGroup, name, options } = category;

        const newOptions = options.map(
          ({
            name: optionName,
            teamPlayerCount,
            enableChargePrice,
            pricingInfo,
            isArchery,
            archerySettings,
          }: {
            name: string;
            teamPlayerCount: number;
            enableChargePrice: boolean;
            pricingInfo?: {
              price: number;
              associationPlayerPrice: number;
            };
            isArchery: boolean | undefined;
            archerySettings: object | undefined;
          }) => {
            const newOption = {
              name: optionName,
              teamPlayerCount,
              enableChargePrice,
              pricingInfo,
            };

            if (isArchery) {
              return {
                ...newOption,
                isArchery,
                archerySettings,
              };
            }

            return newOption;
          }
        );

        return {
          name,
          isOpenOrYouthGroup,
          options: newOptions,
        };
      }
    );

    return {
      allowCrossCategories: gameInfoData.allowCrossCategories,
      categories: newCategories,
    };
  }
);

export const getIsArcheryGameCategory = createSelector(
  getGameInfoData,
  (gameInfoData) => {
    return (
      gameInfoData.categories.some((category: any) => category.isArchery) ||
      gameInfoData.categories.some((category: any) => {
        return category.options.some((option: any) => option.isArchery);
      })
    );
  }
);

export const getSelectedGameOptionName = (state: any) =>
  _get(state.enrollment.details, 'selectedGameOptionSetting.name', undefined);

export const getSelectedGameOptionTeamPlayerCount = (state: any) =>
  _get(
    state.enrollment.details,
    'selectedGameOptionSetting.teamPlayerCount',
    undefined
  );

export const getSelectedGameOptionEnableChargePrice = (state: any) =>
  _get(
    state.enrollment.details,
    'selectedGameOptionSetting.enableChargePrice',
    false
  );

export const getSelectedGameOptionPrice = (state: any) =>
  _get(state.enrollment.details, 'selectedGameOptionSetting.price', undefined);

export const getSelectedGameOptionAssociationPlayerPrice = (state: any) =>
  _get(
    state.enrollment.details,
    'selectedGameOptionSetting.associationPlayerPrice',
    undefined
  );

export const getNewGameOptionSettingCategoryId = (state: any) =>
  _get(state.enrollment.details, 'newGameOptionSetting.categoryId', undefined);

export const getNewGameOptionSettingCategoryOptions = createSelector(
  getNewGameOptionSettingCategoryId,
  getGameInfoData,
  (newGameOptionSettingCategoryId, gameInfoData) => {
    const { categories } = gameInfoData || {};

    return (
      categories?.find(
        (category: any) => category.id === newGameOptionSettingCategoryId
      )?.options || []
    );
  }
);

export const getShowGameOptionSettingNewModal = (state: any) =>
  _get(
    state.enrollment.details,
    'newGameOptionSetting.showGameOptionSettingNewModal',
    false
  );

export const getShowArcheryGameOptionSettingNewModal = (state: any) =>
  _get(
    state.enrollment.details,
    'newGameOptionSetting.showArcheryGameOptionSettingNewModal',
    false
  );

export const getPosterList = (state: any) =>
  _get(state.enrollment.details, 'postersInfo.data', []);

export const getLoadPostersStatus = (state: any) =>
  _get(state.enrollment.details, 'postersInfo.status', API_REQUEST_STATUS.IDLE);

export const getIsPosterListReload = createSelector(
  getLoadPostersStatus,
  (loadPostersStatus) =>
    loadPostersStatus === API_REQUEST_STATUS.IDLE ||
    loadPostersStatus === API_REQUEST_STATUS.REJECTED
);

export const getLoadEnrollmentDetailsByIdStatus = (state: any) =>
  _get(state.enrollment.details, 'loadEnrollmentDetailsByIdStatus', '');

export const getIsEnrollmentDetailsLoaded = createSelector(
  getLoadEnrollmentDetailsByIdStatus,
  (loadEnrollmentDetailsByIdStatus) =>
    loadEnrollmentDetailsByIdStatus === API_REQUEST_STATUS.FULFILLED
);

export const getIsDisabledContent = createSelector(
  getIsOperationsManager,
  getUserId,
  getEnrollmentDetailsCreatorId,
  (isOperationsManager, userId, creatorId) => {
    if (isOperationsManager && creatorId !== userId) {
      return true;
    }

    return false;
  }
);

/*
 * Selectors derived from state
 */
export const getArcheryCompetitionBowTypes = createSelector(
  getLoadArcheryCompetitionOptionsData,
  (loadArcheryCompetitionOptionsData) => {
    const bowTypes = _get(loadArcheryCompetitionOptionsData, 'bow', {});
    const bowTypeList = Object.keys(bowTypes)
      .map((bowTypeKey) => {
        if (!bowTypeKey) {
          return;
        }

        return {
          value: bowTypeKey,
          label: bowTypes[bowTypeKey],
        };
      })
      .filter((bow) => Boolean(bow));

    return bowTypeList;
  }
);

export const getArcheryCompetitionArrowsSets = createSelector(
  getLoadArcheryCompetitionOptionsData,
  (loadArcheryCompetitionOptionsData) => {
    const arrowsSets = _get(loadArcheryCompetitionOptionsData, 'arrows', {});
    const arrowsSetList = Object.keys(arrowsSets)
      .map((arrowsSetKey) => {
        if (!arrowsSetKey) {
          return;
        }

        return {
          value: arrowsSetKey,
          label: arrowsSets[arrowsSetKey],
        };
      })
      .filter((arrow) => Boolean(arrow));

    return arrowsSetList;
  }
);

export const getArcheryCompetitionEnvironments = createSelector(
  getLoadArcheryCompetitionOptionsData,
  (loadArcheryCompetitionOptionsData) => {
    const environments = _get(
      loadArcheryCompetitionOptionsData,
      'environment',
      {}
    );
    const environmentList = Object.keys(environments)
      .map((environmentKey) => {
        if (!environmentKey) {
          return;
        }

        return {
          value: environmentKey,
          label: environments[environmentKey],
        };
      })
      .filter((environment) => Boolean(environment));

    return environmentList;
  }
);

export const getArcheryCompetitionDistances = createSelector(
  getLoadArcheryCompetitionOptionsData,
  (loadArcheryCompetitionOptionsData) => {
    const distances = _get(loadArcheryCompetitionOptionsData, 'distance', {});
    const distanceList = Object.keys(distances)
      .map((distanceKey) => {
        if (!distanceKey) {
          return;
        }

        return {
          value: distanceKey,
          label: distances[distanceKey],
        };
      })
      .filter((distance) => Boolean(distance));

    return distanceList;
  }
);

export const getArcheryCompetitionTargets = createSelector(
  getLoadArcheryCompetitionOptionsData,
  (loadArcheryCompetitionOptionsData) => {
    const targets = _get(loadArcheryCompetitionOptionsData, 'target', {});
    const targetList = Object.keys(targets)
      .map((targetKey) => {
        if (!targetKey) {
          return;
        }

        return {
          value: targetKey,
          label: targets[targetKey],
        };
      })
      .filter((target) => Boolean(target));

    return targetList;
  }
);
