import React from 'react';
import { Button, Space, Upload, UploadProps } from 'antd';
import { CSVLink } from 'react-csv';
import { useMount } from 'react-use';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getUserAuthorization } from '../../../../../../../../redux/app-v2/user/selectors';
import {
  getIsBaseball,
  getMerchantName,
} from '../../../../../../../../redux/app-v2/merchant/selectors';
import { publicIp } from '../../../../../../../../service/apiIP';
import { AppDispatch } from '../../../../../../../../common/utils/types';
import {
  loadAssociationPlayersImportTasks,
  loadAssociationPlayersImportTemplate,
} from '../../redux/thunks';
import { getAssociationPlayersImportTemplateHeaders } from '../../redux/selectors';

const ImportDescription = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const userAuthorization = useSelector(getUserAuthorization);
  const merchantName = useSelector(getMerchantName);
  const headers = useSelector(getAssociationPlayersImportTemplateHeaders);
  const props: UploadProps = {
    name: 'file',
    accept: '.csv',
    headers: {
      Authorization: userAuthorization,
    },
    action: `${publicIp}/merchants/${merchantName}/association-players/import/tasks`,
    onChange({ file }) {
      if (file.status === 'done') {
        dispatch(loadAssociationPlayersImportTasks({}) as any);

        setTimeout(() => {
          dispatch(loadAssociationPlayersImportTasks({}) as any);
        }, 5000);
      }
    },
  };

  const isBaseball = useSelector(getIsBaseball);
  const clubText = isBaseball ? '联盟球队' : '成员单位';
  const playerText = isBaseball ? '球员' : '运动员';

  useMount(async () => {
    await dispatch(loadAssociationPlayersImportTemplate() as any);
  });

  return (
    <>
      <div className="content__body__item">
        <span>提供{clubText}认证的表格以便于快速导入。</span>
        <br />
        <span>
          可以
          {headers.length > 0 ? (
            <CSVLink
              data={[]}
              headers={headers}
              filename={`导入注册${playerText}模板`}
            >
              点击此处
            </CSVLink>
          ) : null}
          下载模板文件作为编辑参考。
        </span>
        <br />
        <span>
          阅读
          <a
            target="_blank"
            href={
              process.env.REACT_APP_ENV === 'test'
                ? process.env.REACT_APP_HELP_CENTER_TEST_URL
                : process.env.REACT_APP_HELP_CENTER_PROD_URL
            }
            rel="noreferrer"
          >
            帮助文档
          </a>
          可获取更多信息以便能成功导入所需信息。
        </span>
        <br />
        <br />
        <div className="ClubAuthenticationImportNotice">
          <div className="notice_title">重要提示：</div>
          <div className="notice_content">
            请不要更改任何默认表头，否则导入会发生错误
            <br />
            当文件被验证没有任何错误的时候才会开始导入，不会出现导入一半的情况
            <br />
            如果导入成功请勿重复导入，否则会员权益会重复导入
            <br />
            如果导入失败，请下载文件检查错误提示
          </div>
        </div>

        <br />
      </div>

      <div style={{ marginTop: '36px', marginBottom: '16px' }}>
        <Space size={10}>
          <Upload {...props}>
            <Button type="primary" className="operateButton">
              导入
            </Button>
          </Upload>
        </Space>
        <Space size={10} style={{ marginLeft: '10px' }}>
          <Button
            type="default"
            className="operateButton cancelButton"
            onClick={() => {
              navigate(-1);
            }}
          >
            返回
          </Button>
        </Space>
      </div>
    </>
  );
};

export default ImportDescription;
