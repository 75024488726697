import _get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { getFormatLocalTime } from '../../../../../../../common/utils/time-lib';

export const getAssociationPlayersImportStateData = (state: {
  association: { players: { playersImport: any } };
}) => state.association.players.playersImport;

export const getAssociationPlayersImportTaskList = createSelector(
  getAssociationPlayersImportStateData,
  (associationPlayersImportStateData) => {
    const tasks = _get(associationPlayersImportStateData, 'data.tasks', []);

    return tasks.map((task: any) => ({
      key: task.id,
      id: task.id,
      createdTime: getFormatLocalTime(task.createdTime),
      operatorName: task.operatorName,
      status: task.status,
      fileUrl: task.fileUrl,
    }));
  }
);

export const getAssociationPlayersImportTasksPageNumber = createSelector(
  getAssociationPlayersImportStateData,
  (associationPlayersImportStateData) =>
    _get(associationPlayersImportStateData, 'data.pageNumber', [])
);

export const getAssociationPlayersImportTasksPageSize = createSelector(
  getAssociationPlayersImportStateData,
  (associationPlayersImportStateData) =>
    _get(associationPlayersImportStateData, 'data.pageSize', [])
);

export const getAssociationPlayersImportTasksPagination = createSelector(
  getAssociationPlayersImportTasksPageNumber,
  getAssociationPlayersImportTasksPageSize,
  (pageNumber, pageSize) => ({
    offset: pageNumber * pageSize,
    limit: pageSize,
  })
);

export const getAssociationPlayersImportTasksTotal = createSelector(
  getAssociationPlayersImportStateData,
  (associationPlayersImportStateData) =>
    _get(
      associationPlayersImportStateData,
      'data.totalAssociationPlayersImportTasks',
      []
    )
);

export const getAssociationPlayersImportTemplateHeaders = createSelector(
  getAssociationPlayersImportStateData,
  (associationPlayersImportStateData) =>
    _get(associationPlayersImportStateData, 'data.headers', [])
);
