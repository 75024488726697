import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { getMerchantName } from '../merchant/selectors';
import { getUserId } from './selectors';
import { UserLoginType } from './types';
import {
  getUserById,
  getUserRoleDetailById,
  saveLocaleAuth,
  saveLocaleUser,
} from './api-request';

export const loadUserAndRoleById = createAsyncThunk(
  'app/user/loadUserAndRoleById',
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const id = getUserId(state);
      const merchantName = getMerchantName(state);

      if (id && merchantName) {
        const result: any = await getUserById({ merchantName, id });
        const { data } = result;
        const roleDetail: any = await getUserRoleDetailById({
          merchantName,
          id: data.roleId,
        });
        const { roleName, roleType, limitedAuthorities } = roleDetail.data;

        return { ...data, roleName, roleType, limitedAuthorities };
      }

      return {};
    } catch (e) {
      throw e;
    }
  }
);

export const saveUserInfoToLocale = createAsyncThunk(
  'app/user/saveUserInfoToLocale',
  async (user: UserLoginType) => {
    const { authorization } = user;
    await saveLocaleAuth(authorization);
    await saveLocaleUser(user);

    return user;
  }
);
