import { createSlice } from '@reduxjs/toolkit';

interface IAppAuditState {
  paginateNum: number;
  assetsDictionary: { [key: string]: string };
  tableData: any[];
  total: number;
  pageSize: number;
  selectStatus: string[];
  loadTableDataStatus: string | null;
}

const initialState: IAppAuditState = {
  tableData: [],
  assetsDictionary: {},
  total: 0,
  paginateNum: 1,
  pageSize: 10,
  selectStatus: ['sentOut', 'redeemed', 'cancelled'],
  loadTableDataStatus: null,
};

export const { reducer, actions } = createSlice({
  name: 'vouchers',
  initialState,
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setAssetsDictionary: (state, action) => {
      state.assetsDictionary = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setPaginateNum: (state, action) => {
      state.paginateNum = action.payload;
    },
  },
  extraReducers: {},
});

export const initType = initialState;

export const setTableData = actions.setTableData;
export const setTotal = actions.setTotal;
export const setPaginateNum = actions.setPaginateNum;
export const setPageSize = actions.setPageSize;
export const setAssetsDictionary = actions.setAssetsDictionary;
export default reducer;
