import { createSlice } from '@reduxjs/toolkit';
import { RegisterStateTypes } from './types';
import { userRegister, createOtp, loadBusinessType } from './thunks';
import { API_REQUEST_STATUS } from '../../../../../service/constants';

const initialState: RegisterStateTypes = {
  merchantName: '',
  accountName: '',
  password: '',
  otp: '',
  business: '',
  type: 'club',
  registerFrom: 'officialSite',
  trial: true,
  pricePlanType: 'elite',
  subscriptionPeriod: 'monthly',
  addonStoreCount: 0,
  businessTypes: [],
  registerStatus: API_REQUEST_STATUS.IDLE,
  createOtpStatus: API_REQUEST_STATUS.IDLE,
  loadBusinessTypeStatus: API_REQUEST_STATUS.IDLE,
};

export const { reducer, actions } = createSlice({
  name: 'register',
  initialState,
  reducers: {
    merchantNameUpdated: (state, { payload }) => {
      state.merchantName = payload;
    },
    accountNameUpdated: (state, { payload }) => {
      state.accountName = payload;
    },
    businessUpdated: (state, { payload }) => {
      state.business = payload;
    },
    passwordUpdated: (state, { payload }) => {
      state.password = payload;
    },
    otpUpdated: (state, { payload }) => {
      state.otp = payload;
    },
  },
  extraReducers: {
    [loadBusinessType.pending.type]: (state) => {
      state.loadBusinessTypeStatus = API_REQUEST_STATUS.PENDING;
    },
    [loadBusinessType.fulfilled.type]: (state, { payload }) => {
      state.business = Object.keys(payload)[0];
      state.businessTypes = payload;
      state.loadBusinessTypeStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [loadBusinessType.rejected.type]: (state) => {
      state.loadBusinessTypeStatus = API_REQUEST_STATUS.REJECTED;
    },
    [userRegister.pending.type]: (state) => {
      state.registerStatus = API_REQUEST_STATUS.PENDING;
    },
    [userRegister.fulfilled.type]: (state) => {
      state.registerStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [userRegister.rejected.type]: (state) => {
      state.registerStatus = API_REQUEST_STATUS.REJECTED;
    },
    [createOtp.pending.type]: (state) => {
      state.createOtpStatus = API_REQUEST_STATUS.PENDING;
    },
    [createOtp.fulfilled.type]: (state) => {
      state.createOtpStatus = API_REQUEST_STATUS.FULFILLED;
    },
    [createOtp.rejected.type]: (state) => {
      state.createOtpStatus = API_REQUEST_STATUS.REJECTED;
    },
  },
});

export default reducer;
