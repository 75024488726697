import { get, put } from '../../../service/api';

export const putNewTournamentSettings = async (payload: {
  allowedCreators: string[];
  isEnabled: Boolean;
}) => {
  try {
    const result: any = await put(
      `/activities/tournaments/settings`,
      payload,
      {}
    );

    return result.data;
  } catch (e) {
    throw new Error('post new tournament setting by failed');
  }
};

export const getTournamentSettings = async (payload: {
  merchantName: string;
}) => {
  try {
    const result: any = await get(
      `/activities/tournaments/settings`,
      payload,
      {},
      {}
    );

    return result.data;
  } catch (e) {
    throw new Error('get new tournament setting by failed');
  }
};
