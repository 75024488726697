import { put } from '../../../../../service/api';

export const putPoster = async (payload: {
  merchantName: string;
  id: string;
  poster: any;
}) => {
  try {
    const { merchantName, id, poster } = payload;

    const result = await put(
      `/merchants/${merchantName}/posters/${id}`,
      poster,
      {
        isShow: false,
      }
    );

    return result;
  } catch (e) {
    throw e;
  }
};
