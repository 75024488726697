import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  errorMessage,
  successMessage,
} from '../../../../../../../common/utils/feedback';
import { getMerchantName } from '../../../../../../../redux/app-v2/merchant/selectors';
import {
  getEnrollmentDetailsById,
  postNewEnrollmentDetails,
  putEnrollmentDetailsById,
  getPosters,
  getArcheryCompetitionOptions,
} from './api-request';
import {
  getEnrollmentDetailsData,
  getGameInfoOutputData,
  getPlayerInfoData,
  getClubFromInfoData,
  getClubInfoOutputData,
  getPlayerInfoOutputData,
} from './selectors';
import { priceRoundFractionalToDollar } from '../../../../../../../common/utils/currency';

export const loadArcheryCompetitionOptions = createAsyncThunk(
  'enrollmentDetails/loadArcheryCompetitionOptions',
  async () => {
    const result = await getArcheryCompetitionOptions();
    const { data }: any = result || {};

    return data;
  }
);

export const loadEnrollmentDetailsById: any = createAsyncThunk(
  'enrollmentDetails/loadEnrollmentDetailsById',
  async (id: string) => {
    try {
      const res: any = await getEnrollmentDetailsById({ id });

      return res.data;
    } catch (e) {
      errorMessage('加载报名详情失败');

      throw e;
    }
  }
);

export const addNewEnrollmentDetails: any = createAsyncThunk(
  'enrollmentDetails/addEnrollmentDetails',
  async ({ backToListPage }: { backToListPage: Function }, { getState }) => {
    try {
      const state: any = getState();
      const enrollmentDetailsData = getEnrollmentDetailsData(state);
      const clubFormInfoData = getClubFromInfoData(state);
      const playerInfoData = getPlayerInfoData(state);
      const gameInfoData = getGameInfoOutputData(state);
      const res = await postNewEnrollmentDetails({
        enrollmentDetails: enrollmentDetailsData,
        clubFormInfo: clubFormInfoData,
        playerInfo: playerInfoData,
        gameInfo: gameInfoData,
      });

      if (res.code === 5) {
        throw Error;
      }

      successMessage('新增报名详情成功', {
        handleConfirm: () => {
          backToListPage();
        },
      });
    } catch (e) {
      errorMessage('添加报名详情失败，输入表格不符合规范');
      throw e;
    }
  }
);

export const updateEnrollmentDetailsById: any = createAsyncThunk(
  'enrollmentDetails/updateEnrollmentDetailsById',
  async (
    {
      enrollmentId,
      backToListPage,
    }: { enrollmentId: string; backToListPage: Function },
    { getState }
  ) => {
    try {
      const state: any = getState();
      const enrollmentDetailsData = getEnrollmentDetailsData(state);
      const clubFormInfo = getClubInfoOutputData(state);
      const playerInfo = getPlayerInfoOutputData(state);
      const gameInfo = getGameInfoOutputData(state);
      const { title } = enrollmentDetailsData || {};

      const result = await putEnrollmentDetailsById({
        id: enrollmentId,
        enrollmentDetails: enrollmentDetailsData,
        clubFormInfo,
        playerInfo,
        gameInfo,
      });

      if (result.code !== 0) {
        throw new Error(result.description || '');
      }

      successMessage(`${title}报名详情更新成功`, {
        handleConfirm: () => {
          backToListPage();
        },
      });
    } catch (error: any) {
      const { message } = error || {};

      if (message === '') {
        errorMessage('报名详情更新失败');
      } else {
        errorMessage(message);
      }
    }
  }
);

export const loadPosterList = createAsyncThunk(
  'enrollmentDetails/loadPosterList',
  async (_, { getState }) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);
      const result: any = await getPosters({
        merchantName,
        offset: 0,
        limit: 500,
      });

      return result.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateSelectedClubNestItemSetting = createAsyncThunk(
  'enrollmentDetails/updateSelectedClubNestItemSetting',
  async (id: string, { getState }) => {
    try {
      const state: any = getState();
      const clubInfoOutputData = getClubInfoOutputData(state);
      let selectedClubInfoNestItem;

      clubInfoOutputData.forEach((clubInfo: any) => {
        if (clubInfo.items) {
          const clubItemFindResult = clubInfo.items.find(
            (item: any) => item.id === id
          );

          if (clubItemFindResult) {
            const { pricingInfo } = clubItemFindResult || {};
            const { price, associationPlayerPrice } = pricingInfo || {};

            selectedClubInfoNestItem = {
              ...clubItemFindResult,
              pricingInfo: pricingInfo && {
                ...pricingInfo,
                price: priceRoundFractionalToDollar(price),
                associationPlayerPrice: priceRoundFractionalToDollar(
                  associationPlayerPrice
                ),
              },
            };
          }
        }
      });

      if (!selectedClubInfoNestItem) {
        throw new Error('未找到该俱乐部');
      }

      return selectedClubInfoNestItem;
    } catch (error) {
      throw error;
    }
  }
);
