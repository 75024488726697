import _get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../common/utils/types';
import { getStores } from '../../../../redux/entities/stores/selectors';
import { API_REQUEST_STATUS } from '../../../../service/constants';

export const getStoreList = createSelector(getStores, (stores) => stores);

export const getLoadStoresStatus = (state: RootState) =>
  _get(state.activities.common, 'loadStoreStatus', API_REQUEST_STATUS.IDLE);

export const getIsStoreListReloaded = createSelector(
  getLoadStoresStatus,
  (loadStoreStatus) =>
    loadStoreStatus === API_REQUEST_STATUS.IDLE ||
    loadStoreStatus === API_REQUEST_STATUS.REJECTED
);

export const getPosterList = (state: RootState) =>
  _get(state.activities.common, 'postersInfo.data', []);

export const getLoadPostersStatus = (state: RootState) =>
  _get(state.activities.common, 'postersInfo.status', API_REQUEST_STATUS.IDLE);

export const getIsPosterListReload = createSelector(
  getLoadPostersStatus,
  (loadPostersStatus) =>
    loadPostersStatus === API_REQUEST_STATUS.IDLE ||
    loadPostersStatus === API_REQUEST_STATUS.REJECTED
);
