import { put } from '../../../../service/api';

export const putMerchantInfo = async (
  payload: {
    merchantName: string;
    data: any;
  },
  config?: {
    isShow: false;
  }
) => {
  try {
    const { merchantName, data } = payload;
    const result = await put(`/merchants/${merchantName}`, data, config);

    return result;
  } catch (error) {
    throw new Error('update merchant failed');
  }
};
