import { getEmployVerification, getNewCrewWechatQRCode } from './api-request';

export const loadEmployVerification = async (merchantName: string) => {
  try {
    const employVerificationResult: any = await getEmployVerification(
      merchantName
    );

    if (!employVerificationResult) {
      throw new Error('Employee verification is empty');
    } else {
      return employVerificationResult;
    }
  } catch (error: any) {
    throw error;
  }
};

export const loadWechatQRCodeURL = async ({
  merchantName,
  employVerification,
  appType,
  storeSeqNum,
}: {
  merchantName: string;
  employVerification: string;
  appType: string;
  storeSeqNum: number;
}) => {
  try {
    const newCrewWechatQRCodeResult: any = await getNewCrewWechatQRCode(
      merchantName,
      {
        employVerification,
        appType,
        storeSeqNum,
      }
    );

    if (!newCrewWechatQRCodeResult) {
      throw new Error('Employee QR code is empty');
    } else {
      return newCrewWechatQRCodeResult;
    }
  } catch (error: any) {
    throw error;
  }
};
