import { createAsyncThunk } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import { getMerchantName } from '../../../../redux/app-v2/merchant/selectors';
import { showMemberCardDetails } from '../memberCardDetails/thunks';
import {
  getMemberCardsSetting,
  deleteRequestMemberCard,
  postMemberCard,
  putMemberCardsSetting,
  putMemberCard,
} from './api-request';
import { getMemberCards } from './selectors';

export const loadMemberCards = createAsyncThunk(
  'memberCards/getMemberCardSetting',
  async (_, { getState }) => {
    try {
      const state = getState();
      const merchantName = getMerchantName(state);
      const res: any = await getMemberCardsSetting({ merchantName });

      return res.data;
    } catch (e) {
      message.error('加载会员卡列表失败');

      throw e;
    }
  }
);

export const createTheMemberCard = createAsyncThunk(
  'memberCards/postMemberCard',
  async (payload: any, { dispatch, getState }) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);
      const res: any = await postMemberCard({ merchantName, ...payload });

      if (res.code === 10) {
        message.error(`${payload.type}已存在!`);
      } else {
        message.success(`${payload.type}添加成功!`);
      }

      dispatch(loadMemberCards() as any);
    } catch (e) {
      notification.error({ message: '创建会员卡失败' });
      throw e;
    }
  }
);

export const deleteTheMemberCard = createAsyncThunk(
  'memberCards/deleteMemberCard',
  async (
    { id, type }: { id: string; type: string },
    { dispatch, getState }
  ) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);
      await deleteRequestMemberCard({ merchantName, id });

      message.success(`${type}删除成功!`);
      dispatch(loadMemberCards() as any);
    } catch (e) {
      notification.error({ message: '删除会员卡失败' });
      throw e;
    }
  }
);

export const revertTheMemberCard = createAsyncThunk(
  'memberCards/revertTheMemberCard',
  async (
    { id, type }: { id: string; type: string },
    { dispatch, getState }
  ) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);
      const memberCards = getMemberCards(state);
      const targetMemberCard = memberCards.find(
        (memberCard: any) => id === memberCard.id
      );
      const { imageUrl, type, description } = targetMemberCard || {};
      await putMemberCard({
        merchantName,
        id,
        skinImageUrl: imageUrl,
        type,
        description,
        deleted: false,
      });

      message.success(`${type}恢复成功!`);
      dispatch(loadMemberCards() as any);
    } catch (e) {
      notification.error({ message: '恢复会员卡失败' });
      throw e;
    }
  }
);

export const memberCardItemClicked = createAsyncThunk(
  'memberCards/memberCardItemClicked',
  async (id: string, { dispatch }) => dispatch(showMemberCardDetails(id) as any)
);

export const updateMemberCardsSetting: any = createAsyncThunk(
  'features/memberCard/updateMemberCardsSetting',
  async (autoGenCardNum, { dispatch, getState }) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);

      await putMemberCardsSetting({
        merchantName,
        data: { autoGenCardNum },
      });

      return autoGenCardNum;
    } catch (e) {
      notification.error({
        message: '修改失败',
        description: '修改自动生成卡号配置失败，请重试',
      });
      throw e;
    }
  }
);
