import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../service/constants';
import {
  getIsStoreManager,
  getUserBelongToStoreId,
} from '../../app-v2/user/selectors';
import { getMerchantName } from '../../../redux/app-v2/merchant/selectors';
import {
  getEmployees,
  getEmployeeById,
  postEmployee,
  putEmployeeById,
} from './api-request';
import { actions as employeesEntitiesActions } from '../../entities/employees';

export const loadEmployeesV2: any = createAsyncThunk(
  'employees/loadEmployeesV2',
  async (
    {
      search = '',
      storeId = '',
      offset,
      limit,
    }: { search: string; storeId: string; offset: number; limit: number },
    { dispatch, getState }
  ) => {
    try {
      const state: any = getState();
      const merchantName = getMerchantName(state);
      const isStoreManager = getIsStoreManager(state);
      const userBelongToStoreId = getUserBelongToStoreId(state);
      const payload = {
        merchantName,
        search,
        storeId,
        offset,
        limit,
      };

      if (isStoreManager) {
        payload.storeId = userBelongToStoreId;
      }

      const { data, pagination }: any = await getEmployees(payload);

      dispatch(employeesEntitiesActions.updateEmployeeMapping(data));

      return { pagination, ids: data.map((item: any) => item.id) };
    } catch (error) {
      throw error;
    }
  }
);

export const loadEmployeeByIdV2 = createAsyncThunk(
  'employees/loadEmployeeByIdV2',
  async (id: string, { dispatch, getState }) => {
    try {
      const state = getState();
      const merchantName = getMerchantName(state);
      const data: any = await getEmployeeById({ merchantName, id });

      await dispatch(employeesEntitiesActions.updateEmployeeById(data));

      return id;
    } catch (error) {
      throw error;
    }
  }
);

export const createAnEmployee: any = createAsyncThunk(
  'employees/createAnEmployee',
  async (payload: any, { getState }) => {
    try {
      const state = getState();
      const merchantName = getMerchantName(state);
      const result: any = await postEmployee({
        merchantName,
        ...payload,
      });

      return result;
    } catch (error) {
      throw error;
    }
  }
);

export const updateEmployeeByIdV2: any = createAsyncThunk(
  'employees/updateEmployeeByIdV2',
  async (
    { id, payload }: { id: string; payload: any },
    { dispatch, getState }
  ) => {
    try {
      const state = getState();
      const merchantName = getMerchantName(state);
      await putEmployeeById({
        merchantName,
        id,
        data: payload,
      });

      await dispatch(
        employeesEntitiesActions.updateEmployeeById({ id, ...payload })
      );

      return {
        status: API_REQUEST_STATUS.FULFILLED,
      };
    } catch (error) {
      throw error;
    }
  }
);
