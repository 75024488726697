import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../../common/utils/types';
import {
  errorMessage,
  successMessage,
} from '../../../../../common/utils/feedback';
import { getStoreInfo } from './selectors';
import {
  loadStoreByIdV2 as loadEntitiesPlayerById,
  updateStoreByIdV2 as updateEntitiesStoreById,
} from '../../../../../redux/entities/stores/thunks';
import { getStoresMapping } from '../../../../../redux/entities/stores/selectors';

export const loadStoreById: any = createAsyncThunk(
  'stores/detail/loadStoreById',
  async (id: string, { getState, dispatch }) => {
    try {
      await dispatch(loadEntitiesPlayerById(id));

      const storesMapping = getStoresMapping(getState() as RootState);

      return storesMapping[id];
    } catch (e) {
      errorMessage('店铺信息加载失败');
    }
  }
);

export const updateStoreById: any = createAsyncThunk(
  'stores/detail/updateStoreById',
  async (_, { dispatch, getState }) => {
    const state = getState();
    const payload = getStoreInfo(state);

    try {
      await dispatch(
        updateEntitiesStoreById({
          id: payload.id,
          payload,
        })
      );

      successMessage('店铺信息保存成功');
    } catch (e) {
      errorMessage('店铺信息保存失败');
    }

    dispatch(loadStoreById(payload.id));
  }
);
