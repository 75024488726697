import _isEmpty from 'lodash/isEmpty';
import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { Button, Col, Modal, Popover, Tooltip } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { getFormatRequestDataTime } from '../../../../../../../../../common/utils/time-lib';
import {
  Segment,
  Target,
  actions as createGameRequestActions,
} from '../../../redux';
import SetProjectTargetPopover from './SetProjectTargetPopover';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIsCreateTargetsSettingModal,
  getCreateGameRequestSelectedTargets,
  getGameDetailSelectedTargets,
  getCreateGameRequestShowSelectedTargetsSettingPopover,
  getGameDetailShowSelectedTargetsSettingPopover,
  getGameLegendTournaments,
  getGameDetailSelectedSetTargetsInfo,
  getCreateGameRequestSelectedSetTargetsInfo,
  getIsTargetsSettingEnabled,
  getTargetNums,
  getIsGameNotStarted,
} from '../../../redux/selectors';
import { generateColor, useEvents } from '../../../utils';
import SegmentSelectionPopover, {
  SegmentEditingView,
} from './SegmentSelectionPopover';
import './index.less';
import { TARGET_LEGEND_COMPETITION_HALF } from '../../../utils/constants';
import moment from 'moment';
import { useEvent, useInterval } from 'react-use';

let tempChangedSegment: any = null;
interface SegmentCellHeaderProps {
  style?: CSSProperties;
  segment: Segment;
  segmentIndex: number;
  onInsertSegment?: (segment: Segment, position: 'top' | 'bottom') => void;
  onUpdateSegment?: (segment: Segment) => void;
}
const SegmentCellHeader: React.FC<SegmentCellHeaderProps> = ({
  style,
  segment,
  segmentIndex,
  onUpdateSegment,
  onInsertSegment,
}) => {
  const targetNums = useSelector(getTargetNums);
  const [showHeaderPopover, setShowHeaderPopover] =
    React.useState<boolean>(false);
  const isTargetsSettingEnabled = useSelector(getIsTargetsSettingEnabled);
  const isGameNotStarted = useSelector(getIsGameNotStarted);
  const [selectRoundTypePopup, setSelectRoundTypePopup] = useState<
    undefined | 'top' | 'bottom'
  >();
  const getLastSegmentTime = (position: 'top' | 'bottom') => {
    switch (position) {
      case 'top':
        return moment(segment.startTime)
          .subtract(30, 'minute')
          .format('YYYY-MM-DD HH:mm');
      case 'bottom':
        return moment(segment.endTime).format('YYYY-MM-DD HH:mm');
    }
  };
  const handleSubmit = (currentSegment: any) => {
    if (!tempChangedSegment) {
      onUpdateSegment && onUpdateSegment(currentSegment);
      tempChangedSegment = currentSegment;
    } else {
      onUpdateSegment && onUpdateSegment(tempChangedSegment);
      tempChangedSegment = null;
    }

    setShowHeaderPopover(false);
  };

  return (
    <Col
      span={2}
      style={
        style || {
          position: 'sticky',
          left: 0,
          zIndex: 1,
          background: 'white',
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
        }
      }
    >
      <Popover
        placement="right"
        trigger={['click']}
        autoAdjustOverflow={false}
        visible={showHeaderPopover}
        onVisibleChange={(visible) => !visible && setShowHeaderPopover(visible)}
        title={'编辑赛程'}
        content={
          <SegmentEditingView
            segment={segment}
            onSubmit={(currentSegment) => {
              handleSubmit(currentSegment);
            }}
          />
        }
      >
        <div className="TargetSettingTableCellButton">
          {segment.displayName}
          <div className="TargetSettingTableCellButtonDescription">
            {/* segment.startTime segment.endTime,是同一天，格式化成 2022-10-23 10:30-11:30 */}
            {getFormatRequestDataTime(segment.startTime) ===
            getFormatRequestDataTime(segment.endTime)
              ? getFormatRequestDataTime(segment.startTime)
              : ''}
          </div>
          <div className="TargetSettingTableCellButtonDescription">
            {/* segment.startTime segment.endTime,是同一天，格式化成 2022-10-23 10:30-11:30 */}
            {getFormatRequestDataTime(segment.startTime) ===
            getFormatRequestDataTime(segment.endTime)
              ? `${getFormatRequestDataTime(
                  segment.startTime,
                  'HH:mm'
                )} - ${getFormatRequestDataTime(segment.endTime, 'HH:mm')}`
              : `${getFormatRequestDataTime(
                  segment.startTime,
                  'YYYY-MM-DD HH:mm'
                )} - ${getFormatRequestDataTime(
                  segment.endTime,
                  'YYYY-MM-DD HH:mm'
                )}`}
          </div>

          {isGameNotStarted && (
            <SegmentSelectionPopover
              trigger={['click']}
              visible={selectRoundTypePopup !== undefined}
              lastSegmentTime={getLastSegmentTime('top')}
              onFinishPicker={(segment) => {
                const targets =
                  segment.segmentType === 'competition'
                    ? targetNums?.map((targetNum: any) => {
                        return {
                          targetNum,
                        };
                      })
                    : undefined;

                selectRoundTypePopup &&
                  onInsertSegment &&
                  onInsertSegment(
                    {
                      ...segment,
                      targets,
                    },
                    selectRoundTypePopup
                  );

                setSelectRoundTypePopup(undefined);
              }}
              onVisibleChange={(visible) => {
                if (!visible) {
                  setSelectRoundTypePopup(undefined);
                }
              }}
            >
              <div
                className="ActionArea"
                onMouseOver={() => {
                  const maskTable = document.querySelector(
                    '#TargetSettingTableMask'
                  );

                  if (maskTable) {
                    const maskTableActionAreas: any =
                      maskTable.querySelectorAll('.ActionArea');

                    if (
                      maskTableActionAreas &&
                      maskTableActionAreas[segmentIndex]
                    ) {
                      maskTableActionAreas[segmentIndex].style.opacity = 1;
                    }
                  }
                }}
                onMouseOut={() => {
                  const maskTable = document.querySelector(
                    '#TargetSettingTableMask'
                  );

                  if (maskTable) {
                    const maskTableActionAreas: any =
                      maskTable.querySelectorAll('.ActionArea');

                    if (
                      maskTableActionAreas &&
                      maskTableActionAreas[segmentIndex]
                    ) {
                      maskTableActionAreas[segmentIndex].style.opacity = 0;
                    }
                  }
                }}
              >
                {isTargetsSettingEnabled && (
                  <Button
                    size="small"
                    block
                    style={{
                      background: 'transparent',
                      border: 'none',
                      color: 'white',
                      height: 'auto',
                      flex: 1,
                    }}
                    icon={<PlusOutlined />}
                    onClick={() => setSelectRoundTypePopup('top')}
                  />
                )}
                <Button
                  size="small"
                  block
                  style={{
                    background: 'transparent',
                    border: 'none',
                    color: ' white',
                    height: 'auto',
                    flex: 1,
                  }}
                  onClick={() => setShowHeaderPopover(!showHeaderPopover)}
                >
                  编辑
                </Button>
                {isTargetsSettingEnabled && (
                  <Button
                    size="small"
                    block
                    style={{
                      background: 'transparent',
                      border: 'none',
                      color: ' white',
                      height: 'auto',
                      flex: 1,
                    }}
                    icon={<PlusOutlined />}
                    onClick={() => setSelectRoundTypePopup('bottom')}
                  />
                )}
              </div>
            </SegmentSelectionPopover>
          )}
        </div>
      </Popover>
    </Col>
  );
};

export default SegmentCellHeader;
