import { message } from 'antd';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { loadStoresV2 as loadEntitiesStores } from '../../../../../redux/entities/stores/thunks';
import { getStoreListPageNumber, getStoreListPageSize } from './selectors';

export const loadStoreList = createAsyncThunk(
  'stores/loadStoreList',
  async (_, { dispatch, getState }) => {
    try {
      const state: any = getState();
      const offset: number =
        getStoreListPageNumber(state) * getStoreListPageSize(state);
      const limit: number = getStoreListPageSize(state);

      const { payload }: any = await dispatch(
        loadEntitiesStores({ offset, limit })
      );

      return payload;
    } catch (e) {
      message.error('加载商店列表失败');
    }
  }
);
