import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select, Space } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../../redux/store';
import { errorMessage } from '../../../../../../common/utils/feedback';
import { getUserPhone } from '../../../../../../redux/app-v2/user/selectors';
import { actions as employeeListActions } from '../../redux';
import {
  getSearchEmployeeOptions,
  getIsEmployeeToOwnerTransferring,
  getTransferEmployeeToOwnerModalShown,
  getEmployeeIdForTransferAsOwner,
  getIsCreatedOtpStatusPending,
  getOtp,
} from '../../redux/selectors';
import {
  createOtp,
  loadSearchEmployeeListForTransferOwner,
  transferOwnerToOtherEmployee,
} from '../../redux/thunks';
import './OwnerTransferModal.less';

const OTP_COUNT_DOWN_DURATION = 60;

let timer: any = null;
const OwnerTransferModal: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const userPhone = useSelector(getUserPhone);
  const [countingDown, setCountingDown] = useState(false);
  const [countingDownTime, setCountingDownTime] = useState(
    OTP_COUNT_DOWN_DURATION
  );
  const otp = useSelector(getOtp);
  // get otp status pending
  const isCreatedOtpStatusPending = useSelector(getIsCreatedOtpStatusPending);
  const transferEmployeeToOwnerModalShown = useSelector(
    getTransferEmployeeToOwnerModalShown
  );
  const searchEmployeeOptions = useSelector(getSearchEmployeeOptions);
  const employeeIdForTransferAsOwner = useSelector(
    getEmployeeIdForTransferAsOwner
  );
  const isEmployeeToOwnerTransferring: boolean = useSelector(
    getIsEmployeeToOwnerTransferring
  );
  const navigateToLoginPage = () => {
    navigate('/login', { replace: true });
  };
  // otp resend count down
  let timeoutTimer: any = null;
  const otpResendCountDown = (time: number) => {
    if (time === 0) {
      setCountingDown(false);
      clearTimeout(timeoutTimer);
      return;
    }

    timeoutTimer = setTimeout(() => {
      time -= 1;
      setCountingDownTime(time);
      otpResendCountDown(time);
    }, 1000);
  };
  const handleOtpChange = (e: any) => {
    dispatch(employeeListActions.otpUpdated(e.target.value));
  };
  // create a new otp
  const handleOtpCreate = async () => {
    try {
      await form.validateFields(['merchantId', 'accountName']);
      setCountingDown(true);
      otpResendCountDown(OTP_COUNT_DOWN_DURATION);
      dispatch(createOtp());
    } catch (error: any) {
      errorMessage('获取验证码失败');
    }
  };

  useEffect(() => {
    if (transferEmployeeToOwnerModalShown) {
      dispatch(loadSearchEmployeeListForTransferOwner(''));
    }
  }, [dispatch, transferEmployeeToOwnerModalShown]);

  return (
    <Modal
      centered
      visible={transferEmployeeToOwnerModalShown}
      title="搜索移交权限员工"
      okText="确认"
      cancelText="取消"
      okButtonProps={{
        loading: isEmployeeToOwnerTransferring,
        danger: true,
      }}
      onCancel={() => {
        dispatch(employeeListActions.hideTransferOwnerModal());
      }}
      onOk={async () => {
        try {
          await form.validateFields();

          await dispatch(
            transferOwnerToOtherEmployee({
              navigateToLoginPage,
            })
          );
        } catch (error: any) {
          throw error;
        }
      }}
    >
      <Form layout="vertical" form={form} preserve={false}>
        <Form.Item
          name="employeeIdForTransferAsOwner"
          label="员工（更新为顶级管理）"
          initialValue={employeeIdForTransferAsOwner}
          rules={[
            {
              required: true,
              message: `请选择员工`,
            },
          ]}
        >
          <Select
            showSearch
            placeholder="请输入搜索员工昵称 / 手机号码"
            value={employeeIdForTransferAsOwner}
            defaultActiveFirstOption={false}
            filterOption={false}
            onSearch={(value) => {
              clearTimeout(timer);

              timer = setTimeout(() => {
                dispatch(loadSearchEmployeeListForTransferOwner(value));
              }, 400);
            }}
            onChange={(value) => {
              dispatch(
                employeeListActions.ownerTransferEmployeeIdUpdated(value)
              );
            }}
            notFoundContent={
              <div className="flex flex-column flex-middle flex-center">
                <InboxOutlined />
                <span>未搜到此员工单位</span>
              </div>
            }
            options={searchEmployeeOptions}
          />
        </Form.Item>
        <Form.Item
          name="otp"
          label={`您的手机号码尾号为：***${userPhone.slice(-4)}`}
          rules={[{ required: true, message: '请输入手机验证码' }]}
        >
          <Space className="OwnerTransferModalOtpContainer flex" size={16}>
            <Input
              className="OwnerTransferModalOtpInput"
              placeholder="短信验证码"
              value={otp}
              onChange={handleOtpChange}
            />
            <Button
              block
              className="OwnerTransferModalOtpButton"
              type="primary"
              htmlType="button"
              loading={isCreatedOtpStatusPending}
              disabled={countingDown}
              onClick={handleOtpCreate}
            >
              获取验证码{countingDown ? `(${countingDownTime}s)` : ''}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OwnerTransferModal;
