import { createSlice } from '@reduxjs/toolkit';
import { API_REQUEST_STATUS } from '../../../service/constants';
import { AssetsStateType } from './types';
import {
  loadAssetList,
  createAssets,
  removeAsset,
  updateAsset,
} from './thunks';

const initialState: {
  dataItems: AssetsStateType[];
  details: {
    type?: string;
    unit?: string;
    description?: string;
    isSensitive?: false;
  };
  selectedAssetsId: string;
  playerAssetsDetailsDetailsVisibility: boolean;
  createAssets: {
    status: string;
  };
  updateAssetsDetails: {
    status: string;
  };
} = {
  dataItems: [],
  details: {},
  selectedAssetsId: '',
  playerAssetsDetailsDetailsVisibility: false,
  createAssets: {
    status: API_REQUEST_STATUS.IDLE,
  },
  updateAssetsDetails: {
    status: API_REQUEST_STATUS.IDLE,
  },
};

export const { reducer, actions } = createSlice({
  name: 'assetList',
  initialState,
  reducers: {
    setSelectedAssetsId: (state, action) => {
      state.selectedAssetsId = action.payload;
    },
    showPlayerAssesDetailsDetailsVisibility: (state) => {
      state.playerAssetsDetailsDetailsVisibility = true;
    },
    hidePlayerAssesDetailsDetailsVisibility: (state) => {
      state.playerAssetsDetailsDetailsVisibility = false;
    },
    detailsTypeUpdated: (state, action) => {
      state.details.type = action.payload;
    },
    detailsUnitUpdated: (state, action) => {
      state.details.unit = action.payload;
    },
    detailsDescriptionUpdated: (state, action) => {
      state.details.description = action.payload;
    },
    detailsIsSensitiveUpdated: (state, action) => {
      state.details.isSensitive = action.payload;
    },
    resetDetails: (state) => {
      state.details = {};
      state.selectedAssetsId = '';
    },
  },
  extraReducers: {
    [loadAssetList.fulfilled.type]: (state, { payload }) => {
      state.dataItems = payload.types;
    },
    [removeAsset.fulfilled.type]: (state) => {
      state.playerAssetsDetailsDetailsVisibility = false;
    },
    [removeAsset.rejected.type]: (state) => {
      state.playerAssetsDetailsDetailsVisibility = false;
    },
    [updateAsset.pending.type]: (state) => {
      state.updateAssetsDetails.status = API_REQUEST_STATUS.PENDING;
    },
    [updateAsset.fulfilled.type]: (state) => {
      state.playerAssetsDetailsDetailsVisibility = false;
      state.updateAssetsDetails.status = API_REQUEST_STATUS.FULFILLED;
    },
    [updateAsset.rejected.type]: (state) => {
      state.updateAssetsDetails.status = API_REQUEST_STATUS.REJECTED;
    },
    [createAssets.pending.type]: (state) => {
      state.createAssets.status = API_REQUEST_STATUS.PENDING;
    },
    [createAssets.fulfilled.type]: (state) => {
      state.playerAssetsDetailsDetailsVisibility = false;
      state.createAssets.status = API_REQUEST_STATUS.FULFILLED;
    },
    [createAssets.rejected.type]: (state) => {
      state.createAssets.status = API_REQUEST_STATUS.REJECTED;
    },
  },
});

export default reducer;
