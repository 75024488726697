import React from 'react';
import { Card, Image, message } from 'antd';
import './MarketFunctionCard.less';
import { MarketFunctionPropsType } from './types';

const MarketFunctionCard = ({
  img,
  title,
  desc,
  onclick,
}: MarketFunctionPropsType) => {
  return (
    <Card
      hoverable
      onClick={() => {
        if (onclick) {
          onclick();
        } else {
          message.error('该功能暂未开通');
        }
      }}
      className="MarketFunctionCard"
      cover={
        <Image
          preview={false}
          className="MarketFunctionCardImage"
          alt="Mini Market Function Card Image - Zaiye"
          src={img}
        />
      }
      bordered={true}
    >
      <div className="MarketFunctionCardTitleContainer">
        <span className="MarketFunctionCardTitle">{title}</span>
      </div>
      <p className="MarketFunctionCardDescription text__omit">{desc}</p>
    </Card>
  );
};

export default MarketFunctionCard;
